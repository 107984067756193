import React, { Component } from 'react';
import $ from 'jquery'
import './index.css';
import MaskBox from '../../components/maskbox';

class Coursedata extends Component {
  constructor(props) {
    super(props)
    this.closeMask = this.closeMask.bind(this);
    this.state = {
      typedownarr: 0,
      ismask: false,
      downmask: false,
      masktext: '请选择要下载的内容'
    }
  }
  loveClick(index) {
    let new_arr = this.props.coursedata;
    new_arr[index].active = !new_arr[index].active;
    let numbers = new_arr[index].active ?
      this.state.typedownarr + 1 :
      this.state.typedownarr - 1
    this.setState({
      typedownarr: numbers
    })
    this.props.change(new_arr);
  }

  godownload = (id, item) => {
    console.log(id, item)
    let that = this
    $.ajax({
      type: "GET",
      url:
        "https://shl-info.cn/front/frontc/sendfile?userid=" +
        this.props.userinfo.userid +
        "&ids=" +
        id,
      dataType: "json",
      headers: { 'token': this.props.userinfo.token },
      cache: false,
      success: res => {
        if (res && res.status !== 2) {
          that.setState({
            downmask: true
          });
        }
      }
    });
  }
  gotoDown() {
    if (this.state.typedownarr < 1) {
      this.setState({
        ismask: true,
        masktext: "请选择要下载的内容"
      });
    } else {
      if (!this.filefalg) {
        return false;
      }
      this.filefalg = false;
      let values = [];
      let new_arr = this.props.coursedata;
      new_arr.forEach(item => {
        if (item.active) {
          values.push(item.id);
          item.active = false;
        }
      });
      $.ajax({
        type: "GET",
        url:
          "https://shl-info.cn/front/frontc/sendfile?userid=" +
          this.props.userinfo.userid +
          "&ids=" +
          values.join(","),
        dataType: "json",
        headers: { 'token': this.props.userinfo.token },
        cache: false
      }).then(res => {
        if (res && res.status !== 2) {
          this.setState({
            ismask: true,
            masktext: "资料已经发送到您邮箱，请查收！",
            typedownarr: 0
          });
          this.props.hidevideo(true);
          this.props.change(new_arr);
          this.filefalg = true;
        }
      });
    }
  }
  closeMask() {
    this.setState({
      ismask: false,
      downmask: false,
    })
  }
  componentDidMount() {
    this.filefalg = true;
    let nums = 0;
    this.props.coursedata.forEach((item) => {
      if (item.active) {
        nums += 1;
      }
    })
    this.setState({
      typedownarr: nums
    })
  }
  componentWillUnmount() {

  }
  render() {
    let coursedata = this.props.coursedata.map((list, index) => {
      if (list.iszip == 1) {
        return (
          <div
            key={list.id}
            className={list.active ? "typedown-li active" : "typedown-li"}
            onClick={(e) => this.loveClick(index, e)}>
            {/* <span></span> */}
            {list.title}
            <div className="typePreview">
              <div href={list.pdf} target="_black" onClick={() => this.godownload(list.id, list)}>下载</div>
            </div>
          </div>
        )
      } else {
        return (
          <div
            key={list.id}
            className={list.active ? "typedown-li active" : "typedown-li"}
            onClick={(e) => this.loveClick(index, e)}>
            {/* <span></span> */}
            {list.title}
            <div className="typePreview">
              <div href={list.pdf} target="_black" onClick={() => this.props.recoreseenum(list.id, list)}>预览</div>
            </div>
          </div>
        )
      }
    })
    let coursemask = null;
    if (this.state.downmask) {
      coursemask = (
        <MaskBox
          info={{
            title: '课程资料',
            text: '已发送到您的邮箱，请查收'
          }}
          closeMask={this.closeMask}>
        </MaskBox>
      )
    }
    if (this.state.ismask) {
      coursemask = (
        <MaskBox
          info={{
            title: '课程资料',
            text: this.state.masktext
          }}
          closeMask={this.closeMask}>
        </MaskBox>
      )
    }
    return (
      <div className="coursedata-scroll">
        <div className="wrap">
          <div className="iscroll-padding">
            {coursedata}
          </div>
          {/* <div className="down-footer">
            <div
              className="hotdown-btn cursor fr"
              onClick={(e) => this.gotoDown(e)}>
              下载
            </div>
            <div className="numbers-box fr">
              <span>{this.state.typedownarr}</span>
            </div>
          </div> */}
          {coursemask}
        </div>
      </div>
    )
  }
}

export default Coursedata;
