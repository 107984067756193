import React, { Component } from 'react'
// 组件
import Header from '../../components/header'
import Footer from '../../components/footer'
import PersonImg from '../../components/personimg'
import LayoutClass from '../../components/layoutclass'
import CLassLabel from '../../components/classlabel'

import './index.css'

class LearningPlanback extends Component {
  constructor() {
    super();

    this.state = {
      active: 0,
      iscroll: null,
      layerstate: false,
      piclist: [],
      imglist: [],
      subtext: null,
      subtextfen: null,
      passbackstate: false,
      //worktextlist: ['', '沙龙1-1A', '沙龙1-1B', '沙龙1-2', '沙龙2-1A', '沙龙2-1B', '沙龙2-2', '沙龙3-1A', '沙龙3-1B', '沙龙3-2'],
      
      worktextlist: ['', 'HPS作业提交', '', 'PLP作业提交', '沙龙2-1A', '沙龙2-1B', '沙龙2-2', '沙龙3-1A', '沙龙3-1B', '沙龙3-2'],
      contentlist: {
        subtext: null,
        piclist: [],
      },
    }
  }
  showlayer = () => {
    this.setState({
      layerstate: true,
    })
  }
  closelayer = () => {
    this.setState({
      layerstate: false,
    })
  }

  getUrlkey(url) {
    var params = {};
    var urls = url.split("?");                  //console.log('1_分割url:', urls)
    var arr = urls[1].split("&");               //console.log('2_分割urls[1]:', arr)
    for (var i = 0, l = arr.length; i < l; i++) {
      var a = arr[i].split("=");                //console.log('3_遍历 arr 并分割后赋值给a:', a[0], a[1])
      params[a[0]] = a[1];                      //console.log('4_a给params对象赋值:', params)
    }                                           //console.log('5_结果:', params)
    return params;
  }

  componentDidMount() {
    this.props.change_person_classlist()
    let userid = (localStorage.getItem('userid'));
    let token = (localStorage.getItem('token'));
    console.log(33333333333)

    var urls = this.getUrlkey(window.location.href);
    console.log(urls)
    this.typeid = urls.typeid;
    this.typeuserid = urls.userid;
    this.workid = urls.workid;
    let that = this
    console.log(urls.typeid)
    console.log(urls.userid)
    fetch(
      "https://shl-info.cn/front/frontc/gettypeidadmindeatail?userid=" + userid +
      "&token=" + token +
      "&workid=" + this.workid,{
        headers:{
          'token':token
        }
      }
    )
      .then(data => {
        return data.json();
      })
      .then(res => {
        console.log(res);
        this.setState({
          contentlist: res.data,
        })
      });


  }

  agin() {
    //window.location.href = "https://shl-info.cn/mobile/learningplanuser?typeid=" + + this.state.contentlist.userworks.typeid;
    this.props.history.push("/learningplanuser?typeid=" + this.state.contentlist.userworks.typeid);
  }
  componentWillUnmount() {

  }
  render() {
    // 列表

    return (
      <div className='learningplan'>
        <Header active='4'></Header>
        <div className="learingplan_user">
          <div className="learingplan_user_title borderbottom">{this.state.contentlist.userworks ? this.state.worktextlist[this.state.contentlist.userworks.typeid] : ""}</div>
          <div className="learningplan_content">{this.state.contentlist.subtext}</div>
          <div className="learningplan_content_img">
            {
              (this.state.contentlist.piclist == 0)
                ? null
                : this.state.contentlist.piclist.map((item1, index1) => {
                  return (
                    <div className=""><img src={item1.pic}></img></div>
                  )
                })
            }
            {/* <img src={require("../../static/images/certidetails.png")} alt="" />
            <img src={require("../../static/images/certificatedataback.jpg")} alt="" /> */}
          </div>

          <div className="btnlist">
            <div className="btn_right agin" onClick={() => this.agin()}>重新作业</div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    )
  }
}

export default LearningPlanback
