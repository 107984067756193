import React, { Component } from 'react'
import $ from 'jquery'
// 组件
import Header from '../../components/header'
import Footer from '../../components/footer'
import PersonImg from '../../components/personimg'
import PersonTitle from '../../components/persontitle'

import './index.css'

class CertifiCate2022 extends Component {
  constructor() {
    super()
    this.state = {
      title: "",
      name: "",
      certificatetime: "",
      certificatenum: "",
      courselist:'',
      surids:'',
      activetype:1,
      usercompany:'',
    };
  }
  getUrlkey(url) {
    var params = {};
    var urls = url.split("?");                  //console.log('1_分割url:', urls)
    var arr = urls[1].split("&");               //console.log('2_分割urls[1]:', arr)
    for (var i = 0, l = arr.length; i < l; i++) {
      var a = arr[i].split("=");                //console.log('3_遍历 arr 并分割后赋值给a:', a[0], a[1])
      params[a[0]] = a[1];                      //console.log('4_a给params对象赋值:', params)
    }                                           //console.log('5_结果:', params)
    return params;
  }
  componentDidMount() {
    this.classid = this.props.location.search.split("&")[0].slice(6);


    var urls = this.getUrlkey(window.location.href);
    this.di=0
    if(urls['di']==1){
      this.di=urls['di']
      console.log(12312312)
      
      $.ajax({
        type: "GET",
        url:
          "https://shl-info.cn/front/frontc/certificateinfobyid_di?watch=1&userid=" +
          this.props.userinfo.userid +
          "&courseid=" +
          this.classid,
        dataType: "json",
        
        headers:{'token':this.props.userinfo.token},
        cache: false
      }).then(res => {
        console.log(res)
        if (res.status === 1) {
          this.setState({
            name: res.name,
            usercompany:res.usercompany,
            title: res.coursetitle,
            certificatetime: res.certificatetime,
            certificatenum: res.certificatenum,
            courselist:res.courselist,
            surids:res.courselist.courseid,
            activetype:urls.activetype
          });
        }
      });
    }else{
      console.log(22222)
      $.ajax({
        type: "GET",
        url:
          "https://shl-info.cn/front/frontc/certificateinfobyid?watch=1&userid=" +
          this.props.userinfo.userid +
          "&courseid=" +
          this.classid,
        dataType: "json",
        headers:{'token':localStorage.getItem('token')},
        cache: false
      }).then(res => {
        if (res.status === 1) {
          this.setState({
            name: res.name,
            usercompany:res.usercompany,
            title: res.coursetitle,
            certificatetime: res.certificatetime,
            certificatenum: res.certificatenum,
            courselist:res.courselist,
            activetype:urls.activetype
          });
        }
      });
    }
    console.log(urls)
    
  }
  gotorul = () => {
    console.log(this.classid)
    if(this.di==1){
      if(this.state.surids){
        this.props.history.push("/diexamachievement?type=" + this.state.surids + "&di=1");
      }
    }else{
      this.props.history.push("/broadcast?type=" + this.classid + "&viewresults=1");
    }
  }
  componentWillUnmount() {

  }
  render() {
    return (
      <div className="personpage">
        <Header active="4"></Header>
        <PersonImg type="no"></PersonImg>
        <PersonTitle title={this.state.activetype==1?"我的证书":"观看证书"}></PersonTitle>
        <div className="wrap cerdata-margin">
          <div className="certificanvas2022">
            <img
              src={require("../../static/images/cert-bg.png")}
              alt=""
              className='cerdata-bg'
            />
            <div className="certi-container2022">
              <div className="certi-top-tips">兹证明 <span>{this.state.usercompany}<b> {this.state.name}</b></span></div>

              
    <div className="certi-middle-text1">参加西门子医疗西影力学院举办的 <span>{this.state.title} </span>(课程ID: {this.classid}) 培训，{this.state.activetype==1?"考核通过，成绩合格":"完成规定的学习"}，特发此证。</div>
              
              <div className="certi-text2">{this.state.courselist?this.state.courselist.certcontent:null}</div>
              <p className="certi-times">
                完成日期：{this.state.certificatetime}
              </p>
              <p className="certi-timesline">
                证书编号：{this.state.certificatenum}
              </p>
              <div className="certi-names">
                <div className='certi-leftname'>
                  <p className='certi-leftname-firname'><img src={require("../../static/images/cert-bg-name.png")} /> </p>
                  <p className='certi-leftname-secname'>王弢</p>
                  <p className='certi-leftname-title'>西门子医疗市场及企业传播部 副总裁</p>
                  <p className='certi-leftname-title'>西影力学院 荣誉院长</p>
                </div>
                <div className='certi-leftname smallleftname'>
                  <p className='certi-leftname-firname'><span>{this.state.courselist?this.state.courselist.teacher:null}</span></p>
                  <p className='certi-leftname-secname'>{this.state.courselist?this.state.courselist.teacher:null}</p>
                  <p className='certi-leftname-title'>{this.state.courselist?this.state.courselist.position:null}</p>
                  <p className='certi-leftname-title'>{this.state.courselist?this.state.courselist.teachercompany:null}</p>
                </div>
              </div>
            </div>
            <div className='small-logo'></div>
          </div>
          
          {this.state.activetype==1?<div className="lookmycer" onClick={() => this.gotorul()}  >查看成绩</div>:null}
         
        </div>
        <Footer></Footer>
      </div>
    );
  }
}

export default CertifiCate2022
