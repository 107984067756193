import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import classnames from 'classnames';
import { Link } from 'react-router-dom'

import './index.css'
function PersonImg(props) {
    let userid = localStorage.getItem('userid'), token = localStorage.getItem('token');
    let data = props.data

    let [ userInfo, setUserInfo ] = useState({})

    useEffect(() => {
        init()
    }, [])

    let init = () => {
    }


    return (
        <Link className={classnames("talkItem", props.mode === 'large' ? 'large' : 'small')} to={`/talkDetail?id=${data.id}`}>
            {/* <img className="talkItemImg" src={ require('../../static/images/publicimg/classimg4.png') }/> */}
            <img className="talkItemImg" src={ data.banner }/>
            <div className="talkItemContent">
                <div className="talkItemTitle">{data.title}</div>
                <div className="talkItemTips">共 {data.article_count} 条发帖<span className="talkItemTips_playcount" > {data.playcount}</span></div>
                <img className="talkItemIcon" src={require('../../static/images/icon-6.png')} />
            </div>
        </Link>
    )
}
// mapStateToProps：将state映射到组件的props中
const mapStateToProps = (state) => {
    return {
        userinfo: state.userinfo
    }
}

// mapDispatchToProps：将dispatch映射到组件的props中
const mapDispatchToProps = (dispatch) => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonImg)
