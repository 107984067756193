import React from 'react'

import './index.css'
import { Link } from 'react-router-dom'

function Speciallayer(props) {
    console.log(props)
    return (
        <div className="speciallayer">
            <div className="speciallayer_box">
                <div className="speciallayer_change" onClick={()=>props.specialchange(1)}>X</div>
                <div className='speciallayer_textbox'>
                    <div className="speciallayer_title">亲爱的西影力会员，</div>
                    <div className="speciallayer_text">我们已经为您开启专属课程推荐功能，您的精选课程页内课程都是为您精心挑选课程，如无需开启该功能，请选择“取消”。</div>
                    <div className="speciallayer_btnlist">
                        <div className="speciallayer_OK"  onClick={()=>props.specialchange(2)}>确认</div>
                        <div className="speciallayer_NO"  onClick={()=>props.specialchange(3)}>取消</div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Speciallayer
