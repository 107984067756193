import React from 'react'

import './index.css'
import { Link } from 'react-router-dom'

let userstate = false;
function CsLiveCode(props) {
    console.log(props)
    console.log(props.seriesinfo)
    console.log(props.thisshowitem)
    let selectlist = null

    if(props.isdetail){
        selectlist = props.seriesinfo.map((item,index)=>{
            return ( <div className={props.seriesid ==item.id?"changelist-changebox active":"changelist-changebox" } key={index} onClick={()=>props.changeseriesid(item.id)}>{item.name}</div>)
        })
    }
    return (
        <div className="common-cslive_code">
            <div className="common-cslive-box ">
                <h2 className="common-cslive-title">
                您还没有解锁当前课程
                    <span
                        className="common-maskclose cursor"
                        onClick={() => props.closeCsLive()}></span>
                </h2>
                
                <div className="common-textbox">
                    {props.thisshowtitle && !props.thisshowok ?<div className={props.isdetail?"textbox-title noselect":"textbox-title"}>您确定要消耗兑换码解锁<b>{props.thisshowitem.title}</b>课程吗？</div>:null}
                    {props.isdetail && props.thisshowtitle && !props.thisshowok ?<div className="common-changelist">
                        {selectlist}
                        {/* <div className="changelist-changebox">test111</div>
                        <div className="changelist-changebox">test111</div>
                        <div className="changelist-changebox">test111</div>
                        <div className="changelist-changebox">test111</div>
                        <div className="changelist-changebox">test111</div>
                        <div className="changelist-changebox">test111</div>
                        <div className="changelist-changebox">test111</div>
                        <div className="changelist-changebox">test111</div>
                        <div className="changelist-changebox">test111</div>
                        <div className="changelist-changebox">test111</div>
                        <div className="changelist-changebox">test111</div>
                        <div className="changelist-changebox">test111</div> */}
                    </div>:null}
                    {props.thisshowok ? <div className="textbox-unlock">
                        <div className="textbox-unlock-info">
                        <div className="textbox-textlist">您已成功解锁此课程系列</div>
                        <div className="textbox-textlist">解锁系列：{props.thisshowitem.title}</div>
                        <div className="textbox-textlist">有效期：{props.thisshowokitem.data.validdate}</div>
                        </div>
                    </div>:null}
                    {props.thisshowtitle?null:<div>
                        <div className="common-cslive-fir">
                            {/* <p>本课程为收费课程<br></br>
请输入线下购买时收到的兑换码解锁观看权限</p> */}
本课程为收费课程，<br></br>请输入线下购买课程获得的兑换码：</div>
                        <input 
                            className="common-cslive-input" 
                            type="text"
                            onChange={props.valueChange}
                            value={props.cardcode}
                            placeholder={props.name_place}
                        ></input>
                        <div className="common-cslive-error">{props.cslayertext}</div>
                        <div className="common-cslive-tipsinfo none">或者前往“西门子医疗”微信公众号，点击西影力学院，完成个人购买</div>
                        {/* <div className="common-cslive-text">您还没有兑换码？点击下方链接获取：</div> */}
                        {/* <a href="https://www.siemens-healthineers.cn" target="_blank" className="common-cslive-link">https://www.siemens-healthineers.cn</a> */}
                    </div>}
                    <div className="common-cslive-btnbox">
                        {props.thisshowtitle?null:<div className="common-cslive-btn addnew" onClick={()=>{props.submitcodechange()}}>确认</div>}
                        {props.thisshowtitle?<div className="common-cslive-btn addnew" onClick={()=>props.submitcode()}>
                            确认
                        </div>:null}
                    </div>

                    <div className="common-cslive-btnbuy" onClick={()=>props.gobuy()}>没有兑换码我要购买</div>
                    
                </div>
            </div>
        </div >
    )
}

export default CsLiveCode
