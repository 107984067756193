import React from 'react'

import './index.css'

function SeriesList(props) {
    let list = null;
    let i = 0;
    // let allnumb=0;
    // let allovernumb=0;
    // console.log(props._item)
    // console.log(props)
    if (props._item.list) {
        list = props._item.list.map((item, index) => {
            // console.log(item)
            // console.log(index)
            // console.log(3333333)
            if (props.thisid >= 0 && i > 0) {
                // props.gotoserieslist(item.id, props._item.title, item.title);
                // i++
            }
            
           // console.log(item)
            let bi='';
            if(item.isdi == 1  || props.iscs){
                if(localStorage.getItem('isdi') ==1 || props.iscs){
                    // if(item.readstate){
                       
                    // }else{
                    //     bi=0
                    // }
                   // console.log(123123123123123123123123)
                    if(item.coursenum){
                        bi=item.passnum/item.coursenum *100;
                    }else{
                        bi = 0
                    }
                    return (
                        <li key={item.id} onClick={() => props.gotoserieslist(item.id, props._item.title, item.title,'',props.index,index)}>
                            {item.title}{props.iscs?item.isnew?item.isnew==1?<span className='hasnewcourse'></span>:"":"":""}
                            <div className={item.isdi == 1 ||  props.iscs?"idlist_line ":"none"}>
                                <span className='idlist_line_text'>{item.passnum ? item.passnum : 0}/{item.coursenum ? item.coursenum : 0}</span>
                                <span className='idlist_line_border'><i style={{width:bi+"%"}}></i></span>
                            </div>
                            <span className='typelist_ultrasonic'>二级课程</span>
                            <span className='typelist_ultrasonic_over'>已完成</span>
                            <span className='series-arrow'></span>
                        </li>
                    )
                }
            }else{
                return (
                    <li key={item.id} onClick={() => props.gotoserieslist(item.id, props._item.title, item.title,'',props.index,index)}>
                        {item.title}
                        <div className={item.isdi == 1?"idlist_line":"none"}>
                            <span className='idlist_line_text'>{item.readstate ? item.readstate.overnum : null}/{item.readstate ? item.readstate.coursenum : null}</span>
                            <span className='idlist_line_border none'><i style={{width:bi+"%"}}></i></span>
                        </div>
                        <span className='typelist_ultrasonic'>二级课程</span>
                        <span className='typelist_ultrasonic_over'>已完成</span>
                        <span className='series-arrow'></span>
                    </li>
                )
            }
            console.log(bi)
            
        })
    }
    let firstate = false;
    if (props.index == 0 && props.thisid >= 0) {
        firstate = true;
        // props.changeActive(props.index, this)
    }
    // console.log(props.thisid)
    
    return (
        <div id={'serinfolist' + props._item.id } className={props._item.active ? 'serieslist cursor active' : props.thisid >= 0 ? 'serieslist cursor ' : 'serieslist cursor '}
        // {['serieslist cursor ', 
        //     props._item.active?'active':null].join(' ')}
        >
            <h3
                className='series-title'
                onClick={(e) => props.changeActive(props.index, e)}>
                {props._item.title}{props.iscs?props._item.isnew?props._item.isnew==1?<span className='hasnewcourse'></span>:"":"":""}
                
                <img
                    src={require('../../static/images/seriesarrow.png')}
                    className="series-select" alt="" />
                {/* {props._item.isdi==1?<span className='series-title-times'>课程完成时间：</span>:null} */}
            </h3>
            {props.iscs?(
                <div className="cslist_tips">
                    <div className="cslist_numlist">
                        <span>视频数量：{props._item.coursecount?props._item.coursecount.isshipin:0}</span>
                        <span>直播数量：{props._item.coursecount?props._item.coursecount.iszhibo:0}</span>
                    </div>
                    <div className="cslist_content">
                        {/* {props._item.desc?props._item.desc:'暂无内容'} */}
                    </div>
                    <div className="cslist_btnlist">
                        <div className={props._item.islock==1?"none":"cslist_gochange"} onClick={()=>props.gotounlock(props._item,props.index)}>点击解锁</div>
                        <div className={props._item.islock == 1 ? "cslist_state active" : "cslist_state"}>{props._item.islock == 1 ? "已解锁" : "未解锁"}</div>
                    </div>
                </div>
            ):null}
           
            <ul className='series-labellist'>
                {list}
            </ul>
        </div>
    )
}

export default SeriesList
