import React, { Component } from 'react'
import $ from 'jquery'
// 组件
import Header from '../../components/header'
import Footer from '../../components/footer'
import PersonImg from '../../components/personimg'
import PersonTitle2022 from '../../components/persontitle2022'
import RecordList from '../../components/recordlist'

import './index.css'

class CertifiCate extends Component {
  constructor() {
    super()
    this.state = {
      activetype:1,
      certificatelist:[],
    };
  }
  componentDidMount() {
    this.props.change_active_two('certificate')
    this.props.change_certificate_list()
    let that=this
    $.ajax({
      type: "GET",
      url:
        "https://shl-info.cn/front/frontc/mycertificate?userid=" +
        localStorage.getItem('userid')+"&certrule=2"+
        "&changetype=" +
        localStorage.getItem('changetype'),
      dataType: "json",
      headers:{'token':localStorage.getItem('token')},
      cache: false,
      success: function (res) {
        console.log(res)
        if (res && res.status !== 2) {
            that.setState({
              certificatelist:res
            })
        }
      }
    });
  }
  componentWillUnmount() {

  }
  gochangeactive =(types)=>{
    this.setState({
      activetype:types
    })
  }
  render() {
    let list = null;
    console.log(this.props.certificate)
    if (this.props.certificate && this.state.activetype==1) {
      list = this.props.certificate.map((item,index) => {
        let jsx_dom = <span><span className="less-font">获取时间：</span>{item.time}</span>;
        return (
          <RecordList
            key={"props.certificate"+index}
            title={item.type}
            item={item}
            right={jsx_dom}
            id={item.id}
            activetype={this.state.activetype} 
            href={true}>

          </RecordList>
        )
      })
    }
    if(this.state.activetype==2 && this.state.certificatelist.length>0){
      list = this.state.certificatelist.map((item,index) => {
        let jsx_dom = <span><span className="less-font">获取时间：</span>{item.time}</span>;
        return (
          <RecordList
            key={"state.certificatelist"+index}
            title={item.type}
            right={jsx_dom}
            id={item.id}
            activetype={this.state.activetype} 
            href={true}>

          </RecordList>
        )
      })
    }
    return (
      <div className='personpage'>
        <Header active='4'></Header>
        <PersonImg type='no'></PersonImg>
        <PersonTitle2022 title='测试证书' activetype={this.state.activetype} gochangeactive = {this.gochangeactive}></PersonTitle2022>
        <div className='wrap minheightmargin'>
          {list}
          {this.state.activetype==2 && this.state.certificatelist.length<=0?<div className='nocontent'>暂无内容</div>:null}
          {this.state.activetype==1 && this.props.certificate.length<=0?<div className='nocontent'>暂无内容</div>:null}
        </div>
        <Footer></Footer>
      </div>
    )
  }
}

export default CertifiCate
