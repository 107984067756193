import React, { Component } from 'react';
import $ from 'jquery'
import './index.css';
import Scorelayer from '../../components/scorelayer'

class Asklist extends Component {
  constructor(props) {
    super(props)
    this.state = {
      talkvalue: '',

      addsrorestate: false,
      addsroretext: '',
      addsrore: 0,
    }
  }
  valueChange(e) {
    this.setState({
      talkvalue: e.target.value
    })
  }
  talksubmit() {
    let athat = this;
    if (this.state.talkvalue && this.subfalg) {
      this.subfalg = false;
      var type = 9;
      $.ajax({
        type: "POST",
        url:
          "https://shl-info.cn/front/score/addlog?userid=" +
          localStorage.getItem('userid') +
          "&usertype=" +
          localStorage.getItem('usertype'),
        data: JSON.stringify({
          scoreid: type,
          userid: localStorage.getItem('userid'),
          usertype: localStorage.getItem('usertype'),
          courseid: this.props.classid
        }),
        dataType: "json",

        headers: { 'token': localStorage.getItem('token') },
        cache: false
      })
        .catch(error => {
          console.log(error);
        })
        .then(res => {
          if (res.code == 200) {
            athat.setState({
              addsrorestate: true,
              addsroretext: '提交问题',
              addsrore: res.score,
            })
            setTimeout(() => {
              athat.setState({
                addsrorestate: false,
              })
            }, 2000)
          }
        });


      $.ajax({
        type: "POST",
        url:
          "https://shl-info.cn/front/frontc/addtalk?userid=" +
          this.props.userinfo.userid +
          "&courseid=" +
          this.props.classid,
        data: JSON.stringify({
          name: this.props.userinfo.info.name,
          type: "2",
          src: this.props.userinfo.pic,
          talk: this.state.talkvalue
        }),
        dataType: "json",

        headers: { 'token': this.props.userinfo.token },
        cache: false
      })
        .catch(error => {
          this.subfalg = true;
          console.log(error);
        })
        .then(res => {
          if (res.status === 1) {
            this.setState({
              talkvalue: ""
            });
            this.props.success();
            this.subfalg = true;
          }
        });
    }
  }
  componentDidMount() {
    this.subfalg = true;
  }
  componentWillUnmount() {

  }
  render() {
    let asklist = this.props.videoquestion.map((item, index) => {
      let thisanswer = null;
      if (item.answer) {
        thisanswer = item.answer.map((answer, answerindex) => (
          <div key={answerindex} className="ask-answer">
            <h4>
              回复<strong>{answer.name}</strong>：<span>{answer.time}</span>
            </h4>
            <p className="askanswer-talk">{answer.talk}</p>
          </div>
        ));
      }
      return (
        <div
          key={index}
          className={item.type === 2 ? "ask-li active" : "ask-li"}
        >
          <span className="ask-img">
            <img src={item.src} alt="" />
          </span>
          <div className="ask-textcontain">
            <h3 className="ask-name">{item.name}</h3>
            <p className="ask-talk">{item.talk}</p>
            <p className="ask-time">{item.time}</p>
            {thisanswer}
          </div>
        </div>
      );
    });
    let addsrorelayer = null;
    if (this.state.addsrorestate) {
      addsrorelayer = <Scorelayer scorenum={this.state.addsrore} showtext={this.state.addsroretext}></Scorelayer>
    }
    return (
      <div className={this.props.active ? "ask-scroll active" : "ask-scroll"}>
        {addsrorelayer}
        {/* <Scorelayer scorenum={this.state.addsrore} showtext={this.state.addsroretext}></Scorelayer> */}
        {/* <Scorelayer></Scorelayer> */}
        <div className="wrap">
          <div className="ask-container">
            {asklist}
          </div>
          <div className="online-submit">
            <textarea
              value={this.state.talkvalue}
              className="online-input"
              onChange={(e) => this.valueChange(e)} />
            <div
              className="submit-talk touchCss"
              onClick={(e) => this.talksubmit(e)}>发送</div>
          </div>
        </div>
      </div>
    )
  }
}

export default Asklist;
