import React, { Component } from 'react'
// 组件
import Header from '../../components/header'
import Footer from '../../components/footer'
import PersonImg from '../../components/personimg'
import LayoutClass from '../../components/layoutclass'
import CLassLabel from '../../components/classlabel'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  change_active_two,
} from '../../store/action'

import './index.css'

class LearningPlan extends Component {
  constructor() {
    super();

    this.state = {
      personallist: '',
      active: 0,
      iscroll: null,
      thisactive: null,
      thisnum: '0,',
      usertrain: null,
      typeid1: null,
      typeid2: null,
      typeid3: null,
      typeid4: null,
      typeid5: null,
      typeid6: null,
      typeid7: null,
      typeid8: null,
      typeid9: null,
      fen1: 0,
      fen2: 0,
      fen3: 0,
    }
  }

  componentDidMount() {
    this.props.change_person_classlist()

    this.props.change_active_two("learnplanuser")
    let userid = (localStorage.getItem('userid'));
    let token = (localStorage.getItem('token'));
    let that = this
    fetch(
      "https://shl-info.cn/front/frontc/getuserworks?userid=" + userid ,{
        headers:{
          'token':token
        }
      }
    )
      .then(data => {
        return data.json();
      })
      .then(res => {
        if (res.status == -1) {
          window.location.href = "playback";
          return false;
        }
        console.log(res.userworks)
        this.setState({
          usertrain: res.usertrain
        })
        if (res.userworks.length > 0) {

          var fen1 = 0;
          var fen2 = 0;
          var fen3 = 0;
          for (var i = 0; i < res.userworks.length; i++) {
            var atype = "typeid" + (res.userworks[i].typeid);
            var btype = "typeid" + (res.userworks[i].typeid) + "userworkreply";
            //console.log(res.userworks[i].userworkreply.id)
            if (res.userworks[i].typeid == 1) {
              fen1++;
            }
            if (res.userworks[i].typeid == 2 && res.userworks[i].score > 0) {
              fen1++;
            }
            if (res.userworks[i].typeid == 3 && res.userworks[i].score > 0) {
              fen1++;
            }
            if (res.userworks[i].typeid == 4) {
              fen2++;
            }
            if (res.userworks[i].typeid == 5 && res.userworks[i].score > 0) {
              fen2++;
            }
            if (res.userworks[i].typeid == 6 && res.userworks[i].score > 0) {
              fen2++;
            }
            if (res.userworks[i].typeid == 7) {
              fen3++;
            }
            if (res.userworks[i].typeid == 8 && res.userworks[i].score > 0) {
              fen3++;
            }
            if (res.userworks[i].typeid == 9 && res.userworks[i].score > 0) {
              fen3++;
            }
            let userworkreply = false;
            if (res.userworks[i].userworkreply.id) {
              userworkreply = true;
            }
            that.setState({
              [atype]: res.userworks[i],
              [btype]: userworkreply,
              fen1: fen1,
              fen2: fen2,
              fen3: fen3,
            })
          }
        }
      });
  }
  componentWillUnmount() {

  }

  jumppages(e) {
    let atype = "typeid" + e;
    let btype = "typeid" + e + "userworkreply";
    let typeuser = (this.state[atype]);
    let typeuserworkreply = (this.state[btype]);
    if (!typeuser) {
      //window.location.href = "https://shl-info.cn/mobile/learningplanuser?typeid=" + e;
      this.props.history.push("/learningplanuser?typeid=" + e);
    } else if (typeuser.score > 0) {
      console.log('已完成')
    } else if (typeuser.score <= 0 && typeuserworkreply) {
      console.log(typeuser.userworkreply.id)
      this.props.history.push("/learningplanback?workid=" + typeuser.userworkreply.id);
      console.log('有反馈')
    } else {
      //window.location.href = "https://shl-info.cn/mobile/learningplanuser?typeid=" + e;
      this.props.history.push("/learningplanuserlook?typeid=" + e);
      console.log('已提交没有反馈')
    }
    //this.props.history.push("/learningplanuser?typeid=" + e);
    console.log(e)
  }


  changepage(e) {
    console.log(this.state.typeid1)
    var newnum = e + ',';
    let num = this.state.thisnum;
    console.log(num)
    if (num.indexOf(newnum) != -1) {
      var a = num.replace(newnum, '');
      this.setState({
        thisnum: a,
      })
    } else {
      var a = num + newnum;
      this.setState({
        thisnum: a,
      })
    }
    // this.refresh()
    //console.log(e)
  }


  render() {
    // 列表

    return (
      <div className='learningplan'>
        <Header active='4'></Header>
        <div className="learingplan_list">
          <ul className="learningplan_userlist">
            <li>
              <span className="learningplan_left">启动大会</span>
              <span className="learningplan_right">{this.state.usertrain ? this.state.usertrain.starter : ""}</span>
            </li>
            <li>
              <span className="learningplan_left">线上培训</span>
              <span className="learningplan_right">{this.state.usertrain ? this.state.usertrain.onlinetrain : ""}</span>
            </li>
          </ul>
          <ul className="learningplan_userlist">
            <li>
              <span className="learningplan_left">面授培训1</span>
              <span className="learningplan_right">{this.state.usertrain ? this.state.usertrain.facelearning : ""}</span>
            </li>
          </ul>
          <ul className={this.state.thisnum.indexOf("1,") != -1 ? "learningplan_userlist show" : "learningplan_userlist"}>
            <div className={this.state.thisnum.indexOf("1,") != -1 ? "learningplan_userlist_title active" : "learningplan_userlist_title"} onClick={() => this.changepage(1)}>
              <span className="learningplan_left">新员工作业</span>
              <span className="learningplan_middle">1/2</span>
              <span className="learningplan_right icon"><img src={require("../../static/images/plan_arrow.png")} alt="" /></span>
            </div>
            <li onClick={() => this.jumppages(1)}>
              <span className="learningplan_left">HPS作业提交</span>
              <span className="learningplan_right">
                {this.state.typeid1 ?
                  this.state.typeid1.score <= 0 ? this.state.typeid1userworkreply ? "已反馈" : "已提交" : this.state.typeid1 ? (<span>已完成<span className="blue">  得分: {this.state.typeid1.score}</span>  </span>) : ""
                  :
                  "未提交"
                }
              </span>
            </li>
            <li onClick={() => this.jumppages(2)} style={{display:"none"}}>
              <span className="learningplan_left">沙龙1-1B</span>
              <span className="learningplan_right">
                {this.state.typeid2 ?
                  this.state.typeid2.score <= 0 ? this.state.typeid2userworkreply ? "已反馈" : "已提交" : this.state.typeid2 ? (<span>已完成<span className="blue">  得分: {this.state.typeid2.score}</span>  </span>) : ""
                  :
                  "未提交"
                }
              </span>
            </li>
            <li onClick={() => this.jumppages(3)}>
              <span className="learningplan_left">PLP作业提交</span>
              <span className="learningplan_right">
                {this.state.typeid3 ?
                  this.state.typeid3.score <= 0 ? this.state.typeid3userworkreply ? "已反馈" : "已提交" : this.state.typeid3 ? (<span>已完成<span className="blue">  得分: {this.state.typeid3.score}</span>  </span>) : ""
                  :
                  "未提交"
                }
              </span>
            </li>
          </ul>
          <ul className={this.state.thisnum.indexOf("2,") != -1 ? "learningplan_userlist show" : "learningplan_userlist"}>
            <div className={this.state.thisnum.indexOf("2,") != -1 ? "learningplan_userlist_title active" : "learningplan_userlist_title"} onClick={() => this.changepage(2)}>
              <span className="learningplan_left">沙龙2</span>
              <span className="learningplan_middle">1/3</span>
              <span className="learningplan_right icon"><img src={require("../../static/images/plan_arrow.png")} alt="" /></span>
            </div>
            <li onClick={() => this.jumppages(4)}>
              <span className="learningplan_left">沙龙2-1A</span>
              <span className="learningplan_right">
                {this.state.typeid4 ?
                  this.state.typeid4.score <= 0 ? this.state.typeid4userworkreply ? "已反馈" : "已提交" : this.state.typeid4 ? (<span>已完成<span className="blue">  得分: {this.state.typeid4.score}</span>  </span>) : ""
                  :
                  "未提交"
                }
              </span>
            </li>
            <li onClick={() => this.jumppages(5)}>
              <span className="learningplan_left">沙龙2-1B</span>
              <span className="learningplan_right">
                {this.state.typeid5 ?
                  this.state.typeid5.score <= 0 ? this.state.typeid5userworkreply ? "已反馈" : "已提交" : this.state.typeid5 ? (<span>已完成<span className="blue">  得分: {this.state.typeid5.score}</span>  </span>) : ""
                  :
                  "未提交"
                }
              </span>
            </li>
            <li onClick={() => this.jumppages(6)}>
              <span className="learningplan_left">沙龙2-2</span>
              <span className="learningplan_right">

                {this.state.typeid6 ?
                  this.state.typeid6.score <= 0 ? this.state.typeid6userworkreply ? "已反馈" : "已提交" : this.state.typeid6 ? (<span>已完成<span className="blue">  得分: {this.state.typeid6.score}</span>  </span>) : ""
                  :
                  "未提交"
                }
              </span>
            </li>
          </ul>
          <ul className={this.state.thisnum.indexOf("3,") != -1 ? "learningplan_userlist show" : "learningplan_userlist"}>
            <div className={this.state.thisnum.indexOf("3,") != -1 ? "learningplan_userlist_title active" : "learningplan_userlist_title"} onClick={() => this.changepage(3)}>
              <span className="learningplan_left">沙龙3</span>
              <span className="learningplan_middle">1/3</span>
              <span className="learningplan_right icon"><img src={require("../../static/images/plan_arrow.png")} alt="" /></span>
            </div>
            <li onClick={() => this.jumppages(7)}>
              <span className="learningplan_left">沙龙3-1A</span>
              <span className="learningplan_right">

                {this.state.typeid7 ?
                  this.state.typeid7.score <= 0 ? this.state.typeid7userworkreply ? "已反馈" : "已提交" : this.state.typeid7 ? (<span>已完成<span className="blue">  得分: {this.state.typeid7.score}</span>  </span>) : ""
                  :
                  "未提交"
                }
              </span>
            </li>
            <li onClick={() => this.jumppages(8)}>
              <span className="learningplan_left">沙龙3-1B</span>
              <span className="learningplan_right">

                {this.state.typeid8 ?
                  this.state.typeid8.score <= 0 ? this.state.typeid8userworkreply ? "已反馈" : "已提交" : this.state.typeid8 ? (<span>已完成<span className="blue">  得分: {this.state.typeid8.score}</span>  </span>) : ""
                  :
                  "未提交"
                }
              </span>
            </li>
            <li onClick={() => this.jumppages(9)}>
              <span className="learningplan_left">沙龙3-2</span>
              <span className="learningplan_right">

                {this.state.typeid9 ?
                  this.state.typeid9.score <= 0 ? this.state.typeid9userworkreply ? "已反馈" : "已提交" : this.state.typeid9 ? (<span>已完成<span className="blue">  得分: {this.state.typeid9.score}</span>  </span>) : ""
                  :
                  "未提交"
                }
              </span>
            </li>
          </ul>
        </div>
        <Footer></Footer>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    labellist: state.common_labellist,
    active_two: state.active_two,
    common_search: state.common_search,
    userinfo: state.userinfo
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    change_active_two(data) {
      dispatch(change_active_two(data))
    }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LearningPlan))
