import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import Iscroll from 'iscroll/build/iscroll-probe';
import axios from 'axios';
import TalkItem from '../talkItem'

import './index.css'
function TalkList(props) {
    let userid = localStorage.getItem('userid'), token = localStorage.getItem('token');

    let [talkList, setTalkList] = useState([])

    useEffect(() => {
        init()
    }, [])

    let init = () => {
        getTalkList()
        pageInit()
    }

    // 获取帖子列表
    let getTalkList = async () => {
        let res = await axios({
            method: 'get',
            headers: {
                "token": token
            },
            url: 'https://shl-info.cn/front/bbs/topicList',
            params: {
                user_id: userid,
            }
        })
        let data = res.data
        setTalkList(data)
    }

    let pageInit = () => {
    }

    let talkItemDom = () => {
        if (talkList.length === 0) {
            return <div className="talkListNotTips">没有话题哦~</div>
        }
        return talkList.map((item, index) => {
            return <TalkItem data={item} key={index}></TalkItem>
        })
    }

    return (
        <section className="talk">
            { talkItemDom()}
        </section>
    )
}
// mapStateToProps：将state映射到组件的props中
const mapStateToProps = (state) => {
    return {
        userinfo: state.userinfo
    }
}

// mapDispatchToProps：将dispatch映射到组件的props中
const mapDispatchToProps = (dispatch) => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TalkList)
