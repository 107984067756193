import React, { Component } from 'react'

import $ from 'jquery'
// 组件
import Headerfir from '../../components/headerfir'
import Header from '../../components/header'
import Footer from '../../components/footer'
import Banner from '../../components/banner'
import SelectBox from '../../components/selectbox'
import ClassList from '../../components/classlist'
import SeriesList from '../../components/serieslist'
import Question from '../../components/question'
import CsLiveCode from '../../components/cslivecode'


import Iscroll from 'iscroll/build/iscroll-probe';

import './index.css'


let startstate = false;
class Home extends Component {
  constructor(props) {
    super(props)
    this.changeSelect = this.changeSelect.bind(this)
    this.changeActive = this.changeActive.bind(this)
    this.changeActive_cs = this.changeActive_cs.bind(this)
    this.gotounlock = this.gotounlock.bind(this)

    
    
    this.changeQuestion = this.changeQuestion.bind(this)
    this.gotoserieslist = this.gotoserieslist.bind(this)
    this.gotoserieslist_cs = this.gotoserieslist_cs.bind(this)
    
    this.changeSelectthi = this.changeSelectthi.bind(this)
    this.changecsxilieids =this.changecsxilieids.bind(this)
    this.goexam = this.goexam.bind(this)

    this.valueChange =this.valueChange.bind(this)
    

    
    this.closeCsLive =this.closeCsLive.bind(this)
    this.gobuy =this.gobuy.bind(this)
    this.submitcode =this.submitcode.bind(this)
    this.submitcodechange =this.submitcodechange.bind(this)
    
    
    

    this.state = {
      isout:true,
      gotoseries: false,
      seriesText: '',
      thisusertype: false,
      isshowstate:false,
      csxilieids:0,
      csxilieids_sec:0,
      csxilieids_thi:0,
      csshowindex:0,
      thisshowtitle:false,
      thisshowitem:{},
      thisshowok:false,
      thisshowokitem:{},
      seriesid:'',
      islock:0,
      csxilielist :[],
      csxiliecourselist:[],
      cslayerstate:false,
      cslayertext:'',
      iscroll: null,
      thistypenums:6,
      cardcode:'',
      thisid: -1,
      
      menu:'',
      selectlist: [],
      selectlisttextlist: ["产品·应用","疾病·诊疗","合作·共创","科研·洞见"],
      selectlisttextlistinfo: [
      {
        id: 6,
        type: 'project',
        title: '产品·应用',
        icon: require('../../static/images/homeicon/footer_icon_add1_2022.png'),
        icon_act: require('../../static/images/homeicon/footer_icon_add1_2022_active.png')
      },{
        id: 7,
        type: 'disease',
        title: '疾病·诊疗',
        icon: require('../../static/images/homeicon/footer_icon_add2_2022.png'),
        icon_act: require('../../static/images/homeicon/footer_icon_add2_2022_active.png')
      },{
        id: 9,
        type: 'research',
        title: '科研·洞见',
        icon: require('../../static/images/homeicon/footer_icon_add4_2022.png'),
        icon_act: require('../../static/images/homeicon/footer_icon_add4_2022_active.png')
      },{
        id: 8,
        type: 'customer',
        title: '合作·共创',
        icon: require('../../static/images/homeicon/footer_icon_add3_2022.png'),
        icon_act: require('../../static/images/homeicon/footer_icon_add3_2022_active.png')
      }],
      
      //课程中心新加最热和最新课程
      hotandnewlistinfo: 
      [{
          id: 10,
          type: 'newcourse',
          title: '最新课程',
          icon: require('../../static/images/homeicon/icon_1_1.png'),
          icon_act: require('../../static/images/homeicon/icon_1_2.png')
        },{
          id: 11,
          type: 'hotcourse',
          title: '最热课程',
          icon: require('../../static/images/homeicon/icon_2_1.png'),
          icon_act: require('../../static/images/homeicon/icon_2_2.png')
        }],

        
        hotlist:[],
        newlist:[],
        // innerlist:[{
        //   id: 12,
        //   type: 'usertype',
        //   title: '员工专区',
        //   icon: require('../../static/images/homeicon/icon_5_1.png'),
        //   icon_act: require('../../static/images/homeicon/icon_5_2.png')
        // }],
        innerlist:[],
      
        
      
      dinumlist:{},

      seriesbannerlist:[],
      isupdatestate:false,
      ischange:false,
      


    }
  }

  static defaultProps = {
    selectlist: [
      // {
      //   id: 2,
      //   type: 'series',
      //   title: '系列课程',
      //   icon: require('../../static/images/playing_icon_05.png'),
      //   icon_act: require('../../static/images/playing_icon_05_act.png')
      // },
      // {
      //   id: 0,
      //   type: 'review',
      //   title: '精彩回顾',
      //   icon: require('../../static/images/playing_icon_03.png'),
      //   icon_act: require('../../static/images/playing_icon_03_act.png')
      // },
      {
        id: 1,
        type: 'hotback',
        title: '精品课程',
        icon: require('../../static/images/homeicon/icon_3_1.png'),
        icon_act: require('../../static/images/homeicon/icon_3_2.png')
      },
      {
        id: 3,
        type: 'question',
        title: '常见问题',
        icon: require('../../static/images/homeicon/icon_4_1.png'),
        icon_act: require('../../static/images/homeicon/icon_4_2.png')
      },
      {
        id: 5,
        type: 'zhidaocs',
        title: '收费课程（合同专享）',
        icon: require('../../static/images/playing_icon_05.png'),
        icon_act: require('../../static/images/playing_icon_05_act.png')
      },
      // {
      //   id: 4,
      //   type: 'csmini',
      //   title: '随身V课',
      //   icon: require('../../static/images/playing_icon_05.png'),
      //   icon_act: require('../../static/images/playing_icon_05_act.png')
      // }
    ],
  }
  // 切换导航
  changeSelect(id, keysword,ids) {
    console.log(id, keysword,ids)
    if(ids==12){
      if( localStorage.getItem('changetype') !=1){
        
        localStorage.setItem('changetype', 1)
      }else{
        localStorage.setItem('changetype', '')
      }
      this.props.change_active_two('project')
      this.changeSelectthi(6)
      return;
    }
    //var keysword = ''
    // if (id == 1) {
    //   keysword = '精彩回顾';
    // }
    // if (id == 2) {
    //   keysword = '精品课程';
    // }
    // if (id == 3) {
    //   keysword = '系列课程';
    // }
    // if (id == 4) {
    //   keysword = '常见问题';
    // }
    if( id === 'research'  || id === 'customer'  || id === 'disease'  || id === 'project' ){
      // //console.log(3333)
      this.changeSelectthi(ids)

      if(ids>=6 && ids<=9){
        console.log(ids)
        this.setState({
          seriesbannerlist:[],
          isupdatestate:false,
        })
      }
      
    }

    window.addreadlogs(keysword)
    //console.log(id,keysword)
    if(id=='csmini'){
      this.setState({
        gotoseries: false
      })
      this.props.change_active_series(0)
      this.props.change_back_data(4)
      this.setState({
        thistypenums: 4,
      })
    }
    
    if(id=='series'){
      this.setState({
        gotoseries: false
      })
      this.props.change_active_series(0)
      this.props.change_back_data(6)
      this.setState({
        thistypenums: 6,
      })
    }

    

    //console.log(id)
    this.props.change_active_two(id)
    let that=this
    setTimeout(function () {
      if($('.series_scroll').length>0){
        //console.log(222222,$('.series_scroll').length,"$('.series_scroll').length")
        that.setState({
          iscroll: new Iscroll('.series_scroll', {
            mouseWheel: true,
            scrollbars: true,
            scrollX: true,
            scrollY: false,
            scrollbars: false,
          }),
        })
      }
      ////console.log(that.state.thisid)

    }, 1000)

    //if(this.props.active_two === 'zhidaocs' && id=='zhidaocs'){
    if(id=='zhidaocs'){  
      //console.log(11111)

      fetch(
        "https://shl-info.cn/front/Monitor/addMonitorData?userid="+localStorage.getItem('userid')+"&intMonitortype=2"+"&pagetype=" +"&seriesid=" +"&source=西影力"   
      )
        .then(data => {
          return data.json();
        })
        .then(res => {
        })
      this.gotoserieslist()
    }


  }

  gotounlock =(item,index)=>{
    //console.log(item,index)
    //console.log(this.state.csxilieids_thi)
    //console.log(this.state.csxilieids_sec)
    //console.log(22222)
    fetch(
      "https://shl-info.cn/front/Monitor/addMonitorData?userid="+localStorage.getItem('userid')+"&intMonitortype=4"+"&pagetype=系列页" +"&seriesid="+item.id +"&source=西影力"   
    )
      .then(data => {
        return data.json();
      })
      .then(res => {
      })

    this.setState({
      seriesid:item.id,
      cslayerstate:true,
      thisshowitem:item,
      csshowindex:index,
    })
  }


  closeCsLive (){
    //console.log(1111)
    this.setState({
      cslayerstate:false,
      seriesid:'',
      thisshowtitle:false,
      thisshowok:false,
    })
  }
  submitcodechange(){
    //console.log(1111)
    if(this.state.cardcode!=''){
      this.setState({
        thisshowtitle:true,
      })
    }else{
      this.setState({
        cslayertext:"请输入兑换码",
      })
    }
  }
  gobuy(){
    //console.log(1111,this.state.seriesid)
    this.props.history.push('/shopmalldetail?typeids='+this.state.seriesid)
  }
  submitcode(){
    //alert(111)
    //console.log(33332222211111)
    //console.log(this.state.cardcode)
    if(this.state.thisshowtitle && this.state.thisshowok){
      this.setState({
        cslayerstate:false,
        thisshowtitle:false,
        thisshowok:false,
      })
      return false;
    }
    let that=this
    if(this.state.cardcode!=''){
      $.ajax({
        type: "POST",
        url:
          "https://shl-info.cn/front/Cscardcode/buyCourse",
          data: JSON.stringify({
            seriesid: this.state.seriesid,
            cardcode: this.state.cardcode,
            userid: localStorage.getItem('userid'),
          }),
        dataType: "json",
        
        headers:{'token':localStorage.getItem('token')},
        cache: false,
        success: function (res) {
          if(res.code==200){
            that.setState({
              cslayertext:'',
              cslayerstate:true,
              islock:1,
              thisshowok:true,
              thisshowokitem:res
            })
            let userlist = that.state.csxilielist
                userlist[that.state.csxilieids].list[that.state.csshowindex]['islock']=1;
                that.setState({
                  csxilielist:userlist
                })
                //console.log(userlist)
          }else{
              that.setState({
                cslayertext:res.msg,
                thisshowtitle:false,
              })
          }
          // that.setState({
          //   csxiliecourselist:res.data.data
          // })
        }
      })
      // $.ajax({
      //   type: "GET",
      //   url:
      //     "https://shl-info.cn/front/Cscourse//buyCourse?seriesid=" +
      //     +"&cardcode="+this.state.cardcode+"&userid="+localStorage.getItem('userid'),
      //   dataType: "json",
      //   headers:{'token':localStorage.getItem('token')},
      //   cache: false,
      //   success: function (res) {
      //     //console.log(res)
      //     // that.setState({
      //     //   csxiliecourselist:res.data.data
      //     // })
      //   }
      // });
    }
  }
  changecsxilieids (id,index){
      //console.log(id)
      //console.log(index)
      this.setState({
        csxilieids:index
      })

        //console.log(this.state.csxilielist)
        let  url = "https://shl-info.cn/front/Diexam/getSeriesData?xilieid=" + this.state.csxilielist[index]['id']+"&userid="+localStorage.getItem('userid')+"&iscs=1"+"&typeid="+this.state.thistypenums;
        // if(this.props.active_two === 'zhidaocs'){
        //     url = "https://shl-info.cn/front/Diexam/getSeriesData?xilieid=" + this.state.csxilielist[id]['id']+"&userid="+localStorage.getItem('userid')+"&iscs=1";
        // }
        //console.log(url)
        let that=this
        $.ajax({
          type: "GET",
          url:url,
          dataType: "json",
          headers:{'token':localStorage.getItem('token')},
          
          success: function (res) {
            //console.log(res)
            let newreslist = res.data.seriestwolist
            let new_reviewClassArr = that.props.reviewClassArr
            new_reviewClassArr[index]['coursenum'] = res.data.coursenum;
            new_reviewClassArr[index]['passnum'] = res.data.passnum;
            let newslist =  new_reviewClassArr[index].list
            for(var i=0;i<newslist.length;i++){
              for(var k=0;k<newslist[i]['list'].length;k++){
                //console.log(newslist[i]['list'])
                //console.log(newslist[i]['list'][k])
                //console.log(newreslist)
                //console.log(newreslist[newslist[i]['list'][k]['id']])
                if(newreslist[newslist[i]['list'][k]['id']]){
                  newslist[i]['list'][k]['coursenum'] =newreslist[newslist[i]['list'][k]['id']]['coursenum']
                  newslist[i]['list'][k]['passnum'] =newreslist[newslist[i]['list'][k]['id']]['passnum']
                }
              }
              // if(newreslist[newslist[i]['id']]){
              //   newslist[i]['coursenum'] =newreslist[newslist[i]['id']]['coursenum']
              //   newslist[i]['passnum'] =newreslist[newslist[i]['id']]['passnum']
              // }
            }
            //console.log(newslist)
            new_reviewClassArr[index].list = newslist;
          //new_reviewClassArr[id].list
            //console.log(new_reviewClassArr)
            that.props.changeActive(new_reviewClassArr)
          }
        });
  }
  valueChange(e) {
    
    this.setState({
      cardcode: e.target.value,
      cslayertext:'',
    })
    
  }

  //切换新的导航
  changeSelectthi(id) {
    ////console.log(this.props)
    ////console.log(22222)


    var keysword = ''
    if (id == 1) {
      keysword = '按产品划分';
    }
    if (id == 2) {
      keysword = '按疾病划分';
    }
    if (id == 3) {
      keysword = '按科室划分';
    }
    

    if (id == 6) {
      keysword = '产品中心';
    }
    if (id == 7) {
      keysword = '疾病中心';
    }
    if (id == 8) {
      keysword = '客户中心';
    }
    if (id == 9) {
      keysword = '课程中心';
    }
    window.addreadlogs(keysword)
    //console.log(id)
    
    $('.series-list').css('width', '99999px')
    this.props.change_active_series(0)
    this.props.change_back_data(id)
    this.gotoserieslist()
    //this.props.change_reviewClassArrbyid(id)

    
    // this.props.change_active_two("series")


    this.setState({
      thistypenums: id,
    })
  }
  headerchangeactive = ()=>{
    this.props.change_active_series(0)
    this.changecsxilieids('',0)
  }

  changeseries(e, id) {
    console.log(e,id)
    console.log("changeseries")
    
    this.setState({
      thisid: -1,
      menu:'',
      seriesbannerlist:[],
    })
    this.getbannerbyseriesid(this.props.reviewClassArr[id]['id'])
    //console.log(e, id)
    if (e.target) {
      window.addreadlogs(e.target.innerText, 1)
    }
    ////console.log(id)

    this.props.change_active_series(id)
    let that=this
    //console.log(this.props.reviewClassArr[id])
    //console.log(this.props.active_two)
    if(this.props.reviewClassArr[id]['isdi'] == 1 ){
      let url =  "https://shl-info.cn/front/Diexam/getSeriesData?xilieid=" + this.props.reviewClassArr[id]['id']+"&userid="+localStorage.getItem('userid')+"&typeid="+this.state.thistypenums;
      // if(this.props.active_two === 'zhidaocs'){
      //     url = "https://shl-info.cn/front/Diexam/getSeriesData?xilieid=" + this.state.csxilielist[id]['id']+"&userid="+localStorage.getItem('userid')+"&iscs=1";
      // }
      //console.log(url)
      $.ajax({
        type: "GET",
        url:url,
        dataType: "json",
        headers:{'token':localStorage.getItem('token')},
        
        success: function (res) {
          //console.log(res)
          let newreslist = res.data.seriestwolist
          let new_reviewClassArr = that.props.reviewClassArr
          new_reviewClassArr[id]['coursenum'] = res.data.coursenum;
          new_reviewClassArr[id]['passnum'] = res.data.passnum;
          let newslist =  new_reviewClassArr[id].list
          for(var i=0;i<newslist.length;i++){
            for(var k=0;k<newslist[i]['list'].length;k++){
              //console.log(newslist[i]['list'])
              //console.log(newslist[i]['list'][k])
              //console.log(newreslist)
              //console.log(newreslist[newslist[i]['list'][k]['id']])
              if(newreslist[newslist[i]['list'][k]['id']]){
                newslist[i]['list'][k]['coursenum'] =newreslist[newslist[i]['list'][k]['id']]['coursenum']
                newslist[i]['list'][k]['passnum'] =newreslist[newslist[i]['list'][k]['id']]['passnum']
              }
            }
            // if(newreslist[newslist[i]['id']]){
            //   newslist[i]['coursenum'] =newreslist[newslist[i]['id']]['coursenum']
            //   newslist[i]['passnum'] =newreslist[newslist[i]['id']]['passnum']
            // }
          }
          //console.log(newslist)
          new_reviewClassArr[id].list = newslist;
          //new_reviewClassArr[id].list
          //console.log(new_reviewClassArr)
          that.props.changeActive(new_reviewClassArr)
        }
      });
    }
  }
  
  changeActive_cs(index){
    //console.log(index)
    let newsarray=this.state.csxilielist;
    newsarray[this.state.csxilieids].list[index]['active'] =!newsarray[this.state.csxilieids].list[index]['active']
    this.setState({
      csxilielist:newsarray,
      csxilieids_sec:index,
    })
    //console.log(newsarray)
  }
  changeActive(index) {
    let new_reviewClassArr = this.props.reviewClassArr
    var subkeywords = new_reviewClassArr[this.props.active_series].list[index]['title'];
    window.addreadlogs(subkeywords)

    new_reviewClassArr[this.props.active_series].list[index].active = !new_reviewClassArr[this.props.active_series].list[index].active
    this.props.changeActive(new_reviewClassArr)
  }
  changeQuestion(index) {
    let new_question = this.props.question
    new_question[index].active = !new_question[index].active
    this.props.change_question(new_question)
  }
  // 跳转系列课程列表页

  gotoserieslist_cs(id, parent, text,seriestype='',index,thisindex) {
    //console.log(id, parent, text,this.state.thistypenums,index,thisindex)
    let that =this
    
    
    let userlist = this.state.csxilielist;
    //console.log(userlist)
    userlist[0].list[index].list[thisindex]['isnew']=0;
    let thisnewstate = false;
    for(var i=0;i<userlist[0].list[index].list.length;i++){
      if(userlist[0].list[index].list[i]['isnew']==1){
        thisnewstate=true;
      }
    }
    if(!thisnewstate){
      userlist[0].list[index]['isnew']=0;
    }
   
    //console.log(userlist)
    if(parent){
      window.addreadlogs(parent + '---' + text)
    }
    $('.series-list').css('width', '99999px')
    this.setState({
      seriesText: parent ? (parent + ' > ' + text) : '',
      gotoseries: !this.state.gotoseries,
      csxilieids_thi:index,
      csxilielist:userlist
    })
    // let that = this
    $.ajax({
      type: "GET",
      url:
        "https://shl-info.cn/front/Cscourse/getCourseBySeries?seriesid=" +
        id+"&userid="+localStorage.getItem('userid')+"&ispc=1",
      dataType: "json",
      headers:{'token':localStorage.getItem('token')},
      cache: false,
      success: function (res) {
        //console.log(res)
        that.setState({
          csxiliecourselist:res.data.data,
          islock:res.data.islock,
        })
      }
    });

  }
  gotoserieslist(id, parent, text,seriestype='',index='') {
    //console.log("seriestitle-arrow",1111)
    //console.log(id, parent, text,this.state.thistypenums)
    
    
    

    //console.log('id, parent, text2222',22222)
    window.addreadlogs(parent + '---' + text)
    $('.series-list').css('width', '99999px')
    this.setState({
      seriesText: parent ? (parent + ' > ' + text) : '',
      gotoseries: !this.state.gotoseries
    })
    let that=this
    if(!this.state.iscroll){
      //console.log('iscrolllllll')
      
      setTimeout(function(){

        if($('.series_scroll').length>0){
          that.setState({
            iscroll: new Iscroll('.series_scroll', {
              mouseWheel: true,
              scrollbars: true,
              scrollX: true,
              scrollY: false,
              scrollbars: false,
            }),
          })
        }
      },1000)
    }else{
      //console.log(123123123)
      //console.log('iscrolllllll',123123123)
      setTimeout(function(){
        if(that.state.iscroll){
          that.state.iscroll.refresh();
        }
      },200)
    }
    if(!parent){
      this.setState({
        gotoseries: false
      })
      return false;
    }
    //this.state.thistypenums
    this.props.change_developments_data(id, this.state.thistypenums)
    if(!id){
        this.props.history.push("/homeback");
    }else{
      this.props.history.push("/homeback?reviewid="+id+"&thistypenums="+this.state.thistypenums);
      that.setState({
        iscroll: null,
      })
    }
  }

  goexam (ids){
    //console.log(ids)
    //console.log(this.state.thistypenums)
    //return false;
    this.props.history.push("/diexamdetail?id="+ids+"&types="+this.state.thistypenums);
  }

  getUrlkey(url) {
    var params = {};
    var urls = url.split("?");                  //////console.log('1_分割url:', urls)
    var arr = urls[1].split("&");               //////console.log('2_分割urls[1]:', arr)
    for (var i = 0, l = arr.length; i < l; i++) {
      var a = arr[i].split("=");                //////console.log('3_遍历 arr 并分割后赋值给a:', a[0], a[1])
      params[a[0]] = a[1];                      //////console.log('4_a给params对象赋值:', params)
    }                                           //////console.log('5_结果:', params)
    return params;
  }
  componentDidUpdate() {
    console.log(this.props.reviewClassArr)
    console.log(this.props.active_series)
    console.log(this.state.isupdatestate)
    console.log(this.state.thistypenums)
    if(this.props.reviewClassArr.length>0 && !this.state.isupdatestate){
      console.log(this.props.reviewClassArr[this.props.active_series]['id'])
      // this.getbannerbyseriesid(this.props.reviewClassArr[this.props.active_series]['id'])
      // this.setState({
      //   isupdatestate:true,
      // })
    }
    console.log("componentDidUpdate")
  }

  componentDidMount() {

    let userid = (localStorage.getItem('userid'));
    let token = (localStorage.getItem('token'));
    var fromurl = document.referrer;
    //console.log(fromurl.indexOf("website"))
    //console.log(this.props)
    //console.log(this.props.history.action)
    //console.log(fromurl)
    if(fromurl.indexOf("website")<0 && this.props.history.action !="PUSH"){
      //console.log(333444)
      if (window.location.href.indexOf('?') != -1) {
        var urls = this.getUrlkey(window.location.href);
        ////console.log(urls.id)
  
        if(!urls['sourcechannel'] && !localStorage.getItem('sourcechannel')){
          localStorage.setItem('sourcechannel',"domainurl")
        }
      }
      //localStorage.setItem('sourcechannel',"domainurl")
    }else{
      
    }
    
    //console.log(fromurl)
    //console.log(1111122222)
    
    $('.series-list').css('width', '99999px')
    let that = this

    if (userid && token) {

      // 验证是否过期
      $.ajax({
        type: "GET",
        url: "https://shl-info.cn/front/user/checkuser?userid=" +
          userid +
          "&ischeckcode=1",
        dataType: "json",
        headers:{'token':token},
        success: function (res) {
          if(res.status==2){
            localStorage.setItem('userid','');
            localStorage.setItem('token','');
          }
          localStorage.setItem('iswaitype',res.list?res.list.type:'')
          if (res.list && (res.list.type == 1 || res.list.type == 2 || res.list.type == 3 || res.list.type == 5)) {
            if(res.isbindstate==1){
              that.setState({
                thisusertype: true,
                selectlist: [
                  // {
                  //   id: 2,
                  //   type: 'series',
                  //   title: '系列课程',
                  //   icon: require('../../static/images/playing_icon_05.png'),
                  //   icon_act: require('../../static/images/playing_icon_05_act.png')
                  // },
                  // {
                  //   id: 0,
                  //   type: 'review',
                  //   title: '精彩回顾',
                  //   icon: require('../../static/images/playing_icon_03.png'),
                  //   icon_act: require('../../static/images/playing_icon_03_act.png')
                  // },
                  {
                    id: 1,
                    type: 'hotback',
                    title: '精品课程',
                    icon: require('../../static/images/homeicon/icon_3_1.png'),
                    icon_act: require('../../static/images/homeicon/icon_3_2.png')
                  },
                  {
                    id: 3,
                    type: 'question',
                    title: '常见问题',
                    icon: require('../../static/images/homeicon/icon_4_1.png'),
                    icon_act: require('../../static/images/homeicon/icon_4_2.png')
                  },
                  {
                    id: 4,
                    type: 'csmini',
                    title: '随身V课',
                    icon: require('../../static/images/playing_icon_05.png'),
                    icon_act: require('../../static/images/playing_icon_05_act.png')
                  },
                  {
                    id: 5,
                    type: 'zhidaocs',
                    title: '收费课程（合同专享）',
                    icon: require('../../static/images/playing_icon_05.png'),
                    icon_act: require('../../static/images/playing_icon_05_act.png')
                  },
                ],
              })
            }else{
              that.setState({
                thisusertype: true,
                selectlist: [
                  // {
                  //   id: 2,
                  //   type: 'series',
                  //   title: '系列课程',
                  //   icon: require('../../static/images/playing_icon_05.png'),
                  //   icon_act: require('../../static/images/playing_icon_05_act.png')
                  // },
                  // {
                  //   id: 0,
                  //   type: 'review',
                  //   title: '精彩回顾',
                  //   icon: require('../../static/images/playing_icon_03.png'),
                  //   icon_act: require('../../static/images/playing_icon_03_act.png')
                  // },
                  {
                    id: 1,
                    type: 'hotback',
                    title: '精品课程',
                    icon: require('../../static/images/homeicon/icon_3_1.png'),
                    icon_act: require('../../static/images/homeicon/icon_3_2.png')
                  },
                  {
                    id: 3,
                    type: 'question',
                    title: '常见问题',
                    icon: require('../../static/images/homeicon/icon_4_1.png'),
                    icon_act: require('../../static/images/homeicon/icon_4_2.png')
                  },
                  
                  //注释掉CS微课二期
                  {
                    id: 5,
                    type: 'zhidaocs',
                    title: '收费课程（合同专享）',
                    icon: require('../../static/images/playing_icon_05.png'),
                    icon_act: require('../../static/images/playing_icon_05_act.png')
                  },
                  // {
                  //   id: 4,
                  //   type: 'csmini',
                  //   title: '随身V课',
                  //   icon: require('../../static/images/playing_icon_05.png'),
                  //   icon_act: require('../../static/images/playing_icon_05_act.png')
                  // }
                ],
              })
              console.log(res.list.type)
              if(window.location.href.indexOf('cscoursestate=1')>=0 ){
                that.setState({
                  thisusertype: true,
                  selectlist: [
                    // {
                    //   id: 2,
                    //   type: 'series',
                    //   title: '系列课程',
                    //   icon: require('../../static/images/playing_icon_05.png'),
                    //   icon_act: require('../../static/images/playing_icon_05_act.png')
                    // },
                    // {
                    //   id: 0,
                    //   type: 'review',
                    //   title: '精彩回顾',
                    //   icon: require('../../static/images/playing_icon_03.png'),
                    //   icon_act: require('../../static/images/playing_icon_03_act.png')
                    // },
                    {
                      id: 1,
                      type: 'hotback',
                      title: '精品课程',
                      icon: require('../../static/images/homeicon/icon_3_1.png'),
                      icon_act: require('../../static/images/homeicon/icon_3_2.png')
                    },
                    {
                      id: 3,
                      type: 'question',
                      title: '常见问题',
                      icon: require('../../static/images/homeicon/icon_4_1.png'),
                      icon_act: require('../../static/images/homeicon/icon_4_2.png')
                    },
                    
                    //注释掉CS微课二期
                    {
                      id: 5,
                      type: 'zhidaocs',
                      title: '收费课程（合同专享）',
                      icon: require('../../static/images/playing_icon_05.png'),
                      icon_act: require('../../static/images/playing_icon_05_act.png')
                    },
                    // {
                    //   id: 4,
                    //   type: 'csmini',
                    //   title: '随身V课',
                    //   icon: require('../../static/images/playing_icon_05.png'),
                    //   icon_act: require('../../static/images/playing_icon_05_act.png')
                    // }
                  ],
                })
              }
              
            }
            
          } else {
            // that.setState({
            //   isout:false,
            // })
            if(localStorage.getItem('changetype')==1){
              that.setState({
                isout:false,
                selectlist: [
                  // {
                  //   id: 2,
                  //   type: 'series',
                  //   title: '系列课程',
                  //   icon: require('../../static/images/playing_icon_05.png'),
                  //   icon_act: require('../../static/images/playing_icon_05_act.png')
                  // },
                  // {
                  //   id: 0,
                  //   type: 'review',
                  //   title: '精彩回顾',
                  //   icon: require('../../static/images/playing_icon_03.png'),
                  //   icon_act: require('../../static/images/playing_icon_03_act.png')
                  // },
                  {
                    id: 1,
                    type: 'hotback',
                    title: '精品课程',
                    icon: require('../../static/images/homeicon/icon_3_1.png'),
                    icon_act: require('../../static/images/homeicon/icon_3_2.png')
                  },
                  {
                    id: 3,
                    type: 'question',
                    title: '常见问题',
                    icon: require('../../static/images/homeicon/icon_4_1.png'),
                    icon_act: require('../../static/images/homeicon/icon_4_2.png')
                  },
                  
                  //注释掉CS微课二期
                  {
                    id: 5,
                    type: 'zhidaocs',
                    title: '收费课程（合同专享）',
                    icon: require('../../static/images/playing_icon_05.png'),
                    icon_act: require('../../static/images/playing_icon_05_act.png')
                  },
                  // {
                  //   id: 4,
                  //   type: 'csmini',
                  //   title: '随身V课',
                  //   icon: require('../../static/images/playing_icon_05.png'),
                  //   icon_act: require('../../static/images/playing_icon_05_act.png')
                  // }
                ],
              })

            }else{
              that.setState({
                isout:false,
              selectlist: [
                {
                  id: 1,
                  type: 'hotback',
                  title: '精品课程',
                  icon: require('../../static/images/homeicon/icon_3_1.png'),
                  icon_act: require('../../static/images/homeicon/icon_3_2.png')
                },
                {
                  id: 3,
                  type: 'question',
                  title: '常见问题',
                  icon: require('../../static/images/homeicon/icon_4_1.png'),
                  icon_act: require('../../static/images/homeicon/icon_4_2.png')
                },
                
                {
                  id: 5,
                  type: 'zhidaocs',
                  title: '收费课程（合同专享）',
                  icon: require('../../static/images/playing_icon_05.png'),
                  icon_act: require('../../static/images/playing_icon_05_act.png')
                },
                // {
                //   id: 4,
                //   type: 'csmini',
                //   title: '随身V课',
                //   icon: require('../../static/images/playing_icon_05.png'),
                //   icon_act: require('../../static/images/playing_icon_05_act.png')
                // }
              ],
            })
            }
            
          }
          ////console.log(22222222222)

        }
      });
    } else {
      that.setState({
        thisusertype: true,
        selectlist: [
          // {
          //   id: 2,
          //   type: 'series',
          //   title: '系列课程',
          //   icon: require('../../static/images/playing_icon_05.png'),
          //   icon_act: require('../../static/images/playing_icon_05_act.png')
          // },
          // {
          //   id: 0,
          //   type: 'review',
          //   title: '精彩回顾',
          //   icon: require('../../static/images/playing_icon_03.png'),
          //   icon_act: require('../../static/images/playing_icon_03_act.png')
          // },
          {
            id: 1,
            type: 'hotback',
            title: '精品课程',
            icon: require('../../static/images/homeicon/icon_3_1.png'),
            icon_act: require('../../static/images/homeicon/icon_3_2.png')
          },
          {
            id: 3,
            type: 'question',
            title: '常见问题',
            icon: require('../../static/images/homeicon/icon_4_1.png'),
            icon_act: require('../../static/images/homeicon/icon_4_2.png')
          },
                  {
                    id: 5,
                    type: 'zhidaocs',
                    title: '收费课程（合同专享）',
                    icon: require('../../static/images/playing_icon_05.png'),
                    icon_act: require('../../static/images/playing_icon_05_act.png')
                  },
          // {
          //   id: 4,
          //   type: 'csmini',
          //   title: '随身V课',
          //   icon: require('../../static/images/playing_icon_05.png'),
          //   icon_act: require('../../static/images/playing_icon_05_act.png')
          // }
        ],
      })
      // setTimeout(function(){
      //   that.props.reviewClassArr[that.props.active_series].list.map((item, index) => {
      //     that.changeActive(index)
      //   })
      // },1000)

    }



    if (window.location.href.indexOf('?') != -1) {
      var urls = this.getUrlkey(window.location.href);
      ////console.log(urls.id)

      this.setState({
        thisid: urls.id
      })
    }
    ////console.log(this)
    if (this.props.active_two !== 'review'
      && this.props.active_two !== 'hotback'
      && this.props.active_two !== 'series'
      && this.props.active_two !== 'question') {
      this.props.change_active_two('project')
    }
    setTimeout(function () {
      // if (that.props.reviewClassArr[that.props.active_series] && window.location.href.indexOf('?') != -1) {
      //   var urls = that.getUrlkey(window.location.href);
      //   if (urls.id >= 0) {
      //     that.props.reviewClassArr[that.props.active_series].list.map((item, index) => {
      //       that.changeActive(index)
      //     })
      //   }
      // }
    }, 500)

    // //注释掉CS微课二期
    $.ajax({
      type: "GET",
      url:
        "https://shl-info.cn/front/Cscourse/getCourseList?userid=" +
        userid,
      dataType: "json",
      headers:{'token':token},
      cache: false,
      success: function (resdata) {
        that.setState({
          csxilielist:resdata.data
        })
        //console.log(3333)
        let  firurl = "https://shl-info.cn/front/Diexam/getSeriesData?xilieid=" + resdata.data[0]['id']+"&userid="+localStorage.getItem('userid')+"&iscs=1"+"&typeid="+that.state.thistypenums;
        $.ajax({
          type: "GET",
          url:firurl,
          dataType: "json",
          headers:{'token':localStorage.getItem('token')},
          
          success: function (res) {
            //console.log(res)
            let newreslist = res.data.seriestwolist
            let new_seriescourses = resdata.data
           
            new_seriescourses[0]['coursenum'] = res.data.coursenum;
            new_seriescourses[0]['passnum'] = res.data.passnum;
            let newslist =  new_seriescourses[0].list
          
            if(newslist){
              for(var i=0;i<newslist.length;i++){
                if(newslist[i]['list']){
                  for(var k=0;k<newslist[i]['list'].length;k++){
                    // //console.log(newslist[i]['list'][k]['id'])
                    // //console.log("newslist")
                    if(newreslist[newslist[i]['list'][k]['id']]){
                      newslist[i]['list'][k]['coursenum'] =newreslist[newslist[i]['list'][k]['id']]['coursenum']
                      newslist[i]['list'][k]['passnum'] =newreslist[newslist[i]['list'][k]['id']]['passnum']
                    }
                  }
                }
              }
            }
            
            //console.log(newslist)
            new_seriescourses[0].list = newslist;
          //new_seriescourses[id].list
            //console.log(new_seriescourses)
            that.setState({
              csxilielist:new_seriescourses
            })
          }
        });

        //console.log(resdata)
      }
    });

    console.log(1123123123123)
    if (window.location.href.indexOf('reviewid=') != -1) {
      var urls = this.getUrlkey(window.location.href);
      //console.log(urls)
      if(urls['thistypenums']){
        urls['thistypenums']=urls['thistypenums'];
        that.setState({
          thistypenums: urls['thistypenums']
        })
      }else{
        urls['thistypenums']=6;
      }
      
      // that.setState({
      //   thistypenums: urls['thistypenums']
      // })
      this.props.change_developments_data(urls.reviewid, urls['thistypenums'])
      $.ajax({
        type: "GET",
        url:
          "https://shl-info.cn/front/frontc/seriescoursebyid/id/" +
          urls.reviewid +
          "?userid=" +
          userid +
          "&typeid=" +
          urls['thistypenums'] ,
        dataType: "json",
        
        headers:{'token':token},
        cache: false,
        success: function (res) {
          if (res && res.status !== 2) {
              that.setState({
                seriesText: res.reviewptitle ? (res.reviewptitle + ' > ' + res.reviewstitle) : '',
              })
          }
        }
      });
      this.setState({
        gotoseries:true,
        //seriesText: parent ? (parent + ' > ' + text) : '',
      })
  
    }else if (window.location.href.indexOf('menu=') != -1) {
      var urls = this.getUrlkey(window.location.href);
      //console.log(urls)
      //console.log(decodeURIComponent(urls['menu']))
      this.setState({
        menu:decodeURIComponent(urls['menu'])
      })
      //console.log("this.props.reviewClassArr",this.props.reviewClassArr)
      
    }else if(window.location.href.indexOf('cscoursestate=1') != -1){
      this.changeSelect('zhidaocs',"收费课程（合同专享）",'5')
    }
    
    setTimeout(function () {
      if($('.series_scroll').length>0){
        //console.log(222222,$('.series_scroll').length,"$('.series_scroll').length")
        that.setState({
          iscroll: new Iscroll('.series_scroll', {
            mouseWheel: true,
            scrollbars: true,
            scrollX: true,
            scrollY: false,
            scrollbars: false,
          }),
        })
      }
      ////console.log(that.state.thisid)

    }, 1000)
    // this.props.change_active_two('series')
    //console.log(this.state.thistypenums)

    if (window.location.href.indexOf('reviewid=') != -1) {
      var urls = this.getUrlkey(window.location.href);
      //console.log(urls)
      if(urls['thistypenums']){
        urls['thistypenums']=urls['thistypenums'];

        let infolist = this.state.selectlisttextlistinfo
        for(var i=0;i<infolist.length;i++){
          if(infolist[i]['id'] ==urls['thistypenums']){
            this.props.change_active_two(infolist[i]['type'])
          }
        }
        //this.props.change_active_two(id)
      }else{
        urls['thistypenums']=6;
      }
      console.log(11111111)
      that.setState({
        thistypenums: urls['thistypenums']
      })
      this.props.change_back_data(urls['thistypenums'])
    }else{
      //console.log(12312312)
      this.props.change_back_data(this.state.thistypenums)
    }
    this.setState({
      isshowstate:true,
    })


    let  gethotlisturl = "https://shl-info.cn/front/Diexam/getProductNewAndHot?userid="+localStorage.getItem('userid');
        $.ajax({
          type: "GET",
          url:gethotlisturl,
          dataType: "json",
          headers:{'token':localStorage.getItem('token')},
          
          success: function (res) {
            //console.log(res)
            that.setState({
              hotlist:res.data.hotlist,
              newlist:res.data.newlist,
            })
            
          }
        });

    setTimeout(function(){
      //console.log("test123",that.props.reviewClassArr)
      if(that.props.reviewClassArr.length>0){
        if(that.props.reviewClassArr[0]['isdi']==1){
          that.changeseries('', 0)
          //console.log('tewts123')
        }
      }
    },3000)
    // //console.log("test123",this.props.reviewClassArr)
    // if(this.props.reviewClassArr&& this.props.reviewClassArr.length>0){
    //   //console.log("test123",this.props.reviewClassArr)
    //   // if(this.props.reviewClassArr[0].isdi){
    //   //   //console.log(this.props.reviewClassArr[0].isdi)
    //   // }
    // }
    // //console.log("this.props.reviewClassArr",this.props.reviewClassArr)
  }
  componentWillUnmount() {

  }

  componentDidShow (){
    console.log(1111,2222)
    this.props.change_back_data(this.state.thistypenums)
  }
  changedatalist = () =>{
    
    this.props.change_active_series(0)
    this.props.change_back_data(6)
    this.gotoserieslist()
    //this.props.change_back_data(this.state.thistypenums)
  }


  getbannerbyseriesid = (seriesid)=>{
   
    fetch(
      "https://shl-info.cn/front/diexam/getBannerbySeries?id=" + seriesid +"&typeids="+this.state.thistypenums
      )
      .then(data => {
        return data.json();
      })
      .then(res => {
        console.log(res)
        this.setState({
          seriesbannerlist:res
        })
      })
  }


  render() {
    //console.log("this.props.active_two",this.props.active_two)
    $('.series-list').css('width', '99999px')
    //////console.log(this.props)
    if (this.state.thisid >= 0) {
      // this.changeseries(, this.state.thisid)
    }
    //  ////console.log(this.state.selectlist)
    // 切换导航
    console.log(this.state.thistypenums)
    let selectlist = this.state.selectlist.map((item) => {
      //console.log(item)
      if(!this.state.isout && localStorage.getItem('changetype') !=1 ){
        if(item.id == 1 || item.id == 5){
          return null
        }else{
          return (
            <SelectBox
              key={item.id}
              _item={item}
              thistypenums={this.state.thistypenums}
              active={this.props.active_two}
              thisid={this.state.thisid}
              change={this.changeSelect}>
            </SelectBox>
          )
        }
      }else{
        return (
          <SelectBox
            key={item.id}
            _item={item}
            thistypenums={this.state.thistypenums}
            active={this.props.active_two}
            thisid={this.state.thisid}
            change={this.changeSelect}>
          </SelectBox>
        )
      }
     

      // if(localStorage.getItem('changetype')!=1 && item.id ==5 && localStorage.getItem('iswaitype') ==0){
      //   return null
      // }else{
      //   return (
      //     <SelectBox
      //       key={item.id}
      //       _item={item}
      //       thistypenums={this.state.thistypenums}
      //       active={this.props.active_two}
      //       thisid={this.state.thisid}
      //       change={this.changeSelect}>
      //     </SelectBox>
      //   )
      // }
    })
    let selectlisttopbanner = this.state.selectlisttextlistinfo.map((item) => {
      console.log(item)
      console.log(this.state.isout)
      console.log(localStorage.getItem('changetype'))

      if(!this.state.isout && localStorage.getItem('changetype') !=1 ){
      
        if(item.id == 6){
          // item.title = "内部培训";
          let tem = item
          tem.title = "内部培训";
          return (
            <SelectBox
              key={item.id}
              _item={tem}
              thistypenums={this.state.thistypenums}
              active={this.props.active_two}
              thisid={this.state.thisid}
              issmall={true}
              change={this.changeSelect}>
            </SelectBox>
          )
        }else{
          return null;
        }
        
      }else{
        // if(item.id == 6){
          // item.title = "内部培训";
          if(item.id == 6){
            item.title = "产品·应用";
          }
        // }
        return (
          <SelectBox
            key={item.id}
            _item={item}
            thistypenums={this.state.thistypenums}
            active={this.props.active_two}
            thisid={this.state.thisid}
            issmall={true}
            change={this.changeSelect}>
          </SelectBox>
        )
      }
      
    })
    let hotandnewlistinfo = this.state.hotandnewlistinfo.map((item) => {
      //console.log(item)
      if(!this.state.isout && localStorage.getItem('changetype') !=1 ){
        return null
      }else{
        return (
          <SelectBox
            key={item.id}
            _item={item}
            thistypenums={this.state.thistypenums}
            active={this.props.active_two}
            thisid={this.state.thisid}
            issmall={true}
            change={this.changeSelect}>
          </SelectBox>
        )
      }
     
    })

    let innerlist = null;
    innerlist = this.state.innerlist.map((item) => {
      //console.log(item)
      return (
        <SelectBox
          key={item.id}
          _item={item}
          thistypenums={this.state.thistypenums}
          active={this.props.active_two}
          thisid={this.state.thisid}
          issmall={true}
          isuser={true}
          change={this.changeSelect}>
        </SelectBox>
      )
    })

    
    // 内容更换
    let container = null;

    if (this.props.active_two === 'review'  ) {
      container = this.props.reviewclass.map((item) => {
        return (
          <ClassList key={'rev' + item.id} _item={item}></ClassList>
        )
      })
    } else if (this.props.active_two === 'hotback') {
      container = this.props.hotback.map((item) => {
        return (
          <ClassList key={'hot' + item.id} _item={item}></ClassList>
        )
      })
    } else if (this.props.active_two === 'newcourse') {
      container = this.state.newlist.map((item) => {
        return (
          <ClassList key={'hot' + item.id} _item={item}></ClassList>
        )
      })
    } else if (this.props.active_two === 'hotcourse') {
      container = this.state.hotlist.map((item) => {
        return (
          <ClassList type={"hot"} key={'hot' + item.id} _item={item}></ClassList>
        )
      })
    }else if (this.props.active_two === 'zhidaocs' ){

      //  CS微课二期
      let series_container = null;
      if (!this.state.gotoseries) {
        let bi=0;
        series_container = (
          <article>
            <div className="series_scroll none">
              <ul className="series-list ">
                {
                  this.state.csxilielist?
                  this.state.csxilielist.map((item, index) => {
                    let thisid = "change"+index;
                    return (
                      <li
                        key={item.id}
                        id={thisid}
                        className={index === this.state.csxilieids ? 'active' : ''}>
                        <span
                          className="cursor"
                          onClick={(e) => this.changecsxilieids(e, index)}>
                          {item.name}
                        </span>
                      </li>
                    )
                  })
                  :null
                }
              </ul>
            </div>
            {
              this.state.isshowstate?
              this.state.csxilielist[this.state.csxilieids] ?this.state.csxilielist[this.state.csxilieids].list? this.state.csxilielist[this.state.csxilieids].list.map((item, index) => {
                // let thisindex=item
                //     thisindex.list = item.child
                //     //console.log(thisindex)
                return (
                  <SeriesList
                    key={'ser' + this.state.csxilieids + item.id}
                    _item={item}
                    index={index}
                    iscs={true}
                    thisid={this.state.thisid}
                    gotounlock={this.gotounlock}
                    changeActive={this.changeActive_cs} 
                    gotoserieslist={this.gotoserieslist_cs}>
                     
                    

                  </SeriesList>
                )
                
              }) : null:null:null
            }
          </article>
        )
      } else {
        series_container = (
          <article>
            <div className="review-list-title">
              {this.state.seriesText}

                <div className="islockstate">
                  {/* {this.state.islock} */}
                  <div className="review-cslist_btnlist">
                      <div className={this.state.islock == 1 ? "none" : "cslist_gochange"} onClick={() => this.gotounlock(this.state.csxilielist[this.state.csxilieids_sec].list[this.state.csxilieids_thi], this.state.csxilieids_thi)}>点击解锁</div>
                  <div className={this.state.islock == 1 ? "cslist_state active" : "cslist_state"}>{this.state.islock == 1 ? "已解锁" : "未解锁"}</div>
                    </div>
                
                </div>
              <span
                className="seriestitle-arrow"
                onClick={() => this.gotoserieslist()}></span>
            </div>
           
            {
             this.state.csxiliecourselist?this.state.csxiliecourselist.map((item) => {
                return (
                  <ClassList iscs={true} key={'reslist' + item.id} _item={item}></ClassList>
                )
              })
              :null
            }
          </article>
        )
      }
      container = (
        <div className="series-contain">
          {series_container}
        </div>
      )
    } else if (this.props.active_two === 'series'  || this.props.active_two === 'research'  || this.props.active_two === 'customer'  || this.props.active_two === 'disease'  || this.props.active_two === 'project'  ||this.props.active_two === 'csmini'   ) {
      for(var i=0;i<this.props.reviewClassArr.length;i++){
        
      }
      
    //} else if (this.props.active_two === 'series' ||this.props.active_two === 'csmini'  ) {
      let series_container = null;
      if (!this.state.gotoseries) {
        //console.log("this.state.gotoseries",this.state.gotoseries)
        let bi=0;
        if(this.props.reviewClassArr[this.props.active_series]){
          //console.log("this.props.active_series",this.props.active_series)
          bi = this.props.reviewClassArr[this.props.active_series]['passnum']/this.props.reviewClassArr[this.props.active_series]['coursenum'];
          bi=bi*100;

        }
        // //console.log("this.props.reviewClassArr[this.props.active_series]")
        series_container = (
          <article>
            <div className="series_scroll">
              <ul className="series-list"
              //  style={{ width: 170 * this.props.reviewClassArr.length+'px'}}
              >
                {/* <li
                  id="22"
                  className={index === this.props.active_series ? 'active' : ''}>
                  <span
                    className="cursor"
                    onClick={(e) => this.changeseries(e, index)}>
                    {item.name}
                  </span>
                </li> */}
                {
                  this.props.reviewClassArr?
                  this.props.reviewClassArr.map((item, index) => {
                    // //console.log(item)
                    // //console.log(this.state.menu)
                    if (this.state.thisid >= 0 && this.state.thisid == index) {
                      this.changeseries(item, index)
                    }else if(this.state.menu !='' && this.state.menu==item.name){
                      this.changeseries(item, index)
                     // //console.log(3333333333333)
                    }
                    let thisid = "change"+index;
                    // let nname='123';
                    // ////console.log(nname.length)
                    return (
                      <li
                        key={item.id}
                        id={thisid}
                        className={index === this.props.active_series ? 'active' : ''}>
                        <span
                          className="cursor"
                          onClick={(e) => this.changeseries(e, index)}>
                          {item.name}
                        </span>
                      </li>
                    )
                  })
                  :null
                }
              </ul>
            </div>
            {
            this.props.reviewClassArr?
            this.props.reviewClassArr[this.props.active_series]
            ?this.props.reviewClassArr[this.props.active_series]['isdi']==1
            ?
            <div className='diexambox'>
              {/* {this.props.reviewClassArr[this.props.active_series]
              ?this.props.reviewClassArr[this.props.active_series]['overnums']==this.props.reviewClassArr[this.props.active_series]['allnums']
              ?(<span className='btns' onClick={()=>this.goexam(this.props.reviewClassArr[this.props.active_series]['id'])}>开始考试</span>)
              :(<span className='btns nostart'>开始考试</span>)
              :null} */}
              {this.props.reviewClassArr[this.props.active_series]
              ?this.props.reviewClassArr[this.props.active_series]['overnums']==this.props.reviewClassArr[this.props.active_series]['allnums']
              ?(<span className='btns' onClick={()=>this.goexam(this.props.reviewClassArr[this.props.active_series]['id'])}>开始考试</span>)
              :(<span className='btns' onClick={()=>this.goexam(this.props.reviewClassArr[this.props.active_series]['id'])}>开始考试</span>)
              :<span className='btns' onClick={()=>this.goexam(this.props.reviewClassArr[this.props.active_series]['id'])}>开始考试</span>}
              
              <span className='line' >
                <i style={{width:bi+"%"}}></i>
              </span>
              <span className='nums'  >
                {/* { this.state.isshowstate? this.props.reviewClassArr[this.props.active_series]?this.props.reviewClassArr[this.props.active_series]['overnums']:0 :0} */}
                {this.state.isshowstate? this.props.reviewClassArr[this.props.active_series]?this.props.reviewClassArr[this.props.active_series]['passnum']?this.props.reviewClassArr[this.props.active_series]['passnum']:0:0:0}
                /
                {/* {this.state.isshowstate? this.props.reviewClassArr[this.props.active_series]?this.props.reviewClassArr[this.props.active_series]['allnums']:0:0} */}
                {this.state.isshowstate? this.props.reviewClassArr[this.props.active_series]?this.props.reviewClassArr[this.props.active_series]['coursenum']?this.props.reviewClassArr[this.props.active_series]['coursenum']:0:0:0}
              </span>
            </div>
            :null
              :null
                :null
          }
            
            
          {/* <div >{this.props.reviewClassArr[this.props.active_series]['isdi']}</div> */}
           
            {
              this.state.isshowstate?
              this.props.reviewClassArr[this.props.active_series] ?this.props.reviewClassArr[this.props.active_series].list? this.props.reviewClassArr[this.props.active_series].list.map((item, index) => {
                //this.changeActive(index)
                if(item.isdi==1){
                  if(localStorage.getItem('isdi')==1 ){
                    return (
                      <SeriesList
                        key={'ser' + this.props.active_series + item.id}
                        _item={item}
                        index={index}
                        thisid={this.state.thisid}
                        changeActive={this.changeActive}
                        gotoserieslist={this.gotoserieslist}>  
    
                      </SeriesList>
                    )
                  }
                }else{
                  return (
                    <SeriesList
                      key={'ser' + this.props.active_series + item.id}
                      _item={item}
                      index={index}
                      thisid={this.state.thisid}
                      changeActive={this.changeActive}
                      gotoserieslist={this.gotoserieslist}>
  
                    </SeriesList>
                  )
                }
                
              }) : null:null:null
            }
          </article>
        )
      } else {
        series_container = (
          <article>
            <div className="review-list-title">
              {this.state.seriesText}
              <span
                className="seriestitle-arrow"
                onClick={() => this.gotoserieslist()}></span>
            </div>
            {
              this.props.developments.map((item) => {
                return (
                  <ClassList key={'reslist' + item.id} _item={item}></ClassList>
                )
              })
            }
          </article>
        )
      }
      container = (
        <div className="series-contain">
          {series_container}
        </div>
      )
    } else {
      container = this.props.question.map((item, index) => {
        return (
          <Question
            key={'quest' + item.id}
            _item={item}
            index={index}
            changeQuestion={this.changeQuestion}></Question>
        )
      });
    }
    let widhtlist = 0;
    let isscrollwidht=0;
    let isscrollwidhtadd=false;
    let that=this;
    
    $('.series-list').css('width', '99999px')
    setTimeout(function () {
      for (var i = 0; i < $('.series-list li').length; i++) {
        widhtlist = widhtlist + $('.series-list li').eq(i).width() + 40;
      }
      $('.series-list').css('width', widhtlist + 'px')
      $('.series-list').css('min-width', '100%')
      setTimeout(function(){
        //console.log('that.state.iscroll',that.state.iscroll)
       
          setTimeout(function(){
            if (that.state.iscroll){
              that.state.iscroll.refresh();
            }
          },1000)
        setTimeout(function(){
          for (var i = 0; i < $('.series-list li').length; i++) {
            if (that.state.iscroll && $('.series-list li').eq(i).hasClass('active')){
              
              //console.log('that.state.iscroll',that.state.iscroll)
              let thisid="change"+i;
              that.state.iscroll.scrollToElement("#"+thisid,1000,0,0); 
            }
          }
        },200)
      },200)


    }, 200)
    //
    ////console.log(widhtlist)
    let overbannerlist = this.props.bannerlist
    //console.log(this.state.seriesbannerlist.length)
      if(this.state.seriesbannerlist.length>0){
        overbannerlist=this.state.seriesbannerlist
      }
      //console.log($('.cslist_tips').length,this.props.active_two,"------1")
      if(this.props.active_two === 'zhidaocs' && $('.cslist_tips').length>0 && !startstate && window.location.href.indexOf('cscoursestate=1')>=0 && window.location.href.indexOf('changeid=')>=0){
        //console.log('scrolltop')
        startstate = true
        
        var urls = this.getUrlkey(window.location.href);
        setTimeout(()=>{
          $('html, body').animate({
            scrollTop: $("#serinfolist"+urls['changeid']).offset().top
          }, 500);
        },100)
      }
    return (
      <div className='playingpage'>
        <Header active='1' headerchangeactive={this.headerchangeactive} ishomeplay={true} changedatalist={this.changedatalist}></Header>
        {/* <Headerfir active='1' 
          changeSelectthi ={this.changeSelectthi}
          thistypenums = {this.state.thistypenums}
        ></Headerfir> */}
       
        {this.state.cslayerstate?(
           <CsLiveCode 
            closeCsLive={this.closeCsLive}
            valueChange={this.valueChange}
            cardcode={this.state.cardcode}
            submitcode={this.submitcode}
            gobuy={this.gobuy}
            submitcodechange={this.submitcodechange}
            thisshowitem={this.state.thisshowitem}
            thisshowtitle={this.state.thisshowtitle}
            name_place="请输入您的兑换码"
            cslayertext={this.state.cslayertext}
            thisshowok={this.state.thisshowok}
            thisshowokitem={this.state.thisshowokitem}
           ></CsLiveCode>
        ):null}

        
<Banner bannerlist={overbannerlist}></Banner>
       
        <div className={this.state.thisusertype ? 'page-navselect other' : ' page-navselect '}>
          <div className="wrap overflow">
            <ul className="navselect fl">
              {selectlisttopbanner}
              {hotandnewlistinfo}
              {selectlist}
              {innerlist}
            </ul>
            <div className="select-contain fr ">
              <div className={this.props.active_two === 'series' ? 'select-contain_thibtnlist none' : 'select-contain_thibtnlist none'}>
                {/* <span className={this.state.thistypenums == 1 ? 'cursor hover' : 'cursor'} onClick={() => this.changeSelectthi(1)}>按产品划分</span>
                <span className={this.state.thistypenums == 2 ? 'cursor hover' : 'cursor'} onClick={() => this.changeSelectthi(2)}>按疾病划分</span>
                <span className={this.state.thistypenums == 3 ? 'cursor hover' : 'cursor'} onClick={() => this.changeSelectthi(3)}>按科室划分</span> */}

                
                <span className={this.state.thistypenums == 6 ? 'cursor hover' : 'cursor'} onClick={() => this.changeSelectthi(6)}>产品中心</span>
                <span className={this.state.thistypenums == 7 ? 'cursor hover' : 'cursor'} onClick={() => this.changeSelectthi(7)}>疾病中心</span>
                <span className={this.state.thistypenums == 8 ? 'cursor hover' : 'cursor'} onClick={() => this.changeSelectthi(8)}>客户中心</span> 
                <span className={this.state.thistypenums == 9 ? 'cursor hover' : 'cursor'} onClick={() => this.changeSelectthi(9)}>课程中心</span>  
              </div>
              {container}
            </div>
          </div>
        </div>

        {/* {
          this.state.thisusertype ? (
            <Banner bannerlist={overbannerlist}></Banner>
          ) : null
        }
         {!this.state.thisusertype ? (
          <Banner bannerlist={overbannerlist}></Banner>
        ) : null} */}
        <Footer></Footer>
      </div >
    )
  }
}

export default Home
