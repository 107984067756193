import React, { useState, useEffect } from 'react'
import './index.css'
import CourseItem from '../CourseItem'
import Search from '../Search'


import Maskbox from "../../../../components/maskbox";
import groupDetail from '../..';

const IndexComponent = (props) => {
  
  let godetail = (id) =>{

    props.history.push('/coursedetails?type='+id)
  }
  let groupChatCourseItem = (item,index) => {
    return (
      <div className="groupChatCourseItem" key={index} onClick={()=>godetail(item.id)}>
        <CourseItem item={item} isseach={true}>
          {/* <div className="groupChatCourseTime">
            <img src={require('../../../../static/images/icon3.png')} alt="" className="groupChatCourseTimeIcon" />
            <div className="groupChatCourseTimeText">已观看06:26</div>
          </div> */}
          <div className="groupChatCoursebyadd" onClick={(e)=>addcourse(e,item)}>添加</div>
        </CourseItem>
      </div>
    )
  }


 
  let [inputtext, setInputtext]= useState('')


 
  let [tipstitle, setTipstitle]= useState('')
  let [tiptext, setTiptext]= useState('')


  let [seachstate, setSeachstate]= useState(false)

  let [seachlist, setSeachlist]= useState([])
  // let addcourse = (item)=>{
  //   console.log(1111)
  //   console.log(item)
  //   console.log(props.groupid)
  // }

  let addcourse =(e,item)=>{
    console.log(e)
    console.log(item)
    console.log(props)
    fetch(
      "https://shl-info.cn/front/groupchat/addCourseForPack",{
        method: 'POST',
        body: JSON.stringify({
          "chatcode": props.groupid,
          "userid": localStorage.getItem('userid'),
          "courseid": item.id,
          "packid": props.packidinfo.packcode,
        }),
        headers:{
          'content-type': 'application/json',
          'token':localStorage.getItem('token')
        },
        header: {
          'content-type': 'application/json',
          'token':localStorage.getItem('token')
        },
      }
    )
      .then(data => {
        return data.json();
      })
      .then(res => {
        console.log(res)
        setIsmask(true);
        if(res.code==2000){
          setTipstitle('添加成功')
          setTiptext('课程已经添加到必修课程包内')
          props.changepacklist();
        }else{
          setTipstitle('添加失败')
          setTiptext(res.msg,res.code)
        }
      })
      e.stopPropagation();

  }

  let gosearch = ()=>{
    console.log(inputtext)
    // setInputtext()
    fetch(
      "https://shl-info.cn/front/groupchat/getCourseListByName?userid=" +
      localStorage.getItem('userid') +
      "&packname=" +
      inputtext+
      "&chatcode="+props.groupid ,{
        headers:{
          'token':localStorage.getItem('token') 
        }
      }
    )
      .then(data => {
        return data.json();
      })
      .then(res => {
        console.log(res)
        if(res.code==2000){
          setSeachstate(true)
          setSeachlist(res.data)
          // this.setState({
          //   seachlist:res.data,
          //   isseach:true
          // })
        }
      });

  }
  let closeMask = ()=>{
    console.log(2222)
    setIsmask(false)

    props.setGroupChatModule('seriesCourse')
  }

  
  let [ismask, setIsmask]= useState(false)
  let exammask = null;
  if (ismask) {
    exammask = (
      <Maskbox
        info={{
          title: tipstitle,
          text: tiptext
        }}
        closeMask={closeMask}
      ></Maskbox>
    );
  }
  let changeseachstate = () =>{
    setSeachstate(false)
  }

  

  return (
    <div className="groupChatCourse">
      {exammask}
      <div className="groupChatCourseHead">
        <Search inputtext={inputtext} setSeachstate={setSeachstate} setInputtext ={setInputtext} gosearch = {gosearch}></Search>
        <img src={require('../../../../static/images/icon6.png')} alt="" className="groupChatCourseClose"  onClick={() => props.closeFunc()} />
      </div>

      <div className="groupChatCourseList">
        {seachlist.map((item,index)=>{
          return groupChatCourseItem(item,index)
        })}
        {seachlist.length<=0 && seachstate?<div className='seachcoursenocontent'>暂无内容</div>:null}
        {/* { groupChatCourseItem() }
        { groupChatCourseItem() }
        { groupChatCourseItem() } */}
      </div>
    </div>
  )
};

export default IndexComponent;
