import React, { Component } from 'react'
import $ from 'jquery'


import {Picker,CascadePicker, Button, Space } from 'antd-mobile'
// 组件
import Header from '../../components/header'
import Footer from '../../components/footer'
import PersonImg from '../../components/personimg'
import MaskBox from '../../components/maskbox'

import { encrypt, decrypt } from '../../utils/crypto.ts'

import './index.css'

class AddAccount extends Component {
  constructor(props) {
    super(props)
    console.log(props)
    this.closeMask = this.closeMask.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.valueChange = this.valueChange.bind(this);
    this.selectChange = this.selectChange.bind(this);

    this.selectChangeprovince = this.selectChangeprovince.bind(this);
    this.selectChangeoffice = this.selectChangeoffice.bind(this);

    let ispersondata = this.props.location.search.indexOf('type=1') > 0 ? true : false;
    this.state = {

      pagename: ispersondata ? '个人资料' : '创建子账号',
      masktext: '请填写完整',
      maskfalg: false,
      iscroll: null,
      name: ispersondata ? props.userinfo.info.name : '',
      nameActive: false,
      component: ispersondata ? props.userinfo.info.component : '',
      componentActive: false,
      local: ispersondata ? props.userinfo.info.local : '',
      localActive: false,
      phone: ispersondata ? props.userinfo.info.phone : '',
      phoneActive: false,
      email: ispersondata ? props.userinfo.info.email : '',
      emailActive: false,
      people: ispersondata ? props.userinfo.info.people : '',
      peopleActive: false,
      hospital: '',
      hospitalActive: false,
      room: '',
      roomActive: false,

      hotail_place: '请输入',
      department_place: '请输入',
      tel_place: '请输入',

      department: '',
      hotail: '',
      tel: '',

      thisnum: 3,
      hotail: '',
      hotailActive: false,
      department: '',
      departmentActive: false,
      telment: '',
      telActive: false,


      thisuserid:'',
      hotiltype:'',
      hotillevel:'',
      office:'',
      province:'',
      svalue:[],
      visible:false,
      value:[],
      basicColumns:[
        {
          label: '公立医疗机构',
          value: '公立医疗机构',
          children: [
            
            {  label: '三级甲等', value: '三级甲等' },
            {  label: '三级乙等', value: '三级乙等' },
            {  label: '三级丙等', value: '三级丙等' },
            {  label: '三级医院', value: '三级医院' },
            {  label: '二级甲等', value: '二级甲等' },
            {  label: '二级乙等', value: '二级乙等' },
            {  label: '二级丙等', value: '二级丙等' },
            {  label: '二级医院', value: '二级医院' },
            {  label: '一级甲等', value: '一级甲等' },
            {  label: '一级乙等', value: '一级乙等' },
            {  label: '一级丙等', value: '一级丙等' },
            {  label: '一级医院', value: '一级医院' },
            {  label: '未定级', value: '未定级' },
            {  label: '非医院', value: '非医院' },
          ],
        },
        {
          label: '非公医疗机构',
          value: '非公医疗机构',
          children: [
            
            {  label: '三级甲等', value: '三级甲等' },
            {  label: '三级乙等', value: '三级乙等' },
            {  label: '三级丙等', value: '三级丙等' },
            {  label: '三级医院', value: '三级医院' },
            {  label: '二级甲等', value: '二级甲等' },
            {  label: '二级乙等', value: '二级乙等' },
            {  label: '二级丙等', value: '二级丙等' },
            {  label: '二级医院', value: '二级医院' },
            {  label: '一级甲等', value: '一级甲等' },
            {  label: '一级乙等', value: '一级乙等' },
            {  label: '一级丙等', value: '一级丙等' },
            {  label: '一级医院', value: '一级医院' },
            {  label: '未定级', value: '未定级' },
            {  label: '非医院', value: '非医院' },
          ],
        },
        // {
        //   label: '医疗机构',
        //   value: '医疗机构',
        //   children: [{ label: '医疗机构', value: '医疗机构' }],
        // },
        {
          label: '大学院校/研究机构',
          value: '大学院校/研究机构',
          children: [{ label: '大学院校/研究机构', value: '大学院校/研究机构' }],
        },
        {
          label: '经销商/第三方合作机构',
          value: '经销商/第三方合作机构',
          children: [{ label: '经销商/第三方合作机构', value: '经销商/第三方合作机构' }],
        }
      ]


    }
  }


  selectChange(e) {
    var department = e.target.value
    if(department=='请选择'){
      department="";
    }
    this.setState({
      department: department
    }, () => {
      //this.haveSubmit();
    })
    console.log(e.target.value)
  }
  
  selectChangeprovince(e) {
    var department = e.target.value
    if(department=='请选择'){
      department="";
    }
    this.setState({
      province: department
    }, () => {
      // this.haveSubmit();
    })
    //console.log(e.target.value)

  }
  selectChangeoffice(e){
    var department = e.target.value
    if(department=='请选择'){
      department="";
    }
    this.setState({
      office: department
    }, () => {
      
    })
  }


  // 表单部分
  onFocus(e) {
    this.setState({
      [e.target.name + 'Active']: true
    })
  }
  onBlur(e) {
    this.setState({
      [e.target.name + 'Active']: false
    })
  }
  valueChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  closeMask() {
    if (this.state.masktext === '提交成功') {
      this.props.history.goBack();
    } else {
      this.setState({
        maskfalg: false
      })
    }
  }
  accountsubmit() {
    let submitfalg = true;
    let submitText = '';
    if (this.state.thisnum == 1) {
      if (!this.state.name
        || !this.state.component
        || !this.state.local
        || !this.state.phone
        || !this.state.email
        || !this.state.people) {
        submitfalg = false;
        submitText = '请填写完整'
      } else if (!this.props.emailexg.test(this.state.email)) {
        submitfalg = false;
        submitText = '邮箱格式错误'
      } else if (!this.props.phoneexg.test(this.state.phone)) {
        submitfalg = false;
        submitText = '手机格式错误'
      }
    } else if (this.state.thisnum == 2) {
      if (!this.state.name || !this.state.email || !this.state.phone || !this.state.department) {
        submitfalg = false;
        submitText = '请填写完整'
      } else if (!this.props.emailexg.test(this.state.email)) {
        submitfalg = false;
        submitText = '邮箱格式错误'
      } else if (!(/^1[3456789]\d{9}$/.test(this.state.phone))) {
        submitfalg = false;
        submitText = '手机格式错误'

      }
    } else if (this.state.thisnum == 3) {
      console.log(this.state.name, this.state.email, this.state.phone, this.state.department, this.state.hotail)
      //|| !this.state.email 
      if (!this.state.name || !this.state.phone || !this.state.department || !this.state.hotail || !this.state.province || !this.state.office || !this.state.hotiltype || !this.state.hotillevel) {
        submitfalg = false;
        submitText = '请填写完整'
      } 
      // else if (!this.props.emailexg.test(this.state.email)) {
      //   submitfalg = false;
      //   submitText = '邮箱格式错误'
      // } 
      else if (!(/^1[3456789]\d{9}$/.test(this.state.phone))) {
        submitfalg = false;
        submitText = '手机格式错误'

      }else{
        if (this.state.email){
          if(!this.props.emailexg.test(this.state.email)) {
            submitfalg = false;
            submitText = '邮箱格式错误'
            console.log(11111)
          }
        }
      } 
    }
    console.log(submitfalg);
    console.log(1111);
    // return false;
    if (submitfalg) {
      if (this.loadingfalg) { return false; }
      this.loadingfalg = true;
      if (this.state.pagename === '创建子账号') {
        $.ajax({
          url:
            "https://shl-info.cn/front/frontc/createsubaccount?userid=" +
            this.props.userinfo.userid +
            "&name=" +
            encodeURI(this.state.name, 'UTF-8') +
            "&component=" +
            encodeURI(this.state.component, 'UTF-8') +
            "&local=" +
            encodeURI(this.state.local, 'UTF-8') +
            "&phone=" +
            this.state.phone +
            "&email=" +
            this.state.email +
            "&people=" +
            encodeURI(this.state.people, 'UTF-8'),
          type: "GET",
          dataType: "json",
          headers:{'token':this.props.userinfo.token},
          cache: false
        }).then(res => {
          this.loadingfalg = false;
          if (res.status === 1) {
            this.setState({
              masktext: "提交成功",
              maskfalg: true
            });
          } else if (res.status !== 1 && res.error === 1) {
            this.setState({
              masktext: "用户已存在",
              maskfalg: true
            });
          }
        });
      } else {
        // 修改资料
        $.ajax({
          url:
            "https://shl-info.cn/front/frontc/updateaccount?userid=" +
            this.props.userinfo.userid +
            "&name=" +
            encodeURI(this.state.name, 'UTF-8') +
            "&component=" +
            encodeURI(this.state.component, 'UTF-8') +
            "&local=" +
            encodeURI(this.state.local, 'UTF-8') +
            "&phone=" +
            this.state.phone +
            "&hospital=" +
            encodeURI(this.state.hotail, 'UTF-8') +
            "&offices=" +
            encodeURI(this.state.department, 'UTF-8') +
            "&email=" +
            this.state.email +
            "&people=" +
            encodeURI(this.state.people, 'UTF-8') + 
            '&hotiltype=' + this.state.hotiltype + 
            '&hotillevel=' + this.state.hotillevel + 
            '&office=' + this.state.office + 
            '&province=' + this.state.province,
            
          type: "GET",
          dataType: "json",
          
          headers:{'token':this.props.userinfo.token},
          cache: false
        }).then(res => {
          this.loadingfalg = false;
          if (res && res.status !== 2) {
            this.setState({
              masktext: "提交成功",
              maskfalg: true
            });
            let newobject = this.props.userinfo;
            newobject.info.email = this.state.email;
            newobject.info.name = this.state.name;
            newobject.info.phone = this.state.phone;
            newobject.info.local = this.state.local;
            newobject.info.component = this.state.component;
            newobject.info.people = this.state.people;
            this.props.change_userinfo(newobject);
          }else{
            this.setState({
              masktext: "更新失败",
              maskfalg: true
            })
          }
        });
      }
    } else {
      this.setState({
        masktext: submitText,
        maskfalg: true
      })
    }
  }

  componentDidMount() {
    console.log(this.props.userinfo)
    this.loadingfalg = false;

    let that = this
    let userid = (localStorage.getItem('userid'));
    let token = (localStorage.getItem('token'));
    if(!userid || !token){
      localStorage.setItem('refurl',window.location.href)
      this.props.history.push('/loginlist')
      return
    }

    if (userid && token) {

      // 验证是否过期
      $.ajax({
        type: "GET",
        url: "https://shl-info.cn/front/user/checkuser?userid=" +
          userid ,
        dataType: "json",
        headers:{'token':token},
        success: function (res) {
          console.log(res)
          console.log(22222222222)
          
          localStorage.setItem('iswaitype', res.list.type)
          if (res.status === 1) {
            if (res.list.type == 4) {
              that.setState({
                thisnum: 2,
              })
            } else if (res.list.type == 1 || res.list.type == 2 || res.list.type == 3 || res.list.type == 5) {

              that.setState({
                thisnum: 3,
              })
            } else {
              that.setState({
                thisnum: 1,
              })
            }
            that.setState({
              name: decrypt(res.list.name),
              hotail: res.list.hospital,
              department: res.list.offices,
              component: res.list.component,
              local: res.list.local,
              phone: decrypt(res.list.phone),
              email: decrypt(res.list.email),
              people: res.list.people,

              province:res.list.province,
              hotillevel:res.list.hotillevel,
              hotiltype:res.list.hotiltype,
              office:res.list.office,
              sValue:[res.list.hotiltype,res.list.hotillevel],
              hotail:res.list.hospital


            })
            console.log(333333333)
            localStorage.setItem("userid", userid);
            localStorage.setItem("token", res.token);


            var userlist = res.list;
            if(userlist.name){
                userlist.name = decrypt(userlist.name);
            }
            if(userlist.email){
                userlist.email = decrypt(userlist.email);
            }
            if(userlist.phone){
                userlist.phone = decrypt(userlist.phone);
            }
            res.list = userlist


            that.props.change_userinfo({
              token: res.token,
              pic: res.pic,
              userid: userid,
              info: res.list
            });
          }
        }
      });
    }



  }
  componentWillUnmount() {

  }
  render() {
    let maskbox = null;
    if (this.state.maskfalg) {
      maskbox = (
        <MaskBox
          info={{
            title: this.state.pagename,
            text: this.state.masktext
          }}
          closeMask={this.closeMask}>

        </MaskBox>
      )
    }
    let hotail = null;

    return (
      <div className='personpage'>

        <CascadePicker
          options={this.state.basicColumns}
          visible={this.state.visible}
          onClose={() => {
            this.setState({
              visible:false
            })
          }}
          onConfirm={(v, extend) => {
            this.setState({
              visible:false,
              hotiltype: v[0],
              hotillevel:v[1],
            }, () => {
              //this.haveSubmit();
            })
          }}
        />


        <Header active='4'></Header>
        <PersonImg type="no"></PersonImg>
        <section className="person-list">
          <div className="wrap no-border">
            <div className="addaccount-list">
              <h4 className="addaccount-title">{this.state.pagename}</h4>
              <div className="addaccount-from">
                <div className={this.state.nameActive ? 'login-form focus' : 'login-form'}>
                  <span className="form-name">姓名</span>
                  <input
                    name="name"
                    value={this.state.name}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onChange={this.valueChange}
                    placeholder="请输入"
                    readOnly={this.state.pagename === '个人资料' ? 'readonly' : false}
                    style={{ 'background': this.state.pagename === '个人资料' ? '#f9f9f9' : 'none' }}></input>
                </div>
                {/* 状态为3的用户显示 */}

                {this.state.thisnum == 3 ?
                  (<div className={this.state.hotailActive ? "login-form focus " : "login-form "}>
                    <span className="form-name">所属医院/单位</span>
                    <input
                      name="hotail"
                      value={this.state.hotail}
                      onFocus={this.onFocus}
                      onBlur={this.onBlur}
                      onChange={this.valueChange}
                      placeholder="请输入"></input>
                  </div>) : null
                }


                {this.state.thisnum == 3 ? (
                  <div className={this.state.departmentActive ? 'login-form focus' : 'login-form'}>
                    <span className="form-name">所在省份</span>
                    <select
                      className={
                        this.state.province == "请选择" ||
                          this.state.province == ""
                          ? "userselect "
                          : "userselect  active"
                      }
                      value={this.state.province}
                      onChange={this.selectChangeprovince}
                    >
                      <option value="">请选择</option>
                      <option value="北京市">北京市</option>
                      <option value="天津市">天津市</option>
                      <option value="河北省">河北省</option>
                      <option value="山西省">山西省</option>
                      <option value="内蒙古自治区">内蒙古自治区</option>
                      <option value="辽宁省">辽宁省</option>
                      <option value="吉林省">吉林省</option>
                      <option value="黑龙江省">黑龙江省</option>
                      <option value="上海市">上海市</option>
                      <option value="江苏省">江苏省</option>
                      <option value="浙江省">浙江省</option>
                      <option value="安徽省">安徽省</option>
                      <option value="福建省">福建省</option>
                      <option value="江西省">江西省</option>
                      <option value="山东省">山东省</option>
                      <option value="河南省">河南省</option>
                      <option value="湖北省">湖北省</option>
                      <option value="湖南省">湖南省</option>
                      <option value="广东省">广东省</option>
                      <option value="海南省">海南省</option>
                      <option value="广西壮族自治区">广西壮族自治区</option>
                      <option value="甘肃省">甘肃省</option>
                      <option value="陕西省">陕西省</option>
                      <option value="新疆维吾尔自治区">新疆维吾尔自治区</option>
                      <option value="青海省">青海省</option>
                      <option value="宁夏回族自治区">宁夏回族自治区</option>
                      <option value="重庆市">重庆市</option>
                      <option value="四川省">四川省</option>
                      <option value="贵州省">贵州省</option>
                      <option value="云南省">云南省</option>
                      <option value="西藏自治区">西藏自治区</option>
                      <option value="中国台湾">中国台湾</option>
                      <option value="中国澳门">中国澳门</option>
                      <option value="中国香港">中国香港</option>
                    </select>
                    
                    <img
                      alt=""
                      className="select_arrow"
                      src={require('../../static/images/select_arrow.png')} />
                  </div>
                ) : null
                }

                {this.state.thisnum == 3 ? (
                  <div className={this.state.departmentActive ? 'login-form focus' : 'login-form'}>
                    <span className="form-name">医院类别</span>
                    <div className="useroldbtn" onClick={()=>{
                      this.setState({
                        visible:true,
                      })
                    }}>{this.state.hotiltype?this.state.hotiltype=='非公医疗机构' ||this.state.hotiltype=='公立医疗机构'?this.state.hotiltype+"---"+ this.state.hotillevel:this.state.hotiltype:"请选择"}</div>
                    <img
                      alt=""
                      className="select_arrow"
                      src={require('../../static/images/select_arrow.png')} />
                  </div>
                ) : null
                }



                {/* {this.state.thisnum == 3 ?
                  (<div className={this.state.departmentActive ? 'login-form focus' : 'login-form'}>
                    <span className="form-name">科室</span>
                    <input
                      name="department"
                      value={this.state.department}
                      onFocus={this.onFocus}
                      onBlur={this.onBlur}
                      onChange={this.valueChange}
                      placeholder="请输入"></input>
                  </div>) : null
                } */}
                {this.state.thisnum == 3 ? (
                  <div className={this.state.departmentActive ? 'login-form focus' : 'login-form'}>
                    <span className="form-name">科室</span>
                    <select
                      className={
                        this.state.department == "请选择" ||
                          this.state.department == ""
                          ? "userselect "
                          : "userselect  active"
                      }
                      value={this.state.department}
                      onChange={this.selectChange}
                    >
                      <option value="">请选择</option>

                      <option value="放射科/影像科">放射科/影像科</option>
                      <option value="核医学科">核医学科</option>
                      <option value="超声科">超声科</option>
                      <option value="检验科">检验科</option>
                      <option value="介入科">介入科</option>
                      <option value="放疗科">放疗科</option>
                      <option value="高层管理">高层管理</option>
                      <option value="设备科/采购科">设备科/采购科</option>
                      <option value="心内/心外科">心内/心外科</option>
                      <option value="神内/神外科">神内/神外科</option>
                      <option value="肿瘤内/外科">肿瘤内/外科</option>
                      <option value="血管外科">血管外科</option>
                      <option value="重症医学科">重症医学科</option>
                      <option value="急诊科">急诊科</option>
                      <option value="手术室/麻醉室">手术室/麻醉室</option>
                      <option value="护理部">护理部</option>
                      <option value="胸外科">胸外科</option>
                      <option value="呼吸科">呼吸科</option>
                      <option value="甲乳外科">甲乳外科</option>
                      <option value="骨科">骨科</option>
                      <option value="泌尿科">泌尿科</option>
                      <option value="普外科/肝胆外科">普外科/肝胆外科</option>
                      <option value="妇产科">妇产科</option>
                      <option value="内分泌科">内分泌科</option>
                      <option value="感染科/肝病科">感染科/肝病科</option>
                      <option value="信息科">信息科</option>
                      <option value="高校院校/研究机构">高校院校/研究机构</option>
                      <option value="其它">其它</option>
                    </select>
                    
                    <img
                      alt=""
                      className="select_arrow"
                      src={require('../../static/images/select_arrow.png')} />
                  </div>
                ) : null
                }



                {/* 状态为3的用户显示结束 */}



                {/* 状态为2的用户显示 */}

                {this.state.thisnum == 2 ?
                  (<div className={this.state.componentActive ? 'login-form focus' : 'login-form'}>
                    <span className="form-name">部门</span>
                    <select className={this.state.department == '请选择' || this.state.department == '' ? "userselect" : 'userselect active'}
                      onChange={this.selectChange} value={this.state.department}>
                      <option>请选择</option>
                      <option>DI</option>
                      <option>AT</option>
                      <option>CS</option>
                      <option>US</option>
                      <option>DX</option>
                      <option>CC</option>
                      <option>BPO</option>
                      <option>HR</option>
                      <option>其他</option>
                    </select>
                    <img
                      alt=""
                      className="select_arrow"
                      src={require('../../static/images/select_arrow.png')} />
                  </div>) : null
                }


                {/* 状态为2的用户显示结束 */}



                {/* 状态为1的用户显示 */}

                {this.state.thisnum == 1 ?
                  (<div className={this.state.componentActive ? 'login-form focus' : 'login-form'}>
                    <span className="form-name">公司名称</span>
                    <input
                      name="component"
                      value={this.state.component}
                      onFocus={this.onFocus}
                      onBlur={this.onBlur}
                      onChange={this.valueChange}
                      placeholder="请输入"></input>
                  </div>) : null
                }
                {/* {this.state.thisnum == 2 ?
                  (<div className={this.state.componentActive ? 'login-form focus' : 'login-form'}>
                    <span className="form-name">公司名称</span>
                    <input
                      name="component"
                      value={this.state.component}
                      onFocus={this.onFocus}
                      onBlur={this.onBlur}
                      onChange={this.valueChange}
                      placeholder="请输入"></input>
                  </div>) : null
                } */}
                {this.state.thisnum == 1 ?
                  (<div className={this.state.localActive ? 'login-form focus' : 'login-form'}>
                    <span className="form-name">职位</span>
                    <input
                      name="local"
                      value={this.state.local}
                      onFocus={this.onFocus}
                      onBlur={this.onBlur}
                      onChange={this.valueChange}
                      placeholder="请输入"></input>
                  </div>) : null
                }


                {this.state.thisnum == 3 ? (
                  <div className={this.state.departmentActive ? 'login-form focus' : 'login-form'}>
                    <span className="form-name">职务</span>
                  <select
                    className={
                      this.state.office == "请选择" ||
                        this.state.office == ""
                        ? "userselect "
                        : "userselect  active"
                    }
                    value={this.state.office}
                    onChange={this.selectChangeoffice}
                  >
                    <option value="">请选择</option>
                    <option value="技师">技师</option>
                    <option value="主管技师">主管技师</option>
                    <option value="副主任技师">副主任技师</option>
                    <option value="技师长">技师长</option>
                    <option value="主任技师">主任技师</option>
                    <option value="院长/副院长">院长/副院长</option>
                    <option value="住院医师">住院医师</option>
                    <option value="主治医师">主治医师</option>
                    <option value="副主任医师">副主任医师</option>
                    <option value="行政主任/副主任">行政主任/副主任</option>
                    <option value="主任医师">主任医师</option>
                    <option value="设备科长">设备科长</option>
                    <option value="工程师">工程师</option>
                    <option value="物理师">物理师</option>
                    <option value="护士">护士</option>
                    <option value="教授/研究员">教授/研究员</option>
                    <option value="医学生">医学生</option>
                    <option value="总经理/部门经理">总经理/部门经理</option>
                    <option value="其他">其他</option>


                  </select>
                    <img
                      alt=""
                      className="select_arrow"
                      src={require('../../static/images/select_arrow.png')} />
                  </div>
                ) : null
                }

                {/* 状态为1的用户显示结束 */}
                {this.state.thisnum == 3?<div className={this.state.phoneActive ? 'login-form focus' : 'login-form'}>
                  <span className="form-name">联系方式</span>
                  <input
                    name="phone"
                    value={this.state.phone}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onChange={this.valueChange}
                    readOnly="readonly"
                    style={{ 'background': '#f9f9f9'}}
                    placeholder="请输入"></input>
                </div>:
                <div className={this.state.phoneActive ? 'login-form focus' : 'login-form'}>
                  <span className="form-name">联系方式</span>
                  <input
                    name="phone"
                    value={this.state.phone}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onChange={this.valueChange}
                    placeholder="请输入"></input>
                </div>
                }


                {this.state.thisnum == 3? <div className={this.state.emailActive ? 'login-form focus' : 'login-form'}>
                  <span className="form-name">邮箱</span>
                  <input
                    name="email"
                    value={this.state.email}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onChange={this.valueChange}
                    placeholder="请输入"></input>
                </div>:<div className={this.state.emailActive ? 'login-form focus' : 'login-form'}>
                  <span className="form-name">邮箱</span>
                  <input
                    name="email"
                    value={this.state.email}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onChange={this.valueChange}
                    placeholder="请输入"
                    readOnly={this.state.pagename === '个人资料' ? 'readonly' : false}
                    style={{ 'background': this.state.pagename === '个人资料' ? '#f9f9f9' : 'none' }}></input>
                </div>}


                {/* 状态为1的用户显示 */}

                {this.state.thisnum == 1 ?
                  (<div className={this.state.peopleActive ? 'login-form focus' : 'login-form'}>
                    <span className="form-name">身份</span>
                    <input
                      name="people"
                      value={this.state.people}
                      onFocus={this.onFocus}
                      onBlur={this.onBlur}
                      onChange={this.valueChange}
                      placeholder="请输入"></input>
                  </div>) : null
                }


                {/* 状态为1的用户显示结束 */}


                {/* <div className={this.state.hospitalActive ? 'login-form focus' : 'login-form'}>
                    <span className="form-name">医院</span>
                    <input
                      name="hospital"
                      onFocus={this.onFocus}
                      onBlur={this.onBlur}
                      onChange={this.valueChange} 
                      placeholder="请输入"></input>
                  </div>
                  <div className={this.state.roomActive ? 'login-form focus' : 'login-form'}>
                    <span className="form-name">科室</span>
                    <input
                      name="room"
                      onFocus={this.onFocus}
                      onBlur={this.onBlur}
                      onChange={this.valueChange} 
                      placeholder="请输入"></input>
                  </div> */}
              </div>
              <div
                className="addAccountBtn cursor"
                onClick={() => this.accountsubmit()}>提交</div>
            </div>
          </div>
        </section>
        {maskbox}
        <Footer></Footer>
      </div>
    )
  }
}

export default AddAccount
