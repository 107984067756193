import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Swiper from 'swiper'

import 'swiper/css/swiper.min.css'

import './index.css'

class LayoutClass extends Component {
    constructor() {
        super()

        this.state = {
            swiper: null
        }
    }
    swiperInit() {
        let that = this;
        that.setState({
            swiper: new Swiper('.swiper-class', {
                loop: false,  //循环
                speed: 500,
                resistanceRatio: 0,
                autoplay: {   //滑动后继续播放
                    disableOnInteraction: false,
                }
            })
        })
    }

    
    componentDidUpdate() {
        console.log(this.state.swiper)
        console.log("this.state.swiper")
        if (!this.state.swiper  || this.state.swiper.length>1) { return false }
        console.log( this.state.swiper )
        let that = this;
        setTimeout(function(){
            that.state.swiper.update()
        },1000)
    }
    componentDidMount() {
        console.log(this.props.title)
        if (!this.props.swiper) { return false }
        this.swiperInit();
    }
    componentWillUnmount() {
        if (!this.props.swiper) { return false }
        if (this.state.swiper.length > 0) {
            for (var i = 0; i < this.state.swiper.length; i++) {
                this.state.swiper[i].destroy();
            }
        } else {
            this.state.swiper.destroy();
        }
    }
    render(){
        let morebtn = null;
        if (this.props.morebtn) {
            morebtn = (
                <div className="common-more addright">
                    <Link to={this.props.morehref}>
                        查看更多
                    <img src={require('../../static/images/moreIcon.png')} alt="" />
                    </Link>
                </div>
            )
        }
        // 截取轮播课程
        let new_list = [];
        for (var i = 0; i < this.props.list.length; i++){
            if(i%3 === 0){
                new_list.push([]);
            }
            new_list[new_list.length - 1].push(this.props.list[i])
        }
        return (
            <section className="common-action">
                <div className="wrap">
                    <h3 className="common-title">{this.props.title}</h3>
                    
                    {this.props.istitle?<h3 className="common-smalltitle">{this.props.iszhibo?"最近回放":"最热文档"}</h3>:null}
                    <div className="common-float">
                        {
                            this.props.swiper ? (
                                <div className="swiper-class">
                                    <div className="swiper-wrapper">
                                        {
                                            new_list.map((item, index) => {
                                                return (
                                                    <div key={index} className="swiper-slide">
                                                        { item }
                                                    </div>
                                                ) 
                                            })
                                        }
                                    </div>
                                </div>
                            ) : this.props.list
                        }
                    </div>
                    {morebtn}
                </div>
            </section>
        )
    }
}

export default LayoutClass
