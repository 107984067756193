import React, { useState, useEffect } from 'react'

import './index.css'

const IndexComponent = (props) => {

  return (
    <div className="courseItem">
     {props.canstate?<div className="delcoursebypick" onClick={(e)=>props.delcourser(e,props.item)}>删除</div>:null} 
      <img src={props.item?props.item.headimg:""} alt="" className="courseItemBanner" />
      <div className="courseItemContent">
        <div className="courseItemTitle twoLineDisplay">{props.item?props.item.coursetitle:""}</div>
        <div className="courseItemLabels">
          <div className="courseItemLabelItem">
            <img src={require('../../../../static/images/icon1.png')} alt="" className="courseItemLabelItemIcon" />
            <div className="courseItemLabelItemText">{props.item?props.item.teacher:""}</div>
          </div>
          
          {/* <div className="courseItemLabelItem">
            <img src={require('../../../../static/images/icon2.png')} alt="" className="courseItemLabelItemIcon" />
            <div className="courseItemLabelItemText">1个月15天</div>
          </div> */}
          {/* <div className="courseItemLabelItem">
            <img src={require('../../../../static/images/icon3.png')} alt="" className="courseItemLabelItemIcon" />
            <div className="courseItemLabelItemText">365</div>
          </div> */}
        </div>
        { props.children }
      </div>
    </div>
  )
};

export default IndexComponent;
