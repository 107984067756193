import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import $ from 'jquery'
// 组件
import Header from '../../components/header'
import Footer from '../../components/footer'
import PersonImg from '../../components/personimg'
import PersonTitle from '../../components/persontitle'
import HistoryList from '../../components/historylist'
import SelectBox from '../../components/selectbox'

import Scorelayer from '../../components/scorelayer2023';

import MaskBox from "../../components/maskbox";

import './index.css'

class Invitelist extends Component {
  constructor() {
    super()

    // this.gourls = this.gourls.bind(this)
    this.state = {
      active: '0',
      mallnums: 3,
      scorcelist: [],

      
      scorelist:[],
      scoreusernum:0,
      score:0,
      selectindex:'1,2,3,',
      types:1,
      scoreinfolist:[],
      showlayer:false,
      isemail:0,
      isscore6:0,
      isscore7:0,
      isscore8:0,

      showscorelayerstate:false,
      agreeMask:false,
      todaysign:false,
      scorelsyerstate:false,
      scorelsyerlist:{
        score:"",
        content:"",
      },


    }
  }

  static defaultProps = {
    selectlist: [
      {
        id: 0,
        type: '0',
        title: '积分规则'
      },
      {
        id: 1,
        type: '1',
        title: '积分明细'
      },
    ]
  }

  changeSelect = (id) => {
    this.setState({
      active: id
    })
  }
  change = (id) => {
    console.log(id)
    this.setState({
      active: id,
    })
  }
  closeMask = () => {
    this.setState({
      usernolayer: false,
    })
  }

  gourls = (e) => {
    if (e == 1 && !this.state.todaysign) {
      this.props.history.push('/homeperson')
    }
    if (e == 2) {
      this.props.history.push('/learnplan')
    }
    if (e == 3) {
      this.props.history.push('/learnplan')
    }
    if (e == 4) {
      this.props.history.push('/learnplan')
    }
    console.log(e)
  }


  closescorelayer = () =>{
    this.setState({
      scorelsyerstate:false
    })
  }
  showscorelayer = () =>{
    this.setState({
      scorelsyerstate:true,
      todaysign:true,
    })
  }
  todayclick() {
    if (this.state.todaysign) {
     
      return false;
    }
    // this.setState({
    //   scorelsyerstate:true
    // })
    this.showscorelayer();


    //注释添加积分ESHOP
    //签到加积分

    $.ajax({
      type: "POST",
      url:
        "https://shl-info.cn/front/Scoreshop/setScore?userid=" +
        localStorage.getItem('userid') +
        "&usertype=" +
        localStorage.getItem('usertype'),
      data: JSON.stringify({
        userid: localStorage.getItem('userid'),
        type: 5,
      }),
      dataType: "json",
      headers:{'token':localStorage.getItem('token')},
      cache: false
    })
      .catch(error => {

        //console.log(error);
      })
      .then(res => {
        console.log(res)
        let newnum = this.state.signnums + 1;
        if(res.code==2000){
          this.setState({
            todaysign: true,
            scorelsyerlist:res.data,
            // signscore: res.score,
            signnums: newnum,
          })
        }
      });
  }

  componentDidMount() {
    this.props.change_active_two('learnplan')
    this.props.change_person_learnplan()
    var that = this;
    fetch(
      "https://shl-info.cn/front/Scoreshop/getScoreDetail?userid=" + localStorage.getItem('userid') ,{
        headers:{
          'token':localStorage.getItem('token')
        }
      }
    )
    .then(data => {
      return data.json();
    })
    .then(res => {
      console.log(res)
      this.setState({
        scoreusernum:res.data.sourcenum,
        isscore6:res.data.isscore6,
        isscore7:res.data.isscore7,
        isscore8:res.data.isscore8,
        isemail:res.data.isemail,
        todaysign:res.data.signin==1?true:false,
        //scoreusernum:1,
      })
    })
    fetch(
      "https://shl-info.cn/front/Scoreshop/getSourceUserInfo?userid=" + localStorage.getItem('userid') ,{
        headers:{
          'token':localStorage.getItem('token')
        }
      }
    )
    .then(data => {
      return data.json();
    })
    .then(res => {
      console.log(res)
      this.setState({
        score:res.data.userscore,
        scorelist:res.data.sourceuser
        //scoreusernum:1,
      })
    })

    
    fetch(
      "https://shl-info.cn//front/Scoreshop/getUserScoreList?userid=" + localStorage.getItem('userid') ,{
        headers:{
          'token':localStorage.getItem('token')
        }
      }
    )
    .then(data => {
      return data.json();
    })
    .then(res => {
      console.log(res)
      this.setState({
        scoreinfolist:res.data
        //scoreusernum:1,
      })
    })

  }
  componentWillUnmount() {

  }
  gojump =(id)=>{
    if(id==1){
      this.props.history.push('/addaccount?type=1')
    }
   
    if(id==3){
      this.props.history.push('/homedoctorinteraction')
    }
    if(id==4){
      this.props.history.push('/homeback')
    }
    if(id==5){
      this.props.history.push('/homeplaying')
    }
  }
  showscoretipslayer = ()=>{
   this.setState({
    agreeMask:true
   })
  }
  closetipsMask = ()=>{
    this.setState({
     agreeMask:false
    })

  }
  goscorelayer = ()=>{
    //
    fetch(
      "https://shl-info.cn/front/Scoreshop/addReadLogs",{
        method: 'POST',
        body: JSON.stringify({
          userid:localStorage.getItem('userid'),
          type:2,
          ua:'pc',
        }),
        headers:{
          'content-type': 'application/json',
          'token':localStorage.getItem('token')
        },
        header: {
          'content-type': 'application/json',
          'token':localStorage.getItem('token')
        },
      }
    )
    .then(data => {
      return data.json();
    })
    .then(res => {
     // this.props.history.push(e);
    })
    this.setState({
      showscorelayerstate:true,
    })
  }
  closelivestate = ()=>{
    this.setState({
      showscorelayerstate:false,
    })
  }
  render() {
    // 切换导航
    let selectlist = this.props.selectlist.map((item) => {
      return (
        <SelectBox
          key={item.id}
          _item={item}
          active={this.state.active}
          change={this.change}
          imgstate="no">

        </SelectBox>
      )
    })

    

    let scorelsyerstatehtml = null
    if(this.state.scorelsyerstate){
      scorelsyerstatehtml= <Scorelayer closescorelayer={this.closescorelayer} scorelsyerlist={this.state.scorelsyerlist}></Scorelayer> 
    }

    let maskbox = null;

    // console.log(this.state.starttimenum)
     if(this.state.showscorelayerstate ){
      
       maskbox = (
         <MaskBox
           info={{
             title: '积分商城',
             text: '积分商城仅支持微信环境打开。请关注“西门子医疗”微信公众号，点击进入西影力学院。',
           }}
           closeMask={this.closelivestate}>
         </MaskBox>
       )
     }

    return (
      <div className='personpage personpagescore'>
        {maskbox}

<div
          className={
            this.state.agreeMask ? "privacyMask active" : "privacyMask"
          }
        >
          <div className="privacyCenter">
            <h3 className="maskTitle">
            西影力学院积分商城政策说明
              <span className="closeIcon" onClick={()=>this.closetipsMask()}>
                <img
                  className="cursor"
                  src={require("../../static/images/close.png")}
                  alt=""
                />
              </span>
            </h3>
            <div className="maskiscroll agree-props nopaddinbottomsc">
              <div className="maskscroll-wrap">
                <h4>积分商城简介：</h4>
                  <p>
                  西影力学院的积分商城是基于西影力学院建设的用户互动板块，用户注册成为西影力学院的会员后，将享受活动参与、课程学习、学习资料下载等更多权益。
                  </p>
                  
                  <h4>积分获取政策：</h4>
                  <p>为了提供更完善的会员服务，西影力学院的积分有效期为12个月，过期将自动作废。目前西影力会员有不同方法获取积分——注册、互动、邀请，具体包括但不限于：</p>
                
                  <h4>注册：</h4>
                  <p>新注册用户并信息完善个人信息，获20积分</p>
                  <h4>互动：</h4>
                  <p>打卡签到，每天获1积分；连续5天签到后，第六天起签到，每天获2积分</p>
                  <p>报名并参与直播活动，获10积分</p>
                  <p>点击回放课程/下载文档，获2积分（每天最多5次）</p>
                  <p>医医互动留言一篇帖子并通过审核（重复帖子仅计算1次），留言者获2积分（每天最多2次）</p>
                  <p>医医互动点赞一篇帖子或留言（重复帖子留言仅计算1次），点赞者获1积分（每天最多5次）</p>
                  <h4>邀请：</h4>
                  <p>邀请1-5位新用户加入西影力学院，每位新用户注册并观看培训课程后，邀请人可获100积分</p>
                  <p>邀请第6位及以上新用户加入西影力学院，每位新用户注册并观看培训课程后，邀请人可获200积分</p>
                  <h4>备注：</h4>
                  <p>以上完成每小节/章节课程所产生的积分每个用户不得重复获得</p>
                  <p>未完成课程不计算得分</p>
                  <p>未成功邀请或分享的不计算得分</p>
                  <p>积分达到一定的数量即可兑换商城礼品，详情以积分商城实际可兑换商品为准</p>
                  <p>积分兑换的礼品仅供用户本人使用，不得转售给他人</p>
                  <p>本次积分基于良性互动所产生，当积分互动行为被判定为“恶意邀请、刷留言”，会被后台警告并根据情节严重程度，针对该账号，给予警告、扣积分、限制操作、封号等相应处罚</p>
                  <p>会员条款，包括积分及积分兑换规则会由西影力学院不定期进行调整，具体最新的规则为准</p>
                  <p>关于西影力学院会员积分规则，如有任何疑问，可进一步咨询西门子医疗子MK&CC 部门，联系方式：（021）38891887转接MK&CC</p>
                  
              </div>
            </div>
          </div>
        </div>


        {scorelsyerstatehtml}
        <Header active='4'></Header>
        <PersonImg type="no"></PersonImg>
        <PersonTitle title="个人积分" showscore ={true} goscorelayer={()=>this.goscorelayer()}></PersonTitle>
        <div className='page-navselect overflow'>
          <div className="wrap ">
            <ul className="navselect fl">
              {selectlist}
            </ul>
            <div className="select-contain fr  integralmall_contain">
              <div className="integralmall_box">
                <div className={this.state.active==1?'integral_fromlist ':"none"}>
                  <ul className="integral_fromlist_looklist " >

                    {this.state.scoreinfolist.map((item,index)=>{
                      console.log(item)
                      return  <li className="small_li">
                      <div className='integral_fromlist_li_icon'><img src={require('../../static/images/mall/23.png')} /></div>
                      <div className="integral_fromlist_li_title">{item.content}</div>
                      <div className="integral_fromlist_li_date">{item.createtime}</div>
                      <div className="integral_fromlist_li_addfen">{item.points_num>0?"+"+item.points_num:item.points_num}</div>
                    </li> 
                    })}
                    {/* <li className="small_li">
                      <div className='integral_fromlist_li_icon'><img src={require('../../static/images/mall/23.png')} /></div>
                      <div className="integral_fromlist_li_title">完成选修培训课程</div>
                      <div className="integral_fromlist_li_date">完成考试或观看完毕即可获得积分。</div>
                      <div className="integral_fromlist_li_addfen">+2</div>
                    </li> 
                    <li className="small_li">
                      <div className='integral_fromlist_li_icon'><img src={require('../../static/images/mall/23.png')} /></div>
                      <div className="integral_fromlist_li_title">完成选修培训课程</div>
                      <div className="integral_fromlist_li_date">完成考试或观看完毕即可获得积分。</div>
                      <div className="integral_fromlist_li_addfen">+2</div>
                    </li>
                    <li className="small_li">
                      <div className='integral_fromlist_li_icon'><img src={require('../../static/images/mall/23.png')} /></div>
                      <div className="integral_fromlist_li_title">完成选修培训课程</div>
                      <div className="integral_fromlist_li_date">完成考试或观看完毕即可获得积分。</div>
                      <div className="integral_fromlist_li_addfen">+2</div>
                    </li>
                    <li className="small_li">
                      <div className='integral_fromlist_li_icon'><img src={require('../../static/images/mall/23.png')} /></div>
                      <div className="integral_fromlist_li_title">完成选修培训课程</div>
                      <div className="integral_fromlist_li_date">完成考试或观看完毕即可获得积分。</div>
                      <div className="integral_fromlist_li_addfen">+2</div>
                    </li>
                    <li className="small_li">
                      <div className='integral_fromlist_li_icon'><img src={require('../../static/images/mall/23.png')} /></div>
                      <div className="integral_fromlist_li_title">完成选修培训课程</div>
                      <div className="integral_fromlist_li_date">完成考试或观看完毕即可获得积分。</div>
                      <div className="integral_fromlist_li_addfen">+2</div>
                    </li> */}
                  </ul>
                </div>


                <div className={this.state.active==0?'integral_fromlist ':"none"}>
                  <div className="integral_fromlist_title">标准积分</div>
                  <ul className="integral_fromlist_looklist " >

                 

                    {/* 赢取积分任务列表 */}
                    <li className={this.state.isemail==1?" activeover":""} onClick={()=>this.gojump(1)} >
                      {/* {this.state.showdolist[0]['type1']['show'] ? "111" : '222'} */}
                      <div className="integral_fromlist_li_title">注册积分并信息完善积分</div>
                      <div className="integral_fromlist_li_date">完善个人邮箱手机等身份信息</div>
                      <div className="integral_fromlist_li_addfen">+20</div>
                      <div className='integral_fromlist_li_rightbox'>{this.state.isemail==1?"已完成":"去完成"}<span>{">"}</span></div>
                    </li>
                   
                    <li className=''  >
                      {/* {this.state.showdolist[0]['type1']['show'] ? "111" : '222'} */}
                      <div className="integral_fromlist_li_title">邀请好友赢积分（仅微信环境）</div>
                      <div className="integral_fromlist_li_today">您已邀请0个好友</div>
                      <div className="integral_fromlist_li_date">成功邀请好友注册获取积分，<br></br>邀请更多好友注册可获得更多积分</div>
                      <div className="integral_fromlist_li_addfen">+100</div>
                      {/* <div className='integral_fromlist_li_rightbox'>去完成<span>{">"}</span></div> */}
                    </li>
                  </ul>

                  <div className="integral_fromlist_title">今日积分<span>（每日00：00更新）</span></div>
                  <ul className="integral_fromlist_looklist " >
                    {/* 赢取积分任务列表 */}
                    
                    
                    <li className={this.state.todaysign==1?" activeover":""}  onClick={()=>this.todayclick(4)} >
                      {/* {this.state.showdolist[0]['type1']['show'] ? "111" : '222'} */}
                      <div className="integral_fromlist_li_title">每日签到</div>
                      <div className="integral_fromlist_li_date">每天可获得1次</div>
                      <div className="integral_fromlist_li_addfen">+1</div>
                      <div className='integral_fromlist_li_rightbox'>{this.state.todaysign==1?"已完成":"去完成"}<span>{">"}</span></div>
                    </li>
                    
                    <li className={this.state.isscore6==1?" activeover":""}   onClick={()=>this.gojump(4)} >
                      {/* {this.state.showdolist[0]['type1']['show'] ? "111" : '222'} */}
                      <div className="integral_fromlist_li_title">点击回放课程/下载文档</div>
                      <div className="integral_fromlist_li_date">单课程/文档仅记录一次，每天可获得5次</div>
                      <div className="integral_fromlist_li_addfen">+2</div>
                      <div className='integral_fromlist_li_rightbox'>{this.state.isscore6==1?"已完成":"去完成"}<span>{">"}</span></div>
                    </li>


                    <li className={this.state.isscore8==1?" activeover":""} onClick={()=>this.gojump(3)} >
                      {/* {this.state.showdolist[0]['type1']['show'] ? "111" : '222'} */}
                      <div className="integral_fromlist_li_title">医医互动-点赞一篇帖子/留言</div>
                      <div className="integral_fromlist_li_date">点赞医医互动中任意帖子或留言，每天可获得5次</div>
                      <div className="integral_fromlist_li_addfen">+1</div>
                      <div className='integral_fromlist_li_rightbox'>{this.state.isscore8==1?"已完成":"去完成"}<span>{">"}</span></div>
                    </li>
                   
                    {/* <li className={this.state.isscore7==1?" activeover":""} onClick={()=>this.gojump(3)} >
                      <div className="integral_fromlist_li_title">医医互动-点赞一篇留言</div>
                      <div className="integral_fromlist_li_date">点赞医医互动中任意留言。每天可获得5次</div>
                      <div className="integral_fromlist_li_addfen">+1</div>
                      <div className='integral_fromlist_li_rightbox'>去完成<span>{">"}</span></div>
                    </li> */}
                    
                    <li className={this.state.isscore7==1?" activeover":""} onClick={()=>this.gojump(3)} >
                      {/* {this.state.showdolist[0]['type1']['show'] ? "111" : '222'} */}
                      <div className="integral_fromlist_li_title">医医互动-评论一篇帖子</div>
                      <div className="integral_fromlist_li_date">评论医医互动中任意帖子，评论通过后可获得<br></br>该积分，每天可获得2次</div>
                      <div className="integral_fromlist_li_addfen">+2</div>
                      <div className='integral_fromlist_li_rightbox'>{this.state.isscore7==1?"已完成":"去完成"}<span>{">"}</span></div>
                    </li>
                  </ul>


                  <div className="integral_fromlist_title">直播积分</div>
                  <ul className="integral_fromlist_looklist " >
                    {/* 赢取积分任务列表 */}
                    <li className='' onClick={()=>this.gojump(5)} >
                      {/* {this.state.showdolist[0]['type1']['show'] ? "111" : '222'} */}
                      <div className="integral_fromlist_li_title">参与直播活动</div>
                      <div className="integral_fromlist_li_date">参与1次直播活动，每场直播仅记录一次</div>
                      <div className="integral_fromlist_li_addfen">+10</div>
                      <div className='integral_fromlist_li_rightbox'>去完成<span>{">"}</span></div>
                    </li>
                  </ul>

                </div>

                <div className="tipsbtnbyscore"><span onClick={()=>this.showscoretipslayer()}>积分商城政策说明</span></div>
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div >
    )
  }
}

export default Invitelist
