import React from 'react'

import { Link } from 'react-router-dom'

import {withRouter} from 'react-router-dom'
import groupDetail from '../../routes/groupDetail'


function MaskBox(props) {
    console.log(props)
    const godetail = (id) =>{
        console.log(id)
        if(id==1){
            props.closeMask()
            props.history.push('/addaccount?type=1')
        }
        if(id==3){
            props.closeMask()
            props.history.push('/homeperson')
        }
    }
    let chattime =props.info.allvideotime - props.info.maxlooktime
    return (
        <div className="common-mask">
            <div className="common-maskbox addtextmorems ">
                <h2 className="common-masktitle">
                    {props.info.title}
                    <span
                        className="common-maskclose cursor"
                        onClick={() => props.closeMask()}></span>
                </h2>

                <div className="common-masktext">
                    <div className='masktextgrouptipsbox'>
                        <div className='masktextgrouptips'>请先观看完视频再答题，当前最大观看至{props.info.maxlooktime}秒</div>
                        <div className='masktextgrouptips'>您还需要观看{chattime}秒才能进行考试</div>
                        <div className='masktextgrouptips'><br></br>如有问题，您可以<br></br>直接拨打电话：15033146565<br></br>或发送邮件至<br></br>zefan.xu.ext@siemens-healthineers.com
                        </div>
                    </div>
                    
                </div>
            </div>
        </div >
    )
}

export default withRouter(MaskBox)
