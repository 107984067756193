import React, { Component } from 'react'

import $ from 'jquery'
// 组件
import Headerfir from '../../components/headerfir'
import Header from '../../components/header'
import Footer from '../../components/footer'
import Banner from '../../components/banner'
import SelectBox from '../../components/selectbox'
import ClassList from '../../components/classlist'
import SeriesList from '../../components/serieslist'
import Question from '../../components/question'
import Iscroll from 'iscroll/build/iscroll-probe';

import './index.css'

class Contentus extends Component {
  constructor(props) {
    super(props)
    this.changeSelectthi = this.changeSelectthi.bind(this)

    

    this.state = {
      gotoseries: false,
      seriesText: '',
      thisusertype: false,
      isshowstate:false,
      iscroll: null,
      thistypenums:6,
      thisid: -1,
      menu:'',
      selectlist: [],
      selectlisttextlist: ["产品中心","疾病中心","客户中心","科研中心"],
    }
  }

  
  changeSelectthi(){
    console.log(12312312)
  }

  
  getUrlkey(url) {
    var params = {};
    var urls = url.split("?");                  ////console.log('1_分割url:', urls)
    var arr = urls[1].split("&");               ////console.log('2_分割urls[1]:', arr)
    for (var i = 0, l = arr.length; i < l; i++) {
      var a = arr[i].split("=");                ////console.log('3_遍历 arr 并分割后赋值给a:', a[0], a[1])
      params[a[0]] = a[1];                      ////console.log('4_a给params对象赋值:', params)
    }                                           ////console.log('5_结果:', params)
    return params;
  }

  componentDidMount() {
    let userid = (localStorage.getItem('userid'));
    let token = (localStorage.getItem('token'));
   
  }
  componentWillUnmount() {

  }

  componentDidShow (){
  }
  render() {
   
    return (
      <div className='playingpage'>
        {/* <Header active='1'></Header> */}
        {/* <Headerfir active='1' 
          changeSelectthi ={this.changeSelectthi}
          thistypenums = {this.state.thistypenums}
        ></Headerfir>
         */}
        <Header
          active='0'></Header>
        <div className="topbannernew">
          <img src={require('../../static/images/contentus_bg.jpg')} />
        </div>
        <div className="contents_text">
          <div className="contents_title">关于西门子医疗</div>
          <div className="contents_t">西门子医疗系统有限公司（Siemens-Healthineers）<br></br>西门子医疗，正在塑造医疗行业的未来 。在西门子医疗，我们的目标是通过全面实现数字化医疗，助力医疗服务提供者在各自领域推进精准医疗、转化诊疗模式、改善患者体验，以全方位助力其提升价值。</div>
          <div className="contents_t">每一天，全球有五百万名患者在影像诊断、临床诊疗、实验室诊断、分子医学以及数字化医疗和企业服务方面受益于我们的创新技术和服务。</div>
          <div className="contents_t">拥有超过170年历史，在全球范围内持有18,000个专利，西门子医疗是领先的医疗技术公司。我们在全球超过70个国家约65，000名员工将继续创新，塑造医疗行业的未来。欲了解更多信息，请访问<br></br>西门子医疗官方网站：<a href='https://www.siemens-healthineers.cn/' target="_black" >www.siemens-healthineers.cn</a></div>
         
          <div className="contents_contact">
            <div className="contents_contact_title">我们的客户服务中心热线提供7天24小时全天侯服务</div>
            <div className="contents_contact_text">请拨打热线：<a href = "tel:4008105888">400-810-5888</a></div>
            <div className="contents_contact_text">西门子医疗大中华区</div>
            <div className="contents_contact_text">上海市浦东新区海阳西路程399号</div>
            <div className="contents_contact_text">邮编：200126</div>
            <div className="contents_contact_text">上海市浦东新区周祝公路程278号</div>
            <div className="contents_contact_text">邮编：201318</div>
            <div className="contents_contact_text">电话：<a href = "tel:021-38895000">（021） 38895000</a></div>
            <div className="contents_contact_text">传真：<a href = "tel:021-38895001">（021） 38895001</a></div>

            
            <div className="contents_contact_title">不良事件联系人（AT/DI/US）</div>
            <div className="contents_contact_text">负责人：高名女士</div>
            <div className="contents_contact_text">电话：<a href = "tel:021-38895000">（021） 38895000</a></div>
            <div className="contents_contact_text">传真：<a href = "tel:021-38895001">（021） 38895001</a></div>
            <div className="contents_contact_text">邮箱：<a href = "mailto:siemens_healthineers_ehs_office.cn@siemens.com">siemens_healthineers_ehs_office.cn@siemens.com</a></div>
            <div className="contents_contact_text">联系地址：上海市浦东新区海阳西路程399号</div>
            <div className="contents_contact_text">邮编：200126</div>


          </div>
        </div>
        <Footer></Footer>
      </div >
    )
  }
}

export default Contentus
