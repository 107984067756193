import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { change_active_two, change_person_learncord_data } from '../../store/action'
import LearnRecord from './index.bundle'

const mapStateToProps = (state) => {
  return {
    learnrecord: state.person_learnrecord
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    change_active_two(data) {
      dispatch(change_active_two(data))
    },
    change_person_learncord_data(data) {
      dispatch(change_person_learncord_data(data))
    }
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LearnRecord)
);
