import React, { Component } from 'react';

import {Picker,CascadePicker, Button, Space } from 'antd-mobile'

import $ from 'jquery'
import MaskBox from '../../components/maskbox'
import './index.css'

import { encrypt, decrypt } from '../../utils/crypto.ts'
import * as ww from '@wecom/jssdk'




function debounce(fn, ms = 500) {
  let timeoutId
  return function () {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => {
      fn.apply(this, arguments)
    }, ms)
  }
}
class Login extends Component {
  constructor(props) {
    super(props);

    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.valueChange = this.valueChange.bind(this);
    this.selectChange = this.selectChange.bind(this);
    this.selectChangehotiltype = this.selectChangehotiltype.bind(this);
    this.selectChangeoffice = this.selectChangeoffice.bind(this);
    this.selectChangeprovince = this.selectChangeprovince.bind(this);
    this.selectChangehotillevel = this.selectChangehotillevel.bind(this);
    this.selectChangedepartment = this.selectChangedepartment.bind(this);


    this.loginClick = this.loginClick.bind(this);
    this.loginClicksec = this.loginClicksec.bind(this);
    this.loginClicksecuserinput = this.loginClicksecuserinput.bind(this);
    this.loginClickthi = this.loginClickthi.bind(this);
    this.goback = this.goback.bind(this);


    this.isPhoneLegal = debounce(this.isPhoneLegal, 600);//调用设定好的防抖方法


    // this.changelogin = this.changelogin.bind(this);



    this.closeMask = this.closeMask.bind(this);

    this.state = {
      thisnum: 0,
      isthisnum: false,

    
      // thisnum: 2,


      isneistate: true,
      name: '',
      email: '',
      department: '',
      hotiltype: '',
      office: '',
      hotillevel: '',
      province: '',
      hotail: '',
      tel: '',
      code: '',
      checkcode:'',
      iscomefrom: false,
      nameActive: false,
      emailActive: false,
      checkcodeActive:false,
      hotailActive: false,
      departmentActive: false,
      hotiltypeActive: false,
      officeActive: false,
      provinceActive: false,
      hotillevelActive: false,
      telActive: false,
      codeActive: false,
      codeText: '获取验证码',
      privacy: false,
      agreement: false,
      submitActive: false,
      privacyMask: false,
      agreeMask: false,
      // placeholder
      name_place: '请输入',
      email_place: '请输入',
      phone_place: '请输入',
      tel_place: '请输入',
      hotail_place: '请输入',
      department_place: '请输入',
      office_place: '请输入',
      hotillevel_place: '请输入',
      province_place: '请输入',
      tel_place: '请输入',
      maskfalg: false,
      masktext: '请填写完整',
      wechetcode: false,
      wecheterror: false,
      wechetimgurl: '',
      showlogintext:false,
      loginbox: {
        box1: false,
        box2: false,
        box3: false,
      },
      courseid:'',
      isbbs:'',
      bbsid:'',
      topicid:'',
      

      showaddress:false,
      addresslist:[],

      wwLogin:'',


      changeindustylayerstate:false,
      changeindustylayer:'',
      industylist:',',
      
      visible:false,
      value:[],
      jumpurl:'',
      basicColumns:[
        {
          label: '公立医疗机构',
          value: '公立医疗机构',
          children: [
            
            {  label: '三级甲等', value: '三级甲等' },
            {  label: '三级乙等', value: '三级乙等' },
            {  label: '三级丙等', value: '三级丙等' },
            {  label: '三级医院', value: '三级医院' },
            {  label: '二级甲等', value: '二级甲等' },
            {  label: '二级乙等', value: '二级乙等' },
            {  label: '二级丙等', value: '二级丙等' },
            {  label: '二级医院', value: '二级医院' },
            {  label: '一级甲等', value: '一级甲等' },
            {  label: '一级乙等', value: '一级乙等' },
            {  label: '一级丙等', value: '一级丙等' },
            {  label: '一级医院', value: '一级医院' },
            {  label: '未定级', value: '未定级' },
            {  label: '非医院', value: '非医院' },
          ],
        },
        {
          label: '非公医疗机构',
          value: '非公医疗机构',
          children: [
            
            {  label: '三级甲等', value: '三级甲等' },
            {  label: '三级乙等', value: '三级乙等' },
            {  label: '三级丙等', value: '三级丙等' },
            {  label: '三级医院', value: '三级医院' },
            {  label: '二级甲等', value: '二级甲等' },
            {  label: '二级乙等', value: '二级乙等' },
            {  label: '二级丙等', value: '二级丙等' },
            {  label: '二级医院', value: '二级医院' },
            {  label: '一级甲等', value: '一级甲等' },
            {  label: '一级乙等', value: '一级乙等' },
            {  label: '一级丙等', value: '一级丙等' },
            {  label: '一级医院', value: '一级医院' },
            {  label: '未定级', value: '未定级' },
            {  label: '非医院', value: '非医院' },
          ],
        },
        // {
        //   label: '医疗机构',
        //   value: '医疗机构',
        //   children: [{ label: '医疗机构', value: '医疗机构' }],
        // },
        {
          label: '大学院校/研究机构',
          value: '大学院校/研究机构',
          children: [{ label: '大学院校/研究机构', value: '大学院校/研究机构' }],
        },
        {
          label: '经销商/第三方合作机构',
          value: '经销商/第三方合作机构',
          children: [{ label: '经销商/第三方合作机构', value: '经销商/第三方合作机构' }],
        }
      ]



    }
  }
  goback() {
    this.setState({
      thisnum: 0,
      submitActive:false,
    })
  }
  // 表单部分
  onFocus(e) {
    this.setState({
      [e.target.name + 'Active']: true,
      showaddress:false,
    })
  }
  onBlur(e) {
    this.setState({
      [e.target.name + 'Active']: false,
      //showaddress:false,
    })
    if(e.target.name=='hotail'){
      let that = this
      setTimeout(function(){
        that.setState({
           showaddress:false,
        })
      },200)
    }
   
  }
  selectChangehotillevel(e) {
    var department = e.target.value
    this.setState({
      hotillevel: department
    }, () => {
      this.haveSubmit();
    })
  }
  selectChangeprovince(e) {
    var department = e.target.value
    if(department=='请选择'){
      department="";
    }
    this.setState({
      province: department
    }, () => {
      this.haveSubmit();
    })
  }
  selectChangedepartment(e) {
    var department = e.target.value
    if(department=='请选择'){
      department="";
    }
    this.setState({
      department: department
    }, () => {
      this.haveSubmit();
    })
  }
  selectChangeoffice(e) {
    var department = e.target.value
    if(department=='请选择'){
      department="";
    }
    this.setState({
      office: department
    }, () => {
      this.haveSubmit();
    })
  }
  selectChangehotiltype(e) {

    var department = e.target.value
    this.setState({
      hotiltype: department
    }, () => {
      this.haveSubmit();
    })
    console.log(e.target.value)
  }
  selectChange(e) {
    var department = e.target.value
    if(department=='请选择'){
      department="";
    }
    this.setState({
      department: department
    }, () => {
      this.haveSubmit();
    })
    console.log(e.target.value)
  }
  isPhoneLegal  = (val) => {
    //console.log(val)  // 防抖后获取的值
      console.log('请求获得地址列表')
    let that = this
      fetch(
        "https://shl-info.cn/front/user/getHospitalList?hospital="+val,{
          method: 'GET',
        }
      )
      .then(data => {
        return data.json();
      })
      .then(res => {
      if(res.data && res.data.length>0){
        this.setState({
          addresslist:res.data,
          showaddress:true,
        })
        $('.addresslistbox').scrollTop(0)
      }else{
        this.setState({
          addresslist:[],
          showaddress:false,
        })
      }
        console.log(res)
      })
  } 
  queonress = (item,index) =>{
    console.log(item,index)
    this.setState({
      hotail:item.name,
      hotiltype:item.type,
      hotillevel:item.level,
      province:item.province,
      showaddress:false,
    })
  }
  onThrottleKeyUpClick = (e) => { //ajax会按照我们设定的时间，每1s执行一次
    this.isPhoneLegal(e.target.value) 
   
    let text = e.target.value
    this.setState(
      {
        [e.target.name]: text,
        hotiltype:"",
        hotillevel:"",
        province:"",
      },
      () => {
        this.haveSubmit();
      }
    );
  }
  valueChange(e) {
    let text = e.target.value
    if(e.target.name=='code'){
      text=text.substring(0,6);
    }
    console.log(text)
    this.setState({
      [e.target.name]: text
    }, () => {
      this.haveSubmit();
    })
  }
  // 选择label
  labelChange(name, e) {
    this.setState({
      // [name]: !this.state[name]
      agreement:!this.state.agreement,
      privacy:!this.state.privacy
    }, () => {
      this.haveSubmit();
    })
  }
  //获取验证码不需要验证的接口
  nochategetEmailCode = () => {
    if (
      (this.state.codeText !== "获取验证码" &&
        this.state.codeText !== "重新获取") ||
      !(/^1[3456789]\d{9}$/.test(this.state.tel))
    ) {
      return false;
    }
    let nums = 60;
    let timer = () => {
      this.setState({
        codeText: nums > 0 ? nums + " s" : "重新获取"
      });
      nums -= 1;
      if (nums >= 0) {
        clearTimeout(this.codetimer)
        this.codetimer = setTimeout(timer, 1000)
      } else {
        nums = 0;
      }
    };
    $.ajax({
      type: "GET",
      url:
        "https://shl-info.cn/front/user/getphonecode?phone=" +
        encodeURIComponent(encrypt(this.state.tel))+"&usertype=1" ,
      dataType: "json",
      cache: false,
      success: res => {


        if(res.code==2001){
          this.setState({
            masktext: res.msg,
            maskfalg: true
          })
        }else{
          timer();
        }
       }
    });
  };



  //获取登录验证码的接口
  getphoneCode = () => {
    if (
      (this.state.codeText !== "获取验证码" &&
        this.state.codeText !== "重新获取") ||
      !(/^1[3456789]\d{9}$/.test(this.state.tel))
    ) {
      return false;
    }
    let nums = 60;
    let timer = () => {
      this.setState({
        codeText: nums > 0 ? nums + " s" : "重新获取"
      });
      nums -= 1;
      if (nums >= 0) {
        clearTimeout(this.codetimer)
        this.codetimer = setTimeout(timer, 1000)
      } else {
        nums = 0;
      }
    };
    timer();
    $.ajax({
      type: "GET",
      url:
        "https://shl-info.cn/front/user/getphonecode?phone=" +
        encodeURIComponent(encrypt(this.state.tel)) + '&islogin=1',
      dataType: "json",
      cache: false,
      success: res => { }
    });
  };


  // 获取验证码
  getEmailCodefornew = () => {
    if (
      (this.state.codeText !== "获取验证码" &&
        this.state.codeText !== "重新获取") ||
      !this.props.emailexg.test(this.state.email)
    ) {
      return false;
    }
    let nums = 60;
    let timer = () => {
      this.setState({
        codeText: nums > 0 ? nums + " s" : "重新获取"
      });
      nums -= 1;
      if (nums >= 0) {
        clearTimeout(this.codetimer)
        this.codetimer = setTimeout(timer, 1000)
      } else {
        nums = 0;
      }
    };
    timer();
    $.ajax({
      type: "GET",
      url:
        "https://shl-info.cn/front/user/noIdentifying?email=" +
        encodeURIComponent(encrypt(this.state.email)),
      dataType: "json",
      cache: false,
      success: res => { }
    });
  };
  // 获取验证码
  getEmailCode = () => {
    if (
      (this.state.codeText !== "获取验证码" &&
        this.state.codeText !== "重新获取") ||
      !this.props.emailexg.test(this.state.email)
    ) {
      return false;
    }
    let nums = 60;
    let timer = () => {
      this.setState({
        codeText: nums > 0 ? nums + " s" : "重新获取"
      });
      nums -= 1;
      if (nums >= 0) {
        clearTimeout(this.codetimer)
        this.codetimer = setTimeout(timer, 1000)
      } else {
        nums = 0;
      }
    };
    timer();
    $.ajax({
      type: "GET",
      url:
        "https://shl-info.cn/front/user/identifying?email=" +
        encodeURIComponent(encrypt(this.state.email)),
      dataType: "json",
      cache: false,
      success: res => { }
    });
  };

  checkHtml = (htmlStr) => {
      var  reg = /<[^>]+>/g;
      return reg.test(htmlStr);
  }

  loginClickthi() {
    let submitfalg = true;
    let submitText = '';
    console.log(!(/^1[3456789]\d{9}$/.test(!this.state.tel)))
    
    console.log(this.checkHtml(this.state.name))

    //|| !this.state.email || !this.state.hotiltype || !this.state.province || !this.state.hotillevel
    if (!this.state.name || !this.state.code || !this.state.tel || !this.state.department || !this.state.hotail  || !this.state.office ) {
      submitfalg = false;
      submitText = '请填写完整'
    } 
    // else if (!this.props.emailexg.test(this.state.email)) {
    //   submitfalg = false;
    //   submitText = '邮箱格式错误'
    // } 
    else if (!(/^1[3456789]\d{9}$/.test(this.state.tel))) {
      submitfalg = false;
      submitText = '手机格式错误'

    } else if (!this.state.privacy || !this.state.agreement) {
      submitfalg = false;
      submitText = '请勾选申明与协议'
    }
    //||this.checkHtml(this.state.email)|
    if(this.checkHtml(this.state.name)|this.checkHtml(this.state.code)||this.checkHtml(this.state.tel)||this.checkHtml(this.state.department)||this.checkHtml(this.state.hotail)||this.checkHtml(this.state.office)){
      submitfalg = false;
      submitText = '不可输入内容'
    }

    if (submitfalg) {
      console.log('提交');
      console.log(this.state.email, this.state.name, this.state.code, this.state.hotail, this.state.department, this.state.tel, this.state.hotillevel, this.state.office, this.state.hotiltype, this.state.province)
      // let sourcechannel = localStorage.getItem('sourcechannel');
      // if(sourcechannel=='null' || sourcechannel =='undefined'){
      //   sourcechannel='';
      // }
      
      let sourcechannel = localStorage.getItem('sourcechannel')?localStorage.getItem('sourcechannel'):"domainurl";

      console.log(sourcechannel)
      $.ajax({
        type: "GET",
        url: "https://shl-info.cn/front/user/userreg?name=" +
          encodeURI(this.state.name, "UTF-8") +
          // "&email=" +
          // encodeURIComponent(encrypt(this.state.email)) +
          "&code=" +
          this.state.code +
          "&hospital=" +
          this.state.hotail +
          "&offices=" +
          this.state.department +
          "&office=" +
          this.state.office +
          "&hotillevel=" +
          this.state.hotillevel +
          "&hotiltype=" +
          this.state.hotiltype +
          "&province=" +
          this.state.province +
          "&registsource=2"+
          "&checkcode=" +
          this.state.checkcode +
          "&phone=" +
          encodeURIComponent(encrypt(this.state.tel)) + '&openid=' + this.codetext+
          '&sourcechannel=' + sourcechannel+ 
          "&courseid=" + this.state.courseid+ 
          "&isbbs=" + this.state.isbbs+ 
          "&articleid=" + this.state.bbsid+ 
          "&topicid=" + this.state.topicid+
          "&visitorid=" + localStorage.getItem('visitor_num'),
        dataType: "json",
        cache: false,
        success: (res) => {
          console.log(res);
          if (res.status === 1) {
            localStorage.setItem('userid', res.userid)
            localStorage.setItem('token', res.token)
            
            localStorage.setItem('isdi', res.list.isdi)

            localStorage.setItem('phone', res.list.phone)
            localStorage.setItem('name', res.list.name)
            localStorage.setItem('email', res.list.email)

            this.props.change_userinfo({
              token: res.token,
              userid: res.userid,
              pic: res.pic,
              info: res.list
            })
            localStorage.setItem('issignstate', 1);
            
            this.setState({
              changeindustylayerstate:true,
              jumpurl:res.jumpurl?res.jumpurl:'',
            })
            // var refurl = localStorage.getItem('refurl');
            // if (refurl && refurl != '') {

            //   window.location.href = refurl
            // } else if (res.isfirst === 1) {
            //   this.props.history.push("/autographin");
            // } else {
            //   this.props.history.push("/home");
            // }


          } else {
            this.setState({
              masktext: res.status === 3 ? "验证码错误" : (res.status === 4 ? "验证码失效，请重新获取" : "请检查您输入的信息，是否填写了重复注册信息，或格式有错误，谢谢！"),
              maskfalg: true
            })
          }
        }
      })
    } else {
      this.setState({
        masktext: submitText,
        maskfalg: true
      })
    }


    console.log(submitfalg)
  }

  loginClicksecuserinput() {
    let submitfalg = true;
    let submitText = '';

    if (
      // !this.state.name || 
      !this.state.email || !this.state.code ) {
    //if (!this.state.name || !this.state.email || !this.state.code || !this.state.tel || !this.state.department) {
      submitfalg = false;
      submitText = '请填写完整'
    } else if (!this.props.emailexg.test(this.state.email)) {
      submitfalg = false;
      submitText = '邮箱格式错误'
    }  else if (!this.state.privacy || !this.state.agreement) {
      submitfalg = false;
      submitText = '请勾选申明与协议'
    }
    if(this.checkHtml(this.state.code)||this.checkHtml(this.state.email)){
      submitfalg = false;
      submitText = '不可输入内容'
    }

    if (submitfalg) {
      console.log('提交');
      let url = '';
      if (this.state.isneistate) {
        url = 'https://shl-info.cn/front/audit/register';
      } else {
        url = 'https://shl-info.cn/front/user/employeelogin';
      }
      $.ajax({
        type: "GET",
        url: url + "?name=" +
          encodeURI(this.state.name, "UTF-8") +
          "&email=" +
          encodeURIComponent(encrypt(this.state.email))  +
          // "&phone=" +
          // this.state.tel +
          // "&bulistid=" +
          // this.state.department +
          "&code=" +
          this.state.code + '&openid=' + this.codetext,
        dataType: "json",
        cache: false,
        success: (res) => {
          if (this.state.isneistate) {

            if (res.status === 5) {
              this.setState({
                masktext: "注册成功，请等待审核结果！",
                maskfalg: true
              })
            } else {
              this.setState({
                masktext: res.status === 3 ? "验证码错误" : (res.status === 4 ? "验证码失效，请重新获取" : "请检查您输入的信息，是否填写了重复注册信息，或格式有错误，谢谢！"),
                maskfalg: true
              })
            }
          } else {
            if (res.status === 1) {
              localStorage.setItem('userid', res.userid)
              localStorage.setItem('token', res.token)
              
            localStorage.setItem('isdi', res.list.isdi)

              localStorage.setItem('phone', res.list.phone)
              localStorage.setItem('name', res.list.name)
              localStorage.setItem('email', res.list.email)

              this.props.change_userinfo({
                token: res.token,
                userid: res.userid,
                pic: res.pic,
                info: res.list
              })
              localStorage.setItem('issignstate', 1);

              var refurl = localStorage.getItem('refurl');
              if (refurl && refurl != '') {

                if(localStorage.getItem('isapp')==1){
                  localStorage.setItem('isapp',"");
                  var addlink = "?"
                  if(refurl.indexOf('?')>=0){
                    addlink = "&"
                  }
                  window.location.href = refurl+addlink+"token="+encodeURIComponent(encrypt(res.userid.toString()))
                }else{
                  window.location.href = refurl
                }
                // window.location.href = refurl
              } else if (res.isfirst === 1) {
                this.props.history.push("/autographin");
              } else {
                this.props.history.push("/home");
              }
            } else if (res.status === 6) {
              this.setState({
                masktext: "您不是内部员工，请切换身份登录",
                maskfalg: true
              })
            } else {
              this.setState({
                masktext: res.status === 3 ? "验证码错误" : (res.status === 4 ? "验证码失效，请重新获取" : "请检查您输入的信息，是否填写了重复注册信息，或格式有错误，谢谢！"),
                maskfalg: true
              })
            }
          }
        }
      })
    } else {
      this.setState({
        masktext: submitText,
        maskfalg: true
      })
    }


    console.log(submitfalg)
  }
  loginClicksec() {
    let submitfalg = true;
    let submitText = '';
    console.log(!(/^1[3456789]\d{9}$/.test(!this.state.tel)))
    if (!this.state.name || !this.state.email || !this.state.code || !this.state.tel || !this.state.department) {
      submitfalg = false;
      submitText = '请填写完整'
    } else if (!this.props.emailexg.test(this.state.email)) {
      submitfalg = false;
      submitText = '邮箱格式错误'
    } else if (!(/^1[3456789]\d{9}$/.test(this.state.tel))) {
      submitfalg = false;
      submitText = '手机格式错误'

    } else if (!this.state.privacy || !this.state.agreement) {
      submitfalg = false;
      submitText = '请勾选申明与协议'
    }

    if (submitfalg) {
      console.log('提交');

      $.ajax({
        type: "GET",
        url: "https://shl-info.cn/front/user/employeelogin?name=" +
          encodeURI(this.state.name, "UTF-8") +
          "&email=" +
          this.state.email +
          "&phone=" +
          this.state.tel +
          "&bu=" +
          this.state.department +
          "&code=" +
          this.state.code + '&openid=' + this.codetext,
        dataType: "json",
        cache: false,
        success: (res) => {
          if (res.status === 1) {
            localStorage.setItem('userid', res.userid)
            localStorage.setItem('token', res.token)
            
            localStorage.setItem('isdi', res.list.isdi)

            localStorage.setItem('phone', res.list.phone)
            localStorage.setItem('name', res.list.name)
            localStorage.setItem('email', res.list.email)

            this.props.change_userinfo({
              token: res.token,
              userid: res.userid,
              pic: res.pic,
              info: res.list
            })
            localStorage.setItem('issignstate', 1);

            var refurl = localStorage.getItem('refurl');
            if (refurl && refurl != '') {

              if(localStorage.getItem('isapp')==1){
                localStorage.setItem('isapp',"");
                var addlink = "?"
                if(refurl.indexOf('?')>=0){
                  addlink = "&"
                }
                window.location.href = refurl+addlink+"token="+encodeURIComponent(encrypt(res.userid.toString()))
              }else{
                window.location.href = refurl
              }
              // window.location.href = refurl
            } else if (res.isfirst === 1) {
              this.props.history.push("/autographin");
            } else {
              this.props.history.push("/home");
            }
          } else {
            this.setState({
              masktext: res.status === 3 ? "验证码错误" : (res.status === 4 ? "验证码失效，请重新获取" : "请检查您输入的信息，是否填写了重复注册信息，或格式有错误，谢谢！"),
              maskfalg: true
            })
          }
        }
      })
    } else {
      this.setState({
        masktext: submitText,
        maskfalg: true
      })
    }


    console.log(submitfalg)
  }
  // 提交部分
  loginClick() {
    let submitfalg = true;
    let submitText = '';
    if (this.state.isthisnum) {
      //if (!this.state.name || !this.state.tel || !this.state.code) {
      if ( !this.state.tel || !this.state.code) {
        submitfalg = false;
        submitText = '请填写完整'
      } else if (!(/^1[3456789]\d{9}$/.test(this.state.tel))) {
        submitfalg = false;
        submitText = '手机格式错误'
      } else if (!this.state.privacy || !this.state.agreement) {
        submitfalg = false;
        submitText = '请勾选申明与协议'
      }
      if(this.checkHtml(this.state.code)||this.checkHtml(this.state.tel)){
        submitfalg = false;
        submitText = '不可输入内容'
      }

    } else {

      // if (!this.state.name || !this.state.email || !this.state.code) {
      if ( !this.state.email || !this.state.code) {
        submitfalg = false;
        submitText = '请填写完整'
      } else if (!this.props.emailexg.test(this.state.email)) {
        submitfalg = false;
        submitText = '邮箱格式错误'
      } else if (!this.state.privacy || !this.state.agreement) {
        submitfalg = false;
        submitText = '请勾选申明与协议'
      }
      if(this.checkHtml(this.state.code)||this.checkHtml(this.state.email)){
        submitfalg = false;
        submitText = '不可输入内容'
      }
    }
    if (submitfalg) {
      if (this.state.isthisnum) {

        $.ajax({
          type: "GET",
          url: "https://shl-info.cn/front/user/phonelogin?name=" +
            encodeURI(this.state.name, "UTF-8") +
            "&phone=" +
            encodeURIComponent(encrypt(this.state.tel))  +
            "&code=" +
            this.state.code + '&openid=' + this.codetext+
            "&checkcode=" +
            this.state.checkcode+
            "&visitorid=" + localStorage.getItem('visitor_num'),
          dataType: "json",
          cache: false,
          success: (res) => {
            if (res.status === 1) {
              localStorage.setItem('userid', res.userid)
              localStorage.setItem('token', res.token)
              
              localStorage.setItem('isdi', res.list.isdi)

              localStorage.setItem('phone', res.list.phone)
              localStorage.setItem('name', res.list.name)
              localStorage.setItem('email', res.list.email)

              this.props.change_userinfo({
                token: res.token,
                userid: res.userid,
                pic: res.pic,
                info: res.list
              })
              localStorage.setItem('issignstate', 1);

              var refurl = localStorage.getItem('refurl');
              if (refurl && refurl != '') {

                if(localStorage.getItem('isapp')==1){
                  localStorage.setItem('isapp',"");
                  var addlink = "?"
                  if(refurl.indexOf('?')>=0){
                    addlink = "&"
                  }
                  window.location.href = refurl+addlink+"token="+encodeURIComponent(encrypt(res.userid.toString()))
                }else{
                  window.location.href = refurl
                }
                // window.location.href = refurl
              } else if (res.isfirst === 1) {
                this.props.history.push("/autographin");
              } else {
                this.props.history.push("/home");
              }
            } else {
              this.setState({
                masktext: res.status === 3 ? "验证码错误" : (res.status === 4 ? "验证码失效，请重新获取" :res.status === 21?"此账号是内部帐号，用BP或员工通道登录": "请检查您输入的信息，是否填写了重复注册信息，或格式有错误，谢谢！"),
                maskfalg: true
              })
            }
          }
        })
      } else {

        $.ajax({
          type: "GET",
          url: "https://shl-info.cn/front/user/login?name=" +
            encodeURI(this.state.name, "UTF-8") +
            "&email=" +
            encodeURIComponent(encrypt(this.state.email)) +
            "&code=" +
            this.state.code + '&openid=' + this.codetext+
            "&visitorid=" + localStorage.getItem('visitor_num'),
          dataType: "json",
          cache: false,
          success: (res) => {
            console.log(res)
            if (res.status === 1) {
              localStorage.setItem('userid', res.userid)
              localStorage.setItem('token', res.token)
              
            localStorage.setItem('isdi', res.list.isdi)
              localStorage.setItem('phone', res.list.phone)
              localStorage.setItem('name', res.list.name)
              localStorage.setItem('email', res.list.email)
              this.props.change_userinfo({
                token: res.token,
                userid: res.userid,
                pic: res.pic,
                info: res.list
              })
              localStorage.setItem('issignstate', 1);
              var refurl = localStorage.getItem('refurl');
              if (refurl && refurl != '') {

                if(localStorage.getItem('isapp')==1){
                  localStorage.setItem('isapp',"");
                  var addlink = "?"
                  if(refurl.indexOf('?')>=0){
                    addlink = "&"
                  }
                  window.location.href = refurl+addlink+"token="+encodeURIComponent(encrypt(res.userid.toString()))
                }else{
                  window.location.href = refurl
                }
                // window.location.href = refurl
              } else if (res.isfirst === 1) {
                this.props.history.push("/autographin");
              } else {
                this.props.history.push("/home");
              }
            } else {
              this.setState({
                masktext: res.status === 3 ? "验证码错误" : (res.status === 4 ? "验证码失效，请重新获取" : "请检查您输入的信息，是否填写了重复注册信息，或格式有错误，谢谢！"),
                maskfalg: true
              })
            }
          }
        })
      }
    } else {
      this.setState({
        masktext: submitText,
        maskfalg: true
      })
    }
  }
  closeMaskTiShi = () => {
    this.setState({
      maskfalg: false
    })
  }
  changelogin(e) {
    console.log(e)
    console.log(this)
    if (e == 4) {
      e = 1;
      this.setState({
        isthisnum: true,
      })
    } else {
      this.setState({
        isthisnum: false,
      })
    }
    if (e == 5) {
      if(!this.state.isneistate){
        window.addreadlogs('注册')
      }
      this.setState({
        isneistate: !this.state.isneistate
      })
      
      return false;
    } else {
    }
    this.setState({
      thisnum: e
    })
    let that = this
    setTimeout(function () {
      console.log(that.state.thisnum)
    }, 1000)
  }
  // 判断是否可以提交
  haveSubmit() {
  
    if (this.state.thisnum == 2) {  
      console.log(1111)
      this.setState({
        fdtime: (() => {
          if (
            // this.state.name
            // && 
            // this.state.email
            // && this.state.code&&
            // && this.state.department
            // && this.state.department != '请选择'
            // && this.state.tel
            //&& 
            this.state.privacy
            && this.state.agreement
            ) {
            this.setState({
              submitActive: true
            })
          } else {
            this.setState({
              submitActive: false
            })
          }
        })()
      })
    } else if (this.state.thisnum == 3) {
     // console.log(this.state.name, this.state.email, this.state.code, this.state.department, this.state.hotail, this.state.office, this.state.hotillevel, this.state.hotiltype, this.state.province, this.state.tel, this.state.privacy, this.state.agreement)
      this.setState({
        fdtime: (() => {
          //console.log(22222)
          if (this.state.name
            // && this.state.email
            && this.state.code
            && this.state.department
            && this.state.department != '请选择'
            && this.state.hotail
            && this.state.office
            && this.state.office != '请选择'


            // && this.state.hotillevel
            // && this.state.hotillevel != '请选择'
            // && this.state.hotiltype
            // && this.state.hotiltype != '请选择'
            // && this.state.province
            // && this.state.province != '请选择'


            && this.state.tel
            && this.state.privacy
            && this.state.agreement) {
            this.setState({
              submitActive: true
            })
          } else {
            this.setState({
              submitActive: false
            })
          }
        })()
      })
    } else if (this.state.thisnum == 1) {
      console.log(this.state.isthisnum)
      if (this.state.isthisnum) {
        this.setState({
          fdtime: (() => {
            if (
              // this.state.name && 
              this.state.tel
              && this.state.code
              && this.state.privacy
              && this.state.agreement) {
              this.setState({
                submitActive: true
              })
            } else {
              this.setState({
                submitActive: false
              })
            }
          })()
        })
      } else {
        this.setState({
          fdtime: (() => {
            if (
              //this.state.name && 
              this.state.email
              && this.state.code
              && this.state.privacy
              && this.state.agreement) {
              this.setState({
                submitActive: true
              })
            } else {
              this.setState({
                submitActive: false
              })
            }
          })()
        })
      }
    }
  }
  agreeClose(name, e) {
    if (name === 'privacy') {
      this.setState({
        privacyMask: false,
        
        agreement:true,
        privacy:true
      }, () => {
        this.haveSubmit();
      })
    } else {
      this.setState({
        agreeMask: false,
        agreement:true,
        privacy:true
      }, () => {
        this.haveSubmit();
      })
    }
  }

  contentus = () => {
    window.location.href = "https://www.healthcare.siemens.com.cn/how-can-we-help-you/email-us";
    // this.props.history.push('');
  }
  aoutbtus = () => {
    window.location.href = "https://www.healthcare.siemens.com.cn/about";
    // this.props.history.push('');
  }
  gothiurl = () => {
    window.location.href = "https://beian.miit.gov.cn";
    // this.props.history.push('');
  }
  // 展示弹窗
  showMask(name, e) {
    if (name === 'privacy') {
      this.setState({
        privacyMask: true
      })
    } else {
      this.setState({
        agreeMask: true
      })
    }
  }
  closeMask() {
    this.setState({
      privacyMask: false,
      agreeMask: false
    })
    clearInterval(this.getwechet)
  }
  addlogin =()=>{
    console.log(this.state.submitActive)
    //if(this.state.submitActive){
      window.location.href="https://shl-info.cn/signin";
    //}
  }
  showWechet = () => {
    this.setState({
      wechetcode: true
    })
    // 请求key值
    $.ajax({
      type: "GET",
      url: "https://shl-info.cn/front/frontc/createkey",
      dataType: "json",
      cache: false
    }).then(res => {
      if (res.status === 1) {
        this.keynum = res.keynum;
        this.setState({
          wechetimgurl:
            "https://shl-info.cn/front/frontc/createcodeqr?keynum=" +
            res.keynum
        });
      }
    });
    // 轮询
    this.getwechet = setInterval(() => {
      $.ajax({
        type: "GET",
        url:
          "https://shl-info.cn/front/frontc/pclogcheck?keynum=" +
          this.keynum,
        dataType: "json",
        cache: false
      }).then(res => {
        if (res.status === 1) {
          clearInterval(this.getwechet);
          localStorage.setItem("userid", res.userid);
          localStorage.setItem("token", res.token);

          localStorage.setItem('phone', res.list.phone)
          localStorage.setItem('name', res.list.name)
          localStorage.setItem('email', res.list.email)

          this.props.change_userinfo({
            token: res.token,
            userid: res.userid,
            pic: res.pic,
            info: res.list
          });

          var refurl = localStorage.getItem('refurl');
          if (refurl && refurl != '') {

            if(localStorage.getItem('isapp')==1){
              localStorage.setItem('isapp',"");
              var addlink = "?"
              if(refurl.indexOf('?')>=0){
                addlink = "&"
              }
              window.location.href = refurl+addlink+"token="+encodeURIComponent(encrypt(res.userid.toString()))
            }else{
              window.location.href = refurl
            }
            // window.location.href = refurl
          } else if (res.isfirst === 1) {
            this.props.history.push("/autographin");
          } else {
            this.props.history.push("/home");
          }
        } else if (res.status === 4) {
          clearInterval(this.getwechet);
          this.codetext = res.openid ? res.openid : "";
          this.setState({
            wecheterror: true
          });
        } else if (res.status === 2) {
          clearInterval(this.getwechet);
        }
      });
    }, 2000)
  }
  closeWechet = () => {
    this.setState({
      wechetcode: false
    })
  }

  bindHandleScroll=(event)=>{
    // 滚动的高度
   console.log( $('body').scrollTop())
   if($('body').scrollTop()>=1200){
     $('.showlogintextbox_array').hide();
   }else{
    $('.showlogintextbox_array').show();
   }
    
}
  componentDidMount() {

    //window.addEventListener('scroll', this.bindHandleScroll)
    // let r = 'http%3A%2F%2Flocalhost%2Fweb%2Flogin%2Findex'
    // console.log(decodeURIComponent(r),33333)
    // return false;
    if(window.location.href.indexOf('backurl')>=0){
      var urls = this.getUrlkey(window.location.href);
      localStorage.setItem('refurl',decodeURIComponent(urls['backurl']));
      localStorage.setItem('isapp',"1");
    }
    

    $('body')[0].addEventListener('scroll', this.bindHandleScroll)


    var newstate = window.location.href.indexOf("isfrom=icp")

    if (localStorage.getItem('name') && localStorage.getItem('name') != null && localStorage.getItem('name') != 'null') {
      this.setState({
        name: localStorage.getItem('name'),
        email: localStorage.getItem('email'),
        tel: localStorage.getItem('phone'),
      })
    }
    console.log(this.state)
    console.log(localStorage.getItem('email'))


    if (newstate > -1) {
      console.log(11111111111111)
      this.setState({
        iscomefrom: true,
      })

      $.ajax({
        type: "GET",
        url: "https://shl-info.cn/front/user/loginnew",
        dataType: "json",
        cache: false,
        success: (res) => {
          console.log(res)
          if (res.status === 1) {
            localStorage.setItem('userid', res.userid)
            localStorage.setItem('token', res.token)
            
            localStorage.setItem('isdi', res.list.isdi)

            localStorage.setItem('phone', res.list.phone)
            localStorage.setItem('name', res.list.name)
            localStorage.setItem('email', res.list.email)

            this.props.change_userinfo({
              token: res.token,
              userid: res.userid,
              pic: res.pic,
              info: res.list
            })
            if (res.isfirst === 1) {
              //this.props.history.push("/autographin");
            } else {
              // this.props.history.push("/home");
            }
          }
        }
      })

    }


    let userid = localStorage.getItem('userid')
    let token = localStorage.getItem('token')
    if (!userid) {
      userid = '';
    }
    if(!userid){
      if(!localStorage.getItem('visitor_num')){
        let user_medical_num="visitor_"+ Math.floor(Math.random()*10000000000);
        localStorage.setItem("visitor_num",user_medical_num)
        userid=user_medical_num
      }else{
        userid=localStorage.getItem('visitor_num')
      }
    }
    fetch(
      "https://shl-info.cn/front/frontc/addlogs?userid=" +
      userid +
      "&urlstr=" +
      encodeURI(window.location.href) +
      "&title=" +
      document.title +
      "&keywords=" +
      '' +
      "&types=" +
      'read' +
      "&isspecial="+localStorage.getItem('special')+
      "&sourcechannel="+localStorage.getItem('sourcechannel')
    )



    if (userid && token) {
      // 验证是否过期
      fetch(
        "https://shl-info.cn/front/user/checkuser?userid=" +
        userid ,{
          headers:{
            'token':token
          }
        }
      )
        .then(data => {
          return data.json();
        })
        .then(res => {
          console.log(res)
          if (res.status === 1) {
            localStorage.setItem('usertype', res.list.usertype)
            
            localStorage.setItem('iswaitype', res.list.type)
            if(window.location.href.indexOf('backurl')>=0){
              var urls = this.getUrlkey(window.location.href);
              localStorage.setItem('refurl',decodeURIComponent(urls['backurl']));
              let refurl = decodeURIComponent(urls['backurl'])
              localStorage.setItem('isapp',"");
              var addlink = "?"
              if(refurl.indexOf('?')>=0){
                addlink = "&"
              }
              window.location.href = refurl+addlink+"token="+encodeURIComponent(encrypt(userid.toString()))
            }else{
              this.props.history.push('./home');
            }
          } else {

          }
        });
    } else {

    }

    if (window.location.href.indexOf('mailcode') != -1) {
      var urls = this.getUrlkey(window.location.href);
      console.log(urls.mailcode)

      $.ajax({
        type: "GET",
        url: "https://shl-info.cn/mslogin" + "?mailcode=" + urls.mailcode,
        dataType: "json",
        cache: false,
        success: (res) => {
          if(res.userid){
            localStorage.setItem('userid', res.userid)
              localStorage.setItem('token', res.token)
              
              localStorage.setItem('isdi', res.list.isdi)

              localStorage.setItem('phone', res.list.phone)
              localStorage.setItem('name', res.list.name)
              localStorage.setItem('email', res.list.email)

              this.props.change_userinfo({
                token: res.token,
                userid: res.userid,
                pic: res.pic,
                info: res.list
              })
              localStorage.setItem('issignstate', 1);

              var refurl = localStorage.getItem('refurl');
              if (refurl && refurl != '') {
                if(localStorage.getItem('isapp')==1){
                  localStorage.setItem('isapp',"");
                  var addlink = "?"
                  if(refurl.indexOf('?')>=0){
                    addlink = "&"
                  }
                  window.location.href = refurl+addlink+"token="+encodeURIComponent(encrypt(res.userid.toString()))
                }else{
                  window.location.href = refurl
                }
                // window.location.href = refurl
              } else if (res.isfirst === 1) {
                this.props.history.push("/autographin");
              } else {
                this.props.history.push("/home");
              }
          }
        }
      })
    }


    if (window.location.href.indexOf('qycode') != -1) {
      var urls = this.getUrlkey(window.location.href);
      console.log(urls.mailcode)

      $.ajax({
        type: "GET",
        url: "https://shl-info.cn/front/wechatenterprise/getcode?qycode=" + urls.qycode,
        dataType: "json",
        cache: false,
        success: (resinfo) => {
          console.log(resinfo)
          let res = resinfo.data;
          if(resinfo.code==2000){
            localStorage.setItem('userid', res.userid)
            localStorage.setItem('token', res.token)
            
            localStorage.setItem('isdi', res.list.isdi)

            localStorage.setItem('phone', res.list.phone)
            localStorage.setItem('name', res.list.name)
            localStorage.setItem('email', res.list.email)

            this.props.change_userinfo({
              token: res.token,
              userid: res.userid,
              pic: res.pic,
              info: res.list
            })
            localStorage.setItem('issignstate', 1);

            var refurl = localStorage.getItem('refurl');
            if (refurl && refurl != '') {

              if(localStorage.getItem('isapp')==1){
                localStorage.setItem('isapp',"");
                var addlink = "?"
                if(refurl.indexOf('?')>=0){
                  addlink = "&"
                }
                window.location.href = refurl+addlink+"token="+encodeURIComponent(encrypt(res.userid.toString()))
              }else{
                window.location.href = refurl
              }
              // window.location.href = refurl
            } else if (res.isfirst === 1) {
              this.props.history.push("/autographin");
            } else {
              this.props.history.push("/home");
            }
          }else{
            this.setState({
              masktext: "您不是内部员工，请切换身份登录",
              maskfalg: true
            })
          }
        }
      })
    }

    

    if (window.location.href.indexOf('reg=1') != -1) {
      this.setState({
        thisnum: 3,
        isthisnum: false,
      })
    }

    if (window.location.href.indexOf('courseid=') != -1) {
      var urls = this.getUrlkey(window.location.href);
      console.log(urls)
      let courseid=urls.courseid
      this.setState({
        courseid:courseid,
      })
    }

    if (window.location.href.indexOf('bbsid=') != -1) {
      var urls = this.getUrlkey(window.location.href);
      console.log(urls)
      let bbsid=urls.bbsid
      this.setState({
        bbsid:bbsid,
      })
    }
    if (window.location.href.indexOf('isbbs=') != -1) {
      var urls = this.getUrlkey(window.location.href);
      console.log(urls)
      let isbbs=urls.isbbs
      this.setState({
        isbbs:isbbs,
      })
    }
    if (window.location.href.indexOf('topicid=') != -1) {
      var urls = this.getUrlkey(window.location.href);
      console.log(urls)
      let topicid=urls.topicid
      this.setState({
        topicid:topicid,
      })
    }

    
    
    if (window.location.href.indexOf('isgrouplogin=1') >=0) {
      this.gotologinstate(3, 1)
      // this.setState({
      //   thisnum: 3,
      //   isthisnum: false,
      // })
    }

    // setTimeout(function(){
    //   var wwLogin = new WwLogin({
    //     "id": "wx_reg",  
    //     "appid": "",
    //     "agentid": "",
    //     "redirect_uri": "",
    //     "state": "",
    //     "href": "",
    //     "lang": "zh",
    // });
    // },3000)

    // this.setState({
    //   thisnum: 3,
    //   isthisnum: false,
    // })

  }
  componentWillUnmount() {

  }

  
  getUrlkey(url) {
    var params = {};
    var urls = url.split("?");                  ////console.log('1_分割url:', urls)
    var arr = urls[1].split("&");               ////console.log('2_分割urls[1]:', arr)
    for (var i = 0, l = arr.length; i < l; i++) {
      var a = arr[i].split("=");                ////console.log('3_遍历 arr 并分割后赋值给a:', a[0], a[1])
      params[a[0]] = a[1];                      ////console.log('4_a给params对象赋值:', params)
    }                                           ////console.log('5_结果:', params)
    return params;
  }

  changeloginbox(e) {
    let loginbox = {
      box1: false,
      box2: false,
      box3: false,
    }
    if (!this.state.loginbox[e]) {
      loginbox[e] = true;
    }
    console.log(this.state)
    console.log(loginbox)
    this.setState({
      loginbox: loginbox
    })
    let that = this

    let subkeywords = '';
    if (e == 'box1') {
      subkeywords = '医院专业人员'
    }
    if (e == 'box2') {
      subkeywords = '认证经销商'
    }
    if (e == 'box3') {
      subkeywords = '内部员工'
    }
    window.addreadlogs(subkeywords)
    
    
    setTimeout(function () {
      that.haveSubmit();
      console.log(that.state.loginbox)
    }, 1000)
  }


  gotologinstate = (index, num) => {
    console.log(index, num)

    let subkeywords = '';
    if (num == 3) {
      subkeywords = '预览'
    }
    if (num == 1) {
      subkeywords = '登录'
    }
    if (num == 2) {
      subkeywords = '注册'
    }
    window.addreadlogs(subkeywords)



    let that = this
    if (num == 3) {
      this.props.history.push('./home');
      return false;
    }
    if (index == 1) {
      if (num == 1) {
        this.setState({
          thisnum: 1,
          isthisnum: true,
        })
      } else {
        this.setState({
          thisnum: 3,
          isthisnum: false,
        })
      }
      setTimeout(function () {
        // that.state.wrapScroll.refresh();
        // that.state.secbox.refresh();
      }, 100)
    }

    if (index == 2) {
      this.setState({
        thisnum: 1,
        isthisnum: false,
      })
      // setTimeout(function () {
      //   that.state.wrapScroll.refresh();
      //   that.state.secbox.refresh();
      // }, 100)
    }


    if (index == 3) {
      console.log(12312312)
      if(this.state.wwLogin){

        this.state.wwLogin.unmount();
      }
     
       let url = "https://shl-info.cn/front/wechatenterprise/getcode?url="+encodeURIComponent(window.location.href)+"&type=1";
     
    //   window.location.href=url;
    //  return false;
      if (num == 1) {
        this.setState({
          thisnum: 2,
          isneistate: false,
          isthisnum: false,
        })
      } else {
        this.setState({
          thisnum: 2,
          isthisnum: false,
          isneistate: true,
        })
      }
      setTimeout(function () {
       that.setState({
         wwLogin:ww.createWWLoginPanel({
          el: '#ww_login',
          params: {
            login_type: 'CorpApp',
            appid: 'wwa6665f6a67ec05ab',
            agentid: '1000032',
            redirect_uri: url,
            state: 'loginState',
            redirect_type: 'callback',
            panel_size: 'small',
          },
          onCheckWeComLogin({ isWeComLogin }) {
            console.log(isWeComLogin)
          },
          onLoginSuccess({ code }) {
            console.log(code)
            if(code){
              // let url = "https://shl-info.cn/front/wechatenterprise/getcode?url="+encodeURIComponent(window.location.href)+"&type=1";


              $.ajax({
                type: "GET",
                url: "https://shl-info.cn/front/wechatenterprise/getcode?qycode=" + code,
                dataType: "json",
                cache: false,
                success: (resinfo) => {
                  console.log(resinfo)
                  let res = resinfo.data;
                  if(resinfo.code==2000){
                    localStorage.setItem('userid', res.userid)
                    localStorage.setItem('token', res.token)
                    
                    localStorage.setItem('isdi', res.list.isdi)
        
                    localStorage.setItem('phone', res.list.phone)
                    localStorage.setItem('name', res.list.name)
                    localStorage.setItem('email', res.list.email)
        
                    that.props.change_userinfo({
                      token: res.token,
                      userid: res.userid,
                      pic: res.pic,
                      info: res.list
                    })
                    localStorage.setItem('issignstate', 1);
        
                    var refurl = localStorage.getItem('refurl');
                    if (refurl && refurl != '') {
        
                      if(localStorage.getItem('isapp')==1){
                        localStorage.setItem('isapp',"");
                        var addlink = "?"
                        if(refurl.indexOf('?')>=0){
                          addlink = "&"
                        }
                        window.location.href = refurl+addlink+"token="+encodeURIComponent(encrypt(res.userid.toString()))
                      }else{
                        window.location.href = refurl
                      }
                      // window.location.href = refurl
                    } else if (res.isfirst === 1) {
                      that.props.history.push("/autographin");
                    } else {
                      that.props.history.push("/home");
                    }
                  }else{
                    that.setState({
                      masktext: "您不是内部员工，请切换身份登录",
                      maskfalg: true
                    })
                  }
                }
              })
            }
          },
          onLoginFail({err}) {
            console.log(err)
            that.setState({
              masktext: "登录失败，请刷新重试",
              maskfalg: true
            })
          },
        })
       }) 
        // that.state.wrapScroll.refresh();
        // that.state.secbox.refresh();
      }, 100)
    }

    let loginbox = {
      box1: false,
      box2: false,
      box3: false,
    }

    this.setState({
      loginbox: loginbox
    })


  }

  changeloginbtns =()=>{
    console.log(12312312)
    this.setState({
      showlogintext:true,
    })
  }
  gobackloginbox =()=>{
    console.log(12312312)
    this.setState({
      showlogintext:false,
    })
  }
  

  changeindusty = (text)=>{
    if(this.state.industylist.indexOf(','+text)>=0){
      
      let old = text+',';
      let industylist = this.state.industylist.replace(old, '');;
      this.setState({
        industylist:industylist
      })
    }else{
      let industylist = this.state.industylist+text+',';
      this.setState({
        industylist:industylist
      })
    }
  }


  jumpold = (index)=>{
    console.log(index)
    if(index==1){
      var refurl = localStorage.getItem('refurl');
      if(this.state.jumpurl){
        window.location.href = this.state.jumpurl
        return;
      }
      if (refurl && refurl != '') {
        // window.location.href = refurl
        
        if(localStorage.getItem('isapp')==1){
          localStorage.setItem('isapp',"");
          var addlink = "?"
          if(refurl.indexOf('?')>=0){
            addlink = "&"
          }
          window.location.href = refurl+addlink+"token="+encodeURIComponent(encrypt(localStorage.getItem('userid').toString()))
        }else{
          window.location.href = refurl
        }
      }else {
        this.props.history.push("/home");
      }
    }else{
      if(this.state.industylist==''){
        return 
      }
      let ids=localStorage.getItem('userid');
      fetch(
        "https://shl-info.cn/front/user/updateUserLikeProduct?userid=" +ids
        +
        "&likeproduct=" +
        this.state.industylist ,{
          headers:{
            'token':localStorage.getItem('token') 
          }
        }
      )
        .then(data => {
          return data.json();
        })
        .then(res => {
          console.log(res)
          var refurl = localStorage.getItem('refurl');
          if(this.state.jumpurl){
            window.location.href = this.state.jumpurl
            return;
          }
          if (refurl && refurl != '') {
            
            if(localStorage.getItem('isapp')==1){
              localStorage.setItem('isapp',"");
              var addlink = "?"
              if(refurl.indexOf('?')>=0){
                addlink = "&"
              }
              window.location.href = refurl+addlink+"token="+encodeURIComponent(encrypt(localStorage.getItem('userid').toString()))
            }else{
              window.location.href = refurl
            }
            // window.location.href = refurl
          }  else {
            this.props.history.push("/home");
          }
        })
      //提交信息

      


      console.log()
    }
  }

  
  render() {
    let maskbox = null;
    if (this.state.maskfalg) {
      maskbox = (
        <MaskBox
          info={
            {
              title: '登录',
              text: this.state.masktext,
            }
          }
          closeMask={this.closeMaskTiShi}></MaskBox>
      )
    }
    if(this.state.showlogintext){
      setTimeout(function(){
        $('body').animate({scrollTop: 950}, 1000);
        //$("body").scrollTop(1000);
      },3000)
    }
    let industylist = null
    if(this.state.changeindustylayerstate){
      industylist = (<div className="industylayer">
          <div className="industybox">
            <div className="industylogo">
              <img
                  alt=""
                  className="login-selectlogo"
                  src={require("../../static/images/logo_select.png")}
                />
            </div>
            <div  className="indstycontent">
              <div className="industy_title">感觉兴趣的产品</div>
              <div className="industy_iconbox">
                <div className={this.state.industylist.indexOf('移动C形臂')>=0?"industy_icon icon1 active":"industy_icon icon1"}  onClick={() => this.changeindusty("移动C形臂")}>
                <div className="industy_iconimg" >
                    <img
                    src={require("../../static/images/industlist/icon12.png")}
                    className=""
                    alt="logo"
                    ></img>
                  </div>
                  <div className="industy_text">移动C形臂</div>
                </div>
      
                <div className={this.state.industylist.indexOf('血管造影设备')>=0?"industy_icon icon2 active":"industy_icon icon2"}  onClick={() => this.changeindusty("血管造影设备")}>
                  <div className="industy_iconimg">
                    <img
                    src={require("../../static/images/industlist/at.png")}
                    className=""
                    alt="logo"
                    ></img>
                  </div>
                  <div className="industy_text">血管造影设备</div>
                </div>
      
                
                <div className={this.state.industylist.indexOf('CT计算机断层')>=0?"industy_icon icon3 active":"industy_icon icon3"}  onClick={() => this.changeindusty("CT计算机断层")}>
                  <div className="industy_iconimg">
                    <img
                    src={require("../../static/images/industlist/ct.png")}
                    className=""
                    alt="logo"
                    ></img>
                  </div>
                  <div className="industy_text">CT计算机断层</div>
                </div>
      
                
                
                <div className={this.state.industylist.indexOf('实验室诊断')>=0?"industy_icon icon4 active":"industy_icon icon4"}  onClick={() => this.changeindusty("实验室诊断")}>
                  <div className="industy_iconimg">
                    <img
                    src={require("../../static/images/industlist/dx.png")}
                    className=""
                    alt="logo"
                    ></img>
                  </div>
                  <div className="industy_text">实验室诊断</div>
                </div>
      
      
                
                <div className={this.state.industylist.indexOf('分子影像')>=0?"industy_icon icon5 active":"industy_icon icon5"}  onClick={() => this.changeindusty("分子影像")}>
                  <div className="industy_iconimg">
                    <img
                    src={require("../../static/images/industlist/mi.png")}
                    className=""
                    alt="logo"
                    ></img>
                  </div>
                  <div className="industy_text">分子影像</div>
                </div>
      
      
                
                <div className={this.state.industylist.indexOf('床旁诊断')>=0?"industy_icon icon6 active":"industy_icon icon6"}  onClick={() => this.changeindusty("床旁诊断")}>
                  <div className="industy_iconimg">
                    <img
                    src={require("../../static/images/industlist/poc.png")}
                    className=""
                    alt="logo"
                    ></img>
                  </div>
                  <div className="industy_text">床旁诊断</div>
                </div>
      
      
                
                <div className={this.state.industylist.indexOf('磁共振成像')>=0?"industy_icon icon7 active":"industy_icon icon7"}  onClick={() => this.changeindusty("磁共振成像")}>
                  <div className="industy_iconimg">
                    <img
                    src={require("../../static/images/industlist/mr.png")}
                    className=""
                    alt="logo"
                    ></img>
                  </div>
                  <div className="industy_text">磁共振成像</div>
                </div>
      
      
                
                <div className={this.state.industylist.indexOf(',X线摄影')>=0?"industy_icon icon8 active":"industy_icon icon8"}  onClick={() => this.changeindusty("X线摄影")}>
                  <div className="industy_iconimg">
                    <img
                    src={require("../../static/images/industlist/xp.png")}
                    className=""
                    alt="logo"
                    ></img>
                  </div>
                  <div className="industy_text">X线摄影</div>
                </div>
      
      
                
                <div className={this.state.industylist.indexOf('超声成像')>=0?"industy_icon icon9 active":"industy_icon icon9"}  onClick={() => this.changeindusty("超声成像")}>
                  <div className="industy_iconimg">
                    <img
                    src={require("../../static/images/industlist/us.png")}
                    className=""
                    alt="logo"
                    ></img>
                  </div>
                  <div className="industy_text">超声成像</div>
                </div>
      
      
                
                <div className={this.state.industylist.indexOf('医疗数字服务')>=0?"industy_icon icon10 active":"industy_icon icon10"}  onClick={() => this.changeindusty("医疗数字服务")}>
                  <div className="industy_iconimg">
                    <img
                    src={require("../../static/images/industlist/dig.png")}
                    className=""
                    alt="logo"
                    ></img>
                  </div>
                  <div className="industy_text">医疗数字服务</div>
                </div>
      
      
                <div className={this.state.industylist.indexOf('乳腺X线摄影')>=0?"industy_icon icon11 active":"industy_icon icon11"}  onClick={() => this.changeindusty("乳腺X线摄影")}>
                  <div className="industy_iconimg">
                    <img
                    src={require("../../static/images/industlist/icon11.png")}
                    className=""
                    alt="logo"
                    ></img>
                  </div>
                  <div className="industy_text">乳腺X线摄影</div>
                </div>
      
                
                
              </div>
              <div className="isloginbtnbox industybtnlistbox">
                  <div
                    className="login-submitreg"
                    onClick={()=>this.jumpold(1)}
                  >
                    跳过
                  </div>
      
                  <div
                    className="login-submitok"
                    onClick={()=>this.jumpold(2)}
                  >
                    确定
                  </div>
                </div>
            </div>
            <div className="icpicon newloginicp ">
                <div className="loginicpsnewbox">
                  <span onClick={this.gothiurl}>沪ICP备19037992号-2</span> 
                  {/* <br></br> */}
                  <a
                    href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502010367"
                    target="view_window"
                  >
                    沪公网安备31011502010367号
                  </a>
                  {/* <br></br> */}
                  <a href='https://shl-info.oss-cn-shanghai.aliyuncs.com/pdf/QCMISI.pdf' target="view_window">(沪)-经营性-2024-0040</a>
                  {/* <a
                    href="https://shl-info.cn/images/healthcare_certificate.pdf"
                    target="view_window"
                  >
                    (沪)-非经营性-2021-0063
                  </a>{" "} */}
                  <br></br>
                  版权声明：西门子医疗系统有限公司 @2021版权所有<br></br>
                  <span onClick={this.aoutbtus} className="aboutus">
                    关于西门子医疗
                  </span>
                  |
                  <span onClick={this.contentus} className="contentus">
                    联系我们
                  </span>
                </div>
              </div>
          </div>
      </div>)
    }
    return (
      
      <div className={this.state.thisnum==3 || (this.state.thisnum==1 && this.state.isthisnum ) ?(this.state.thisnum==1 && this.state.isthisnum )?this.state.showlogintext?"loginpage colorbgs colorbgsone showlogintextwrap":this.state.showlogintext?"loginpage colorbgs colorbgsone showlogintextwrap":"loginpage colorbgs   colorbgsone":"loginpage  showlogintextwrap colorbgs":"loginpage"} >
       
       {/* <Button>112312312</Button> */}
       
        {industylist}
        {this.state.showlogintext?
        <div className="showlogintextbox ">
          <div className="showlogintextboxlist">
            <img
                alt=""
                className="pc_away_newlogoonlog"
                src={require("../../static/images/pc_away_newlogo_write.png")}
              /> 
              <div className="showlogintextbox_banner1">
                <img src={require("../../static/images/login_banner1.png")}  />
              </div>
            <div className="showlogintextbox_banner2">
              <img src={require("../../static/images/login_banner2.png")}  />
            </div>
            <div className="gobackloginbox" onClick={()=>this.gobackloginbox()}>返回</div>
          </div>
          <div className="showlogintextbox_banner3">
            <img src={require("../../static/images/login_banner3.png")}  />
          </div>
          <div className="showlogintextbox_array">
            <img src={require("../../static/images/arrow.png")}  />
          </div>
        </div>
        :null}
        
        
        
        <div
          className={
            this.state.thisnum == 0
              ? "wrap loginwrapbox "
              : "wrap loginwrapbox none"
          }
        >
          <div className="login_selectbox center">
            <img
              alt=""
              className="login-selectlogo"
              src={require("../../static/images/logo_select.png")}
            />
            <div className="selectli" >
              {/* onClick={() => this.changelogin(3)} */}
             {/* <div className='newselectlis' onClick={() => this.changeloginbox('box1')}  ></div>  */}
              <div className='newselectlis' onClick={() => this.gotologinstate(1, 1)}  >
                <img
                  alt=""
                  className="iconlist"
                  src={require("../../static/images/select_icon1.png")}
                />
              医院专业人员
              <img
                  alt=""
                  className="nexticon"
                  src={require("../../static/images/gonextbottom.png")}
                />
              </div>
              <div className={this.state.loginbox.box1 ? "morselectli" : "morselectli none"}>
                <div className='morselectli_text' onClick={() => this.gotologinstate(1, 2)}>注册</div>
                <div className='morselectli_text' onClick={() => this.gotologinstate(1, 3)}>预览</div>
                <div className='morselectli_text' onClick={() => this.gotologinstate(1, 1)}>登录</div>
              </div>
            </div>
            <div className="selectli" >
              {/* onClick={() => this.changelogin(1)} */}
              {/* onClick={() => this.changeloginbox('box2')} */}
              <div className='newselectlis' onClick={() => this.gotologinstate(2, 1)}  >
                <img
                  alt=""
                  className="iconlist"
                  src={require("../../static/images/select_icon2.png")}
                />
              认证经销商
              <img
                  alt=""
                  className="nexticon"
                  src={require("../../static/images/gonextbottom.png")}
                />
              </div>
              <div className={this.state.loginbox.box2 ? "morselectli" : "morselectli none"}>
                {/* <div className='morselectli_text' onClick={() => this.gotologinstate(2, 3)}>预览</div> */}
                <div className='morselectli_text' onClick={() => this.gotologinstate(2, 1)}>登录</div>
              </div>
            </div>
            <div className="selectli" >
              {/* onClick={() => this.changelogin(2)} */}
              {/* <div className='newselectlis' onClick={() => this.changeloginbox('box3')}  > */}
              <div className='newselectlis' onClick={() => this.gotologinstate(3, 1)}  >
                <img
                  alt=""
                  className="iconlist"
                  src={require("../../static/images/select_icon3.png")}
                />
              内部员工
              <img
                  alt=""
                  className="nexticon"
                  src={require("../../static/images/gonextbottom.png")}
                />

              </div>
              <div className={this.state.loginbox.box3 ? "morselectli" : "morselectli none"}>
                {/* <div className='morselectli_text' onClick={() => this.gotologinstate(3, 3)}>预览</div> */}
                <div className='morselectli_text' onClick={() => this.gotologinstate(3, 1)}>登录</div>
                {/* <div className='morselectli_text' onClick={() => this.gotologinstate(3, 2)}>注册</div> */}
              </div>
            </div>

            <div className="icpicon newloginicp">
              <span onClick={this.gothiurl}>沪ICP备19037992号-2</span> <br></br>
              <a
                href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502010367"
                target="view_window"
              >
                沪公网安备31011502010367号
              </a>
              <br></br>
              <a href='https://shl-info.oss-cn-shanghai.aliyuncs.com/pdf/QCMISI.pdf' target="view_window">(沪)-经营性-2024-0040</a>
              {/* <a
                href="https://shl-info.cn/images/healthcare_certificate.pdf"
                target="view_window"
              >
                (沪)-非经营性-2021-0063
              </a>{" "} */}
              <br></br>
              版权声明：西门子医疗系统有限公司 @2021版权所有<br></br>
              <span onClick={this.aoutbtus} className="aboutus">
                关于西门子医疗
              </span>
              |
              <span onClick={this.contentus} className="contentus">
                联系我们
              </span>
            </div>
            {/* <div className="icpicon"  >
              <span onClick={this.gothiurl}>沪ICP备19037992号-2</span> <br></br>版权声明：西门子医疗系统有限公司 @2021版权所有<br></br>
              <span onClick={this.aoutbtus} className="aboutus" >关于西门子医疗</span>|<span onClick={this.contentus} className="contentus" >联系我们</span>
            </div> */}
          </div>
        </div>

        <div
          className={
            this.state.thisnum == 1 && !this.state.showlogintext
              ? "wrap loginwrap secwrap"
              : "wrap loginwrap secwrap none"
          }
        >
        {this.state.thisnum==1 && this.state.isthisnum ?
         <img
         alt=""
         className="pc_away_newlogoonlog"
        //  src={require("../../static/images/pc_away_newlogo_write.png")}
         src={require("../../static/images/logo_select.png")}
       /> 

    //    <img
    //    alt=""
    //    className="pc_away_newlogoonlog"
    //    src={require("../../static/images/pc_away_newlogo.png")}
    //  /> 
       :null}

            {/* {this.state.thisnum==1 && this.state.isthisnum ?
              <div className='pc_away_newlogo_tips'>
                <div className="pc_away_newlogo_tips_title">西影力通行证</div>
                <div className="pc_away_newlogo_tips_textbtns" onClick={()=>this.changeloginbtns()}>什么是西影力通行证？</div>
              </div>
            :null}
       */}



         
          <div
            className={this.state.thisnum==1 && this.state.isthisnum
              ?" login-container center onewrap addnewsthisnum"
              : this.state.iscomefrom
              ? " login-container center onewrap"
              : " login-container center onewrap"
            }
            // style={{height:"760px"}}
          >


            {this.state.thisnum==1 && this.state.isthisnum ?
              <div className='pc_away_newlogo_tips'>
                <div className="pc_away_newlogo_tips_title">西影力通行证</div>
                <div className="pc_away_newlogo_tips_textbtns" onClick={()=>this.changeloginbtns()}>什么是西影力通行证？</div>
              </div>
            :null}
      

            <img
              alt=""
              className="login-logo"
              src={require("../../static/images/logo.png")}
            />
            <img
              alt=""
              className="goback"
              src={require("../../static/images/goback.png")}
              onClick={this.goback}
            />
             
            {/* <div
              className={
                this.state.nameActive ? "login-form focus" : "login-form"
              }
            >
              <span className="form-name">中文姓名</span>
              <input
                className={this.state.name_place === "请输入" ? null : "errors"}
                name="name"
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onChange={this.valueChange}
                value={this.state.name}
                placeholder={this.state.name_place}
              ></input>
              <span
                className={
                  this.state.isthisnum ? "form-name none" : "form-name "
                }
              >
                *经销商输入公司全称，内部员工输入中文姓名
              </span>
            </div> */}
            {this.state.isthisnum ? (
              <div
                className={
                  this.state.telActive ? "login-form focus" : "login-form"
                }
              >
                
                <div className="logininputbox">       
                  <div className="logininputbox_title">手&nbsp;&nbsp;&nbsp;&nbsp;机</div>
                  <input
                    className={
                      this.state.tel_place === "请输入" ? null : "errors"
                    }
                    name="tel"
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onChange={this.valueChange}
                    value={this.state.tel}
                    placeholder={this.state.tel_place}
                  ></input>
                </div>
                {/* <span className="form-name">手机号码</span>
                <input
                  className={
                    this.state.tel_place === "请输入" ? null : "errors"
                  }
                  name="tel"
                  onFocus={this.onFocus}
                  onBlur={this.onBlur}
                  onChange={this.valueChange}
                  value={this.state.tel}
                  placeholder={this.state.tel_place}
                ></input> */}
              </div>
            ) : (
                <div
                  className={
                    this.state.emailActive ? "login-form focus" : "login-form"
                  }
                >
                  <span className="form-name">邮箱</span>
                  <input
                    className={
                      this.state.email_place === "请输入" ? null : "errors"
                    }
                    name="email"
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onChange={this.valueChange}
                    value={this.state.email}
                    placeholder={this.state.email_place}
                  ></input>
                </div>
              )}
            {/* <div className={this.state.emailActive ? 'login-form focus' : 'login-form'}>
              <span className="form-name">邮箱</span>
              <input
                className={this.state.email_place === '请输入' ? null : 'errors'}
                name="email"
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onChange={this.valueChange}
                placeholder={this.state.email_place}></input>
            </div> */}

            {this.state.isthisnum ? (
              <div
                className={
                  this.state.codeActive ? "login-form focus  " : "login-form "
                }
              >
                <div className="logininputbox">       
                  <div className="logininputbox_title">验证码</div>
                  <input
                    className={
                      this.state.phone_place === "请输入" ? null : "errors"
                    }
                    name="code"
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onChange={this.valueChange}
                    placeholder={this.state.phone_place}
                    value={this.state.code}
                  ></input>
                  <div
                    className={
                      !/^1[3456789]\d{9}$/.test(this.state.tel)
                        ? "code-btn cursor"
                        : "code-btn cursor active"
                    }
                    onClick={this.getphoneCode}
                  >
                    {this.state.codeText}
                  </div>
                </div>
                {/* <span className="form-name">手机验证码</span> */}
                {/* <input
                  className={
                    this.state.phone_place === "请输入" ? null : "errors"
                  }
                  name="code"
                  onFocus={this.onFocus}
                  onBlur={this.onBlur}
                  onChange={this.valueChange}
                  placeholder={this.state.phone_place}
                ></input>
                <div
                  className={
                    !/^1[3456789]\d{9}$/.test(this.state.tel)
                      ? "code-btn cursor"
                      : "code-btn cursor active"
                  }
                  onClick={this.getphoneCode}
                >
                  {this.state.codeText}
                </div> */}
              </div>
            ) : (
                <div
                  className={
                    this.state.codeActive ? "login-form focus" : "login-form"
                  }
                >
                  <span className="form-name">邮箱验证码</span>
                  <input
                    className={
                      this.state.phone_place === "请输入" ? null : "errors"
                    }
                    name="code"
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onChange={this.valueChange}
                    placeholder={this.state.phone_place}
                    
                    value={this.state.code}
                  ></input>
                  <div
                    className={
                      !this.props.emailexg.test(this.state.email)
                        ? "code-btn cursor"
                        : "code-btn cursor active"
                    }
                    onClick={this.getEmailCode}
                  >
                    {this.state.codeText}
                  </div>
                </div>
              )}
              {this.state.isthisnum && localStorage.getItem('isfromcs') ==1 ? (
                 <div
                 className={
                   this.state.checkcodeActive ? "login-form focus " : "login-form "
                 }
               >
                 <span className="form-name">兑换券码（选填）</span>
                 <input
                   className={
                     this.state.checkcode_place === "请输入" ? null : null
                   }
                   name="checkcode"
                   onFocus={this.onFocus}
                   onBlur={this.onBlur}
                   onChange={this.valueChange}
                   placeholder="请输入"
                 ></input>
               </div>
              ):null}
            {this.state.isthisnum ? (
              <p className="login-code-text">
                备注：验证码将发送到您手机中，请注意查收！
              </p>
            ) : (
                <p className="login-code-text">
                  备注：验证码将发送到您邮箱中，请注意查收！
                </p>
              )}

            <ul className="loginLabel">
              <li
                className={[
                  "fl cursor",
                  this.state.privacy ? "active" : "",
                ].join(" ")}
              >
                
              
                <span
                  className="labelSelect privacy"
                  onClick={(e) => this.labelChange("privacy", e)}
                ></span>
                  <span>阅读并同意</span>
                <span
                  className={this.state.isthisnum?"labelText active":"labelText"}
                  onClick={(e) => this.showMask("privacy", e)}
                >
                  隐私保护政策
                </span>
                <span>和</span>
                <span
                  className={this.state.isthisnum?"labelText active":"labelText"}
                  onClick={(e) => this.showMask("agreement", e)}
                >
                  用户使用条款
                </span>
              </li>
              {/* <li
                className={[
                  "fr cursor",
                  this.state.agreement ? "active" : "",
                ].join(" ")}
              >
                <span
                  className="labelSelect agreement"
                  onClick={(e) => this.labelChange("agreement", e)}
                ></span>
                <span
                  className="labelText"
                  onClick={(e) => this.showMask("agreement", e)}
                >
                  用户使用条款
                </span>
              </li> */}
            </ul>
            <div className="login-btnlist newbtnlist">

            <div className="isloginbtnbox">
              {this.state.isthisnum? <div
                    className="login-submitreg cursor"
                    onClick={() => this.gotologinstate(1, 2)}
                  >
                  注册
                </div>:null}

                <div
                  className={[
                    "login-submit cursor",
                    this.state.submitActive ? "active" : "",
                  ].join(" ")}
                  onClick={this.loginClick}
                >
                  登录
                </div>
              </div>
              {/* <div
                className={[
                  "login-submit cursor",
                  this.state.submitActive ? "active" : "",
                ].join(" ")}
                onClick={this.loginClick}
              >
                登录
              </div> */}
              
              <div className="wechet-btn none" onClick={this.showWechet}>
                <img
                  src={require("../../static/images/wecheticon.png")}
                  alt=""
                />
                微信登录
              </div>
            </div>
            
            {/* {this.state.isthisnum?<div className="hasid" style={{paddingTop:'20px'}}>
            没有账号？
                <span  className="cursor" onClick={() => this.gotologinstate(1, 2)}>点此注册 &gt;</span>
              </div>:null} */}

            <div className="icpicon newloginicp ">
              <div className="loginicpsnewbox">
                <span onClick={this.gothiurl}>沪ICP备19037992号-2</span> 
                {/* <br></br> */}
                <a
                  href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502010367"
                  target="view_window"
                >
                  沪公网安备31011502010367号
                </a>
                {/* <br></br> */}
                <a href='https://shl-info.oss-cn-shanghai.aliyuncs.com/pdf/QCMISI.pdf' target="view_window">(沪)-经营性-2024-0040</a>
                {/* <a
                  href="https://shl-info.cn/images/healthcare_certificate.pdf"
                  target="view_window"
                >
                  (沪)-非经营性-2021-0063
                </a>{" "} */}
                <br></br>
                版权声明：西门子医疗系统有限公司 @2021版权所有<br></br>
                <span onClick={this.aoutbtus} className="aboutus">
                  关于西门子医疗
                </span>
                |
                <span onClick={this.contentus} className="contentus">
                  联系我们
                </span>
              </div>
            </div>
            {/*             
            <div className="icpicon"  ><span onClick={this.gothiurl}>沪ICP备19037992号-2</span> <br></br>版权声明：西门子医疗系统有限公司 @2021版权所有<br></br><span onClick={this.aoutbtus} className="aboutus" >关于西门子医疗</span>|<span onClick={this.contentus} className="contentus" >联系我们</span></div> */}
          </div>
          <div
            className={this.state.iscomefrom ? "newts none " : " newts none"}
          ></div>
        </div>

        {/* 内部员工登录 */}
        <div
          className={
            this.state.thisnum == 2
              ? "wrap loginwrap secwrap thiwrap "
              : "wrap loginwrap secwrap thiwrap none"
          }
          style={{"minHeight":"1000px"}}
        >
          <div
            className={
              this.state.iscomefrom
                ? "login-container seclong center "
                : " login-container seclong center  "
            }
            style={{"height":"645px"}}
          >
            <img
              alt=""
              className="login-logo"
              src={require("../../static/images/logo.png")}
            />
            <img
              alt=""
              className="goback"
              src={require("../../static/images/goback.png")}
              onClick={this.goback}
            />
            {/* <div
              className={
                this.state.nameActive ? "login-form focus" : "login-form"
              }
            >
              <span className="form-name">中文姓名</span>
              <input
                className={this.state.name_place === "请输入" ? null : "errors"}
                name="name"
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onChange={this.valueChange}

                value={this.state.name}
                placeholder={this.state.name_place}
              ></input>
              <span className="form-name">
                *经销商输入公司全称，内部员工输入中文姓名
              </span>
            </div> */}

<div className="formbox_outsite">

<div className="ww_login" id="ww_login"></div>
            <div className="formbox">
           
           
              <div
              className={
                this.state.emailActive ? "login-form  focus " : "login-form  "
              }
            >
              <span className="form-name">邮箱</span>
              <input
                className={
                  this.state.email_place === "请输入" ? null : "errors"
                }
                name="email"
                onFocus={this.onFocus}
                onBlur={this.onBlur}

                value={this.state.email}
                onChange={this.valueChange}
                placeholder={this.state.email_place}
              ></input>
            </div>
            <div
              className={
                this.state.departmentActive ? "login-form focus none" : "login-form none"
              }
            >
              <span className="form-name ">部门</span>
              <select
                className={
                  this.state.department == "请选择" ||
                    this.state.department == ""
                    ? "userselect"
                    : "userselect active"
                }
                onChange={this.selectChange}
              >
                <option value="">请选择</option>
                <option value="1">DI</option>
                <option value="2">AT</option>
                <option value="3">CS</option>
                <option value="4">US</option>
                <option value="5">DX</option>
                <option value="6">CC</option>
                <option value="7">M&S</option>
                <option value="8">HR</option>
                <option value="9">DH</option>
                <option value="10">ES</option>
                <option value="11">FI</option>
                <option value="12">QT</option>
                <option value="13">LC</option>
                <option value="14">GMO</option>
                <option value="15">SSME</option>
                <option value="16">SSMR</option>
                <option value="17">SXVT</option>
                <option value="18">其他</option>
              </select>
              <img
                alt=""
                className="select_arrow"
                src={require("../../static/images/select_arrow.png")}
              />
            </div>
            <div
              className={
                this.state.telActive ? "login-form focus none" : "login-form none"
              }
            >
              <span className="form-name">手机号码</span>
              <input
                className={this.state.tel_place === "请输入" ? null : "errors"}
                name="tel"
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onChange={this.valueChange}

                value={this.state.tel}
                placeholder={this.state.tel_place}
              ></input>
            </div>
            <div
              className={
                this.state.codeActive ? "login-form  focus " : "login-form  "
              }
            >
              <span className="form-name">邮箱验证码</span>
              <input
                className={
                  this.state.phone_place === "请输入" ? null : "errors"
                }
                name="code"
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onChange={this.valueChange}
                placeholder={this.state.phone_place}
                value={this.state.code}
              ></input>
              <div
                className={
                  !this.props.emailexg.test(this.state.email)
                    ? "code-btn cursor"
                    : "code-btn cursor active"
                }
                onClick={this.getEmailCodefornew}
              >
                {this.state.codeText}
              </div>
            </div>
            <p className="login-code-text none">
              备注：验证码将发送到您邮箱中，请注意查收！
            </p>
            
           
            <ul className="loginLabel">
              <li
                className={[
                  "fl cursor",
                  this.state.privacy ? "active" : "",
                ].join(" ")}
              >
                <span
                  className="labelSelect privacy"
                  onClick={(e) => this.labelChange("privacy", e)}
                ></span>
                
                <span>阅读并同意</span>
                <span
                  className="labelText"
                  onClick={(e) => this.showMask("privacy", e)}
                >
                  隐私保护政策
                </span>
                
                <span>和</span>
                <span
                  className="labelText"
                  onClick={(e) => this.showMask("agreement", e)}
                >
                  用户使用条款
                </span>
              </li>
              {/* <li
                className={[
                  "fr cursor",
                  this.state.agreement ? "active" : "",
                ].join(" ")}
              >
                <span
                  className="labelSelect agreement"
                  onClick={(e) => this.labelChange("agreement", e)}
                ></span>
                <span
                  className="labelText"
                  onClick={(e) => this.showMask("agreement", e)}
                >
                  用户使用条款
                </span>
              </li> */}
            </ul>
              
            {/* <div
                className={[
                  "login-submit cursor  addloginbtn",
                  this.state.submitActive ? "active" : "",
                ].join(" ")}
                onClick={this.addlogin}
              >
                员工登录
              </div> */}


            <div className="login-btnlist newbtnlist ">
              <div
                className={[
                  "login-submit cursor  ",
                  this.state.submitActive ? "active " : " nonoe",
                ].join("  ")}
                onClick={this.loginClicksecuserinput}
              >
                {this.state.isneistate ? "提交注册" : "员工登录"}
              </div>
              <div className="wechet-btn none" onClick={this.showWechet}>
                <img
                  src={require("../../static/images/wecheticon.png")}
                  alt=""
                />
                微信登录
              </div>

              <div className="hasid none">
                {this.state.isneistate ? "已有账号？" : "没有账号？"}
                <span onClick={() => this.changelogin(5)}>  {this.state.isneistate ? "在此登录 >" : "点此注册 >"}</span>
              </div>

              {/* <div
                className={[
                  "login-submit cursor  ",
                  this.state.submitActive ? "active " : "",
                ].join(" ")}
                onClick={this.addlogin}
              >
                AAD登录
              </div> */}


              </div>

</div>

</div>

          <div className="addnewcard">新入职员工的帐号会在15天内创建完成</div>
<div className="addnoregts">
              如您登录过程中有任何问题，欢迎联系zefan.xu.ext@siemens-healthineers.com
              </div>   
              <div className="addnoregts none">
                若您还未注册，请联系您的部门助理或其他人员，
                统一提供部门需注册人员信息进行注册，
                联系zefan.xu.ext@siemens-healthineers.com，谢谢！
              </div>

            <div className="icpicon newloginicp">
              <span onClick={this.gothiurl}>沪ICP备19037992号-2</span> <br></br>
              <a
                href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502010367"
                target="view_window"
              >
                沪公网安备31011502010367号
              </a>
              <br></br>
              <a href='https://shl-info.oss-cn-shanghai.aliyuncs.com/pdf/QCMISI.pdf' target="view_window">(沪)-经营性-2024-0040</a>
              {/* <a
                href="https://shl-info.cn/images/healthcare_certificate.pdf"
                target="view_window"
              >
                (沪)-非经营性-2021-0063
              </a>{" "} */}
              <br></br>
              版权声明：西门子医疗系统有限公司 @2021版权所有<br></br>
              <span onClick={this.aoutbtus} className="aboutus">
                关于西门子医疗
              </span>
              |
              <span onClick={this.contentus} className="contentus">
                联系我们
              </span>
            </div>

            {/* <div className="icpicon"  >
              <span onClick={this.gothiurl}>沪ICP备19037992号-2</span> <br></br>版权声明：西门子医疗系统有限公司 @2021版权所有<br></br>
              <span onClick={this.aoutbtus} className="aboutus" >关于西门子医疗</span>|<span onClick={this.contentus} className="contentus" >联系我们</span>
            </div> */}
          </div>
          <div
            className={this.state.iscomefrom ? "newts none " : " newts none"}
          ></div>
        </div>
        {/* 外部游客登录 */}
        <div
          className={
            this.state.thisnum == 3  && !this.state.showlogintext
              ? "wrap loginwrap secwrap thiwrap "
              : "wrap loginwrap secwrap thiwrap none"
          }
        >
          <img
              alt=""
              className="pc_away_newlogo "
              src={require("../../static/images/pc_away_newlogo_write.png")}
            /> 
          
          <div
            className={
              this.state.iscomefrom
                ? "login-container thilong regnewbox center "
                : " login-container thilong regnewbox center  "
            }
          >

          <CascadePicker
            options={this.state.basicColumns}
            visible={this.state.visible}
            onClose={() => {
              this.setState({
                visible:false
              })
            }}
            onConfirm={(v, extend) => {
              this.setState({
                visible:false,
                hotiltype: v[0],
                hotillevel:v[1],
              }, () => {
                this.haveSubmit();
              })
            }}
          />
          
            {/* <img
              alt=""
              className="login-logo"
              src={require("../../static/images/logo.png")}
            /> */}
            {/* <img
              alt=""
              className="pc_away_newlogo_write"
              src={require("../../static/images/pc_away_newlogo_write.png")}
            /> */}
            <div className='pc_away_newlogo_tips'>
              <div className="pc_away_newlogo_tips_title">西影力通行证</div>
              
              <div className="pc_away_newlogo_tips_textbtns" onClick={()=>this.changeloginbtns()}>什么是西影力通行证？</div>
            </div>
            
            <img
              alt=""
              className="goback"
              src={require("../../static/images/goback.png")}
              onClick={this.goback}
            />
            {/* <div className='pc_wraplogo_tips'>多平台统一账户，学院、直播、回放等便捷通用</div> */}
            <div
              className={
                this.state.nameActive ? "login-form focus" : "login-form"
              }
            >
              <div className="logininputbox twolong">       
                  <div className="logininputbox_title twolong">姓名</div>
                  <input
                    className={this.state.name_place === "请输入" ? null : "errors"}
                    name="name"
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onChange={this.valueChange}
                    value={this.state.name}
                    placeholder={this.state.name_place}
                  ></input>
                </div>
              
              {/* <span className="form-name">*经销商输入公司全称，内部员工输入中文姓名</span> */}
            </div>

            <div
              className={
                this.state.telActive ? "login-form focus" : "login-form"
              }
            >
              {/* <span className="form-name">手机号码</span> */}
              <div className="logininputbox twolong">       
                  <div className="logininputbox_title twolong">手机</div>
                <input
                  className={this.state.tel_place === "请输入" ? null : "errors"}
                  name="tel"
                  onFocus={this.onFocus}
                  onBlur={this.onBlur}
                  onChange={this.valueChange}
                  value={this.state.tel}
                  placeholder={this.state.tel_place}
                ></input>
              </div>
            </div>
            <div
              className={
                this.state.codeActive ? "login-form focus" : "login-form"
              }
            >
              
              <div className="logininputbox thitextlong">       
                  <div className="logininputbox_title thitextlong">验证码</div>
                {/* <span className="form-name">手机验证码</span> */}
                <input
                  className={
                    this.state.phone_place === "请输入" ? null : "errors"
                  }
                  name="code"
                  onFocus={this.onFocus}
                  onBlur={this.onBlur}
                  onChange={this.valueChange}
                  placeholder={this.state.phone_place}
                  value={this.state.code}
                ></input>
                <div
                  className={
                    !/^1[3456789]\d{9}$/.test(this.state.tel)
                      ? "code-btn cursor"
                      : "code-btn cursor active"
                  }
                  onClick={this.nochategetEmailCode}
                >
                  {this.state.codeText}
                </div>
                <p className="login-code-textnews">
                  备注：验证码将发送到您的手机中，请注意查收！
                </p>
              </div>
             
            </div>

            

            {/* <div
              className={
                this.state.emailActive ? "login-form focus" : "login-form"
              }
            >
              <span className="form-name">邮箱</span>
              <input
                className={
                  this.state.email_place === "请输入" ? null : "errors"
                }
                name="email"
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onChange={this.valueChange}
                value={this.state.email}
                placeholder={this.state.email_place}
              ></input>
            </div> */}

            <div
              className={
                this.state.hotailActive ? "login-form focus" : "login-form"
              }
            >
              
              <div className="logininputbox morelong">       
                <div className="logininputbox_title morelong">所属医院/单位</div>
                {/* <span className="form-name">所属医院/单位</span> */}
                <input
                  className={
                    this.state.hotail_place === "请输入" ? null : "errors"
                  }
                  name="hotail"
                  onFocus={this.onFocus}
                  onBlur={this.onBlur}
                 //  onChange={this.valueChange}
                  
                  value={this.state.hotail}
                  onChange={this.onThrottleKeyUpClick}
                  placeholder={this.state.hotail_place}
                ></input>
              </div>

              <div className={this.state.showaddress?"addresslistbox":"addresslistbox none"}>
                <div className="addresslist">
                  {this.state.addresslist.map((item,index)=>{
                    return  <div className="addresslist_li" key={"addreslist"+index} onClick={()=>this.queonress(item,index)}>{item.name}</div>
                  })}
                </div>
              </div>


            </div>




            <div
              className={
                this.state.provinceActive ? "login-form none focus " : "login-form none "
              }
            >
              <div className="logininputbox fourlong">       
                <div className="logininputbox_title fourlong">所在省份</div>
                {/* <span className="form-name ">省份</span> */}
                <select
                  className={
                    this.state.province == "请选择" ||
                      this.state.province == ""
                      ? "userselect"
                      : "userselect active"
                  }
                  onChange={this.selectChangeprovince}
                >
                  <option value="">请选择</option>
                  <option value="北京市">北京市</option>
                  <option value="天津市">天津市</option>
                  <option value="河北省">河北省</option>
                  <option value="山西省">山西省</option>
                  <option value="内蒙古自治区">内蒙古自治区</option>
                  <option value="辽宁省">辽宁省</option>
                  <option value="吉林省">吉林省</option>
                  <option value="黑龙江省">黑龙江省</option>
                  <option value="上海市">上海市</option>
                  <option value="江苏省">江苏省</option>
                  <option value="浙江省">浙江省</option>
                  <option value="安徽省">安徽省</option>
                  <option value="福建省">福建省</option>
                  <option value="江西省">江西省</option>
                  <option value="山东省">山东省</option>
                  <option value="河南省">河南省</option>
                  <option value="湖北省">湖北省</option>
                  <option value="湖南省">湖南省</option>
                  <option value="广东省">广东省</option>
                  <option value="海南省">海南省</option>
                  <option value="广西壮族自治区">广西壮族自治区</option>
                  <option value="甘肃省">甘肃省</option>
                  <option value="陕西省">陕西省</option>
                  <option value="新疆维吾尔自治区">新疆维吾尔自治区</option>
                  <option value="青海省">青海省</option>
                  <option value="宁夏回族自治区">宁夏回族自治区</option>
                  <option value="重庆市">重庆市</option>
                  <option value="四川省">四川省</option>
                  <option value="贵州省">贵州省</option>
                  <option value="云南省">云南省</option>
                  <option value="西藏自治区">西藏自治区</option>
                  <option value="中国台湾">中国台湾</option>
                  <option value="中国澳门">中国澳门</option>
                  <option value="中国香港">中国香港</option>
                </select>
                <img
                  alt=""
                  className="select_arrow"
                  src={require("../../static/images/select_arrow.png")}
                />
              </div>
            </div>


            <div
              className={
                this.state.hotillevelActive ? "login-form none focus " : "login-form none "
              }
            >
               {/* <span className="form-name ">医院级别</span> */}
              <div className="logininputbox fourlong">       
                <div className="logininputbox_title fourlong">医院级别</div>
                <div className="hotile_type" onClick={()=>{
                  this.setState({
                    visible:true
                  })
                }}>{this.state.hotiltype?this.state.hotiltype=='非公医疗机构' ||this.state.hotiltype=='公立医疗机构'?this.state.hotiltype+"---"+ this.state.hotillevel:this.state.hotiltype:"请选择"}</div>
                {/* <select
                  className={
                    this.state.hotillevel == "请选择" ||
                      this.state.hotillevel == ""
                      ? "userselect"
                      : "userselect active"
                  }
                  onChange={this.selectChangehotillevel}
                >
                  <option value="">请选择</option>
                  <option value="三级甲等">三级甲等</option>
                  <option value="三级乙等">三级乙等</option>
                  <option value="二级甲等">二级甲等</option>
                  <option value="二级乙等">二级乙等</option>
                  <option value="一级">一级</option>
                  <option value="未定级">未定级</option>
                  <option value="非医院">非医院</option>
                </select> */}
                <img
                  alt=""
                  className="select_arrow"
                  src={require("../../static/images/select_arrow.png")}
                />
              </div>
            </div>
            {/* <div
              className={
                this.state.departmentActive ? "login-form focus" : "login-form"
              }
            >
              <span className="form-name">科室</span>
              <input
                className={
                  this.state.department_place === "请输入" ? null : "errors"
                }
                name="department"
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onChange={this.valueChange}
                placeholder={this.state.department_place}
              ></input>
            </div> */}


            <div
              className={
                this.state.departmentActive ? "login-form focus" : "login-form"
              }
            >
              {/* <span className="form-name ">科室<i className="addispan">注：课程推荐以科室信息为依据，请您如实填写</i></span> */}
              <div className="logininputbox twolong">       
                <div className="logininputbox_title twolong">科室</div>
                <select
                  className={
                    this.state.department == "请选择" ||
                      this.state.department == ""
                      ? "userselect"
                      : "userselect active"
                  }
                  onChange={this.selectChangedepartment}
                >
                  <option value="">请选择</option>
                  <option value="放射科/影像科">放射科/影像科</option>
                  <option value="核医学科">核医学科</option>
                  <option value="超声科">超声科</option>
                  <option value="检验科">检验科</option>
                  <option value="介入科">介入科</option>
                  <option value="放疗科">放疗科</option>
                  <option value="高层管理">高层管理</option>
                  <option value="设备科/采购科">设备科/采购科</option>
                  <option value="心内/心外科">心内/心外科</option>
                  <option value="神内/神外科">神内/神外科</option>
                  <option value="肿瘤内/外科">肿瘤内/外科</option>
                  <option value="血管外科">血管外科</option>
                  <option value="重症医学科">重症医学科</option>
                  <option value="急诊科">急诊科</option>
                  <option value="手术室/麻醉室">手术室/麻醉室</option>
                  <option value="护理部">护理部</option>
                  <option value="胸外科">胸外科</option>
                  <option value="呼吸科">呼吸科</option>
                  <option value="甲乳外科">甲乳外科</option>
                  <option value="骨科">骨科</option>
                  <option value="泌尿科">泌尿科</option>
                  <option value="普外科/肝胆外科">普外科/肝胆外科</option>
                  <option value="妇产科">妇产科</option>
                  <option value="内分泌科">内分泌科</option>
                  <option value="感染科/肝病科">感染科/肝病科</option>
                  <option value="信息科">信息科</option>
                  <option value="高校院校/研究机构">高校院校/研究机构</option>
                  <option value="其它">其它</option>
                </select>
                <img
                  alt=""
                  className="select_arrow"
                  src={require("../../static/images/select_arrow.png")}
                />
                <p className="login-code-textnews">
                  注：课程推荐以科室信息为依据，请您如实填写
                </p>
              </div>
            </div>

           
            {/* <div
              className={
                this.state.officeActive ? "login-form focus" : "login-form"
              }
            >
              <span className="form-name">职务</span>
              <input
                className={
                  this.state.office_place === "请输入" ? null : "errors"
                }
                name="office"
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onChange={this.valueChange}
                placeholder={this.state.office_place}
              ></input>
            </div> */}

            <div
              className={
                this.state.officeActive ? "login-form focus" : "login-form"
              }
            >
              {/* <span className="form-name ">职务</span> */}
              <div className="logininputbox twolong">       
                <div className="logininputbox_title twolong">职务</div>
                <select
                  className={
                    this.state.office == "请选择" ||
                      this.state.office == ""
                      ? "userselect"
                      : "userselect active"
                  }
                  onChange={this.selectChangeoffice}
                >
                  <option value="">请选择</option>
                  <option value="技师">技师</option>
                  <option value="主管技师">主管技师</option>
                  <option value="副主任技师">副主任技师</option>
                  <option value="技师长">技师长</option>
                  <option value="主任技师">主任技师</option>
                  <option value="院长/副院长">院长/副院长</option>
                  <option value="住院医师">住院医师</option>
                  <option value="主治医师">主治医师</option>
                  <option value="副主任医师">副主任医师</option>
                  <option value="行政主任/副主任">行政主任/副主任</option>
                  <option value="主任医师">主任医师</option>
                  <option value="设备科长">设备科长</option>
                  <option value="工程师">工程师</option>
                  <option value="物理师">物理师</option>
                  <option value="护士">护士</option>
                  <option value="教授/研究员">教授/研究员</option>
                  <option value="医学生">医学生</option>
                  <option value="总经理/部门经理">总经理/部门经理</option>
                  <option value="其他">其他</option>

                </select>
                <img
                  alt=""
                  className="select_arrow"
                  src={require("../../static/images/select_arrow.png")}
                />
              </div>
            </div>




            {/* <div
              className={
                this.state.hotillevelActive ? "login-form focus" : "login-form"
              }
            >
              <span className="form-name">医院级别</span>
              <input
                className={
                  this.state.hotillevel_place === "请输入" ? null : "errors"
                }
                name="hotillevel"
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onChange={this.valueChange}
                placeholder={this.state.hotillevel_place}
              ></input>
            </div> */}

           




            {/* <div
              className={
                this.state.hotiltypeActive ? "login-form focus" : "login-form"
              }
            >
              <span className="form-name ">医院类别</span>
              <select
                className={
                  this.state.hotiltype == "请选择" ||
                    this.state.hotiltype == ""
                    ? "userselect"
                    : "userselect active"
                }
                onChange={this.selectChangehotiltype}
              >
                <option value="">请选择</option>
                
                <option value="公立医院">公立医院</option>
                <option value="非公医院">非公医院</option>
                <option value="医疗机构">医疗机构</option>
                <option value="大学院校">大学院校</option>
                <option value="经销商/第三方合作机构">经销商/第三方合作机构</option>
              </select>
              <img
                alt=""
                className="select_arrow"
                src={require("../../static/images/select_arrow.png")}
              />
            </div> */}


            {/* <div
              className={
                this.state.provinceActive ? "login-form focus" : "login-form"
              }
            >
              <span className="form-name">省份</span>
              <input
                className={
                  this.state.province_place === "请输入" ? null : "errors"
                }
                name="province"
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onChange={this.valueChange}
                placeholder={this.state.province_place}
              ></input>
            </div> */}


            {/* <div
              className={
                this.state.provinceActive ? "login-form focus" : "login-form"
              }
            >
              <span className="form-name ">省份</span>
              <select
                className={
                  this.state.province == "请选择" ||
                    this.state.province == ""
                    ? "userselect"
                    : "userselect active"
                }
                onChange={this.selectChangeprovince}
              >
                <option value="">请选择</option>
                <option value="北京市">北京市</option>
                <option value="天津市">天津市</option>
                <option value="河北省">河北省</option>
                <option value="山西省">山西省</option>
                <option value="内蒙古自治区">内蒙古自治区</option>
                <option value="辽宁省">辽宁省</option>
                <option value="吉林省">吉林省</option>
                <option value="黑龙江省">黑龙江省</option>
                <option value="上海市">上海市</option>
                <option value="江苏省">江苏省</option>
                <option value="浙江省">浙江省</option>
                <option value="安徽省">安徽省</option>
                <option value="福建省">福建省</option>
                <option value="江西省">江西省</option>
                <option value="山东省">山东省</option>
                <option value="河南省">河南省</option>
                <option value="湖北省">湖北省</option>
                <option value="湖南省">湖南省</option>
                <option value="广东省">广东省</option>
                <option value="海南省">海南省</option>
                <option value="广西壮族自治区">广西壮族自治区</option>
                <option value="甘肃省">甘肃省</option>
                <option value="陕西省">陕西省</option>
                <option value="新疆维吾尔自治区">新疆维吾尔自治区</option>
                <option value="青海省">青海省</option>
                <option value="宁夏回族自治区">宁夏回族自治区</option>
                <option value="重庆市">重庆市</option>
                <option value="四川省">四川省</option>
                <option value="贵州省">贵州省</option>
                <option value="云南省">云南省</option>
                <option value="西藏自治区">西藏自治区</option>
                <option value="中国台湾">中国台湾</option>
                <option value="中国澳门">中国澳门</option>
                <option value="中国香港">中国香港</option>
              </select>
              <img
                alt=""
                className="select_arrow"
                src={require("../../static/images/select_arrow.png")}
              />
            </div> */}




            {/* <div
              className={
                this.state.telActive ? "login-form focus" : "login-form"
              }
            >
              <span className="form-name">手机号码</span>
              <input
                className={this.state.tel_place === "请输入" ? null : "errors"}
                name="tel"
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onChange={this.valueChange}
                value={this.state.tel}
                placeholder={this.state.tel_place}
              ></input>
            </div>
            <div
              className={
                this.state.codeActive ? "login-form focus" : "login-form"
              }
            >
              <span className="form-name">手机验证码</span>
              <input
                className={
                  this.state.phone_place === "请输入" ? null : "errors"
                }
                name="code"
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onChange={this.valueChange}
                placeholder={this.state.phone_place}
              ></input>
              <div
                className={
                  !/^1[3456789]\d{9}$/.test(this.state.tel)
                    ? "code-btn cursor"
                    : "code-btn cursor active"
                }
                onClick={this.nochategetEmailCode}
              >
                {this.state.codeText}
              </div>



            </div> */}
                { localStorage.getItem('isfromcs') ==1 ?(
                  <div
                  className={
                    this.state.checkcodeActive ? "login-form focus " : "login-form "
                  }
                >
                  <span className="form-name">兑换券码（选填）</span>
                  <input
                    className={
                      this.state.checkcode_place === "请输入" ? null : null
                    }
                    name="checkcode"
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onChange={this.valueChange}
                    placeholder="请输入"
                  ></input>
                </div>
                ):null}
            
           
            <ul className="loginLabel">
              <li
                className={[
                  "fl cursor",
                  this.state.privacy ? "active" : "",
                ].join(" ")}
              >
                <span
                  className="labelSelect privacy"
                  onClick={(e) => this.labelChange("privacy", e)}
                ></span>

                <span>阅读并同意</span>
                <span
                  className="labelText active"
                  onClick={(e) => this.showMask("privacy", e)}
                >
                  隐私保护政策
                </span>
                
                <span>和</span>

                <span
                  className="labelText active"
                  onClick={(e) => this.showMask("agreement", e)}
                >
                  用户使用条款
                </span>
              </li>
              {/* <li
                className={[
                  "fr cursor",
                  this.state.agreement ? "active" : "",
                ].join(" ")}
              >
                <span
                  className="labelSelect agreement"
                  onClick={(e) => this.labelChange("agreement", e)}
                ></span>
                <span
                  className="labelText"
                  onClick={(e) => this.showMask("agreement", e)}
                >
                  用户使用条款
                </span>
              </li> */}
            </ul>

            <div className="isloginbtnbox regbtnlist">
                <div
                    className="login-submitreg cursor"
                    onClick={() => this.changelogin(4)}
                  >
                  返回
                </div>

                <div
                  className={[
                    "login-submit cursor",
                    this.state.submitActive ? "active" : "",
                  ].join(" ")}
                  onClick={this.loginClickthi}
                >
                  提交
                </div>
              </div>


            <div className="login-btnlist newbtnlist">
              {/* <div className='hasid'>注册功能将于近期开放，敬请期待</div> */}
              {/* <div
                className={[
                  "login-submit cursor",
                  this.state.submitActive ? "active" : "",
                ].join(" ")}
                onClick={this.loginClickthi}
              >
                提交注册
              </div>
              <div className="wechet-btn none" onClick={this.showWechet}>
                <img
                  src={require("../../static/images/wecheticon.png")}
                  alt=""
                />
                微信登录
              </div> */}

              {/* <div className="hasid">
                已有账号？
                <span  className="cursor"  onClick={() => this.changelogin(4)}>在此登录 &gt;</span>
              </div> */}
            </div>

            <div className="icpicon newloginicp">
              <div className="loginicpsnewboxreg">
                <span onClick={this.gothiurl}>沪ICP备19037992号-2</span>
                 {/* <br></br> */}
                <a
                  href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502010367"
                  target="view_window"
                >
                  沪公网安备31011502010367号
                </a>
                {/* <br></br> */}
                <a href='https://shl-info.oss-cn-shanghai.aliyuncs.com/pdf/QCMISI.pdf' target="view_window">(沪)-经营性-2024-0040</a>
                {/* <a
                  href="https://shl-info.cn/images/healthcare_certificate.pdf"
                  target="view_window"
                >
                  (沪)-非经营性-2021-0063
                </a>{" "} */}
                <br></br>
                版权声明：西门子医疗系统有限公司 @2021版权所有<br></br>
                <span onClick={this.aoutbtus} className="aboutus">
                  关于西门子医疗
                </span>
                |
                <span onClick={this.contentus} className="contentus">
                  联系我们
                </span>
              </div>
            </div>
            {/*             
            <div className="icpicon"  >
              <span onClick={this.gothiurl}>沪ICP备19037992号-2</span> <br></br>版权声明：西门子医疗系统有限公司 @2021版权所有<br></br>
              <span onClick={this.aoutbtus} className="aboutus" >关于西门子医疗</span>|<span onClick={this.contentus} className="contentus" >联系我们</span>
            </div> */}
          </div>
          <div
            className={this.state.iscomefrom ? "newts none " : " newts none"}
          ></div>
        </div>
        <div
          className={
            this.state.privacyMask ? "privacyMask active" : "privacyMask"
          }
        >
          <div className="privacyCenter">
            <h3 className="maskTitle">
              隐私保护政策
              <span className="closeIcon" onClick={this.closeMask}>
                <img src={require("../../static/images/close.png")} alt="" />
              </span>
            </h3>
            <div className="maskiscroll priva-props">
              <div className="maskscroll-wrap ">
              <p>
                尊敬的西门子医疗公众号用户（以下简称“您”），我们，注册地址位于【中国(上海)自由贸易试验区英伦路38号五层516室】的【西门子医疗系统有限公司】（以下简称“西门子医疗”）希望通过本隐私政策帮助您了解您在使用西门子医疗提供的服务的过程中，西门子医疗如何处理您的个人信息以及您对于这些个人信息所享有的权利（“本隐私政策”）。
                </p>
                <h4>本隐私政策将帮助您了解以下内容：</h4>
                <p>
                1.西门子医疗为何处理您的个人信息以及处理您个人信息的类别
                </p>
                <p>
                2.个人信息的委托处理、共享和披露
                </p>
                <p>
                3.个人信息的保护
                </p>
                <p>
                4.个人信息的保留期限
                </p>
                <p>
                5.未成年人的个人信息
                </p>
                <p>
                6.您的权利
                </p>
                <p>
                7.西门子医疗个人信息保护机构
                </p>
                <p>
                8.内容变更
                </p>
                <h4>1.西门子医疗为何处理您的个人信息以及处理您个人信息的类别</h4>
                <p>
                  1.1.人信息是以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。敏感个人信息是一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息，包括生物识别、宗教信仰、特定身份、医疗健康、金融账户、行踪轨迹等信息，以及不满十四周岁未成年人的个人信息。<br></br>
 个人信息的处理包括个人信息的收集、存储、使用、加工、传输、提供、公开、删除等。
                </p>
                <p>
                1.2.我们会出于以下目的收集和使用您的以下个人信息:
                </p>
                <p>
                1.2.1.目的：帮助您注册为我们的用户；个人信息类别：您的手机号和验证码信息<br></br>
                1.2.2.目的：使您完善您的个人资料；个人信息类别：您的姓名、医院、职位以及名片上提供的其他信息<br></br>
                1.2.3.目的：与您签署服务协议或其他协议；个人信息类别：您的姓名、联系方式、身份证号<br></br>
                1.2.4.目的：提高应用的安全性及保护您的账户安全；个人信息类别：您的浏览信息、设备信息，网页Cookies信息<br></br>
                1.2.5.目的：优化和改进应用的功能以及提升用户体验；个人信息类别：您的个人在线行为轨迹，包括登录平台的时间、地点或来源、登录的设备及IP地址、登录时长、浏览时长、浏览次数、打开或浏览内容、浏览偏好、互动行为等<br></br>
                1.2.6.目的：精准推送用户订阅的内容信息；个人信息类别：您的个人在线行为轨迹，包括登录平台的时间、地点或来源、登录的设备及IP地址、登录时长、浏览时长、浏览次数、打开或浏览内容、浏览偏好、互动行为等
                </p>
                <h4>2.个人信息的委托处理、共享和披露</h4>
                <p>
                2.1.个人信息的委托处理
                </p>
                <p>
为实现上述第1条所述之目的，西门子医疗有可能委托有关第三方（“委托处理方”）处理您的个人信息，比如系统提供商、数据处理服务提供商、云服务提供商。届时西门子医疗将要求该等第三方按照适用的法律法规的要求，采取相应的安全措施保护该等个人信息，并且该等保护应与西门子医疗采取的个人信息保护措施在程度上相当。
                </p>
                <p>
                2.2.个人信息的披露
                </p>
                <p>
                西门子医疗可能会在如下的情形披露您的个人信息，比如：<br></br>
                2.2.1.中国法律或者任何适用于西门子医疗的其他国家的法律要求进行披露；<br></br>
                2.2.2.在中国法律或者任何适用于西门子医疗的其他国家的法律授权或允许的范围内，为保护西门子医疗的合法权利或者一个第三方的重大利益而进行披露；<br></br>
                2.2.3.根据司法程序或政府主管部门的要求。
                </p>
                <h4>3.个人信息的保护</h4>
                <p>
                西门子医疗将会采取适当的技术措施和管理措施来保护您的个人信息不受未经授权的访问、修改、分发、披露或删除。请您知悉并理解，互联网并非绝对安全的环境。如您发现自己的个人信息发生泄漏的，请您立即通过本隐私政策下方列出的联系方式联系我们，以便我们采取相应措施。
                </p>
                <h4>4.个人信息的保留期限 </h4>
                <p>
                除非在收集您个人信息时另有约定，若保留信息已不再为个人信息收集或另行处理时的目的或为遵守法律义务所需要（例如税法或商业法下的法定保留义务），公司将删除您的个人信息。
如您想主动要求相应删除，请联系【shl.scrm.cn@siemens-healthineers.com】。如届时法律、行政法规规定的保存期限未届满，或者删除个人信息从技术上难以实现的，西门子医疗应当停止除存储和采取必要的安全保护措施之外的处理。
                </p>

                
                <h4>5.未成年人的个人信息</h4>
                <p>
                我们的服务主要面向年满18周岁的成年人提供，我们一般不直接从未成年人收集其个人信息。如果您认为我们未经过您同意处理了您的未成年子女的个人信息，或者对我们处理您的未成年子女的个人信息有任何问题或者疑虑，请通过本隐私政策底部所示的联系方式与我们联系。
                </p>

                <h4>6.您的权利</h4>
                <p>
                受制于相关法律要求，您可能有权：<br></br>
1.与西门子医疗确认其是否正在处理与您有关的个人信息，并且若在处理的话，您可以访问该个人信息; <br></br>
2.借助西门子医疗更正有关您的不准确的个人信息；<br></br>
3.借助西门子医疗删除您的个人信息；<br></br>
4.撤销您的同意，但该等撤销不影响基于您的同意已经做出的合法处理行为。<br></br>
5.借助西门子医疗限制对您的个人信息的处理； <br></br>
6.获得您的个人信息的副本；<br></br>
7.以您的特定情况为理由反对进一步处理与您有关的个人信息。<br></br>
您有权随时退订我们不时向您发送的营销信息。如您不希望收到我们发送的电子邮件，您可点击您收到的电子邮件底部的“取消订阅”链接；如您不希望收到我们发送的短信，您可以根据短信中的操作提示，选择“退订”功能。如您希望退订其他形式的营销信息（比如通过邮寄或者电话等方式进行的营销），您可以通过本隐私政策底部列出的联系方式联系我们。
                </p>

                <p>
                请您注意，在中国法律允许的情况下，如果西门子医疗无法或在实际操作上难以按照您的上述要求行事，西门子医疗有权拒绝您的全部或部分请求，并仅需于合理时间内向您说明拒绝理由。特别是，西门子医疗有权在中国法律允许的情况下在下列任一情形下拒绝您的请求：<br></br>
1.所请求信息是与西门子医疗履行法律法规义务直接相关的；<br></br>
2.所请求信息是与国家安全、国防安全直接相关的；<br></br>
3.所请求信息是与公共安全、公共卫生、重大公共利益直接相关的；<br></br>
4.所请求信息是与犯罪侦查、起诉、审判和执行判决等直接相关的；<br></br>
5.西门子医疗有合理证据表明您存在主观恶意或滥用权利的；<br></br>
6.响应您的请求将导致您或其他个人、实体或组织的合法权益受到损害的；<br></br>
7.出于维护您或其他自然人的生命、财产等重大合法权益但又很难得到您的同意的；<br></br>
8.所请求信息涉及商业秘密。
                </p>

                <h4>7.西门子医疗个人信息保护机构</h4>
                <p>
                西门子医疗个人信息保护机构为任何个人信息保护有关的问题、意见、疑虑或投诉提供支持，也可在您希望行使任何与个人信息保护相关的权利时提供支持。您可以通过联系以下邮箱获取关于西门子医疗个人信息保护机构、西门子医疗个人信息保护体系的信息或寻求支持：dataprivacy.func@siemens-healthineers.com.<br></br>
西门子医疗个人信息保护机构会尽商业合理可行的努力回应、解决收到的问题和投诉。除联系西门子医疗个人信息保护机构，您也可能有权向相应个人信息保护主管机关反映您的问题或投诉。 
                </p>

                <h4>8.内容变更</h4>
                <p>
                西门子医疗有权修订本告知函的内容，当（发生）更新时，我们会在西门子医疗的网站以及我们认为合适的其他地方公布变更内容，并以口头、书面、电话、短信、电子邮件、传真、电子文档等方式向您提供修订概要。以确保您始终了解我们收集了哪些信息、如何使用以及我们在何种情况下（如果有）会分享或公开这些信息。
                </p>
              </div>
              <div className="maskscroll-wrap none">
                <h4>西门子医疗对数据隐私保护的承诺</h4>
                <p>
                  西门子医疗重视保护您个人信息的安全和隐私。因此，我们开展业务时符合法律对数据隐私保护和数据安全的规定。希望下列政策能帮助您了解哪些数据我们可以收集，我们如何使用、保护和共享这些数据。
                </p>
                <h4>个人数据</h4>
                <p>
                  西门子医疗不会通过我们的网站收集任何您的个人信息（例如：姓名、地址、手机号码或邮件地址），除非您自愿选择通过注册、调查等形式告知我们，或者通过被保护个人信息的法律、法规所允许的途径收集。
                </p>
                <h4>Adobe Analytics 的使用</h4>
                <p>
                  在我们的网站上使用Abode Analytics
                  是为了不断改进网站或识别错误。为了这个目的，有些数据需要被储存，如网站来访及它的原数据、引用西门子医疗网站的其他页面、网站被打开的时间和所用的浏览器。
                </p>
                <p>
                  如您要排除您的访问所造成的数据聚合和分析，
                  <a href="https://www.d1.sc.omtrdc.net/optout.html?locale=en_US&second=1&second_has_cookie=0">
                    请点击此处
                  </a>
                  。它将会在您的浏览器中设置一个小文本储存文件（cookie），以排除您的访问对网站分析的影响。虽然这些数据不包含您的个人信息，但是它能使Adobe软件从技术上永久性的识别您的偏好。
                </p>
                <p>
                  如果您删除该cookie、更换计算机或浏览器，您需要重新设置这个cookie。
                </p>
                <h4>使用目的</h4>
                <p>
                  当您为我们提供个人资料时，我们通常用它来回复您的问询、处理您的订单或提供您访问的特定信息。此外，通过这些数据来维护我们的合作关系：
                </p>
                <div className="dotpadd">
                  <p className="dooted">
                    我们存储和处理个人数据并与我们的全球分支机构分享，以便更好地理解您的业务需求和我们如何能够改善我们的产品和服务；
                  </p>
                  <p className="dooted">
                    我们（或第三方代表）可以使用您的个人资料来联系您，以便于西门子医疗提供业务支持或进行网上调查来更好地理解客户的需要。
                  </p>
                </div>
                <p>
                  如果您不愿意您的个人数据用于我们的客户关系维护（尤其是直接营销或市场调研），我们将尊重您的选择。我们不会出售您的个人信息给除西门子医疗以外的第三方。
                </p>
                <h4>使用限制 </h4>
                <p>
                  西门子医疗只因为以上目的搜集、使用或披露您在线提交的个人资料。除非：
                </p>
                <p>
                  将个人资料用于与我们搜集信息的初衷直接相关的其他任何目的，
                </p>
                <p>准备、谈判或与您执行合同所需要，</p>
                <p>被法律、政府或司法机关所要求，</p>
                <p>建立或保护合法要求或防御所需要，</p>
                <p>
                  反欺诈或制止其他违法行为所需要，如恶意攻击西门子医疗信息技术系统。
                </p>
                <h4>通信或使用数据 </h4>
                <p>
                  通过使用电信服务来访问我们的网站，您的通信数据（例如互联网协议地址）或使用数据（如开始、结束和访问度信息、您访问的电信服务信息）是技术上生成的，但也能归于个人数据。在某种程度上有必要将收集、处理和使用您的通讯或使用数据纳入数据隐私保护政策的法律框架内。
                </p>
                <h4>非个人信息的自动收集 </h4>
                <p>
                  当您进入我们的网站，我们将自动的（即无需通过注册）搜集非个人信息（如：使用的浏览器和操作系统类型、网站的域名、访问数量、在网站停留的平均时间、浏览页面等）。我们能够使用这些数据并与我们的全球分支机构共享，以此监控我们网站的吸引力并提高网站的性能或内容。
                </p>
                <h4>“Cookies”——自动在您的计算机上储存的信息</h4>
                <p>
                  当您访问了我们的网站，我们将在您的计算机上以“Cookie”的形式储存一些数据，以便在您下次访问我们的网站时能够自动识别您的计算机。Cookie能够在许多方面给我们提供帮助，例如，允许我们调整网站以更好地匹配您的兴趣或存储你的密码使您下次不需要重新输入。如果您不愿意接受cookie，请设置您的浏览器，并删除所有的cookie。限制所有的cookie或储存cookie时会收到警告。
                </p>
                <h4>儿童</h4>
                <p>
                  西门子医疗不会在儿童没有获得他们父母同意的情况下故意收集儿童的个人信息，除非法律需要。我们只使用或披露在法律允许或依照当地法律、法规寻求父母同意的情况下的儿童个人信息。对儿童的定义应该考虑适用法律以及国家和地区的文化习俗。
                </p>
                <h4>安全性</h4>
                <p>
                  为了保护您的个人数据免受意外或非法破坏、损失或变更和未经授权的披露或访问，西门子医疗使用技术性和有组织的安全措施。
                </p>
                <h4>链接到其他网站</h4>
                <p>
                  西门子医疗网站包含有其他网站的链接。西门子医疗不对其他网站的隐私保护政策或内容负责。
                </p>
                <h4>问题和评论</h4>
                <p>
                  西门子医疗将回应合理的请求，来审查你的个人数据，纠正、修改或删除任何不准确之处。如果您有任何问题或对西门子医疗数据隐私保护政策的评论（如：查阅或更新您的个人信息），请点击此窗口右上角的“联系我们”，并选择“关于西门子医疗”、“关于我们——与西门子医疗有关的问题”。由于互联网不断地发展成熟，我们的数据隐私保护政策也将不断修改完善。我们将在此页面上改变我们的数据隐私保护政策。请定期检查这个页面以获取及时信息。
                </p>
              </div>
            </div>
            <div
              className="maskButton cursor"
              onClick={(e) => this.agreeClose("privacy", e)}
            >
              接受条款
            </div>
          </div>
        </div>
        <div
          className={
            this.state.agreeMask ? "privacyMask active" : "privacyMask"
          }
        >
          <div className="privacyCenter">
            <h3 className="maskTitle">
              用户使用条款
              <span className="closeIcon" onClick={this.closeMask}>
                <img
                  className="cursor"
                  src={require("../../static/images/close.png")}
                  alt=""
                />
              </span>
            </h3>
            <div className="maskiscroll agree-props">
              <div className="maskscroll-wrap">
                <p>
                  *本网站所载文档及相关视频仅作为西门子医疗内部在线培训系统的定制化培训材料提供给经授权或允许访问的经销商、所涉及医疗设备和方案应用的相关医疗卫生专业人士、内部员工参考，非为对外推广宣传产品之目的。本网站将授予每一有权访问者专有账户并设定特定访问权限以使每一有权访问者仅有权在其访问权限范围内访问及享受特定的培训内容及服务。未经西门子医疗确认访问权限，请访问者停止访问本网站中专属相关用户使用的培训及服务内容，且不得采取任何形式窃取、获取或破解本网站为特定访问者设置之账号、密码或其它安全措施。未经西门子医疗书面许可，任何访问者或任何第三方不得对外进行任何转载、传播、宣传或用于任何商业用途，西门子医疗不对该等未经许可的行为或对外传播产生的结果负责。西门子医疗保留因前述该等行为而造成西门子医疗相应损失追究法律责任的权利。
                </p>

                <h4>1. 范围 </h4>
                <p>
                  1.1本使用条款适用于任何对西门子医疗系统有限公司提供的网站（"西门子医疗网站"）的使用行为。使用条款可为其他条款（如产品和服务购买条款）所修改、调整或代替。登陆或无需登陆进入或使用西门子医疗网站，使用条款将以其当时的形式为准。
                </p>
                <p>
                  1.2
                  除非另有说明，本使用条款不适用于通过西门子医疗网站登陆其他网站的使用行为。
                </p>
                <h4>2.服务</h4>
                <p>
                  2.1
                  西门子医疗网站包括专门信息、软件及有关文件（视具体情况而定），以供浏览或下载。
                </p>
                <p>
                  2.2
                  西门子医疗可以随时全部或部分地停止运行西门子医疗网站。由于网络和计算机系统的属性，西门子医疗不对西门子医疗网站的持续运转承担任何义务。
                </p>
                <h4>3.注册和密码</h4>
                <p>
                  3.1
                  西门子医疗网站的部分页面需要提供密码进入。为保证商业交易的安全，只有注册用户才能进入上述页面。西门子医疗保留拒绝接受任何用户注册的权利。西门子医疗特别保留将以前自由进入的网页改为注册才能进入的网页的权利。西门子医疗有权在任何时候，通过冻结用户数据（定义如下）禁止用户进入密码保护网页，且无需说明理由，特别是如果用户：
                </p>
                <div className="dotpadd">
                  <p className="dooted">为注册目的使用虚假信息；</p>
                  <p className="dooted">违反使用条款或违反用户信息注意义务；</p>
                  <p className="dooted">
                    在进入或使用西门子医疗网站过程中违反有关法律；
                  </p>
                  <p className="dooted">不再使用西门子医疗网站。</p>
                </div>
                <p>
                  3.2
                  用户在注册过程中应提供准确信息，信息变更时在合理期限内及时更新该信息（在可能的程度内在线更新）。用户应确保其提供给西门子医疗的电子邮箱在任何时候为有效联系邮箱。
                </p>
                <p>
                  3.3
                  注册后，用户将获得进入代码包括用户名和密码（"用户信息"）。在首次进入后，用户应尽快修改西门子医疗提供的密码。用户信息可用于浏览或修改个人信息，或取消其对数据处理的同意。
                </p>
                <p>
                  3.4
                  用户应确保用户信息不为第三方取得，并对以其用户信息进行的交易或其他活动负责。在每次登陆后，用户应在密码保护的网页上进行注销。如果用户发现第三方在不当使用其用户信息，其应毫不迟延地通过书面或电子邮件形式将此情况通知西门子医疗。西门子医疗不对任何用户的任何登陆信息或用户信息丢失或被不正当使用承担任何责任。
                </p>
                <p>
                  3.5
                  在收到第3.4条的信息后，西门子医疗应拒绝该用户信息进入密码保护的网页。用户需经向西门子医疗申请或再次注册方能进入。
                </p>
                <p>
                  3.6
                  在不影响合同履行的前提下，用户可以随时书面要求终止其注册。在此情况下，西门子医疗在不再需要该信息时，应删除所有用户信息和其他存储的可辨明的用户个人信息。
                </p>
                <h4>4.使用信息、软件和文件的权利</h4>
                <p>
                  4.1
                  任何西门子医疗网站上提供的信息、软件和文件的使用应适用使用条款，如涉及信息、软件和文件的更新，应适用与西门子医疗事先达成的许可条款。单独的许可条款优于使用条款。
                </p>
                <p>
                  4.2
                  西门子医疗授予用户非排他和不可转让许可（即不得进行转许可），在许可范围内使用自西门子医疗网站获取的信息、软件和文件，如无协议，则应遵循西门子医疗提供上述信息的目的使用。
                </p>
                <p>
                  4.3
                  用户在任何情况下均不得向第三方转让、出租或以其他方式提供信息、软件和文件。除非强制性法律另行规定，用户不得修改软件、文件，也不应重组、调整设计或分解软件或其部件。根据使用条款，用户可以保留软件复印件，以确保进一步使用软件。
                </p>
                <p>
                  4.4
                  信息、软件和文件受著作权法、国际著作权公约或其他与知识产权相关的法律和公约的保护。用户应遵守上述法律，特别是不能修改、公开或删除信息、软件、文件及其复印件中的任何文字数字代码、标识或著作权提示。
                </p>
                <h4>5.知识产权</h4>
                <p>
                  5.1
                  未经西门子医疗事先书面同意，西门子医疗网站的信息、商标和其他内容不得传播、修改、复制、出售、出租、使用、增加或以其他方式使用。
                </p>
                <p>
                  5.2
                  除本规定明确授予的使用权或其他权利，用户不享有其他权利也无权请求授予其他权利。任何和所有专利权和许可被明确排除。
                </p>
                <h4>6.用户义务</h4>
                <p>6.1 用户在进入或使用西门子医疗网站过程中，不得：</p>
                <div className="dotpadd">
                  <p className="dooted">
                    伤害他人，特别是儿童，或者侵犯他们的人身权利；
                  </p>
                  <p className="dooted">以违反公共道德的方式使用；</p>
                  <p className="dooted">侵犯任何知识产权或其他财产权利；</p>
                  <p className="dooted">
                    上传任何含有病毒的文件，或其他可能破坏数据的程序；
                  </p>
                  <p className="dooted">
                    传输、存储或上传用户无权的链接或内容，特别是如果该链接或内容违反保密义务或非法；
                  </p>
                  <p className="dooted">
                    发布广告或自动电子邮件（所谓的"垃圾邮件"）或不准确的病毒警告、缺陷或类似文件，用户不得要求参加任何抽奖、雪球系统、连锁信、金字塔游戏或类似活动。
                  </p>
                </div>
                <p>
                  6.2
                  西门子医疗可以在任何时候拒绝用户进入西门子医疗网站，特别是如果用户违反使用规定的义务。
                </p>
                <h4>7.超级链接</h4>
                <p>
                  西门子医疗网站可能包含连接到第三方网页上的链接。西门子医疗对该网页的内容不承担责任，也不承诺该网页及内容为其所有，因为西门子医疗不能对该网页的信息进行控制，也不对网页上的内容和信息负责。用户使用上述网页应该自担风险。
                </p>
                <h4>8.权利瑕疵和质量瑕疵责任</h4>
                <p>
                  8.1
                  由于所提供的信息、软件或文件为免费提供给用户，除故意不当行为或欺诈外，任何关于信息、软件或文件的质量和权利的瑕疵担保义务，特别是关于准确、无瑕疵、无请求或第三方权利要求的义务，或关于充分和/或适合目的的义务，在此被明确排除。
                </p>
                <p>
                  8.2
                  西门子医疗网站上的信息可能包括有关某个产品的技术可能性的专门或一般的描述，但该产品在某些情况下可能无法获得（如由于产品变化）。因此产品品质应在每次购买时由双方确认。
                </p>
                <p>
                  8.3
                  使用西门子医疗网站的风险由您个人承担。公司不对所提供的信息做任何认证，包括有关适销性、针对某一特定用途的适用性、权利、交易过程或商业惯例的任何默示担保和条件。无论是否有法律原因，是否在合同和担保中写明，是否有满意的补救措施，也无论是否存在民事侵权（包括故意和过失），是否有责任限制，是否要求赔偿或是否有任何其他法律依据，西门子医疗公司均不对任何担保要求或损失承担责任，特别是不对因在设置过程中提供咨询和帮助而导致的损失以及业务中断或软件缺陷承担责任，除非法律要求必须对损失承担责任，或因违反主要的合同义务或故意不遵守或因重大过失忽略了明示的担保义务而必须承担责任。尽管如此，因违反主要的合同义务而导致的损害赔偿责任，应仅限于合同中通常规定的可预见损失，除非这种违反是故意的或因疏忽所致。
                </p>
                <h4>9.其他义务，病毒</h4>
                <p>
                  9.1
                  西门子医疗有关质量和权利瑕疵义务应根据使用条款第8条确定。西门子医疗任何其他义务均被排除，除非法律要求如产品责任法、或故意或重大过失、人身伤害或死亡、无法满足所担保的特征、缺陷欺诈性披露、或违反基本合同义务。
                </p>
                <p>
                  9.2
                  尽管西门子医疗努力使其网站免受病毒攻击，但仍无法对此提供任何保证。出于自我保护目的，用户应采取必要安全措施，并在下载信息、软件和文件前检查是否存在病毒。西门子医疗不对病毒引起的任何用户的损失、损害承担任何责任。
                </p>
                <h4>10.出口控制</h4>
                <p>
                  10.1
                  如由于其属性、意图或最终使用地，某些信息、软件和文件的出口需经过授权。用户应严格遵守信息、软件和文件出口管理办法，特别是欧盟、欧盟成员国和美国的出口管理规定。西门子医疗应标明与德国、欧盟出口控制清单和美国商务控制单有关的信息、软件和文件。
                </p>
                <p>10.2 用户应特别检查和确认：</p>
                <div className="dotpadd">
                  <p className="dooted">
                    信息、软件和文件不得用于军事、核技术或武器；
                  </p>
                  <p className="dooted">
                    不向美国拒绝入境人员清单所列的组织或人员，提供源于美国的货物、软件或技术；
                  </p>
                  <p className="dooted">
                    未经授权，不向美国警告清单、实体清单或特别指定名单所列人员和组织提供上述资料；
                  </p>
                  <p className="dooted">
                    不向任何特别指定恐怖主义者清单、外国恐怖组织清单、特别指定国际恐怖主义者清单或欧盟恐怖组织上列明的组织或人员提供产品；
                  </p>
                  <p className="dooted">不向军方代理人提供产品；</p>
                  <p className="dooted">
                    应遵守德国和中国各政府部门的警告指示。
                  </p>
                </div>
                <p>
                  只有在符合上述保证和检查的前提下，才能取得信息、软件和文件。如果用户不遵守上述规定，西门子医疗没有履行义务。
                </p>
                <p>
                  10.3 应用户要求，西门子医疗应向其提供联系方式以获取更多信息。
                </p>
                <h4>11.信息保密</h4>
                <p>
                  收集、使用和处理西门子医疗网站上的用户确认信息，西门子医疗应遵守有关保密法律和西门子医疗网上数据保密政策，该政策可通过西门子医疗网站链接或在
                  <a href="https://new.siemens.com/cn/zh.html">
                    www.siemens.com.cn
                  </a>
                  获取。
                </p>
              </div>
            </div>
            <div
              className="maskButton cursor"
              onClick={(e) => this.agreeClose("agreement", e)}
            >
              接受条款
            </div>
          </div>
        </div>
        {maskbox}
        <div
          className={this.state.wechetcode ? "wechetMask active" : "wechetMask"}
        >
          <div className="wechetBox center">
            <h2 className="wechetTitle">
              微信登录
              <span className="wechetClose" onClick={this.closeWechet}></span>
            </h2>
            {this.state.wecheterror ? (
              <div className="wechetError">
                <p>
                  您还未登录过“西门子医疗在线培训”系统
                  <br />
                  请填写姓名和邮箱进行登录
                </p>
              </div>
            ) : (
                <div className="wechetCode">
                  <img src={this.state.wechetimgurl} alt="" />
                  <p>
                    请使用微信扫描二维码
                  <br />
                  登录“西门子医疗在线培训平台”
                </p>
                </div>
              )}
          </div>
        </div>
      </div>
    );
  }
}

export default Login
