import React, { useState, useEffect } from 'react'
import { change_file_typedownlist, change_userinfo } from '../../../../store/action'
import GroupUserJurisToexamine from '../GroupUserJurisToexamine'


import Maskbox from "../../../../components/maskbox";


import './index.css'
let selectNotIcon = require('../../../../static/images/icon12.png')
let selectIcon = require('../../../../static/images/icon13.png')

const IndexComponent = (props) => {
  let [userlist, setUserlist] = useState({
      creater:[],
      admin:[],
      general:[],
      leader:[],
      isverify:''
  })

  useEffect(() => {
    // fetch(
    //   "https://shl-info.cn/front/Groupchat/getGroupUserList?userid=" +
    //   localStorage.getItem('userid') +
    //   "&chatcode=" +
    //   props.groupid ,{
    //     headers:{
    //       'token':localStorage.getItem('token') 
    //     }
    //   }
    // )
    // .then(data => {
    //   return data.json();
    // })
    // .then(res => {
    //   console.log(res)
    //   if(res.code==2000){
    //     setUserlist(res.data)
    //   // this.setState({
    //   //   creater:res.data.creater,
    //   //   admin:res.data.admin,
    //   //   general:res.data.general,
    //   //   leader:res.data.leader,
    //   //   isverify:res.data.isverify
    //   // })
    //   }

    // });
  },[])

  useEffect(() => {
    console.log(props.groupinfo)
    
    setInputtext(props.groupinfo.notice)
  },[props.groupinfo])
  
  let [changeid, setChangeid] = useState('')

  let [changetype, setChangetype] = useState('')
  let [changeindex, setChangeindex] = useState('')




  let [ismasknew, setIsmasknew] = useState(false)
  let [masktitle, setMasktitle] = useState('')
  let [masktext, setMasktext] = useState('')
  let [inputtext, setInputtext] = useState('')
  

  let thischangeids = (item,types,index)=>{
    setChangetype(types)
    setChangeindex(index)
    console.log(1111)
    console.log(item.id)
    setChangeid(item.id)
  }
  let changeuserstate = (type)=>{
    console.log(type)

    console.log(type)
    if(changetype==''){
      setIsmasknew(true)
      setMasktitle('请选择')
      setMasktext('请先勾选要切换身份用户')
     
    }else if(type==1 && userlist['leader'].length>0){

      setIsmasknew(true)
      setMasktitle('选择失败')
      setMasktext('请先把组长切换为其他身份')
    }else{
      if(type==5){
        fetch(
          "https://shl-info.cn/front/groupchat/delGroupUser", {
          method: 'POST',
          body: JSON.stringify({
            "chatcode": props.groupid ,
            "userid": localStorage.getItem('userid'),
            "deluserid": userlist[changetype][changeindex]['userid'],
          }),
          headers:{
            'content-type': 'application/json',
            'token':localStorage.getItem('token')
          },
          header: {
            'content-type': 'application/json',
            'token':localStorage.getItem('token')
          },
        })
          .then(data => {
            return data.json();
          })
          .then(res => {
            if(res.code==2000){
              fetch(
                "https://shl-info.cn/front/Groupchat/getGroupUserList?userid=" +
                localStorage.getItem('userid') +
                "&chatcode=" +
                props.groupid  ,{
                  headers:{
                    'token':localStorage.getItem('token') 
                  }
                }
              )
                .then(data => {
                  return data.json();
                })
                .then(res => {
                  console.log(res)
                  if(res.code==2000){
                    setUserlist(res.data)
                    setChangetype('')
                    setChangeindex('')
                    setChangeid('')
                  }
                });
            }
          })
      }else{
        fetch(
          "https://shl-info.cn/front/groupchat/updateUserType", {
          method: 'POST',
          body: JSON.stringify({
            "chatcode": props.groupid ,
            "userid": localStorage.getItem('userid'),
            "changeuserid": userlist[changetype][changeindex]['userid'],
            "usertype": type,
          }),
          headers:{
            'content-type': 'application/json',
            'token':localStorage.getItem('token')
          },
          header: {
            'content-type': 'application/json',
            'token':localStorage.getItem('token')
          },
        })
          .then(data => {
            return data.json();
          })
          .then(res => {
            if(res.code==2000){
              fetch(
                "https://shl-info.cn/front/Groupchat/getGroupUserList?userid=" +
                localStorage.getItem('userid') +
                "&chatcode=" +
                props.groupid  ,{
                  headers:{
                    'token':localStorage.getItem('token') 
                  }
                }
              )
                .then(data => {
                  return data.json();
                })
                .then(res => {
                  console.log(res)
                  if(res.code==2000){
                    setUserlist(res.data)
                    setChangetype('')
                    setChangeindex('')
                    setChangeid('')
                  }
                });
            }
          });
      }


    }


  }
  

  let [groupUserJurisToexaOpen, setGroupUserJurisToexaOpen] = useState(false)

  let groupUserJurisItem = (item,types,index) => {
    console.log(item)
    return (
      <div className="groupUserJurisItem">
        <div className="groupUserJurisItemName">{item.username}</div>
        <div className="groupUserJurisItemOper">
          <div className="groupUserJurisItemLable">{item.type==4?"创建者":item.type==1?"组长":item.type==2?"管理员":item.type==3?"组员":''}</div>
          {/* {changeid}----{item.id} */}
          {props.usertypes==2 && types =='general'?<img src={changeid === item.id?selectIcon: selectNotIcon } alt="" className="groupUserJurisItemSelect" onClick={()=>thischangeids(item,types,index)} />:props.usertypes==1 &&( types =='general' ||  types =='admin' )?<img src={changeid === item.id?selectIcon: selectNotIcon } alt="" className="groupUserJurisItemSelect" onClick={()=>thischangeids(item,types,index)} />:props.usertypes==4 && types !='creater'?<img src={changeid === item.id?selectIcon: selectNotIcon } alt="" className="groupUserJurisItemSelect" onClick={()=>thischangeids(item,types,index)} />:null}
          {/* {types !='creater'?<img src={changeid === item.id?selectIcon: selectNotIcon } alt="" className="groupUserJurisItemSelect" onClick={()=>thischangeids(item,types,index)} />:null} */}
        </div>
      </div>
    )
  }
  let closeMask =()=>{
    setIsmasknew(false)
  }
  let valueChange = (e) =>{
    setInputtext(e.target.value)
  }
  let goupdatetext = ()=>{

//     修改群公告：setGroupNotice
// userid、chatcode、notice
    fetch(
      "https://shl-info.cn/front/groupchat/setGroupNotice", {
      method: 'POST',
      body: JSON.stringify({
        "chatcode": props.groupid ,
        "userid": localStorage.getItem('userid'),
        "notice": inputtext,
      }),
      headers:{
        'content-type': 'application/json',
        'token':localStorage.getItem('token')
      },
      header: {
        'content-type': 'application/json',
        'token':localStorage.getItem('token')
      },
    })
      .then(data => {
        return data.json();
      })
      .then(res => {
        props.changenotice();
        props.closeFunc()
      })

    console.log(inputtext)
  }

  return (
    <div className="groupUserJuris ">
      <div className="groupUserJurisHead">
        <img src={require('../../../../static/images/icon6.png')} alt="" className="groupUserJurisReturn" onClick={() => props.closeFunc()}  />
       
      </div>
      
      <div className="groupnoticebox">
        <div className='groupnoticeboxtitle'>群公告</div>
        <div className='groupnoticeboxinput'>
          <textarea 
              onChange={(e)=>valueChange(e)}
              value={inputtext}
          className='groupnoticeboxtext' placeholder='请输入文字'>

          </textarea>
        </div>
        <div className='groupnoticeboxbutton'><span onClick={()=>goupdatetext()}>确认</span></div>
      </div>
    </div>
  )
};

export default IndexComponent;
