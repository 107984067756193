import React, { useState, useEffect } from 'react'
import CourseItem from '../CourseItem'
import SeriesAdd from '../SeriesAdd'
import SeriesCourse from '../SeriesCourse'
import SearchCourse from '../SearchCourse'

import Exam from '../Exam'
import Examresults from '../Examresults'
import './index.css'

const IndexComponent = (props) => {

  // searchCourse: 搜索课程   seriesCourse: 系列课程  seriesAdd: 系列添加  exam:考试
  let [groupChatModule, setGroupChatModule]= useState('seriesCourse')
  //let [groupChatModule, setGroupChatModule]= useState('exam')

  let [courseinfo, setCourseinfo]= useState([])
  let [packinfo, setPackinfo]= useState([])
  
  
  let [examqalist, setExamqalist]= useState([])
  let [state, setState]= useState(false)
  let [examresults, setExamresults]= useState(false)

  let [userqalist, setUserqalist]= useState({
    userqastate:0,
    userqacount:0,
  })
  
  useEffect(() => {
    if(props.groupid){
      fetch(
        "https://shl-info.cn/front/groupchat/getCourseList?userid=" +
        localStorage.getItem('userid') +
        "&chatcode=" +
        props.groupid ,{
          headers:{
            'token':localStorage.getItem('token') 
          }
        }
      )
        .then(data => {
          return data.json();
        })
        .then(res => {
          console.log(res)
          if(res.code==2000){
            let packinfo = [];
            console.log()
            let datainfo =  res.data.datainfo;
              datainfo['showlayer'] = datainfo.userqastate;
            setUserqalist(datainfo)
            setExamqalist(res.data.questions)
            setCourseinfo(res.data.courseinfo)
            for(var i=0;i<res.data.packinfo.length;i++){
              console.log(res.data.packinfo[i])
              packinfo.push(res.data.packinfo[i])
              if(i==0){
                setPackidinfo(res.data.packinfo[i]);
              }
            }
            console.log(packinfo)
            setPackinfo(packinfo)


            props.changecourse(res.data.datainfo)
            setState(!state)
          }
        });
    }
  }, [props.groupid])
 
  let [packidinfo, setPackidinfo]= useState({})
  let clickpackidinfo = (item,index) =>{
    setPackidinfo(item);
    
    setGroupChatModule('seriesCourse')
    // setGroupChatModule('searchCourse')
   // setGroupChatModule('seriesCourse')
  }
  let changeagain = ()=>{
    userqalist['showlayer'] = '';
    console.log(userqalist);
    setUserqalist(userqalist)
    setState(!state)
  }
  // 修改考试
  let  changeExams = arr => {
    console.log(arr)
    setExamqalist(arr)
    setState(!state)
  };


  let  gotoresult = ()=> {
    
    setExamresults(!examresults)
  }

  let groupCourseSeriesItem = (item,index) => {
   
    let bi=0
    if(item.coursenum==0 || item.looknum==0 ){
      bi=0
    }else{
      bi = parseInt(item.looknum/item.coursenum*100);
    }
    return (
      <div className="groupCourseSeriesItem" onClick={() =>clickpackidinfo(item,index) } >
        <div className="groupCourseSeriesItemTitle text-overflow">{item.packname}</div>
        <div className="groupCourseSeriesItemContent">
          <div className="groupCourseSeriesItemDetailItem">
            <img src={require('../../../../static/images/icon10.png')} alt="" className="groupCourseSeriesItemDetailItemIcon" />
            <div className="groupCourseSeriesItemDetailItemTitle">{item.coursenum}</div>
          </div>
          {/* <div className="groupCourseSeriesItemDetailItem">
            <img src={require('../../../../static/images/icon9.png')} alt="" className="groupCourseSeriesItemDetailItemIcon" />
            <div className="groupCourseSeriesItemDetailItemTitle">1个月</div>
          </div> */}
        </div>
        <div className="groupCourseSeriesItemSpeedProgressView">
          <div className="groupCourseSeriesItemSpeedPro">
            <div className="groupCourseSeriesItemSpeedProProp" style={{width:bi+"%"}}></div>
          </div>
          <div className="groupCourseSeriesItemSpeedProNum">{bi}%</div>
        </div>
      </div>
    )
  }
  

  let groupChatCourseItem = () => {
    return (
      <div className="groupChatCourseItem">
        <CourseItem>
          <div className="groupChatCourseTime">
            <img src={require('../../../../static/images/icon3.png')} alt="" className="groupChatCourseTimeIcon" />
            <div className="groupChatCourseTimeText">已观看06:26</div>
          </div>
        </CourseItem>
      </div>
    )
  }
  let changepacklist = ()=>{
    console.log(1111)
    console.log(22222)

    fetch(
      "https://shl-info.cn/front/groupchat/getCourseList?userid=" +
      localStorage.getItem('userid') +
      "&chatcode=" +
      props.groupid ,{
        headers:{
          'token':localStorage.getItem('token') 
        }
      }
    )
      .then(data => {
        return data.json();
      })
      .then(res => {
        console.log(res)
        if(res.code==2000){
          let packinfo = [];
        
          //setCourseinfo(res.data.courseinfo)
          for(var i=0;i<res.data.packinfo.length;i++){
            console.log(res.data.packinfo[i])
            packinfo.push(res.data.packinfo[i])
            // if(i==0){
            //   setPackidinfo(res.data.packinfo[i]);
            // }
          }
          console.log(packinfo)
          setPackinfo(packinfo)
        }
      });


  }

  let groupChatSearchCourse = () => {
    return <SearchCourse setGroupChatModule={setGroupChatModule} history={props.history} changepacklist ={changepacklist} packidinfo={packidinfo} groupid={props.groupid}  closeFunc={() => setGroupChatModule('seriesCourse')}></SearchCourse>
  }

  
  let groupChatSeriesCourse = () => {
    return <SeriesCourse history={props.history} usertypes={props.usertypes} groupid={props.groupid } packidinfo={packidinfo} addCourseFunc={() => setGroupChatModule('searchCourse')}></SeriesCourse>
  }
  let groupChatSeriesAdd = () => {
    return (
      <SeriesAdd groupid={props.groupid} closeFunc={ () => seriesAddCloseFunc() }></SeriesAdd>
    )
  }

  let Examlist = () => {
    console.log(props.groupid)
    if(examresults){
      return (
        <Examresults history={props.history} gotoresult= {gotoresult} change = {changeExams} groupid={props.groupid} examlist={examqalist} closeFunc={ () => seriesAddCloseFunc() }></Examresults>
      )
    }else{
      return (
        <Exam changeagain={changeagain} history={props.history} userqacount={userqalist.userqacount} showlayer={userqalist.showlayer} userqastate={userqalist.userqastate} gotoresult= {gotoresult} change = {changeExams} groupid={props.groupid} examlist={examqalist} closeFunc={ () => seriesAddCloseFunc() }></Exam>
      )
    }
  
  }

  
  let seriesAddCloseFunc = () => {
    setGroupChatModule('seriesCourse')
    changepacklist();
  }

  
  let groupChatModuleComp = {
    searchCourse: groupChatSearchCourse,
    seriesCourse: groupChatSeriesCourse,
    seriesAdd: groupChatSeriesAdd,
    exam:Examlist,
  }
  let certificate = ()=>{
    props.history.push("/certificategroup?type="+props.groupid );
  }

  return (
    <div className="groupCourse">

      <div className="groupCourseLeft scrollbar">

        <div className={userqalist.isexam==1 ?"groupCourseSeriesHead":"none"}>
          {userqalist.isexam==1 && userqalist.islookover==1?<div className="groupCourseSeriesTitle cursor text-overflow"  onClick={() => setGroupChatModule('exam')} >考试</div>
          :<div className="groupCourseSeriesTitle cursor active text-overflow"  >考试</div>}
        </div>
        <div className={userqalist.isexam==1 ?"groupCourseSeriesHead":"none"}>
        {userqalist.userqacount>=80?<div className="groupCourseSeriesTitle cursor text-overflow"  onClick={() => certificate()} >证书</div>
          :<div className="groupCourseSeriesTitle cursor active text-overflow"  >证书</div>}
        </div>
        <div className="groupCourseSeriesHead">
          <div className="groupCourseSeriesTitle cursor text-overflow"   onClick={() => setGroupChatModule('seriesCourse')} >群组必修课程</div>
          {props.usertypes==1 || props.usertypes==2 ||props.usertypes==4? <img src={require('../../../../static/images/icon8.png')} alt="" className="groupCourseSeriesAdd" onClick={() => setGroupChatModule('seriesAdd')} />:null }
         
        </div>
        <div className="groupCourseSeriesBody">
          {packinfo.map((item,index)=>{
            return groupCourseSeriesItem(item,index)
          })}
          {/* { groupCourseSeriesItem() }
          { groupCourseSeriesItem() }
          { groupCourseSeriesItem() } */}
        </div>
      </div>
      
      <div className="groupCourseRight scrollbar">
        { groupChatModuleComp[groupChatModule]() }
      </div>
    </div>
  )
};

export default IndexComponent;
