import React from 'react'

import './index.css'

function PersonTitle2022(props) {
    return (
        <div className="person-title">
            <div className="wrap">
                <span className={props.activetype==1?'active':null} onClick={()=>props.gochangeactive(1)}>{props.title}</span>
                <span className={props.activetype==2?'active':null} onClick={()=>props.gochangeactive(2)} >参会证书</span>
            </div>
        </div>
    )
}

export default PersonTitle2022
