import React from 'react'

import './index.css'

function FileType(props) {
    // 二级循环start
    let listlabel = props._item.list.map((twolist, twoidex) => {
        // 三级循环start
        console.log(twolist.list.length)
        let thirdlist = twolist.list.map((list,thirdindex) => {
            let fourlist = list.list.map((fourlistnew, fourindex) => {
                //console.log(fourlistnew)
                let downbtn = null;
                if(fourlistnew.iszip==1){
                    console.log()
                    if(localStorage.getItem('userid')){
                    return (
                        <div
                            key={fourlistnew.id}
                            className={fourlistnew.active ? "typedown-li typedownleft active" : "typedown-li typedownleft"}>
                            {fourlistnew.title}
                            {downbtn}
                            <div className="typePreview">
                                <div href={fourlistnew.pdf} target="_black" onClick={() => props.godownload(fourlistnew.id, fourlistnew)}>下载</div>
                            </div>
                        </div>
                    )
                    }else{
                        return null;
                    }
                }else{
                    return (
                        <div
                            key={fourlistnew.id}
                            className={fourlistnew.active ? "typedown-li typedownleft active" : "typedown-li typedownleft"}>
                            {fourlistnew.title}
                            {downbtn}
                            <div className="typePreview">
                                <div href={fourlistnew.pdf} target="_black" onClick={() => props.recoreseenum(fourlistnew.id, fourlistnew)}>预览</div>
                            </div>
                        </div>
                    )
                }
                   
            })
           // console.log(fourlist)
            return (
            < div
                    className={list.active ? "down_scendbox active" : "down_scendbox" }
                    key={thirdindex } >
                    <h4
                        className={list.active ? "typedown-stitle typedown-left active_second" : "typedown-stitle  typedown-left"}
                        onClick={(e) => props.typethirdSelect(props._item.index, twoidex, thirdindex, e)}>
                        <i className="scend_line"></i>{list.title}
                        <span
                            className={list.active ? 'question-second active' : 'question-second'}>
                        </span>
                    </h4>
                    <div className={list.active ? "second-select active" : "second-select"}>
                        {fourlist}
                    </div>
                </div >
            )
        })
        if (thirdlist !=''){
            return (
                <div
                    className={ twolist.active ? "down_scendbox active" : "down_scendbox"}
                    key={twoidex}>
                    <h4
                        className={twolist.active ? "typedown-stitle active_second" : "typedown-stitle"}
                        onClick={(e) => props.typeTwoSelect(props._item.index, twoidex, e)}>
                        <i className="scend_line"></i>{twolist.title}
                        <span
                            className={twolist.active ? 'question-second active' : 'question-second'}>
                        </span>
                    </h4>
                    <div className={twolist.active ? "second-select active" : "second-select"}>
                        {thirdlist}
                    </div>
                </div>
            )
        }else{
            return ''
        }
        // return (
        //     <div
        //         className={thirdlist == '' ? 'none'  :twolist.active ? "down_scendbox active" : "down_scendbox"}
        //         key={twoidex}>
        //         <h4
        //             className={twolist.active ? "typedown-stitle active_second" : "typedown-stitle"}
        //             onClick={(e) => props.typeTwoSelect(props._item.index, twoidex, e)}>
        //             <i className="scend_line"></i>{twolist.title}
        //             <span
        //                 className={twolist.active ? 'question-second active' : 'question-second'}>
        //             </span>
        //         </h4>
        //         <div className={twolist.active ? "second-select active" : "second-select"}>
        //             {thirdlist}
        //         </div>
        //     </div>
        // )
        // let downbtn = null;
        // if (twolist.downid) {
        //     downbtn = (
        //         <div className="typeDownBtn" onClick={() => props.downajax(twolist.id)}>
        //             下载
        //             </div>
        //     )
        // }
        // return (
        //     <div
        //         key={twolist.id}
        //         className={twolist.active ? "typedown-li active" : "typedown-li"}>
        //         {twolist.title}
        //         {downbtn}
        //         <div className="typePreview">
        //             <a href={twolist.pdf} target="_black" onClick={() => props.recoreseenum(twolist.id)}>预览</a>
        //         </div>
        //     </div>
        // )

    })
    
   let state=0;
    for (var i = 0; i < listlabel.length;i++){
        if(listlabel[i] !=''){
            state++
        }
    }
    if (state>0){
        return (
            <div className="filetype-box">
                <h4
                    className={props._item.active ? "typedown-title active_first" : "typedown-title"}
                    onClick={(e) => props.typeSelect(props._item.index, e)}>
                    {props._item.title}
                    <span
                        className={props._item.active ? 'question-titlearrow active' : 'question-titlearrow'}>
                    </span>
                </h4>
                <div className={props._item.active ? "typedown-list active" : "typedown-list"}>
                    {listlabel}
                </div>
            </div>
        )
    }else{
        return null
    }
    
}

export default FileType
