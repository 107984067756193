import React from 'react'

import './index.css'
import { Link } from 'react-router-dom'

function Cslayer(props) {
    console.log(props)
    let groupname  = ''
    if(props.groupinfolist){
        for(var i=0;i<props.groupinfolist.length;i++){
            // if(i==props.groupinfolist.length-1){
            //     groupname +=props.groupinfolist[i]['name'];
            // }else{
                groupname +="《"+props.groupinfolist[i]['name']+"》";
            // }
        }
    }
    return (
        <div className="bbslayer">
            <div className="bbslayer_box">
                <h2 className="common-masktitle">
                致道微课堂
                    <span
                        className="common-maskclose cursor"
                        onClick={()=>props.closejumpcourse()}
                       ></span>
                </h2>

                <div className='bbslayer_textbox'>
                    <div className='bbslayer_title'>解锁成功</div>
                  
                    <div className="bbslayer_btnlist">
                        <div className={props.csliselect?"bbslayer_btn":"bbslayer_btn "} onClick={()=>props.jumpcourse()}>确认</div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Cslayer
