import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
// import { change_active_two, change_person_historydata } from '../../store/action'
import Landingservies from './index.bundle'

const mapStateToProps = (state) => {
  return {
    history: state.person_history
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    // change_active_two(data) {
    //   dispatch(change_active_two(data))
    // },
    // change_person_historydata(data) {
    //   dispatch(change_person_historydata(data))
    // }
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Landingservies)
);
