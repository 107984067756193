import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Diexamachievement from './index.bundle'

const mapStateToProps = (state) => {
  return {
    userinfo: state.userinfo
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Diexamachievement)
);
