import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import $ from 'jquery'
// 组件
import Header from '../../components/header'
import Footer from '../../components/footer'
import PersonImg from '../../components/personimg'
import PersonTitle from '../../components/persontitle'
import AccountList from '../../components/accountlist'

import './index.css'

class SubAccount extends Component {
  constructor() {
    super()

    this.deleteAccount = this.deleteAccount.bind(this)
  }

  deleteAccount(item) {
    $.ajax({
      type: "GET",
      url: 'https://shl-info.cn/front/frontc/delsubaccount?userid=' +
        this.props.userinfo.userid +
        '&subid=' + item.id,
      dataType: "json",
      headers:{'token':this.props.userinfo.token },
      success: (res) => {
        if (res && res.status !== 2) {
          let new_accountlist = this.props.accountlist
          new_accountlist.splice(item, 1)
          this.props.change_accountlist(new_accountlist)
        }
      }
    });
  }

  componentDidMount() {
    this.props.change_accountlist_data()
  }
  componentWillUnmount() {

  }
  render() {
    let list = null;
    if (this.props.accountlist) {
      list = this.props.accountlist.map((item) => {
        return (
          <AccountList
            key={item.id}
            _item={item}
            deleteAccount={this.deleteAccount}>

          </AccountList>
        )
      })
    }
    return (
      <div className='personpage'>
        <Header active='4'></Header>
        <PersonImg type='no'></PersonImg>
        <PersonTitle title='子账号管理'></PersonTitle>
        <div className='wrap'>
          {list}
          <div className="record-bottom">
            <div className="add-newaccount"><Link to="/addaccount">创建新账号</Link></div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    )
  }
}

export default SubAccount
