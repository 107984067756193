import CryptoJS from 'crypto-js';

/** 加密
 * @param word 加密数据
 */
const encrypt = (word: string) => {
  if (word) {
    try {
      
      // $key="ZErJ8BeX0IdFWEwl";
      // $iv = "RRGfHn9YYGb8G7xx";
      const key = CryptoJS.enc.Utf8.parse("ZErJ8BeX0IdFWEwl");
      const iv = CryptoJS.enc.Utf8.parse("RRGfHn9YYGb8G7xx");
      const encoded = CryptoJS.AES.encrypt(word, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }).toString();
      return encoded;
    } catch (err) {
      return err;
    }
  }
  return '';
};

/** 解密
 * @param word 解密数据
 */
const decrypt = (word: string) => {
  if (word) {
    try {
      const key = CryptoJS.enc.Utf8.parse("ZErJ8BeX0IdFWEwl");
      const iv = CryptoJS.enc.Utf8.parse("RRGfHn9YYGb8G7xx");
      const decode = CryptoJS.AES.decrypt(word, key, {
        iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });
      return decode.toString(CryptoJS.enc.Utf8);
    } catch (err) {
      return err;
    }
  }
  return '';
};

export { encrypt, decrypt };
