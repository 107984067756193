import React from 'react'

import './index.css'

function FileHots(props) {
    // 预览部分
    let preview = null;
    if (props._item.pdf) {
        preview = (
            <div className="previewBtn">
                <a href={props._item.pdf}> </a>
            </div>
        )
    }
    let userid =null;
        userid= localStorage.getItem('userid');
    console.log(userid)
    if (props._item.iszip==1 && !userid){
        return null;
    }
    return (
        <div className="filehot">
            <span className="hotdown-img">
                <img src={props._item.src} alt="" />
            </span>
            <h4 className="hotdown-title">
                {props._item.name}
            </h4>
            <div className="hotdown-label">
                <span className="hotdown-top">
                    <img src={require('../../static/images/hotdownIcon_01.png')} alt=""/>
                    TOP{props._item.index + 1}
                </span>
                <span className="gotdown-nums">
                    <img src={require('../../static/images/hotdownIcon_02.png')} alt="" />
                    {props._item.downnum}
                </span>
            </div>
            <div
                className={['hotdownStar cursor', props._item.active ? 'active' : null].join(' ')}
                onClick={(e) => props.loveClick(props._item.index, e)}>
                {props._item.iszip ==1?(
                    <div href={props._item.pdf} target="_black" className="addnew" onClick={() => props.godownload(props._item.id, props._item)}>
                        <div className="hotdown-margin">
                            <span className="starcontain"></span>
                        下载
                    </div>
                    </div>
                ):(
                    <div href = {props._item.pdf} target="_black" className="addnew" onClick={() => props.recoreseenum(props._item.id, props._item)}>
                    <div className="hotdown-margin">
                    <span className="starcontain"></span>
                        预览
                    </div>
            </div>
                )}
               
            </div>
            {preview}
        </div>
    )
}

export default FileHots
