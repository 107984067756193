import React from 'react'
import { Link } from 'react-router-dom'

import './index.css'

function RecordList(props) {
    //console.log(props)
    return (
        <div className='record-list'>
           
            {
                props.item && props.item.isgroup ==1?(
                    <Link to={'/certificategroup?type='+props.id+"&activetype="+props.activetype} className="overflow">
                            <span className="fl">{props.title}</span>
                            <span className="fr">{props.right}</span>
                        </Link>
                ):
                props.href?props.activetype==2?(
                    <Link to={'/certificatedata?type='+props.id+"&activetype="+props.activetype} className="overflow">
                        <span className="fl">{props.title}</span>
                        <span className="fr">{props.right}</span>
                    </Link>
                    
                ):(
                    <Link to={'/certificatedata?type='+props.id+"&activetype="+props.activetype} className="overflow">
                        <span className="fl">{props.title}</span>
                        <span className="fr">{props.right}</span>
                    </Link>
                    
                ):(
                    <div className="overflow">
                        <span className="fl">{props.title}</span>
                        <span className="fr">{props.right}</span>
                    </div>
                )
            }
            
        </div>
    )
}

export default RecordList
