import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { change_userinfo } from "../../store/action";
import Login from './index.bundle'

const mapStateToProps = (state) => {
  return {
    emailexg: state.verification.email,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    change_userinfo(data) {
      dispatch(change_userinfo(data));
    }
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Login)
);
