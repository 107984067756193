import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  change_file_hordownlist,
  change_file_typedownlist,
  change_active_two,
  change_file_data,
  change_file_hotdownarr,
  change_labellist,
  change_common_search } from '../../store/action'
import HomeFile from './index.bundle'

const mapStateToProps = (state) => {
  return {
    active_two: state.active_two,
    hordownlist: state.file_hordownlist,
    typedownlist: state.file_typedownlist,
    number: state.file_hotdownarr,
    userinfo: state.userinfo,
    emailexg: state.verification.email,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    change_file_hordownlist(data){
      dispatch(change_file_hordownlist(data))
    },
    change_file_typedownlist(data){
      dispatch(change_file_typedownlist(data))
    },
    change_active_two(data) {
      dispatch(change_active_two(data))
    },
    change_file_data() {
      dispatch(change_file_data())
    },
    change_file_hotdownarr(data) {
      dispatch(change_file_hotdownarr(data))
    },
    change_labellist(data) {
      dispatch(change_labellist(data))
    },
    change_common_search(data) {
      dispatch(change_common_search(data))
    }
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HomeFile)
);
