let state = {
  // 登录状态
  login: '',
  // 当前导航记录
  active: '0',
  // 记录第二层级
  active_two: '',
  active_series: 0,//系列课程导航记录
  /* 首页 */
  // 首页banner列表
  home_bannerlist: [],
  // 首页课程中心
  home_classlist: [],
  // 首页课程中心
  home_infolist: [],
  // 首页课程中心
  home_classxilielist: [],
  // 首页直播中心
  home_playlist: [],
  // 第二个课程中心
  home_classfour: [],
  // 推荐课程
  home_recommend: [],

  /* 直播中心 */
  // banner列表
  play_bannerlist: [],
  // 课程预告列表
  play_developments: [],
  // 近期回放列表
  play_hotclass: [],

  // 课程中心
  // banner列表
  back_bannerlist: [],
  // 精彩回顾
  back_reviewclass: [],
  // 专家讲堂
  back_hotback: [],
  // 系列课程全部内容
  back_reviewClassArr: [],
  // 系列课程列表
  developments: [],
  // 常见问答
  back_question: [],
  /* 文档中心 */
  // 最热查看列表
  file_hordownlist: [],
  // 资料分类
  file_typedownlist: [],
  file_hotdownarr: 0,
  /* 个人中心 */
  // 推荐课程
  person_classlist: [],
  // 观看历史
  person_history: [],
  // 学习记录
  person_learnrecord: {
    lasttimer: '',
    reglist: '',
    loglist: []
  },
  // 我的证书
  person_certificate: [],
  // 下载记录
  person_downrecord: [],
  // 我的收藏
  person_collect: [],
  // 学习计划
  // 定制课程
  person_customized: [],
  // 指向课程
  person_pointclass: [],
  // 子账号管理
  person_accountlist: [],
  /* 统一筛选标签 */
  common_labellist: [],
  common_search: '',
  userinfo: {
    token: '',
    userid: '',
    pic: '',
    info: {
      addtime: '',
      component: '',
      email: '',
      id: 17,
      local: '',
      name: '',
      openid: '',
      people: '',
      phone: '',
      pid: 0
    }
  },
  verification: {
    //email: new RegExp('^([.a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+'),
    //
    email: /^(\w+([.A-Za-z0-9_-]+)*){1,18}@\w+([-.][A-Za-z0-9]+)*\.\w+([-.][A-Za-z0-9]+)*$/,
    //email: new RegExp('^(\w+([-.][A-Za-z0-9]+)*){3,18}@\w+([-.][A-Za-z0-9]+)*\.\w+([-.][A-Za-z0-9]+)*$'),
    phone: /^1[3456789]\d{9}$/
  },
}

export default state
