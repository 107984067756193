import React from 'react'

import './index.css'

function AccountList(props) {
    return (
        <div className='account-list'>
            <span className="fl text-overflow">{props._item.company}</span>
            <span className="fl text-overflow">{props._item.name}</span>
            <span className="fr">{props._item.time}</span>
            <span
             className="delete-account"
             onClick={() => props.deleteAccount(props._item)}></span>
        </div>
    )
}

export default AccountList
