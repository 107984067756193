import React, { useState, useEffect } from 'react'
import Search from '../Search'
import './index.css'
import QRCode from 'qrcode.react';

const IndexComponent = (props) => {

  let [qrcodenum, setQrcodenum] = useState('')
  useEffect(() => {
    fetch(
      "https://shl-info.cn/front/Groupchat/setGroupQrcodeTime?userid=" +
      localStorage.getItem('userid') +
      "&chatcode=" +
      props.groupid ,{
        headers:{
          'token':localStorage.getItem('token') 
        }
      }
    )
      .then(data => {
        return data.json();
      })
      .then(res => {
        console.log(res)
        if(res.code==2000){
          setQrcodenum(res.data.qrcodenum)
        }

      });
  },[])
  let groupDataAddUserItem = () => {
    return (
      <div className="groupDataAddUserItem">
        <div className="groupDataAddUserItemHead">
          <div className="groupDataAddUserItemName">王五</div>
          <div className="groupDataAddUserItemLabel">内部用户</div>
        </div>
        <div className="groupDataAddUserItemDetail">
          <div className="groupDataAddUserItemDetailItem">1</div>
          <div className="groupDataAddUserItemDetailItem">vssqa@qq.com</div>
        </div>
        <div className="groupDataAddUserItemPos">
          <div className="groupDataAddUserItemAdd">
            <img src={require('../../../../static/images/icon7.png')} alt="" className="groupDataAddUserItemAddIcon" />
            <div className="groupDataAddUserItemAddText">添加</div>
          </div>
        </div>
      </div>
    )
  }


  return (
    <div className="groupDataAddUser scrollbar">
      <div className="groupDataAddUserHead">
        <img src={require('../../../../static/images/icon6.png')} alt="" className="groupDataAddUserReturn" onClick={() => props.closeFunc()}  />
      </div>
      
      {/* <div className="groupDataAddUserInputView">
        <Search></Search>
      </div> */}

      {/* <div className="groupDataAddUserList">
        { groupDataAddUserItem() }
        { groupDataAddUserItem() }
        { groupDataAddUserItem() }
      </div> */}

      <div className="groupDataQrcode">
        <div className="groupDataQrcodeHead">
          <img src={require('../../../../static/images/groupcodelogo.png')} alt="" className="groupDataQrcodeLogo" />
        </div>
        <div className="groupDataQrcodeBody">
          
          <div className="groupDataName">{localStorage.getItem('name')}</div>
          <div className="groupDataName padding10">邀请您加入</div>
          <div className="groupDataName">{props.groupinfo.name}</div>
          {/* <div className="groupDataSubName">{props.groupinfo.hospital}</div> */}
          {/* <img src="" alt="" className="groupDataQrcodeImg" /> */}
          {qrcodenum?<QRCode
                    className="groupDataQrcodeImg"
                    value={"https://shl-info.cn/mobile/groupreg?qrcodenum="+qrcodenum}  //value参数为生成二维码的链接
                    size={160} //二维码的宽高尺寸
                    fgColor="#000000"  //二维码的颜色
                    level="H"
                  />:null}
          <div className="groupDataQrcodeTips top20">扫描二维码进入群组</div>
          {/* <div className="groupDataLine"></div>
          <div className="groupDataQrcodeTips">有机会获得精美好礼</div> */}
        </div>
      </div>
      
    </div>
  )
};

export default IndexComponent;
