import React from 'react'

import './index.css'
import { Link } from 'react-router-dom'

function MaskBoxsurvery(props) {
    console.log(props)
    return (
        <div className="common-mask">
            <div className={props.info.type == 'signfirst' ? "common-maskbox addtextmore" : "common-maskbox "}>
                <h2 className="common-masktitle">
                    {props.info.title}
                    <span
                        className="common-maskclose cursor"
                        onClick={() => props.closeMask()}></span>
                </h2>
                <div className="common-masktext-surbox">
                   <p style={{ whiteSpace: 'pre-wrap' }}> {props.info.text}</p>
                    <div className="masksurbox-btnlist">
                        <span className="maskokbtn" onClick={()=>props.subsurvery()}>马上提交</span>
                        <span className="changebtn" onClick={()=>props.changesurverymask()}>稍后提交</span>
                    </div>
                   
                </div>
            </div>
        </div >
    )
}

export default MaskBoxsurvery
