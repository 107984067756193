import React, { useState, useEffect } from 'react'
// 组件
import Header from '../../components/header'
import Footer from '../../components/footer'
import PostItem from '../../components/postItem'
import TalkItem from '../../components/talkItem'
import utils from '../../utils/index'
import classnames from 'classnames';
import axios from 'axios';
import moment from 'moment';
import './index.css'

const DoctorQuery = (props) => {
  let userid = localStorage.getItem('userid'), token = localStorage.getItem('token');
  let query = utils.getUrlParams()
  const propsinfo = props
  let [ list, setList ] = useState([])

  useEffect(() => {
    init()
  }, [propsinfo])


  let init = () => {
    let type = query.type
    if (type === 'post') {
      queryPost()
    } else {
      queryTalk()
    }
  }

  // useEffect(() => {
  //   console.log(111)
  //   init()
  // }, [propsinfo])

  // 查询话题：列表
  let queryTalk = async () => {
    let queryValue = query.query
    console.log(queryValue)
    let res = await axios({
      method: 'get',
      headers:{
          "token":token
      },
      url: 'https://shl-info.cn/front/bbs/topicList',
      params: {
          user_id: userid,
          title:queryValue,
      }
  })
  // let data = res.data
  console.log(queryValue)
  console.log(res)
    // let res = await axios({
    //   method: 'get',
    //   url: `https://shl-info.cn/front/bbs/articleList`,
    //   params: {
    //     user_id: userid,
    //     token: token,
    //   }
    // })
    let data = res.data
    console.log(data)
    setList(data)
    //setList(data)
  }

  // 查询帖子：列表
  let queryPost = async () => {
    let queryValue = query.query
    console.log(queryValue)
    let res = await axios({
      method: 'get',
      headers:{
          "token":token
      },
      url: `https://shl-info.cn/front/bbs/articleList`,
      params: {
        user_id: userid,
        topic_id: '',
        keywords_id: '',
        title: queryValue,
        order: 'id'
      }
    })
    let data = res.data
    setList(data.data)
  }

  // 帖子
  let postItemDom = () => {
    console.log(list)
    return list.map((item, index) =>{
      return (
        <PostItem data={item} key={item.id}  isgodetail={true} history={props.history}></PostItem>
      )
    })
  }

  // 话题
  let TalkItemDom = () => {
    let bodylist=null
    console.log(list)
    bodylist = list.map((item,index)=>{
      return <TalkItem data={ item } key={ index }></TalkItem>
    })
   
    return (
      <div className="talkBody">
        {bodylist}
        {/* 
        <TalkItem mode="large"></TalkItem>
        <TalkItem mode="large"></TalkItem>
        <TalkItem mode="large"></TalkItem> */}
      </div>
    )
  }
  
  // 提示
  let notDataDom = () => {
    let queryValue = query.query

    return (
      <div className="tipsBody">
        <div className="tipsContent">
          找不到与“<span>{ queryValue }</span>”相关的结果<br/>
          请检查您的拼写或尝试其他结果
        </div>
      </div>
    )
  }

  // 加载更多
  let doctorQueryLoadDom = () => {
    return (
      <div className="doctorQueryLoad">
        加载更多
        <img className="doctorQueryLoadIcon" src={require('../../static/images/icon-9.png')}/>
      </div>
    )
  }

  let contentDom = () => {
    if (list.length === 0) return notDataDom()
    let type = query.type
    return type === 'post' ? postItemDom() : TalkItemDom()
  }

  return (
    <div className='doctorQuery'>
      <Header active='2'></Header>
      <div className="doctorQueryContent">
        <div className="doctorQueryHead">
          为您找到相关
          {query.type=='post'?"帖子":"主题"}
          {list.length}个
        </div>
        <div className="doctorQueryBody">
          { contentDom() }
        </div>
        { list.length === 0 ? null : doctorQueryLoadDom() }
      </div>
      
      <Footer></Footer>
    </div>
  )
}

export default DoctorQuery
