import React from 'react'

import './index.css'

function Question(props) {
    return (
        <div className={['question-list cursor', 
            props._item.active?'active':null].join(' ')}>
            <h3
                className='question-title'
                onClick={(e) => props.changeQuestion(props.index, e)}>
                {props._item.title}
                <span className="question-arrow"></span>
            </h3>
            <p className="question-text">
                {props._item.text}
            </p>
        </div>
    )
}

export default Question
