import React, { Component } from 'react'
// 组件
import Header from '../../components/header'
import Footer from '../../components/footer'
import Banner from '../../components/banner'
import SelectBox from '../../components/selectbox'
import ClassList from '../../components/classlist'

import './index.css'

class HomePlaying extends Component {
  constructor() {
    super()
    this.changeSelect = this.changeSelect.bind(this)
    this.selectChangedepartment = this.selectChangedepartment.bind(this)
    
    this.state = {
      typeids: 0,
      thisselectids:0,
      myregistration:[],
      
      page:0,
      loadingstate:false,
      isend:false,
      thistypes:1,
    }
    
  }

  static defaultProps = {
    selectlist: [
      {
        id: 1,
        type: 'lasttime',
        title: '近期回放',
        icon: require('../../static/images/playing_icon_02.png'),
        icon_act: require('../../static/images/playing_icon_02_act.png')
      },
      {
        id: 0,
        type: 'classfur',
        title: '课程预告',
        icon: require('../../static/images/playing_icon_01.png'),
        icon_act: require('../../static/images/playing_icon_01_act.png')
      },
      {
        id: 2,
        type: 'myregistration',
        title: '我的报名',
        icon: require('../../static/images/playing_icon_01.png'),
        icon_act: require('../../static/images/playing_icon_01_act.png')
      }
    ]
  }

  onScrollHandle(event) {
    const clientHeight = event.target.clientHeight
    const scrollHeight = event.target.scrollHeight
    const scrollTop = event.target.scrollTop
    const isBottom = (clientHeight + scrollTop === scrollHeight)
    console.log('is bottom:' + isBottom)
    if(isBottom ){
      this.loadinglist(3);
    }
  }
  loadinglist = (id)=>{
    if( this.state.loadingstate || this.state.isend || id!=3 ){
      return
    }
    this.setState({
      loadingstate:true,
    })
    fetch(
      "https://shl-info.cn/front/livevideo/getPrivateCourseList?userid=" +
      localStorage.getItem('userid')+"&page="+this.state.page+
      "&changetype=" +
      localStorage.getItem('changetype') ,{
        headers:{
          'token':localStorage.getItem('token') 
        }
      }
    )
    .then(data => {
      return data.json();
    })
    .then(res => {
      console.log(res.data)
      
      if(res.code!=2000){
        return;
      }


      if(res.data.length<=0){
        this.setState({
          isend:true,
        })
        return;
      }
      let page=this.state.page+1;
      let pagelist = this.state.myregistration;
      for(var i=0;i<res.data.length;i++){
        pagelist.push(res.data[i])
      }
      this.setState({
        myregistration:pagelist,
        page:page,
        loadingstate:false,
      })
    });
  }
  // 切换导航
  changeSelect(id) {
    var keywords = '';
    this.setState({
      typeids: 0,
      thistypes:id
    })
    if (id == 'lasttime') {
      keywords = '近期回放';
    } else if (id == 'classfur') {
      keywords = '课程预告';
    } else {
      keywords = '我的报名';
      this.loadinglist(3);
      // fetch(
      //   "https://shl-info.cn/front/livevideo/getPrivateCourseList?userid=" +
      //   localStorage.getItem('userid') ,{
      //     headers:{
      //       'token':localStorage.getItem('token') 
      //     }
      //   }
      // )
      // .then(data => {
      //   return data.json();
      // })
      // .then(res => {
      //   console.log(res.data)
      //   this.setState({
      //     myregistration:res.data
      //   })
      // });
    }
    window.addreadlogs(keywords)
    this.props.change_active_two(id)

  }

  componentDidMount() {
    if (this.props.active_two !== 'classfur' && this.props.active_two !== 'lasttime') {
      this.props.change_active_two('lasttime')
    }
    
    // this.props.change_active_two("lasttime")
    this.props.change_play_data()

    
    document.body.addEventListener('scroll', this.onScrollHandle.bind(this))
    


  }
  componentWillUnmount() {

  }

  
  selectChangedepartment(e) {
    var typeids = e.target.value
    console.log(typeids)
    this.setState({
      typeids: typeids,
      thisselectids:typeids,
    }, () => {
      //this.haveSubmit();
    })
  }

  render() {
    // 切换导航
    let selectlist = this.props.selectlist.map((item) => {
      return (
        <SelectBox
          key={item.id}
          _item={item}
          active={this.props.active_two}
          change={this.changeSelect}>

        </SelectBox>
      )
    })
    // 内容更换
    let container = null;
    let container_list = this.props.developments;
    if (this.props.active_two === 'lasttime') {
      container_list = this.props.hotclass;
    }
    if (this.props.active_two === 'myregistration') {
      
      console.log(3333)
      container_list = this.state.myregistration;
    }
    console.log(container_list)
    let ishover=0;
    container = container_list.map((item) => {
      console.log(item)
      console.log(this.state.typeids)
      console.log(this.props.active_two)
      if(this.state.typeids==0){
        ishover=1
        return (
          <ClassList islive={true} active_two={this.props.active_two} typeids={1}  key={item.id}   _item={item}></ClassList>
        )
      }else if(this.state.typeids==1){
        if(item.cloudtalk==1){
          ishover=1
          return (
            <ClassList islive={true}  key={item.id} _item={item}></ClassList>
          )
        }
      }else if(this.state.typeids==2){
        if(item.cloudread==1){
          ishover=1
          return (
            <ClassList islive={true} key={item.id} _item={item}></ClassList>
          )
        }
      }else{
        if(item.cloudread!=1 && item.cloudtalk!=1 ){
          ishover=1
          return (
            <ClassList islive={true} key={item.id} _item={item}></ClassList>
          )
        }
      }
      
    })
    console.log(ishover)
    return (
      <div className='playingpage'>
        <Header active='2'></Header>
        <Banner bannerlist={this.props.bannerlist}></Banner>
        <div className='page-navselect'>
          <div className="wrap overflow">
            <ul className="navselect fl">
              {selectlist}
            </ul>
            {/* <div className="select-contain spacing fr"> */}
            <div className="select-contain  fr">
              {/* {this.props.active_two === 'classfur'? */}
              
              <div className="statebox none">
                <div className='select-changestate'>
                  <select onChange={this.selectChangedepartment} value={this.state.typeids}>
                    <option value="0">{this.props.active_two === 'lasttime'?"回放":"预告"}筛选</option>
                    <option value="1">云讲座</option>
                    <option value="2">云读片</option>
                    <option value="3">其他直播</option>
                  </select>
                </div>
                <div className='select-changestateline'></div>
              </div>
              {/* :null} */}
              
              {ishover==1?container:<div className='nocontent'>暂无内容</div>}
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    )
  }
}

export default HomePlaying
