import React, { Component } from 'react';
import $ from 'jquery'
import MaskBox from '../../components/maskbox'
import './index.css'

class Login extends Component {
  constructor(props) {
    super(props);

    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.valueChange = this.valueChange.bind(this);
    this.loginClick = this.loginClick.bind(this);
    this.closeMask = this.closeMask.bind(this);

    this.state = {
      name: '',
      email: '',
      code: '',
      iscomefrom: false,
      nameActive: false,
      emailActive: false,
      codeActive: false,
      codeText: '验证码',
      privacy: false,
      agreement: false,
      submitActive: false,
      privacyMask: false,
      agreeMask: false,
      // placeholder
      name_place: '请输入',
      email_place: '请输入',
      phone_place: '请输入',
      maskfalg: false,
      masktext: '请填写完整',
      wechetcode: false,
      wecheterror: false,
      wechetimgurl: ''
    }
  }
  // 表单部分
  onFocus(e) {
    this.setState({
      [e.target.name + 'Active']: true
    })
  }
  onBlur(e) {
    this.setState({
      [e.target.name + 'Active']: false
    })
  }
  valueChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    }, () => {
      this.haveSubmit();
    })
  }
  // 选择label
  labelChange(name, e) {
    this.setState({
      [name]: !this.state[name]
    }, () => {
      this.haveSubmit();
    })
  }
  // 获取验证码
  getEmailCode = () => {
    if (
      (this.state.codeText !== "验证码" &&
        this.state.codeText !== "重新获取") ||
      !this.props.emailexg.test(this.state.email)
    ) {
      return false;
    }
    let nums = 90;
    let timer = () => {
      this.setState({
        codeText: nums > 0 ? nums + " s" : "重新获取"
      });
      nums -= 1;
      if (nums >= 0) {
        clearTimeout(this.codetimer)
        this.codetimer = setTimeout(timer, 1000)
      } else {
        nums = 0;
      }
    };
    timer();
    $.ajax({
      type: "GET",
      url:
        "https://shl-info.cn/front/user/identifying?email=" +
        this.state.email,
      dataType: "json",
      cache: false,
      success: res => { }
    });
  };
  // 提交部分
  loginClick() {
    let submitfalg = true;
    let submitText = '';
    if (!this.state.name || !this.state.email || !this.state.code) {
      submitfalg = false;
      submitText = '请填写完整'
    } else if (!this.props.emailexg.test(this.state.email)) {
      submitfalg = false;
      submitText = '邮箱格式错误'
    } else if (!this.state.privacy || !this.state.agreement) {
      submitfalg = false;
      submitText = '请勾选申明与协议'
    }
    if (submitfalg) {
      $.ajax({
        type: "GET",
        url: "https://shl-info.cn/front/user/login?name=" +
          encodeURI(this.state.name, "UTF-8") +
          "&email=" +
          this.state.email +
          "&code=" +
          this.state.code + '&openid=' + this.codetext,
        dataType: "json",
        cache: false,
        success: (res) => {
          if (res.status === 1) {
            localStorage.setItem('userid', res.userid)
            localStorage.setItem('token', res.token)
            this.props.change_userinfo({
              token: res.token,
              userid: res.userid,
              pic: res.pic,
              info: res.list
            })
            if (res.isfirst === 1) {
              this.props.history.push("/autographin");
            } else {
              this.props.history.push("/home");
            }
          } else {
            this.setState({
              masktext: res.status === 3 ? "验证码错误" : (res.status === 4 ? "验证码失效，请重新获取" : "用户信息有误"),
              maskfalg: true
            })
          }
        }
      })
    } else {
      this.setState({
        masktext: submitText,
        maskfalg: true
      })
    }
  }
  closeMaskTiShi = () => {
    this.setState({
      maskfalg: false
    })
  }
  // 判断是否可以提交
  haveSubmit() {
    this.setState({
      fdtime: (() => {
        if (this.state.name
          && this.state.email
          && this.state.code
          && this.state.privacy
          && this.state.agreement) {
          this.setState({
            submitActive: true
          })
        } else {
          this.setState({
            submitActive: false
          })
        }
      })()
    })
  }
  agreeClose(name, e) {
    if (name === 'privacy') {
      this.setState({
        privacyMask: false,
        privacy: true
      }, () => {
        this.haveSubmit();
      })
    } else {
      this.setState({
        agreeMask: false,
        agreement: true
      }, () => {
        this.haveSubmit();
      })
    }
  }

  contentus = () => {
    window.location.href = "https://www.healthcare.siemens.com.cn/how-can-we-help-you/email-us";
    // this.props.history.push('');
  }
  aoutbtus = () => {
    window.location.href = "https://www.healthcare.siemens.com.cn/about";
    // this.props.history.push('');
  }
  gothiurl = () => {
    window.location.href = "http://www.beian.miit.gov.cn/state/outPortal/loginPortal.action";
    // this.props.history.push('');
  }
  // 展示弹窗
  showMask(name, e) {
    if (name === 'privacy') {
      this.setState({
        privacyMask: true
      })
    } else {
      this.setState({
        agreeMask: true
      })
    }
  }
  closeMask() {
    this.setState({
      privacyMask: false,
      agreeMask: false
    })
    clearInterval(this.getwechet)
  }
  showWechet = () => {
    this.setState({
      wechetcode: true
    })
    // 请求key值
    $.ajax({
      type: "GET",
      url: "https://shl-info.cn/front/frontc/createkey",
      dataType: "json",
      cache: false
    }).then(res => {
      if (res.status === 1) {
        this.keynum = res.keynum;
        this.setState({
          wechetimgurl:
            "https://shl-info.cn/front/frontc/createcodeqr?keynum=" +
            res.keynum
        });
      }
    });
    // 轮询
    this.getwechet = setInterval(() => {
      $.ajax({
        type: "GET",
        url:
          "https://shl-info.cn/front/frontc/pclogcheck?keynum=" +
          this.keynum,
        dataType: "json",
        cache: false
      }).then(res => {
        if (res.status === 1) {
          clearInterval(this.getwechet);
          localStorage.setItem("userid", res.userid);
          localStorage.setItem("token", res.token);
          this.props.change_userinfo({
            token: res.token,
            userid: res.userid,
            pic: res.pic,
            info: res.list
          });
          if (res.isfirst === 1) {
            this.props.history.push("/autographin");
          } else {
            this.props.history.push("/home");
          }
        } else if (res.status === 4) {
          clearInterval(this.getwechet);
          this.codetext = res.openid ? res.openid : "";
          this.setState({
            wecheterror: true
          });
        } else if (res.status === 2) {
          clearInterval(this.getwechet);
        }
      });
    }, 2000)
  }
  closeWechet = () => {
    this.setState({
      wechetcode: false
    })
  }
  componentDidMount() {
    var newstate = window.location.href.indexOf("isfrom=icp")
    if (newstate > -1) {
      this.setState({
        iscomefrom: true,
      })

      $.ajax({
        type: "GET",
        url: "https://shl-info.cn/front/user/loginnew",
        dataType: "json",
        cache: false,
        success: (res) => {
          console.log(res)
          if (res.status === 1) {
            localStorage.setItem('userid', res.userid)
            localStorage.setItem('token', res.token)
            this.props.change_userinfo({
              token: res.token,
              userid: res.userid,
              pic: res.pic,
              info: res.list
            })
            if (res.isfirst === 1) {
              this.props.history.push("/autographin");
            } else {
              this.props.history.push("/home");
            }
          }
        }
      })

    }


    let userid = localStorage.getItem('userid')
    let token = localStorage.getItem('token')
    if (userid && token) {
      // 验证是否过期
      fetch(
        "https://shl-info.cn/front/user/checkuser?userid=" +
        userid ,{
          headers:{
            'token':token
          }
        }
      )
        .then(data => {
          return data.json();
        })
        .then(res => {
          console.log(res)
          if (res.status === 1) {
            this.props.history.push('./home');
          } else {

          }
        });
    } else {

    }
  }
  componentWillUnmount() {

  }

  render() {
    let maskbox = null;
    if (this.state.maskfalg) {
      maskbox = (
        <MaskBox
          info={
            {
              title: '登录',
              text: this.state.masktext,
            }
          }
          closeMask={this.closeMaskTiShi}></MaskBox>
      )
    }
    return (
      <div className="loginpage">
        <div className="wrap loginwrap ">
          <div className={this.state.iscomefrom ? "login-container center newloginbox" : " login-container center  newloginbox"} >
            <img
              alt=""
              className="login-logo"
              src={require('../../static/images/logo.png')} />
            <div className={this.state.nameActive ? 'login-form focus' : 'login-form'}>
              <span className="form-name">中文姓名</span>
              <input
                className={this.state.name_place === '请输入' ? null : 'errors'}
                name="name"
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onChange={this.valueChange}
                placeholder={this.state.name_place}></input>
              <span className="form-name">*经销商输入公司全称，内部员工输入中文姓名</span>

            </div>
            <div className={this.state.emailActive ? 'login-form focus' : 'login-form'}>
              <span className="form-name">企业邮箱</span>
              <input
                className={this.state.email_place === '请输入' ? null : 'errors'}
                name="email"
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onChange={this.valueChange}
                placeholder={this.state.email_place}></input>
            </div>
            <div className={this.state.codeActive ? 'login-form focus' : 'login-form'}>
              <span className="form-name">验证码</span>
              <input
                className={this.state.phone_place === '请输入' ? null : 'errors'}
                name="code"
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onChange={this.valueChange}
                placeholder={this.state.phone_place}></input>
              <div
                className={
                  !this.props.emailexg.test(this.state.email)
                    ? "code-btn cursor"
                    : "code-btn cursor active"
                }
                onClick={this.getEmailCode}
              >
                {this.state.codeText}
              </div>
            </div>
            <p className="login-code-text">备注：验证码将发送到您企业邮箱中，请注意查收！</p>
            <ul className="loginLabel">
              <li
                className={['fl cursor', this.state.privacy ? 'active' : ''].join(' ')}
              >
                <span
                  className="labelSelect privacy"
                  onClick={(e) => this.labelChange('privacy', e)}>
                </span>
                <span
                  className="labelText"
                  onClick={(e) => this.showMask('privacy', e)}>隐私保护政策</span>
              </li>
              <li
                className={['fr cursor', this.state.agreement ? 'active' : ''].join(' ')}
              >
                <span
                  className="labelSelect agreement"
                  onClick={(e) => this.labelChange('agreement', e)}></span>
                <span
                  className="labelText"
                  onClick={(e) => this.showMask('agreement', e)}>用户使用条款</span>
              </li>
            </ul>
            <div className="login-btnlist newloginbtn">
              <div
                className={['login-submit cursor', this.state.submitActive ? "active" : ""].join(' ')}
                onClick={this.loginClick}>提交</div>
              <div className="wechet-btn none" onClick={this.showWechet}>
                <img src={require('../../static/images/wecheticon.png')} alt="" />微信登录
              </div>
            </div>

            <div className="icpicon newloginicp"  ><span onClick={this.gothiurl}>沪ICP备19037992号-2</span> <br></br><a href='http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502010367' target="view_window">沪公网安备31011502010367号</a><br></br><a href='https://shl-info.cn/images/healthcare_certificate.pdf' target="view_window">(沪)-非经营性-2020-0063</a> <br></br>版权声明：西门子医疗系统有限公司 @2020版权所有<br></br><span onClick={this.aoutbtus} className="aboutus" >关于西门子医疗</span>|<span onClick={this.contentus} className="contentus" >联系我们</span>
            </div>
          </div>
          <div className={this.state.iscomefrom ? "newts none " : " newts none"} ></div>
          {/* <div className="icpicon"  ><span onClick={this.gothiurl}>沪ICP备19037992号-2</span> <br></br><a href='http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502010367' target="view_window">沪公网安备31011502010367号</a><br></br><a href='https://shl-info.cn/images/healthcare_certificate.pdf' target="view_window">(沪)-非经营性-2020-0063</a> <br></br>版权声明：西门子医疗系统有限公司 @2020版权所有<br></br><span onClick={this.aoutbtus} className="aboutus" >关于西门子医疗</span>|<span onClick={this.contentus} className="contentus" >联系我们</span>
          </div> */}
        </div>
        <div className={this.state.privacyMask ? "privacyMask active" : "privacyMask"} >
          <div className="privacyCenter">
            <h3 className="maskTitle">隐私保护政策
              <span className="closeIcon" onClick={this.closeMask}>
                <img src={require('../../static/images/close.png')} alt="" />
              </span>
            </h3>
            <div className="maskiscroll priva-props">
              <div className="maskscroll-wrap">
                <h4>西门子对数据隐私保护的承诺</h4>
                <p>
                  西门子重视保护您个人信息的安全和隐私。因此，我们开展业务时符合法律对数据隐私保护和数据安全的规定。希望下列政策能帮助您了解哪些数据我们可以收集，我们如何使用、保护和共享这些数据。
                </p>
                <h4>个人数据</h4>
                <p>
                  西门子不会通过我们的网站收集任何您的个人信息（例如：姓名、地址、手机号码或邮件地址），除非您自愿选择通过注册、调查等形式告知我们，或者通过被保护个人信息的法律、法规所允许的途径收集。
                </p>
                <h4>Adobe Analytics 的使用</h4>
                <p>
                  在我们的网站上使用Abode Analytics 是为了不断改进网站或识别错误。为了这个目的，有些数据需要被储存，如网站来访及它的原数据、引用西门子网站的其他页面、网站被打开的时间和所用的浏览器。
                </p>
                <p>
                  如您要排除您的访问所造成的数据聚合和分析，<a href="http://www.d1.sc.omtrdc.net/optout.html?locale=en_US&second=1&second_has_cookie=0">请点击此处</a>。它将会在您的浏览器中设置一个小文本储存文件（cookie），以排除您的访问对网站分析的影响。虽然这些数据不包含您的个人信息，但是它能使Adobe软件从技术上永久性的识别您的偏好。
                </p>
                <p>
                  如果您删除该cookie、更换计算机或浏览器，您需要重新设置这个cookie。
                </p>
                <h4>使用目的</h4>
                <p>
                  当您为我们提供个人资料时，我们通常用它来回复您的问询、处理您的订单或提供您访问的特定信息。此外，通过这些数据来维护我们的合作关系：
                </p>
                <div className="dotpadd">
                  <p className="dooted">
                    我们存储和处理个人数据并与我们的全球分支机构分享，以便更好地理解您的业务需求和我们如何能够改善我们的产品和服务；
                  </p>
                  <p className="dooted">我们（或第三方代表）可以使用您的个人资料来联系您，以便于西门子提供业务支持或进行网上调查来更好地理解客户的需要。</p>
                </div>
                <p>
                  如果您不愿意您的个人数据用于我们的客户关系维护（尤其是直接营销或市场调研），我们将尊重您的选择。我们不会出售您的个人信息给除西门子以外的第三方。
                </p>
                <h4>使用限制 </h4>
                <p>
                  西门子只因为以上目的搜集、使用或披露您在线提交的个人资料。除非：
                </p>
                <p>
                  将个人资料用于与我们搜集信息的初衷直接相关的其他任何目的，
                </p>
                <p>
                  准备、谈判或与您执行合同所需要，
                </p>
                <p>
                  被法律、政府或司法机关所要求，
                </p>
                <p>
                  建立或保护合法要求或防御所需要，
                </p>
                <p>
                  反欺诈或制止其他违法行为所需要，如恶意攻击西门子信息技术系统。
                </p>
                <h4>通信或使用数据 </h4>
                <p>
                  通过使用电信服务来访问我们的网站，您的通信数据（例如互联网协议地址）或使用数据（如开始、结束和访问度信息、您访问的电信服务信息）是技术上生成的，但也能归于个人数据。在某种程度上有必要将收集、处理和使用您的通讯或使用数据纳入数据隐私保护政策的法律框架内。
                </p>
                <h4>非个人信息的自动收集 </h4>
                <p>
                  当您进入我们的网站，我们将自动的（即无需通过注册）搜集非个人信息（如：使用的浏览器和操作系统类型、网站的域名、访问数量、在网站停留的平均时间、浏览页面等）。我们能够使用这些数据并与我们的全球分支机构共享，以此监控我们网站的吸引力并提高网站的性能或内容。
                </p>
                <h4>“Cookies”——自动在您的计算机上储存的信息</h4>
                <p>
                  当您访问了我们的网站，我们将在您的计算机上以“Cookie”的形式储存一些数据，以便在您下次访问我们的网站时能够自动识别您的计算机。Cookie能够在许多方面给我们提供帮助，例如，允许我们调整网站以更好地匹配您的兴趣或存储你的密码使您下次不需要重新输入。如果您不愿意接受cookie，请设置您的浏览器，并删除所有的cookie。限制所有的cookie或储存cookie时会收到警告。
                </p>
                <h4>儿童</h4>
                <p>
                  西门子不会在儿童没有获得他们父母同意的情况下故意收集儿童的个人信息，除非法律需要。我们只使用或披露在法律允许或依照当地法律、法规寻求父母同意的情况下的儿童个人信息。对儿童的定义应该考虑适用法律以及国家和地区的文化习俗。
                </p>
                <h4>安全性</h4>
                <p>
                  为了保护您的个人数据免受意外或非法破坏、损失或变更和未经授权的披露或访问，西门子使用技术性和有组织的安全措施。
                </p>
                <h4>链接到其他网站</h4>
                <p>
                  西门子网站包含有其他网站的链接。西门子不对其他网站的隐私保护政策或内容负责。
                </p>
                <h4>问题和评论</h4>
                <p>
                  西门子将回应合理的请求，来审查你的个人数据，纠正、修改或删除任何不准确之处。如果您有任何问题或对西门子数据隐私保护政策的评论（如：查阅或更新您的个人信息），请点击此窗口右上角的“联系我们”，并选择“关于西门子”、“关于我们——与西门子有关的问题”。由于互联网不断地发展成熟，我们的数据隐私保护政策也将不断修改完善。我们将在此页面上改变我们的数据隐私保护政策。请定期检查这个页面以获取及时信息。
                </p>
              </div>
            </div>
            <div
              className="maskButton cursor"
              onClick={(e) => this.agreeClose('privacy', e)}>
              接受条款
            </div>
          </div>
        </div>
        <div className={this.state.agreeMask ? "privacyMask active" : "privacyMask"} >
          <div className="privacyCenter">
            <h3 className="maskTitle">用户使用条款
              <span className="closeIcon" onClick={this.closeMask}>
                <img
                  className="cursor"
                  src={require('../../static/images/close.png')} alt="" />
              </span>
            </h3>
            <div className="maskiscroll agree-props">
              <div className="maskscroll-wrap">
                <p>*本系统内所有视频及文档仅作为西门子医疗在线培训系统的培训材料提供给西门子医疗内部员工及经授权的经销商参考，未经西门子医疗书面许可，任何内部员工、经销商或第三方不得对外进行任何转载、传播、宣传或用于任何商业用途，西门子医疗不对该等未经许可的行为或对外传播产生的结果负责，并保留因该等行为而造成西门子医疗相应损失追究法律责任的权利。</p>

                <h4>1. 范围 </h4>
                <p>
                  1.1本使用条款适用于任何对西门子（中国）有限公司提供的网站（"西门子网站"）的使用行为。使用条款可为其他条款（如产品和服务购买条款）所修改、调整或代替。登陆或无需登陆进入或使用西门子网站，使用条款将以其当时的形式为准。
                </p>
                <p>
                  1.2 除非另有说明，本使用条款不适用于通过西门子网站登陆其他网站的使用行为。
                </p>
                <h4>2.服务</h4>
                <p>
                  2.1 西门子网站包括专门信息、软件及有关文件（视具体情况而定），以供浏览或下载。
                </p>
                <p>
                  2.2 西门子可以随时全部或部分地停止运行西门子网站。由于网络和计算机系统的属性，西门子不对西门子网站的持续运转承担任何义务。
                </p>
                <h4>3.注册和密码</h4>
                <p>
                  3.1 西门子网站的部分页面需要提供密码进入。为保证商业交易的安全，只有注册用户才能进入上述页面。西门子保留拒绝接受任何用户注册的权利。西门子特别保留将以前自由进入的网页改为注册才能进入的网页的权利。西门子有权在任何时候，通过冻结用户数据（定义如下）禁止用户进入密码保护网页，且无需说明理由，特别是如果用户：
                </p>
                <div className="dotpadd">
                  <p className="dooted">
                    为注册目的使用虚假信息；
                  </p>
                  <p className="dooted">违反使用条款或违反用户信息注意义务；</p>
                  <p className="dooted">
                    在进入或使用西门子网站过程中违反有关法律；
                  </p>
                  <p className="dooted">
                    不再使用西门子网站。
                  </p>
                </div>
                <p>
                  3.2 用户在注册过程中应提供准确信息，信息变更时在合理期限内及时更新该信息（在可能的程度内在线更新）。用户应确保其提供给西门子的电子邮箱在任何时候为有效联系邮箱。
                </p>
                <p>
                  3.3 注册后，用户将获得进入代码包括用户名和密码（"用户信息"）。在首次进入后，用户应尽快修改西门子提供的密码。用户信息可用于浏览或修改个人信息，或取消其对数据处理的同意。
                </p>
                <p>
                  3.4 用户应确保用户信息不为第三方取得，并对以其用户信息进行的交易或其他活动负责。在每次登陆后，用户应在密码保护的网页上进行注销。如果用户发现第三方在不当使用其用户信息，其应毫不迟延地通过书面或电子邮件形式将此情况通知西门子。西门子不对任何用户的任何登陆信息或用户信息丢失或被不正当使用承担任何责任。
                </p>
                <p>
                  3.5 在收到第3.4条的信息后，西门子应拒绝该用户信息进入密码保护的网页。用户需经向西门子申请或再次注册方能进入。
                </p>
                <p>
                  3.6 在不影响合同履行的前提下，用户可以随时书面要求终止其注册。在此情况下，西门子在不再需要该信息时，应删除所有用户信息和其他存储的可辨明的用户个人信息。
                </p>
                <h4>4.使用信息、软件和文件的权利</h4>
                <p>
                  4.1 任何西门子网站上提供的信息、软件和文件的使用应适用使用条款，如涉及信息、软件和文件的更新，应适用与西门子事先达成的许可条款。单独的许可条款优于使用条款。
                </p>
                <p>
                  4.2 西门子授予用户非排他和不可转让许可（即不得进行转许可），在许可范围内使用自西门子网站获取的信息、软件和文件，如无协议，则应遵循西门子提供上述信息的目的使用。
                </p>
                <p>
                  4.3 用户在任何情况下均不得向第三方转让、出租或以其他方式提供信息、软件和文件。除非强制性法律另行规定，用户不得修改软件、文件，也不应重组、调整设计或分解软件或其部件。根据使用条款，用户可以保留软件复印件，以确保进一步使用软件。
                </p>
                <p>
                  4.4 信息、软件和文件受著作权法、国际著作权公约或其他与知识产权相关的法律和公约的保护。用户应遵守上述法律，特别是不能修改、公开或删除信息、软件、文件及其复印件中的任何文字数字代码、标识或著作权提示。
                </p>
                <h4>5.知识产权</h4>
                <p>
                  5.1 未经西门子事先书面同意，西门子网站的信息、商标和其他内容不得传播、修改、复制、出售、出租、使用、增加或以其他方式使用。
                </p>
                <p>
                  5.2 除本规定明确授予的使用权或其他权利，用户不享有其他权利也无权请求授予其他权利。任何和所有专利权和许可被明确排除。
                </p>
                <h4>6.用户义务</h4>
                <p>
                  6.1 用户在进入或使用西门子网站过程中，不得：
                </p>
                <div className="dotpadd">
                  <p className="dooted">
                    伤害他人，特别是儿童，或者侵犯他们的人身权利；
                  </p>
                  <p className="dooted">以违反公共道德的方式使用；</p>
                  <p className="dooted">
                    侵犯任何知识产权或其他财产权利；
                  </p>
                  <p className="dooted">
                    上传任何含有病毒的文件，或其他可能破坏数据的程序；
                  </p>
                  <p className="dooted">
                    传输、存储或上传用户无权的链接或内容，特别是如果该链接或内容违反保密义务或非法；
                  </p>
                  <p className="dooted">
                    发布广告或自动电子邮件（所谓的"垃圾邮件"）或不准确的病毒警告、缺陷或类似文件，用户不得要求参加任何抽奖、雪球系统、连锁信、金字塔游戏或类似活动。
                  </p>
                </div>
                <p>
                  6.2 西门子可以在任何时候拒绝用户进入西门子网站，特别是如果用户违反使用规定的义务。
                </p>
                <h4>7.超级链接</h4>
                <p>
                  西门子网站可能包含连接到第三方网页上的链接。西门子对该网页的内容不承担责任，也不承诺该网页及内容为其所有，因为西门子不能对该网页的信息进行控制，也不对网页上的内容和信息负责。用户使用上述网页应该自担风险。
                </p>
                <h4>8.权利瑕疵和质量瑕疵责任</h4>
                <p>
                  8.1 由于所提供的信息、软件或文件为免费提供给用户，除故意不当行为或欺诈外，任何关于信息、软件或文件的质量和权利的瑕疵担保义务，特别是关于准确、无瑕疵、无请求或第三方权利要求的义务，或关于充分和/或适合目的的义务，在此被明确排除。
                </p>
                <p>
                  8.2 西门子网站上的信息可能包括有关某个产品的技术可能性的专门或一般的描述，但该产品在某些情况下可能无法获得（如由于产品变化）。因此产品品质应在每次购买时由双方确认。
                </p>
                <p>
                  8.3 使用西门子网站的风险由您个人承担。公司不对所提供的信息做任何认证，包括有关适销性、针对某一特定用途的适用性、权利、交易过程或商业惯例的任何默示担保和条件。无论是否有法律原因，是否在合同和担保中写明，是否有满意的补救措施，也无论是否存在民事侵权（包括故意和过失），是否有责任限制，是否要求赔偿或是否有任何其他法律依据，西门子公司均不对任何担保要求或损失承担责任，特别是不对因在设置过程中提供咨询和帮助而导致的损失以及业务中断或软件缺陷承担责任，除非法律要求必须对损失承担责任，或因违反主要的合同义务或故意不遵守或因重大过失忽略了明示的担保义务而必须承担责任。尽管如此，因违反主要的合同义务而导致的损害赔偿责任，应仅限于合同中通常规定的可预见损失，除非这种违反是故意的或因疏忽所致。
                </p>
                <h4>9.其他义务，病毒</h4>
                <p>
                  9.1 西门子有关质量和权利瑕疵义务应根据使用条款第8条确定。西门子任何其他义务均被排除，除非法律要求如产品责任法、或故意或重大过失、人身伤害或死亡、无法满足所担保的特征、缺陷欺诈性披露、或违反基本合同义务。
                </p>
                <p>
                  9.2 尽管西门子努力使其网站免受病毒攻击，但仍无法对此提供任何保证。出于自我保护目的，用户应采取必要安全措施，并在下载信息、软件和文件前检查是否存在病毒。西门子不对病毒引起的任何用户的损失、损害承担任何责任。
                </p>
                <h4>10.出口控制</h4>
                <p>
                  10.1 如由于其属性、意图或最终使用地，某些信息、软件和文件的出口需经过授权。用户应严格遵守信息、软件和文件出口管理办法，特别是欧盟、欧盟成员国和美国的出口管理规定。西门子应标明与德国、欧盟出口控制清单和美国商务控制单有关的信息、软件和文件。
                </p>
                <p>
                  10.2 用户应特别检查和确认：
                </p>
                <div className="dotpadd">
                  <p className="dooted">
                    信息、软件和文件不得用于军事、核技术或武器；
                  </p>
                  <p className="dooted">不向美国拒绝入境人员清单所列的组织或人员，提供源于美国的货物、软件或技术；</p>
                  <p className="dooted">
                    未经授权，不向美国警告清单、实体清单或特别指定名单所列人员和组织提供上述资料；
                  </p>
                  <p className="dooted">
                    不向任何特别指定恐怖主义者清单、外国恐怖组织清单、特别指定国际恐怖主义者清单或欧盟恐怖组织上列明的组织或人员提供产品；
                  </p>
                  <p className="dooted">
                    不向军方代理人提供产品；
                  </p>
                  <p className="dooted">
                    应遵守德国和中国各政府部门的警告指示。
                  </p>
                </div>
                <p>
                  只有在符合上述保证和检查的前提下，才能取得信息、软件和文件。如果用户不遵守上述规定，西门子没有履行义务。
                </p>
                <p>
                  10.3 应用户要求，西门子应向其提供联系方式以获取更多信息。
                </p>
                <h4>11.信息保密</h4>
                <p>
                  收集、使用和处理西门子网站上的用户确认信息，西门子应遵守有关保密法律和西门子网上数据保密政策，该政策可通过西门子网站链接或在<a href="https://new.siemens.com/cn/zh.html">www.siemens.com.cn</a>获取。
                </p>
              </div>
            </div>
            <div
              className="maskButton cursor"
              onClick={(e) => this.agreeClose('agreement', e)}>
              接受条款
            </div>
          </div>
        </div>
        {maskbox}
        <div className={this.state.wechetcode ? 'wechetMask active' : 'wechetMask'}>
          <div className="wechetBox center">
            <h2 className="wechetTitle">
              微信登录
                <span className="wechetClose" onClick={this.closeWechet}></span>
            </h2>
            {
              this.state.wecheterror ? (
                <div className="wechetError">
                  <p>您还未登录过“西门子医疗在线培训”系统<br />请填写姓名和邮箱进行登录</p>
                </div>
              ) : (
                  <div className="wechetCode">
                    <img src={this.state.wechetimgurl} alt="" />
                    <p>请使用微信扫描二维码<br />登录“西门子医疗在线培训平台”</p>
                  </div>
                )
            }
          </div>
        </div>
      </div>
    )
  }
}

export default Login
