import React from 'react'
import { Link } from 'react-router-dom'

import './index.css'
function gotourl(tid, link, props, history) {
  console.log(history)
  console.log(props._item.title)
  //console.log(11)
  //window.location.href = link
  //props.history.push(link);

  window.addreadlogs(props._item.title)
  if (tid == 201) {
    window.location.href = "http://1010.hicixi.com/h5/#/?email=" + localStorage.getItem('email');
    return
  }
  if (tid <= 6 || tid == 8 || tid == 11 || tid == 21 || tid == 24 || tid == 31 || tid == 102) {
    if (tid == 21) {
      fetch(
        "https://shl-info.cn/front/Scoreshop/addReadLogs", {
        method: 'POST',
        body: JSON.stringify({
          userid: localStorage.getItem('userid'),
          type: 1,
          ua: 'pc',
        }),
        headers: {
          'content-type': 'application/json',
          'token': localStorage.getItem('token')
        },
        header: {
          'content-type': 'application/json',
          'token': localStorage.getItem('token')
        },
      }
      )
        .then(data => {
          return data.json();
        })
        .then(res => {
          // this.props.history.push(e);
        })
    }
    props.history.push(link);
  } else if (tid == 101) {
    props.changetype();
  } else if (tid == 207) {

    fetch(
      "https://shl-info.cn/front/Monitor/addMonitorData?userid=" + localStorage.getItem('userid') + "&intMonitortype=1" + "&pagetype=" + "&seriesid=" + "&source=西影力"
    )
      .then(data => {
        return data.json();
      })
      .then(res => {
      })
    props.history.push(link);
  } else {
    window.location.href = link
  }
  console.log(link)
}
function PersonalList(props) {
  console.log(props)
  // 
  return (
    <li className="personal-list" style={{ width: 100 / props.width + '%' }} onClick={() => gotourl(props._item.id, props._item.link, props)}>
      {/* <Link to={props._item.link}> */}
      <img src={props._item.icon} alt="" />
      <p>{props._item.title}</p>
      {/* </Link> */}
    </li>
  )
}

export default PersonalList
