import React, { Component } from 'react';
import $ from 'jquery'
import './index.css';
import Maskbox from '../../../../components/maskbox';

class Onlinediexam extends Component {
  constructor(props) {
    super(props);
    this.closeMask = this.closeMask.bind(this);
    this.state = {
      masktext: "提交成功",
      submitmask: false,
      examlist: [],
      usercount: '',
    };
  }
  changeanswer(type, parent, index, e) {
    let new_arr = this.props.examlist;
    if (type === "1") {
      new_arr[parent].answer.forEach(item => {
        if (item.id === index) {
          item.active = true;
        } else {
          item.active = false;
        }
      });
    } else {
      new_arr[parent].answer[index].active = !new_arr[parent].answer[index]
        .active;
    }
    if (!new_arr[parent].active) {
      new_arr[parent].active = true;
    }
    this.props.change(new_arr);
  }
  submitexam() {
    // 检查是否答完所有题目
    let examComplete = [];
    let start = 0;
    this.props.examlist.forEach(item => {
      for (let i = 0; i < item.answer.length; i++) {
        if (item.answer[i].active && !examComplete[start]) {
          examComplete[start] = { id: item.index, answer: [] };
          examComplete[start].answer.push(item.answer[i].title);
        } else if (item.answer[i].active && examComplete[start]) {
          examComplete[start].answer.push(item.answer[i].title);
        }
      }
      start += 1;
    });
    if (examComplete.length === this.props.examlist.length) {
      $.ajax({
        type: "POST",
        url: "https://shl-info.cn/front/frontc/savesurvergroup?userid=" +
          localStorage.getItem('userid') +
          "&groupid=" +
          this.props.groupid,
        data: JSON.stringify({
          data: examComplete
        }),
        dataType: "json",
        headers: { 'token': localStorage.getItem('token') },
        cache: false
      }).then(res => {
        console.log(1111, res)
        if (res && res.status !== 2) {
          this.props.gotoresult();
        }
      });
    } else {
      console.log(1111)
      this.setState({
        masktext: "请回答所有问题",
        submitmask: true
      });
    }
  }

  again() {

    if (this.props.userqacount >= 80) {
      this.setState({
        islayer: true,
      })
      //this.gotocertificate();
      // this.setState({
      //   islayer: true,
      // })
      this.props.history.push("/certificategroup?type=" + this.props.groupid);
    } else {
      console.log(12312312312)
      console.log(222)
      // this.setState({
      //   islayer: false,
      // })
      this.props.changeagain();
    }
    // if (this.state.usercount >= 80 && this.props.classid != 152) {
    //   this.props.gotocertificate();
    //   //this.props.history.push("/Examresults" );
    // } else if (this.state.usercount >= 100 && this.props.classid == 152) {
    //   this.props.gotocertificate();
    //   //this.props.history.push("/Examresults" );
    // } else {
    //   this.setState({
    //     islayer: true,
    //   })
    // }
  }
  closeMask() {
    this.setState({
      submitmask: false
    });
  }
  componentDidUpdate() {
    console.log(this.props)
    if (this.state.usercount == '' && this.props.userqastate == 1) {
      console.log(11111)
      this.setState({
        usercount: this.props.usercount,
      })
    }
    if (this.props.userqastate == 1 && this.state.islayer) {
      console.log(2222)
      this.setState({
        islayer: false,
      })
    }
  }
  componentDidMount() {

    console.log(this.props)
    // this.setState({
    //   usercount: this.props.usercount,
    // })
    if (this.props.userqastate == 1) {
      this.setState({
        islayer: false,
      })
    } else {

      this.setState({
        islayer: true,
      })
    }

  }
  componentWillUnmount() { }
  render() {
    let examlist = this.props.examlist.map((item, index) => {
      let answer = item.answer.map((list, listindex) => (
        <div
          className="exam-li"
          key={listindex}
          onClick={e => this.changeanswer(item.type, index, listindex, e)}
        >
          {list.title}
          <span
            className={
              item.type === "1"
                ? list.active
                  ? "radio active"
                  : "radio"
                : list.active
                  ? "checkout active"
                  : "checkout"
            }
          >
            <span></span>
          </span>
        </div>
      ));
      return (
        <div key={index} className="exam-wrap">
          <h2 className="exam-title">
            {index + 1}. {item.title}({item.type === "1" ? "单选" : "多选"})
          </h2>
          <div className="exam-list">{answer}</div>
        </div>
      );
    });
    let exammask = null;
    if (this.state.submitmask) {
      exammask = (
        <Maskbox
          info={{
            title: "在线考试",
            text: this.state.masktext
          }}
          closeMask={this.closeMask}
        ></Maskbox>
      );
    }
    var examstate = 1;
    if ((this.props.classid == 919 || this.props.classid == 918 || this.props.classid == 917 || this.props.classid == 841) && this.props.userqastate == 1) {
      examstate = 0;
    }
    console.log(this.props)


    // let courseidlist = ["152","154","155","156","157","158","417","648","737","862","1375","1761","1965","428","1444","2432"];
    let courseidlist = ["152", "1375", "2432", "3058"];
    return (
      <div className="exam-scroll group">
        <div className="wrap">
          <div className="iscroll-padding">{examlist}

            {/* <div>123123123123</div>
          <div>123123123123</div>
          <div>123123123123</div>
          <div>123123123123</div>
          <div>123123123123</div> */}
          </div>

          {
            this.props.examlist.length > 0 && examstate == 1 ? (
              <div
                className="exam-submit touchCss"
                onClick={() => this.submitexam()}
              >
                提交
              </div>
            ) : null
          }
          {exammask}
        </div>
        <div className={this.props.showlayer != 1 ? "exam_layer none" : "exam_layer "}>
          <div class="result-number "><h5><span>{this.props.userqacount}</span>分</h5>

            <p>{this.props.userqacount >= 80 ? "恭喜您通过了考试" : "本次考试80分通过，继续努力！"}</p>

            <div className="down-footer">
              {examstate == 1 ? (
                <div
                  className="exam-submit touchCss"
                  onClick={() => this.again()}
                >
                  <span>{this.props.userqacount >= 80 ? "查看证书" : "重新考试"}</span>

                </div>
              ) : null}

            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Onlinediexam;
