import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { change_person_learnplan, change_customized, change_active_two } from '../../store/action'
import IntegralMall from './index.bundle'

const mapStateToProps = (state) => {
  return {
    customized: state.person_customized,
    pointclass: state.person_pointclass,
    userinfo: state.userinfo
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    change_person_learnplan() {
      dispatch(change_person_learnplan())
    },
    change_customized(data) {
      dispatch(change_customized(data))
    },
    change_active_two(data) {
      dispatch(change_active_two(data))
    }
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(IntegralMall)
);
