import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import $ from 'jquery'
import { encrypt, decrypt } from '../../utils/crypto.ts'
import {
    change_active,
    change_labellist,
    change_active_two,
    change_common_search,
    change_common_label,
    change_userinfo
} from '../../store/action'
import { Link } from 'react-router-dom'


import MaskBox from '../maskbox'



import './index.css'

class Headerfir extends Component {
    constructor() {
        super()

        this.closeMask = this.closeMask.bind(this);
        this.state = {
            searchText: '',
            study: false,
            userid: null,
            thisusertype: false,
            studyadmin: false,
            showfalg: false,
            maskfalg: false,
            loginstate: false,
            showlayerout: false,
        }
    }

    closeMask() {
        if (this.props.signstate) {
            this.props.todayclick();
        }
        this.setState({
            maskfalg: false,
            loginstate: false,
        })
    }
    searchchange = e => {
        this.setState({
            searchText: e.target.value
        })
    }
    gologinyes = e => {
        let email = localStorage.getItem('email');
        let name = localStorage.getItem('name');
        let phone = localStorage.getItem('phone');
        let sourcechannel = localStorage.getItem('sourcechannel');
        localStorage.clear();
        localStorage.setItem('phone', phone);
        localStorage.setItem('name', name);
        localStorage.setItem('email', email);
        if (sourcechannel) {
            localStorage.setItem('sourcechannel', sourcechannel);
        }
        localStorage.setItem('refurl', window.location.href);
        this.props.history.push('./loginlist')
    }
    gologinno = e => {
        this.setState({
            showlayerout: false
        })
    }
    gologin = e => {


        let userid = localStorage.getItem('userid');

        if (!userid || userid <= 0) {
            let email = localStorage.getItem('email');
            let name = localStorage.getItem('name');
            let phone = localStorage.getItem('phone');
            let sourcechannel = localStorage.getItem('sourcechannel');
            localStorage.clear();
            localStorage.setItem('phone', phone);
            localStorage.setItem('name', name);
            localStorage.setItem('email', email);
            if (sourcechannel) {
                localStorage.setItem('sourcechannel', sourcechannel);
            }
            localStorage.setItem('refurl', window.location.href);
            this.props.history.push('./loginlist')
        } else {
            this.setState({
                showlayerout: true
            })
        }
        //localStorage.clear();
        // this.props.history.push('./loginlist')
    }
    selectLavel = (parent, id) => {
        let new_labellist = this.props.labellist;
        new_labellist[parent].list[id].active = !new_labellist[parent].list[id].active;
        this.props.change_labellist(new_labellist)
        if (!this.props.searchCollback) {
            this.props.history.push('./searchpage')
        } else {
            this.props.changeLabel(new_labellist);
        }
    }
    searchCollback = (text, e) => {
        this.props.change_common_search(text)
        if (e && e.nativeEvent.keyCode !== 13) { return false; }
        if (!this.props.searchCollback) {
            this.props.history.push('./searchpage')
        } else {
            this.props.searchCollback(text)
        }
    }

    showlabel = () => {
        this.setState({
            showfalg: !this.state.showfalg
        })
    }

    change_twonav = (data, names) => {
        window.addreadlogs(names)
        this.props.change_active_two(data)
    }

    componentDidMount() {
        console.log('添加监测代码')
        console.log(this.props)

        if (localStorage.getItem('issignstate') == 1) {
            this.setState({
                loginstate: true,
            })
            localStorage.setItem('issignstate', 0);
        }
        console.log(this.props)
        this.props.change_active(this.props.active)
        // 页面滚动到顶部位置
        document.documentElement.scrollTop = document.body.scrollTop = 0;
        if (this.props.labellist.length < 1) {
            this.props.change_common_label()
        }
        let that = this
        let userid = (localStorage.getItem('userid'));
        let token = (localStorage.getItem('token'));
        if (!userid) {
            userid = '';
        }



        var typeids = this.props.typeids;
        if (!typeids) {
            typeids = '';
        }


        if (!userid) {
            if (!localStorage.getItem('visitor_num')) {
                let user_medical_num = "visitor_" + Math.floor(Math.random() * 10000000000);
                localStorage.setItem("visitor_num", user_medical_num)
                userid = user_medical_num
            } else {
                userid = localStorage.getItem('visitor_num')
            }
        }
        //添加监测代码
        fetch(
            "https://shl-info.cn/front/frontc/addlogs?userid=" +
            userid +
            "&urlstr=" +
            encodeURI(window.location.href) +
            "&title=" +
            document.title +
            "&keywords=" +
            '' +
            "&typeids=" +
            typeids +
            "&types=" +
            'read' +
            "&isspecial=" + localStorage.getItem('special') +
            "&sourcechannel=" + localStorage.getItem('sourcechannel')
        )

        if (userid && token) {
            // 验证是否过期
            $.ajax({
                type: "GET",
                url: "https://shl-info.cn/front/user/checkuser?userid=" +
                    userid,
                dataType: "json",
                headers: { 'token': token },
                success: function (res) {
                    console.log(res)
                    console.log(22222222222)
                    if (res.status === 1) {
                        localStorage.setItem("userid", userid);
                        localStorage.setItem("token", res.token);
                        localStorage.setItem("local", res.list.local);
                        localStorage.setItem('usertype', res.list.usertype)
                        localStorage.setItem('iswaitype', res.list.type)
                        localStorage.setItem('isdi', res.list.isdi)
                        if (res.list.type == 1 || res.list.type == 2 || res.list.type == 3 || res.list.type == 5) {
                            that.setState({
                                thisusertype: true,
                            })
                        }
                        var userlist = res.list;
                        if (userlist.name) {
                            userlist.name = decrypt(userlist.name);
                        }
                        if (userlist.email) {
                            userlist.email = decrypt(userlist.email);
                        }
                        if (userlist.phone) {
                            userlist.phone = decrypt(userlist.phone);
                        }
                        res.list = userlist
                        that.props.change_userinfo({
                            token: res.token,
                            pic: res.pic,
                            userid: userid,
                            info: res.list
                        });
                    }
                }
            });
        } else {
            that.setState({
                thisusertype: true,
            })
        }


        this.setState({
            userid: userid,
        })
        if (userid && token) {
            fetch(
                "https://shl-info.cn/front/Userstudy/checkUserStudentType?userid=" + userid, {
                headers: {
                    'token': token
                }
            }
            )
                .then(data => {
                    return data.json();
                })
                .then(res => {
                    if (res.status != -1) {
                        this.setState({
                            study: true
                        })
                    }
                })
            fetch(
                "https://shl-info.cn/front/Userstudy/checkUserType?userid=" + userid, {
                headers: {
                    'token': token
                }
            }
            )
                .then(data => {
                    return data.json();
                })
                .then(res => {
                    console.log(res);
                    if (res && res.length > 0) {
                        this.setState({
                            studyadmin: true
                        })
                    }
                })
        }
    }
    componentWillUnmount() {

    }

    render() {
        // 列表渲染
        let listItems = this.props.labellist.map((item, index) => {
            let listli = item.list.map((list, listindex) => {
                return (
                    <li
                        key={listindex}
                        className={list.active ? 'active' : ''}
                        onClick={(e) => this.selectLavel(index, listindex, e)} >
                        {list.name}
                    </li>
                )
            })
            return (
                <div key={index}>
                    <h2 className="select-title">{item.name}</h2>
                    <ul className="label-list">
                        {listli}
                    </ul>
                </div>
            )
        })
        let label = null;
        if (this.state.showfalg) {
            label = (
                <div className="select-list">
                    <div className="wrap">
                        {listItems}
                    </div>
                </div>
            )

        }
        let masklayer = null;
        if (this.state.maskfalg || this.props.signstate) {
            if (this.state.thisusertype) {
                masklayer = null
            } else {
                var newnums = 0;
                if (this.propssignnums >= 2) {
                    newnums = 3;
                } else {
                    newnums = 2;
                }

                masklayer = <MaskBox
                    info={{
                        title: '签到成功',
                        text: '明天继续签到可获' + newnums + '分奖励',
                        textnew: '恭喜获得' + this.props.signscore + '分积分奖励',
                        type: 'sign',
                    }}
                    closeMask={this.closeMask}>
                    &gt;
                </MaskBox>;
            }
        }

        if ((this.state.maskfalg || this.state.loginstate)) {
            // if (this.state.thisusertype) {
            //     masklayer = null
            // } else {

            masklayer = <MaskBox
                info={{
                    title: '登录完成',
                    text: '完成课程赚积分 赢好礼',
                    textnew: '',
                    type: 'signfirst',
                }}
                closeMask={this.closeMask}>
                &gt;
               </MaskBox>;
            //}
        }


        // masklayer = null;
        return (
            <header id="header" className="headerfir">
                <div className={this.state.showlayerout ? "login_layer_is " : "login_layer_is none"}>
                    <div className='login_layer_is_box center'>
                        <h2 className="common-masktitle">
                            是否退出登录
                            {/* <span
                                className="common-maskclose cursor"
                            ></span> */}
                        </h2>
                        <div className='login_layer_is_btnlist'>
                            <span onClick={(e) => this.gologinyes()}>是</span>
                            <span onClick={(e) => this.gologinno()}>否</span>
                        </div>
                    </div>
                </div>
                <div className="addlist_banner">
                    <div className="addlist_banner_box">
                        <div className={this.props.thistypenums == 6 ? "addlist_banner_li active addheader_icon1" : "addlist_banner_li addheader_icon1"} onClick={() => this.props.changeSelectthi(6)}>
                            产品中心<span className="addlist_banner_li_icon"></span>
                        </div>
                        <div className={this.props.thistypenums == 7 ? "addlist_banner_li active addheader_icon2" : "addlist_banner_li addheader_icon2"} onClick={() => this.props.changeSelectthi(7)}>
                            疾病中心<span className="addlist_banner_li_icon"></span>
                        </div>
                        <div className={this.props.thistypenums == 8 ? "addlist_banner_li active addheader_icon3" : "addlist_banner_li addheader_icon3"} onClick={() => this.props.changeSelectthi(8)}>
                            客户中心<span className="addlist_banner_li_icon"></span>
                        </div>
                        <div className={this.props.thistypenums == 9 ? "addlist_banner_li active addheader_icon4" : "addlist_banner_li addheader_icon4"} onClick={() => this.props.changeSelectthi(9)}>
                            科研中心<span className="addlist_banner_li_icon"></span>
                        </div>
                    </div>
                </div>
                {masklayer}
                <div className="wrap">
                    <ul className="fl home-logo">
                        <li>
                            <img src={require('../../static/images/logo.png')} alt="" />
                        </li>
                    </ul>
                    <ul className="fl navlist">
                        <li className={this.props.active === '0' ? 'active' : ''}>
                            <Link onClick={() => this.change_twonav('', '首页')} to="/home">首页</Link>
                        </li>
                        <li className={this.props.active === '1' ? 'active none' : 'none'}>
                            <Link
                                onClick={() => this.change_twonav('series', '课程中心')}
                                to="/homeback">
                                课程中心
                            </Link>
                            <ul className="navlist-list">
                                <li
                                    className={this.props.active_two === 'series' ? 'active' : null}
                                    onClick={() => this.change_twonav('series', '系列课程')}>
                                    <Link to="/homeback">系列课程</Link></li>
                                {!this.state.thisusertype ? (
                                    <li
                                        className={this.props.active_two === 'review' ? 'active' : null}
                                        onClick={() => this.change_twonav('review', '精彩回顾')}>
                                        <Link to="/homeback">精彩回顾</Link>
                                    </li>
                                ) : (
                                        <li
                                            className={this.props.active_two === 'review' ? 'active' : null}
                                            onClick={() => this.change_twonav('review', '精彩回顾')}>
                                            <Link to="/homeback">精彩回顾</Link>
                                        </li>
                                    )}
                                {!this.state.thisusertype ? (
                                    <li
                                        className={this.props.active_two === 'hotback' ? 'active' : null}
                                        onClick={() => this.change_twonav('hotback', '专家讲堂')}>
                                        <Link to="/homeback">专家讲堂</Link></li>
                                ) : (
                                        <li
                                            className={this.props.active_two === 'hotback' ? 'active' : null}
                                            onClick={() => this.change_twonav('hotback', '专家讲堂')}>
                                            <Link to="/homeback">专家讲堂</Link></li>
                                    )}


                                <li
                                    className={this.props.active_two === 'question' ? 'active' : null}
                                    onClick={() => this.change_twonav('question', '常见问题')}>
                                    <Link to="/homeback">常见问题</Link></li>
                            </ul>
                        </li>
                        <li className={this.props.active === '2' ? 'active' : ''}>
                            <Link
                                onClick={() => this.change_twonav('lasttime', '直播中心')}
                                to="/homeplaying">
                                直播中心
                            </Link>
                            <ul className="navlist-list">
                                <li
                                    className={this.props.active_two === 'lasttime' ? 'active' : null}
                                    onClick={() => this.change_twonav('lasttime', '近期回放')}>
                                    <Link to="/homeplaying">近期回放</Link></li>
                                <li
                                    className={this.props.active_two === 'classfur' ? 'active' : null}
                                    onClick={() => this.change_twonav('classfur', '课程预告')}>
                                    <Link to="/homeplaying">课程预告</Link></li>
                            </ul>
                        </li>
                        <li className={this.props.active === '3' ? 'active' : ''}>
                            <Link
                                onClick={() => this.change_twonav('hotwatch', '文档中心')}
                                to="/homefile">
                                文档中心
                            </Link>
                            <ul className="navlist-list">
                                <li
                                    className={this.props.active_two === 'hotwatch' ? 'active' : null}
                                    onClick={() => this.change_twonav('hotwatch', '最热查看')}>
                                    <Link to="/homefile">最热查看</Link></li>
                                <li
                                    className={this.props.active_two === 'typefile' ? 'active' : null}
                                    onClick={() => this.change_twonav('typefile', '资料分类')}>
                                    <Link to="/homefile">资料分类</Link></li>
                            </ul>
                        </li>
                        {this.state.userid ?
                            (
                                <li className={this.props.active === '4' ? 'active' : ''}>
                                    <Link
                                        onClick={() => this.change_twonav('homeperson', '个人中心')}
                                        to="/homeperson">
                                        个人中心
                            </Link>
                                    <ul className="navlist-list">
                                        <li
                                            className={this.props.active_two === 'watchhistory' ? 'active' : null}
                                            onClick={() => this.change_twonav('watchhistory', '观看历史')}>
                                            <Link to="/watchhistory">观看历史</Link></li>

                                        <li
                                            className={this.props.active_two === 'learnrecord' ? 'active' : null}
                                            onClick={() => this.change_twonav('learnrecord', '学习记录')}>
                                            <Link to="/learnrecord">学习记录</Link></li>

                                        {!this.state.thisusertype ? (
                                            <li
                                                className={this.props.active_two === 'integralmall' ? 'active' : null}
                                                onClick={() => this.change_twonav('integralmall', '积分商城')}>
                                                <Link to="/integralmall">积分商城</Link></li>
                                        ) : null}
                                        {!this.state.thisusertype ? (
                                            <li
                                                className={this.props.active_two === 'certificate' ? 'active' : null}
                                                onClick={() => this.change_twonav('certificate', '我的证书')}>
                                                <Link to="/certificate">我的证书</Link></li>
                                        ) : null}
                                        {!this.state.thisusertype ? (
                                            <li
                                                className={this.props.active_two === 'downrecord' ? 'active' : null}
                                                onClick={() => this.change_twonav('downrecord', '下载记录')}>
                                                <Link to="/downrecord">下载记录</Link></li>
                                        ) : null}
                                        <li
                                            className={this.props.active_two === 'learnplan' ? 'active' : null}
                                            onClick={() => this.change_twonav('learnplan', '学习计划')}>
                                            <Link to="/learnplan">学习计划</Link></li>
                                        {!this.state.thisusertype ? (
                                            <li
                                                className={this.props.active_two === 'surver' ? 'active' : null}
                                                onClick={() => this.change_twonav('surver', '调研')}>
                                                <a href="https://jinshuju.net/f/fl3vd2">调研</a></li>
                                        ) : null}

                                        {this.state.study ? (
                                            <li
                                                className={this.props.active_two === 'learnplanuser' ? 'active' : null}
                                                onClick={() => this.change_twonav('learnplanuser', '学员计划')}>
                                                <Link to="/learningplan">学员计划</Link></li>
                                        ) : null}

                                        {this.state.studyadmin ? (
                                            <li
                                                className={this.props.active_two === 'learnplanadmin' ? 'active' : null}
                                                onClick={() => this.change_twonav('learnplanadmin', '作业批改')}>
                                                <Link to="/learningplanadminuserlist">作业批改</Link></li>
                                        ) : null}

                                        {/* <li
                                    className={this.props.active_two === 'collect' ? 'active' : null}
                                    onClick={() => this.change_twonav('collect')}>
                                    <Link to="/collect">我的收藏</Link></li> */}
                                    </ul>
                                </li>
                            )
                            : null}
                        {/* <li className={this.props.active === '4' ? 'active' : ''}>
                            <Link
                              onClick={() => this.change_twonav('homeperson')}
                              to="/homeperson">
                                  个人中心
                            </Link>
                            <ul className="navlist-list">
                                <li
                                    className={this.props.active_two === 'watchhistory' ? 'active' : null}
                                    onClick={() => this.change_twonav('watchhistory')}>
                                    <Link to="/watchhistory">观看历史</Link></li>
                                <li
                                    className={this.props.active_two === 'learnrecord' ? 'active' : null}
                                    onClick={() => this.change_twonav('learnrecord')}>
                                    <Link to="/learnrecord">学习记录</Link></li>
                                <li
                                    className={this.props.active_two === 'certificate' ? 'active' : null}
                                    onClick={() => this.change_twonav('certificate')}>
                                    <Link to="/certificate">我的证书</Link></li>
                                <li
                                    className={this.props.active_two === 'downrecord' ? 'active' : null}
                                    onClick={() => this.change_twonav('downrecord')}>
                                    <Link to="/downrecord">下载记录</Link></li>
                                <li
                                    className={this.props.active_two === 'learnplan' ? 'active' : null}
                                    onClick={() => this.change_twonav('learnplan')}>
                                    <Link to="/learnplan">学习计划</Link></li>
                                <li
                                    className={this.props.active_two === 'surver' ? 'active' : null}
                                    onClick={() => this.change_twonav('surver')}>
                                    <a href="https://jinshuju.net/f/fl3vd2">调研</a></li>
                                {this.state.study?(
                                    <li
                                        className={this.props.active_two === 'learnplanuser' ? 'active' : null}
                                        onClick={() => this.change_twonav('learnplanuser')}>
                                        <Link to="/learningplan">学员计划</Link></li>
                                ):null}

                                {this.state.studyadmin ? (
                                    <li
                                        className={this.props.active_two === 'learnplanadmin' ? 'active' : null}
                                        onClick={() => this.change_twonav('learnplanadmin')}>
                                        <Link to="/learningplanadminuserlist">作业批改</Link></li>
                                ) : null} */}

                        {/* <li
                                    className={this.props.active_two === 'collect' ? 'active' : null}
                                    onClick={() => this.change_twonav('collect')}>
                                    <Link to="/collect">我的收藏</Link></li> */}
                        {/* </ul>
                        </li> */}
                    </ul>
                    <div className="search-wrap fl">
                        <div className="search-input">
                            <input
                                id="search"
                                placeholder="搜索课程"
                                defaultValue={this.props.common_search}
                                onChange={this.searchchange}
                                onKeyPress={(e) => this.searchCollback(this.state.searchText, e)} />
                            <span
                                className="search-btn cursor"
                                onClick={() => this.searchCollback(this.state.searchText)}></span>
                        </div>
                    </div>
                    <div
                        className={['labelicon cursor fl', this.state.showfalg ? 'active' : null].join(' ')}
                        onClick={this.showlabel}></div>
                    <div className="personal fr" onClick={(e) => this.gologin()}>
                        <img src={require('../../static/images/personalicon.png')} alt="" />
                        <span className="usernames">{this.props.userinfo.info.name}</span>
                        <span className='addusershen moresamll'>{localStorage.getItem('userid') != '' && localStorage.getItem('userid') > 0 ? "已登录" : "注册/登录"}</span>
                    </div>
                </div>
                {label}

            </header>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        labellist: state.common_labellist,
        active_two: state.active_two,
        common_search: state.common_search,
        userinfo: state.userinfo
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        change_active(data) {
            dispatch(change_active(data))
        },
        change_labellist(data) {
            dispatch(change_labellist(data))
        },
        change_userinfo(data) {
            dispatch(change_userinfo(data))
        },
        change_active_two(data) {
            dispatch(change_active_two(data))
        },
        change_common_search(data) {
            dispatch(change_common_search(data))
        },
        change_common_label() {
            dispatch(change_common_label())
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Headerfir))
