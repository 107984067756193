import React, { Component } from 'react'
import $ from 'jquery'
// 组件
import Header from '../../components/header'
import Footer from '../../components/footer'
import Banner from '../../components/banner'
import LayoutClass from '../../components/layoutclass'
import LayoutClassmore from '../../components/layoutclassmore'
import ClassBox from '../../components/classbox'
import CLassLabel from '../../components/classlabel'
import ClassFour from '../../components/classfour'

import MaskBox from '../../components/maskbox'
import './index.css'

class Homelist extends Component {
  constructor() {
    super();
    this.closeMaskuserstate = this.closeMaskuserstate.bind(this);
    this.gologin = this.gologin.bind(this);
    this.goreg = this.goreg.bind(this);

    this.state = {
      xilies: [],
      thisuserstateadd:false,
      isouter:true,
      coursetypelist:['','产品中心','疾病中心','客户中心','科研中心'],
    }
  }
  getuserrole = ()=>{
    let that =this
    $.ajax({
      type: "GET",
      url: "https://shl-info.cn/front/Smartremember/my_role?userid=" +
        localStorage.getItem('userid') ,
      dataType: "json",
      headers:{'token':localStorage.getItem('token')},
      success: function (res) {
        console.log(res)
        if(res.data && res.data.user_type ==1){
          that.setState({
            isouter:false,
          })
         
        }
      }
    });

    
  }
  goreg (){
    console.log(1111)
    this.props.history.push('/loginlist?reg=1');
  }
  gologin (){
    this.props.history.push('/loginlist');
    console.log(2222)
  }
  closeMaskuserstate() {

    //this.props.history.push('/loginlist');
    localStorage.setItem('isclose',1);
    this.setState({
      thisuserstateadd: false
    })
  }
  
  getUrlkey(url) {
    var params = {};
    var urls = url.split("?");                  ////console.log('1_分割url:', urls)
    var arr = urls[1].split("&");               ////console.log('2_分割urls[1]:', arr)
    for (var i = 0, l = arr.length; i < l; i++) {
      var a = arr[i].split("=");                ////console.log('3_遍历 arr 并分割后赋值给a:', a[0], a[1])
      params[a[0]] = a[1];                      ////console.log('4_a给params对象赋值:', params)
    }                                           ////console.log('5_结果:', params)
    return params;
  }
  
  componentDidMount() {
    console.log(12312312)

    if (window.location.href.indexOf('mailcode') != -1) {
      var urls = this.getUrlkey(window.location.href);
      let that=this
      setTimeout(function(){
        that.props.history.push('/loginlist?mailcode='+urls.mailcode);
      },1000)
      return false;
    }


    console.log(localStorage.getItem('userid'))
    console.log("localStorage.getItem('userid')")
    if((!localStorage.getItem('userid') ||localStorage.getItem('userid')=='' || localStorage.getItem('userid')=='null' || localStorage.getItem('userid')<=0 ) && localStorage.getItem('isclose') !=1){
      console.log(1111)
      this.setState({
        thisuserstateadd:true
      })
    }else{
      console.log(2222)
      
    }
    this.props.change_home_data()

    let userid = (localStorage.getItem('userid'));
    let token = (localStorage.getItem('token'));
    let changetype = localStorage.getItem('changetype')
    let that = this;
    if(userid && token){
      this.getuserrole();
    }
    // $.ajax({
    //   type: "GET",
    //   url:
    //     "https://shl-info.cn/front/frontc/gethomepagelist?userid=" +
    //     userid +
    //     "&changetype=" +
    //     changetype,
    //   dataType: "json",
    //   headers:{'token':token},
    //   cache: false,
    //   success: function (res) {
    //     console.log(res.xilies)
    //     if (res && res.status !== 2) {
    //       that.setState({
    //         xilies: res.xilies,
    //       })
    //     }
    //   }
    // });
    
  }
  componentWillUnmount() {

  }
  goai = () =>{
    this.props.history.push('/ai')
  }

  render() {
    // 课程中心
    let classlist = null;
    console.log(this.props.classlist)
    console.log(this.props.infolist)
    console.log("this.props.infolist")
    console.log(this.props.infolist)
    let newclasslist = [];
    if (this.props.classlist) {
      classlist = this.props.classlist.map((item, index) => {
        if (index <= 2) {
          return (
            <CLassLabel
              key={'class' + item.id}
              _item={item}></CLassLabel>
          )
        }
      })
    }
    console.log(classlist)
    // 直播中心
    let playlist = null;
    if (this.props.playlist) {
      playlist = this.props.playlist.map((item) => {
        return (
          <CLassLabel
            key={'label' + item.id}
            _item={item}></CLassLabel>
        )
      })
    }
    // 第二个课程中心
    let classfour = null;
    if (this.props.classfour) {
      classfour = this.props.classfour.map((item) => {
        return (
          <ClassFour
            key={'four' + item.id}
            _item={item}></ClassFour>
        )
      })
    }



    let maskbox = null;
    if (this.state.thisuserstateadd) {
      maskbox = (
        <MaskBox
          info={{
            title: '',
            //text: "抱歉，您目前还没有注册/登录，\n请点击右上角“切换身份”注册/登录"
            text: "抱歉，您目前还没有注册/登录，\n请您点击前往注册/登录",
            goglinsstate:true,
          }}
          goreg= {this.goreg}
          gologin= {this.gologin}
          closeMask={this.closeMaskuserstate}>
        </MaskBox>
      )
    }



    // 推荐课程
    let recommend = null;
    if (this.props.recommend) {
      recommend = this.props.recommend.map((item) => {
        return (
          <ClassBox
            key={'recomm' + item.id}
            _item={item}></ClassBox>
        )
      })
    }
    return (
      <div className='homepage'>
        {maskbox}
        <Header
          ishome={true}
          active='0'></Header>
        <Banner bannerlist={this.props.bannerlist}></Banner>
        {/* {this.state.isouter?null:<div className="showaiicon" onClick={()=>this.goai()}><img src={require('../../static/images/ai/aigif.gif')} />
          <div className='showtips'></div>
        </div>} */}
        
        <LayoutClass
          key='直播中心'
          title='直播中心'
          morebtn={true}
          istitle={true}
          iszhibo={true}
          morehref='/homeplaying'
          list={playlist}></LayoutClass>



        {this.props.infolist.length>0?
          this.props.infolist.map((item,index)=>{
            console.log(item)
            console.log(item.xilies)
            console.log("item.xilies")
            let names='';
            if(item.types>=1 && item.types<=4){
              names = this.state.coursetypelist[item.types];
            }
            if(item.xilies){
              if(item.xilies.length>0){
                return <LayoutClassmore
                key={names}
                title={names}
                morebtn={true}
                morehref='/homeback'
                list={item.xilies}></LayoutClassmore>
              }
            }
          })
        :null}
        {/* <LayoutClassmore
          key='课程中心'
          title='课程中心'
          morebtn={true}
          morehref='/homeback'
          list={this.props.infolist}></LayoutClassmore> */}
        <LayoutClass
          key='文档中心'
          title='文档中心'
          morebtn={true}
          istitle={true}
          isdoc={true}
          morehref='/homefile'
          list={classfour}></LayoutClass>
        {recommend?<LayoutClass
          key='推荐课程'
          title='推荐课程'
          swiper={true}
          list={recommend}></LayoutClass>:null}
        <Footer></Footer>
      </div>
    )
  }
}

export default Homelist
