import React, { Component } from 'react';
import $ from 'jquery'
import './index.css';
import Maskbox from '../maskbox';
import MaskboxSurvery from '../../components/maskboxsurvery';

class Onlinexam extends Component {
  constructor(props) {
    super(props);
    this.closeMask = this.closeMask.bind(this);
    this.closeMasksurver = this.closeMasksurver.bind(this);
    this.state = {
      masktext: "提交成功",
      submitmask: false,
      overmin: 90,
      timenums: '',
      timeoverstate: false,
      mastboxsurverystate: false,
      mastboxsurverynumstext: '已答题：20   未答题：30',
    };
  }
  changeanswer(type, parent, index, e) {


    let new_arr = this.props.examlist;
    if (type === "1") {
      new_arr[parent]['errorstate'] = false;
      new_arr[parent].answer.forEach(item => {
        if (item.id === index) {
          item.active = true;
        } else {
          item.active = false;
        }
      });
    } else {
      new_arr[parent].answer[index].active = !new_arr[parent].answer[index]
        .active;
    }
    if (!new_arr[parent].active) {
      new_arr[parent].active = true;
    }
    this.props.change(new_arr);
  }
  changeerrorstate() {
    // let new_arr = this.props.examlist;

    // this.props.change(new_arr);
  }


  changesurverymask = () => {
    console.log(2222222)
    this.setState({
      mastboxsurverystate: false,
    });
  }
  subsurvery = () => {
    this.submitexam_over()
  }
  submitexam() {
    let examComplete = [];
    let start = 0;
    let thisnums = 0;
    let answernum = 0;
    let noanswernum = 0;
    let hasanswer = 0;

    let new_arr = this.props.examlist;
    this.props.examlist.forEach(item => {

      let answerstate = false;
      for (let i = 0; i < item.answer.length; i++) {
        if (item.answer[i].active && !examComplete[start]) {
          examComplete[start] = { id: item.index, answer: [] };
          examComplete[start].answer.push(item.answer[i].title);

          start += 1;
          answernum += 1;
          answerstate = true;
        } else if (item.answer[i].active && examComplete[start]) {
          examComplete[start].answer.push(item.answer[i].title);

          start += 1;
          answernum += 1;
          answerstate = true;
        } else {


        }
      }
      if (answerstate) {
        hasanswer += 1;
      }
      if (!answerstate) {
        new_arr[thisnums]['errorstate'] = true;
      }

      thisnums += 1;
    });



    this.props.change(new_arr);
    noanswernum = this.props.examlist.length - hasanswer;
    console.log(this.props.examlist)
    console.log(this.props.examlist.length)
    console.log(examComplete.length)
    console.log(examComplete)
    console.log(answernum)
    console.log(noanswernum)
    console.log(new_arr)
    // return;

    this.setState({
      mastboxsurverystate: true,
      mastboxsurverynumstext: '已答题：' + hasanswer + '   未答题：' + noanswernum,
    })
  }
  submitexam_over() {
    // 检查是否答完所有题目
    let examComplete = [];
    let start = 0;
    
    let answlist = [];

    this.props.examlist.forEach(item => {
      for (let i = 0; i < item.answer.length; i++) {
        if (item.answer[i].active && !examComplete[start]) {
          examComplete[start] = { id: item.index, answer: [] };
          examComplete[start].answer.push(item.answer[i].title);
          // if(!answlist.includes(item.index)){
          //   answlist.push(item.index)
          // }
        } else if (item.answer[i].active && examComplete[start]) {
          examComplete[start].answer.push(item.answer[i].title);
          // if(!answlist.includes(item.index)){
          //   answlist.push(item.index)
          // }
        }
      }
      start += 1;
    });
    console.log(examComplete)
    console.log(examComplete.length)
    if (examComplete.length <= 0) {
      return;
    }
    console.log(examComplete)
    this.setState({
      mastboxsurverystate: false,
    })

    $.ajax({
      type: "POST",
      url:
        "https://shl-info.cn/front/frontc/savesurverdi?userid=" +
        this.props.userinfo.userid +
        "&courseid=" +
        this.props.classid +
        "&types=" +
        this.props.itemselect.types,
      data: JSON.stringify({
        data: examComplete
      }),
      dataType: "json",
      headers: { 'token': this.props.userinfo.token },
      cache: false
    }).then(res => {
      console.log(123123123)
      console.log(this.props.history)
      if (res && res.status !== 2) {
        if (res.ispass == 0) {
          this.setState({
            submitmask: true,
            masktext: "您的考试成绩为：" + res.overnums + "分，很遗憾未通过考试！",
          })
        } else if (res.ispass == 1) {
          this.props.history.push("/certificatedata?type=" + res.ids + "&di=1");
        }
        //this.props.gotoresult();
      }
    });
  }

  again() {
    if (this.state.usercount >= 80 && this.props.classid != 152) {
      this.props.gotocertificate();
      //this.props.history.push("/Examresults" );
    } else if (this.state.usercount >= 100 && this.props.classid == 152) {
      this.props.gotocertificate();
      //this.props.history.push("/Examresults" );
    } else {
      this.setState({
        islayer: true,
      })
    }
  }
  closeMask() {
    window.location.href = window.location.href
    // this.setState({
    //   submitmask: false
    // });
  }
  closeMasksurver() {
    this.setState({
      mastboxsurverystate: false
    });
  }





  intervalTime = (startTime, endTime) => {
    var date1 = new Date();  //开始时间
    var date2 = endTime;    //结束时间
    var date3 = new Date(date2).getTime() - date1.getTime();   //时间差的毫秒数      
    //------------------------------
    //计算出相差天数
    var days = Math.floor(date3 / (24 * 3600 * 1000))

    //计算出小时数
    var leave1 = date3 % (24 * 3600 * 1000)    //计算天数后剩余的毫秒数
    var hours = Math.floor(leave1 / (3600 * 1000))
    //计算相差分钟数
    var leave2 = leave1 % (3600 * 1000)        //计算小时数后剩余的毫秒数
    var minutes = Math.floor(leave2 / (60 * 1000))
    //计算相差秒数
    var leave3 = leave2 % (60 * 1000)      //计算分钟数后剩余的毫秒数
    var seconds = Math.round(leave3 / 1000)
    //console.log()
    return [days, hours, minutes, seconds]
    //days + "天 " + hours + "小时 " + minutes + " 分钟" + seconds + " 秒"
    // return   days + "天 " + hours + "小时 "
  }
  addtimes = () => {

    let thistime = this.intervalTime(111, this.props.itemselect.endtime);
    //console.log(thistime)
    let thisnum = thistime[0] + "天 " + thistime[1] + "小时 " + thistime[2] + "分钟 " + thistime[3] + "秒"
    this.setState({
      timenums: thisnum
    })
    if (thistime[0] <= 0 && thistime[1] <= 0 && thistime[2] <= 10) {
      this.setState({
        timeoverstate: true
      })
    }
    if (thistime[0] <= 0 && thistime[1] <= 0 && thistime[2] <= 0 && thistime[3] <= 0) {
      //提交所有问卷
      // this.submitexam();
      this.submitexam_over();
      return false;
    }
    let that = this
    setTimeout(function () {
      that.addtimes();
    }, 1000)
  }
  componentDidMount() {
    console.log(this.props.thisids)
    this.addtimes();
    console.log(this.props.classid)
    this.setState({
      usercount: this.props.usercount,
    })
    if (this.props.userqastate == 1) {
      this.setState({
        islayer: false,
      })
    } else {

      this.setState({
        islayer: true,
      })
    }

    this.setState({
      overmin: 11,
    })

  }
  componentWillUnmount() { }
  render() {
    let examlist = this.props.examlist.map((item, index) => {
      let answer = item.answer.map((list, listindex) => (
        <div
          className="exam-li"
          key={listindex}
          onClick={e => this.changeanswer(item.type, index, listindex, e)}
        >
          {list.title}
          <span
            className={
              item.type === "1"
                ? list.active
                  ? "radio active"
                  : "radio"
                : list.active
                  ? "checkout active"
                  : "checkout"
            }
          >
            <span></span>
          </span>
        </div>
      ));
      return (
        <div key={index} className="exam-wrap">
          <h2 className={item.errorstate ? "exam-title errorstate" : "exam-title"}>
            {index + 1}. {item.title}({item.type === "1" ? "单选" : "多选"})
            <span>*请选择</span>
          </h2>
          <div className="exam-list">{answer}</div>
        </div>
      );
    });
    let exammask = null;
    if (this.state.submitmask) {
      exammask = (
        <Maskbox
          info={{
            title: "在线考试",
            text: this.state.masktext
          }}
          closeMask={this.closeMask}
        ></Maskbox>
      );
    }
    var examstate = 1;
    if ((this.props.classid == 919 || this.props.classid == 918 || this.props.classid == 917 || this.props.classid == 841) && this.props.userqastate == 1) {
      examstate = 0;
    }


    let layerexammaskstate = null;
    if (this.state.mastboxsurverystate) {
      layerexammaskstate = (
        <MaskboxSurvery
          info={{
            title: "在线考试",
            text: this.state.mastboxsurverynumstext,
          }}
          subsurvery={() => this.subsurvery()}
          changesurverymask={() => this.changesurverymask()}
          closeMask={() => this.closeMasksurver()}
        ></MaskboxSurvery>
      )
    }
    // console.log(this.props)
    return (
      <div className="exam-scroll">
        <div className='timeoverobx'>
          考试结束时间：{this.props.itemselect.endtime}
          <span className={this.state.timeoverstate ? "exam_space exam_count" : "exam_space"}>剩余时间为：{this.state.timenums}</span>
        </div>
        <div className="wrap">
          <div className="iscroll-padding">{examlist}</div>

          {
            this.props.examlist.length > 0 && examstate == 1 ? (
              <div
                className="exam-submit touchCss"
                onClick={() => this.submitexam()}
              >
                提交
              </div>
            ) : null
          }
          {exammask}
          {layerexammaskstate}
        </div>
        <div className={this.state.islayer ? "exam_layer none" : this.props.changeexamstate ? "exam_layer none" : "exam_layer "}>
          <div class="result-number "><h5><span>{this.state.usercount}</span>分</h5>
            {this.props.classid == 152 ? (
              <p>{this.state.usercount >= 100 ? "恭喜您通过了考试" : "您在考试中没有及格，继续努力！"}</p>
            ) : (
                <p>{this.state.usercount >= 80 ? "恭喜您通过了考试" : "您在考试中没有及格，继续努力！"}</p>
              )}

            <div className="down-footer">
              {examstate == 1 ? (
                <div
                  className="exam-submit touchCss"
                  onClick={() => this.again()}
                >
                  {this.props.classid == 152 ? (
                    <span>{this.state.usercount >= 100 ? "查看证书" : "重新考试"}</span>
                  ) : (
                      <span>{this.state.usercount >= 80 ? "查看证书" : "重新考试"}</span>
                    )}

                </div>
              ) : null}

            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Onlinexam;
