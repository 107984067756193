import React from 'react'

import './index.css'
import { Link } from 'react-router-dom'

function Bbslayer(props) {
    console.log(props)
    return (
        <div className="bbslayer">
            <div className="bbslayer_box">
                <h2 className="common-masktitle">
                登录完成
                    <span
                        className="common-maskclose cursor"
                        onClick={()=>props.closelayer()}
                       ></span>
                </h2>

                <div className='bbslayer_textbox'>
                    {props.HomeTipsinfo.descript?<div className="bbslayer_text">{props.HomeTipsinfo.descript}</div>:null}
                    {props.HomeTipsinfo.banner? <div className="bbslayer_img"><img src={props.HomeTipsinfo.banner} /></div>:null}
                    {/* {props.isbbsinfo.content?<div className="bbslayer_text">{props.isbbsinfo.content}</div>:null}
                    {props.isbbsinfo.imgurl? <div className="bbslayer_img"><img src={props.isbbsinfo.imgurl} /></div>:null} */}

                    {/* 
                    <div className="bbslayer_text">哈哈哈哈</div>
                    <div className="bbslayer_img"><img src={require("../../static/images/good_icon.png")} /></div> */}
                    {props.HomeTipsinfo.isjump ==1?<div className="bbslayer_btnlist">
                        <div className="bbslayer_btn" onClick={()=>props.gojumpdetail()}>点击前往</div>
                        {/* <div className="bbslayer_btn" onClick={()=>props.gotopic()}>点击前往</div> */}
                    </div>:<div className="bbslayer_btnlist">
                        <div className="bbslayer_btn" onClick={()=>props.closelayer()}>确认</div>
                        {/* <div className="bbslayer_btn" onClick={()=>props.gotopic()}>点击前往</div> */}
                    </div>}
                </div>
            </div>
        </div >
    )
}

export default Bbslayer
