import React, { useState, useEffect } from 'react'

import './index.css'

const IndexComponent = (props) => {
  let valueChange = (e) =>{
    console.log(e)
    console.log(e.target.value)
    if(e.keyCode === 13){ //主要区别就是这里，可以直接获取到keyCode的值
      props.gosearch()
      return;
    }
    props.setInputtext(e.target.value)
    props.setSeachstate(false)
    
  }
  // useEffect(() => {
  //   document.addEventListener('keypress',handleEnterKey)
  // }, [])
  let handleEnterKey = (e) => {
    console.log(e)
    console.log(e.keyCode)
      if(e.keyCode === 13){ //主要区别就是这里，可以直接获取到keyCode的值
        props.gosearch()
      }
  }

  return (
    <div className="searchInputView">
      <input type="text" className="searchInput" placeholder="输入内容" 
        onChange={(e)=>valueChange(e)}
        value={props.inputtext}
        onKeyDown={handleEnterKey}
      />
      <img src={require('../../../../static/images/searchicon.png')} alt="" className="searchInputIcon" onClick={props.gosearch} />
    </div>
  )
};

export default IndexComponent;
