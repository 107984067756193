import React from 'react'
import { Link } from 'react-router-dom'

import '../classlist/index.css'

function HistoryList(props) {
    return (
        <div className='classlist transform_hover'>
            <Link to={props._item.link}>
                <div className='classlist-img'>
                    <img src={props._item.src} className='tranform_img' alt="" />

                    <b className="classlist-img-right">{props._item.nums}</b>
                </div>
                <h2 className="classlist-title">{props._item.title}</h2>
                <p className="history-name">讲师<span>{props._item.name}</span></p>
                <p className="history-label">
                    {/* <span className="history-numbers">{props._item.nums}</span> */}
                    <span className="history-time">{props._item.time}</span>
                </p>
            </Link>
        </div>
    )
}

export default HistoryList
