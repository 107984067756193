import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { change_accountlist, change_accountlist_data } from '../../store/action'
import SubAccount from './index.bundle'

const mapStateToProps = (state) => {
  return {
    accountlist: state.person_accountlist,
    userinfo: state.userinfo
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    change_accountlist(data) {
      dispatch(change_accountlist(data))
    },
    change_accountlist_data() {
      dispatch(change_accountlist_data())
    }
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SubAccount)
);
