import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Swiper from 'swiper'

import 'swiper/css/swiper.min.css'
import './index.css'

class Banner extends Component {
    constructor(props) {
        super(props)
        this.state = {
            swiper: [],
            active: 1,
        }
    }
    // 每次有更新需要执行的代码
    componentDidUpdate() {
        if (this.state.swiper) {
            this.state.swiper.update()
        }
    }
    swiperInit() {
        let that = this;
        that.setState({
            swiper: new Swiper('.swiper-container', {
                loop: false,  //循环
                speed: 500,
                resistanceRatio: 0,
                pagination: {
                    el: '.swiper-pagination',
                    clickable :true,
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                autoplay: {   //滑动后继续播放
                    disableOnInteraction: false,
                },
                on: {
                    slideChange() {
                        that.setState({
                            active: this.activeIndex + 1
                        })
                    }
                }
            })
        })
    }
    componentDidMount() {
        this.swiperInit();
    }
    componentWillUnmount() {
        if (this.state.swiper.length > 0) {
            for (var i = 0; i < this.state.swiper.length; i++){
                this.state.swiper[i].destroy();
            }
        } else {
           // this.state.swiper.destroy();
        }
    }

    render() {
        let banner = this.props.bannerlist.map((item,index) => {
            let isplaying = null;
            if (item.active) {
                isplaying = (
                    <span className="bannerStart">
                        正在直播
                        <img src={require('../../static/images/isplaying.png')}  alt="西影力学院" />
                    </span>
                )
            }
            return (
                <div
                    key={index}
                    className="swiper-slide"
                >
                    <Link to={item.link}>
                        <img src={item.srcpic} className="banner-img"  alt={item.title} />
                        {/* <span className="bannerMaks"></span> */}
                        <div className="wrap">
                            <div className="banner-text">
                                <h3>{item.title}</h3>
                                <p><span>{item.name}</span><span>{item.label}</span></p>
                            </div>
                            {isplaying}
                        </div>
                    </Link>
                </div>
            )
        })
        return (
            <div className="swiper-container">
                <div className="swiper-wrapper">
                    {banner}
                </div>
                <div className="swiper-button-prev"></div>
                <div className="swiper-button-next"></div>

                <div className="swiper-pagination"></div>
                <div className="wrap-front">
                    <div className="swiper-nagtive">
                        <span className="nagtive-color">
                            {this.state.active}
                        </span>
                         &nbsp;/&nbsp; 
                    <span>
                            {this.props.bannerlist.length}
                        </span>
                    </div>
                </div>
            </div>
        )
    }
}

export default Banner
