import React, { useState, useEffect } from 'react'
import './index.css'
import classnames from 'classnames';


import MaskBox from '../../../../components/maskbox'



const IndexComponent = (props) => {

  // let [grouplist, setList] = useState([])
  useEffect(() => {
    console.log("useEffect")
    fetch(
      "https://shl-info.cn/front/groupchat/getChatForAddCourse?userid=" +
      localStorage.getItem('userid') +
      "&groupname=" ,{
        headers:{
          'token':localStorage.getItem('token') 
        }
      }
    )
    .then(data => {
      return data.json();
    })
    .then(res => {
      console.log(res)
      if(res.code==2000){
        setList(res.data)
        // this.setState({
        //   grouplist:res.data
        // })
      }
    });
  },[])
  let [list, setList] = useState([
    // {
    //   title: '北京第一群',
    //   subtitle: '北京协和医院',
    //   select: false,
    //   child: [
    //     { title: '抗疫系列公开课' },
    //     { title: 'CT系列公开课' },
    //     { title: 'MR系列公开课' },
    //   ]
    // },
    // {
    //   title: '北京第二群',
    //   subtitle: '北京协和医院',
    //   select: false,
    //   child: [
    //     { title: '抗疫系列公开课' },
    //     { title: 'CT系列公开课' },
    //     { title: 'MR系列公开课' },
    //   ]
    // },
    // {
    //   title: '北京第三群',
    //   subtitle: '北京协和医院',
    //   select: false,
    //   child: [
    //     { title: '抗疫系列公开课' },
    //     { title: 'CT系列公开课' },
    //     { title: 'MR系列公开课' },
    //   ]
    // }
  ])

  let selectItemFunc = (index,item) => {
    console.log(item)
    setChatcode(item.groupcode)
    list[index].select = !list[index].select
    fetch(
      "https://shl-info.cn/front/groupchat/getPackListByGroupId?userid=" +
      localStorage.getItem('userid') +
      "&chatcode="+item.groupcode ,{
        headers:{
          'token':localStorage.getItem('token') 
        }
      }
    )
    .then(data => {
      return data.json();
    })
    .then(res => {
      console.log(res)
      if(res.code==2000){
        list[index].child=res.data;
      }else{
        list[index].child=[];
      }
      setList([...list])
    });
    setList([...list])
  }


  let [chatcode, setChatcode] = useState('')

  let [tipstitle, setTipstitle] = useState('')
  let [tipstext, setTipstext] = useState('')
  let [maskboxstate, setMaskboxstate] = useState(false)
  

  let changethiscode = (item,groupcode)=>{
    console.log(item)
    console.log(item.packcode,chatcode)

    fetch(
      "https://shl-info.cn/front/groupchat/addCourseForPack",{
        method: 'POST',
        body: JSON.stringify({
          "chatcode": groupcode,
          "userid": localStorage.getItem('userid'),
          "courseid": props.classid,
          "packid": item.packcode,
        }),
        headers:{
          'content-type': 'application/json',
          'token':localStorage.getItem('token')
        },
        header: {
          'content-type': 'application/json',
          'token':localStorage.getItem('token')
        },
      }
    )
    .then(data => {
      return data.json();
    })
    .then(res => {
      console.log(res)
      setMaskboxstate(true)
      if(res.code==2000){
        setTipstitle('添加成功');
        setTipstext('课程已经添加到课程包内');
        
      }else{
        setTipstitle('添加失败');
        setTipstext(res.msg);
      }
    })
  }
  
  let groupSelectAlertLi = () => {
    return list.map((item, index) => {
      let groupSelectAlertLiBody='';
      if(item.child){
        let groupSelectAlertLiItem = item.child.map((currentItem, currentIndex) => {
          return (
            <div className="groupSelectAlertLiItem" key={currentIndex} onClick={()=>changethiscode(currentItem,item.groupcode)}>{ currentItem.name }</div>
          )
        })
        groupSelectAlertLiBody = (
          <div className="groupSelectAlertLiBody">
            { groupSelectAlertLiItem }
          </div>
        )
      }
      
      return (
        <div className={classnames("groupSelectAlertLi", item.select && 'active')} key={ index }>
          <div className="groupSelectAlertLiHead" onClick={() => selectItemFunc(index,item)}>
            <div className="groupSelectAlertLiHeadContent">
              <div className="groupSelectAlertLiTitle">{ item.name }</div>
              <div className="groupSelectAlertLiSubTitle">{ item.hospital }</div>
            </div>
          </div>
          { item.select && groupSelectAlertLiBody }
        </div>
      )
    })
  }


  let closeMaskuserstate = () => {
    setMaskboxstate(false)
  }
  let maskbox = null;
    if (maskboxstate) {
      maskbox = (
        <MaskBox
          info={{
            title: tipstitle,
            text: tipstext,
          }}
          closeMask={closeMaskuserstate}>
        </MaskBox>
      )
    }

    let goaddgroup =()=>{
      props.history.push('/groupManipulat?isadd=1');
    }

  return (
    <div className="groupSelect">
      {maskbox}
        <div className="groupSelectAlert">
          <div className="groupSelectAlertHead">
            <div className="groupSelectAlertTitle">选择群组</div>
            <img src={require('../../../../static/images/icon16.png')} alt="" className="groupSelectAlertClose" onClick={props.closelayer} />
          </div>
          <div className="groupSelectAlertBody scrollbar">
            { groupSelectAlertLi() }

            {props.cancreate?<div className="groupSelectAlertButton">
              <img src={require('../../../../static/images/icon18.png')} alt="" className="groupSelectAlertButtonIcon" />
              
             <div className="groupSelectAlertButtonText" onClick={()=>goaddgroup()}>创建新的群组</div>
            </div>:null}
          </div>
        </div>
    </div>
  )
};

export default IndexComponent;
