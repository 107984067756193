import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { change_userinfo,change_active_two } from '../../store/action'
import AddAccount from './index.bundle'

const mapStateToProps = (state) => {
  return {
    emailexg: state.verification.email,
    phoneexg: state.verification.phone,
    userinfo: state.userinfo
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    change_userinfo(data) {
      dispatch(change_userinfo(data))
    },
    change_active_two(data) {
      dispatch(change_active_two(data))
    },
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddAccount)
);
