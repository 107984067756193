import React, { Component } from 'react'
// 组件
import Header from '../../components/header'
import Footer from '../../components/footer'
import PersonImg from '../../components/personimg'
import LayoutClass from '../../components/layoutclass'
import CLassLabel from '../../components/classlabel'
import $ from 'jquery';

import './index.css'

class LearningPlanadmin extends Component {
  constructor() {
    super();

    this.state = {
      active: 0,
      worktext: '',
      iscroll: null,
      imgiscroll: null,
      layerstate: false,
      thistypeidstate: true,
      userworkreply: [],
      piclist: [],
      imglist: [],
      worktextlist: ['', 'HPS作业提交', '', 'PLP作业提交', '沙龙2-1A', '沙龙2-1B', '沙龙2-2', 'S3-1A', 'S3-1B', 'S3-2'],
      worktextlist: ['', 'HPS作业提交', '', 'PLP作业提交', '沙龙2-1A', '沙龙2-1B', '沙龙2-2', 'S3-1A', 'S3-1B', 'S3-2'],
      subtext: null,
      subtextfen: '',
      passbackstate: false,
      newpiclist: [],
      contentlist: {
        subtext: null,
        piclist: [],
      },
      layerstate: false,
    }
  }
  showlayer = () => {
    this.setState({
      layerstate: true,
    })
  }
  closelayer = () => {
    this.setState({
      layerstate: false,
    })
  }

  getUrlkey(url) {
    var params = {};
    var urls = url.split("?");                  //console.log('1_分割url:', urls)
    var arr = urls[1].split("&");               //console.log('2_分割urls[1]:', arr)
    for (var i = 0, l = arr.length; i < l; i++) {
      var a = arr[i].split("=");                //console.log('3_遍历 arr 并分割后赋值给a:', a[0], a[1])
      params[a[0]] = a[1];                      //console.log('4_a给params对象赋值:', params)
    }                                           //console.log('5_结果:', params)
    return params;
  }
  componentDidMount() {
    //this.props.change_person_classlist()
    let userid = (localStorage.getItem('userid'));
    let token = (localStorage.getItem('token'));
    console.log(33333333333)

    var urls = this.getUrlkey(window.location.href);
    console.log(urls)
    this.typeid = urls.typeid;
    this.typeuserid = urls.userid;
    this.workid = urls.workid;
    this.userid = userid;
    this.token = token;
    let that = this
    console.log(urls.typeid)
    console.log(urls.userid)
    fetch(
      "https://shl-info.cn/front/frontc/gettypeiddeatail?userid=" + userid +
      "&workid=" + this.workid,{
        headers:{
          'token':token
        }
      }
    )
      .then(data => {
        return data.json();
      })
      .then(res => {
        console.log(res);
        if (res.status == -1) {
          window.location.href = "playback"
        } else {
          console.log(res.userworkreply.id)
          console.log(res.userwork.piclist)
          if (res.userwork.piclist.length > 0) {
            var newpiclist = [];
            for (var i = 0; i < res.userwork.piclist.length; i++) {
              newpiclist.push(res.userwork.piclist[i]['pic'])
            }
            console.log(newpiclist)
            var images = []
            newpiclist.forEach(el => {
              var image = new Image()
              image.src = el
              image.onload = () => {
                images.push(el)
                this.setState({
                  newpiclist: images,
                })
              }
            })
          }
          this.setState({
            contentlist: res.userwork,
            userworkreply: res.userworkreply,
          })
          if (res.userwork.typeid == 1 || res.userwork.typeid == 4 || res.userwork.typeid == 7) {
            this.setState({
              thistypeidstate: false,
            })
          }
        }

      });

  }


  subworks = () => {
    var text = this.state.subtext;
    var subimg = this.state.imglist;
    var subimglist = '';
    if (subimg.length > 0) {
      for (var i = 0; i < subimg.length; i++) {
        subimglist = subimglist + subimg[i] + ',';
      }
    }
    fetch(
      "https://shl-info.cn/front/frontc/addadminworks?userid=" + this.userid +
      "&workid=" + this.workid +
      "&subtext=" + text +
      "&subimglist=" + subimglist,{
        headers:{
          'token':this.token
        }
      }
    )
      .then(data => {
        return data.json();
      })
      .then(res => {
        if (res.status === 1) {

          //window.wx.closeWindow();

          this.props.history.push("/learningplanadminuserlist?_" + Math.ceil(Math.random() * 10));
          //this.props.history.push("/learningplan");
        }
      });
    console.log(text, subimg)
  }

  subworksfen = () => {
    var text = this.state.subtextfen;
    if (!text || text <= 0) {
      return false;
    }
    fetch(
      "https://shl-info.cn/front/frontc/addadminfen?userid=" + this.userid +
      "&workid=" + this.workid +
      "&subtextfen=" + text,{
        headers:{
          'token':this.token
        }
      }
    )
      .then(data => {
        return data.json();
      })
      .then(res => {
        if (res.status === 1) {
          this.props.history.push("/learningplanadminuserlist?_" + Math.ceil(Math.random() * 10));
        }
      });
  }

  valueChangefen = (e) => {
    console.log(111111111111)
    let num = parseInt(e.target.value);
    console.log(num)
    if (!num) {
      num = '';
    }
    if (num > 100) {
      num = 100;
    }
    // if (num>0){
    this.setState({
      subtextfen: num
    })
    // }
  }

  passback() {
    this.setState({
      passbackstate: true,
    })
    console.log(1231231231)
  }

  componentWillUnmount() {

  }

  valueChange = (e) => {
    console.log(e.target.value)
    this.setState({
      subtext: e.target.value
    })
  }

  imgupload = (e) => {
    console.log(e)
    let that = this
    var imgfile = document.querySelector('#imgFile');//获取input
    var file = imgfile.files[0];
    // var reader = new FileReader();
    // reader.readAsDataURL(file);
    // reader.onload = function (e) {
    //   // 图片base64化
    //   var newUrl = this.result;
    //   that.setState({
    //     newUrl: newUrl
    //   })
    // };
    var formFile = new FormData();

    let userid = (localStorage.getItem('userid'));
    let token = (localStorage.getItem('token'));
    formFile.append("uploadFile", file); //加入文件对象
    $.ajax({
      type: "post",
      url: "https://shl-info.cn/front/frontc/adduserworkspc?userid=" + userid ,
      data: formFile,
      //是否预处理,默认值为true,这里改成false
      processData: false,
      //是否设置内容,默认值为true,这里改成false
      contentType: false,
      headers:{'token':localStorage.getItem('token')},
      success: function (res) {
        //alert('提交成功');
        var backres = JSON.parse(res);
        let arr = that.state.piclist
        let arrimg = that.state.imglist;
        arr.push(backres.pic);
        arrimg.push(parseInt(backres.id))
        console.log(arr)
        // arr.splice(e, 1);
        // arrimg.splice(e, 1);
        imgfile.value = '';
        that.setState({
          piclist: arr,
          imglist: arrimg,
        })
      },
      error: function (data) {
        //alert('提交失败');
      }
    });
  }

  removethisimg = (e) => {
    console.log(e)
    let arr = this.state.piclist
    let arrimg = this.state.imglist
    arr.splice(e, 1);
    arrimg.splice(e, 1);
    this.setState({
      piclist: arr,
      imglist: arrimg,
    })
  }
  render() {
    // 列表

    return (
      <div className='learningplan'>
        <Header active='4'></Header>
        <div className={this.state.passbackstate ? "learingplan_user none" : "learingplan_user  "}>
          <div className="learingplan_user_title borderbottom">{this.state.worktextlist[this.state.contentlist.typeid]}</div>
          <div className="learningplan_content">{this.state.contentlist.subtext ? this.state.contentlist.subtext : ""}</div>
          <div className="learningplan_content_img">
            {
              (this.state.newpiclist == 0)
                ? null
                : this.state.newpiclist.map((item1, index1) => {
                  return (
                    <img src={item1} alt="" />
                  )
                })
            }

            {/* <img src={require("../../static/images/certidetails.png")} alt="" /> */}
          </div>

          <div className={this.state.contentlist.score > 0 ? "btnlist none" : this.state.userworkreply.id > 0 ? "btnlist none" : "btnlist bottom0"}>
            <div className={this.state.thistypeidstate ? "btn_left" : "btn_left none"} onClick={() => this.showlayer()}>通过</div>
            <div className={this.state.thistypeidstate ? "btn_right" : "btn_right "} onClick={() => this.passback()}>反馈</div>
          </div>
        </div>
        <div className={this.state.layerstate ? "admin_layer " : "admin_layer none"}>
          <div className="layer_input_box">
            <div className="layer_input_box_top"><img src={require("../../static/images/layer_close.png")} className="closelayer" onClick={() => this.closelayer()} /></div>
            <div className="layer_userinput">请输入评分：<span><input type="text" value={this.state.subtextfen}
              onChange={(e) => this.valueChangefen(e)} className="core_input" /></span>分</div>
            <div className="subbtn" onClick={() => this.subworksfen()} >提交</div>
          </div>
        </div>
        <div className={this.state.passbackstate ? "admin_textbox" : "admin_textbox none "}>
          <div className="learingplan_user">
            <div className="learingplan_user_title">{this.state.worktextlist[this.typeid]}</div>
            <div className="learingplan_user_box">
              <textarea placeholder="请输入文字"
                value={this.state.subtext}
                onChange={(e) => this.valueChange(e)}></textarea>
            </div>
            <div className="learingplan_user_imglist">
              <ul>
                {
                  (this.state.piclist.length == 0)
                    ? null
                    : this.state.piclist.map((item1, index1) => {
                      return (
                        <li>
                          <img src={item1} alt="" />
                          <span className="closeimg" onClick={() => this.removethisimg(index1)}></span>
                        </li>
                      )
                    })
                }

                {/* <li>
                <img src={require("../../static/images/certificatedataback.jpg")} alt="" />
                <span className="closeimg"></span>
              </li>
              <li>
                <img src={require("../../static/images/certificatedataback.jpg")} alt="" />
                <span className="closeimg"></span>
              </li>
              <li>
                <img src={require("../../static/images/certificatedataback.jpg")} alt="" />
                <span className="closeimg"></span>
              </li>
              <li>
                <img src={require("../../static/images/certificatedataback.jpg")} alt="" />
                <span className="closeimg"></span>
              </li> */}
                {/* <li>
                <img src={this.state.newUrl} alt="" />
                <span className="closeimg"></span>
              </li> */}
                <li className="uploadimg"><input type="file" id="imgFile" name='img' accept=".jpg,.png,.jpeg" onChange={(e) => this.imgupload(e)} /></li>
              </ul>
            </div>
            <div className="btnlist">
              <div className="btn_right" onClick={() => this.subworks()}>提交</div>
            </div>
          </div>

          <div className={this.state.substate ? "layer" : "layer none"}>
            <div className="layer_ts"> 提交中</div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    )
  }
}

export default LearningPlanadmin
