import React from 'react'

import './index.css'
import { Link } from 'react-router-dom'

function Scorelayer(props) {
    console.log(props)
    return (
        <div className="scroelayer-mask ">
            <div className="video-title-layer" >{props.showtext}<br></br>恭喜获得{props.scorenum}积分</div>
        </div >
    )
}

export default Scorelayer
