import React from 'react'

import './index.css'

function ClassFour(props) {
    return (
        <div className="class-four">
            <a target="_blank" rel="noopener noreferrer" href={props._item.link}>
                {/* <p>{props._item.time}</p> */}
                <h2>{props._item.title}</h2>
                <div className="four-rankicon">
                    <span className="four-top">
                        <img src={require('../../static/images/home_four_icon.png')} alt="" />
                        TOP{props._item.index + 1}
                    </span>
                    <span className="four-nums">
                        <img src={require('../../static/images/hotdownIcon_02.png')} alt="" />
                        {props._item.downnum}
                    </span>
                </div>
            </a>
        </div>
    )
}

export default ClassFour
