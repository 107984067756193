import React, { Component } from 'react'
// 组件
import Header from '../../components/header'
import Footer from '../../components/footer'
import PersonImg from '../../components/personimg'
import PersonTitle from '../../components/persontitle'
import HistoryList from '../../components/historylist'
import './index.css'

import {Picker,CascadePicker, Button, Space } from 'antd-mobile'

class Testdemo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible:false,
      value:[],
      basicColumns:[
        [
          { label: '周一', value: 'Mon' },
          { label: '周二', value: 'Tues' },
          { label: '周三', value: 'Wed' },
          { label: '周四', value: 'Thur' },
          { label: '周五', value: 'Fri' },
        ],
        [
          { label: '上午', value: 'am' },
          { label: '下午', value: 'pm' },
        ],
      ],
      baseinfo:[
        {
          label: '公立医疗机构',
          value: '公立医疗机构',
          children: [
            {  label: '三级甲等', value: '三级甲等' },
            {  label: '三级乙等', value: '三级乙等' },
            {  label: '三级丙等', value: '三级丙等' },
            {  label: '二级甲等', value: '二级甲等' },
            {  label: '二级乙等', value: '二级乙等' },
            {  label: '二级丙等', value: '二级丙等' },
            {  label: '一级医院', value: '一级医院' },
            {  label: '未定级', value: '未定级' },
          ],
        },
        {
          label: '非公医疗机构',
          value: '非公医疗机构',
          children: [
            {  label: '三级甲等', value: '三级甲等' },
            {  label: '三级乙等', value: '三级乙等' },
            {  label: '三级丙等', value: '三级丙等' },
            {  label: '二级甲等', value: '二级甲等' },
            {  label: '二级乙等', value: '二级乙等' },
            {  label: '二级丙等', value: '二级丙等' },
            {  label: '一级医院', value: '一级医院' },
            {  label: '未定级', value: '未定级' },
          ],
        },
        {
          label: '医疗机构',
          value: '医疗机构',
          children: [{ label: '医疗机构', value: '医疗机构' }],
        },
        {
          label: '大学院校/研究机构',
          value: '大学院校/研究机构',
          children: [{ label: '大学院校/研究机构', value: '大学院校/研究机构' }],
        },
        {
          label: '经销商/第三方合作机构',
          value: '经销商/第三方合作机构',
          children: [{ label: '经销商/第三方合作机构', value: '经销商/第三方合作机构' }],
        }
      ]
    }
  }
  componentDidMount() {
   
  }
  componentWillUnmount() {
    
  }
  
  
  render() {
    
    return (
      <div className='personpage'>
        12312312312
        <Button onClick={()=>{
          this.setState({
            visible:true,
          })
        }}>测试</Button> 
        <CascadePicker
          options={this.state.baseinfo}
          visible={this.state.visible}
          onClose={() => {
            console.log(1111)
          }}
          onConfirm={(val, extend) => {
            console.log('onConfirm', val, extend.items)
          }}
        />
        {/* <Picker
          columns={this.state.basicColumns}
          visible={this.state.visible}
          onClose={() => {
           console.log(123123)
          }}
          value={this.state.value}
          onConfirm={v => {
            console.log(22222)
          }}
        /> */}
        {/* <Picker
          cascade={true}
          inputclass="iptsize2"
          placeholder="城市"
          data={this.state.basicColumns}
          cols={2}
          // visible={true}
          visible={this.state.visible}
          onDismiss={() => {
            this.setState({
              visible:false
            })
          }}
          onOk={v => {
            console.log(v)
            // this.setState({
            //   hotiltype: department
            // }, () => {
            //   this.haveSubmit();
            // })
            this.setState({
              visible:false,
              hotiltype: v[0],
              hotillevel:v[1],
            }, () => {
            // this.haveSubmit();
            })
          }}
        />  */}
      </div>
    )
  }
}

export default Testdemo
