import React from 'react'

import './index.css'
import { Link } from 'react-router-dom'

function Scorelayer(props) {
    console.log(props)
    return (
        <div className="scorelayer2023">
            <div className="scorelayer2023box center">
                <div className="scorelayer2023box_title">
                    积分获取
                    <div className="scorelayer2023box_title_close" onClick={()=>props.closescorelayer()}></div>
                </div>
                <div className="scorelayernewbox_icon">
                    <div className="iconimg"></div>
                </div>
                <div className="scorelayernewbox_score">
                    <span>+{props.scorelsyerlist.score}</span>积分
                </div>
                <div className="scorelayernewbox_desc">
                    {props.scorelsyerlist.content}
                </div>
            </div>
        </div >
    )
}

export default Scorelayer
