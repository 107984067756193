import React, { Component } from 'react';
import $ from 'jquery'
// 组件
import myCanvas from '../../components/sign/sign';

import './index.css'

class AutoGrapgin extends Component {

  constructor(props) {
    super(props);

    this.initCanvas = this.initCanvas.bind(this);
    this.clearCanvas = this.clearCanvas.bind(this);
    this.getcanvas = this.getcanvas.bind(this);
    this.gotoIndex = this.gotoIndex.bind(this);

    this.state = {
      canvas: null,
      cansure: false
    }
  }
  initCanvas() {
    this.setState({
      canvas: new myCanvas('canvas')
    })
  }
  getcanvas() {
    this.setState({
      cansure: true
    })
  }
  clearCanvas() {
    if (this.state.canvas) {
      this.state.canvas.clear();
      this.setState({
        cansure: false
      })
    }
  }
  gotoIndex() {
    // 跳转首页
    if (this.state.cansure) {
      $.ajax({
        type: "POST",
        url:
          "https://shl-info.cn/front/user/savepic?&userid=" +
          this.props.userinfo.userid ,
        data: this.state.canvas.canvas.toDataURL("images/png", 0.5),
        dataType: "json",
        headers:{'token':this.props.userinfo.token},
        cache: false,
        success: res => {
          if (res && res.status !== 2) {
            this.props.history.push("/home");
          }
        }
      });
    }
  }

  componentDidMount() {
    this.initCanvas();
    // 更新canvas描点位置
    window.onresize = () => {
      if (document.getElementsByClassName('canvasOffset')[0]) {
        let _canvas = this.state.canvas;
        _canvas._left = document.getElementsByClassName('canvasOffset')[0].offsetLeft + 241;
        _canvas._top = document.getElementsByClassName('autograph-contain')[0].offsetTop + 50;
        this.setState({
          canvas: _canvas
        })
      }
    }
  }
  componentWillUnmount() {

  }

  render() {
    return (
      <div className="autographpage">
        <div className="wrap canvasOffset">
          <div className="autograph-contain center">
            <div className="canvasTips">请签署您的名字确认已知晓和接受“西门子医疗在线培训平台”隐私声明及保密协议！</div>
            <div className="auto-signBox" onMouseDown={this.getcanvas}>
              <canvas id="canvas"></canvas>
            </div>
            <div className="auto-btnlist">
              <div
                className="auto-btn auto-clear fl cursor"
                onClick={this.clearCanvas}>
                重置
              </div>
              <div
                className={['auto-btn auto-sure fr cursor',
                  this.state.cansure ?
                    ' active' :
                    ''].join(' ')}
                onClick={this.gotoIndex}
              >
                确认
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default AutoGrapgin
