import React, { useState, useEffect } from 'react'

import './index.css'

const IndexComponent = (props) => {
  console.log(props.item)
  return (
    <div className="courseItem">
     {props.canstate?<div className="delcoursebypick" onClick={(e)=>props.delcourser(e,props.item)}>删除</div>:null} 
      <img src={props.item?props.item.headimg:""} alt="" className="courseItemBanner" />
      <div className="courseItemContent">
        <div className={props.isseach || props.canstate?"courseItemTitle twoLineDisplay groupinfo":"courseItemTitle twoLineDisplay"}>{props.item?props.item.coursetitle:""}</div>
        <div className="courseItemLabels">
         {props.item?props.item.teacher? <div className="courseItemLabelItem groupinfo">
            <img src={require('../../../../static/images/icon1.png')} alt="" className="courseItemLabelItemIcon" />
            <div className="courseItemLabelItemText groupinfo">{props.item?props.item.teacher:""}</div>
          </div>:"":""}
          {props.isseach?"":props.ischat?null:<div className={props.item? (props.item.havesurver==0&&props.item.islookover==1) ||  (props.item.havesurver==1&&props.item.islookover==1&&props.item.issurverover==1)?"courseItemLabelItem nomargin overname":"courseItemLabelItem nomargin":"courseItemLabelItem nomargin"}>
           <div className="courseItemLabelItemText groupinfo">{props.item? (props.item.havesurver==0&&props.item.islookover==1) ||  (props.item.havesurver==1&&props.item.islookover==1&&props.item.issurverover==1)?<span className='overname'>已完成</span> :"未完成":"未完成"}</div>
          </div>}
          {/* <div className="courseItemLabelItem">
            <img src={require('../../../../static/images/icon2.png')} alt="" className="courseItemLabelItemIcon" />
            <div className="courseItemLabelItemText">1个月15天</div>
          </div> */}
          {/* <div className="courseItemLabelItem">
            <img src={require('../../../../static/images/icon3.png')} alt="" className="courseItemLabelItemIcon" />
            <div className="courseItemLabelItemText">365</div>
          </div> */}
        </div>
        { props.children }
      </div>
    </div>
  )
};

export default IndexComponent;
