import React, { Component } from 'react';
import $ from 'jquery'
import './index.css';
import Maskbox from '../maskbox';

class Onlinebpmexam extends Component {
  constructor(props) {
    super(props);
    this.closeMask = this.closeMask.bind(this);
    this.valueChange = this.valueChange.bind(this);
    this.state = {
      masktext: "提交成功",
      submitmask: false
    };
  }
  changeanswer(type, parent, index, e) {
    if (!this.props.lookstate) {
      this.setState({
        submitmask: true,
        masktext: "请先观看视频后再答题",
      })
      return;
    }


    let new_arr = this.props.examlist;
    if (type == 1) {
      new_arr[parent].answer.forEach(item => {
        if (item.id === index) {
          item.active = true;
        } else {
          item.active = false;
        }
      });
    } else {
      new_arr[parent].answer[index].active = !new_arr[parent].answer[index]
        .active;
    }
    if (!new_arr[parent].active) {
      new_arr[parent].active = true;
    }
    this.props.change(new_arr);
  }
  submitexam() {
    // 检查是否答完所有题目
    let that = this
    let examComplete = [];
    let start = 0;
    this.props.examlist.forEach(item => {

      let state = false;
      for (let i = 0; i < item.answer.length; i++) {
        if (item.answer[i].active && !examComplete[start]) {
          examComplete[start] = { id: item.id, type: item.type, answer: [] };
          examComplete[start].answer.push(item.answer[i].title);
          state = true
        } else if (item.answer[i].active && examComplete[start]) {
          examComplete[start].answer.push(item.answer[i].title);
          state = true
        }
      }
      if (item.type == 3) {
        console.log(item)
        if (item.subtext) {
          state = true
          examComplete[start] = { id: item.id, type: item.type, answer: [] };
          examComplete[start].answer.push(item.subtext);
        }
      }
      if (state) {
        start += 1;
      }
    });
    // console.log(examComplete.length ,this.props.examlist.length)
    // console.log(examComplete);
    //return false;
    if (examComplete.length === this.props.examlist.length) {


      $.ajax({
        type: "POST",
        url:
          "https://shl-info.cn/front/Questionnaire/saveQuestionnaireInfo?userid=" +
          this.props.userinfo.userid +
          "&surverid=" +
          this.props.examlist[0]['surverid'],
        data: JSON.stringify({
          data: examComplete
        }),
        dataType: "json",

        headers: { 'token': this.props.userinfo.token },
        cache: false
      }).then(res => {
        // if (res && res.status !== 2) {
        //   this.props.gotoresult();
        // }
        that.setState({
          islayer: true
        })
        setTimeout(function () {
          that.setState({
            islayer: false
          })
          that.props.gotoresult();
        }, 5000)

      });
    } else {
      this.setState({
        masktext: "请回答所有问题",
        submitmask: true
      });
    }
  }

  again() {
    if (this.state.usercount >= 80 && this.props.classid != 152) {
      this.props.gotocertificate();
      //this.props.history.push("/Examresults" );
    } else if (this.state.usercount >= 100 && this.props.classid == 152) {
      this.props.gotocertificate();
      //this.props.history.push("/Examresults" );
    } else {
      this.setState({
        islayer: true,
      })
    }
  }
  closeMask() {
    this.setState({
      submitmask: false
    });
  }
  componentDidMount() {

    console.log(this.props.classid)
    console.log(this.props)
    console.log(366)
    this.setState({
      usercount: this.props.usercount,
    })
    this.setState({
      islayer: false,
    })

  }

  valueChange(e) {
    //console.log(e)
    console.log(e.target.name)
    console.log(e.target.value)

    let new_arr = this.props.examlist;
    new_arr[e.target.name]['subtext'] = e.target.value
    this.props.change(new_arr);
    console.log(new_arr)
    // this.setState({
    //   [e.target.name]: e.target.value
    // }, () => {
    //   this.haveSubmit();
    // })
  }
  componentWillUnmount() { }
  render() {
    let examlist = this.props.examlist.map((item, index) => {
      console.log(item)
      let answer = item.answer.map((list, listindex) => (
        <div
          className="exam-li"
          key={listindex}
          onClick={e => this.changeanswer(item.type, index, listindex, e)}
        >
          {list.title}
          <span
            className={
              item.type == 1
                ? list.active
                  ? "radio active"
                  : "radio"
                : list.active
                  ? "checkout active"
                  : "checkout"
            }
          >
            <span></span>
          </span>
        </div>
      ));
      if (item.type == 3) {
        answer = <div className='userinputbox'>
          <textarea name={index} onChange={this.valueChange}></textarea>
        </div>
      }

      return (
        <div key={index} className="exam-wrap">
          <h2 className="exam-title">
            {index + 1}. {item.title}({item.type == 1 ? "单选" : item.type == 2 ? "多选" : "填空"})
          </h2>
          <div className="exam-list">{answer}</div>
        </div>
      );
    });
    let exammask = null;
    if (this.state.submitmask) {
      exammask = (
        <Maskbox
          info={{
            title: "在线考试",
            text: this.state.masktext
          }}
          closeMask={this.closeMask}
        ></Maskbox>
      );
    }
    var examstate = 1;
    // if ((this.props.classid == 919 || this.props.classid == 918 || this.props.classid == 917 || this.props.classid == 841) && this.props.userqastate == 1) {
    //   examstate = 0;
    // }
    console.log(this.props)
    return (
      <div className="exam-scroll">
        <div className={this.state.islayer ? "none" : "wrap"}>
          <div className="iscroll-padding">{examlist}</div>

          {
            this.props.examlist.length > 0 && examstate == 1 ? (
              <div
                className="exam-submit touchCss"
                onClick={() => this.submitexam()}
              >
                提交
              </div>
            ) : null
          }
          {exammask}
        </div>
        <div className={this.state.islayer ? "exam_layerbox " : "none "}>
          <div className="textbox">感谢您的参与，5秒后将回到上级菜单</div>
        </div>
      </div>
    );
  }
}

export default Onlinebpmexam;
