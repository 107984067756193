import React, { Component } from 'react'
// 组件
import Header from '../../components/header'
import Footer from '../../components/footer'
import PersonImg from '../../components/personimg'
import LayoutClass from '../../components/layoutclass'
import CLassLabel from '../../components/classlabel'

import './index.css'

class LearningPlanuserlook extends Component {
  constructor() {
    super();

    this.state = {
      active: 0,
      iscroll: null,
      layerstate: false,
      piclist: [],
      imglist: [],
      subtext: null,
      subtextfen: null,
      passbackstate: false,
      //worktextlist: ['', '沙龙1-1A', '沙龙1-1B', '沙龙1-2', '沙龙2-1A', '沙龙2-1B', '沙龙2-2', '沙龙3-1A', '沙龙3-1B', '沙龙3-2'],
      
      worktextlist: ['', 'HPS作业提交', '', 'PLP作业提交', '沙龙2-1A', '沙龙2-1B', '沙龙2-2', '沙龙3-1A', '沙龙3-1B', '沙龙3-2'],
      contentlist: {
        subtext: null,
        piclist: [],
      },
    }
  }

  getUrlkey(url) {
    var params = {};
    var urls = url.split("?");                  //console.log('1_分割url:', urls)
    var arr = urls[1].split("&");               //console.log('2_分割urls[1]:', arr)
    for (var i = 0, l = arr.length; i < l; i++) {
      var a = arr[i].split("=");                //console.log('3_遍历 arr 并分割后赋值给a:', a[0], a[1])
      params[a[0]] = a[1];                      //console.log('4_a给params对象赋值:', params)
    }                                           //console.log('5_结果:', params)
    return params;
  }

  componentDidMount() {
    this.props.change_person_classlist()
    let userid = (localStorage.getItem('userid'));
    let token = (localStorage.getItem('token'));
    console.log(33333333333)

    var urls = this.getUrlkey(window.location.href);
    console.log(urls)
    this.typeid = urls.typeid;
    this.typeuserid = urls.userid;
    this.workid = urls.workid;
    let that = this
    console.log(urls.typeid)
    console.log(urls.userid)
    fetch(
      "https://shl-info.cn/front/frontc/getuserworks?userid=" + userid ,{
        headers:{
          'token':token
        }
      }
    )
      .then(data => {
        return data.json();
      })
      .then(res => {
        console.log(res);
        if (res.userworks.length > 0) {
          console.log(222222222)
          for (var i = 0; i < res.userworks.length; i++) {
            console.log(res.userworks[i].typeid)
            console.log(this.typeid)
            if (res.userworks[i].typeid == this.typeid) {
              console.log(1111111)
              that.setState({
                contentlist: res.userworks[i],
              })
            }
          }
        }
      });

  }
  componentWillUnmount() {

  }
  render() {
    // 列表

    return (
      <div className='learningplan'>
        <Header active='4'></Header>
        <div className="learingplan_user">
          <div className="learingplan_user_title borderbottom">{this.state.worktextlist[this.state.contentlist.typeid]}</div>
          <div className="learningplan_content">{this.state.contentlist.subtext}</div>
          <div className="learningplan_content_img">
            {
              (this.state.contentlist.piclist == 0)
                ? null
                : this.state.contentlist.piclist.map((item1, index1) => {
                  return (
                    <div className=""><img src={item1.pic}></img></div>
                  )
                })
            }
            {/* <img src={require("../../static/images/certidetails.png")} alt="" />
            <img src={require("../../static/images/certificatedataback.jpg")} alt="" /> */}
          </div>
        </div>
        <Footer></Footer>
      </div>
    )
  }
}

export default LearningPlanuserlook
