import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { change_person_learnplan, change_customized, change_active_two,
  change_reviewClassArr,
  change_active_series,
  change_developments_data,
  change_back_data} from '../../store/action'
import LearnPlan from './index.bundle'

const mapStateToProps = (state) => {
  return {
    active_series: state.active_series,
    customized: state.person_customized,
    pointclass: state.person_pointclass,
    userinfo: state.userinfo,
    reviewClassArr: state.back_reviewClassArr,
    developments: state.developments
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeActive(data) {
      console.log(data)
      dispatch(change_reviewClassArr(data))
    },
    change_person_learnplan() {
      dispatch(change_person_learnplan())
    },
    change_customized(data){
      dispatch(change_customized(data))
    },
    change_active_series(data) {
      console.log(data)
      dispatch(change_active_series(data))
    },
    change_active_two(data) {
      dispatch(change_active_two(data))
    },
    change_back_data(data) {
      //console.log(data)
      dispatch(change_back_data(data))
    },
    // 修改系列课程
    change_reviewClassArr(data) {
      dispatch(change_reviewClassArr(data))
    },
    change_developments_data(id, thistypenums) {
      dispatch(change_developments_data(id, thistypenums))
    },
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LearnPlan)
);
