import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  change_active_two,
  change_play_data } from '../../store/action'
import HomePlaying from './index.bundle'

const mapStateToProps = (state) => {
  return {
    active_two: state.active_two,
    bannerlist: state.play_bannerlist,
    developments: state.play_developments,
    hotclass: state.play_hotclass
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    change_active_two(data) {
      dispatch(change_active_two(data))
    },
    change_play_data() {
      dispatch(change_play_data())
    },
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HomePlaying)
);
