import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import $ from 'jquery'
// 组件
import Header from '../../components/header'
import Footer from '../../components/footer'
import PersonImg from '../../components/personimg'
import PersonTitle from '../../components/persontitle'
import HistoryList from '../../components/historylist'
import SelectBox from '../../components/selectbox'

import ClassList from '../../components/classlist'
import SeriesList from '../../components/serieslist'


import Iscroll from 'iscroll/build/iscroll-probe';


import './index.css'

class LearnPlan extends Component {
  constructor() {
    super()
    this.changeActive = this.changeActive.bind(this)
    this.gotoserieslist = this.gotoserieslist.bind(this)
    this.goexam = this.goexam.bind(this)

    this.state = {
      active: '0',
      iscroll:null,
      seriesText:'',
      thisid: -1,
      thistypenums:1,
      gotoseries: false,
      selectlist: [
        {
          id: 0,
          type: '0',
          title: '定制课程'
        },
        {
          id: 1,
          type: '1',
          title: '指定课程'
        }
      ],
    }
  }
  static defaultProps = {
    selectlist: [
      {
        id: 0,
        type: '0',
        title: '定制课程'
      },
      {
        id: 1,
        type: '1',
        title: '指定课程'
      }
    ],
    reviewClassArrabc:[
      {
        id: '1',
        type: '1',
        title: '销售一级'
      },
      {
        id: '2',
        type: '2',
        title: '销售2级'
      },
    ]
  }
  

  deletePlan(item,index) {
    let iscs = '';
    console.log(333333)
    console.log(item)
    if(item.id>=100000){
      iscs=1;
    }
    $.ajax({
      type: "GET",
      url:
        "https://shl-info.cn/front/frontc/addlearningplan?userid=" +
        this.props.userinfo.userid +
        "&courseid=" +
        item.coursecode +
        "&type=1" +
        "&iscs="+iscs,
      dataType: "json",
      
      headers:{'token':this.props.userinfo.token},
      cache: false,
      success: res => {
        if (res && res.status !== 2) {
          let new_customized = this.props.customized;
          console.log(new_customized)
          new_customized.splice(index, 1);
          console.log(new_customized)
          this.props.change_customized(new_customized);
        }
      }
    });

  }

  changeSelect = (id) => {
    this.setState({
      active: id
    })
    if(id==2){
      if($('.series_scroll').length>0){
        console.log(222222,$('.series_scroll').length,"$('.series_scroll').length")
        this.setState({
          iscroll: new Iscroll('.series_scroll', {
            mouseWheel: true,
            scrollbars: true,
            scrollX: true,
            scrollY: false,
            scrollbars: false,
          }),
        })
      }
    }else{
      this.setState({
        iscroll:null
      })
    }
  }
  gotoserieslist(id, parent, text) {
    console.log(id, parent, text,this.state.thistypenums)
    console.log(2222)
   
    console.log('id, parent, text',22222)
    window.addreadlogs(parent + '---' + text)
    this.setState({
      seriesText: parent ? (parent + ' > ' + text) : '',
      gotoseries: !this.state.gotoseries
    })
    let that=this
    if(!this.state.iscroll){
      console.log('iscrolllllll')
      console.log('iscrolllllll',$('.series_scroll').length)
      if($('.series_scroll').length>0){
        setTimeout(function(){
          //console.log($('.series_scroll').html())
          // that.setState({
          //   iscroll: new Iscroll('.series_scroll', {
          //     mouseWheel: true,
          //     scrollbars: true,
          //     scrollX: true,
          //     scrollY: false,
          //     scrollbars: false,
          //   }),
          // })
        },3000)
      }
    }
    //this.state.thistypenums
    console.log(id,this.state.thistypenums)
    this.props.change_developments_data(id, this.state.thistypenums)
    if(!id){
      this.props.history.push("/learnplan");
    }else{
      this.props.history.push("/learnplan?reviewid="+id);
      that.setState({
        iscroll: null,
      })
    }
  }
  componentDidMount() {

    if(localStorage.getItem('isdi')==1){
      this.setState({
        selectlist:[{
          id: 0,
          type: '0',
          title: '定制课程'
        },
        {
          id: 1,
          type: '1',
          title: '指定课程'
        },
        {
          id: 2,
          type: '2',
          title: '销售课程'
        }]
      })
      
    }
    this.props.change_active_two('learnplan')
    this.props.change_person_learnplan()

    this.props.change_back_data(1)
    
    
    // console.log(this.props.customized.length)
    // console.log(this.props.pointclass.length)
  }
  componentWillUnmount() {

  }

  
  goexam (ids){
    console.log(ids)
    this.props.history.push("/diexamdetail?id="+ids);
  }

  // changeseries (e,index){
  //   console.log(e,index)
  // }
  

  changeseries(e, id) {
    //console.log(e.target.innerText)
    console.log(e, id)
    if (e.target) {
      window.addreadlogs(e.target.innerText, 1)
    }
    //console.log(id)
    this.setState({
      thisid: -1,
    })
    this.props.change_active_series(id)
  }
  
  changeActive(index) {
    let new_reviewClassArr = this.props.reviewClassArr
    var subkeywords = new_reviewClassArr[this.props.active_series].list[index]['title'];
    window.addreadlogs(subkeywords)
    new_reviewClassArr[this.props.active_series].list[index].active = !new_reviewClassArr[this.props.active_series].list[index].active
    this.props.changeActive(new_reviewClassArr)
  }


  render() {
    // 切换导航
    console.log(this.props.reviewClassArr)
    console.log(this.props.active_series)
    
    console.log(this.props.customized.length)
    console.log(this.props.customized)
    console.log(this.props.pointclass.length)

    let selectlist = this.state.selectlist.map((item) => {
      return (
        <SelectBox
          key={item.id}
          _item={item}
          active={this.state.active}
          imgstate="no"
          change={this.changeSelect}>

        </SelectBox>
      )
    })
    let list = null;
    if (this.state.active === '0') {
      list = this.props.customized.map((item,index) => {
        return (
          <div key={"customized"+index}>
            <HistoryList _item={item}></HistoryList>
            <div className="plan-text">
              <div className="plan-textlabel">
                <p>学习进度：<span>{item.types}</span></p>
                <p>考核结果：<span>{item.end}</span></p>
              </div>
              <span
                className="delete-plan"
                onClick={(e) => this.deletePlan(item,index, e)}>
                取消计划</span>
            </div>
          </div>
        )
      })
    } else if (this.state.active === '1') {
      list = this.props.pointclass.map((item,index) => {
        return (
          <div key={"pointclass"+index} className="learnplay-lines">
            <HistoryList _item={item}></HistoryList>
          </div>
        )
      })
    }else {
      let series_container=null
      let userstate=0;
      list = ''
      console.log(this.state.gotoseries)
      if (!this.state.gotoseries) {
        let bi=0;
        if(this.props.reviewClassArr[this.props.active_series]){
          bi = this.props.reviewClassArr[this.props.active_series]['overnums']/this.props.reviewClassArr[this.props.active_series]['allnums'];
          bi=bi*100;

        }
        series_container =  (
          <article>
            <div className="series_scroll">
              <ul className="series-list"
              //  style={{ width: 170 * this.props.reviewClassArr.length+'px'}}
              >
                {
                  this.props.reviewClassArr.map((item, index) => {
                    //console.log(item)
                    console.log(item,this.state,index)
                    if (this.state.thisid >= 0 && this.state.thisid == index) {
                      this.changeseries(item, index)
                    }
                    console.log(item.isdi)
                    if(item.isdi !=1){
                     
                    }else{
                      console.log(index,this.props.active_series)
                      return (
                        <li
                          key={"reviewClassArr"+index}
                          className={index === this.props.active_series ? 'active' : ''}>
                          <span
                            className="cursor"
                            onClick={(e) => this.changeseries(e, index)}>
                            {item.name}
                          </span>
                        </li>
                      )
                    }
                    // let nname='123';
                    // //console.log(nname.length)
                    
                  })
                }
              </ul>
            </div>
            {this.props.reviewClassArr[this.props.active_series]
            ?this.props.reviewClassArr[this.props.active_series]['isdi']==1
            ?<div className='diexambox'>
              {this.props.reviewClassArr[this.props.active_series]
              ?this.props.reviewClassArr[this.props.active_series]['overnums']==this.props.reviewClassArr[this.props.active_series]['allnums']
              ?(<span className='btns' onClick={()=>this.goexam(this.props.reviewClassArr[this.props.active_series]['id'])}>开始考试</span>)
              :null
              :null}
              
              <span className='line'>
                <i style={{width:bi+"%"}}></i>
              </span>
              <span className='nums'>
                {this.props.reviewClassArr[this.props.active_series]?this.props.reviewClassArr[this.props.active_series]['overnums']:0}
                /
                {this.props.reviewClassArr[this.props.active_series]?this.props.reviewClassArr[this.props.active_series]['allnums']:0}
              </span>
            </div>
            :null
              :null
          }
            
            {
              
              this.props.reviewClassArr[this.props.active_series] ? this.props.reviewClassArr[this.props.active_series].list.map((item, index) => {
                console.log(this.props.active_series)
                console.log(item)
                //this.changeActive(index)
                //console.log(2222)

                if(item.isdi !=1){

                }else{
                  userstate++;
                  return (
                    <SeriesList
                      key={'ser' + this.props.active_series + item.id+index}
                      _item={item}
                      index={index}
                      thisid={this.props.active_series}
                      changeActive={this.changeActive}
                      gotoserieslist={this.gotoserieslist}>

                    </SeriesList>
                  )
                }
                
              }) : null
            }
            {
              userstate==0?<div style={{"font-size":"18px","text-align":"center"}}>暂无内容</div>:null
            }
          </article>
        )
      }else{
        console.log(222222)
        series_container = (
          <article>
            <div className="review-list-title">
              {this.state.seriesText}
              <span className="seriestitle-arrow" onClick={() => this.gotoserieslist()}></span>
            </div>
            {
              this.props.developments.map((item,index) => {
                return (
                  <ClassList key={'reslist' + index} _item={item}></ClassList>
                )
              })
            }
          </article>
        )
      }
        
        // list= (<div>
        //   {series_container}
        // </div>)
        // series_container ='';
        // console.log( this.props.developments)
        
        
        list= (<div className="series-contain">
        {series_container}
      </div>)
    }
    console.log(this.state.active)
    return (
      <div className='personpage'>
        <Header active='4'></Header>
        <PersonImg type="no"></PersonImg>
        <PersonTitle title="学习计划"></PersonTitle>
        <div className='page-navselect '>
          <div className={this.state.active !=2?"planclass_level none":"none"} >
            <p>1 / {this.state.active === '0' ? this.props.customized.length : this.props.pointclass.length}</p><br></br>
            <div className='planclass_level_line'>
              <i></i>
            </div>
          </div>
          <div className="wrap overflow">
            <ul className="navselect fl">
              {selectlist}
            </ul>
            <div className="select-contain fr overflow">
              <div className="planclass">

                {list}
                {
                  this.state.active === '0' ? (
                    <div className="record-bottom">
                      <div className="add-newaccount">
                        <Link to="/homeback">添加课程</Link>
                      </div>
                    </div>
                  ) : null
                }
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    )
  }
}
// const mapDispatchToProps = (dispatch) => {
//   return {
//     changeActive(data) {
//       dispatch(change_reviewClassArr(data))
//     },
    
//   }
// }
export default LearnPlan
