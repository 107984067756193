import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  change_reviewClassArr,
  change_question,
  change_active_two,
  change_back_data,
  change_active_series,
  change_developments_data,
} from '../../store/action'
import HomeBack from './index.bundle'

const mapStateToProps = (state) => {
  console.log(state)
  return {
    active_series: state.active_series,
    active_two: state.active_two,
    bannerlist: state.back_bannerlist,
    reviewclass: state.back_reviewclass,
    hotback: state.back_hotback,
    reviewClassArr: state.back_reviewClassArr,
    question: state.back_question,
    developments: state.developments
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeActive(data) {
      dispatch(change_reviewClassArr(data))
    },
    change_question(data) {
      dispatch(change_question(data))
    },
    change_active_two(data) {
      dispatch(change_active_two(data))
    },
    change_back_data(data) {
      //console.log(data)
      dispatch(change_back_data(data))
    },
    // 修改系列课程
    change_reviewClassArr(data) {
      dispatch(change_reviewClassArr(data))
    },
    change_active_series(data) {
      dispatch(change_active_series(data))
    },
    change_developments_data(id, thistypenums) {
      dispatch(change_developments_data(id, thistypenums))
    },
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HomeBack)
);
