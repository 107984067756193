import React, { useState, useEffect } from 'react'


import { encrypt, decrypt } from '../../utils/crypto'

// 组件
import Header from '../../components/header'
import Footer from '../../components/footer'
// import GroupChat from './components/GroupChat'
// import GroupData from './components/GroupData'
import GroupList from './components/GroupList'
import GroupAdd from './components/GroupAdd'



import Contactuslayer from '../../components/contactuslayer'

import classnames from 'classnames'
import './index.css'

let playingIcon12 = require('../../static/images/playing_icon_12.png')
let playingIcon12Act = require('../../static/images/playing_icon_12_act.png')

let playingIcon11 = require('../../static/images/playing_icon_11.png')
let playingIcon11Act = require('../../static/images/playing_icon_11_act.png')


const UserPost = (props) => {
  let userid = localStorage.getItem('userid') 
  let token = localStorage.getItem('token')

  // list: 列表  add: 新建
  let [groupNav, setGroupNav] = useState('list')
  let [grouplist, setGrouplist] = useState([])
  
  let [cancreate, setCancreate] = useState(false)

  let [roletype, setRoletype] = useState(2)

  let [contactus, setContactus] = useState(false)
  useEffect(() => {
    fetch(
      "https://shl-info.cn/front/groupchat/getGroupChatList?userid=" +
      localStorage.getItem('userid') +
      "&groupname=" ,{
        headers:{
          'token':localStorage.getItem('token') 
        }
      }
    )
    .then(data => {
      return data.json();
    })
    .then(res => {
      console.log(res)
      if(res.code==2000){
        setGrouplist(res.data)
        // this.setState({
        //   grouplist:res.data
        // })
      }
    });


    let userid = localStorage.getItem('userid')
    let token = localStorage.getItem('token')

  
    if (userid && token) {
      // 验证是否过期
      fetch(
        "https://shl-info.cn/front/user/checkuser?userid=" +
        localStorage.getItem('userid') ,{
          headers:{
            'token':localStorage.getItem('token') 
          }
        }
      )
        .then(data => {
          return data.json();
        })
        .then(res => {
          console.log(res)
          if(res.list){
            localStorage.setItem('headimg', res.pic)
            localStorage.setItem('names', decrypt(res.name))

            if(res.list.roletype==1 || res.list.roletype==2){
              setCancreate(true)
              setRoletype(res.list.roletype)
            }
            
          }
          
        });
    }else{
      props.history.push("/loginlist?isgroup=1")
    }

    if(window.location.href.indexOf("isadd=1")>=0){
      setGroupNav('add')
    }

  }, [])
  
  let closelayer = ()=>{
    setContactus(false)
  }
  
  return (
    <div className='groupManipulatPage'>
      <Header active='4'></Header>
      {contactus?<Contactuslayer closelayer={closelayer}></Contactuslayer>:""}
      <div className="pageContent">
        {/* <div className="pageHead">
          <div className="content">
            <div className="nav">
              <div className="navItem">
                <div className="navItemText">产品中心</div>
                <img src={require('../../static/images/homeplay/footer_icon_add1_active.png')} alt="" className="navItemIcon" />
              </div>
              <div className="navItem">
                <div className="navItemText">疾病中心</div>
                <img src={require('../../static/images/homeplay/footer_icon_add2_active.png')} alt="" className="navItemIcon" />
              </div>
              <div className="navItem">
                <div className="navItemText">客户中心</div>
                <img src={require('../../static/images/homeplay/footer_icon_add3_active.png')} alt="" className="navItemIcon" />
              </div>
              <div className="navItem">
                <div className="navItemText">科研中心</div>
                <img src={require('../../static/images/homeplay/footer_icon_add4_active.png')} alt="" className="navItemIcon" />
              </div>
            </div>
          </div>
        </div> */}

        <div className="pageBody">
          <div className="content groupinfo"> 
            <div className='contactusicon' onClick={()=> setContactus(true)}>
              <p>技术热线</p>
            </div>
            <div className="pageBodyLeft">
              <div className="groupNav">
                <div className={classnames("groupNavItem cursor", groupNav === 'list' && 'active')} onClick={() => setGroupNav('list')}>
                  <img src={ groupNav === 'list' ? playingIcon12Act : playingIcon12 } alt="" className="groupNavItemIcon" />
                  <div className="groupNavItemText ">群组列表</div>
                </div>
                {cancreate? <div className={classnames("groupNavItem cursor", groupNav === 'add' && 'active')} onClick={() => setGroupNav('add')}>
                  <img src={ groupNav === 'add' ? playingIcon11Act : playingIcon11 } alt="" className="groupNavItemIcon" />
                  <div className="groupNavItemText ">新建群组</div>
                </div>:null}
               
              </div>
            </div>
            <div className="pageBodyRight">
              { groupNav === 'list' ? <GroupList history={props.history} grouplist = {grouplist}></GroupList> : <GroupAdd roletype={roletype} setGroupNav={setGroupNav}></GroupAdd> }
            </div>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </div>
  )
};

export default UserPost;
