import React from 'react'

import './index.css'

function SelectBox(props) {
   // console.log(props)
    let selectlisttextlist =  ["产品中心","疾病中心","客户中心","系列课程"]
    let tagname = "外部专区"
    if(localStorage.getItem('changetype')==1){
        tagname =  "员工专区";
    }
    
    return (
        <li
            className={['select-box cursor', props.active === props._item.type ?props.issmall?"active smallicon": 'active' :props.issmall?"smallicon": ''].join(' ')}
            onClick={() => props.change(props._item.type, props._item.title, props._item.id)}>
            <img src={props.active === props._item.type ? props._item.icon_act : props._item.icon} alt="" className={props.imgstate == 'no' ? "none" : ""} />
            {/* {props._item.title} */}
            {props._item.type =="series"
            ?selectlisttextlist[props.thistypenums-6]?selectlisttextlist[props.thistypenums-6]:props._item.title
            :props.isuser?tagname : props._item.title
            }
            
            {
                props.active === props._item.type ?
                    <span className='select-arrow'></span> : null
            }
        </li>
    )
}

export default SelectBox
