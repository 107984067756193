import React, { useState, useEffect } from 'react'
import $, { type } from 'jquery'
// 组件
import Header from '../../components/header'
import Footer from '../../components/footer'
import PostItem from '../../components/postItem'
import classnames from 'classnames';
import axios from 'axios';
import moment from 'moment';
import utils from '../../utils/index'
import { Link } from 'react-router-dom'



import Scorelayer from '../../components/scorelayer2023';

import './index.css'
let maxheight=0;
const PostDetail = (props) => {
  let userid = localStorage.getItem('userid'), token = localStorage.getItem('token');
  let query = utils.getUrlParams()
  let [ postsDetail, setPostsDetail ] = useState({})
  let [ topiclist, setTopicList ] = useState([])
  let [ topicseclist, setTopicsecList ] = useState([])
  let [ isLoading, setIsloading ] = useState(false)

  let [ postImg, setPostImg ] = useState('')

  let [ issubtextstate, setIssubtextstate ] = useState(false)
  

  let [ lastkey, setLastkey ] = useState('')
  let [ lastiskey, setLastiskey ] = useState('')


  let [ postText, setPostText ] = useState('')
  let [ postTexterror, setPostTexterror ] = useState(false)

  

  let [ isBest, setisBest ] = useState(0)
  let [ isCommit, setisCommit ] = useState(0)

  
  let [ scorelsyerstate, setScorelsyerstate ] = useState(false)
  
  let [ scorelsyerlist, setScorelsyerlist ] = useState({
    score:"",
    content:"",
  })



  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    console.log("isBest",isBest)
    console.log("isCommit",isCommit)
    maxheight = $('.postDetailContent').height();
    if(maxheight && isBest==1 && isCommit==0 && postsDetail.is_hits_text !=1){
      console.log($('.postDetailContent').height())
      $('.postDetailContent').css("height","auto");
      $('.postDetailContent').css("height",maxheight/2+"px");
    }else{
      console.log($('.postDetailContent').height())
      $('.postDetailContent').css("height","auto");
    };
  }, [isCommit,postsDetail,isBest])

  let init = () => {
    getPostsDetail()
  }

  
 let cutStr = (str,L) =>{    

  var result = '',
      strlen = str.length, // 字符串长度
      chrlen = str.replace(/[^\x00-\xff]/g,'**').length; // 字节长度

  if(chrlen<=L){return str;}
  
  for(var i=0,j=0;i<strlen;i++){
      var chr = str.charAt(i);
      if(/[\x00-\xff]/.test(chr)){
          j++; // ascii码为0-255，一个字符就是一个字节的长度
      }else{
          j+=2; // ascii码为0-255以外，一个字符就是两个字节的长度
      }
      if(j<=L){ // 当加上当前字符以后，如果总字节长度小于等于L，则将当前字符真实的+在result后
          result += chr;
      }else{ // 反之则说明result已经是不拆分字符的情况下最接近L的值了，直接返回
          return result;
      }
  }
}

let  removeHTMLTag = (str) => {

  
  str = str.replace(/<\/?[^>]*>/g, '') // 去除HTML tag
  str = str.replace(/[ | ]*\n/g, '\n') // 去除行尾空白
  str = str.replace(/\n[\s| | ]*\r/g,'\n'); //去除多余空行
  str = str.replace(/ /ig, '') // 去掉
  const arrEntities = { 'lt': '<', 'gt': '>', 'nbsp': ' ', 'amp': '&', 'quot': '"' } // 转义符换成普通字符
  str = str.replace(/&(lt|gt|nbsp|amp|quot);/ig, function(all, t) { return arrEntities[t] })
  return str
}
  // 获取帖子详情
  let getPostsDetail = async () => {
    let id = query.id
    if (!id) { window.history.go(-1); return }

    //添加帖子浏览记录
    fetch(
      "https://shl-info.cn/front/Bbsdata/addTopicLog?topicid="+id+"&userid="+localStorage.getItem('userid')+"&type=1"
    )
    .then(data => {
      return data.json();
    })
    .then(res => {
    
    })

    let res = await axios({
      method: 'get',
      headers:{
          "token":token
      },
      url: `https://shl-info.cn/front/bbs/articleDetail/id/${id}`,
      params: {
        user_id: userid,
      }
    })
    let data = res.data
    if (!data) { window.history.go(-1); return }
    var smalldata = removeHTMLTag(data.data);
    if(smalldata.length>100){
      data.smalldata = data.data
    }else{
      smalldata =cutStr(smalldata,smalldata.length/5)
      data.smalldata = smalldata
    }
    if(id==587){
      data.course_id = 999
      data.course = {"headimg":"https://shlinfo.oss-cn-shanghai.aliyuncs.com/static/MR微课堂.png","coursetitle":"MR微课堂"}
    }
    // smalldata =cutStr(smalldata,smalldata.length/3)
    // data.smalldata = smalldata

    setPostsDetail(data)
    if(!localStorage.getItem('userid')){
      setisBest(1)
    }else{
      setisBest(data.isbest)
    }
    setisCommit(data.iscomment)


    fetch(
      "https://shl-info.cn/front/bbs/articleCommentList/article_id/"+id+"?user_id="+localStorage.getItem('userid')+"&parent_comment_id="
    )
    .then(data => {
      return data.json();
    })
    .then(res1 => {
      console.log(res1)
      console.log(11111)
      if(res1){
        setTopicList(res1)
        setTopicsecList(res1)
      }

      setIssubtextstate(false)
      // this.setState({
      //   commentlist:res,
      // })
    })
  }
  const getsmalltalk = (item,cid)=>{
    let id = query.id
    //console.log(cid)
    let commentid=item[cid]['id']
    //console.log(commentid)
    
    fetch(
      "https://shl-info.cn/front/bbs/articleCommentList/article_id/"+id+"?user_id="+localStorage.getItem('userid')+"&parent_comment_id="+commentid
    )
    .then(data => {
      return data.json();
    })
    .then(res1 => {
      console.log(res1)
      console.log(222222)
      let itemlist=item
      itemlist[cid]['seclist']=res1;
      setTopicsecList(JSON.parse(JSON.stringify(itemlist)))
      //setTopicList(res1)
      // this.setState({
      //   commentlist:res,
      // })
    })
  }

  // 评论Item
  let commentItemDom = (data, key) => {
    let commentItemDoms = []
    if (data.children) {
      for(let key in data.children) {
        commentItemDoms.push(commentItemDom(data.children[key], key))
      }
    }
    let commentItemReplyBodyDom = (
      <div className="commentItemReply">
        { commentItemDoms }
      </div>
    )
    return (
      <div className="commentItem" key={ key }>
        <div className="commentItemUserHead">
          <img className="commentItemUserHeadImg" src={ require("../../static/images/userHead.png")}/>
        </div>
        <div className="commentItemBody">
          <div className="commentItemUserName">
            <span className="commentItemDate"></span>
          </div>
          <div className="commentItemContent">
            <img className="commentItemContentImg" src={require('../../static/images/publicimg/classimg5.png')}/>
            
          </div>
          <div className="commentItemOption">
            <div className="commentItemOptionReply">
              回复
              { commentItemDoms.length === 0 ? '' : <span>&nbsp;· {commentItemDoms.length}条回复<img className="commentItemOptionReplyIcon" src={require('../../static/images/icon-9.png')}/></span> }
            </div>
            <div className="commentItemOptionLike">
              80
              <img className="commentItemOptionLikeIcon" src={require('../../static/images/icon-1.png')}/>
            </div>
          </div>
          { commentItemDoms.length !== 0 ? commentItemReplyBodyDom : '' }
        </div>
      </div>
    )
  }

  //拆分数组
  let commentItemDomone = (data, key) => {
    let lengs=0;
    return (
      <div className="commentItem" key={ key }>
        <div className="commentItemUserHead">
          <img className="commentItemUserHeadImg" src={ require("../../static/images/userHead.png")}/>
        </div>
        <div className="commentItemBody">
          <div className="commentItemUserName">
            王佚名1222
            <span className="commentItemDate">12-23 10:30</span>
          </div>
          <div className="commentItemContent">
            <img className="commentItemContentImg" src={require('../../static/images/publicimg/classimg5.png')}/>
            拥有超过170年历史，在全球范围内持有18,000个专利，西门子医疗是领先的医疗技术公司。
          </div>
          <div className="commentItemOption">
            <div className="commentItemOptionReply">
              回复
              { lengs === 0 ? '' : <span>&nbsp;· {lengs}条回复<img className="commentItemOptionReplyIcon" src={require('../../static/images/icon-9.png')}/></span> }
            </div>
            <div className="commentItemOptionLike">
              80
              <img className="commentItemOptionLikeIcon" src={require('../../static/images/icon-1.png')}/>
            </div>
          </div>
        </div>
      </div>
    )
  }

  // 评论List
  let commentListDom = (data) => {
    let commentList = []
    for(let key in data) {
      commentList.push(commentItemDom(data[key], key))
    }
    return commentList
  }


  const addscorenew = (type,talkid)=>{
    $.ajax({
      type: "POST",
      url:
        "https://shl-info.cn/front/Scoreshop/setScore?userid=" +
        localStorage.getItem('userid') +
        "&usertype=" +
        localStorage.getItem('usertype'),
      data: JSON.stringify({
        userid: localStorage.getItem('userid'),
        type: type,
        courseid:"",
        attachid:'',
        bbsid:query.id,
        talkid:talkid,
      }),
      dataType: "json",
      headers:{'token':localStorage.getItem('token')},
      cache: false
    })
      .catch(error => {
      })
      .then(res => {
        console.log(res)
        if(res.code==2000){
          setScorelsyerlist(res.data);
          setScorelsyerstate(true)
          // this.setState({
          //   scorelsyerlist:res.data,
          //   scorelsyerstate:true,
          // })
        }
        
        //console.log(res)
      });
  }
  
  //
  const imgupload = (e,keys,iskey="") => {
    //console.log(keys,iskey)
    //console.log(e.target.files[0])


    if(typeof(keys) =='undefined'){
      setIsloading(true)
    }else{
      let itemlist=topiclist
      if(iskey>=0 && iskey !==''){
        itemlist[iskey]['seclist'][keys]['isupload']=true;
      }else{
        itemlist[keys]['isupload']=true;
      }
     
      setTopicList(JSON.parse(JSON.stringify(itemlist)))
    }
    let that = this
    var imgfile = document.querySelector('#imgFile');//获取input
    var file = e.target.files[0];
    //console.log(file)
    // var reader = new FileReader();
    // reader.readAsDataURL(file);
    // reader.onload = function (e) {
    //   // 图片base64化
    //   var newUrl = this.result;
    //   that.setState({
    //     newUrl: newUrl
    //   })
    // };
    var formFile = new FormData();

    let userid = (localStorage.getItem('userid'));
    let token = (localStorage.getItem('token'));
    formFile.append("uploadFile", file); //加入文件对象
    $.ajax({
      type: "post",
      url: "https://shl-info.cn/front/frontc/adduserworkspc?userid=" + userid ,
      data: formFile,
      //是否预处理,默认值为true,这里改成false
      processData: false,
      
      headers:{'token':localStorage.getItem('token')},
      //是否设置内容,默认值为true,这里改成false
      contentType: false,
      success: function (res) {
        //alert('提交成功');
       
        var backres = JSON.parse(res);
        //console.log(backres.pic)
        if(backres.pic){
          if(typeof(keys) =='undefined'){
            //console.log('回复帖子')
            setPostImg(backres.pic)
          }else{
            console.log('回复评论')
            let itemlist=topiclist

            if(iskey>=0 && iskey!==''){
              console.log(1111)
              itemlist[iskey]['seclist'][keys]['postimg']=backres.pic;
            }else{
              console.log(2222)
              itemlist[keys]['postimg']=backres.pic;
            }
            console.log(itemlist)

            //itemlist[keys]['postimg']=backres.pic;
            setTopicList(JSON.parse(JSON.stringify(itemlist)))
          }
        }else{
          if(typeof(keys) =='undefined'){
            setIsloading(false)
          }else{
            let itemlist=topiclist
            //itemlist[keys]['isupload']=false;
            if(iskey>=0&& iskey!==''){
              itemlist[iskey]['seclist'][keys]['isupload']=true;
            }else{
              itemlist[keys]['isupload']=true;
            }
            setTopicList(JSON.parse(JSON.stringify(itemlist)))
          }
        }
        

       // setIsloading(false)
        
      },
      error: function (data) {
        //alert('提交失败');
      }
    });
  }
  const closeimg =(types,keys,iskey='')=>{
    if(types==1){
      setIsloading(false)
      setPostImg("")
    }else{

      let itemlist=topiclist
      if(iskey>=0 && iskey!==''){
        itemlist[iskey]['seclist'][keys]['isupload']=false;
        itemlist[iskey]['seclist'][keys]['postimg']='';
      }else{
        itemlist[keys]['isupload']=false;
        itemlist[keys]['postimg']="";
      }
      //itemlist[keys]['isupload']=false;
      setTopicList(JSON.parse(JSON.stringify(itemlist)))
    }
  }
  // 评论框
  let postCommentDom = (ids='',keys,iskey="") => {
    //console.log(ids)
    //console.log(keys)
    //console.log(iskey)
   
    
    // //console.log(keys==undefined?"postImg":"111")
    // //console.log(postImg)

    // if(typeof(keys) =='undefined'){
    //   //console.log('回复帖子')
    //   setPostText(e.target.value)
    //   setPostTexterror(false)
    // }else{ 
    //   let itemlist=topiclist
    //   itemlist[keys]['comments']=e.target.value;
    //   itemlist[keys]['commentserror']=false
    //   setTopicList(JSON.parse(JSON.stringify(itemlist)))
    // }


    if(keys==undefined){
      return (
        <div className="postComment">
          <textarea  className="postCommentInput" onChange={(e)=>changetext(e,ids,keys)} value={postText} placeholder="写评论" ></textarea>
          <div className="postCommentInputTips">{postTexterror?"请输入内容":null}</div>
          <div className="postCommentBottom">
            <div className="postCommentFiles">
              
            {/* <div>{keys==undefined?"postImg":"111"}</div> */}
              <div className={isLoading?"postCommentFilesList":" none"}>
                <div className="postCommentFilesItem">
                  <div className="postCommentFilesItemContent">
                    <img src={keys==undefined?postImg:""} alt="" className="postCommentFilesItemImg"/>
                    <div className={postImg?"none":"postCommentFilesItemTips"}>上传中...</div>
                  </div>
                  <img className="postCommentFilesItemIcon" onClick={()=>closeimg(1,keys)} src={require('../../static/images/classErrorpng.png')} />
                </div>
              </div>
              <input type="file" id="imgFile"  name='img'  className={isLoading ?"none":""} accept=".jpg,.png,.jpeg" onChange={(e) => imgupload(e,keys)}   />
              <img src={require('../../static/images/icon-4.png')} className={isLoading?"none":" postCommentFilesTips"} />
            </div>
            <div className={localStorage.getItem('userid')?"postCommentSub":"postCommentSub nologin"}  onClick={()=>subcomment(keys)}>发布</div>
          </div>
        </div>
      )
    }else{
      // //console.log(topiclist[keys]['isupload'])
      //console.log(11111)
      ////console.log(topiclist[keys]['postimg'])
      return (
        <div className="postCommentbox">
          <div className="backtalkarrow"><img src={require('../../static/images/backtalkarrow.png')} /></div>
          <div className="postComment">
            <textarea  className="postCommentInput" onChange={(e)=>changetext(e,ids,keys,iskey)} ></textarea>
            {iskey===''?topiclist[keys]['commentserror']?<div className="postCommentInputTips">请输入内容</div>:null:null}
            {iskey>=0 && iskey!==''?topiclist[iskey]['seclist'][keys]['commentserror']?<div className="postCommentInputTips">请输入内容</div>:null:null}
            <div className="postCommentBottom">
              <div className="postCommentFiles">
                
              {/* <div>{keys==undefined?"postImg":"111"}</div> */}
              
                <div className={iskey===''?topiclist[keys]['isupload']?"postCommentFilesList":" none":iskey>=0 && iskey!==''?topiclist[iskey]['seclist'][keys]['isupload']?"postCommentFilesList":"none":"none"}>
                  <div className="postCommentFilesItem">
                    <div className="postCommentFilesItemContent">
                      <img src={iskey===''?topiclist[keys]['postimg']?topiclist[keys]['postimg']:"":iskey>=0 && iskey!==''?topiclist[iskey]['seclist'][keys]['postimg']?topiclist[iskey]['seclist'][keys]['postimg']:"":""} alt="" className="postCommentFilesItemImg"/>
                      <div className={iskey===''?topiclist[keys]['postimg']?"none":"postCommentFilesItemTips":iskey>=0 && iskey!==''?topiclist[iskey]['seclist'][keys]['postimg']?"none":"postCommentFilesItemTips":"postCommentFilesItemTips"}>上传中...</div>
                    </div>
                    <img className="postCommentFilesItemIcon" onClick={()=>closeimg(2,keys,iskey)}  src={require('../../static/images/classErrorpng.png')} />
                  </div>
                </div>
                <input type="file" id="imgFile"  name='img'  className={iskey===''?topiclist[keys]['isupload'] ?" none ":"":iskey>=0 && iskey!==''?topiclist[iskey]['seclist'][keys]['isupload']?"none":"":""} accept=".jpg,.png,.jpeg" onChange={(e) => imgupload(e,keys,iskey)}   />
                <img src={require('../../static/images/icon-4.png')} className={iskey===''?topiclist[keys]['isupload']?"none":" postCommentFilesTips":iskey>=0 && iskey!==''?topiclist[iskey]['seclist'][keys]['postimg']?"none":"postCommentFilesTips":"postCommentFilesTips"} />
              </div>
              <div className={localStorage.getItem('userid')?"postCommentSub":"postCommentSub nologin"} onClick={()=>subcomment(keys,ids,iskey)}>发布</div>
            </div>
          </div>
        </div>
      )
    }
    
  }
  const changetext = (e,ids,keys,seckey)=>{
    //console.log(e.target.value,ids,keys)
    //console.log(seckey)
  
    setPostTexterror(false)
    if(typeof(keys) =='undefined'){
      //console.log('回复帖子')
      setPostText(e.target.value)
      setPostTexterror(false)
    }else{
      if(seckey===''){
        let itemlist=topiclist
        itemlist[keys]['comments']=e.target.value;
        itemlist[keys]['commentserror']=false
        setTopicList(JSON.parse(JSON.stringify(itemlist)))
      } else{
        let itemlist=topiclist
        itemlist[seckey]['seclist'][keys]['comments']=e.target.value;
        itemlist[seckey]['seclist'][keys]['commentserror']=false
        setTopicList(JSON.parse(JSON.stringify(itemlist)))
      }
    }
  }
  const subcomment =(keys,ids,iskey='')=>{
    //console.log(keys)
    //console.log(ids)
    //console.log(iskey)
    console.log(issubtextstate)
    if(issubtextstate){
      console.log('拦截')
      return false
    }
    console.log(12312312)
    setIssubtextstate(true)
    console.log(localStorage.getItem('userid'))
    if(!localStorage.getItem('userid')){
      return false;
    }
    if(typeof(keys) =='undefined'){
      //console.log('回复帖子')
      //console.log(postText)
      //console.log(postImg)
      if(postText==''){
        setPostTexterror(true)
        return false;
      }
      let conmmentid='';
      
      $.ajax({
        type: "POST",
        url:
          "https://shl-info.cn/front/bbs/createArticleComment/article_id/"+query.id+"?user_id=" +
          localStorage.getItem('userid') ,
          data: {
            "parent_comment_id": conmmentid,
            "data": postText,
            "img": postImg,
          },
        dataType: "json",
        
        headers:{'token':localStorage.getItem('token')},
        cache: false
      })
      .catch(error => {
        //console.log(error);
      })
      .then(res => {

        //console.log(res);
        let newarr = topiclist;
        //console.log(newarr)
        newarr.splice(0, 0, res)
        //console.log(newarr);
        setPostImg('')
        setPostText('')
        setIsloading(false)

        setisCommit(1)
        
        setTopicList(JSON.parse(JSON.stringify(newarr)))
        setIssubtextstate(false)
        //console.log(res)
        //this.props.closeusercback(conmmentid,res);
        
      });

      
    }else{
      //console.log('回复评论')
      //console.log(topiclist)
      //console.log(iskey)
      //console.log(keys)
      let conmmentid=ids?ids:topiclist[keys].id;
      let datacoments='';
      let dataimgurl='';
      if(iskey===''){
        //console.log(11)
        if(!topiclist[keys].comments ||topiclist[keys].comments==''){
          let itemlist=topiclist
          itemlist[keys]['commentserror']=true;
          setTopicList(JSON.parse(JSON.stringify(itemlist)))
          return false;
        }
        datacoments = topiclist[keys].comments
        dataimgurl = topiclist[keys].postimg
      }
      if(iskey!=='' && iskey>=0){
        //console.log(22)
        //console.log(topiclist[iskey]['seclist'][keys].comments)
        if(!topiclist[iskey]['seclist'][keys].comments ||topiclist[iskey]['seclist'][keys].comments==''){
          //console.log(4444)
          let itemlist=topiclist
          itemlist[iskey]['seclist'][keys]['commentserror']=true;
          setTopicList(JSON.parse(JSON.stringify(itemlist)))
          return false;
        }

        datacoments = topiclist[iskey]['seclist'][keys].comments
        dataimgurl = topiclist[iskey]['seclist'][keys].postimg
      }
      // //console.log(datacoments)
      // //console.log(dataimgurl)
      // //console.log(topiclist)
      //return false;
      
      $.ajax({
        type: "POST",
        url:
          "https://shl-info.cn/front/bbs/createArticleComment/article_id/"+query.id+"?user_id=" +
          localStorage.getItem('userid') ,
          data: {
            "parent_comment_id": conmmentid,
            "data": datacoments,
            "img": dataimgurl,
          },
        dataType: "json",
        headers:{'token':localStorage.getItem('token')},
        cache: false
      })
      .catch(error => {
        //console.log(error);
      })
      .then(res => {

        // setIssubtextstate(false)
        //console.log(res);
        // let newarr = topiclist;
        // //console.log(newarr)
        // newarr.splice(0, 0, res)
        // //console.log(newarr);
        //console.log(iskey)
        if(iskey!==''){
          //console.log(iskey)
          showthiscomment(topiclist,iskey,true)
        }

        //console.log(iskey,keys)
        //console.log(4444)
        if(iskey==='' && keys>=0){
          //console.log(3333)
          getPostsDetail()
        }
        
        //setTopicList(JSON.parse(JSON.stringify(newarr)))
        //console.log(res)
        //this.props.closeusercback(conmmentid,res);
        
      });
    }
  }
  const showthiscomment =(itemlist,keys,isstate)=>{
    if(itemlist[keys]['commentstate'] && !isstate){
      itemlist[keys]['commentstate']=false;
      itemlist[keys]['seclist']=[];
      setTopicList(JSON.parse(JSON.stringify(itemlist)))
    }else{
      itemlist[keys]['commentstate']=true;
      //console.log(itemlist[keys])
      getsmalltalk(itemlist,keys)
    }
    //setTopicList(JSON.parse(JSON.stringify(itemlist)))
  }
  const showthisback =(itemlist,keys)=>{



    if(itemlist[keys]['backstate']){
      if(itemlist[keys]){
        itemlist[keys]['backstate']=false;
      }
      //console.log(itemlist[keys])
      //setTopicList(JSON.parse(JSON.stringify(itemlist)))
    }else{
      itemlist[keys]['backstate']=true;
      //console.log(itemlist[keys])
    }

    if( lastkey>=0 && lastkey!==keys && lastkey!=''){
      if(itemlist[lastkey]){

        itemlist[lastkey]['backstate']=false;
      }
    }
    if(lastiskey>=0&& lastiskey!==''){
      //console.log(345)
      if(itemlist[lastiskey]['seclist']){
        itemlist[lastiskey]['seclist'][lastkey]['backstate']=false;
      }
    }

    setTopicList(JSON.parse(JSON.stringify(itemlist)))
    //setTopicList(JSON.parse(JSON.stringify(itemlist)))


    setLastiskey("");
    setLastkey(keys)
  }
  const talklist=()=>{
    
  }
  const shosecback =(keys,key)=>{
    let itemlist=topiclist
    // alert(keys)
    // alert(key)
    if(itemlist[keys]['seclist'][key]['backstate']){

      itemlist[keys]['seclist'][key]['backstate']=false;
      ////console.log(itemlist[keys])
      //setTopicList(JSON.parse(JSON.stringify(itemlist)))
    }else{
      
      itemlist[keys]['seclist'][key]['backstate']=true;
    ////console.log(itemlist[keys])
    }
    //console.log(lastiskey,lastkey)
    //console.log(keys,key)
    if(lastkey>=0 && lastkey!==''){
      itemlist[lastkey]['backstate']=false;
    }
    if(lastiskey>=0&& lastkey>=0 && (lastiskey!=keys ||lastkey!=key ) && lastiskey!==''){
      //console.log(345)
      itemlist[lastiskey]['seclist'][lastkey]['backstate']=false;
    }

    setTopicList(JSON.parse(JSON.stringify(itemlist)))

    setLastiskey(keys);
    setLastkey(key)
    //console.log(itemlist)
    //console.log(keys,key)
  }
  const showseclist = (item,keys)=>{
    //console.log(keys)
    let sechtml=null;
    if(item.seclist.length>0){
      sechtml = item.seclist.map((item,key)=>{
        return (
          <div className="commentItem" key={ key }>
            <div className="commentItemUserHead">
              <img className="commentItemUserHeadImg" src= {item.user?item.user.headimgurl:require('../../static/images/icon-portrait.png')}/>
            </div>
            <div className="commentItemBody">
              <div className="commentItemUserName">
                {item.user.name_text}
                <span className="commentItemDate">
                {item.created}</span>
              </div>
              <div className="commentItemContent">
                {/* <img className="commentItemContentImg" src={require('../../static/images/publicimg/classimg5.png')}/> */}
                {item.img?<img className="commentItemContentImg" src={item.img}/>:null}
                {item.data}
                {item.comment?" //":null} 
                {item.comment?<span className='isspan'>@{item.comment?item.comment.user?item.comment.user.name_text:null:null} {item.comment?"：":null}{item.comment?item.comment.data:null}</span>:null}
              </div>
              <div className="commentItemOption">
                <div className="commentItemOptionReply" >
                 <span onClick={()=>shosecback(keys,key)}>回复</span> 
                </div>
                <div className="commentItemOptionLike" onClick={()=>golove(item.is_hits_text,item.id,keys,key)}>
                {item.hits_count}
                  <img className="commentItemOptionLikeIcon" src={item.is_hits_text==1?require('../../static/images/clickicon_active.png'):require('../../static/images/icon-1.png')}/>
                </div>
              </div>
                {/* <div>{item.id}</div> */}
              {item.backstate && item.backstate!='undefined'? postCommentDom(item.id,key,keys):null}
            </div>
          </div>
        )
      })
      return sechtml;
      
    }else{
      return '';
    }
    
  }
  const golove = (is_hits_text,ids,firkey,seckey) =>{
    //console.log(is_hits_text,ids,firkey,seckey)
    if(!localStorage.getItem('userid')){
      console.log(postsDetail)
      localStorage.setItem("refurl",window.location.href)
      props.history.push('/loginlist?isbbs=1&bbsid='+query.id)
      
      return
    }

    let types=0;
    if(is_hits_text==1){
      types=0
    }else{
      types=1
    }
    let itemlist=topiclist
    if(seckey===''){  
      if(types==1){
        itemlist[firkey]['hits_count'] = itemlist[firkey]['hits_count']+1;
        
        //添加新积分
        addscorenew(8,ids);
      }else{
        itemlist[firkey]['hits_count'] = itemlist[firkey]['hits_count']-1;
      }
      itemlist[firkey]['is_hits_text']=types;
      setTopicList(JSON.parse(JSON.stringify(itemlist)))
    }else{
      if(types==1){
        itemlist[firkey]['seclist'][seckey]['hits_count'] = itemlist[firkey]['seclist'][seckey]['hits_count']+1;
        
        //添加新积分
        addscorenew(8,ids);
      }else{
        itemlist[firkey]['seclist'][seckey]['hits_count'] = itemlist[firkey]['seclist'][seckey]['hits_count']-1;
      }
      itemlist[firkey]['seclist'][seckey]['is_hits_text']=types;
      setTopicList(JSON.parse(JSON.stringify(itemlist)))
    }
   
    fetch(
      "https://shl-info.cn/front/bbs/articleCommentHits/comment_id/"+ids+"/type/"+types+"?user_id="+ localStorage.getItem('userid')
    )
    .then(data => {
      //return data.json();
    })
    .then(res => {
      ////console.log(res)
    })  
  }
  let firtalklist = null
  //console.log(topiclist)
  if(topiclist.length>0 ){
    firtalklist = topiclist.map((item,key)=>{
      let lengs=0;
      
      lengs= item.children_comment_count_text;
      return (
        <div className="commentItem" key={ key }>
          <div className="commentItemUserHead">
            
            <img className="commentItemUserHeadImg" src= {item.user?item.user.headimgurl?item.user.headimgurl:require('../../static/images/icon-portrait.png'):require('../../static/images/icon-portrait.png')}/>
          </div>
          <div className="commentItemBody">
            <div className="commentItemUserName">
              {item.user?item.user.name_text:""}
              <span className="commentItemDate">
              {item.created}</span>
            </div>
            <div className="commentItemContent">
              {/* <img className="commentItemContentImg" src={require('../../static/images/publicimg/classimg5.png')}/> */}
              {item.img?<img className="commentItemContentImg" src={item.img}/>:null}
              {item.data}
            </div>
            <div className="commentItemOption">
              <div className="commentItemOptionReply" >
               <span onClick={()=>showthisback(topiclist,key)}>回复</span> 
                { lengs === 0 ? '' : <span onClick={()=>showthiscomment(topiclist,key)}>&nbsp;· {lengs}条回复<img className="commentItemOptionReplyIcon" 
                src={item.commentstate?require('../../static/images/icon-9_hover.png'):require('../../static/images/icon-9.png')}/></span> }
              </div>

              <div className="commentItemOptionLike" onClick={()=>golove(item.is_hits_text,item.id,key,"")}>
              {item.hits_count}
                <img className="commentItemOptionLikeIcon" src={item.is_hits_text==1?require('../../static/images/clickicon_active.png'):require('../../static/images/icon-1.png')}/>
              </div>
            </div>


            {item.backstate && item.backstate!='undefined'? postCommentDom(item.id,key):null}

            {/* {item.commentstate?getsmalltalk(key):null} */}
            {item.seclist?item.seclist.length>0?
              showseclist(item,key)
              //commentItemDomone()
              // item.seclist.map((item,index)=>{
              //   //console.log(item)
              // })
            :null:null}
          </div>
        </div>
      )
    })
  }
  

  const goback = ()=>{
    //console.log(props.history)

    props.history.push('/homedoctorinteraction')
    //props.history.goBack()
  }
  const gettoppik = () =>{

  }
  const gologin = () =>{
    //props.history.push('/interaction')

   props.history.push('/loginlist?isbbs=1&bbsid='+query.id)
  }
  const goloverfir = ()=>{
    //console.log(postsDetail.is_hits_text)
    //hits_count is_hits_text
   
    console.log(localStorage.getItem('userid'))
    if(!localStorage.getItem('userid')){
      localStorage.setItem("refurl",window.location.href)
      props.history.push('/loginlist?isbbs=1&bbsid='+query.id)
      return false;
    }

  
    let subtype=0;

    if(postsDetail.is_hits_text==0){
      subtype=1;
      postsDetail.is_hits_text=subtype;
      postsDetail.hits_count=postsDetail.hits_count+1;

      //添加新积分
      addscorenew(6)

    }else{
      subtype=0;
      postsDetail.is_hits_text=subtype;
      postsDetail.hits_count=postsDetail.hits_count-1;
    }
    

    setPostsDetail(JSON.parse(JSON.stringify(postsDetail)))
    //console.log(postsDetail)
    
    //添加点赞
    fetch(
      "https://shl-info.cn/front/bbs/articleHits/article_id/"+postsDetail.id+"/type/"+subtype+"?user_id="+ localStorage.getItem('userid')
    )
    .then(data => {
      //return data.json();
    })
    .then(res => {
      ////console.log(res)
    })  


  }

  const closescorelayer = () =>{
    setScorelsyerstate(false)
  }
  
  const   scorelsyerstatehtml = () =>{
    return <Scorelayer closescorelayer={()=>closescorelayer()} scorelsyerlist={scorelsyerlist}></Scorelayer> 
  }
  // setScorelsyerlist(res.data);
  //setScorelsyerstate(true)
  return (
    <div className='postDetail'>
      {scorelsyerstate?scorelsyerstatehtml():null}
      <Header active='6'  isbbs={true}  bbsid={query.id}></Header>
      <div className="detailContent">
        <div className="detailHead">
          <div className="detailHeadItem">
            <span className="detailHeadItemModule">互动专区</span>
            <img className="detailHeadItemIcon" src={require('../../static/images/select_arrow.png')}/>
            <span className="detailHeadItemText">帖子</span>
          </div>
          <img className="detailHeadReturn" onClick={()=>goback()} src={require('../../static/images/icon-11.png')}/>
        </div>
        <div className="postDetailBody">
          <div className="postItemLabel">
            #{postsDetail.topic && postsDetail.topic.title}
            {postsDetail.topic?postsDetail.topic['keywords_text'].map((item,index)=>{
              return <div className="postItemTab" key={index}>{item.title}</div>
            }):null}
          </div>
          <div className="postDetailTitle">{postsDetail.title}</div>
          <div className="postDetailUser">
              <img className="postDetailUserImg" src={ postsDetail.user && postsDetail.user.headimgurl?postsDetail.user.headimgurl:require('../../static/images/icon-portrait.png')}/>
              <div className="postDetailUserName">{ postsDetail.user && postsDetail.user.name_text }</div>
              <div className="postDetailDate">{ moment(postsDetail.created).format('YYYY-MM-DD') }</div>
          </div>
          <div className="postDetailContent overhidetext" dangerouslySetInnerHTML={{ __html: isBest ==1 && isCommit ==0 && postsDetail.is_hits_text !=1?postsDetail.data :postsDetail.data }}></div>
          {isBest ==1 && isCommit ==0  && postsDetail.is_hits_text !=1?<div className='isnocoment'>{localStorage.getItem('userid')>0?"评论或点赞后可阅读全文":<span className="cursor" onClick={()=>gologin()}>登录可浏览全文或评论</span>}</div>:null}
          {postsDetail.course_id>0?<div className="postDetailRelation">
            <div className="postDetailRelationTitle">关联课程</div>
            <Link to={query.id==587?"/shopmalldetail?typeids=51":`/coursedetails?type=${postsDetail.course_id}`}>
            <div className="postDetailRelationItem">
              <img className="postDetailRelationItemImg" src={postsDetail.course?postsDetail.course.headimg:null} />
              <div className="postDetailRelationItemTitle">{postsDetail.course?postsDetail.course.coursetitle:null}</div>
              <img className="postDetailRelationItemIcon" src={require("../../static/images/gonextbottom.png")}/>
            </div>
            </Link>
          </div>:null}
          
        </div>
        <div className="detailSubord">
          <div className="detailSubordHead addnewsclass">
            <div className="detailSubordHeadTitle">评论  {topiclist.length>0?topiclist.length:null}</div>

            <div className="detailSubordHeadTip" onClick={()=>goloverfir()}>
              <img className="postDetailRelationItemIcon" src={postsDetail.is_hits_text==1?require("../../static/images/big_zan_hover.png"):require("../../static/images/big_zan.png")}/>
               { postsDetail.hits_count }
            </div>
            <div className='detailSubordHeadNewtips'>转发邀约注册，领取限量好礼</div>
          </div>
          <div className="detailSubordBody">

            { postCommentDom() }
            {firtalklist}
            {/* {topiclist.map((item,index)=>{
              //console.log(item,index) 
              commentItemDomone(item,index) 

            })} */}
            {/* {commentItemDomone({},1) }
            { commentListDom([{}, { children: [ { }, { }, { } ] }]) } */}
            {/* <div className="detailLoad">
              加载更多
              <img src={require('../../static/images/icon-9.png')} alt="" className="detailLoadIcon"/>
            </div> */}
          </div>
        </div>

      </div>

      <Footer></Footer>
    </div>
  )
}

export default PostDetail
