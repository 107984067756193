import React, { useState, useEffect } from 'react'
import './index.css'

const IndexComponent = (props) => {


  let [packidname, setPackidname]= useState('')
  let [packidlist, setPackidlist]= useState([])
  let [packidliststate, setPackidliststate]= useState(false)

  let [datainfo, setDatainfo]= useState({

  })
  
  useEffect(() => {
    console.log(33333)
    console.log(props.cousreinfo)
    console.log(props.isexam)
  }, [props.isexam,props.cousreinfo])
  
  console.log(props.isexam)
  return (
    <div className="newtipslayer">
      <div className='strp1'>

{props.showtipslayer && props.skipnum==1?
        <div className='leftbox'>
          <div className='strp1_tips1'  onClick={()=>props.changetips(2)}>
            查看群组公告
            <span></span>
            <p>下一步{">>"}</p>
          </div>
        </div>

:null}

{props.showtipslayer && props.skipnum==2?
        <div className='rightbox'>
          <div className='strp1_tips2' onClick={()=>props.changetips(3)}>
            查看群组课程
            <span></span>
            <p>下一步{">>"}</p>
          </div>
        </div>

:null}

{props.showtipslayer && props.skipnum==3?
        <div className='leftbox'>
          <div className={props.isexam!=1?"strp1_tips3 noexam":"strp1_tips3"}  onClick={()=>props.changetips(4)}>
            课程包学习进度和考试状态
            <span></span>
            <p>下一步{">>"}</p>
          </div>
        </div>
:null}



{props.showtipslayer && props.skipnum==4?
        <div className='leftbox'>
          <div className='strp1_tips4'  onClick={()=>props.changetips(5)}>
            进入课程学习
            <span></span>
            <p>下一步{">>"}</p>
          </div>
        </div>
:null}

{props.showtipslayer && props.skipnum==5?
        <div className='leftbox'>
          <div className='strp1_tips5'  onClick={()=>props.changetips(6)}>
            满足课程包考试条件时，可进入考试
            <span></span>
            <p>下一步{">>"}</p>
          </div>
        </div>
:null}


{props.showtipslayer && props.skipnum==6?
        <div className='leftbox'>
          <div className='strp1_tips6'  onClick={()=>props.changetips(7)}>

            {props.cousreinfo.condition==1?"完成所有课程学习后，参与群组考试":"参与群组考试"}
            {/* 当您满足群组大考时，可点击此处参与群组考试 */}
            <span></span>
            <p>下一步{">>"}</p>
          </div>
        </div>
:null}



{props.showtipslayer && props.skipnum==7?
        <div className='leftbox'>
          <div className='strp1_tips7'  onClick={()=>props.changetips(8)}>
            考试通过后，可查看证书
            <span></span>
            <p>下一步{">>"}</p>
          </div>
        </div>
:null}




{props.showtipslayer && props.skipnum==8?
        <div className='leftbox'>
          <div className='strp1_tips8'  onClick={()=>props.changetips(9)}>
            查看群组全体成员学习进度和考试成绩
            <span></span>
            <p>下一步{">>"}</p>
          </div>
        </div>
:null}

{props.showtipslayer && props.skipnum==9?
        <div className='leftbox'>
          <div className='strp1_tips9'  onClick={()=>props.changetips(10)}>
            技术客服联系方式
            <span></span>
            <p>完成{">>"}</p>
          </div>
        </div>
:null}



      </div>
    </div>
  )
};

export default IndexComponent;
