import React from 'react'

import './index.css'
import { Link } from 'react-router-dom'

function Grouplayer(props) {
    console.log(props)
    let groupname  = ''
    if(props.groupinfolist){
        for(var i=0;i<props.groupinfolist.length;i++){
            // if(i==props.groupinfolist.length-1){
            //     groupname +=props.groupinfolist[i]['name'];
            // }else{
                groupname +="《"+props.groupinfolist[i]['name']+"》";
            // }
        }
    }
    return (
        <div className="bbslayer">
            <div className="bbslayer_box">
                <h2 className="common-masktitle">
                登录完成
                    <span
                        className="common-maskclose cursor"
                        onClick={()=>props.closelayer()}
                       ></span>
                </h2>

                <div className='bbslayer_textbox'>
                    <div className='bbslayer_title'>您已加入如下群组，请及时进入群组学习与考试：</div>
                    <div className='bbslayer_grouplistbox'>
                        {props.groupinfolist&&props.groupinfolist.length>0?props.groupinfolist.map((item,index)=>{
                            return <div>{item.name}</div>
                        }):null}
                    </div>
                    {/* <div className="bbslayer_text groupinfo">您已加入{groupname}销售培训群组，请及时进入群组学习与考试！</div> */}
                    {/* {props.groupinfolist.descript?:null} */}
                  
                    <div className="bbslayer_btnlist">
                        <div className="bbslayer_btn groupinfo" onClick={()=>props.godetail()}>点击进入</div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Grouplayer
