import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import axios from 'axios';

import { encrypt, decrypt } from '../../utils/crypto.ts'

import './index.css'
function PersonImg(props) {
    let userid = localStorage.getItem('userid'), token = localStorage.getItem('token');

    let userDesi = ['', '南岳新秀', '玄嵩青栎', '岱宗劲柏', '幽恒云松', '太华寒杉', '珠穆朗玛峰']
    let userLevel = ['', '超声级别：一级', '超声级别：二级', '超声级别：三级', '超声级别：四级']
    let [userInfo, setUserInfo] = useState({})
    let [scoreLevel, setScoreLevel] = useState({})
    let [signScore, setSignScore] = useState({})

    useEffect(() => {
        init()
    }, [])

    let init = () => {
        getUserInfo()
        getScoreLevel()
        getSignScore()
    }

    // 获取用户数据
    let getUserInfo = async () => {
        let res = await axios({
            method: 'get',
            headers: {
                "token": token
            },
            url: `https://shl-info.cn/front/user/checkuser?userid=${userid}`
        })
        let data = res.data
        if (data.status !== 1) return
        setUserInfo(data.list)
    }

    // 获取超声等级
    let getScoreLevel = async () => {
        let res = await axios({
            method: 'get',
            headers: {
                "token": token
            },
            url: `https://shl-info.cn/front/score/getscorelevel?userid=${userid}`
        })
        let data = res.data
        console.log('------------ getScoreLevel data')
        console.log(data)
        setScoreLevel(data.data)
    }

    // 获取积分
    let getSignScore = async () => {
        let res = await axios({
            method: 'get',
            headers: {
                "token": token
            },
            url: `https://shl-info.cn/front/score/getsignscore?userid=${userid}`
        })
        let data = res.data
        // console.log('------------ getSignScore data')
        // console.log(data)
        setSignScore(data)
    }

    // 签到
    let signIn = async () => {
        if (signScore.has_sign) {
            return
        }
        console.log(userid, token)
        let usertype = localStorage.getItem('usertype')
        let res = await axios({
            method: 'post',
            headers: {
                "token": token
            },
            url: `https://shl-info.cn/front/score/addlog?userid=${userid}&usertype=${usertype}`,
            data: JSON.stringify({
                scoreid: 1,
                userid: userid,
                usertype: userInfo.usertype
            }),
            cache: false
        })
        setSignScore({
            ...signScore,
            has_sign: 1
        })
    }


    // 超声等级Dom
    let userLevelDom = () => {
        if (localStorage.getItem('local') == 'BP Sale') {
            return (
                <span className="userLevel">({ scoreLevel && userLevel[scoreLevel.chaosheng_level]})</span>
            )
        }
    }

    // 等级Dom
    let userDesiDom = () => {
        if (props.isperson == 1 && props.thisnum != 3) {
            return (
                <div className="userDesi">
                    { userDesi[props.signlevel]}
                    <span className="userDesiNum"> ( {props.signlevelscore} / 200 )</span>
                </div>
            )
        }
    }

    // 积分Dom
    let userScheduleDom = () => {
        if (props.isperson == 1 && props.thisnum != 3 && scoreLevel.current_level_score !== undefined) {
            return (
                <div className="userSchedule">
                    <div className="userScheduleCont" style={{ width: scoreLevel.current_level_score / 200 + '%' }}></div>
                </div>
            )
        }
    }


    return (
        <section className="personimg-new">
            <div className="wrap">
                <img src={userInfo.headimgurl} alt="" className="userHeadImg" />
                <div className="userContent">
                    <div className="userName">
                        {decrypt(userInfo.name)}
                        {userLevelDom()}
                    </div>
                    {userDesiDom()}
                    {userScheduleDom()}
                </div>
                <div className={classnames("userPos", props.isperson == 1 && props.thisnum != 3 ? '' : 'none')}>
                    <div className={classnames("userPosButton", signScore.has_sign ? 'active' : '')} onClick={() => signIn()}>签到</div>
                    <div className="userSignTips">已连续签到{signScore.sign_days}天</div>
                </div>
            </div>
        </section>
    )
}
// mapStateToProps：将state映射到组件的props中
const mapStateToProps = (state) => {
    return {
        userinfo: state.userinfo
    }
}

// mapDispatchToProps：将dispatch映射到组件的props中
const mapDispatchToProps = (dispatch) => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonImg)
