import React, { useState, useEffect } from 'react'


// 组件
import Header from '../../components/header'
import Footer from '../../components/footer'
import PersonImg from '../../components/personimg'
import MaskBox from '../../components/maskbox'
import axios from 'axios';
import PostItem from '../../components/postItem';
import classnames from 'classnames';

import './index.css'
import $ from 'jquery';


const UserPost = (props) => {
  let userid = localStorage.getItem('userid') 
  let token = localStorage.getItem('token')
  let [postsList, setPostsList] = useState([])
  let [postState, setPostState] = useState(2)

  useEffect(() => {
    getPostsList(2)
  }, [])

  let getPostsList = async (state) => {
    let res = await axios({
        method: 'get',
        headers:{
            "token":token
        },
        url: 'https://shl-info.cn/front/bbs/userArticleList',
        params: {
          user_id: userid,
          state: state !== undefined ? state : postState
        }
    })
    let data = res.data
    setPostsList(data.data)
  }

  
  let selectPostsTab = (state) => {
    setPostState(state)
    getPostsList(state)
  }
  const delpost=(item,index)=>{
    console.log(item)
    var url='https://shl-info.cn/front/bbs/createArticle/article_id/'+item.id+'?user_id=';
    let that=this;
    $.ajax({
      type: "POST",
      url: url +
        localStorage.getItem('userid'),
        data: {
          "topic_id": item.topic_id,
          "course_id": item.course_id,
          "state": 3,
          "title": item.title,
          "data": item.data,
        },
      dataType: "json",
      headers:{'token':localStorage.getItem('token')},
      cache: false
    })
    .catch(error => {
      console.log(error);
    })
    .then(res => {
      var newarr=postsList;
      newarr.splice(index, 1);
      setPostsList(JSON.parse(JSON.stringify(newarr))) 
    });
  }
  const gochangedetail=(item,index)=>{
    console.log(item,index)
    console.log(props)
    props.history.push("/homeDoctorReleasePost?id="+item.id)
  }
  let PostItemsDom = () => {
    if (postsList.length === 0) {
      return <div className="postListNotTips">暂无帖子</div>
    }

    return postsList.map((item, index) => {
      //console.log(item)
      //console.log(index)
      return (
        <PostItem data={item} key={ index } >
          <div className="postLiPos">
            <div className="postLiButton blue" onClick={()=>gochangedetail(item,index)}>修改</div>
            <div className="postLiButton yellow"  onClick={()=>delpost(item,index)}>删除</div>
          </div>
        </PostItem>
      )
    })
  }
  return (
    <div className='userPost'>
      <Header active='4'></Header>
      <PersonImg type="no"></PersonImg>
      
      <div className="userPostContentView">
        <div className="headView"><div className="head">我的发帖</div></div>

      
        <div className="userPostContent">
          <div className="tabs">
            <div className={classnames("tab", postState === 2 ? 'active' : '')} onClick={ () => selectPostsTab(2) } >已发布</div>
            <div className={classnames("tab", postState === 1 ? 'active' : '')} onClick={ () => selectPostsTab(1) } >审核中</div>
          </div>

          <div className="postList">
            { PostItemsDom() }
          </div>

        </div>

      </div>

      <Footer></Footer>
    </div>
  )
};

const Demo = ()=>{
  return <>1111</>
}

export default UserPost;
