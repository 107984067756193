import React, { useState, useEffect } from 'react'
import Search from '../Search'
import './index.css'
let yesIcon = require('../../../../static/images/icon14.png')
let noIcon = require('../../../../static/images/icon15.png')

const IndexComponent = (props) => {

  

  let [checklist, setChecklist] = useState([])
  useEffect(() => {
    fetch(
      "https://shl-info.cn/front/Groupchat/getNoVerifyList?userid=" +
      localStorage.getItem('userid') +
      "&chatcode=" +
      props.groupid ,{
        headers:{
          'token':localStorage.getItem('token') 
        }
      }
    )
      .then(data => {
        return data.json();
      })
      .then(res => {
        console.log(res)
        if(res.code==2000){
          setChecklist(res.data)
          // this.setState({
          //   checklist:res.data
          // })

        }

      });
  },[])
  let groupUserJurisToexItem = (item,index) => {
    return (
      <div className="groupUserJurisToexItem">
        <div className="groupUserJurisToexItemName">{item.username}</div>
        {item.state===0?<div className="groupUserJurisToexItemOper">
          <div className="groupUserJurisToexItemOperItem" onClick={()=>changeuserstate(1,index)}>
            <img src={ yesIcon } alt="" className="groupUserJurisToexItemOperItemIcon" />
            <div className="groupUserJurisToexItemOperItemTitle">同意</div>
          </div>
          <div className="groupUserJurisToexItemOperItem red" onClick={()=>changeuserstate(2,index)}>
            <img src={ noIcon } alt="" className="groupUserJurisToexItemOperItemIcon" />
            <div className="groupUserJurisToexItemOperItemTitle">拒绝</div>
          </div>
        </div>:<div className="groupUserJurisToexItemOper">
          <div className="groupUserJurisToexItemOperState">已拒绝</div>
        </div>}
      </div>
    )
  }

  let changeuserstate = (type,index) =>{
    console.log(type,index)

    fetch(
      "https://shl-info.cn/front/groupchat/setGroupUserVerify",{
        method: 'POST',
        body: JSON.stringify({
          "chatcode": props.groupid,
          "userid": localStorage.getItem('userid'),
          "groupuserid": checklist[index]['userid'],
          "verify": type,
        }),
        headers:{
          'content-type': 'application/json',
          'token':localStorage.getItem('token')
        },
        header: {
          'content-type': 'application/json',
          'token':localStorage.getItem('token')
        },
      }
    )
      .then(data => {
        return data.json();
      })
      .then(res => {
        console.log(res)
        if(res.code==2000){
         
          fetch(
            "https://shl-info.cn/front/Groupchat/getNoVerifyList?userid=" +
            localStorage.getItem('userid') +
            "&chatcode=" +
            props.groupid ,{
              headers:{
                'token':localStorage.getItem('token') 
              }
            }
          )
            .then(data => {
              return data.json();
            })
            .then(res => {
              console.log(res)
              if(res.code==2000){
                setChecklist(res.data)
    
              }
    
            });

        }

      });



    // front/groupchat/setGroupUserVerify
  }

  return (
    <div className="groupUserJurisToex ">
      <div className="groupUserJurisToexHead">
        <img src={require('../../../../static/images/icon6.png')} alt="" className="groupUserJurisToexReturn" onClick={() => props.closeFunc()}  />
      </div>
      
      <div className="groupUserJurisToexContent scrollbar">
        <div className="groupUserJurisToexList">
          {checklist.length<=0?<div className='nochecklist'>暂无内容</div>:null}
        {checklist.map((item,index)=>{
          return groupUserJurisToexItem(item,index)
        })}
          {/* { groupUserJurisToexItem() }
          { groupUserJurisToexItem() }
          { groupUserJurisToexItem() }
          { groupUserJurisToexItem() }
          { groupUserJurisToexItem() }
          { groupUserJurisToexItem() }
          { groupUserJurisToexItem() }
          { groupUserJurisToexItem() }
          { groupUserJurisToexItem() }
          { groupUserJurisToexItem() }
          { groupUserJurisToexItem() }
          { groupUserJurisToexItem() }
          { groupUserJurisToexItem() }
          { groupUserJurisToexItem() } */}
        </div>
      </div>
      
    </div>
  )
};

export default IndexComponent;
