import React, { Component } from 'react'
// 组件
import Header from '../../components/header'
import Footer from '../../components/footer'
import Banner from '../../components/banner'
import LayoutClass from '../../components/layoutclass'
import ClassBox from '../../components/classbox'
import CLassLabel from '../../components/classlabel'
import ClassFour from '../../components/classfour'

import $ from 'jquery'

import './index.css'

class Home extends Component {

  componentDidMount() {
    this.props.change_home_data()

    this.isouter=true;

    // this.getuserrole();
  }
  getuserrole = ()=>{
    let that =this
    $.ajax({
      type: "GET",
      url: "https://shl-info.cn/front/Smartremember/my_role?userid=" +
        localStorage.getItem('userid') ,
      dataType: "json",
      headers:{'token':localStorage.getItem('token')},
      success: function (res) {
        console.log(res)
        if(res.data && res.data.user_type ==1){
          this.isouter = false;
         
        }
      }
    });

    
  }
  componentWillUnmount() {
    
  }

  render() {
    // 课程中心
    let classlist = null;
    console.log(this.props.classlist)
    if (this.props.classlist) {
      classlist = this.props.classlist.map((item,index) => {
        if(index<=2){
        return (
          <CLassLabel
            key={'class'+item.id}
            _item={item}></CLassLabel>
        )
        }
      })
    }
    // 直播中心
    let playlist = null;
    if (this.props.playlist) {
      playlist = this.props.playlist.map((item) => {
        return (
          <CLassLabel
            key={'label'+item.id}
            _item={item}></CLassLabel>
        )
      })
    }
    // 第二个课程中心
    let classfour = null;
    if (this.props.classfour) {
      classfour = this.props.classfour.map((item) => {
        return (
          <ClassFour
            key={'four' + item.id}
            _item={item}></ClassFour>
        )
      })
    }
    // 推荐课程
    let recommend = null;
    if (this.props.recommend) {
      recommend = this.props.recommend.map((item) => {
        return (
          <ClassBox
            key={'recomm'+item.id}
            _item={item}></ClassBox>
        )
      })
    }
    return (
      <div className='homepage'>
        <Header
          ishome={true}
          active='0'></Header>
        <Banner bannerlist={this.props.bannerlist}></Banner>
        <LayoutClass
          key='课程中心'
          title='课程中心'
          morebtn={true}
          morehref='/homeback'
          list={classlist}></LayoutClass>
        <LayoutClass
          key='直播中心'
          title='直播中心'
          morebtn={true}
          morehref='/homeplaying'
          list={playlist}></LayoutClass>
        <LayoutClass
          key='文档中心'
          title='文档中心'
          morebtn={true}
          morehref='/homefile'
          list={classfour}></LayoutClass>
        <LayoutClass
          key='推荐课程'
          title='推荐课程'
          swiper={true}
          list={recommend}></LayoutClass>
        <Footer></Footer>
      </div>
    )
  }
}

export default Home
