let myCanvas = function (select) {
  let _that = this;

  this.canvas = document.getElementById(select);
  this.ctx = this.canvas.getContext('2d');
  this.canvas.width = this.canvas.offsetWidth * 2;
  this.canvas.height = this.canvas.offsetHeight * 2;

  this.ctx.fillStyle = "rgba(0, 0, 0, 0.25)";
  this.isDraw = false;
  this.radius = 0;
  this.has = [];
  this.lineMax = 40;  //最大半径
  this.lineMin = 10;  //最小半径
  this.linePressure = 2.5;
  this.smoothness = 80;  //
  this.upof = {};
  this._left = document.getElementsByClassName('canvasOffset')[0].offsetLeft + 241;
  this._top = document.getElementsByClassName('autograph-contain')[0].offsetTop + 50;

  this.canvas.addEventListener('mousedown', function (e) { _that.touStar(e) });
  this.canvas.addEventListener('mousemove', function (e) { _that.touMove(e) });
  this.canvas.addEventListener('mouseup', function (e) { _that.touEnd(e) });
  this.canvas.addEventListener('mouseover', function (e) { _that.touEnd(e) });
}
myCanvas.prototype.touStar = function (e) {
  this.isDraw = true;
  this.has = [];
  this.upof = this.getXY(e);
}
myCanvas.prototype.touEnd = function (e) {
  this.isDraw = false;
}
myCanvas.prototype.touMove = function (e) {
  if (!this.isDraw) {
    return false;
  }
  var of = this.getXY(e);
  var up = this.upof;
  var ur = this.radius;
  this.has.unshift({ time: new Date().getTime(), dis: this.distance(up, of) });
  var dis = 0;
  var time = 0;
  for (var n = 0; n < this.has.length - 1; n++) {
    dis += this.has[n].dis;
    time += this.has[n].time - this.has[n + 1].time;
    if (dis > this.smoothness)
      break;
  }
  var or = Math.min(time / dis * this.linePressure + this.lineMin, this.lineMax) / 2;
  this.radius = or;
  this.upof = of;
  // if (this.has.length <= 4)
  // return;
  var len = Math.round(this.has[0].dis / 2) + 1;
  for (var i = 0; i < len; i++) {
    var x = up.x + (of.x - up.x) / len * i;
    var y = up.y + (of.y - up.y) / len * i;
    var r = ur + (or - ur) / len * i;
    this.ctx.beginPath();
    this.ctx.arc(x, y, r, 0, 2 * Math.PI, true);
    this.ctx.fill();
  }
}
myCanvas.prototype.getXY = function (e) {
  return {
    x: (e.clientX - this._left) * 2,
    y: (e.clientY - this._top) * 2
  }
}
myCanvas.prototype.distance = function (a, b) {
  var x = b.x - a.x, y = b.y - a.y;
  return Math.sqrt(x * x + y * y);
}
myCanvas.prototype.clear = function () {
  this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
}

export default myCanvas;
