import React, { useState, useEffect } from 'react'
import './index.css'
let selectNotIcon = require('../../../../static/images/icon12.png')
let selectIcon = require('../../../../static/images/icon13.png')

const IndexComponent = (props) => {
  
  let selectData = {
    effectiveTime: [
      { label: '1个月', value: '1个月' },
      { label: '2个月', value: '2个月' },
      { label: '3个月', value: '3个月' },
      { label: '其他', value: '其他' },
    ]
  }

  let [effectiveTimeList, setEffectiveTimeList] = useState(selectData.effectiveTime)


  // type: true(多选)  false(单选)
  let selectRedioFunc = (index, type) => {
    
    if (!type) {
      for(let key in effectiveTimeList) {
        effectiveTimeList[key].select = false
      }
    }
    effectiveTimeList[index].select = true
    setEffectiveTimeList([
      ...effectiveTimeList
    ])
  }

  let groupChatFormItemRedio = () => {
    return effectiveTimeList.map((item, index) => {
      return (
        <div className="groupChatFormItemRedio" key={index} onClick={ () => selectRedioFunc(index, false) }>
          <img src={ effectiveTimeList[index].select ? selectIcon : selectNotIcon } alt="" className="groupChatFormItemRedioIcon" />
          { item.value }
        </div>
      )
    })
  }

  let [suberror, setSuberror] = useState(false)
  let subcontents = ()=>{
    if(!inputtext){
      setSuberror(true);
      return;
    }

    fetch(
      "https://shl-info.cn/front/groupchat/createPackForChat",{
        method: 'POST',
        body: JSON.stringify({
          "chatcode": props.groupid,
          "userid": localStorage.getItem('userid'),
          "packname": inputtext,
        }),
        headers:{
          'content-type': 'application/json',
          'token':localStorage.getItem('token')
        },
        header: {
          'content-type': 'application/json',
          'token':localStorage.getItem('token')
        },
      }
    )
      .then(data => {
        return data.json();
      })
      .then(res => {
        console.log(res)
        if(res.code==2000){
          props.closeFunc()
          // this.setState({
          //   ismasknew:true,
          // })
        }
      })


  }

  let [inputtext, setInputtext] = useState('')
  let valueChange = (e) =>{
    console.log(e.target.value)
    setInputtext(e.target.value)
    setSuberror(false);
    
  }

  return (
    <div className="groupChatCourse">
      <div className="groupChatCourseHead">
        <div className="groupChatCourseTitle">添加本群必修课程系列</div>
      </div>
      <div className="groupChatSeriesAdd">
        <div className="groupChatForm">
          <div className="groupChatFormItem">
            <div className="groupChatFormItemTitle">标题</div>
            <div className="groupChatFormItemContent">
              <input type="text" className="groupChatFormItemInput" placeholder="请输入课程组标题"
                 onChange={(e)=>valueChange(e)}
                 value={inputtext}
              />
            </div>
          </div>
          {suberror?<div className="suberror">请输入课程组标题</div>:null}
          {/* <div className="groupChatFormItem">
            <div className="groupChatFormItemTitle">有效期</div>
            <div className="groupChatFormItemContent">
              <div className="groupChatFormItemRedios">
                { groupChatFormItemRedio() }
              </div>
            </div>
          </div> */}

          <div className="groupChatFormSub" onClick={() =>subcontents() }>保存</div>
        </div>
      </div>
    </div>
  )
};

export default IndexComponent;
