import React from 'react'

import './index.css'
import { Link } from 'react-router-dom'

function Cslayer(props) {
    console.log(props)
    let groupname  = ''
    if(props.groupinfolist){
        for(var i=0;i<props.groupinfolist.length;i++){
            // if(i==props.groupinfolist.length-1){
            //     groupname +=props.groupinfolist[i]['name'];
            // }else{
                groupname +="《"+props.groupinfolist[i]['name']+"》";
            // }
        }
    }
    return (
        <div className="bbslayer">
            <div className="bbslayer_box">
                <h2 className="common-masktitle">
                致道微课堂
                    <span
                        className="common-maskclose cursor"
                        onClick={()=>props.closeuserlayercs()}
                       ></span>
                </h2>

                <div className='bbslayer_textbox'>
                    <div className='bbslayer_title'>请选择您要解锁的系列课程:</div>
                    <div className='bbslist'>
                        {props.cslist&&props.cslist.length>0?props.cslist.map((item,index)=>{
                            return  <div className='bbslist_info'  key={"cslistkey_"+index} onClick={()=>props.changecslist(item)} >  
                                {item.name}
                               {item.id==props.csliselect? <span className='hover'><i></i></span>: <span><i></i></span>}
                            </div>
                        }):"加载中"}
                       
                    </div>
                  
                    <div className="bbslayer_btnlist">
                        <div className={props.csliselect?"bbslayer_btn":"bbslayer_btn gary"} onClick={()=>props.gocheckcode()}>确认</div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Cslayer
