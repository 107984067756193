import React, { Component } from 'react'
// 组件
import Header from '../../components/header'
import Footer from '../../components/footer'
import PersonImg from '../../components/personimg'
import LayoutClass from '../../components/layoutclass'
import CLassLabel from '../../components/classlabel'

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  change_active_two,
} from '../../store/action'

import './index.css'

class LearningPlanadminuserlist extends Component {
  constructor() {
    super();

    this.state = {
      active: 0,
      worktext: '',
      iscroll: null,
      imgiscroll: null,
      layerstate: false,
      thistypeidstate: true,
      userworkreply: [],
      piclist: [],
      imglist: [],
      worktextlist: ['', 'HPS作业提交', '', 'PLP作业提交', '沙龙2-1A', '沙龙2-1B', '沙龙2-2', '沙龙3-1A', '沙龙3-1B', '沙龙3-2'],
      subtext: null,
      subtextfen: null,
      passbackstate: false,
      contentlist: {
        subtext: null,
        piclist: [],
      },
      userlist: [

      ]
    }
  }

  componentDidMount() {
    this.props.change_person_classlist()

    this.props.change_active_two("learnplanadmin")
    let userid = (localStorage.getItem('userid'));
    let token = (localStorage.getItem('token'));
    let that = this
    fetch(
      "https://shl-info.cn/front/frontc/studentlist?userid=" + userid ,{
        headers:{
          'token':token
        }
      }
    )
      .then(data => {
        return data.json();
      })
      .then(res => {
        console.log(res);
        that.setState({
          userlist: res,
        })
      });
  }
  componentWillUnmount() {

  }

  jumppages(e) {
    let atype = "typeid" + e;
    let btype = "typeid" + e + "userworkreply";
    let typeuser = (this.state[atype]);
    let typeuserworkreply = (this.state[btype]);
    if (!typeuser) {
      //window.location.href = "https://shl-info.cn/mobile/learningplanuser?typeid=" + e;
      this.props.history.push("/learningplanuser?typeid=" + e);
    } else if (typeuser.score > 0) {
      console.log('已完成')
    } else if (typeuser.score <= 0 && typeuserworkreply) {
      console.log(typeuser.userworkreply.id)
      this.props.history.push("/learningplanback?workid=" + typeuser.userworkreply.id);
      console.log('有反馈')
    } else {
      //window.location.href = "https://shl-info.cn/mobile/learningplanuser?typeid=" + e;
      this.props.history.push("/learningplanuserlook?typeid=" + e);
      console.log('已提交没有反馈')
    }
    //this.props.history.push("/learningplanuser?typeid=" + e);
    console.log(e)
  }
  changepage(e) {
    console.log(this.state.typeid1)
    var newnum = e + ',';
    let num = this.state.thisnum;
    console.log(num)
    if (num.indexOf(newnum) != -1) {
      var a = num.replace(newnum, '');
      this.setState({
        thisnum: a,
      })
    } else {
      var a = num + newnum;
      this.setState({
        thisnum: a,
      })
    }
    // this.refresh()
    //console.log(e)
  }

  userlistclick = (a, b) => {
    let newarr = this.state.userlist;
    newarr[b].thisstate = !newarr[b].thisstate;
    this.setState({
      userlist: newarr,
    })
    console.log(newarr)
  }
  userlistsecclick = (a, b, type) => {
    let newarr = this.state.userlist;
    newarr[b][type].thisstate = !newarr[b][type].thisstate;
    this.setState({
      userlist: newarr,
    })
    console.log(newarr)
  }


  dumpdetail = (e, thisstate) => {
    if (thisstate == 1 || thisstate == 2) {
      //window.location.href = "https://shl-info.cn/mobile/learningplanadmin?workid=" + e;
      this.props.history.push("/learningplanadmin" + "?workid=" + e);
    }
  }

  render() {
    // 列表
    console.log(this.state.userlist)
    console.log(this.state.userlist)
    let onelist = this.state.userlist.map((item, index) => {
      let text = '';
      let type2state = null;
      let type3state = null;
      let type5state = null;
      let type6state = null;
      let type8state = null;
      let type9state = null;
      if (item.type1.type2state == 2) {
        type2state = (<div> 已完成<span className="stime ">  得分：{item.type1.type2score}分 </span></div>);
      }
      if (item.type1.type3state == 2) {
        type3state = (<div> 已完成<span className="stime ">  得分：{item.type1.type3score}分 </span></div>);
      }
      if (item.type2.type2state == 2) {
        type5state = (<div> 已完成<span className="stime ">  得分：{item.type2.type2score}分 </span></div>);
      }
      if (item.type2.type3state == 2) {
        type6state = (<div> 已完成<span className="stime ">  得分：{item.type2.type3score}分 </span></div>);
      }
      if (item.type3.type2state == 2) {
        type8state = (<div> 已完成<span className="stime ">  得分：{item.type3.type2score}分 </span></div>);
      }
      if (item.type3.type3state == 2) {
        type9state = (<div> 已完成<span className="stime ">  得分：{item.type3.type3score}分 </span></div>);
      }
      return (
        <ul className="learningplan_userlist">
          <div className={item.thisstate ? "learningplan_userlist_title headimgtitle" : "learningplan_userlist_title headimgtitle active"} onClick={(e) => this.userlistclick(item, index)} >
            <span className="headimg"><img src={item.headimg} /></span>
            <span className="learningplan_left">{item.nickname} </span>
            <span className="learningplan_middle">{item.overnum}/8</span>
            <span className="learningplan_right icon"><img src={require("../../static/images/plan_arrow.png")} alt="" /></span>
          </div>
          <li className={item.thisstate ? "bigli" : "bigli none"}>
            <ul className={item.type1.thisstate ? "learningplan_userlist smalltitle show" : "learningplan_userlist smalltitle "}>
              <div className={item.type1.thisstate ? "learningplan_userlist_title   active" : "learningplan_userlist_title  "} onClick={(e) => this.userlistsecclick(item, index, "type1")}>
                <span className="learningplan_left">新员工作业</span>
                <span className="learningplan_right icon"><img src={require("../../static/images/plan_arrow.png")} alt="" /></span>
              </div>
              <li onClick={(e) => this.dumpdetail(item.type1.type1workid, item.type1.type1state)}>
                <span className="learningplan_left">HPS作业提交</span>
                <span className="learningplan_right">{item.type1.type1state == 0 ? "待提交" : item.type1.type1state == 1 ? "已通过" : "已通过"}</span>
              </li>
              <li onClick={(e) => this.dumpdetail(item.type1.type2workid, item.type1.type2state)} style={{display:"none"}}>
                <span className="learningplan_left">沙龙1-1B</span>
                <span className="learningplan_right">{item.type1.type2state == 0 ? "待提交" : item.type1.type2state == 1 ? item.type1.type2list ? "已反馈" : "已提交" : type2state}</span>
              </li>
              <li onClick={(e) => this.dumpdetail(item.type1.type3workid, item.type1.type3state)}>
                <span className="learningplan_left">PLP作业提交</span>
                <span className="learningplan_right">{item.type1.type3state == 0 ? "待提交" : item.type1.type3state == 1 ? item.type1.type3list ? "已反馈" : "已提交" : type3state}</span>
              </li>
            </ul>
            <ul className={item.type2.thisstate ? "learningplan_userlist smalltitle show" : "learningplan_userlist smalltitle "}>
              <div className={item.type2.thisstate ? "learningplan_userlist_title   active" : "learningplan_userlist_title  "} onClick={(e) => this.userlistsecclick(item, index, "type2")}>
                <span className="learningplan_left">沙龙主题2</span>
                <span className="learningplan_right icon"><img src={require("../../static/images/plan_arrow.png")} alt="" /></span>
              </div>
              <li onClick={(e) => this.dumpdetail(item.type2.type1workid, item.type2.type1state)}>
                <span className="learningplan_left">沙龙2-1A</span>
                <span className="learningplan_right">{item.type2.type1state == 0 ? "待提交" : item.type2.type1state == 1 ? "已通过" : "已通过"}</span>
              </li>
              <li onClick={(e) => this.dumpdetail(item.type2.type2workid, item.type2.type2state)}>
                <span className="learningplan_left">沙龙2-1B</span>
                <span className="learningplan_right">{item.type2.type2state == 0 ? "待提交" : item.type2.type2state == 1 ? item.type2.type2list ? "已反馈" : "已提交" : type5state}</span>
              </li>
              <li onClick={(e) => this.dumpdetail(item.type2.type3workid, item.type2.type3state)}>
                <span className="learningplan_left">沙龙2-2</span>
                <span className="learningplan_right">{item.type2.type3state == 0 ? "待提交" : item.type2.type3state == 1 ? item.type2.type3list ? "已反馈" : "已提交" : type6state}</span>
              </li>
            </ul>
            <ul className={item.type3.thisstate ? "learningplan_userlist smalltitle show" : "learningplan_userlist smalltitle "}>
              <div className={item.type3.thisstate ? "learningplan_userlist_title   active" : "learningplan_userlist_title  "} onClick={(e) => this.userlistsecclick(item, index, "type3")}>
                <span className="learningplan_left">沙龙主题3</span>
                <span className="learningplan_right icon"><img src={require("../../static/images/plan_arrow.png")} alt="" /></span>
              </div>
              <li onClick={(e) => this.dumpdetail(item.type3.type1workid, item.type3.type1state)}>
                <span className="learningplan_left">沙龙3-1A</span>
                <span className="learningplan_right">{item.type3.type1state == 0 ? "待提交" : item.type3.type1state == 1 ? "已通过" : "已通过"}</span>
              </li>
              <li onClick={(e) => this.dumpdetail(item.type3.type2workid, item.type3.type2state)}>
                <span className="learningplan_left">沙龙3-1B</span>
                <span className="learningplan_right">{item.type3.type2state == 0 ? "待提交" : item.type3.type2state == 1 ? item.type3.type2list ? "已反馈" : "已提交" : type8state}</span>
              </li>
              <li onClick={(e) => this.dumpdetail(item.type3.type3workid, item.type3.type3state)}>
                <span className="learningplan_left">沙龙3-2</span>
                <span className="learningplan_right">{item.type3.type3state == 0 ? "待提交" : item.type3.type3state == 1 ? item.type3.type3list ? "已反馈" : "已提交" : type9state}</span>
              </li>
            </ul>
          </li>
        </ul>
      )

    })
    return (
      <div className='learningplan'>
        <Header active='4'></Header>
        <div className="learingplan_list">
          {onelist}
          {/* <ul className="learningplan_userlist">
            <div className="learningplan_userlist_title headimgtitle" >
              <span className="headimg"><img src={require("../../static/images/plan_arrow.png")} /></span>
              <span className="learningplan_left">沙龙1</span>
              <span className="learningplan_middle">1/3</span>
              <span className="learningplan_right icon"><img src={require("../../static/images/plan_arrow.png")} alt="" /></span>
            </div>
            <li className="bigli"> 
              <ul className="learningplan_userlist ">
                <div className="learningplan_userlist_title " >
                  <span className="learningplan_left">沙龙3</span>
                  <span className="learningplan_right icon"><img src={require("../../static/images/plan_arrow.png")} alt="" /></span>
                </div>
                <li>
                  <span className="learningplan_left">沙龙3-1A</span>
                  <span className="learningplan_right">已反馈</span>
                </li>
                <li>
                  <span className="learningplan_left">沙龙3-1B</span>
                  <span className="learningplan_right">已反馈</span>
                </li>
                <li>
                  <span className="learningplan_left">沙龙3-2</span>
                  <span className="learningplan_right">已反馈</span>
                </li>
              </ul>
              <ul className="learningplan_userlist ">
                <div className="learningplan_userlist_title " >
                  <span className="learningplan_left">沙龙3</span>
                  <span className="learningplan_right icon"><img src={require("../../static/images/plan_arrow.png")} alt="" /></span>
                </div>
                <li>
                  <span className="learningplan_left">沙龙3-1A</span>
                  <span className="learningplan_right">已反馈</span>
                </li>
                <li>
                  <span className="learningplan_left">沙龙3-1B</span>
                  <span className="learningplan_right">已反馈</span>
                </li>
                <li>
                  <span className="learningplan_left">沙龙3-2</span>
                  <span className="learningplan_right">已反馈</span>
                </li>
              </ul>
              <ul className="learningplan_userlist ">
                <div className="learningplan_userlist_title " >
                  <span className="learningplan_left">沙龙3</span>
                  <span className="learningplan_right icon"><img src={require("../../static/images/plan_arrow.png")} alt="" /></span>
                </div>
                <li>
                  <span className="learningplan_left">沙龙3-1A</span>
                  <span className="learningplan_right">已反馈</span>
                </li>
                <li>
                  <span className="learningplan_left">沙龙3-1B</span>
                  <span className="learningplan_right">已反馈</span>
                </li>
                <li>
                  <span className="learningplan_left">沙龙3-2</span>
                  <span className="learningplan_right">已反馈</span>
                </li>
              </ul>
            </li>
          </ul> */}
          {/* <ul className="learningplan_userlist">
            <li>
              <span className="learningplan_left">面授培训1</span>
              <span className="learningplan_right">{this.state.usertrain ? this.state.usertrain.facelearning : ""}</span>
            </li>
          </ul> */}
          {/* <ul className={this.state.thisnum.indexOf("1,") != -1 ? "learningplan_userlist show" : "learningplan_userlist"}>
            <div  className={this.state.thisnum.indexOf("1,") != -1 ? "learningplan_userlist_title active" : "learningplan_userlist_title"} onClick={() => this.changepage(1)}>
              <span className="learningplan_left">沙龙1</span>
              <span className="learningplan_middle">1/3</span>
              <span className="learningplan_right icon"><img src={require("../../static/images/plan_arrow.png")} alt="" /></span>
            </div>
            <li onClick={() => this.jumppages(1)}>
              <span className="learningplan_left">沙龙1-1A</span>
              <span className="learningplan_right">
                {this.state.typeid1 ? 
                  this.state.typeid1.score <= 0 ? this.state.typeid1userworkreply ? "已反馈" : "已提交" : this.state.typeid1 ? (<span>已完成<span className="blue">  得分: {this.state.typeid1.score}</span>  </span>) : "" 
                :
                 "未提交"
                }
              </span>
            </li>
            <li  onClick={() => this.jumppages(2)}>
              <span className="learningplan_left">沙龙1-1B</span>
              <span className="learningplan_right">
                {this.state.typeid2 ?
                  this.state.typeid2.score <= 0 ? this.state.typeid2userworkreply ? "已反馈" : "已提交" : this.state.typeid2 ? (<span>已完成<span className="blue">  得分: {this.state.typeid2.score}</span>  </span>) : ""
                  :
                  "未提交"
                }
              </span>
            </li>
            <li  onClick={() => this.jumppages(3)}>
              <span className="learningplan_left">沙龙1-2</span>
              <span className="learningplan_right">
                {this.state.typeid3 ?
                  this.state.typeid3.score <= 0 ? this.state.typeid3userworkreply ? "已反馈" : "已提交" : this.state.typeid3 ? (<span>已完成<span className="blue">  得分: {this.state.typeid3.score}</span>  </span>) : ""
                  :
                  "未提交"
                }
              </span>
            </li>
          </ul>
          <ul  className={this.state.thisnum.indexOf("2,") != -1 ? "learningplan_userlist show" : "learningplan_userlist"}>
            <div className={this.state.thisnum.indexOf("2,") != -1 ? "learningplan_userlist_title active" : "learningplan_userlist_title"} onClick={() => this.changepage(2)}>
              <span className="learningplan_left">沙龙2</span>
              <span className="learningplan_middle">1/3</span>
              <span className="learningplan_right icon"><img src={require("../../static/images/plan_arrow.png")} alt="" /></span>
            </div>
            <li  onClick={() => this.jumppages(4)}>
              <span className="learningplan_left">沙龙2-1A</span>
              <span className="learningplan_right">
                {this.state.typeid4 ?
                  this.state.typeid4.score <= 0 ? this.state.typeid4userworkreply ? "已反馈" : "已提交" : this.state.typeid4 ? (<span>已完成<span className="blue">  得分: {this.state.typeid4.score}</span>  </span>) : ""
                  :
                  "未提交"
                }
                </span>
            </li>
            <li  onClick={() => this.jumppages(5)}>
              <span className="learningplan_left">沙龙2-1B</span>
              <span className="learningplan_right">
                {this.state.typeid5 ?
                  this.state.typeid5.score <= 0 ? this.state.typeid5userworkreply ? "已反馈" : "已提交" : this.state.typeid5 ? (<span>已完成<span className="blue">  得分: {this.state.typeid5.score}</span>  </span>) : ""
                  :
                  "未提交"
                }
                </span>
            </li>
            <li  onClick={() => this.jumppages(6)}>
              <span className="learningplan_left">沙龙2-2</span>
              <span className="learningplan_right">

                {this.state.typeid6 ?
                  this.state.typeid6.score <= 0 ? this.state.typeid6userworkreply ? "已反馈" : "已提交" : this.state.typeid6 ? (<span>已完成<span className="blue">  得分: {this.state.typeid6.score}</span>  </span>) : ""
                  :
                  "未提交"
                }
              </span>
            </li>
          </ul>
          <ul  className={this.state.thisnum.indexOf("3,") != -1 ? "learningplan_userlist show" : "learningplan_userlist"}>
            <div className={this.state.thisnum.indexOf("3,") != -1 ? "learningplan_userlist_title active" : "learningplan_userlist_title"} onClick={() => this.changepage(3)}>
              <span className="learningplan_left">沙龙3</span>
              <span className="learningplan_middle">1/3</span>
              <span className="learningplan_right icon"><img src={require("../../static/images/plan_arrow.png")} alt="" /></span>
            </div>
            <li  onClick={() => this.jumppages(7)}>
              <span className="learningplan_left">沙龙3-1A</span>
              <span className="learningplan_right">

                {this.state.typeid7 ?
                  this.state.typeid7.score <= 0 ? this.state.typeid7userworkreply ? "已反馈" : "已提交" : this.state.typeid7 ? (<span>已完成<span className="blue">  得分: {this.state.typeid7.score}</span>  </span>) : ""
                  :
                  "未提交"
                }
              </span>
            </li>
            <li  onClick={() => this.jumppages(8)}>
              <span className="learningplan_left">沙龙3-1B</span>
              <span className="learningplan_right">

                {this.state.typeid8 ?
                  this.state.typeid8.score <= 0 ? this.state.typeid8userworkreply ? "已反馈" : "已提交" : this.state.typeid8 ? (<span>已完成<span className="blue">  得分: {this.state.typeid8.score}</span>  </span>) : ""
                  :
                  "未提交"
                }
              </span>
            </li>
            <li  onClick={() => this.jumppages(9)}>
              <span className="learningplan_left">沙龙3-2</span>
              <span className="learningplan_right">

                {this.state.typeid9 ?
                  this.state.typeid9.score <= 0 ? this.state.typeid9userworkreply ? "已反馈" : "已提交" : this.state.typeid9 ? (<span>已完成<span className="blue">  得分: {this.state.typeid9.score}</span>  </span>) : ""
                  :
                  "未提交"
                }
              </span>
            </li>
          </ul> */}
        </div>
        <Footer></Footer>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    labellist: state.common_labellist,
    active_two: state.active_two,
    common_search: state.common_search,
    userinfo: state.userinfo
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    change_active_two(data) {
      dispatch(change_active_two(data))
    }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LearningPlanadminuserlist))
