import React, { useState, useEffect,useRef,useReducer } from 'react'
import Search from '../Search'
import CourseItem from '../CourseItem'
import './index.css'
import ReconnectingWebSocket from 'reconnecting-websocket'
import $ from 'jquery'
import groupDetail from '../..'

let socket = '';
    
let newsmessage=[];

let  timer = '';
const chatRoomState = {
  onlinelist: [
  ],
};
let socketstate = false;

const chatRoomReducer = (state, action) => {
  // 新加入的数据
  return {
    ...state,
    ...action.state,
  };
};


const IndexComponent = (props) => {



  useEffect(() => {
    console.log(22222)
    console.log("props.usertypes",props.usertypes)


  }, [props.usertypes])


  console.log(props)
  console.log(2123123123)
  let [groupChatCourseShareOpen, setGroupChatCourseShareOpen] = useState(false)
  
  let [imgstyle,setImgstyle] = useState({
    width:"",
    height:"",
    //imgurl:"https://shlinfo.oss-cn-shanghai.aliyuncs.com/static/upload/20220805/7bcd05617fd5cce49be4f619a3c60f01.jpg",
    imgurl:"",
  })
  // let [onlinelist, setOnlinelist] = useState([])
  let onlinelist = useRef([]);

  const [state, dispatch] = useReducer(chatRoomReducer, chatRoomState);
  // let [socketstate, setSocketstate] = useState(false)


  useEffect(() => {

  })
  useEffect(() => {
    console.log("useEffect")
    console.log(props.usertypes)
    fetch(
      "https://shl-info.cn/front/Groupchatroom/getGroupChatRecord?userid=" +
      localStorage.getItem('userid') +
      "&chatcode=" +
      props.groupid ,{
        headers:{
          'token':localStorage.getItem('token') 
        }
      }
    )
      .then(data => {
        return data.json();
      })
      .then(res => {
        console.log('test11',res)
        if(res.code==2000){
          // setOnlinelist(res.data)
         
          onlinelist.current = res.data
          
        dispatch({
          state:{
            onlinelist:res.data
          }
        })
        setTimeout(()=>{
          $('.groupChatData').scrollTop("999999");
        },500)
          // setState({
          //   onlinelist:res.data
          // })
        }
      });

      // groupChatData
    if(props.groupid && props.usertypes!=''){
      getsocket();
      changesocket(props.groupid);
    }
     
    console.log(props.groupid,props.usertypes)
    console.log("props.groupid")
    
    
  }, [props.groupid,props.usertypes])
  // useEffect(() => {
  //   console.log(props.onlinelist)
  //   onlinelist.current = props.onlinelist
  // }, [props.onlinelist])

  let changeuserstate = (type,userid) =>{
    //console.log(type,index)
    fetch(
      "https://shl-info.cn/front/groupchat/setGroupUserVerify",{
        method: 'POST',
        body: JSON.stringify({
          "chatcode": props.groupid,
          "userid": localStorage.getItem('userid'),
          "groupuserid": userid,
          "verify": type,
        }),
        headers:{
          'content-type': 'application/json',
          'token':localStorage.getItem('token')
        },
        header: {
          'content-type': 'application/json',
          'token':localStorage.getItem('token')
        },
      }
    )
      .then(data => {
        return data.json();
      })
      .then(res => {
        console.log(res)
        if(res.code==2000){
         
        }
      });



    // front/groupchat/setGroupUserVerify
  }


  let allplyok = (item,index) =>{
    console.log("applyok")
    changeuserstate(1,item.userid)
    let talkarr = onlinelist.current;
    talkarr[index]['applystate']=1;
    dispatch({
      state:{
        onlinelist:talkarr
      }
    })
  }
  let allplyno = (item,index) =>{
    changeuserstate(2,item.userid)
    let talkarr = onlinelist.current;
    talkarr[index]['applystate']=2;
    dispatch({
      state:{
        onlinelist:talkarr
      }
    })
        // props.setOnlinelist(talkarr)

       


    // props.setOnlinelist(talkarr)
  }

  // useEffect(() => {
  //   getsocket();
  // }, [props.onlinelist])
  let godetail = (id,iscs) =>{
    console.log(11111)
    let csstate=iscs?iscs:'';
    props.history.push('/coursedetails?type='+id+"&iscs="+csstate)
  }
  

  let applyuserlist = (item,index) =>{
    console.log(item.applystate)
    return (
     <div className="applyuserlist " index={item.applystate}>
         <div className="applyuserlist_name">{item.username}申请加入组群</div>
         <div className='applyuserlist_box '>
           {item.applystate?null:<div className='applyuserlist_left' onClick={()=>allplyok(item,index)}>同意</div>}
           {item.applystate?null:<div className='applyuserlist_right' onClick={()=>allplyno(item,index)}>拒绝</div>}
           {item.applystate?<div>{item.applystate==1?"已通过":"已拒绝"}</div>:null}
         </div>
     </div>
   )
 }


  let groupChatItemCourse = (item) => {

    console.log(11111)
    return (
      <div className="groupChatItemCourse" onClick={()=>godetail(item.id,item.iscs)}>
        <CourseItem item={item} >
          
          
        </CourseItem>
      </div>
    )
  }
  
  let groupChatItemText = (text) => {
    return (
      <div className="groupChatItemTextView">
        <div className="groupChatItemText">{text}</div>
      </div>
    )
  }
  
  let groupChatItemImg = (text) => {
    return (
      <div className="groupChatItemTextView">
        <div className="groupChatItemImg">
          <img src={text} onClick={()=>bigimglayer(text)} />
        </div>
      </div>
    )
  }

  let groupChatItemLeft = (item,index) => {

    let ids =item.messageid?item.messageid:item.id
    return (
      <div className="groupChatItem left" id={ids}>
        <div className="groupChatItemUser">

        <img src={item.avatarurl ?item.avatarurl:require('../../../../static/images/icon-portrait.png')} alt="" className="groupChatItemUserAvatar" />
          {/* <img src={require('../../../../static/images/icon-portrait.png')} alt="" className="groupChatItemUserAvatar" /> */}
        </div>
        <div className="groupChatItemContent">
       {props.usertypes==2 ||  props.usertypes==1 || props.usertypes==4? <div className="groupmessagedel lefticon" onClick={()=>closemessage(ids)}>X</div>:null}
          <div className="groupChatItemUserName">{item.username?item.username:''}</div>

          {item.msgtype==4?groupChatItemCourse(JSON.parse(item.text)):item.msgtype==2?groupChatItemImg(item.text):groupChatItemText(item.text) }


          {/* { groupChatItemText() }
          { groupChatItemCourse() } */}
        </div>
      </div>
    )
  }


  let gosendmessagebycourse = (data) =>{
    console.log(data)

    let arr = {
      messagetype: "messagegroup",
      user_id: localStorage.getItem('userid'),
      type_id: "1", //类型 :自己
      username: localStorage.getItem('names'), // 姓名
      text: JSON.stringify(data),
      groupcode: props.groupid,
      type:4,
      avatar_url: localStorage.getItem('headimg')
    }
    socket.send(JSON.stringify(arr));
  }
  let sendtmpmessage = (item,index) =>{
    console.log(item,index)
    let talkarr = onlinelist.current;
    if(talkarr[index]['issend']==1){
      return;
    }

    let ids= item.id?item.id:item.messageid;
    talkarr[index]['issend']=1;
    dispatch({
      state:{
        onlinelist:talkarr
      }
    })

    fetch(
      "https://shl-info.cn/front/groupchat/sendMessageForGroup",{
        method: 'POST',
        body: JSON.stringify({
          "chatcode": props.groupid,
          "userid": localStorage.getItem('userid'),
          "id":ids
        }),
        headers:{
          'content-type': 'application/json',
          'token':localStorage.getItem('token')
        },
        header: {
          'content-type': 'application/json',
          'token':localStorage.getItem('token')
        },
      }
    )
      .then(data => {
        //return data.json();
      })
      .then(res => {
        console.log(res)
      })


  }
  let groupChatItemRight = (item,index) => {
    
    let ids =item.messageid?item.messageid:item.id
    return (
      <div className="groupChatItem right" id={ids}>
        <div className="groupChatItemContent">
          {props.usertypes==2 ||  props.usertypes==1 || props.usertypes==4? <div className="groupmessagedel" onClick={()=>closemessage(ids)}>X</div>:null}
          {/* <div className="groupChatItemUserName">liuce</div> */}
          {item.msgtype==4?groupChatItemCourse(JSON.parse(item.text)):item.msgtype==2?groupChatItemImg(item.text): groupChatItemText(item.text) }
          {/* { groupChatItemText() }
          { groupChatItemCourse() } */}

          {props.usertypes==2 ||  props.usertypes==1 || props.usertypes==4? <div className={item.issend?"sendmessage active":"sendmessage"} onClick={()=>sendtmpmessage(item,index)}><span></span>模板推送</div>:null}
        
        </div>

        <div className="groupChatItemUser">
          <img src={item.avatarurl ?item.avatarurl:require('../../../../static/images/icon-portrait.png')} alt="" className="groupChatItemUserAvatar" />
        </div>
      </div>
    )
  }
  let closemessage = (ids) =>{
    console.log(ids)

    let arr = {
      messagetype: "messagegroup",
      user_id: localStorage.getItem('userid'),
      type_id: "1", //类型 :自己
      username: localStorage.getItem('names'), // 姓名
      text: ids,
      groupcode: props.groupid,
      type:6,
      avatar_url: localStorage.getItem('headimg')
    }
    socket.send(JSON.stringify(arr));
  }

  let goshare = (e,item) =>{
    console.log(item)
    console.log(e)
    gosendmessagebycourse(item)
    setGroupChatCourseShareOpen(false)
    e.stopPropagation();
  }

  let groupChatCourseItem = (item,index) => {
    return (
      <div className="groupChatCourseItem" key={index}>
        <CourseItem item={item}>
          <div className="groupChatCourseShareButton" onClick={(e)=>goshare(e,item)}>分享</div>
        </CourseItem>
      </div>
    )
  }
  
  let bigimglayer = (imgurl)=>{
    console.log(imgurl)

    var img = document.createElement("img");
    img.src = imgurl;
    img.onload = () => {
    // 为什么要写 onload  是因为要等他加载完之后才能获取到图片宽高
    if(img.naturalWidth>img.naturalHeight){
      setImgstyle({
        width:"100%",
        height:"auto",
        imgurl:imgurl,
      })
    }else{
      setImgstyle({
        width:"auto",
        height:"100%",
        imgurl:imgurl,
      })
    }
        console.log(img.naturalWidth,img.naturalHeight);   //  2064,4608
    };
  }


  

  let [inputseachtext, setInputseachtext] = useState('')

  let [seachstate, setSeachstate]= useState(false)


  let [seachlist, setSeachlist]= useState([])

  let gosearch = ()=>{
    
    // setInputtext()
    fetch(
      "https://shl-info.cn/front/groupchat/getCourseListByName?userid=" +
      localStorage.getItem('userid') +
      "&packname=" +
      inputseachtext+
      "&chatcode="+props.groupid+"&requesttype=1" ,{
        headers:{
          'token':localStorage.getItem('token') 
        }
      }
    )
      .then(data => {
        return data.json();
      })
      .then(res => {
        console.log(res)
        if(res.code==2000){
          setSeachstate(true)
          setSeachlist(res.data)
          // this.setState({
          //   seachlist:res.data,
          //   isseach:true
          // })
        }
      });

  }


  let groupChatCourseShare = () => {
    return (
      <div className="groupChatCourseShareView">
        <div className="groupChatCourseShare ">
          <div className="groupChatCourseShareHead">
            <Search inputtext={inputseachtext} setSeachstate={setSeachstate} setInputtext ={setInputseachtext} gosearch = {gosearch}></Search>
            <img src={require('../../../../static/images/icon8.png')} alt="" className="groupChatCourseShareClose" onClick={() => setGroupChatCourseShareOpen(false)} />
          </div>

          <div className="groupChatCourseList scrollbar">
            <div>
            {seachlist.length<=0 && seachstate?<div className="groupnocontent">暂无内容</div>:null}
            {seachlist.map((item,index)=>{
              return groupChatCourseItem(item,index);
            })}
           </div>
          </div>
        </div>
      </div>
    )
  }
  
  let changesocket  = (ids) =>{
    
   
    timer =  setTimeout(function(){
        if(ids && !socketstate){
            changesocket(ids);
            getsocket();
        } 
      },3000);
  }
      
  
  //聊天室组件
  let getsocket = () => {
      // socket 
      if(socket){
        console.log(socket)
        // socket.close(); 
        // socket.dispose();
      }
    console.log(socket)
    //if(!socket){
      socket = new ReconnectingWebSocket(
        "wss://shl-info.cn/webwsthi/"
      );
    //}

    console.log(socket)
    console.log("socket")
    socket.onopen = () => {
      //console.log("open");
      socket.send(
        JSON.stringify({
          messagetype: "linkgroup",
          user_id: localStorage.getItem('userid'),
          type_id: '1',
          username: localStorage.getItem("names"),
          groupcode: props.groupid,
          avatar_url: localStorage.getItem("headimg"),
        })
      );
    };
    socket.onmessage = message => {
      console.log('message',props.usertypes)
      let talkarr = onlinelist.current;
      let newdata = JSON.parse(message.data);

      if (newdata.type === "success") {
        console.log("success",newdata)
        socketstate=true;
        // changesocket=null
        // clearTimeout(changesocket)
      }
      if (newdata.type === "message") {
        if (newdata.user_id === localStorage.getItem('userid')) {
          newdata.type_id = '3';
        }
        talkarr.push(newdata);
        props.setOnlinelist(talkarr)
        // setState({
        //   onlinelist: talkarr
        // });
      }else if(newdata.type === "applygroup"  && (props.usertypes==2 ||  props.usertypes==1 || props.usertypes==4)  ){
        if (newdata.user_id === localStorage.getItem('userid')) {
          newdata.type_id = '3';
        }
        newdata.msgtype=5;
        talkarr.push(newdata);
        // props.setOnlinelist(talkarr)
        onlinelist.current = talkarr

        dispatch({
          state:{
            onlinelist:talkarr
          }
        })
        setTimeout(function(){
          $('.groupChatData').scrollTop("999999");
        },100)


      } else if (newdata.type === "messagegroup" ){
        console.log(newdata)
        if(newdata.msgtype==6){
          $('#'+newdata.text).remove();
          return
        }
        if (newdata.user_id === localStorage.getItem('userid')) {
          newdata.type_id = '3';
        }
        if(newdata.msgtype==4){
          //newdata.text = JSON.parse(newdata.text);
        }
        talkarr.push(newdata);
        // props.setOnlinelist(talkarr)
        onlinelist.current = talkarr

        dispatch({
          state:{
            onlinelist:talkarr
          }
        })
        if (newdata.userid === localStorage.getItem('userid')) {
          setTimeout(function(){
            $('.groupChatData').scrollTop("999999");
          },100)
        }
      
        fetch(
          "https://shl-info.cn/front/Groupchatroom/setUserReadLog",{
            method: 'POST',
            body: JSON.stringify({
              "chatcode": props.groupid,
              "userid": localStorage.getItem('userid'),
              "messageid": newdata.messageid,
            }),
            headers:{
              'content-type': 'application/json',
              'token':localStorage.getItem('token')
            },
            header: {
              'content-type': 'application/json',
              'token':localStorage.getItem('token')
            },
          }
        )
          .then(data => {
            return data.json();
          })
          .then(res => {
            console.log(res)
            if(res.code==2000){
              
            }
          })
      }
    };
    socket.onclose = () => {
      //console.log("close");
    };
    // socket.onerror = ()  {
    //   // console.log('发生异常了');
    //   // reconnect(wsUrl);
    // };
  };

  let [inputtext, setInputtext] = useState('')

  let [onmessagestate, setOnmessagestate] = useState('')


  let valueChange = (e) =>{
    setInputtext(e.target.value)
  }
  let handleEnterKey = (e) => {
      if(e.keyCode === 13){ //主要区别就是这里，可以直接获取到keyCode的值
        //alert(2222)
      }
  }
  let submittext = ()=>{
    if(inputtext){
      let arr = {
        messagetype: "messagegroup",
        user_id: localStorage.getItem('userid'),
        type_id: "1", //类型 :自己
        username: localStorage.getItem('names'), // 姓名
        text: inputtext,
        groupcode: props.groupid,
        type:1,
        avatar_url: localStorage.getItem('headimg')
      }
      socket.send(JSON.stringify(arr));
      setInputtext('')
    }
    console.log(inputtext)
  }
  let gettimes = (time)=>{
    return  <div className="groupChatTime">
      <div className="groupChatTimeText">{time}</div>
    </div>
  }

  let imgupload = (e) => {
    console.log(e)
    let that = this
    var imgfile = document.querySelector('#imgFile');//获取input
    var file = imgfile.files[0];
   
    var formFile = new FormData();

    let userid = (localStorage.getItem('userid'));
    let token = (localStorage.getItem('token'));
    formFile.append("uploadFile", file); //加入文件对象
    $.ajax({
      type: "post",
      url: "https://shl-info.cn/front/frontc/adduserworkspc?userid=" + userid ,
      data: formFile,
      //是否预处理,默认值为true,这里改成false
      processData: false,
      //是否设置内容,默认值为true,这里改成false
      contentType: false,
      headers:{'token':localStorage.getItem('token')},
      success: function (res) {
        var backres = JSON.parse(res);
        let arr = {
          messagetype: "messagegroup",
          user_id: localStorage.getItem('userid'),
          type_id: "1", //类型 :自己
          username: localStorage.getItem('names'), // 姓名
          text: backres.pic,
          groupcode: props.groupid,
          type:2,
          avatar_url: localStorage.getItem('headimg')
        }
        console.log(arr)
        socket.send(JSON.stringify(arr));

       
      },
      error: function (data) {
      }
    });
  }

  return (
    <div className="groupChat">
      {imgstyle.imgurl?<div className="bigimgurl" onClick={()=> setImgstyle({
        width:"",
        height:"",
        imgurl:"",
      })}>
        <img src={imgstyle.imgurl} style={{width:imgstyle.width,height:imgstyle.height}} />
      </div>:null}
      <div className="groupChatData scrollbar groupinfo">
       
        <div className="grouptipsbox">
          <div className='grouptipsboxtitle'>本群公告</div>
          <div className='grouptipsboxdesc'>
            <p>{props.groupinfo.notice}</p>
          </div>
        </div>

        
        {state.onlinelist.map((item,index)=>{
          return  <div>
                {item.createtime?gettimes(item.createtime):null}
                {item.msgtype!=6 ?item.userid==localStorage.getItem('userid') ?item.msgtype==5?applyuserlist(item,index):groupChatItemRight(item,index):item.msgtype==5?applyuserlist(item,index) : groupChatItemLeft(item):null }
          </div>
                
            })}
        {/* { groupChatItemLeft() }
        { groupChatItemRight() } */}
      </div>
      <div className="groupChatSub">
        <div className="grouptextarea">
          <textarea className="groupChatInput" placeholder="输入内容..."
            onChange={(e)=>valueChange(e)}
            value={inputtext}
            onKeyDown={handleEnterKey}
          ></textarea>
        </div>
        <div className="groupChatFunc">
          <div className="groupInputimg">
            <input type="file" id="imgFile" name='img' accept=".jpg,.png,.jpeg" onChange={(e) => imgupload(e)} />
          </div>
          <div className="groupChatButton" onClick={() => setGroupChatCourseShareOpen(true)} >分享课程</div>
          
          <div className="groupChatButton yellow" onClick={submittext}>发送</div>
        </div>
      </div>
      

      { groupChatCourseShareOpen && groupChatCourseShare() }
    </div>
  )
};

export default IndexComponent;
