import React, { Component } from 'react'
// 组件
import Header from '../../components/header'
import Footer from '../../components/footer'
import SelectBox from '../../components/selectbox'
import PostList from '../../components/postList'
import TalkList from '../../components/talkList'

import './index.css'

class HomePlaying extends Component {
  constructor() {
    super()
    this.changeSelect = this.changeSelect.bind(this)
  }

  static defaultProps = {
    selectlist: [
      {
        id: 1,
        type: 'posts',
        title: '帖子',
        icon: require('../../static/images/playing_icon_09.png'),
        icon_act: require('../../static/images/playing_icon_09_act.png')
      },
      {
        id: 0,
        type: 'talk',
        title: '话题',
        icon: require('../../static/images/playing_icon_10.png'),
        icon_act: require('../../static/images/playing_icon_10_act.png')
      }
    ]
  }
  // 切换导航
  changeSelect(id) {
    var keywords = '';
    if (id == 'posts') {
      keywords = '帖子';
    } else {
      keywords = '话题';
    }
    window.addreadlogs(keywords)
    this.props.change_active_two(id)
  }

  componentDidMount() {
    if (this.props.active_two !== 'posts') {
      this.props.change_active_two('posts')
    }
    if(window.location.href.indexOf('topic=1')>=0){
      this.changeSelect("talk")
    }
    // this.props.change_active_two("talk")
  }
  componentWillUnmount() {

  }
  render() {
    // 切换导航
    let selectlist = this.props.selectlist.map((item) => {
      return (
        <SelectBox
          key={item.id}
          _item={item}
          active={this.props.active_two}
          change={this.changeSelect}>

        </SelectBox>
      )
    })
    // 内容更换
    let container = null;
    if (this.props.active_two === 'posts') {
      container = (<PostList history={this.props.history}></PostList>)
    } else if (this.props.active_two === 'talk') {
      container = (<TalkList></TalkList>)
    }
    return (
      <div className='playingpage homedoctorinteraction'>
        <Header active='6'  isbbs={true}  ></Header>
        <div className='page-navselect'>
          <div className="wrap overflow">
            <ul className="navselect fl">
              {selectlist}
            </ul>
            <div className="select-contain fr">
              {container}
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    )
  }
}

export default HomePlaying
