import React from 'react'

import './index.css'
import { Link } from 'react-router-dom'

function Contactuslayer(props) {
    return (
        <div className="bbslayer groupinfo">
            <div className="bbslayer_box">
                <h2 className="common-masktitle">
                技术热线
                    <span
                        className="common-maskclose cursor"
                        onClick={()=>props.closelayer()}
                       ></span>
                </h2>

                <div className='bbslayer_textbox'>
                    <div className="bbslayer_contacttext ">若您在群组中遇到任何问题，您可以直接拨打电话：15033146565<br></br>也可以发送邮件至zefan.xu.ext@siemens-healthineers.com</div>
                </div>
            </div>
        </div >
    )
}

export default Contactuslayer
