import React, { Component } from 'react'
// 组件
import Header from '../../components/header'
import Footer from '../../components/footer'
import PersonImg from '../../components/personimg'
import PersonTitle from '../../components/persontitle'
import HistoryList from '../../components/historylist'

import './index.css'
import { $CombinedState } from 'redux'
import { textChangeRangeIsUnchanged } from 'typescript'

class Myregistration extends Component {
  constructor() {
    super()
    this.state = {
      myregistration:[],
      page:0,
      loadingstate:false,
      isend:false,
    }
  }
  onScrollHandle(event) {
    const clientHeight = event.target.clientHeight
    const scrollHeight = event.target.scrollHeight
    const scrollTop = event.target.scrollTop
    const isBottom = (clientHeight + scrollTop === scrollHeight)
    console.log('is bottom:' + isBottom)
    if(isBottom ){
      this.getcourselist();
    }
  }
  getcourselist = ()=>{
    if(this.state.loadingstate || this.state.isend){
      return
    }
    fetch(
      "https://shl-info.cn/front/livevideo/getPrivateCourseList?userid=" +
      localStorage.getItem('userid') +"&page=" + this.state.page+
      "&changetype=" +
      localStorage.getItem('changetype'),{
        headers:{
          'token':localStorage.getItem('token') 
        }
      }
    )
    .then(data => {
      return data.json();
    })
    .then(res => {
      console.log(res.data)
      
      if(res.code!=2000){
        return;
      }

      if(res.data.length<=0){
        this.setState({
          isend:true,
        })
        return;
      }
      let page=this.state.page+1;
      let pagelist = this.state.myregistration;
      for(var i=0;i<res.data.length;i++){
        pagelist.push(res.data[i])
      }
      this.setState({
        myregistration:pagelist,
        page:page,
        loadingstate:false,
      })


      // this.setState({
      //   myregistration:res.data
      // })
    });
  }
  componentDidMount() {
    this.props.change_active_two('myregistration')
    console.log("this.contentNode",this.contentNode)

    document.body.addEventListener('scroll', this.onScrollHandle.bind(this))
    this.getcourselist()
   
  }
  componentWillUnmount() {
    
  }
  render() {
    let list = null;
    if (this.state.myregistration){
      list = this.state.myregistration.map((item) => {
        return (
          <HistoryList key={item.id} _item={item}></HistoryList>
        )
      })
    }
    return (
      <div className='personpage'  ref='contentNode'>
        <Header active='4'></Header>
        <PersonImg type="no"></PersonImg>
        <PersonTitle title="我的报名"></PersonTitle>
        <div className="wrap personal-margin">
          {list}
        </div>
        <Footer></Footer>
      </div>
    )
  }
}

export default Myregistration
