import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { Input, Select } from 'antd';
import classnames from 'classnames';
import Iscroll from 'iscroll/build/iscroll-probe';
import axios from 'axios';
import PostItem from '../postItem';
import BraftEditor from 'braft-editor'; // 引入编辑器组件

import { ContentUtils } from 'braft-utils'
import 'braft-editor/dist/index.css'; // 引入编辑器样式
import './index.css';
import $ from 'jquery';

const { Option } = Select;
let num = 0;
function PersonImg(props) {
    let userid = localStorage.getItem('userid'), token = localStorage.getItem('token');
    let [talkList, setTalkList] = useState([])
    let [courseList, setCourseList] = useState([])
    let [formState, setformState] = useState(0) // 0：草稿箱 1：待审核 2：通过 3：拒绝
    let [textlist, setTextlist] = useState('adsfasdfasdfad')
    let [showloading, setShowloading] = useState(false)
    let [form, setForm] = useState({
        topic_id: {
            value: '',
            tip: ''
        },
        title: {
            value: '',
            tip: ''
        },
        data: {
            value: '',
            tip: ''
        },
        course_id: {
            value: '',
            tip: ''
        },
        // state: 0, // 0：草稿箱 1：待审核 2：通过 3：拒绝
    })


    // 空值效验: 属性值不能为空
    let emptyCheck = {
        topic_id: { tip: '请选择话题' },
        title: { tip: '请输入标题' },
        // data: { tip: '请输入内容' },
        //course_id: { tip: '请选择关联课程' },
    }
    // 专属效验(正则): 邮箱 手机号 
    let exclusiveCheck = {
        // company_email: { tip: 'Email填写错误', func: (str) => { let re = /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/; return re.test(str) } },
        // tel: { tip: '号码填写错误', func: (str) => { let re = /^1\d{10}$/; return re.test(str) } }
        data: { tip: '请输入内容', func: (str) => { return (typeof str !== 'string' && str.toHTML() !== '<p></p>') || (typeof str === 'string' && str.length !== 0) } }
    }
    let selectCheck = {
        // tel: { tip: '' },
    }
    const getUrlkey = (url) => {
        var params = {};
        var urls = url.split("?");                  //console.log('1_分割url:', urls)
        var arr = urls[1].split("&");               //console.log('2_分割urls[1]:', arr)
        for (var i = 0, l = arr.length; i < l; i++) {
            var a = arr[i].split("=");                //console.log('3_遍历 arr 并分割后赋值给a:', a[0], a[1])
            params[a[0]] = a[1];                      //console.log('4_a给params对象赋值:', params)
        }                                           //console.log('5_结果:', params)
        return params;
    }

    const gethasactive = (ids) => {
        //     if(window.location.href.indexOf('?')>=0){
        //         let url=getUrlkey(window.location.href);
        //             ids=url.id;
        //         console.log(form.course_id.value)
        //    }else if(ids){
        //         ids=ids;
        //    }
        ids = ids;
        fetch(
            "https://shl-info.cn/front/bbs/articleDetail_all/id/" + ids + "?userid=" + localStorage.getItem('userid'), {
            headers: {
                'token': localStorage.getItem('token')
            }
        }
        )
            .then(data => {
                return data.json();
            })
            .then(res => {
                form['topic_id'].value = res.topic_id
                form['title'].value = res.title
                form['data'].value = res.data
                console.log(form)
                setTimeout(function () {

                }, 1000)
                setForm({
                    ...form,
                    ['topic_id']: form['topic_id'],
                    ['title']: form['title'],
                    ['data']: form['data']
                })
                fetch(
                    "https://shl-info.cn/front/bbs/courseList?userid=" + localStorage.getItem('userid'), {
                    headers: {
                        'token': localStorage.getItem('token')
                    }
                }
                )
                    .then(data => {
                        return data.json();
                    })
                    .then(res1 => {
                        console.log(res)
                        setCourseList(res1)
                        for (var i = 0; i < res1.length; i++) {
                            //console.log(i)
                            if (res.course) {
                                if (res1[i]['id'] == res.course.id) {
                                    // setCourseList
                                    let newarr = courseList;
                                    //newarr.push(res1[i])
                                    //setCourseList(newarr)
                                    form.course_id.value = res.course.id
                                    setForm({
                                        ...form,
                                        course_id: form.course_id
                                    })
                                }
                            }
                        }
                        console.log(res1)
                    })
            })
    }
    useEffect(() => {
        console.log(props)
        console.log("321123props")
        if (window.location.href.indexOf('?') >= 0) {

        }
        if (props.ids > 0) {
            gethasactive(props.ids)
        }
        init()
    }, [props.ids])

    let init = () => {
        getTalkList()
    }

    // form 校验
    let formDataCheck = () => {
        let res = true
        // console.log(res)
        let exclusiveCheckKeys = Object.keys(exclusiveCheck)
        for (let key in form) {
            // 非空检测
            if (emptyCheck[key] && form[key].value.length === 0) {
                formDataTip(key, emptyCheck[key]) // 提示
                res = false
                continue
            }
            // 选填效验 (不填不效验, 填写就效验) 
            if (selectCheck[key]) {
                if (form[key].value.length === 0) {
                    formDataTipClone(key)
                    continue
                }
            }
            // 专属效验(正则)
            if (exclusiveCheckKeys.indexOf(key) !== -1) {
                let checkRes = exclusiveCheck[key].func(form[key].value)
                console.log('typeof form[key].value')
                console.log(typeof form[key].value)
                console.log(form[key].value)
                if (!checkRes) {
                    formDataTip(key, exclusiveCheck[key]) // 提示
                    res = false
                    continue
                }
            }
            formDataTipClone(key)
        }
        return res
    }


    // 输入框效验 失去焦点后效验
    let blurInputItem = (key) => {
        if (emptyCheck[key] && form[key].value.length === 0) {
            formDataTip(key, emptyCheck[key]) // 提示
            return false
        }
        // 选填效验 
        if (selectCheck[key]) {
            // 不填不效验 填写就效验
            if (form[key].value.length === 0) {
                formDataTipClone(key)
                return true
            }
        }
        // 专属效验(正则)
        if (exclusiveCheck[key]) {
            let checkRes = exclusiveCheck[key].func(form[key].value)
            console.log('------------- checkRes', checkRes)
            if (!checkRes) {
                formDataTip(key, exclusiveCheck[key]) // 提示
                return false
            }
        }
        formDataTipClone(key)
    }
    // form 提示
    let formDataTip = (key, tip) => {
        form[key].tip = tip.tip
        console.log(key, tip.tip)
        setForm({
            ...form,
            [key]: form[key]
        })
    }

    // 清除form提示
    let formDataTipClone = (key) => {
        form[key].tip = ''
        setForm({
            ...form,
            [key]: form[key]
        })
    }

    // 关联课程选择
    let selectRelationChange = (value) => {
        console.log(value)
        console.log('selectRelationChange', selectRelationChange)
        form.course_id.value = value
        setForm({
            ...form,
            course_id: form.course_id
        })
    }
    let selectRelationSearch = (value) => {
        if (value) {
            getCourseList(value)
        } else {
            setCourseList([])
        }
    }
    // 富文本输入
    let handleEditorChange = (attr, value) => {
        form[attr].value = value
        formDataRenew(attr) // 更新
    }
    // select选择
    let formSelectChange = (attr, value) => {
        form[attr].value = value
        formDataRenew(attr) // 更新
    }
    // input输入
    let formInputChange = (attr, e) => {
        form[attr].value = e.target.value
        formDataRenew(attr) // 更新
    }

    // form 数据更新
    let formDataRenew = (attr) => {
        setForm({
            ...form,
            [attr]: form[attr]
        })
    }
    // 获取话题列表
    let getTalkList = async () => {
        let res = await axios({
            method: 'get',
            headers: {
                "token": localStorage.getItem('token')
            },
            url: 'https://shl-info.cn/front/bbs/topicList?user_id=' + localStorage.getItem('userid'),
        })
        setTalkList(res.data)
    }

    // 获取关联课程列表
    let getCourseList = async (value) => {
        let res = await axios({
            method: 'get',
            headers: {
                "token": localStorage.getItem('token')
            },
            url: 'https://shl-info.cn/front/bbs/courseList',
            params: {
                title: value,
                user_id: localStorage.getItem('userid')
            }
        })
        console.log(res.data)
        setCourseList(res.data)
    }

    let subForm = async () => {
        console.log(props.history)
        console.log(form.data.value)
        if (!formDataCheck()) {
            return
        }
        // let post_id='';
        let post_id = props.ids ? props.ids : "";

        let url = 'https://shl-info.cn/front/bbs/createArticle?user_id=';
        if (post_id) {
            url = 'https://shl-info.cn/front/bbs/createArticle/article_id/' + post_id + '?user_id=';
        }
        $.ajax({
            type: "POST",
            url: url +
                localStorage.getItem('userid'),
            data: {
                "topic_id": form.topic_id.value,
                "course_id": form.course_id.value,
                "state": 1,
                "title": form.title.value,
                "data": form.data.value ? form.data.value.toHTML() : null,
            },
            dataType: "json",
            headers: { 'token': localStorage.getItem('token') },
            cache: false
        })
            .catch(error => {

                console.log(error);
            })
            .then(res => {
                console.log(res)
                if (res.code === 0) {
                    props.history.push('/HomeDoctorInteraction');
                    //this.props.history.push("/home")
                }
            });
    }


    let subSave = async () => {
        // if (!formDataCheck()) {
        //     return
        // }   
        // console.log(props.ids);
        // return false; 
        let post_id = props.ids ? props.ids : "";

        let url = 'https://shl-info.cn/front/bbs/createArticle?user_id=';
        if (post_id) {
            url = 'https://shl-info.cn/front/bbs/createArticle/article_id/' + post_id + '?user_id=';
        }
        $.ajax({
            type: "POST",
            url: url +
                localStorage.getItem('userid'),
            data: {
                "topic_id": form.topic_id.value,
                "course_id": form.course_id.value,
                "state": 0,
                "title": form.title.value,
                "data": form.data.value ? form.data.value.toHTML() : null,
            },
            dataType: "json",
            headers: { 'token': localStorage.getItem('token') },
            cache: false
        })
            .catch(error => {

                console.log(error);
            })
            .then(res => {
                console.log(res)
                if (res.code === 0) {
                    props.changeSelect("draft")
                }
            });
    }


    let talkOptionsDom = null
    console.log(talkList)
    if (talkList.length > 0) {
        talkOptionsDom = talkList.map((item, index) => {
            return <Option value={item.id} key={item.id}># {item.title}</Option>
        })
    }

    let courseOptionsDom = courseList.map((item, index) => {
        let closeSelectDom = <img className="selectItemImg" src={require('../../static/images/deleteicon.png')} style={{ width: '10px', height: '10px', margin: '27px 0 27px 27px', cursor: 'hand', cursor: 'pointer' }} onClick={(e) => { e.stopPropagation(); selectRelationChange('') }} alt="" />;
        return (
            <Option value={item.id} key={index} style={{ padding: 0 }}>
                <div className="selectOption" style={{ padding: '15px 20px', display: 'flex' }}>
                    <img className="selectItemImg" src={item.headimg} style={{ width: '120px', height: '66px' }} alt="" />
                    <div className="selectItemTitle" style={{ fontSize: '16px', color: '#1b1b1b', lineHeight: '66px', marginLeft: '20px', flex: 1 }}>{item.coursetitle}</div>
                    {form.course_id.value === item.id ? closeSelectDom : ''}
                </div>
            </Option>
        )
    })
    const changetext = (e) => {
        return BraftEditor.createEditorState('22222')
    }
    let newtext = null;
    console.log(form.data.value)
    console.log('form.data')
    if (num == 0 && form.data.value) {
        console.log(form.data)
        newtext = BraftEditor.createEditorState(form.data.value)
        //num=2;
    } else {
        newtext = BraftEditor.createEditorState(' ');
    }
    const videoupload = (e) => {
        console.log(e)
        let that = this
        var imgfile = document.querySelector('#videoFile');//获取input
        var file = imgfile.files[0];

        var formFile = new FormData();
        let userid = (localStorage.getItem('userid'));
        let token = (localStorage.getItem('token'));
        formFile.append("uploadFile", file); //加入文件对象
        formFile.append("name", file); //加入文件对象
        function progressHandlingFunction(event) {
            var loaded = Math.floor(100 * (event.loaded / event.total)); //已经上传的百分比
            console.log(loaded)
            $("#loadingimg").css("width", loaded + "%")
            $("#progress-bar").html(loaded + "%").css("width", loaded + "%");
        }

        function getname(length) {
            const data =
                ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "A", "B", "C", "D", "E", "F",
                    "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y",
                    "Z", "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r",
                    "s", "t", "u", "v", "w", "x", "y", "z"];
            let nums = "";
            for (let i = 0; i < length; i++) {
                const r = parseInt(Math.random() * 61, 10);
                nums += data[r];
            }
            return nums;
        }

        fetch("/front/api/upvideotooss")
            .then(data => {
                return data.json();
            })
            .then(res1 => {
                console.log(res1)

                var postlist = new FormData();
                let filename = new Date().getTime() + getname(6) + ".mp4";
                postlist.append("name", filename);
                postlist.append("key", res1.dir + filename);
                postlist.append("policy", res1.policy);
                postlist.append("OSSAccessKeyId", res1.accessid);
                postlist.append("success_action_status", 200);
                postlist.append("callback", res1.callback);
                postlist.append("signature", res1.signature);
                postlist.append("file", file);
                setShowloading(true)
                $.ajax({
                    type: "post",
                    url: res1.host,
                    processData: false,
                    contentType: false,
                    data: postlist,
                    success: function (res) {
                        setShowloading(false)
                        form.data.value = ContentUtils.insertMedias(form.data.value, [{
                            type: 'VIDEO',
                            url: res1.host + "/" + res1.dir + filename
                        }])
                        formDataRenew('data') // 更新
                    },
                    error: function (data) {
                        console.log('上传失败')
                        setShowloading(false)
                    },
                    xhr: function () {
                        let myXhr = $.ajaxSettings.xhr();
                        if (myXhr.upload) { //检查upload属性是否存在  
                            //绑定progress事件的回调函数  
                            myXhr.upload.addEventListener('progress', progressHandlingFunction, false);
                        }
                        return myXhr; //xhr对象返回给jQuery使用
                    }

                })
            })
        // $.ajax({
        //   type: "post",
        //   url: "https://shl-info.cn/front/frontc/adduserworkspc?userid=" + localStorage.getItem('userid') ,
        //   data: formFile,
        //   //是否预处理,默认值为true,这里改成false
        //   processData: false,
        //   //是否设置内容,默认值为true,这里改成false
        //   contentType: false,
        //   headers:{'token':localStorage.getItem('token')},
        //   success: function (res) {
        //       console.log(res)
        //     var backres = JSON.parse(res);

        //     // let addimg  = form.data.value 
        //     // formDataRenew(attr) // 更新


        //     console.log(ContentUtils)
        //     form.data.value = ContentUtils.insertMedias(form.data.value , [{
        //         type: 'VIDEO',
        //         url: backres.pic
        //       }])
        //     formDataRenew('data') // 更新
        //   },
        //   error: function (data) {
        //     //alert('提交失败');
        //   }
        // });
    }
    const imgupload = (e) => {
        // if(!form.data.value){
        //     newtext=BraftEditor.createEditorState(null)
        // }
        console.log(e)
        let that = this
        var imgfile = document.querySelector('#imgFile');//获取input
        var file = imgfile.files[0];

        var formFile = new FormData();
        let userid = (localStorage.getItem('userid'));
        let token = (localStorage.getItem('token'));
        formFile.append("uploadFile", file); //加入文件对象
        $.ajax({
            type: "post",
            url: "https://shl-info.cn/front/frontc/adduserworkspc?userid=" + localStorage.getItem('userid'),
            data: formFile,
            //是否预处理,默认值为true,这里改成false
            processData: false,
            //是否设置内容,默认值为true,这里改成false
            contentType: false,
            headers: { 'token': localStorage.getItem('token') },
            success: function (res) {
                console.log(res)
                var backres = JSON.parse(res);

                // let addimg  = form.data.value 
                // formDataRenew(attr) // 更新


                console.log(ContentUtils)
                form.data.value = ContentUtils.insertMedias(form.data.value, [{
                    type: 'IMAGE',
                    url: backres.pic
                }])
                formDataRenew('data') // 更新
            },
            error: function (data) {
                //alert('提交失败');
            }
        });
    }


    const uploadHandler = (param) => {

        console.log(param.target.files[0])
        if (!param.target.files[0]) {
            return false
        }
        console.log($("#inputfile").get(0).files[0])
        console.log($('#inputfile').val())

        // var reader=new FileReader();
        // //读取file文件
        // reader.readAsDataURL(file);
        // //读取成功后执行函数
        // reader.onload=function (ev) {

        // } 
        // this.setState({
        //   editorState: ContentUtils.insertMedias(this.state.editorState, [{
        //     type: 'IMAGE',
        //     url: URL.createObjectURL
        //   }])
        // })

    }

    const extendControls = [
        {
            key: 'antd-uploader',
            type: 'component',
            component: (
                <div>
                    <div className='inputbox imgbox'>
                        <button class="control-item button upload-button" data-title="插入图片">插入图片</button>
                        <input type="file" id="imgFile" name='img' accept=".jpg,.png,.jpeg" onChange={(e) => imgupload(e)} />
                    </div>
                    <div className='inputbox imgbox'>
                        <button class="control-item button upload-button" data-title="插入视频">插入视频</button>
                        <div className={showloading ? "loadingbox" : "none"}>
                            <div className="loadingtext">上传中</div>
                            <i id="loadingimg"></i>
                        </div>
                        <input type="file" id="videoFile" name='img' accept=".mp4" disabled={showloading ? "disabled" : false} onChange={(e) => videoupload(e)} />
                    </div>
                </div>
            )
        }
    ]
    const controls = ['bold', 'italic', 'underline', 'text-color', 'separator', 'link', 'separator']

    return (
        <section className="releasePost">
            <div className="releasePostHead"></div>
            <div className="releasePostBody">
                <div className="releasePostItem">
                    <div className="releasePostItemTitle">
                        话题
                        <div className="releasePostItemTips">{form['topic_id'].tip}</div>
                    </div>
                    <div className="releasePostItemContent">
                        <Select size="large" value={form.topic_id.value} onChange={(e) => formSelectChange('topic_id', e)} placeholder="请选择话题">
                            {talkOptionsDom}
                        </Select>
                    </div>
                </div>
                <div className="releasePostItem">
                    <div className="releasePostItemTitle">
                        标题：
                        <div className="releasePostItemTips">{form['title'].tip}</div>
                    </div>
                    <div className="releasePostItemContent">
                        <Input  value={form.title.value} size="large" placeholder="请输入标题" onChange={(e) => formInputChange('title', e)} />
                    </div>
                </div>
                <div className="releasePostItem">
                    <div className="releasePostItemTitle">
                        内容：
                        <div className="releasePostItemTips">{form['data'].tip}</div>
                    </div>
                    <div className="releasePostItemContent">
                        <BraftEditor
                            //value={ form.data }
                            //value={'1231312312312'}
                            //value= {form.data}
                            //defaultValue={textlist}
                            value={newtext}
                            onChange={(value) => handleEditorChange('data', value)}
                            extendControls={extendControls}
                        // controls={controls}
                        />
                    </div>
                </div>
                <div className="releasePostItem">
                    <div className="releasePostItemTitle">
                        关联课程：
                        <div className="releasePostItemTips">{form['course_id'].tip}</div>
                    </div>
                    <div className="releasePostItemContent">
                        <Select showSearch
                            value={form.course_id.value}
                            //value={props.options.filter(option => option.label === 'Some label')}
                            filterOption={false}
                            placeholder="请输入课程关键字"
                            className={classnames(form.course_id.value !== '' ? 'formRelation' : '')}
                            size="large"
                            onChange={selectRelationChange}
                            onSearch={selectRelationSearch}
                            showArrow={form.course_id.value === ''}
                            suffixIcon={<img src={require('../../static/images/searchicon.png')} style={{ width: '15px', height: '15px', margin: '-5px 0 0 -15px' }} ></img>}
                        >
                            {courseOptionsDom}
                        </Select>
                    </div>
                </div>
                <div className="releasePostSub">
                    <button className="releasePostSubButton blue" onClick={subSave}>保存</button>
                    <button className="releasePostSubButton yellow" onClick={subForm}>发布</button>
                </div>
            </div>

        </section>
    )
}
// mapStateToProps：将state映射到组件的props中
const mapStateToProps = (state) => {
    return {
        userinfo: state.userinfo
    }
}

// mapDispatchToProps：将dispatch映射到组件的props中
const mapDispatchToProps = (dispatch) => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonImg)
