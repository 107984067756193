import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { change_active_two, change_person_collect_data } from '../../store/action'
import Collect from './index.bundle'

const mapStateToProps = (state) => {
  return {
    collect: state.person_collect
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    change_active_two(data) {
      dispatch(change_active_two(data))
    },
    change_person_collect_data(data) {
      dispatch(change_person_collect_data(data))
    }
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Collect)
);
