import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { change_home_data } from '../../store/action'
import Home from './index.bundle'

const mapStateToProps = (state) => {
  return {
    bannerlist: state.home_bannerlist,
    classlist: state.home_classlist,
    recommend: state.home_recommend,
    playlist: state.home_playlist,
    classfour: state.home_classfour
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    change_home_data() {
      dispatch(change_home_data())
    }
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Home)
);
