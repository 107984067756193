import React, { Component } from 'react'
import $ from 'jquery'
// 组件
import Header from '../../components/header'
import Footer from '../../components/footer'
import PersonImg from '../../components/personimg'
import PersonTitle from '../../components/persontitle'

import Onlinebpmexam from '../../components/onlinebpmexam';//考试


import Maskbox from '../../components/maskbox';



import './index.css'

class Bpmexamdetail extends Component {
  constructor() {
    super()
    this.state = {
      title: "",
      thisindex:-1,
      mastboxsurverystate:false,
      starttime:'',
      qalist:[],
      endtime:'',
      iscanactive:true,
      item:'',
      itemselect:'',
      isnotime:false,
      iserrortext:'',
      examlist:'',
      thistypeids:'',
      thisids:'',
      name: "",
      certificatetime: "",
      certificatenum: ""
      
    };
  }

  
  getUrlkey(url) {
    var params = {};
    var urls = url.split("?");                  ////console.log('1_分割url:', urls)
    var arr = urls[1].split("&");               ////console.log('2_分割urls[1]:', arr)
    for (var i = 0, l = arr.length; i < l; i++) {
      var a = arr[i].split("=");                ////console.log('3_遍历 arr 并分割后赋值给a:', a[0], a[1])
      params[a[0]] = a[1];                      ////console.log('4_a给params对象赋值:', params)
    }                                           ////console.log('5_结果:', params)
    return params;
  }
  componentDidMount() {
    this.getquestlist();
    // let that=this;
    // $.ajax({
    //   type: "GET",
    //   url:
    //     "https://shl-info.cn/front/Questionnaire/getQuestionnaireList&userid=" +
    //     this.props.userinfo.userid +
    //     "&token=" +
    //     this.props.userinfo.token,
    //   dataType: "json",
    //   cache: false
    // }).then(res => {
    //   console.log(res)
    //   if(res.status==1){

    //     // qalist:res.qalist
    //     that.setState({
    //       // title:res.data.name,
    //       examlist:res.data,
    //     })
    //   }
    // });
    // let ids=132;
    // $.ajax({
    //   type: "GET",
    //   url:
    //     "https://shl-info.cn/front/Questionnaire/getQuestionnaireInfo?surverid="+ids+"&userid=" +
    //     this.props.userinfo.userid +
    //     "&token=" +
    //     this.props.userinfo.token,
    //   dataType: "json",
    //   cache: false
    // }).then(res => {
    //   console.log(res)
    //   if(res.status==1){

    //     // qalist:res.qalist
    //     that.setState({
    //       qalist:res.data.questions,
    //     })
    //   }
    // });


  }
  getquestlist = ()=>{
    let that=this;
    $.ajax({
      type: "GET",
      url:
        "https://shl-info.cn/front/Questionnaire/getQuestionnaireList&userid=" +
        this.props.userinfo.userid ,
      dataType: "json",
      headers:{'token':this.props.userinfo.token},
      cache: false
    }).then(res => {
      console.log(res)
      if(res.status==1){

        // qalist:res.qalist
        that.setState({
          // title:res.data.name,
          examlist:res.data,
        })
      }
    });
  }
  gotorul = () => {
    this.props.history.push("/broadcast?type=" + this.classid + "&viewresults=1");
  }
  componentWillUnmount() {

  }
  changeactive = (e)=>{
    console.log(e)
    if(e==this.state.thisindex){
      this.setState({
        thisindex:-1
      })
    }else{
      this.setState({
        thisindex:e
      })
    }
    console.log(12312312312)
  }
  
  changeactivesec = (index1,index2)=>{
    console.log(index1,index2)
    let newarr=this.state.examlist
    this.state.examlist[index1].child[index2].state=!this.state.examlist[index1].child[index2].state;
    console.log(12312312312)
    this.setState({
      examlist:newarr
    })
  }
  
  changeactivethi = (index1,index2,index3)=>{
    console.log(index1,index2,index3)
    console.log(this.state.examlist[index1].child[index2].child[index3])
    let newarr=this.state.examlist
    this.state.examlist[index1].child[index2].child[index3].state=!this.state.examlist[index1].child[index2].child[index3].state;
    console.log(12312312312)
    this.setState({
      examlist:newarr
    })

    console.log(12312312312)
  }
  // gotoserieslist= ()=>{
  //   console.log(2222)
  // }
  gotocertificatedetails = (ids) =>{
   // certificatedata?type=20&di=1
    this.props.history.push("/certificatedata?type=" + ids +"&di=1");
  }
  goexam = (ids,starttime,endtime,item,itemselect)=>{
    
    let sttime=new Date(starttime).getTime();
    let entime=new Date(endtime).getTime();
    let nowtime=new Date().getTime();
    if(nowtime>=sttime&&nowtime<=entime ){
      localStorage.setItem('itemlistid',ids)
      this.setState({
        thistypeids:ids,
        starttime:starttime,
        endtime:endtime,
        item:item,
        itemselect:itemselect,
      })
    }else{
      this.setState({
        iserrortext:"不在考试时间内，请按时间准时参与考试",
        isnotime:true,
      })
    }
    console.log(sttime,entime,nowtime)
    return false;
    console.log(ids)
    console.log(item)
    
    // this.props.history.push("/Bpmexamdetails?id=" + ids );
    // console.log(1231231230)
  }
  gotoresult=()=>{
    this.getquestlist();
    this.setState({
      thistypeids:''
    })
  }
  gotocertificate=()=>{
    console.log(33333)
  }

  changesurverymask =()=>{
    console.log(2222222)
  }
  subsurvery =()=>{
    console.log(1111111)
  }
  goexamlist =(ids)=>{
    this.setState({
      thistypeids:ids
    })
     //let ids=132;
    let that=this
    $.ajax({
      type: "GET",
      url:
        "https://shl-info.cn/front/Questionnaire/getQuestionnaireInfo?surverid="+ids+"&userid=" +
        this.props.userinfo.userid ,
      dataType: "json",
      headers:{'token':this.props.userinfo.token},
      cache: false
    }).then(res => {
      console.log(res)
      if(res.status==1){
        // qalist:res.qalist
        that.setState({
          qalist:res.data.questions,
        })
      }
    });
    //console.log(this.state.item['numlist'][0]['examlist'])
    // this.setState({
    //   qalist:this.state.itemselect['examlist'],
    // })
  }

  
  // 修改考试
  changeExams = arr => {
    console.log(arr)
    console.log(this.state.thistypeids)
    let arrss=JSON.stringify(arr);
    console.log(arrss)
    localStorage.setItem("qalist"+this.state.thistypeids,arrss)
    this.setState({
      qalist: arr
    });
  };


  
  closeMask = () => {
    this.setState({
      isnotime: false,
      mastboxsurverystate:false,
    });
  }

  changeiscanactive = ()=>{
    this.setState({
      iscanactive:!this.state.iscanactive,
      
    })
  }

  gotoachievement = (ids) =>{
    console.log(ids)

    this.props.history.push("/diexamachievement?type=" + ids +"&di=1");
  }


  render() {
    console.log(this.state.itemselect)
    let onlineexam=null
    if(this.state.qalist.length>0){
      onlineexam=<Onlinebpmexam
      examlist={this.state.qalist}
      thisids={this.state.thisids}
      gotoresult={() => this.gotoresult()}
      gotocertificate={() => this.gotocertificate()}
      change={this.changeExams}
      classid={this.state.thistypeids}
      userinfo={this.props.userinfo}
      itemselect={this.state.itemselect}
      lookstate={true}
      usercount={this.state.userqacount}
      history = {this.props.history}
      userqastate={this.state.userqastate}
      changeexamstate={this.state.changeexamstate}
    ></Onlinebpmexam>
    }


    let exammask = null;
    if (this.state.isnotime) {
      exammask = (
        <Maskbox
          info={{
            title: "在线考试",
            text: this.state.iserrortext
          }}
          closeMask={()=>this.closeMask()}
        ></Maskbox>
      );
    }

    
    return (
      <div className="personpage">
        <Header active="4"></Header>
        <PersonImg type="no"></PersonImg>
        <PersonTitle title={this.state.title}></PersonTitle>
        {exammask}
        {/* {layerexammaskstate} */}
        <div className={this.state.thistypeids?"wrap diexam-qalist ":"none"}>
          {onlineexam}
        </div>
        <div className={this.state.thistypeids?"none":"wrap diexam-box "}>
          {console.log(this.state.examlist.length)}
          {this.state.examlist.length >0?
            this.state.examlist.map((item,index)=>{
              //console.log(item)
              return (
                <div className={this.state.thisindex == index?"serieslist cursor active":"serieslist cursor" } key={index}>
                  <h3 className='series-title'  onClick={(e) => this.changeactive(index)}>
                      {item.name}
                      <img src={require('../../static/images/seriesarrow.png')}  className="series-selectfir" alt="" />
                  </h3>
                  {/* <div>{item.name}</div> */}
                  {item.child.map((item1,index1)=>{
                      return (
                      <div className={this.state.thisindex == index?"examlist-boxlisec":"none"}>
                        <h3 className={item1.state?"series-titleaddsec selectsecactive":"series-titleaddsec"}  onClick={(e) => this.changeactivesec(index,index1)}>
                            {item1.name}
                            <img src={require('../../static/images/seriesarrow.png')}  className="series-selectsec" alt="" />
                        </h3>
                        {item1.child.map((item2,index2)=>{
                          return (
                            <div className={item1.state?"examlist-boxlithi":"none"}>
                              <h3 className={item2.state?"series-titleaddsec selectthiactive":"series-titleaddsec"}  onClick={(e) => this.changeactivethi(index,index1,index2)}>
                                  {item2.name}
                                  <img src={require('../../static/images/seriesarrow.png')}  className="series-selectthi" alt="" />
                              </h3>
                              {item2.child.map((item3,index3)=>{
                                return (
                                  <div className={item2.state?"examlist-boxlilist":"none"} key={index3}>
                                    <h3 className='series-titleaddsec'  >
                                        {item3.title}
                                        {item3.isanswer==1?<div className="goshow" >已完成</div>:null}
                                        {item3.isanswer==0?<div className="goshow" onClick ={()=>this.goexamlist(item3.id)} >开始填写</div>:null}
                                       
                                    </h3>
                                  </div>
                                )
                              })}
                            </div>
                          )
                        }
                        )}
                        {/* <ul className='series-labellist examlist-boxlist examlist-boxlistsec'>
                          <li style={{fontWeight:"bold"}}>
                            <span className='firtitle'>场次</span>
                            <span className='sectitle'>开始时间</span>
                            <span className='thititle'>结束时间</span>
                            <span className='fivetitle'>考试状态</span>
                            <span className='sixtitle'>考试结果</span>
                            <span className='fourtitle titles'>操作</span>
                          </li>
                        </ul> */}
                      </div> 
                      )
                  }
                  )}
                  
                  {/* <div className={item['timelist_fir'].length>0?this.state.thisindex == index?"examlist-boxli ":"none":" none "} >
                    <div className='nametitles'>第一轮考试</div> 
                    <ul className='series-labellist examlist-boxlist'>
                      <li style={{fontWeight:"bold"}}>
                        <span className='firtitle'>场次</span>
                        <span className='sectitle'>开始时间</span>
                        <span className='thititle'>结束时间</span>
                        <span className='fivetitle'>考试状态</span>
                        <span className='sixtitle'>考试结果</span>
                        <span className='fourtitle titles'>操作</span>
                      </li>
                      {item['timelist_fir'].length>0?
                        item['timelist_fir'].map((itemlist,indexlist)=>{
                          console.log(itemlist)
                          console.log("itemlist22222")
                          let thisstate=0;
                          let sttime=new Date(itemlist.starttime).getTime();
                          let entime=new Date(itemlist.endtime).getTime();
                          let nowtime=new Date().getTime();
                          let stovertime = sttime + (item.max_logintime*1000*60);
                          //  / && localStorage.getItem('itemlistid') ==  itemlist.id
                          if( (nowtime>=sttime&&nowtime<=stovertime ) || (nowtime>=sttime&&nowtime<=entime && itemlist.issubstate ===0) ){
                            thisstate=1;
                          }else{
                            thisstate=2
                          }
                          console.log(thisstate)
                          return (
                            <li  key={indexlist} >
                              <span className='firtitle'>第{indexlist+1}场</span>
                              <span className='sectitle'>{itemlist.starttime} </span>
                              <span className='thititle'>{itemlist.endtime} </span>

                          {itemlist.issuerver !=1   && thisstate !=1?<span className='fourtitle nostart' >{nowtime>entime?"考试结束":"进入考试"}</span>:null}
                              { (itemlist.issuerver !=1 && thisstate ==1 ) || (itemlist.issubstate !=1  && thisstate ==1 ) 
                              ?(item.firissuerver==1 && item.iscan_exam_fir==1 )||item.firissuerver==0 || (item.firissuerver==1 && itemlist.issubstate ===0  && thisstate ==1 )
                              ?<span className='fourtitle '  onClick={()=>this.goexam(itemlist.id,itemlist.starttime,itemlist.endtime,item,itemlist)} >进入考试</span>
                              :<span className='fourtitle nostart' >{"进入考试"}</span>
                              :null}
                              {itemlist.isfirsurver==1 && itemlist.issubstate ==1 ? <span className='fourtitle nostart' >考试结束</span>: null}
                              {itemlist.ispass==1?<span className='fourtitle ' onClick={()=>this.gotocertificatedetails(itemlist.baseids)} >查看证书</span>:null}
                              {itemlist.issuerver==1  && itemlist.issubstate ==1?<span className='fourtitle ' onClick={()=>this.gotoachievement(itemlist.id)} >查看成绩</span>:null}

                             
                              <span className='fivetitle'>{itemlist.issuerver==1?"已考试":"未考试"}</span>
                              <span className='sixtitle'>{itemlist.issuerver==1&&itemlist.issubstate ==1?itemlist.ispass==1&&itemlist.issubstate ==1?itemlist.maxfen+"分/ 通过":itemlist.maxfen+"分/ 未通过":"无成绩"}</span>
                            </li>
                          )
                        })
                      :null}
                    </ul>
                   </div> */}
                    
                  {/* <div className={item['timelist_sec'].length>0?this.state.thisindex == index?"examlist-boxli ":"none":" none "} >
                    <div className='nametitles'>第二轮考试</div> 
                    <ul className='series-labellist examlist-boxlist'>
                      <li  style={{fontWeight:"bold"}}>
                        <span className='firtitle'>场次</span>
                        <span className='sectitle'>开始时间</span>
                        <span className='thititle'>结束时间</span>
                        <span className='fivetitle'>考试状态</span>
                        <span className='sixtitle'>考试结果</span>
                        <span className='fourtitle titles'>操作</span>
                      </li>

                      {item['timelist_sec'].length>0?
                          item['timelist_sec'].map((itemlist,indexlist)=>{
                           
                            let thisstate=0;
                            let sttime=new Date(itemlist.starttime).getTime();
                            let entime=new Date(itemlist.endtime).getTime();
                            let nowtime=new Date().getTime();
                            let stovertime = sttime + (item.max_logintime*1000*60);
                            console.log(sttime,stovertime,entime)
                            console.log(item.max_logintime)
                            if( (nowtime>=sttime&&nowtime<=stovertime ) || (nowtime>=sttime&&nowtime<=entime && localStorage.getItem('itemlistid') ==  itemlist.id) ){
                              thisstate=1;
                            }else{
                              thisstate=2
                            }
                            console.log(itemlist)
                            return (
                                  <li key={indexlist} >
                                    <span className='firtitle'>第{indexlist+1}场</span>
                                    <span className='sectitle'>{itemlist.starttime} </span>
                                    <span className='thititle'>{itemlist.endtime} </span>
                                    {thisstate !=1?<span className='fourtitle nostart' >{nowtime>entime?"考试结束":itemlist.issuerver==1?"考试结束":"进入考试"}</span>:null}
                                    {thisstate==1
                                    ?itemlist.issuerver==1  && itemlist.issubstate ==1
                                    ?<span className='fourtitle nostart' >考试结束</span>
                                    :item.iscan_exam==0 && item.firispass==1 
                                    ?<span className='fourtitle nostart' >进入考试</span>
                                    :<span className='fourtitle '  onClick={()=>this.goexam(itemlist.id,itemlist.starttime,itemlist.endtime,item,itemlist)}>进入考试</span>
                                    : null}
                                  
                                   {itemlist.ispass==1?<span className='fourtitle ' onClick={()=>this.gotocertificatedetails(itemlist.baseids)} >查看证书</span>:null}
                                    {itemlist.issuerver==1 && itemlist.issubstate ==1?<span className='fourtitle ' onClick={()=>this.gotoachievement(itemlist.id)} >查看成绩</span>:null}


                                    <span className='fivetitle'>{itemlist.issuerver==1?"已考试":"未考试"}</span>
                                    <span className='sixtitle'>{itemlist.issuerver==1 && itemlist.issubstate ==1?itemlist.ispass==1&&itemlist.issubstate ==1?itemlist.maxfen+"分/ 通过":itemlist.maxfen+"分/ 未通过":"无成绩"}</span>
                                  </li>
                            )
                          })
                      :null}
                    
                    </ul>
                  </div> */}
                </div>)
            })
            
          :null}
          
        </div>
        <Footer></Footer>
      </div>
    );
  }
}

export default Bpmexamdetail
