import React, { Component } from 'react'

import $ from 'jquery'
// 组件
import Header from '../../components/header'
import Footer from '../../components/footer'
import PersonImg from '../../components/personimg'
import PersonTitle from '../../components/persontitle'
import RecordList from '../../components/recordlist'
import Maskbox from "../../components/maskbox";

import './index.css'

class Checkcode extends Component {
  constructor(props) {
    
    // this.valueChange = this.valueChange.bind(this);
    super(props);
    this.state = {
      askactive: false,
      checkcode:'',
      checkok:false,
      bindoktext:"",
      ishaver:false,
    }
  }
  closeMask = () => {
    this.setState({
      askactive: false,
    });
  };
  componentDidMount() {
    this.props.change_active_two('checkcode')
  }
  componentWillUnmount() {

  }
  subcode =()=>{
    if(this.state.checkcode==''){
      this.setState({
        askactive: true,
      });
    }else{
      
      //提交code去验证
      $.ajax({
        type: "GET",
        url:
          "https://shl-info.cn/front/frontc/checkcode?userid=" +
          localStorage.getItem('userid')  +
          "&checkcode=" +
          this.state.checkcode,
        dataType: "json",
        
        headers:{'token':localStorage.getItem('token')},
      }).then(res => {
        console.log(res)
        
        this.setState({
          askactive: true,
        });
        if(res['code']==200){
          this.setState({
            checkok: true,
            bindoktext:"礼券兑换成功！有效期为"+res.allmonth+"个月，\n权限解除日期"+res.bindtimeover+"。\n期间您可在课程中心与直播中心中查看相关内容",
          });
        }else if(res['code']==402){
          this.setState({
            checkok: false,
            ishaver:true,
          });
        }else{
          this.setState({
            checkok: false,
            ishaver:false,
          });
        }


      });
      

    }
  }
  valueChange(e) {
    console.log(e.target.value)
    let subtext=e.target.value
    var fileName = subtext;
    // 过滤掉文件名中的中文（也包含日文和韩文），不包含中文符号
    var regex = /[\u4E00-\u9FA5\uF900-\uFA2D]/;
    // 包含中文
    if (regex.test(fileName)) {
        // 用于临时存储单字符
        var chinese = "";
        // 用于校验是否是中文
        var flag = false;
        // 用于存储过滤后的文件名
        var filterChinese = "";
        for (var i=0; i < fileName.length; i++) {
            chinese = fileName.substring(i, i+1);
            flag = regex.test(chinese);
            // 该字符不是中文
            if(!flag) {
                filterChinese += chinese;
            }
        }
        // 过滤掉中文后的文件名
        fileName = filterChinese;
    }
    console.log(fileName)
    this.setState({
      checkcode: fileName
    })
  }
  render() {
    let layermask = null;
    if(this.state.askactive){
      if(this.state.checkok){
        layermask=<Maskbox
          info={{
            title: "",
            text: this.state.bindoktext
          }}
          closeMask={this.closeMask}
        ></Maskbox>
      }else if(this.state.checkcode==''){
        layermask=<Maskbox
          info={{
            title: "",
            text: "请输入券码"
          }}
          closeMask={this.closeMask}
        ></Maskbox>
      }else{
        let text="礼券兑换失败！请核实券码信息"
        if(this.state.ishaver){
          text="您已拥有权限";
        }
        layermask=<Maskbox
          info={{
            title: "",
            text: text
          }}
          closeMask={this.closeMask}
        ></Maskbox>
      }
      

      
      
    }
    return (
      <div className='personpage'>
        <Header active='4'></Header>
        <PersonImg type='no'></PersonImg>
        <PersonTitle title='兑换券码'></PersonTitle>
        <div className='wrap checkcodewrap'>
          <div className='checkcodewraptops'>
            <span>输入券码解锁课程</span>
            <span><input type="text"  value={this.state.checkcode}
                onChange={(e) =>this.valueChange(e)} /></span>
          </div>
          <div className="subbtn" onClick={()=>this.subcode()}>提交</div>
        </div>
        {layermask}
        <Footer></Footer>
      </div>
    )
  }
}

export default Checkcode
