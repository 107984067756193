import React, { Component } from 'react'

import $ from 'jquery';
// 组件
import Header from '../../components/header'
import Footer from '../../components/footer'

import Banner from '../../components/banner'
import SelectBox from '../../components/selectbox'
import Shopclasslist from '../../components/shopclasslist'


import MaskBox from '../../components/maskbox'
// import Scorelayer from '../../components/scorelayer'

import FileType from '../../components/filetype'
import Swiper from 'swiper'

import QRCode from 'qrcode.react';



import Scorelayer from '../../components/scorelayer2023';

import './index.css'

class Shopmallindex extends Component {
  constructor() {
    super()

    

  this.changeValue = this.changeValue.bind(this)

  
  this.selectmonth = this.selectmonth.bind(this);
    this.state = {
      swiper: null,
      active:'home',
      active:'my',
      isneedpo:false,
      iscompany:false,
      postponame:'',
      postponum:'',
      postpoemail:'',
      postpoaddress:'',
      postpophone:'',
      postpopbank:'',
      postpopbanknum:'',
      postponameerror:false,
      postponumerror:false,
      postpoemailerror:false,
      privacyerror:false,
      emailerrortext:"请输入邮箱",
      

      showpri:false,
      privacy:true,
      showcode:false,


      serviceinfo:{
        name:'',
      },
      getdetailpage:{},
      loadinglayer:false,
      paycode:'',
      qrCode:'',
      order_id:'',
      getcodestate:'',
      time:300,
      //time:70,
      timeoutfun:'',
      buynum:'',
      mastday:'',

      shimonth:"一个月",
      maskfalg:false,
      buyerror:false,
    }
  }
  
  selectmonth(e) {
    var department = e.target.value
    if(department=='请选择'){
      department="";
    }
    let num = department
    console.log(num)
    // num = num+1
    const date = new Date(this.state.serviceinfo.date);
    date.setMonth(date.getMonth() + (num-1));
    this.setState({
      buynum:department,
      mastday:date.toISOString().slice(0, 10),
    }, () => {
      
    })
  }
  changeValue  = (e) =>{
    let text = e.target.value
    this.setState({
      [e.target.name]:text,
      [e.target.name+'error']:false,
    })
   
  }
  
  componentDidUpdate() {
    if(this.state.swiper){
      this.state.swiper.update();
    }
    console.log("componentDidUpdate")
  }
  componentDidMount() {
    let that = this
    let userid = localStorage.getItem('userid')
    let token = localStorage.getItem('token')
    let thisuserid = userid;
    // $('.changeselect').val()
    if (userid && token) {
      // 验证是否过期
      $.ajax({
        type: "GET",
        url: "https://shl-info.cn/front/user/checkuser?userid=" +
          userid ,
        dataType: "json",
        headers:{'token':token},
        success: function (res) {
          console.log(res)
          if (res.status === 1) {
            localStorage.setItem('usertype', res.list.usertype)
            
            localStorage.setItem('iswaitype', res.list.type)
            if (res.list.id != '' && res.list.type === 0) {
              that.setState({
                isoutuser: false,
              })
            }
            //isoutuser
          }else{
            localStorage.setItem('refurl', window.location.href);
            this.props.history.push('./loginlist')
          }
        }
      });
    }else{
      localStorage.setItem('refurl', window.location.href);
      this.props.history.push('./loginlist')
    }
     this.swiperInit();
     if (window.location.href.indexOf('typeids') >= 0) {
      var urls = this.getUrlkey(window.location.href);
      let typeids = urls.typeids
      this.typeids = typeids

      
      fetch(
        "https://shl-info.cn/front/Monitor/addMonitorData?userid="+localStorage.getItem('userid')+"&intMonitortype=4"+"&category="+"&pagetype=商城详情页" +"&seriesid="+this.typeids+"&source=西影力"   
      )
        .then(data => {
          return data.json();
        })
        .then(res => {
        })

      this.getseriesinfo(typeids)
      this.getdetailpage(typeids)
    }
  }
  getUrlkey(url) {
    var params = {};
    var urls = url.split("?");                  //////console.log('1_分割url:', urls)
    var arr = urls[1].split("&");               //////console.log('2_分割urls[1]:', arr)
    for (var i = 0, l = arr.length; i < l; i++) {
      var a = arr[i].split("=");                //////console.log('3_遍历 arr 并分割后赋值给a:', a[0], a[1])
      params[a[0]] = a[1];                      //////console.log('4_a给params对象赋值:', params)
    }                                           //////console.log('5_结果:', params)
    return params;
  }
  getseriesinfo = (typeids)=>{
    console.log(this.typeids)
    fetch(
      "https://shl-info.cn/front/ShlMultiPay/getseriesinfo?userid="+localStorage.getItem('userid'),{
        method: 'POST',
        body: JSON.stringify({
          seriesid:typeids,
          userid:localStorage.getItem('userid'),
        }),
        headers:{
          'content-type': 'application/json',
          'token':localStorage.getItem('token')
        },
        header: {
          'content-type': 'application/json',
          'token':localStorage.getItem('token')
        },
      }
    )
    .then(data => {
      return data.json();
    })
    .then(res => {
      console.log(res)
      const date = new Date(res.data.date);
      date.setMonth(date.getMonth() + 0);
      this.setState({
        serviceinfo:res.data,
        mastday:date.toISOString().slice(0, 10),
      })
    })
  }
  
  getdetailpage = (typeids)=>{
    console.log(this.typeids)
    fetch(
      "https://shl-info.cn/front/ShlMultiPay/getdetailpage?userid="+localStorage.getItem('userid'),{
        method: 'POST',
        body: JSON.stringify({
          seriesid:typeids,
          userid:localStorage.getItem('userid'),
        }),
        headers:{
          'content-type': 'application/json',
          'token':localStorage.getItem('token')
        },
        header: {
          'content-type': 'application/json',
          'token':localStorage.getItem('token')
        },
      }
    )
    .then(data => {
      return data.json();
    })
    .then(res => {
      console.log(res)
      this.setState({
        getdetailpage:res.data,
      })
    })
  }

  
  swiperInit() {
    let that = this;
    that.setState({
      swiper: new Swiper(".mySwiper", {
        slidesPerView: 4,
        spaceBetween: 16,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        // pagination: {
        //   el: ".swiper-pagination",
        //   clickable: true,
        // },
      })
    })
  }
  changeiscompany = (state) =>{
    this.setState({
      iscompany:state
    })
  }
  changeisneedpo = (state)=>{
    this.setState({
      isneedpo:state
    })
  }
  showMask = ()=>{
    this.setState({
      showpri:true
    })
  }
  closeMask = ()=>{
    this.setState({
      showpri:false
    })
  }
  agreeClose = () =>{
    this.setState({
      privacy:true,
      showpri:false,
      privacyerror:false,
    })
  }
  changepristate = ()=>{
    this.setState({
      privacy:!this.state.privacy,
    },()=>{
      if(!this.state.privacy){
        this.setState({
          privacyerror:true
        })
      }else{
        this.setState({
          privacyerror:false
        })
      }
    })
  }
  gocourse=()=>{
    this.props.history.push("/homeback?cscoursestate=1&changeid="+this.typeids);
  }
  gopay = ()=>{
    let errornum=0
    let postponame=false
    let postponum=false
    let postpoemail=false
    let emailerrortext="请输入邮箱";
    if(!this.state.buynum){
      this.setState({
        buyerror:true
      })
      return
    }
    if(this.state.isneedpo){
      if(this.state.iscompany ){
        if(!this.state.postponame || this.state.postponame.replace(/\s*/g,'')=='' ){
          console.log(1)
          errornum++
          postponame=true
        }
        if(!this.state.postponum || this.state.postponum.replace(/\s*/g,'')=='' ){
          console.log(2)
          errornum++
          postponum=true
        }
        if(!this.state.postpoemail){
          console.log(3)
          errornum++
          postpoemail=true
        }
      }
      if(!this.state.iscompany){
        if(!this.state.postponame  || this.state.postponame.replace(/\s*/g,'')==''){
          console.log(41)
          errornum++
          postponame=true
        }
        if(!this.state.postpoemail){
          console.log(51)
          errornum++
          postpoemail=true
          emailerrortext="请输入邮箱";
        }
      }
      if(this.state.postpoemail && !this.props.emailexg.test(this.state.postpoemail)){
        console.log(61)
          errornum++
          postpoemail=true
          emailerrortext="请输入正确邮箱";
      }
      
    }
    console.log(errornum)
    if(errornum>0){
      this.setState({
        postponameerror:postponame,
        postponumerror:postponum,
        postpoemailerror:postpoemail,
        emailerrortext:emailerrortext,
      })
      return 
    }
    if(!this.state.privacy){
      this.setState({
        privacyerror:true,
      })
      return
    }
    this.setState({
      loadinglayer:true,
    })
    let subdata={
      userid:localStorage.getItem('userid'),
      seriesid:this.typeids,
      isinvoice:this.state.isneedpo?1:0, //是否需要发票
      type:'咨询服务发票',
      titletype:this.state.iscompany?"公司":"个人",
      content:'咨询服务',
      price:'140元',
      title:this.state.postponame, //发票抬头
      dutyparagraph:this.state.postponum, // 纳税人识别号
      email:this.state.postpoemail, //收件人邮箱
      company:this.state.postpoaddress,// 公司地址
      phone:this.state.postpophone, //公司电话
      bank:this.state.postpopbank, //公司开户行
      bankcode:this.state.postpopbanknum, //开户行账号
      ruleid:this.state.buynum, //购买月份
    };
  
    fetch(
      "https://shl-info.cn/front/ShlMultiPay/createOrder?userid="+localStorage.getItem('userid'),{
        method: 'POST',
        body: JSON.stringify(subdata),
        headers:{
          'content-type': 'application/json',
          'token':localStorage.getItem('token')
        },
        header: {
          'content-type': 'application/json',
          'token':localStorage.getItem('token')
        },
      }
    )
    .then(data => {
      return data.json();
    })
    .then(res => {
      console.log(res)
      if(res.code==200){
        this.setState({
          paycode:res.data.orderId,
          qrCode:res.data.qrCode,
          order_id:res.order_id,
          loadinglayer:false,
          showcode:true,
        },()=>{
          //this.getpaycode()
          this.getcodestate()
          
          this.gettimeout();
        })

      }else{
        this.setState({
          maskfalg:true,
          loadinglayer:false,
        })
      }
    })
    // this.setState({
    //   showcode:true,
    // })
  }
  getpaycode(){
    console.log(this.state.paycode)
    fetch(
      "https://shl-info.cn/front/ShlMultiPay/pay?userid="+localStorage.getItem('userid'),{
        method: 'POST',
        body: JSON.stringify({
          seriesid:this.typeids,
          ordercode:this.state.paycode,
          userid:localStorage.getItem('userid'),
        }),
        headers:{
          'content-type': 'application/json',
          'token':localStorage.getItem('token')
        },
        header: {
          'content-type': 'application/json',
          'token':localStorage.getItem('token')
        },
      }
    )
    .then(data => {
      return data.json();
    })
    .then(res => {
      console.log(res)
      this.setState({
        qrCode:res.data.qrCode,
        order_id:res.order_id,
        loadinglayer:false,
        showcode:true,
      })
        this.getcodestate()
    })
  }
  closeqrcodelayer = ()=>{
    clearInterval(this.state.timeoutfun)
    clearTimeout(this.state.getcodestate)
    this.setState({
      showcode:false,
      time:300,
    })
  }
  gettimeout(){
    let that = this
    
    clearInterval(this.state.timeoutfun)
    let timeoutfun =  setInterval(()=>{
      console.log(that.state.time)
      if(that.state.time==0){
        clearInterval(this.state.timeoutfun)
        clearTimeout(this.state.getcodestate)
        that.setState({
          showcode:false,
          time:300,
        })
        return
      }
      that.setState({
        time:that.state.time-1,
      })
    
    },1000)
    this.setState({
      timeoutfun:timeoutfun
    })
  }
  getcodestate(){
    clearTimeout(this.state.getcodestate)
    fetch(
      "https://shl-info.cn/front/ShlMultiPay/queryOrder?userid="+localStorage.getItem('userid'),{
        method: 'POST',
        body: JSON.stringify({
          orderid:this.state.paycode,
          userid:localStorage.getItem('userid'),
        }),
        headers:{
          'content-type': 'application/json',
          'token':localStorage.getItem('token')
        },
        header: {
          'content-type': 'application/json',
          'token':localStorage.getItem('token')
        },
      }
    )
    .then(data => {
      return data.json();
    })
    .then(res => {
      console.log(res)
      if(res.code==200){
        //支付成功
        clearInterval(this.state.timeoutfun)
        this.setState({
          time:300,
        })
        this.props.history.push("/homeback?cscoursestate=1&changeid="+this.typeids);
      }else{
        let getstate =  setTimeout(()=>{
            this.getcodestate()
        },3000)
        this.setState({
          getcodestate:getstate
        })
      }
     
    })
    
  }

  addnum = (type)=>{
    if(type==1){
      if(this.state.buynum>=12){
        return
      }else{
        let num = this.state.buynum
            num = num+1
            const date = new Date(this.state.serviceinfo.date);
            date.setMonth(date.getMonth() + (num-1));
        this.setState({
          buynum:num,
          mastday:date.toISOString().slice(0, 10),
        },()=>{
          // if(num==3){
          //   const date = new Date(this.state.serviceinfo.date);
          //   date.setMonth(date.getMonth() + 5);
          //   this.setState({
          //     mastday:date.toISOString().slice(0, 10),
          //     shimonth:'六个月',
          //   })
          // }
          // if(num==2){
          //   const date = new Date(this.state.serviceinfo.date);
          //   date.setMonth(date.getMonth() + 2);
          //   this.setState({
          //     mastday:date.toISOString().slice(0, 10),
          //     shimonth:'三个月',
          //   })
          // }
          // if(num==1){
          //   this.setState({
          //     mastday:this.state.serviceinfo.date,
          //     shimonth:'一个月',
          //   })
          // }
        })
      }
    }else{
      if(this.state.buynum<=1){
        return
      }else{
        let num = this.state.buynum
            num = num-1

            const date = new Date(this.state.serviceinfo.date);
            date.setMonth(date.getMonth() + (num-1));
        this.setState({
          buynum:num,
          mastday:date.toISOString().slice(0, 10),
        },()=>{
          // if(num==3){
          //   const date = new Date(this.state.serviceinfo.date);
          //   date.setMonth(date.getMonth() + 5);
          //   this.setState({
          //     mastday:date.toISOString().slice(0, 10),
          //     shimonth:'六个月',
          //   })
          // }
          // if(num==2){
          //   const date = new Date(this.state.serviceinfo.date);
          //   date.setMonth(date.getMonth() + 2);
          //   this.setState({
          //     mastday:date.toISOString().slice(0, 10),
          //     shimonth:'三个月',
          //   })
          // }
          // if(num==1){
          //   this.setState({
          //     mastday:this.state.serviceinfo.date,
          //     shimonth:'一个月',
          //   })
          // }
        })
      }
    }
  }

  closeMask = ()=>{
    console.log(3333)
    this.setState({
      maskfalg:false,
      buyerror:false,
    })
  }
  jumpdetail = (item)=>{
    console.log(item)
    this.props.history.push('./coursedetails?type='+item.id+'&iscs=1')
  }
  render() {
    // 切换导航
   
    let priinfo = null
    priinfo = <div
    className={this.state.showpri?"privacyMask active":"none"}
  >
    <div className="privacyCenter">
      <h3 className="maskTitle">
        西门子医疗西影力学院商城销售协议
        <span className="closeIcon" onClick={()=>this.closeMask()}>
          <img src={require("../../static/images/close.png")} alt="" />
        </span>
      </h3>
      <div className="maskiscroll priva-props">
        <div className="maskscroll-wrap ">
        <div className="dotrighttext">发布时间：【2023年4月1日】</div>
          <h4>提示条款：</h4>
          <p>欢迎您使用西门子医疗西影力学院商城网站（https://shl-info.cn/）（以下称“西影力商城”）。本销售协议是您或您所代表之实体（以下简称“您”）通过西影力商城采购产品/服务与西门子医疗系统有限公司（以下简称“西门子医疗”）所订立的协议（以下简称“本协议”）。本协议自发布之日起生效，如本协议内容有任何修订或更新，以西影力商城届时在线公布的协议内容为准。</p>
          <h4>【审慎阅读】</h4>
          <p>您在申请注册流程中点击同意本协议之前，应当认真阅读本协议。请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款、法律适用和争议解决条款。免除或者限制责任的条款将以粗体下划线标识，您应重点阅读。如您对协议有任何疑问，可咨询人工客服021-38891887（服务时间:周一至周五08:30-18:00）。</p>
          <h4>【达成协议】</h4>
          <p>当您按照注册页面提示信息填写、阅读并勾选复选框同意本协议且完成全部注册程序后，即表示您已充分阅读、理解并接受本协议的全部内容。</p>

          <h4>二、交易流程和规则</h4>
          <p>1.<b>【订单支付及生成】</b>您可在完成账号注册及实名认证后，登录西影力商城进行下单。您在西影力商城下单后，须在24小时内通过线上付款方式完成付款，否则西门子医疗有权关闭交易。
            下单时您需要选择西影力商城显示的可选支付方式进行付款，线上支付服务由第三方服务商提供。</p>
          <p>您支付订单全款后（如订单约定订金的，则支付订金后），订单即生效。您可以随时登陆西影力商城，查询您的订单状态。</p>
          <p>如果您的注册信息、订单信息不完整导致订单无法生成的，请您尽快补充相应的信息。</p>

          <p>2.<b>【产品/服务交付】</b>当您完成在线下单后，西门子医疗会自动解锁您采购的相关产品/服务（包括但不限于技术咨询视频等），即视为西门子医疗已交付产品/服务。西门子医疗不保证交付的产品/服务将会达到您的预期效果，且西影力商场上展示的所有产品/服务均不适用“无理由退货”。</p>
          <p>3.<b>【订单取消情形】</b>西影力商城的产品/服务信息随时都有可能发生变动，变动时不作特别通知。西影力商城将尽合理的商业努力，使商城内展示的产品/服务价格、参数、说明、库存、限购数量等商品信息尽可能准确、详细，但由于商城上产品/服务信息的数量庞大，且受互联网技术发展水平等因素的限制，本商城不排除部分信息会存在滞后或差错的可能性。如因上述原因，导致您的订单出现错误，西门子医疗有权单方取消订单。您确认对此情形您表示知悉并理解，并同意不追究西影力商城的相关责任。西门子医疗会在发生上述情况时，尽早通知您，您在订单下已经支付的款项将在订单取消后原路退还给您。</p>


          <p>4.<b>【发票】</b>线上订单完成后，您可以在线申请开具电子发票，电子发票由西影力商城运营主体西门子医疗系统有限公司为您提供。</p>
          <h4>三、责任限制</h4>

          <p>1.<b>【责任限制】</b>您理解并同意，西门子医疗应承担因违反本销售协议给您造成的直接损失，但在法律允许的范围内，西门子医疗向您承担的与交易相关的全部各类法律责任不应超出相关产品/服务的交易总价。</p>
          <p>2.<b>【间接损失排除】</b>您理解并同意，在任何情况下西门子医疗对您的使用、生产、利润、利息以及收入损失、信息或数据的丢失或任何间接的或后果性的损坏或损失都不承担赔偿责任，无论该等损失是否可以预见。</p>
        
          <h4>四、不可抗力</h4>
          <p>1.任一方因不可抗力不能或迟延履行义务时，均不承担违约责任。不可抗力应指遭受不可抗力方无法预见的且超出其合理控制的事件，包括但不限于：自然力、自然灾害、劳工纠纷、封锁、战争或类似战争状态、暴乱、阴谋破坏、火灾、公共卫生安全事件（例如：“新冠肺炎疫情”）、履行订单的关键设备瘫痪、运输阻滞或交通事故、政府行为(例如但不限于修改法律规定和取消进口许可)。</p>
          <p>2.任何一方因不可抗力无法履行本协议的相关义务时，可以主张相应顺延其履行该义务的期限。如果因不可抗力事件导致本协议相关义务持续180天以上无法履行的，任何一方可以书面通知另一方解除本协议。</p>

          <h4>五、知识产权</h4>
          <p>1.西门子医疗在指定期限（详见商品页面的相关描述信息，例如，“课程兑换”页面的“有效期”等信息）内授予您一项非独占的、不可转让、不可分许可的权利，以使您可以基于供您个人学习的目的，以未经修改的形式、在中国大陆在线浏览、观看西门子医疗提供的相关视频内容，指定期限届满，则前述授权终止。</p>
          <p>2.您通过本销售条款获得的知识产权相关权利限于本条第1项中所列的内容，未经西门子医疗许可，您不得自行或帮助他人实施侵犯西门子医疗受法律保护的知识产权相关权益或其他权利，包括但不限于，通过任何机器、工具、软件程序、设备或其他技术手段对西门子医疗西影力商城上的视频内容（包括但不限于，文字、图片、图像、音频、视频、图表、版面设计、电子文档等）进行抓取、复制、录制、下载、传播、镜像、修改、出售等。</p>
          <p>3.在双方交易之前所存在的著作权及其他知识产权归于拥有该著作权及其他知识产权的各方所有，不因交易而发生转移。除非双方另有约定，西门子医疗为实施本交易或在本交易过程中开发或制作的内容所涉及的知识产权相关权益或其他权利属于西门子医疗，包括但不限于，西门子医疗在其西影力商城上提供的视频内容的相关权益等。</p>
          <p>您不得以任何方式自行或帮助他人实施侵犯西门子医疗著作权、商标权、专利权或其他知识产权的行为。</p>


          <h4>六、适用法律和争议解决</h4>
          <p>本条款以及双方之间的交易适用法律为中华人民共和国法律。如双方就本条款或交易产生的或者与其相关的所有请求、分歧或争议，包括关于合同存在、效力、终止或履行，或者与订单履行安排有关的任何问题（以下称“争议”），应提交中国国际经济贸易仲裁委员会(CIETAC)，并根据申请仲裁时有效的CIETAC仲裁规则由三（3）名仲裁员仲裁解决。双方同意，不在CIETAC仲裁员名单上的仲裁员可以被指定为仲裁员或首席仲裁员。仲裁地点为上海。仲裁语言为中文。仲裁裁决是终局的并对双方具有约束力。
          </p>

          <h4>七、通知</h4>

          <p>您确认并同意，西门子医疗可通过客服、短信、微信、站内消息等西影力商城网站或小程序的消息发送功能或向您提供的手机号码、电子邮件地址或邮寄地址发送通知。</p>

          <h4>八、遵守法律</h4>
          <p>您陈述并保证您自身及其任何代理人（包括雇员）将遵守所有适用的法律法规，包括但不限于，任何与课税、反腐败、反垄断、反洗钱相关的法律法规或任何其他的刑事法律、规章或法规。</p>
         
          <h4>九、补充条款</h4>
          <p>您已充分知悉，本销售协议系针对西影力商城线上展示的所有类型产品/服务的通用条款，对于部分产品/服务，可能有特殊的约定。您还应仔细阅读相应的展示页面下的“产品/服务详情”、“产品/服务参数”、“买家必读”和“资料下载”中关于产品/服务的详细介绍、说明和产品条款，该等条款均构成本销售协议的补充条款。您在西影力商城下单即表明一并接受了该等补充条款。若补充条款与本销售条款存在冲突的，以补充条款的约定为准。</p>
          <h4>十、其他</h4>
          <p>任何本销售协议（包括补充条款）中明示或依其性质应当在协议终止后继续有效的条款，将在本协议终止后继续保持其完整效力，直至这些条款中的条件被满足或者依其性质应当到期为止。如果本销售条款的任何条款被认定为无效，本条款的其他条款将继续有效。
          </p>

        </div>
      </div>
      <div
        className="maskButton cursor"
        onClick={(e) => this.agreeClose("privacy", e)}
      >
        接受条款
      </div>
    </div>
  </div>

let  getTime = (time) => {
  // 转换为式分秒
  let h = parseInt(time / 60 / 60 % 24)
  h = h < 10 ? '0' + h : h
  let m = parseInt(time / 60 % 60)
   m = m < 10 ?  m : m
  let s = parseInt(time % 60)
   s = s < 10 ?  s : s
  // 作为返回值返回
  console.log(m,s)
  //return [h, m, s]
  if(m!=0){
    return m+'分'+s+'秒'
  }else{
    return s+'秒'
  }
}

let showcode = null
if(this.state.showcode){
  showcode=<div className="showcodelayer">
    <div className="showqrcode">
      <span className="showqrcodeclose" onClick={()=>this.closeqrcodelayer()}></span>
      <span className="showqrcodetips">请使用微信进行扫码支付</span>
      <QRCode
          className="showqrcodeimg"
          value={this.state.qrCode}  //value参数为生成二维码的链接
          size={280} //二维码的宽高尺寸
          fgColor="#000000"  //二维码的颜色
          level="H"
        />
        <div className="showqrcodetext">请在{getTime(this.state.time)}内扫码完成支付</div>
      {/* <div className="tipstimeout">倒计时：</div> */}
    </div>
  </div>
}
  let getgetailpage = () =>{
    this.state.getdetailpage.courselist.map(()=>{
      return <div className="swiper-slide">
      <div className="shopmalldetail_desc_courselist_box_img"><img src={require('../../static/images/shopmall/shopmall-banner.png')} /></div>
      <div className="shopmalldetail_desc_courselist_box_title">12312312</div>
      <div className="shopmalldetail_desc_courselist_box_tips">
        <div className="people">
          <img src={require('../../static/images/mall/mall_rank_icon2.png')} alt="" />
          免费课程
        </div>
        
        <div className="people">
          <img src={require('../../static/images/mall/mall_rank_icon2.png')} alt="" />
          123
        </div>
      </div>
    </div>
    })
  }
  
  let loadinglayer=<div className='loadinglayer'><div className="loadinglayertips">提交中</div></div>
  
  let formattime = (timestamp)=> {
    var date = new Date(timestamp * 1000);
    var Y = date.getFullYear() + "-";
    var M =
      (date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1) + "-";
    var D =
      (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
    var h =
      (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
  
    var m =
      (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
      ":";
    var s =
      date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D ;
     // return Y + M + D + h + m + s;
  }

  let maskbox = '';
  if (this.state.maskfalg) {
    maskbox = (
      <MaskBox
        info={{
          title: '支付失败',
          text: '获取支付信息失败。请稍后重试'
        }}
        closeMask={this.closeMask}>
      </MaskBox>
    )
  }
  if(this.state.buyerror){
    maskbox = (
      <MaskBox
        info={{
          title: '',
          text: '请选择购买数量'
        }}
        closeMask={this.closeMask}>
      </MaskBox>
    )
  }
    return (
      <div className='shopmalldetail'>
        {showcode}
        {priinfo}
      {maskbox}
        {this.state.loadinglayer?loadinglayer:null}

        <Header active='4'></Header>
        <div className="person-title"><div className="wrap">{this.typeids==999?"智能模拟云1小时体验":this.state.serviceinfo.name}</div></div>
        <div className="shopmalldetail_box">
          <div className={this.typeids==999?"none":'shopmalldetail_desc'}>
            <div className="shopmalldetail_desc_title">简介</div>
            <div className="shopmalldetail_desc_text">{this.state.getdetailpage?this.state.getdetailpage.descript:''}</div>
            <div className={this.typeids==999||!this.state.getdetailpage.courselist || this.state.getdetailpage.courselist.length<=0?"none":"shopmalldetail_desc_smalltitle"}>免费课程，抢先试听</div>
            
            <div className={this.typeids==999||!this.state.getdetailpage.courselist || this.state.getdetailpage.courselist.length<=0?"none":"shopmalldetail_desc_courselist"}>
              <div className="shopmalldetail_desc_courselist_box">
                <div className="swiper mySwiper">
                  <div className="swiper-wrapper">
                    {/* {this.state.getdetailpage.courselist &&this.state.getdetailpage.courselist.length>0?getgetailpage():null} */}
                    {this.state.getdetailpage.courselist &&this.state.getdetailpage.courselist.length>0? this.state.getdetailpage.courselist.map((item,index)=>{
                      console.log(item)
                      return <div className="swiper-slide" key={"swipercourselist" +index} onClick={()=>this.jumpdetail(item)}>
                      <div className="shopmalldetail_desc_courselist_box_img"><img src={item.headimg} /></div>
                      <div className="shopmalldetail_desc_courselist_box_title">{item.coursetitle}</div>
                      <div className="shopmalldetail_desc_courselist_box_tips">
                        <div className="people">
                          <img src={require('../../static/images/peopleIcon.png')} alt="" />
                          {item.teacher}
                        </div>
                        
                        <div className="people">
                          <img src={require('../../static/images/dateIcon.png')} alt="" />
                          {formattime(item.addtime)}
                        </div>
                      </div>
                    </div>
                    }):null}
                    {/* <div className="swiper-slide">
                      <div className="shopmalldetail_desc_courselist_box_img"><img src={require('../../static/images/shopmall/shopmall-banner.png')} /></div>
                      <div className="shopmalldetail_desc_courselist_box_title">随时随地可以让医护人员按需学习的影像学习工具平台，口袋里的影像百科全书</div>
                      <div className="shopmalldetail_desc_courselist_box_tips">
                        <div className="people">
                          <img src={require('../../static/images/mall/mall_rank_icon2.png')} alt="" />
                          免费课程
                        </div>
                        
                        <div className="people">
                          <img src={require('../../static/images/mall/mall_rank_icon2.png')} alt="" />
                          123
                        </div>
                      </div>
                    </div>
                    
                    <div className="swiper-slide">
                      <div className="shopmalldetail_desc_courselist_box_img"><img src={require('../../static/images/shopmall/shopmall-banner.png')} /></div>
                      <div className="shopmalldetail_desc_courselist_box_title">随时随地可以让医护人员全书</div>
                      <div className="shopmalldetail_desc_courselist_box_tips">
                        <div className="people">
                          <img src={require('../../static/images/mall/mall_rank_icon2.png')} alt="" />
                          免费课
                        </div>
                        
                        <div className="people">
                          <img src={require('../../static/images/mall/mall_rank_icon2.png')} alt="" />
                          123
                        </div>
                      </div>
                    </div>
                    
                    <div className="swiper-slide">
                      <div className="shopmalldetail_desc_courselist_box_img"><img src={require('../../static/images/shopmall/shopmall-banner.png')} /></div>
                      <div className="shopmalldetail_desc_courselist_box_title">随时随地可以让医护人员全书</div>
                      <div className="shopmalldetail_desc_courselist_box_tips">
                        <div className="people">
                          <img src={require('../../static/images/mall/mall_rank_icon2.png')} alt="" />
                          免费课
                        </div>
                        
                        <div className="people">
                          <img src={require('../../static/images/mall/mall_rank_icon2.png')} alt="" />
                          123
                        </div>
                      </div>
                    </div>
                    
                    <div className="swiper-slide">
                      <div className="shopmalldetail_desc_courselist_box_img"><img src={require('../../static/images/shopmall/shopmall-banner.png')} /></div>
                      <div className="shopmalldetail_desc_courselist_box_title">随时随地可以让医护人员全书</div>
                      <div className="shopmalldetail_desc_courselist_box_tips">
                        <div className="people">
                          <img src={require('../../static/images/mall/mall_rank_icon2.png')} alt="" />
                          免费课
                        </div>
                        
                        <div className="people">
                          <img src={require('../../static/images/mall/mall_rank_icon2.png')} alt="" />
                          123
                        </div>
                      </div>
                    </div>
                    
                    <div className="swiper-slide">
                      <div className="shopmalldetail_desc_courselist_box_img"><img src={require('../../static/images/shopmall/shopmall-banner.png')} /></div>
                      <div className="shopmalldetail_desc_courselist_box_title">随时随地可以让医护人员全书</div>
                      <div className="shopmalldetail_desc_courselist_box_tips">
                        <div className="people">
                          <img src={require('../../static/images/mall/mall_rank_icon2.png')} alt="" />
                          免费课
                        </div>
                        
                        <div className="people">
                          <img src={require('../../static/images/mall/mall_rank_icon2.png')} alt="" />
                          123
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
              
              <div className="swiper-button-next"></div>
                <div className="swiper-button-prev"></div>
            </div>
          </div>

          
          <div className={!this.state.getdetailpage.content && !this.state.getdetailpage.content?"none":'shopmalldetail_desc'}>
            
            <div className="shopmalldetail_desc_title">课程介绍</div>
            <div className="shopmalldetail_desc_contentbox" dangerouslySetInnerHTML={{ __html:this.typeids==999?"智能模拟云1小时体验": this.state.getdetailpage.content?this.state.getdetailpage.content:'' }} ></div>
          </div>

          
          <div className={this.state.serviceinfo.canbuy ==1?this.state.isneedpo?"shopmalldetail_desc nobottom":"shopmalldetail_desc ":'none'}>
            
            <div className="shopmalldetail_desc_title">购买明细</div>
            <div className="shopmalldetail_desc_buylist">
              <div className="shopmalldetail_desc_buylist_numbox">
                <div className="shopmalldetail_desc_buylist_numboxli shopmalldetail_desc_buylist_numbox_name">{this.typeids==999?"智能模拟云1小时体验":this.state.serviceinfo.name}</div>
                  <div className="shopmalldetail_desc_buylist_numboxli shopmalldetail_desc_buylist_numbox_num">
                    <span className="shopmalldetail_desc_buylist_numbox_numtips">购买数量</span>
                    <div className="num">
                      <select className="changeselect" 
                          value={this.state.buynum}
                          onChange={this.selectmonth}
                      >
                        <option value=""  >请选择</option>
                        <option value="1" >1个月</option>
                        <option value="2">2个月</option>
                        <option value="3">3个月</option>
                        <option value="4">4个月</option>
                        <option value="5">5个月</option>
                        <option value="6">6个月</option>
                        <option value="7">7个月</option>
                        <option value="8">8个月</option>
                        <option value="9">9个月</option>
                        <option value="10">10个月</option>
                        <option value="11">11个月</option>
                        <option value="12">12个月</option>
                      </select>
                      {/* {this.state.buynum} */}
                      {/* <div className="addnum" onClick={()=>this.addnum(1)}>+</div>
                      <div className="jiannum" onClick={()=>this.addnum(2)}>-</div> */}
                    </div>
                  </div>
                  <div className="shopmalldetail_desc_buylist_numboxli shopmalldetail_desc_buylist_numbox_money">¥{this.typeids==999?275*this.state.buynum:140*this.state.buynum}</div>
              </div>
            </div>
            
            <div className="shopmalldetail_desc_buystate">
              {/* <div className={this.state.buynum==2 || this.state.buynum==3 ?"shopmalldetail_desc_buystate_sale":"none"}>
                <span>赠送：</span>
                  <div  className="active">{this.state.buynum==2?"一个月":"三个月"}</div>

                  （时效性：{this.state.shimonth}）
              </div> */}
              
                  <div className={this.state.buynum?"shopmalldetail_desc_buystate_smalltitle":"none"}>有效期至：{this.state.mastday}</div>
              <div className="shopmalldetail_desc_buystate_change">
                <span>是否需要发票：</span>
                <div onClick={()=>this.changeisneedpo(true)} className={this.state.isneedpo?"active":""}>是</div>
                <div onClick={()=>this.changeisneedpo(false)} className={this.state.isneedpo?"":"active"}>否</div>
              </div>
            </div>

          </div>


          <div className={this.state.isneedpo?"shopmalldetail_desc shopmalldetail_desc_infobox":"none"}>
            <div className="shopmalldetail_desc_title">您的开票信息</div>
            <div className="shopmalldetail_desc_desctip">购课商城目前试运行阶段，预计两个月内将发票发送至您邮箱</div>
            <div className="shopmalldetail_desc_buyinfostate">
              <div className="shopmalldetail_desc_buystate_change">
                <span>发票类型</span>
                <div>咨询服务发票</div>
              </div>
            </div>
            
            <div className="shopmalldetail_desc_buyinfostate">
              <div className="shopmalldetail_desc_buystate_change">
                <span>抬头类型</span>
                <div  onClick={()=>this.changeiscompany(false)} className={this.state.iscompany?"changediv":"changediv active"}>个人</div>
                <div  onClick={()=>this.changeiscompany(true)} className={this.state.iscompany?"changediv active":"changediv"}>公司</div>
              </div>
            </div>


            
            <div className="shopmalldetail_desc_buyinfostate">
              <div className="shopmalldetail_desc_buystate_change">
                <span>发票内容</span>
                <div>咨询服务</div>
              </div>
            </div>

            
            <div className="shopmalldetail_desc_buyinfostate">
              <div className="shopmalldetail_desc_buystate_change">
                <span>发票金额</span>
                <div>{this.typeids==999?275*this.state.buynum:140*this.state.buynum}元</div>
              </div>
            </div>
      
            <div className="shopmalldetail_desc_buyinfostate">
              <div className="shopmalldetail_desc_buystate_change">
                <span>发票抬头<i>*</i></span>
                <div className="shopmalldetail_desc_buystate_inputbox">
                  <input type="text"  name='postponame' onInput={this.changeValue}  className="buystate_input" />
                  {this.state.postponameerror?<div className="error">请输入发票抬头</div>:null}
                </div>
              </div>
            </div>
      
            {this.state.iscompany?<div className="shopmalldetail_desc_buyinfostate">
              <div className="shopmalldetail_desc_buystate_change">
                <span>纳税人识别号<i>*</i></span>
                <div className="shopmalldetail_desc_buystate_inputbox">
                  <input type="text"  name='postponum' onInput={this.changeValue}  className="buystate_input" />
                  {this.state.postponumerror?<div className="error">请输入纳税人识别号</div>:null}
                </div>
              </div>
            </div>:null}
            
            <div className="shopmalldetail_desc_buyinfostate">
              <div className="shopmalldetail_desc_buystate_change">
                <span>收件人邮箱<i>*</i></span>
                <div className="shopmalldetail_desc_buystate_inputbox">
                  <input type="text"   name='postpoemail' onInput={this.changeValue}   className="buystate_input" />
                  {this.state.postpoemailerror?<div className="error">{this.state.emailerrortext}</div>:null}
                </div>
              </div>
            </div>
            {this.state.iscompany?<>
            <div className="shopmalldetail_desc_buyinfostate">
              <div className="shopmalldetail_desc_buystate_change">
                <span>公司地址</span>
                <div className="shopmalldetail_desc_buystate_inputbox">
                  <input type="text"  name='postpoaddress' onInput={this.changeValue}   className="buystate_input" />
                </div>
              </div>
            </div>
            
            <div className="shopmalldetail_desc_buyinfostate">
              <div className="shopmalldetail_desc_buystate_change">
                <span>公司电话</span>
                <div className="shopmalldetail_desc_buystate_inputbox">
                  <input type="text"  name='postpophone'  onInput={this.changeValue}  className="buystate_input" />
                </div>
              </div>
            </div>
            
            <div className="shopmalldetail_desc_buyinfostate">
              <div className="shopmalldetail_desc_buystate_change">
                <span>公司开户行</span>
                <div className="shopmalldetail_desc_buystate_inputbox">
                  <input type="text"  name='postpopbank'  onInput={this.changeValue}  className="buystate_input" />
                </div>
              </div>
            </div>
            
            <div className="shopmalldetail_desc_buyinfostate">
              <div className="shopmalldetail_desc_buystate_change">
                <span>开户行账号</span>
                <div className="shopmalldetail_desc_buystate_inputbox">
                  <input type="text" name='postpopbanknum'  onInput={this.changeValue}  className="buystate_input" />
                </div>
              </div>
            </div>
            </>:null}



          </div>
        </div>
        <div className="shopmalldetail_buybtnbox">
          <div className="shopmalldetail_buybtnbox_list">
          {this.state.serviceinfo.canbuy==1?<> <div className="shopmalldetail_buybtnbox_title">应支付：<span>¥{this.typeids==999?275*this.state.buynum:140*this.state.buynum}</span></div>
            <div className={this.state.privacy?"shopmalldetail_buybtnbox_pri active":"shopmalldetail_buybtnbox_pri"}><span className="icon " onClick={()=>this.changepristate()}></span>我已阅读并同意西门子<span  onClick={()=>this.showMask()}>《销售条款》</span>和<span  onClick={()=>this.showMask()}>《隐私政策》 </span>以下条款</div>
           {this.state.privacyerror?<div className='privacyerror'>请先同意条款</div>:null} 
           </>:null}
           {this.state.serviceinfo.canbuy==1?<div className="shopmalldetail_buybtnbox_gobuy" onClick={()=>this.gopay()}>购买</div>:<div className="shopmalldetail_buybtnbox_gobuy" onClick={()=>this.gocourse()}>查看课程</div>} 
          </div>
        </div>
        <Footer></Footer>
      </div>
    )
  }
}

export default Shopmallindex
