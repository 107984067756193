import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import Iscroll from 'iscroll/build/iscroll-probe';
import axios from 'axios';
import PostItem from '../postItem';


import './index.css'
function PersonImg(props) {
    let userid = localStorage.getItem('userid'), token = localStorage.getItem('token');
    let [postsList, setPostsList] = useState([])
    let [dellist, setDellist] = useState('')




    useEffect(() => {
        init()
        console.log(props)
    }, [])

    let init = () => {
        getPostsList()
    }

    // 获取草稿箱列表
    let getPostsList = async () => {
        let res = await axios({
            method: 'get',
            headers: {
                "token": token
            },
            url: 'https://shl-info.cn/front/bbs/userArticleList',
            params: {
                user_id: userid,
                state: 0
            }
        })
        let data = res.data
        setPostsList(data.data)
    }
    let showlayer = (ids) => {
        setDellist(ids)
        console.log(ids)
    }
    let changenodel = () => {
        setDellist('')
    }
    let changeokdel = () => {
        console.log(dellist)
        deltalk(postsList[dellist]);
    }
    let deltalk = async (arry) => {
        var url = 'https://shl-info.cn/front/bbs/createArticle/article_id/' + arry.id + '?user_id=' + userid;

        let res = await axios({
            method: 'post',
            headers: {
                "token": token
            },
            url: url,
            data: JSON.stringify({
                topic_id: arry.topic_id,
                course_id: arry.course_id,
                state: 3,
                title: arry.title,
                data: arry.data,
            }),
            cache: false
        })
        let data = res.data

        setDellist('')
        getPostsList();
        console.log(data)
    }

    let postItemDom = () => {
        if (postsList.length === 0) {
            return <div className="postListNotTips">没有帖子哦~</div>
        }
        return postsList.map((item, index) => {
            return (
                <PostItem data={item} key={index} hide={['userLabel']}>
                    <div className="postItemPos">
                        <div className="postItemButton blue" onClick={() => props.preview(item.id)}>预览</div>
                        <div className="postItemButton blue" onClick={() => props.changetalk(item.id)}>修改</div>
                        <div className="postItemButton yellow" onClick={() => showlayer(index)}>删除</div>
                    </div>
                </PostItem>
            )
        })
    }


    return (
        <section className="posts">
            <div className={dellist === '' ? "none" : "changedellayer"}>
                <div className="changedellayer-maskbox">
                    <h2 className="changedellayer-masktitle">
                        {/* 是否删除 */}
                            是否确认删除
                        </h2>
                    <div className="changedellayer-btnlist">
                        <span onClick={() => changeokdel()}>是</span>
                        <span className="nomargin" onClick={() => changenodel()}>否</span>
                    </div>
                </div>
            </div>
            <div className="postsContent">
                <div className="postsHead">
                </div>
                <div className="postsBody">
                    {postItemDom()}
                </div>
            </div>
        </section>
    )
}
// mapStateToProps：将state映射到组件的props中
const mapStateToProps = (state) => {
    return {
        userinfo: state.userinfo
    }
}

// mapDispatchToProps：将dispatch映射到组件的props中
const mapDispatchToProps = (dispatch) => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonImg)
