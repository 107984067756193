import React, { Component } from 'react';
import $ from 'jquery'
import './index.css';
import Maskbox from '../../../../components/maskbox';

let timer = '';
class Onlinediexam extends Component {
  constructor(props) {
    super(props);
    this.closeMask = this.closeMask.bind(this);
    this.state = {
      masktext: "提交成功",
      submitmask: false,
      examlist: [],
      usercount: '',
      starexam:false,
      clocktimes:'',
      examtimes:0,
      showlayer:false,
      
    };
    this.intervalId = null;  

  }


  changeanswer(type, parent, index, e) {
    let new_arr = this.props.examlist;
    if (type === "1") {
      new_arr[parent].answer.forEach(item => {
        if (item.id === index) {
          item.active = true;
        } else {
          item.active = false;
        }
      });
    } else {
      new_arr[parent].answer[index].active = !new_arr[parent].answer[index]
        .active;
    }
    if (!new_arr[parent].active) {
      new_arr[parent].active = true;
    }
    this.props.change(new_arr);
  }
  submitexam(type) {
    // 检查是否答完所有题目
    let examComplete = [];
    let start = 0;
    let answlist = [];
    this.props.examlist.forEach(item => {
      
      examComplete[start] = { id: item.index, answer: [] };
      for (let i = 0; i < item.answer.length; i++) {
        if (item.answer[i].active && !examComplete[start]) {
          examComplete[start] = { id: item.index, answer: [] };
          examComplete[start].answer.push(item.answer[i].title);
          
          if(!answlist.includes(item.index)){
            answlist.push(item.index)
          }
        } else if (item.answer[i].active && examComplete[start]) {
          examComplete[start].answer.push(item.answer[i].title);
          
          if(!answlist.includes(item.index)){
            answlist.push(item.index)
          }
        }
      }
      start += 1;
    });
    console.log(answlist)
    console.log(examComplete)
    console.log(this.props.examlist)
    if ( answlist.length === this.props.examlist.length  || type ==1) {
      $.ajax({
        type: "POST",
        url: "https://shl-info.cn/front/groupchat/savesurvergrouppack?userid=" +
          localStorage.getItem('userid') +"&chattype=2"+
          "&groupid=" +
          this.props.groupid
          +"&packid="+this.props.packidinfo.packid
          ,
        data: JSON.stringify({
          data: examComplete
        }),
        dataType: "json",
        headers: { 'token': localStorage.getItem('token') },
        cache: false
      }).then(res => {
        console.log(1111, res)
        if (res && res.status !== 2 && res.status !== 3) {
          this.props.gotoresult();
        }
        if (res && res.status == 3) {
          this.setState({
            masktext: "检测到您已在其他设备上通过本次考试",
            submitmask: true
          });
        }
      });
    } else {
      console.log(1111)
      this.setState({
        masktext: "请回答所有问题",
        submitmask: true
      });
    }
  }
  gochangedetail(){ 
    this.props.gotoresult();
  }
  again() {

    if (this.props.packinfodetail && this.props.packinfodetail.userqacount>=80) {
      this.setState({
        islayer: true,
      })
      this.props.history.push("/certificategrouppack?type=" + this.props.groupid+"&packid="+this.props.packidinfo.packid);
    } else {
      
     clearInterval(this.intervalId);  
      // this.props.changeagain();
    }
    // if (this.state.usercount >= 80 && this.props.classid != 152) {
    //   this.props.gotocertificate();
    //   //this.props.history.push("/Examresults" );
    // } else if (this.state.usercount >= 100 && this.props.classid == 152) {
    //   this.props.gotocertificate();
    //   //this.props.history.push("/Examresults" );
    // } else {
    //   this.setState({
    //     islayer: true,
    //   })
    // }
  }
  closeMask() {
    if(this.state.masktext=='检测到您已在其他设备上通过本次考试'){
      console.log(1111)
      this.props.gotoresult();
      this.setState({
        submitmask: false,
        masktext:'',
      });
    }else{
      this.setState({
        submitmask: false
      });
    }
  }
  componentDidUpdate() {
    // console.log(this.props)
    // if(this.props.examtimes == 0 && this.props.statexamstate){
    //   this.submitexam(1);
    // }
    console.log(this.props.packinfodetail)
    if(this.props.packidinfo && this.props.packidinfo.userqacount>=80){
      console.log('考完了')
    }
    if (this.state.usercount == '' && this.props.userqastate == 1) {
      console.log(11111)
      this.setState({
        usercount: this.props.usercount,
      })
    }
    if (this.props.userqastate == 1 && this.state.islayer) {
      console.log(2222)
      this.setState({
        islayer: false,
      })
    }
  }
  componentDidMount() {

    console.log(this.props)
    if(this.props.packinfodetail){
      this.setState({
        examtimes:this.props.packinfodetail.timelength,
        // examtimes:10
      })
    }
    // this.setState({
    //   usercount: this.props.usercount,
    // })
    if (this.props.userqastate == 1) {
      this.setState({
        islayer: false,
      })
    } else {

      this.setState({
        islayer: true,
      })
    }

  }
  componentWillUnmount() { 
    clearInterval(this.intervalId);  
  }

  tick = () => {  
    let times = this.state.examtimes -1;
    console.log(times)
    this.setState({  
      examtimes: times,  
      clocktimes:this.convertSecondsToMinutesSeconds(times),
    })
    if (this.state.examtimes > 0) {  
      
    } else {  
      clearInterval(this.intervalId);  
      this.submitexam(1);
    }  
  }  
  changeexam = ()=>{
    this.intervalId = setInterval(() => {  
      this.tick();  
    }, 1000);  
    this.setState({
      starexam:true,
    })
  }

  convertSecondsToMinutesSeconds = (totalSeconds) => {  
    var minutes = Math.floor(totalSeconds / 60);  
    var seconds = totalSeconds % 60;  
  
    minutes = minutes < 10 ? "0" + minutes : minutes;  
    seconds = seconds < 10 ? "0" + seconds : seconds;  
  
    return minutes + ":" + seconds;  
  }  
  render() {
    let examlist = this.props.examlist.map((item, index) => {
      let answer = item.answer.map((list, listindex) => (
        <div
          className="exam-li"
          key={listindex}
          onClick={e => this.changeanswer(item.type, index, listindex, e)}
        >
          {list.title}
          <span
            className={
              item.type === "1"
                ? list.active
                  ? "radio active"
                  : "radio"
                : list.active
                  ? "checkout active"
                  : "checkout"
            }
          >
            <span></span>
          </span>
        </div>
      ));
      return (
        <div key={index} className="exam-wrap groupinfo">
          <h2 className="exam-title groupinfo">
            {index + 1}. {item.title}({item.type === "1" ? "单选" : "多选"})
          </h2>
          <div className="exam-list">{answer}</div>
        </div>
      );
    });
    let exammask = null;
    if (this.state.submitmask) {
      exammask = (
        <Maskbox
          info={{
            title: "在线考试",
            text: this.state.masktext
          }}
          closeMask={this.closeMask}
        ></Maskbox>
      );
    }
    var examstate = 1;
    if ((this.props.classid == 919 || this.props.classid == 918 || this.props.classid == 917 || this.props.classid == 841) && this.props.userqastate == 1) {
      examstate = 0;
    }
    // console.log(this.props)


    // let courseidlist = ["152","154","155","156","157","158","417","648","737","862","1375","1761","1965","428","1444","2432"];
    let courseidlist = ["152", "1375", "2432", "3058"];
    return (
      <>
      <div className={this.state.starexam && this.state.clocktimes?"exampacktime":"none"}><span className="examtimes">{this.state.clocktimes}</span></div>
      <div className='newexambox'>
      <div className="exam-scroll group grouppadding">
        
        <div className={this.state.starexam?"none":"exam-tipslayer"}>
          <div className='exam-tipslayer-title'>考试说明</div>
          <div className='exam-tipslayer-desc'>1、本场考试限时{this.props.packinfodetail.timelength?this.props.packinfodetail.timelength/60:"-"}分钟，超时将自动交卷，请考生把握好时间</div>
          <div className='exam-tipslayer-desc'>2、考试成绩80分为合格，不合格者需重新考试</div>
          <div className='exam-tipslayer-btn' onClick={()=>this.changeexam()}>开始考试</div>
        </div>
        <div className={this.state.starexam?"wrap":"none"}>
          <div className="iscroll-padding">{examlist}

          </div>

          {
            this.props.examlist.length > 0 && examstate == 1 ? (
              <div
                className="exam-submit touchCss"
                onClick={() => this.submitexam()}
              >
                提交
              </div>
            ) : null
          }
          {exammask}
        </div>
        <div className={this.props.packinfodetail && this.props.packinfodetail.userqastate ==1 && this.props.packinfodetail.userqacount>=80 ? "exam_layer " : "exam_layer none"}>
          <div class="result-number "><h5><span>{this.props.packinfodetail && this.props.packinfodetail.userqacount?this.props.packinfodetail.userqacount:0}</span>分</h5>

            <p>{this.props.packinfodetail && this.props.packinfodetail.userqacount>=80  ? "恭喜您通过了考试" : "本次考试80分通过，继续努力！"}</p>

            <div className="down-footer">
              {/* {examstate == 1 ? ( */}
                <div
                  className="exam-submit touchCss"
                  onClick={() => this.again()}
                >
                  <span>{this.props.packinfodetail && this.props.packinfodetail.userqacount>=80  ? "查看证书" : "重新考试"}</span>

                </div>
              {/* ) : null} */}

            </div>

            {this.props.packinfodetail.userqacount>=80?<div className="down-footer">
               
               <div
                 className="exam-submit touchCss"
                 onClick={() => this.gochangedetail()}
               >
                 <span>查看详情</span>

               </div>
           </div>: null}
          </div>
        </div>
      </div>
      </div>
      </>
    );
  }
}

export default Onlinediexam;
