import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { change_person_classlist } from '../../store/action'
import LearningPlanadmin from './index.bundle'

const mapStateToProps = (state) => {
  return {
    classlist: state.person_classlist
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    change_person_classlist(data) {
      dispatch(change_person_classlist(data))
    }
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LearningPlanadmin)
);
