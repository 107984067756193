import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import $ from 'jquery'
import '../Exam/index.css';
// import Scorelayer from '../../components/scorelayer'

class ExamResults extends Component {
  constructor() {
    super();
    this.state = {
      achievement: 0,

      addsrorestate: false,
      addsroretext: '',
      addsrore: 0,

      examlist: []
    };
  }
  get_answer = treuanswer => {
    // 分析分数
    let new_arr = treuanswer;
    console.log(new_arr)
    console.log(232323232323)
    if (new_arr) {
      new_arr.forEach(item => {
        console.log(item.answer)
        for (var i = 0; i < item.answer.length; i++) {
          if (!item.myanswer) {
            item.myanswer = '';
          }
          if (
            item.myanswer.split("#_#").includes(item.answer[i].title) &&
            item.trueansweer.split("#_#").includes(item.answer[i].title)
          ) {
            // 选择正确
            item.answer[i].classname = "classTrue";
          } else if (
            !item.myanswer.split("#_#").includes(item.answer[i].title) &&
            item.trueansweer.split("#_#").includes(item.answer[i].title)
          ) {
            // 漏选
            item.answer[i].classname = "classLess";
          } else if (
            item.myanswer.split("#_#").includes(item.answer[i].title) &&
            !item.trueansweer.split("#_#").includes(item.answer[i].title)
          ) {
            // 选择错误
            item.answer[i].classname = "classError";
          }
        }
        if (item.myanswer === item.trueansweer) {
          item.yesfalg = true;
        } else {
          item.yesfalg = false;
        }
      });

      console.log(1111222)
      console.log(new_arr)
      this.setState({
        examlist: new_arr
      });
    }
  };
  componentDidMount() {
    let that = this;

    console.log(this.props)
    console.log('test', this.props)
    $.ajax({
      type: "GET",
      url:
        "https://shl-info.cn/front/frontc/survergroupdeatil?userid=" +
        localStorage.getItem('userid') +
        "&groupid=" +
        this.props.groupid,
      dataType: "json",
      headers: { 'token': localStorage.getItem('token') },
      cache: false
    }).then(res => {
      console.log(res)
      if (res && res.status !== 2) {
        this.setState({
          achievement: res.achievement
        });
        this.get_answer(res.questions);
      }
    });
  }
  examHref() {

    // let courseidlist = ["152","154","155","156","157","158","417","648","737","862","1375","1761","1965","428","1444","2432"];
    let courseidlist = ["152", "1375", "2432", "3058"];
    if (this.state.achievement >= 80) {
      this.props.history.push("/certificategroup?type=" + this.props.groupid);
    } else {
      window.location.href = window.location.href;
    }
  }
  componentWillUnmount() { }
  render() {
    console.log(this.state.examlist)
    let examlist = this.state.examlist.map((item, aindex) => {
      console.log(aindex)
      let answer = item.answer.map(list => (
        <div className={"exam-li " + list.classname} key={list.id}>
          {list.title}
          <span
            className={
              item.type === "1"
                ? list.active
                  ? "radio active"
                  : "radio"
                : list.active
                  ? "checkout active"
                  : "checkout"
            }
          >
            <span></span>
          </span>
        </div>
      ));
      console.log(this.props)

      return (
        <div key={item.index} className="exam-wrap no-padding">
          <h2 className="exam-title">
            {aindex + 1}. {item.title}({item.type === "1" ? "单选" : "多选"})
            ({item.yesfalg ? <span className='green' style={{ "color": "green", "margin": "0", "display": "inline-block" }}>正确</span> : <span className='red' style={{ "color": "red", "margin": "0", "display": "inline-block" }}>错误</span>})
          </h2>
          <div className="exam-list">{answer}</div>
          <div className={item.yesfalg ? "analysis" : "analysis active"}>
            <h6>题{aindex + 1}解析</h6>
            {item.analysis}
          </div>
        </div>
      );
    });

    var examstate = 1;
    var text = '恭喜获得证书';
    if ((this.props.groupid == 919 || this.props.groupid == 918 || this.props.groupid == 917 || this.props.groupid == 841)) {
      examstate = 0;
      text = '恭喜通过SAT考试';
    }



    let addsrorelayer = null;
    // if (this.state.addsrorestate) {
    //   addsrorelayer = <Scorelayer scorenum={this.state.addsrore} showtext={this.state.addsroretext}></Scorelayer>
    // }


    // let courseidlist = ["152","154","155","156","157","158","417","648","737","862","1375","1761","1965","428","1444","2432"];
    let courseidlist = ["152", "1375", "2432", "3058"];
    // console.log(courseidlist.includes(this.props.groupid.toString()))
    return (
      <div className="examresultpage">
        {addsrorelayer}
        <div className="scroll">
          <div className="result-number">
            <h5>
              {this.state.achievement}<span>分</span>
            </h5>
            {this.props.di == 1 ? null :
              <p>
                {this.state.achievement >= 80
                  ? text
                  : "本次考试80分通过，继续努力！"}
              </p>
            }
          </div>
          {examlist}
        </div>
        {this.props.di == 1 ? null : examstate == 1 ? (
          <div className="resultBtn touchCss" onClick={() => this.examHref()}>
            { this.state.achievement >= 80 ? "查看证书" : "重新考试"}
          </div>
        ) : null}

      </div>
    );
  }
}

export default withRouter(ExamResults);
