import React, { Component } from 'react'
// 组件
import Header from '../../components/header'
import Footer from '../../components/footer'
import PersonImg from '../../components/personimg'
import LayoutClass from '../../components/layoutclass'
import CLassLabel from '../../components/classlabel'
import MaskBox from '../../components/maskbox';
import $ from 'jquery';

import './index.css'

class LearningPlanuser extends Component {
  constructor() {
    super();

    this.state = {
      active: 0,
      iscroll: null,
      //worktextlist: ['', '沙龙1-1A', '沙龙1-1B', '沙龙1-2', '沙龙2-1A', '沙龙2-1B', '沙龙2-2', '沙龙3-1A', '沙龙3-1B', '沙龙3-2'],
      
      worktextlist: ['', 'HPS作业提交', '', 'PLP作业提交', '沙龙2-1A', '沙龙2-1B', '沙龙2-2', '沙龙3-1A', '沙龙3-1B', '沙龙3-2'],
      imglist: [],
      piclist: [],
      newUrl: require("../../static/images/certificatedataback.jpg"),
      subsubtext: null,
      submitmask: false,
      substate: false,

      masktext: '保存成功',
    }
  }

  getUrlkey(url) {
    var params = {};
    var urls = url.split("?");                  //console.log('1_分割url:', urls)
    var arr = urls[1].split("&");               //console.log('2_分割urls[1]:', arr)
    for (var i = 0, l = arr.length; i < l; i++) {
      var a = arr[i].split("=");                //console.log('3_遍历 arr 并分割后赋值给a:', a[0], a[1])
      params[a[0]] = a[1];                      //console.log('4_a给params对象赋值:', params)
    }                                           //console.log('5_结果:', params)
    return params;
  }
  componentDidMount() {
    this.props.change_person_classlist()
    let userid = (localStorage.getItem('userid'));
    let token = (localStorage.getItem('token'));
    var urls = this.getUrlkey(window.location.href);
    console.log(urls.typeid)
    this.typeid = urls.typeid;
    let that = this;
    fetch(
      "https://shl-info.cn/front/frontc/getuserdraft?userid=" + userid +
      "&typeid=" + this.typeid ,{
        headers:{
          'token':token 
        }
      }
    )
      .then(data => {
        return data.json();
      })
      .then(res => {
        console.log(res)
        if (res.data) {
          if (res.data.piclist) {
            var imglist = [];
            var piclist = [];
            for (var i = 0; i < res.data.piclist.length; i++) {
              piclist.push(res.data.piclist[i].pic)
              imglist.push(res.data.piclist[i].id)
            }
            that.setState({
              imglist: imglist,
              piclist: piclist,
            })
          }
          that.setState({
            subtext: res.data.subtext,
          })
        }
      });
  }
  componentWillUnmount() {

  }


  subworks = () => {
    this.setState({
      substate: true,
    })
    let userid = (localStorage.getItem('userid'));
    let token = (localStorage.getItem('token'));
    var text = this.state.subtext;
    var subimg = this.state.imglist;
    var subimglist = '';
    if (subimg.length > 0) {
      for (var i = 0; i < subimg.length; i++) {
        subimglist = subimglist + subimg[i] + ',';
      }
    }
    if (!text) {
      this.setState({
        substate: false,
      })
      return false;
    }
    console.log(111)
    //return false;
    fetch(
      "https://shl-info.cn/front/frontc/adduserworks?userid=" + userid +
      "&typeid=" + this.typeid +
      "&type=1"  +
      "&subtext=" + text +
      "&subimglist=" + subimglist,{
        headers:{
          'token':token 
        }
      }
    )
      .then(data => {
        return data.json();
      })
      .then(res => {
        this.setState({
          substate: false,
        })
        if (res.status === 1) {
          this.props.history.push("/learningplan");
        }
      });
    console.log(text, subimg)
  }


  saveworks = () => {

    let userid = (localStorage.getItem('userid'));
    let token = (localStorage.getItem('token'));
    $('texttextarea').blur();
    var text = this.state.subtext;
    var subimg = this.state.imglist;
    var subimglist = '';
    if (subimg.length > 0) {
      for (var i = 0; i < subimg.length; i++) {
        subimglist = subimglist + subimg[i] + ',';
      }
    }
    fetch(
      "https://shl-info.cn/front/frontc/adduserworksdraft?userid=" + userid +
      "&typeid=" + this.typeid +
      "&type=1"+
      "&subtext=" + text +
      "&subimglist=" + subimglist,{
        headers:{
          'token':token 
        }
      }
    )
      .then(data => {
        return data.json();
      })
      .then(res => {
        if (res.status === 1) {
          //this.props.history.push("/learningplan");
        }
      });
    console.log(text, subimg)
    this.setState({
      submitmask: true,
    })
  }
  closeMask = () => {

    this.setState({
      submitmask: false,
    })
  }

  valueChange = (e) => {
    console.log(e.target.value)
    this.setState({
      subtext: e.target.value
    })
  }
  imgupload = (e) => {
    console.log(e)
    let that = this
    var imgfile = document.querySelector('#imgFile');//获取input
    var file = imgfile.files[0];
    // var reader = new FileReader();
    // reader.readAsDataURL(file);
    // reader.onload = function (e) {
    //   // 图片base64化
    //   var newUrl = this.result;
    //   that.setState({
    //     newUrl: newUrl
    //   })
    // };
    var formFile = new FormData();

    let userid = (localStorage.getItem('userid'));
    let token = (localStorage.getItem('token'));
    formFile.append("uploadFile", file); //加入文件对象
    $.ajax({
      type: "post",
      url: "https://shl-info.cn/front/frontc/adduserworkspc?userid=" + userid ,
      data: formFile,
      //是否预处理,默认值为true,这里改成false
      processData: false,
      //是否设置内容,默认值为true,这里改成false
      contentType: false,
      headers:{'token':localStorage.getItem('token')},
      success: function (res) {
        //alert('提交成功');
        var backres = JSON.parse(res);
        let arr = that.state.piclist
        let arrimg = that.state.imglist;
        arr.push(backres.pic);
        arrimg.push(parseInt(backres.id))
        console.log(arr)
        imgfile.value = '';
        // arr.splice(e, 1);
        // arrimg.splice(e, 1);
        that.setState({
          piclist: arr,
          imglist: arrimg,
        })
      },
      error: function (data) {
        //alert('提交失败');
      }
    });
  }
  removethisimg = (e) => {
    console.log(e)
    let arr = this.state.piclist
    let arrimg = this.state.imglist
    arr.splice(e, 1);
    arrimg.splice(e, 1);
    this.setState({
      piclist: arr,
      imglist: arrimg,
    })
  }
  render() {
    // 列表
    let coursemask = null;
    if (this.state.submitmask) {
      console.log('保存成功')
      coursemask = (
        <MaskBox
          info={{
            title: '在线考试',
            text: this.state.masktext
          }}
          closeMask={this.closeMask}>
        </MaskBox>
      )
    }
    return (
      <div className='learningplan'>
        <Header active='4'></Header>
        <div className="learingplan_user">
          <div className="learingplan_user_title">{this.state.worktextlist[this.typeid]}</div>
          <div className="learingplan_user_box">
            <textarea placeholder="请输入文字"
              value={this.state.subtext}
              onChange={(e) => this.valueChange(e)}></textarea>
          </div>
          <div className="learingplan_user_imglist">
            <ul>
              {
                (this.state.piclist.length == 0)
                  ? null
                  : this.state.piclist.map((item1, index1) => {
                    return (
                      <li>
                        <img src={item1} alt="" />
                        <span className="closeimg" onClick={() => this.removethisimg(index1)}></span>
                      </li>
                    )
                  })
              }

              {/* <li>
                <img src={require("../../static/images/certificatedataback.jpg")} alt="" />
                <span className="closeimg"></span>
              </li>
              <li>
                <img src={require("../../static/images/certificatedataback.jpg")} alt="" />
                <span className="closeimg"></span>
              </li>
              <li>
                <img src={require("../../static/images/certificatedataback.jpg")} alt="" />
                <span className="closeimg"></span>
              </li>
              <li>
                <img src={require("../../static/images/certificatedataback.jpg")} alt="" />
                <span className="closeimg"></span>
              </li> */}
              {/* <li>
                <img src={this.state.newUrl} alt="" />
                <span className="closeimg"></span>
              </li> */}
              <li className="uploadimg"><input type="file" id="imgFile" name='img' accept=".jpg,.png,.jpeg" onChange={(e) => this.imgupload(e)} /></li>
            </ul>
          </div>
          <div className="btnlist">
            <div className="btn_left" onClick={() => this.saveworks()}>保存草稿</div>
            <div className="btn_right" onClick={() => this.subworks()}>完成作业</div>
          </div>
        </div>
        {coursemask}
        <div className={this.state.substate ? "layer" : "layer none"}>
          <div className="layer_ts"> 提交中</div>
        </div>
        <Footer></Footer>
      </div>
    )
  }
}

export default LearningPlanuser
