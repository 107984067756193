import React, { Component } from 'react'
import $ from 'jquery'
// 组件
import Header from '../../components/header'
import Footer from '../../components/footer'
import ClassList from '../../components/classlist'

import './index.css'

class SearchPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      list: [],
      allnum: 0,
      
      seachkeyword:this.props.common_search,
      page:1,
      isstate:1,
      //isstate:this.props.location.search.indexOf('source=files') > -1?2:1,
      isover:false,
      countcourse:0,

    }
  }
  searchinit(labellist, text) {
    if (!this.ajaxfalg) {
      return false;
    }
    this.ajaxfalg = false;
    let labelarr = [];
    labellist.forEach((item) => {
      item.list.forEach((list) => {
        if (list.active) {
          labelarr.push(list.id)
        }
      })
    })
    let that = this;
    this.setState({
      seachkeyword:text,
    },()=>{
      $.ajax({
        type: "POST",
        url:
          "https://shl-info.cn/front/frontc/searchcourse?userid=" +
          that.props.userinfo.userid +"&page="+that.state.page,
        data: JSON.stringify({
         // title: text,
          title: that.state.seachkeyword,
          tags: labelarr
        }),
        dataType: "json",
        headers:{'token':that.props.userinfo.token},
        cache: false
      }).then(res => {
        that.ajaxfalg = true;
        if (res && res.status !== 2) {
          let searchcourselist=[];
            if(res.searchcourselist){
              if(that.state.page==1){
                searchcourselist = res.searchcourselist
              }else{
                searchcourselist = that.state.list.concat(res.searchcourselist)
              }
            }
            
            let isstate=false
            if(res.searchcourselist.length<=0 &&  that.state.isstate==1){
              isstate =true;
            }
  
            
            let hotattachlist=[];
            if(res.hotattachlist.length<=0 &&  that.state.isstate==2){
              isstate =true;
            }
            if(res.hotattachlist){
              
              if(that.state.page==1){
                hotattachlist = res.hotattachlist
              }else{
                hotattachlist = that.state.filelist.concat(res.hotattachlist)
              }
            }
            that.setState({
              list: searchcourselist,
              filelist: hotattachlist,
              countcourse:res.coursecount,
              allnum:res.coursecount,
             // ajaxfalg: true,
              isover:isstate,
            })
  
  
          // that.setState({
          //   list: res.searchcourselist,
          //   allnum: res.searchcourselist.length
          // });
          that.ajaxfalg = true;
        }
      });
    })
    
    
    
  }
  changeLabel = (arr) => {
    // 筛选
    this.searchinit(arr, this.props.common_search)
  }
  searchCollback = (text) => {
    // 搜索
    console.log("seach")
    this.setState({
      page:1,
      isover:false,
      filelist:[],
      list:[],
    },()=>{
      this.searchinit(this.props.labellist, text)
    })
    //this.searchinit(this.props.labellist, text)
  }
  checkScrollEnd = () => {
    console.log(this.state.page)

    if (!this.ajaxfalg || this.state.isover) {
      return false;
    }
    this.setState({
      page:this.state.page+1,
    },()=>{
      this.searchinit(this.props.labellist, this.state.seachkeyword)
    })
  }
  isScrollBottom = () => {
    var scrollElement = $('body')[0]; // 或者 document.body，根据实际情况选择滚动的元素
    var scrollTop = scrollElement.scrollTop; // 滚动的距离
    var clientHeight = scrollElement.clientHeight; // 可视区域高度
    var scrollHeight = scrollElement.scrollHeight; // 滚动内容的总高度
    console.log(scrollTop + clientHeight)
    console.log(scrollHeight)
    if(scrollTop + clientHeight >=scrollHeight-50){
      this.checkScrollEnd();
    }
  }
  // is
  componentDidMount() {
    this.ajaxfalg = true;
    this.searchinit(this.props.labellist, this.props.common_search)

    $('body')[0].addEventListener('scroll', this.isScrollBottom)
   

  }
  componentWillUnmount() {

  }
  render() {
    let list = this.state.list.map((item) => {
      return (
        <ClassList key={item.id} _item={item}></ClassList>
      )
    })
    return (
      <div className='personpage'>
        <Header
          active={this.props.active}
          searchCollback={this.searchCollback}
          changeLabel={this.changeLabel}></Header>
        <div className="wrap personal-margin">
          <div className="seachnumb">为您找到相关结果{this.state.allnum}个</div>
          


          {list}
          


        </div>
        
        {!this.ajaxfalg && !this.state.isover?<div className="ajaxloading">加载中...</div>:""}
        {this.state.isover?<div className="ajaxloading">已全部加载...</div>:""}
        <Footer></Footer>
      </div>
    )
  }
}

export default SearchPage
