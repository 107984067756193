// 获取链接上所有参数
const getUrlParams = () => {
    var urlArr = window.location.href.split('?'); //获取url中"?"符后的字串

    var url = '';
    if (urlArr.length > 1) {
        url = "?" + urlArr[1];
    };
    var theRequest = new Object();
    if (url.indexOf("?") != -1) {
        var str = url.substr(1);
        var strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
            theRequest[strs[i].split("=")[0]] = decodeURIComponent((strs[i].split("=")[1]));
        }
    };
    return theRequest;
}
export default {
    getUrlParams
}