import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import CourseDetails from './index.bundle'

const mapStateToProps = (state) => {
  return {
    active: state.active,
    information: state.course_bannerlist,
    userinfo: state.userinfo
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CourseDetails)
);
