import React, { Component } from 'react'

import $ from 'jquery';
// 组件
import Header from '../../components/header'
import Footer from '../../components/footer'
import SelectBox from '../../components/selectbox'
import FileHots from '../../components/filehots'
import MaskBox from '../../components/maskbox'
// import Scorelayer from '../../components/scorelayer'

import FileType from '../../components/filetype'
import Swiper from 'swiper'




import Scorelayer from '../../components/scorelayer2023';

import './index.css'

class HomeFile extends Component {
  constructor() {
    super()
    this.changeSelect = this.changeSelect.bind(this)
    this.loveClick = this.loveClick.bind(this)
    this.closeMask = this.closeMask.bind(this)
    this.hotlistDown = this.hotlistDown.bind(this)
    this.typeSelect = this.typeSelect.bind(this)
    this.typeTwoSelect = this.typeTwoSelect.bind(this)
    this.typethirdSelect = this.typethirdSelect.bind(this)
    this.valueChangeemail = this.valueChangeemail.bind(this)

    

    this.downajax = this.downajax.bind(this)
    this.state = {
      swiper: null,
      layerstate: false,
      attachmentcount: 0,
      prevstate: false,
      nextstate: false,

      atthid:'',

      addsrorestate: false,
      addsroretext: '',
      addsrore: 0,

      isoutuser: true,
      layerimglist: [],
      maskinfo: {
        title: '文档中心',
        text: '请选择下载内容',
      },
      maskfalg: false,


      //补全邮箱
      addemail:'',
      email:'',
      emaillayer:false,
      oldlist:[],
      oldtype:1,

      
      scorelsyerstate:false,
      scorelsyerlist:{
        score:"",
        content:"",
      },



    }
  }

  static defaultProps = {
    selectlist: [
      {
        id: 0,
        type: 'hotwatch',
        title: '最热查看',
        icon: require('../../static/images/playing_icon_07.png'),
        icon_act: require('../../static/images/playing_icon_07_act.png')
      },
      {
        id: 1,
        type: 'typefile',
        title: '资料分类',
        icon: require('../../static/images/playing_icon_08.png'),
        icon_act: require('../../static/images/playing_icon_08_act.png')
      }
    ]
  }
  changeLabel = (arr) => {
    // 筛选
    this.props.change_labellist(arr)
    this.props.history.push('./searchpage')
  }
  searchCollback = (text) => {
    // 搜索
    this.props.change_common_search(text)
    this.props.history.push('./searchpage')
  }
  // 切换导航
  changeSelect(id) {
    var keywords = '';
    if (id == 'hotwatch') {
      keywords = '最热查看';
    } else {
      keywords = '资料分类';
    }
    window.addreadlogs(keywords)
    this.props.change_active_two(id)
  }
  // 选中下载
  loveClick(index) {
    // let new_hordownlist = this.props.hordownlist;
    // new_hordownlist[index].active = !new_hordownlist[index].active;
    // this.props.change_file_hordownlist(new_hordownlist);
    // this.props.change_file_hotdownarr(new_hordownlist[index].active ? this.props.number + 1 : this.props.number - 1)
  }
  // 记录浏览次数

  swiperInit() {
    let that = this;
    that.setState({
      swiper: new Swiper('.swiper-container', {
        observer: true,//修改swiper自己或子元素时，自动初始化swiper 
        observeParents: false,//修改swiper的父元素时，自动初始化swiper 
        onSlideChangeEnd: function () {
          that.state.swiper.update();
        }
      })
    })
  }

  
  valueChangeemail(e){
    this.setState({
      addemail: e.target.value,
    })
  }
  
  closenophonebox = ()=>{
    this.setState({
      emaillayer:false
    })
  }
  subphone = ()=>{
    if(!this.props.emailexg.test(this.state.addemail)){
      return 
    }

    fetch('https://shl-info.cn/front/user/updateUserEmail?userid=' +localStorage.getItem('userid')+"&email="+this.state.addemail,{
      headers:{
        'token':localStorage.getItem('token')
      }
    })
      .then((data) => {
        return data.json();
      })
      .then((res) => {
        console.log(res)
        console.log(this.state.oldtype,this.state.oldlist)
        localStorage.setItem('email',this.state.addemail);
        if(this.state.oldtype==1){
          this.gotoDown(this.state.oldlist[0])
        }
        if(this.state.oldtype==2){
          this.godownload(this.state.oldlist[0],'',this.state.oldlist[1])
        }
        console.log(this.state.oldlist,this.state.oldtype)
        this.setState({
          emaillayer:false,
          email:this.state.addemail,
        })
      })
   
    
  }
  checkuseremail =(id,type,e)=>{
    if(!localStorage.getItem('email')){
      this.setState({
        oldtype:id,
        oldlist:[type,e],
        emaillayer:true,
      })

      
      return false;
    }else{
      return true
    }
    console.log(1111)
  }
  godownload = (id, item) => {
    
    window.addreadlogsfiles(item.name, id)
    let state = this.checkuseremail(2,id, item);
    console.log(state)
    if(!state){
      return
    }
    console.log(12312312)
    fetch(
      "https://shl-info.cn/front/frontc/addzhiqufilelogs?userid=" +
      this.props.userinfo.userid +
      "&filename=" +
      item.name+
      "&usertel=" +
      this.props.userinfo.info.phone+
      "&types=" +
      this.props.userinfo.info.type
      ,{
        headers:{
          'token':this.props.userinfo.token 
        }
      }
    )
    console.log(id, item)
    $.ajax({
      type: "GET",
      url:
        "https://shl-info.cn/front/frontc/sendfile?userid=" +
        this.props.userinfo.userid +
        "&ids=" +
        id,
      dataType: "json",
      headers:{'token':this.props.userinfo.token},
      cache: false,
      success: res => {
        if (res && res.status !== 2) {
          
          //添加新积分
         this.addscorenew(id);
          this.setState({
            maskinfo: {
              title: "文档中心",
              text: "已发送到您的邮箱，请查收!"
            },
            maskfalg: true
          });
        }
      }
    });
  }
  recoreseenum = (id, item) => {
    console.log(this)
    console.log(33333333333333)
    this.setState({
      atthid:id,
    })
    
   
    window.addreadlogsfiles(item.name ? item.name : item.title, id)
    console.log(this.props.userinfo)
  
    fetch(
      "https://shl-info.cn/front/frontc/addzhiqufilelogs?userid=" +
      this.props.userinfo.userid +
      "&filename=" +
      (item.name ? item.name : item.title)+
      "&usertel=" +
      this.props.userinfo.info.phone+
      "&types=" +
      this.props.userinfo.info.type,{
        headers:{
          'token':this.props.userinfo.token 
        }
      }
    )
    console.log(22223)

    let that = this
    var thisnum = item.attachmentcount;

    var thisname = item.attachmentname;
    var newa = [];
    if (thisnum > 0) {

      if(thisnum==1){
        let athat = this
        var type = 5;
        // $.ajax({
        //   type: "POST",
        //   url:
        //     "https://shl-info.cn/front/score/addlog?userid=" +
        //     localStorage.getItem('userid') +
        //     "&usertype=" +
        //     localStorage.getItem('usertype'),
        //   data: JSON.stringify({
        //     scoreid: type,
        //     userid: localStorage.getItem('userid'),
        //     usertype: localStorage.getItem('usertype'),
        //     attachid: id
        //   }),
        //   dataType: "json",
          
        //   headers:{'token':localStorage.getItem('token')},
        //   cache: false
        // })
        //   .catch(error => {
        //     console.log(error);
        //   })
        //   .then(res => {
        //     if (res.code == 200) {
        //       athat.setState({
        //         addsrorestate: true,
        //         addsroretext: '观看文档',
        //         addsrore: res.score,
        //       })
        //       setTimeout(() => {
        //         athat.setState({
        //           addsrorestate: false,
        //         })
        //       }, 2000)
        //     }

        //   });
      }
      for (var i = 0; i < thisnum; i++) {
        newa.push({
          img: 'https://shlinfo.oss-cn-shanghai.aliyuncs.com/static/upload/pptpic/' + thisname + '__' + (i + 1) + '.png?1',
        })
      }

      if (this.state.isoutuser) {
        newa.push({
          img: 'https://shlinfo.oss-cn-shanghai.aliyuncs.com/thank.png?1',
        })
        thisnum++;
      }

    }
    //
    // nextEl: '.swiper-button-next',
    // prevEl: '.swiper-button-prev',
    this.setState({
      layerimglist: newa,
      layerstate: true,
      prevstate: true,
      attachmentcount: thisnum,

    })

    this.state.swiper.update();

    console.log(newa)

    console.log(id)
    //注释掉点击预览添加事件
    // $.ajax({
    //   type: "GET",
    //   url:
    //     "https://shl-info.cn/front/frontc/addattachcount?userid=" +
    //     this.props.userinfo.userid +
    //     "&token=" +
    //     this.props.userinfo.token +
    //     "&attachid=" +
    //     id
    // });
  }
  downajax(id) {
    $.ajax({
      type: "GET",
      url:
        "https://shl-info.cn/front/frontc/sendfile?userid=" +
        this.props.userinfo.userid +
        "&ids=" +
        id,
      dataType: "json",
      headers:{'token':this.props.userinfo.token},
      cache: false,
      success: res => {
        if (res && res.status !== 2) {
          this.setState({
            maskinfo: {
              title: "文档中心",
              text: "已发送到您的邮箱，请查收!"
            },
            maskfalg: true
          });
        }
      }
    });
  }
  // 点击下载 
  hotlistDown() {
    if (this.props.number > 0) {
      if (this.loading) { return false; }
      this.loading = true;
      let new_hordownlist = this.props.hordownlist;
      let values = [];
      new_hordownlist.forEach((item) => {
        if (item.active) {
          values.push(item.id)
          item.downnum += 1;
          item.active = false;
        }
      })
      $.ajax({
        type: "GET",
        url:
          "https://shl-info.cn/front/frontc/sendfile?userid=" +
          this.props.userinfo.userid +
          "&ids=" +
          values.join(","),
        dataType: "json",
        headers:{'token':this.props.userinfo.token},
        cache: false,
        success: res => {
          if (res && res.status !== 2) {
            this.loading = false;
            this.setState({
              maskinfo: {
                title: "文档中心",
                text: "已发送到您的邮箱，请查收!"
              },
              maskfalg: true
            });
            this.props.change_file_hordownlist(new_hordownlist);
            this.props.change_file_hotdownarr(0);
          }
        }
      });
    } else {
      this.setState({
        maskinfo: {
          title: '文档中心',
          text: '请选择下载内容'
        },
        maskfalg: true
      })
    }
  }
  // 关闭弹层
  closeMask() {
    this.setState({
      maskfalg: false
    })
  }
  //资料查看二级下拉 
  typeSelect(index) {
    let new_typedownlist = this.props.typedownlist;
    new_typedownlist[index].active = !new_typedownlist[index].active;
    this.props.change_file_typedownlist(new_typedownlist);
  }
  typeTwoSelect(parent, index) {
    let new_typedownlist = this.props.typedownlist;
    new_typedownlist[parent].list[index].active = !new_typedownlist[parent].list[index].active;
    this.props.change_file_typedownlist(new_typedownlist);
  }

  typethirdSelect(parent, index, index1, e) {
    console.log(parent, index, index1, e)
    let new_typedownlist = this.props.typedownlist;
    new_typedownlist[parent].list[index].list[index1].active = !new_typedownlist[parent].list[index].list[index1].active;
    console.log(111)
    this.props.change_file_typedownlist(new_typedownlist)
  }
  componentDidMount() {

    let that = this
    let userid = localStorage.getItem('userid')
    let token = localStorage.getItem('token')
    let thisuserid = userid;
    if (userid && token) {
      // 验证是否过期
      $.ajax({
        type: "GET",
        url: "https://shl-info.cn/front/user/checkuser?userid=" +
          userid ,
        dataType: "json",
        headers:{'token':token},
        success: function (res) {
          console.log(res)
          if (res.status === 1) {
            localStorage.setItem('usertype', res.list.usertype)
            
            localStorage.setItem('iswaitype', res.list.type)
            if (res.list.id != '' && res.list.type === 0) {
              that.setState({
                isoutuser: false,
              })
            }
            //isoutuser
          }
        }
      });
    }



    this.loading = false;
    if (this.props.active_two !== 'hotwatch' && this.props.active_two !== 'typefile') {
      this.props.change_active_two('hotwatch')
    }
    this.props.change_file_data()
    //关闭不重复请求文档。
    // if (this.props.typedownlist.length < 1) {
    //   this.props.change_file_data()
    // }
    this.swiperInit();
  }
  componentWillUnmount() {
  }

  nextswi = () => {
    let thisswiindex = (this.state.swiper.activeIndex)
    console.log(this.state.swiper)
    console.log(thisswiindex)
    console.log(this.state.attachmentcount)
    console.log(this.state)
    if(thisswiindex+2==parseInt(this.state.attachmentcount/2)){
      console.log(1111)

      let athat = this
      var type = 5;
      // $.ajax({
      //   type: "POST",
      //   url:
      //     "https://shl-info.cn/front/score/addlog?userid=" +
      //     localStorage.getItem('userid') +
      //     "&usertype=" +
      //     localStorage.getItem('usertype'),
      //   data: JSON.stringify({
      //     scoreid: type,
      //     userid: localStorage.getItem('userid'),
      //     usertype: localStorage.getItem('usertype'),
      //     attachid: this.state.atthid
      //   }),
      //   dataType: "json",
        
      //   headers:{'token':localStorage.getItem('token')},
      //   cache: false
      // })
      //   .catch(error => {
      //     console.log(error);
      //   })
      //   .then(res => {
      //     if (res.code == 200) {
      //       athat.setState({
      //         addsrorestate: true,
      //         addsroretext: '观看文档',
      //         addsrore: res.score,
      //       })
      //       setTimeout(() => {
      //         athat.setState({
      //           addsrorestate: false,
      //         })
      //       }, 2000)
      //     }

      //   });
    }
    if (thisswiindex + 2 >= this.state.attachmentcount) {
      this.setState({
        nextstate: true,
      })
    }
    this.setState({
      prevstate: false,
    })
    this.state.swiper.slideNext();
  }
  prevswi = () => {
    let thisswiindex = (this.state.swiper.activeIndex)
    if (thisswiindex - 1 <= 0) {
      this.setState({
        prevstate: true,
      })
    }
    console.log(thisswiindex)
    this.setState({
      nextstate: false,
    })
    this.state.swiper.slidePrev();
  }

  
  addscorenew = (ids)=>{
    $.ajax({
      type: "POST",
      url:
        "https://shl-info.cn/front/Scoreshop/setScore?userid=" +
        localStorage.getItem('userid') +
        "&usertype=" +
        localStorage.getItem('usertype'),
      data: JSON.stringify({
        userid: localStorage.getItem('userid'),
        type: 4,
        courseid:"",
        attachid:ids,
        bbsid:'',
      }),
      dataType: "json",
      headers:{'token':localStorage.getItem('token')},
      cache: false
    })
      .catch(error => {
      })
      .then(res => {
        console.log(res)
        if(res.code==2000){
          this.setState({
            scorelsyerlist:res.data,
            scorelsyerstate:true,
          })
        }
        
        //console.log(res)
      });
  }
  closelayer = () => {
    let that = this
    this.setState({
      layerstate: false,
      nextstate: false,
      prevstate: true,
    })
    console.log(1111)
    this.state.swiper.slideTo(0, 100, false);
    // this.state.swiper.destroy();
  }

  
  closescorelayer = () =>{
    this.setState({
      scorelsyerstate:false
    })
  }

  render() {
    // 切换导航
    let selectlist = this.props.selectlist.map((item) => {
      return (
        <SelectBox
          key={item.id}
          _item={item}
          active={this.props.active_two}
          change={this.changeSelect}>

        </SelectBox>
      )
    })
    // 内容更换
    let container = null;
    if (this.props.active_two === 'hotwatch') {
      container = this.props.hordownlist.map((item, index) => {
        return (
          <FileHots
            key={item.id}
            _item={{ ...item, index: index }}
            loveClick={this.loveClick}
            recoreseenum={this.recoreseenum}
            godownload={this.godownload}>

          </FileHots>
        )
      })
    } else {
      container = this.props.typedownlist.map((item, index) => {
        return (
          <FileType
            key={item.id}
            _item={{ ...item, index: index }}
            typeSelect={this.typeSelect}
            typeTwoSelect={this.typeTwoSelect}
            typethirdSelect={this.typethirdSelect}

            downajax={this.downajax}
            recoreseenum={this.recoreseenum}
            godownload={this.godownload}>

          </FileType>
        )
      })
    }
    let maskbox = null;
    if (this.state.maskfalg) {
      maskbox = (
        <MaskBox
          info={this.state.maskinfo}
          closeMask={this.closeMask}></MaskBox>
      )
    }
    let pagenums = 0;
    if (this.state.swiper) {
      pagenums = this.state.swiper.activeIndex + 1;
    }
    let addsrorelayer = null;
    if (this.state.addsrorestate) {
      //addsrorelayer = <Scorelayer scorenum={this.state.addsrore} showtext={this.state.addsroretext}></Scorelayer>
    }


    let isnoemailtext = null
    if(this.state.emaillayer){
      isnoemailtext=<div className='noemailbox'>
        <div className='nophoneinputbox'>
          <div className='nophoneinputboxtitle'>请补全您的邮箱
            <span className="common-maskclose cursor" onClick={()=>this.closenophonebox()}></span>
          </div>
          <div className="nophoneinputboxpadding">
          <div class="login-form" >
            <span class="form-name">邮箱</span>
              <input name="tel" type="text" placeholder="请输入" value={this.state.addtel} onChange={this.valueChangeemail} />
            </div>
            <div className={this.props.emailexg.test(this.state.addemail)?"login-submit active":"login-submit"}  onClick={()=>this.subphone()}>确认</div>
            </div>
        </div>
      </div>
    }

    
    
    let scorelsyerstatehtml = null
    if(this.state.scorelsyerstate){
      scorelsyerstatehtml= <Scorelayer closescorelayer={this.closescorelayer} scorelsyerlist={this.state.scorelsyerlist}></Scorelayer> 
    }




    return (
      <div className='filepage'>

        
        {scorelsyerstatehtml}


        <Header active='3'></Header>
        {addsrorelayer}
        {isnoemailtext}
        {/* <Scorelayer scorenum={22} showtext={'textttt'}></Scorelayer> */}
        <div className='page-navselect'>
          <div className="wrap overflow">
            <ul className="navselect fl">
              {selectlist}
            </ul>
            <div className="select-contain fr overflow">
              <div>
                {container}
              </div>
              {
                // this.props.active_two === 'hotwatch' ? (
                //   <div className="hotdown-number fr">
                //     <div className="numbers-box fl">
                //       <span>{this.props.number}</span>
                //     </div>
                //     <div
                //      className="hotdown-btn cursor fr"
                //      onClick={this.hotlistDown}>下载</div>
                //   </div>
                // ) : null
              }
            </div>
          </div>
        </div>
        {maskbox}
        <div className={this.state.layerstate ? "imglayer active" : "imglayer active none"}>

          <div className="close_layer" onClick={(e) => this.closelayer()}></div>
          <div className="pagenums">{pagenums}/{this.state.layerimglist.length}</div>
          <div class="swiper-container imglist">
            <div class="swiper-wrapper imglist">
              {
                (this.state.layerimglist.length == 0)
                  ? null
                  : this.state.layerimglist.map((item1, index1) => {
                    return (
                      <div class="swiper-slide" key={index1}><img src={item1.img}></img></div>
                    )
                  })
              }
              {/* <div class="swiper-slide"><img src={require('../../static/images/poster_01.jpg')}></img></div>
              <div class="swiper-slide"><img src={require('../../static/images/poster_02.jpg')}></img></div> */}
            </div>
            <div class={this.state.prevstate ? "swiper-button-prev none" : "swiper-button-prev"} onClick={(e) => this.prevswi()}></div>
            <div class={this.state.nextstate ? "swiper-button-next none" : "swiper-button-next"} onClick={(e) => this.nextswi()}></div>


            <div className={this.state.isoutuser ? 'userlogos' : 'userlogos none'}>仅供西门子医疗内部员工培训使用</div>


            {/* <div className="tsnum">{this.state.active}/{this.state.layerimglist.length}</div> */}
            {/* <div class="swiper-pagination"></div> */}
          </div>
        </div>
        <Footer></Footer>
      </div>
    )
  }
}

export default HomeFile
