import React, { useState, useEffect } from 'react'


// 组件
import Header from '../../components/header'
import Footer from '../../components/footer'
import PersonImg from '../../components/personimg'
import MaskBox from '../../components/maskbox'
import axios from 'axios';
import PostItem from '../../components/postItem';
import classnames from 'classnames';

import scorm  from 'scorm-again'

import './index.css'
import $ from 'jquery';

console.log(scorm)

const scormDetail = (props) => {
  let userid = localStorage.getItem('userid') 

  return (
    <div className='scormDetail'>
      {/* <Header active='4'></Header> */}
      <iframe className="embed-responsive-item htmls"  src="http://dev-scorm.blue-dot.com.cn/index.html?url=/xsd_html/20IbBqN1GzaRQov1CrxYHnXWM9FXvUH7AExJsl10/SCO/stub.html" id="sscourseFrame" ></iframe>
       

      {/* <Footer></Footer> */}
    </div>
  )
};


export default scormDetail;
