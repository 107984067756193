import React, { useState, useEffect } from 'react'
import './index.css'
import CourseItem from '../CourseItem'
import groupDetail from '../..';

const IndexComponent = (props) => {


  let [packidname, setPackidname]= useState('')
  let [packidlist, setPackidlist]= useState([])
  let [packidliststate, setPackidliststate]= useState(false)
  let [showlayerstate, setshowlayerstate]= useState(false)

  let [datainfo, setDatainfo]= useState({

  })
  
  useEffect(() => {
    console.log(33333)
    fetch(
      "https://shl-info.cn/front/groupchat/getCourseListByPackId?userid=" +
      localStorage.getItem('userid') +
      "&packcode=" +
      props.packidinfo.packcode ,{
        headers:{
          'token':localStorage.getItem('token') 
        }
      }
    )
      .then(data => {
        return data.json();
      })
      .then(res => {
        console.log(res)
        if(res.code==2000){
          setPackidname(res.data.packname)
          if(res.data.courseinfo){
            setPackidlist(res.data.courseinfo)
          }
          if(res.data.datainfo){
            setDatainfo(res.data.datainfo)
            props.changepackinfo(res.data.datainfo,res.data.questions)
          }
        

          setPackidliststate(true)
        }
      });
  }, [props.packidinfo.packcode])
  let godetail = (id) =>{
    console.log(props.groupid)
      localStorage.setItem("jumpgroupcode",props.groupid)
     props.history.push('/coursedetails?type='+id)
  }
  let getcourselistbypackid = () =>{
    fetch(
      "https://shl-info.cn/front/groupchat/getCourseListByPackId?userid=" +
      localStorage.getItem('userid') +
      "&packcode=" +
      props.packidinfo.packcode ,{
        headers:{
          'token':localStorage.getItem('token') 
        }
      }
    )
      .then(data => {
        return data.json();
      })
      .then(res => {
        console.log(res)
        if(res.code==2000){
          setPackidname(res.data.packname)
          if(res.data.courseinfo){
            setPackidlist(res.data.courseinfo)
          }

          setPackidliststate(true)
        }
      });
  }
  let delcourser = (e,courseid) =>{
    console.log(e)
    console.log(props)
    fetch(
      "https://shl-info.cn/front/groupchat/delCourseForPack",{
        method: 'POST',
        body: JSON.stringify({
          "chatcode": props.groupid,
          "userid": localStorage.getItem('userid'),
          "courseid": courseid.id,
          "packid": props.packidinfo.packcode,
        }),
        headers:{
          'content-type': 'application/json',
          'token':localStorage.getItem('token')
        },
        header: {
          'content-type': 'application/json',
          'token':localStorage.getItem('token')
        },
      }
    )
      .then(data => {
        return data.json();
      })
      .then(res => {
        getcourselistbypackid();
      })
      e.stopPropagation();
    // //getcourselistbypackid();
    // e.stopPropagation();
  }
  let groupChatCourseItem = (item,index) => {
    console.log(item)
    let canstate = false;
    if(props.packidinfo.isdefault==0  && (props.usertypes==1 || props.usertypes==2 ||props.usertypes==4) ){
      canstate=true
    }
    return (
      <div className="groupChatCourseItem" key={index} onClick={()=>godetail(item.id)}>
        <CourseItem canstate={canstate}   item={item} delcourser={delcourser}>
          {/* <div className="groupChatCourseTime">
            <img src={require('../../../../static/images/icon3.png')} alt="" className="groupChatCourseTimeIcon" />
            <div className="groupChatCourseTimeText">已观看06:26</div>
          </div> */}
        </CourseItem>
      </div>
    )
  }
  let showlayer = ()=>{
    setshowlayerstate(true)
  }
  let closeMask = ()=>{
    setshowlayerstate(false)
  }
  console.log(props)

  return (
    <div className="groupChatCourse">
      {showlayerstate?<div className="common-mask userlayer">
            <div className="common-maskbox " >
                <h2 className="common-masktitle">
                西影力学院
                    <span
                        className="common-maskclose cursor"
                        onClick={() => closeMask()}></span>
                </h2>
                <div className="common-masktext-surbox">
                   <p className=' ' style={{textalign:"center"}}> 请先完成全部课程</p>
                    {/* <div className="masksurbox-btnlist">
                        <span className="changebtn" onClick={()=>closeMask()}>确认</span>
                    </div> */}
                   
                </div>
            </div>
        </div >:null}
      <div className="groupChatCourseHead">
        <div className="groupChatCourseTitle groupinfo">{packidname}</div>

        {datainfo.isexam==1?(datainfo.islookover && datainfo.condition==1) || datainfo.condition==0 ?<div className="groupChatCourseAdd groupChatCourseAddaddcourse" onClick={() => props.gopacksurvery(props.packidinfo.packcode)}>
        <div className="groupChatCourseAddText">课程包考试</div>
        </div>:<div className="groupChatCourseAdd groupChatCourseAddaddcourse canotsurvery" onClick={()=>showlayer()} >
        <div className="groupChatCourseAddText ">课程包考试</div>
        </div>:null}
        { (props.usertypes==1 || props.usertypes==2 ||props.usertypes==4) ?props.packidinfo?props.packidinfo.isdefault!=1 && props.packidinfo.packcode?  <div className="groupChatCourseAdd" onClick={() => props.addCourseFunc()}>
          <img src={require('../../../../static/images/icon11.png')} alt="" className="groupChatCourseAddIcon"  />
         <div className="groupChatCourseAddText">添加课程</div>
        </div>:null :null :null}





       
      </div>

      <div className="groupChatCourseList">
        {packidlist.map((item,index)=>{
          return groupChatCourseItem(item,index)
        })}
        {!packidliststate?<div className="addtextloading">加载中</div>:null}
        {packidlist.length<=0 && packidliststate?<div className="addtextloading">暂无内容</div>:null}
        {/* { groupChatCourseItem() }
        { groupChatCourseItem() }
        { groupChatCourseItem() } */}
      </div>
    </div>
  )
};

export default IndexComponent;
