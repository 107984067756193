import React from 'react'
import { Link } from 'react-router-dom'

import './index.css'

function ClassBox(props) {
    return (
        <div className="class-box transform_hover">
            <Link to={props._item.link}>
                <span className="class-banner">
                    <img className="tranform_img" src={props._item.src} alt=""/>
                </span>
                <div className="class-text">
                    <p>{props._item.title}</p>
                    <span className="class-href">
                        <img src={require('../../static/images/moreIcon.png')} alt=""/>
                    </span>
                </div>
            </Link>
        </div>
    )
}

export default ClassBox
