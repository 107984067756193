import React from 'react'

import './index.css'

function PersonTitle(props) {
    return (
        <div className="person-title">
            <div className="wrap">
                {props.title}
                {props.showscore?<div className="goscorelayer" onClick={props.goscorelayer}><span>积分商城</span></div>:null}
            </div>

        </div>
    )
}

export default PersonTitle
