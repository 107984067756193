import React, { useState, useEffect } from 'react'
import classnames from 'classnames';
import regionJson from '../../../../utils/regionJson';
import './index.css'
import MaskBox from '../../../../components/maskbox'

let selectNotIcon = require('../../../../static/images/icon12.png')
let selectIcon = require('../../../../static/images/classTruepng.png')

const IndexComponent = (props) => {
  
  let identitys = {
    teacher: {
      title: '应用老师'
    },
    user: {
      title: '受邀用户'
    }
  }
  let [identityState, setIdentityState] = useState('user')

  let [selectData, setSelectData] = useState({
    province: regionJson,
    city: [],
    level: [
      { value: '三级甲等', label: '三级甲等' },
      { value: '三级乙等', label: '三级乙等' },
      { value: '二级甲等', label: '二级甲等' },
      { value: '二级乙等', label: '二级乙等' },
      { value: '一级医院', label: '一级医院' },
      { value: '其他', label: '其他' },
    ],
    type: [
      { value: '公立', label: '公立' },
      { value: '民营', label: '民营' },
    ],
    classification: [
      // { value: '分类1', label: '分类1' },
      // { value: '分类2', label: '分类2' },
      // { value: '分类3', label: '分类3' },
      // { value: '分类4', label: '分类4' },
    ],
    course: [
      // { value: '课程1', label: '课程1' },
      // { value: '课程2', label: '课程2' },
      // { value: '课程3', label: '课程3' },
      // { value: '课程4', label: '课程4' },
    ]
  })

  
  useEffect(() => {
    fetch(
      "https://shl-info.cn/front/groupchat/getClassifyInfo?userid=" +
      localStorage.getItem('userid'),{
        headers:{
          'token':localStorage.getItem('token') 
        }
      }
    )
      .then(data => {
        return data.json();
      })
      .then(res => {
        console.log(res)
        if(res.code==2000){
          let newsarry=[]
            for(var i=0;i<res.data.length;i++){
              let list=  { value:res.data[i]['name'] , label: res.data[i]['name']};
              newsarry.push(list)
            }
          let statearry=selectData
          statearry.classification=newsarry
          console.log(statearry)
          setSelectData(statearry)
          // this.setState({
          //   selectData:statearry
          // })
        }

        fetch(
          "https://shl-info.cn/front/groupchat/getPackInfo?userid=" +
          localStorage.getItem('userid'),{
            headers:{
              'token':localStorage.getItem('token') 
            }
          }
        )
          .then(data => {
            return data.json();
          })
          .then(res => {
            console.log(res)
            if(res.code==2000){
              let newsarry=[]
              for(var i=0;i<res.data.length;i++){
                let list=  { value:res.data[i]['name'] , label: res.data[i]['id']};
                newsarry.push(list)
              }
              let statearrycourse=selectData;
              console.log(statearrycourse)
              statearrycourse.course=newsarry
              setSelectData(statearrycourse)
              console.log(statearrycourse)
              formDataRenew() // 更新
              // this.setState({
              //   selectData:statearry
              // })
            }
          });
      });

  }, [props.roletype])
  


  // 空值效验: 属性值不能为空
  let [emptyCheck, setEmptyCheck] = useState({
    groupName: { tip: '请输入群组名称' },
    hospitalName: { tip: '请输入医院名称' },
    province: { tip: '请选择省份' },
    city: { tip: '请选择城市' },
    type: { tip: '请选择医院类型' },
    level: { tip: '请选择医院级别' },
    classification: { tip: '请选择群组分类' },
   // course: { tip: '请选择初始课程包' },
  })
  // 专属效验
  let [exclusiveCheck, setExclusiveCheck] = useState({
    email: { tip: '邮箱格式不正确', func: (str) => { let re = /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/; return re.test(str) } },
  })
  // 选填效验
  let [selectCheck, setSelectCheck] = useState({
    phone: { tip: '' },
    course: { tip: '' },
  })

  // form表单
  let [form, setForm] = useState({
    groupName : {
      value: '',
      tip: '',
      encrypt: false,
    },
    hospitalName: { // 医院名称
      value: '',
      tip: '',
      encrypt: false,
    },
    // province: { // 
    //   value: '',
    //   tip: '',
    //   encrypt: false,
    //   linkage: 'city'
    // },
    // city: { // 
    //   value: '',
    //   tip: '',
    //   encrypt: false
    // },
    // type: { // 
    //   value: '',
    //   tip: '',
    //   encrypt: false
    // },
    // level: { // 
    //   value: '',
    //   tip: '',
    //   encrypt: false
    // },
    classification: { // 
      value: '',
      tip: '',
      encrypt: false
    },
    course: {
      value: [],
      tip: '',
      encrypt: false
    }
  })
  let { hospitalName, province, city, type, level, course, classification, groupName } = form

  
  
  // input 输入
  let changeInputItem = (key, e) => {
    form[key].value = e
    formDataRenew() // 更新
  }

  

  // 失去焦点效验
  let blurInputItem = (key) => {
    if (emptyCheck[key] && form[key].value.length === 0) {
      formDataTip(key, emptyCheck[key]) // 提示
      return false
    }

    // 选填效验
    if (selectCheck[key]) {
      // 不填不效验 填写就效验
      if (form[key].value.length === 0) {
        formDataTipClone(key)
        return true
      }
    }
    
    // 专属效验(正则)
    if (exclusiveCheck[key]) {
      let checkRes = exclusiveCheck[key].func(form[key].value)
      if (!checkRes) {
        formDataTip(key, exclusiveCheck[key]) // 提示
        return false
      }
    }
    formDataTipClone(key)
  }

  
  // select 选择
  let changeSelectItem = (key, e) => {
    form[key].value = e
    formDataTipClone(key)
    changeSelectLinkage(key, e) // 联动
  }

  // select 多选
  let changeManySelectItem = (key, e, id) => {
    let selectIndex= form[key].value.indexOf(id)
    if (selectIndex !== -1) {
      form[key].value.splice(selectIndex, 1)
    } else {
      form[key].value.push(id)
    }
    formDataTipClone(key)
    changeSelectLinkage(key, e) // 联动
  }

  
  // 表单联动
  let changeSelectLinkage = (key, select) => {
    let linkageKey = form[key].linkage
    if (!linkageKey) return;
    if (!select) {
      selectData[linkageKey] = []
      form[linkageKey].value = ''
      return
    }
    let selectIndex = selectIndexFunc(key, select)
    let selectItem = selectData[key][selectIndex]
    selectData[linkageKey] = selectItem.children
    form[linkageKey].value = ''
  }

  // 查询选择的Index
  let selectIndexFunc = (selectKey, select) => {
    let selectDataTemp = selectData[selectKey]
    for(let key in selectDataTemp) {
      if (selectDataTemp[key].value === select) {
        return Number(key)
      }
    }
  }


  // form 提示
  let formDataTip = (key, tip) => {
    form[key].tip = tip.tip
    formDataRenew()
  }

  // 清除form提示
  let formDataTipClone = (key) => {
    form[key].tip = ''
    formDataRenew() // 更新
  }

  // 更新
  let formDataRenew = () => {
    setForm({
      ...form
    })
  }

  let groupNameDom = () => {
    return (
      <div className="formItem">
        <div className="formItemTitle">群组名称*</div>
        <div className="formItemTips">{ groupName ? groupName.tip:'' }</div>
        <div className="formItemContent">
          <input type="text" className="formItemInput" placeholder="输入群组名称" maxLength="20" value={ groupName.value } onChange={(e) => changeInputItem('groupName', e.target.value)}  onBlur={() => blurInputItem('groupName')} />
        </div>
      </div>
    )
  }

  
  let formItemSelect = (key, placeholder) => {
    let list = selectData[key]
    let optionList = list.map((item, index) => {
      return (
        <option value={item.label} key={index}>{item.value}</option>
      )
    })
    return (
      <div className="formItemInput formItemSelect">
        <span className={classnames("formItemInputText", form[key].value.length || 'formItemInputTextPlaceholder')}>{ form[key].value || (placeholder || '请选择') }</span>
        <select className="formItemSelectContent" onChange={(e) => changeSelectItem(key, e.target.value)} >
          <option value={''} >{placeholder || '请选择'}</option>
          { optionList }
        </select>
      </div>
    )
  }

  
  let formItemRadios = (key) => {
    let list = selectData[key]
    let redioList = list.map((item, index) => {
      return (
        <div className="formItemRadio" key={index} onClick={() => changeManySelectItem(key, item.value,item.label)}>
          <img src={ form[key].value.indexOf(item.label) !== -1 ? selectIcon : selectNotIcon } alt="" className="formItemRadioIcon" />
          <div className="formItemRadioText">{ item.value }</div>
        </div>  
      )
    })
    
    return (
      <div className="formItemRadios">
        { redioList }
      </div>
    )
  }


  
  // 提交前效验
  let formDataCheck = () => {
    let res = {
      check: true,
      tips: null
    };
    let exclusiveCheckKeys = Object.keys(exclusiveCheck)
    for (let key in form) {
      if (emptyCheck[key] && form[key].value.length === 0) {
        formDataTip(key, emptyCheck[key]) // 提示
        res.check = false
        if (!res.tips) {
          res.tips = emptyCheck[key]
        }
        continue
      }
      
      // 选填效验 (不填不效验, 填写就效验)
      if (selectCheck[key]) {
        if (form[key].value.length === 0) {
          formDataTipClone(key)
          continue
        }
      }

      // 专属效验(正则)
      if (exclusiveCheckKeys.indexOf(key) !== -1) {
        let checkRes = exclusiveCheck[key].func(form[key].value)
        if (!checkRes) {
          formDataTip(key, exclusiveCheck[key]) // 提示
          res.check = false
          if(!res.tips) {
            res.tips = exclusiveCheck[key]
          }
          continue
        }
      }
      
      formDataTipClone(key)
    }
    return res
  }



  let formReqData = () => {
    let reqData = {}
    for (let key in form) {
      let value = form[key].value
      if (value instanceof Array) {
        if(value.length > 1){
          value = form[key].value.join();
        }else{
          value = form[key].value[0];
        }
      }
      if(value){
        if (value.length === 0) continue; // 空值不传
      }else{
        continue; 
      }
      //if (value.length === 0) continue; // 空值不传
      // reqData[key] = form[key].encrypt ? encrypt(value) : value // 加密
      reqData[key] = value
    }
    return reqData
  }

  let subFormFunc = () => {
    let checkRes = formDataCheck()
    if (!checkRes.check) {
      return
    }


    // 效验
    let formData = formReqData()

    let courseinf=''
    if(formData.course){
      courseinf=','+formData.course+','
    }
    fetch(
      "https://shl-info.cn/front/groupchat/setCreateGroup",{
        method: 'POST',
        body: JSON.stringify({
          name:formData.groupName,
          // province:formData.province,
          // city:formData.city,
          hospital:formData.hospitalName,
          // hospitallevel:formData.level,
          // hospitaltype:formData.type,
          userid:localStorage.getItem('userid'),
          classify:formData.classification,
          packid:courseinf,
        }),
        headers:{
          'content-type': 'application/json',
          'token':localStorage.getItem('token')
        },
        header: {
          'content-type': 'application/json',
          'token':localStorage.getItem('token')
        },
      }
    )
      .then(data => {
        return data.json();
      })
      .then(res => {
        console.log(res)
        if(res.code==2000){
          setThisuserstateadd(true)
          setThisuserstateaddtitle('创建成功')
          setThisuserstateaddtext('群组已经成功提交审核')

          // this.setState({
          //   ismasknew:true,
          //   layertitle:'创建成功',
          //   layertext:'群组已经创建成功',
          // });
        }else{
          setThisuserstateadd(true)
          setThisuserstateaddtitle('创建失败')
          setThisuserstateaddtext(res.msg)
          // this.setState({
          //   ismasknew:true,
          //   layertitle:'创建失败',
          //   layertext:res.msg,
          // });
        }
      })

    
    console.log(formData)
  }



  let closeMaskuserstate = () => {
    
    setThisuserstateadd(false)
    setThisuserstateaddtitle('')
    setThisuserstateaddtext('')
    if(thisuserstateaddtitle=='创建成功'){
      props.setGroupNav('list')
    }
  }
  let [thisuserstateadd, setThisuserstateadd] = useState(false)
  let [thisuserstateaddtitle, setThisuserstateaddtitle] = useState('')
  let [thisuserstateaddtext, setThisuserstateaddtext] = useState('')
  let maskbox = null;
  if (thisuserstateadd) {
    maskbox = (
      <MaskBox
        info={{
          title:thisuserstateaddtitle,
          text:thisuserstateaddtext,
        }}
        closeMask={closeMaskuserstate}>
      </MaskBox>
    )
  }

  
  return (
    <div className="groupAdd">
      {maskbox}
      <div className="groupAddView">
        <div className="form">
          <div className="formTitle">您的身份是{ props.roletype == 1? "应用老师" : "受邀用户"}</div>
          
          { identityState === 'teacher' || identityState === 'user' ? groupNameDom() :'' }

          
          
          <div className="formItem">
            <div className="formItemTitle">医院名称*</div>
            <div className="formItemTips">{ hospitalName.tip }</div>
            <div className="formItemContent">
              <input type="text" className="formItemInput" placeholder="输入医院名称" value={ hospitalName.value } onChange={(e) => changeInputItem('hospitalName', e.target.value)}  onBlur={() => blurInputItem('hospitalName')} />
            </div>
          </div>
          
          {/* <div className="formItem">
            <div className="formItemTitle">医院地址*</div>
            <div className="formItemTips">{ province.tip || city.tip }</div>
            <div className="formItemContent formItemContentFlex">
              <div className="formItemModule">
                { formItemSelect('province', '省') }
              </div>
              <div className="formItemModule">
                { formItemSelect('city', '市') }
              </div>
            </div>
          </div>

          <div className="formItem">
            <div className="formItemTitle">医院级别*</div>
            <div className="formItemTips">{ level.tip }</div>
            <div className="formItemContent">
              { formItemSelect('level') }
            </div>
          </div>

          <div className="formItem">
            <div className="formItemTitle">医院类型*</div>
            <div className="formItemTips">{ type.tip }</div>
            <div className="formItemContent">
              { formItemSelect('type') }
            </div>
          </div> */}

          <div className="formItem">
            <div className="formItemTitle">群组分类*</div>
            <div className="formItemTips">{ classification.tip }</div>
            <div className="formItemContent">
              { formItemSelect('classification') }
            </div>
          </div>

          <div className="formItem">
            <div className="formItemTitle">初始课程包</div>
            <div className="formItemTips">{ course.tip }</div>
            <div className="formItemContent">
              { formItemRadios('course') }
            </div>
          </div>

          <div className="formSub"  onClick={() => subFormFunc()}>提交</div>
        </div>
      </div>
    </div>
  )
};

export default IndexComponent;
