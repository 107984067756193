import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { change_active_two, change_certificate_list } from '../../store/action'
import CertifiCate from './index.bundle'

const mapStateToProps = (state) => {
  return {
    certificate: state.person_certificate
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    change_active_two(data) {
      dispatch(change_active_two(data))
    },
    change_certificate_list(data) {
      dispatch(change_certificate_list(data))
    }
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CertifiCate)
);
