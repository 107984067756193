import React, { Component } from 'react';
import $ from 'jquery'
import './index.css';
import Maskbox from '../../components/maskbox';
import Maskboxgroup from '../../components/maskboxcoursegroup';


class Onlinediexam extends Component {
  constructor(props) {
    super(props);
    this.closeMask = this.closeMask.bind(this);
    this.state = {
      masktext: "提交成功",
      submitmask: false,
      submitmaskgroup:false,
      masktextgroup:"",
    };
  }
  changeanswer(type, parent, index, e) {
    if (!this.props.lookstate) {
      this.setState({
        submitmaskgroup: true,
        masktextgroup: "请先观看视频后再答题",
        masktext:'请先观看视频后再答题'
      })
      return;
    }


    let new_arr = this.props.examlist;
    if (type === "1") {
      new_arr[parent].answer.forEach(item => {
        if (item.id === index) {
          item.active = true;
        } else {
          item.active = false;
        }
      });
    } else {
      new_arr[parent].answer[index].active = !new_arr[parent].answer[index]
        .active;
    }
    if (!new_arr[parent].active) {
      new_arr[parent].active = true;
    }
    this.props.change(new_arr);
  }
  submitexam() {
    // 检查是否答完所有题目
    let examComplete = [];
    let start = 0;
    let answlist = [];
    this.props.examlist.forEach(item => {
      for (let i = 0; i < item.answer.length; i++) {
        if (item.answer[i].active && !examComplete[start]) {
          examComplete[start] = { id: item.index, answer: [] };
          examComplete[start].answer.push(item.answer[i].title);
          if(!answlist.includes(item.index)){
            answlist.push(item.index)
          }
        } else if (item.answer[i].active && examComplete[start]) {
          examComplete[start].answer.push(item.answer[i].title);
          if(!answlist.includes(item.index)){
            answlist.push(item.index)
          }
        }
      }
      start += 1;
    });
    console.log(answlist)
    // return
    if (answlist.length === this.props.examlist.length) {
      // if (examComplete.length === this.props.examlist.length) {


      fetch(
        "https://shl-info.cn/front/frontc/addzhiqusurverlogs?userid=" +
        this.props.userinfo.userid +
        "&coursetitle=" +
        this.props.coursetitle +
        "&usertel=" +
        this.props.userinfo.info.phone +
        "&types=" +
        this.props.userinfo.info.type, {
        headers: {
          'token': this.props.userinfo.token
        }
      }
      )
      console.log(this.props)
      console.log(3434343)


      $.ajax({
        type: "POST",
        url:
          "https://shl-info.cn/front/frontc/savesurver?userid=" +
          this.props.userinfo.userid +
          "&courseid=" +
          this.props.classid + "&iscs=" + this.props.iscs,
        data: JSON.stringify({
          data: examComplete
        }),
        dataType: "json",
        headers: { 'token': this.props.userinfo.token },
        cache: false
      }).then(res => {
        if (res && res.status !== 2) {
          this.props.gotoresult();
        }
      });
    } else {
      if (!this.props.lookstate) {
        this.setState({
          submitmask: true,
          masktext: "请先观看视频后再答题",
        })
        return;
      } else {
        this.setState({
          masktext: "请回答所有问题",
          submitmask: true
        });
      }

    }
  }

  again() {

    // let courseidlist = ["152", "154", "155", "156", "157", "158", "417", "648", "737", "862", "1375", "1761", "1965", "428", "1444", "2432"];
    let courseidlist = ["152", "1375", "2432", "3058"];

    console.log(courseidlist.includes(this.props.classid.toString()))
    if (this.state.usercount >= 80 && !courseidlist.includes(this.props.classid.toString())) {
      this.props.gotocertificate();
      //this.props.history.push("/Examresults" );
    } else if (this.state.usercount >= 100 && courseidlist.includes(this.props.classid.toString())) {
      this.props.gotocertificate();
      //this.props.history.push("/Examresults" );
    } else {
      this.setState({
        islayer: true,
      })
    }
    // if (this.state.usercount >= 80 && this.props.classid != 152) {
    //   this.props.gotocertificate();
    //   //this.props.history.push("/Examresults" );
    // } else if (this.state.usercount >= 100 && this.props.classid == 152) {
    //   this.props.gotocertificate();
    //   //this.props.history.push("/Examresults" );
    // } else {
    //   this.setState({
    //     islayer: true,
    //   })
    // }
  }
  closeMask() {
    this.setState({
      submitmask: false,
      submitmaskgroup:false,
    });
  }
  goshowdetail = () =>{
    this.props.goshowdetail();
  }
  componentDidMount() {

    console.log(this.props.classid)
    this.setState({
      usercount: this.props.usercount,
    })
    if (this.props.userqastate == 1) {
      this.setState({
        islayer: false,
      })
    } else {

      this.setState({
        islayer: true,
      })
    }

  }
  componentWillUnmount() { }
  render() {
    let examlist = this.props.examlist.map((item, index) => {
      let answer = item.answer.map((list, listindex) => (
        <div
          className="exam-li"
          key={listindex}
          onClick={e => this.changeanswer(item.type, index, listindex, e)}
        >
          {list.title}
          <span
            className={
              item.type === "1"
                ? list.active
                  ? "radio active"
                  : "radio"
                : list.active
                  ? "checkout active"
                  : "checkout"
            }
          >
            <span></span>
          </span>
        </div>
      ));
      return (
        <div key={index} className="exam-wrap">
          <h2 className="exam-title">
            {index + 1}. {item.title}({item.type === "1" ? "单选" : "多选"})
          </h2>
          <div className="exam-list">{answer}</div>
        </div>
      );
    });
    let exammask = null;
    if (this.state.submitmaskgroup) {
      if(this.props.issale==1){
        exammask = (
          <Maskboxgroup
            info={{
              title: "在线考试",
              text: this.state.masktextgroup,
              chatime:this.props.chatime,
              maxlooktime:this.props.maxlooktime,
              allvideotime:this.props.allvideotime
            }}
            closeMask={this.closeMask}
          ></Maskboxgroup>
        );
      }else{
        exammask = (
          <Maskbox
            info={{
              title: "在线考试",
              text: this.state.masktext,
            }}
            closeMask={this.closeMask}
          ></Maskbox>
        );
      }
      
    }
    if (this.state.submitmask) {
        exammask = (
          <Maskbox
            info={{
              title: "在线考试",
              text: this.state.masktext,
            }}
            closeMask={this.closeMask}
          ></Maskbox>
        );
      
    }
    var examstate = 1;
    if ((this.props.classid == 919 || this.props.classid == 918 || this.props.classid == 917 || this.props.classid == 841) && this.props.userqastate == 1) {
      examstate = 0;
    }
    console.log(this.props)


    // let courseidlist = ["152", "154", "155", "156", "157", "158", "417", "648", "737", "862", "1375", "1761", "1965", "428", "1444", "2432"];
    let courseidlist = ["152", "1375", "2432", "3058"];

    return (
      <div className={this.props.iscourse ? "exam-scroll iscourse" : "exam-scroll"}>
        <div className="wrap">
          <div className="iscroll-padding">{examlist}</div>

          {
            this.props.examlist.length > 0 && examstate == 1 ? (
              <div
                className="exam-submit touchCss"
                onClick={() => this.submitexam()}
              >
                提交
              </div>
            ) : null
          }
          {exammask}
        </div>
        <div className={this.state.islayer ? "exam_layer course none" : this.props.changeexamstate ? "exam_layer course none" : "exam_layer course "}>
          <div class="result-number "><h5><span>{this.state.usercount}</span>分</h5>
            {courseidlist.includes(this.props.classid.toString()) ? (
              <p>{this.state.usercount >= 100 ? "恭喜您通过了考试" : "本次考试100分通过，继续努力！"}</p>
            ) : (
                <p>{this.state.usercount >= 80 ? "恭喜您通过了考试" : "本次考试80分通过，继续努力！"}</p>
              )}

            <div className="down-footer">
              {examstate == 1 ? (
                <><div
                  className="exam-submit touchCss"
                  onClick={() => this.again()}
                >
                  {courseidlist.includes(this.props.classid.toString()) ? (
                    <span>{this.state.usercount >= 100 ? "查看证书" : "重新考试"}</span>
                  ) : (
                      <span>{this.state.usercount >= 80 ? "查看证书" : "重新考试"}</span>
                    )}
                  
                

                </div>

                {this.props.issale==1 && this.state.usercount >= 80 ?(
                     <div
                     className="exam-submit touchCss margintiop"
                     onClick={() => this.goshowdetail()}
                   >
                     <span>查看详情</span>
                   
   
                   </div> 
                   ):null}
                  
                
                </>
              ) : null}

            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Onlinediexam;
