const fetchStream = (url: string, options?: any, onMessage?: (response: any) => void) => {
  return new Promise<void>(async (resolve, reject) => {
    //console.log(options)
    //console.log(3333)
    const { data, body, ...currentOptions} = options;
    const response: any = await fetch(url,
      {
        method: 'POST',
        body: JSON.stringify(data) || JSON.stringify(body),
        // headers: {
        //   'Content-Type': 'application/json',
        //   'X-Tenant': "8945f594-8402-4039-879b-523c85bf3ca1",
        // },
          headers:{
            'content-type': 'application/json',
            'token':localStorage.getItem('token'),
            // 'Content-Type': 'application/json',
            // 'X-Tenant': "8945f594-8402-4039-879b-523c85bf3ca1"
          },
        ...currentOptions,
      });
      //console.log(123123123123)
      if (response.status < 200 || response.status >= 300) {
      //  if (response.status < 200 || response.status >= 300) {
      // onError?.();
      //console.log(123123123123)
      reject();
      return;
    };

    const reader = response.body.getReader();
    while (true) {
      const { value, done } = await reader.read();
      // //console.log(value)
      // //console.log(done)
      //   //console.log(55555)
      //   //console.log(44444)
      if (done) {
        // onDone?.();
        resolve();
        break;
      };
      onMessage?.({
        result: value,
      });
    };
  });
};

export default fetchStream;
