import React, { Component } from 'react'
// 组件
import Header from '../../components/header'
import Footer from '../../components/footer'
import PersonImg from '../../components/personimg'
import PersonTitle from '../../components/persontitle'
import HistoryList from '../../components/historylist'
import './index.css'

class WatchHistory extends Component {
  
  componentDidMount() {
    this.props.change_active_two('watchhistory')
    this.props.change_person_historydata()
  }
  componentWillUnmount() {
    
  }
  
  
  render() {
    let list = null;
    if (this.props.history){
      list = this.props.history.map((item) => {
        return (
          <HistoryList key={item.id} _item={item}></HistoryList>
        )
      })
    }
    return (
      <div className='personpage'>
        <Header active='4'></Header>
        <PersonImg type="no"></PersonImg>
        <PersonTitle title="观看历史"></PersonTitle>
        <div className="wrap personal-margin">
          {list}
        </div>
        <Footer></Footer>
      </div>
    )
  }
}

export default WatchHistory
