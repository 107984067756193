import React, { useEffect, useState } from 'react'
import $ from 'jquery'
// 组件
import Header from '../../components/header'
import Footer from '../../components/footer'
import PostItem from '../../components/postItem'
import classnames from 'classnames';
import axios from 'axios';
import moment from 'moment';
import utils from '../../utils/index'

import './index.css'

const TalkDetail = (props) =>{
  let userid = localStorage.getItem('userid'), token = localStorage.getItem('token');
  let query = utils.getUrlParams()

  let [ patternSelect, setPatternSelect ] = useState(0)
  let [ patternSelectPanel, setPatternSelectPanel ] = useState(0)
  let postsPattern = [ { name: '最新帖子', id: '0' }, { name: '最热帖子', id: '1' } ]
  let [ talkDetail, setTalkDetail ] = useState({})
  let [ userPostlist, setUserPostlist ] = useState([])

  let [ usertypes, setUsertypes ] = useState('id')

  let [ setstate, setSetstate ] = useState(false)

  let [ userpage, setPages ] = useState(1)

  let [ allnums, setAllnums ] = useState(0)


  let [ morestate, setMorestate ] = useState(true)



  useEffect(() => {
    init()
  }, [])

  let init = () => {
    getTalkDetail()
  }

  // 获取话题详情
  let getTalkDetail = async () => {
    let id = query.id
    if (!id) { window.history.go(-1); return }


    //添加帖子浏览记录
    fetch(
      "https://shl-info.cn/front/Bbsdata/addTopicLog?topicid="+id+"&userid="+localStorage.getItem('userid')+"&type=0"
    )
    .then(data => {
      return data.json();
    })
    .then(res => {
    
    })


    let res = await axios({
      method: 'get',
      headers:{
          "token":token
      },
      url: `https://shl-info.cn/front/bbs/topicDetail/id/${id}`,
      params: {
        user_id: userid,
      }
    })
    let data = res.data
    if (!data) { window.history.go(-1); return }
    setTalkDetail(data)
    getpostlist(id,"id",1);
  }
  var getpostlist = async (id,types,pagenums) =>{
    console.log()
    if(pagenums==1){
      setMorestate(true)
    }

    let res = await axios({
        method: 'get',
        headers:{
            "token":token
        },
        url: 'https://shl-info.cn/front/bbs/articleList',
        params: {
            user_id: localStorage.getItem('userid'),
            topic_id: id,
            title: '',
            page:pagenums,
            order: types
        }
    })
    let data = res.data
    console.log(res)
    console.log("resdatalist")
    setPages(data.current_page)
    
    if(pagenums>1){
        let olddata=userPostlist;
        olddata.push.apply(olddata, data.data);
        console.log(olddata)
        setUserPostlist(olddata)
        setSetstate(!setstate)
    }else{
        setUserPostlist(data.data)
        setSetstate(!setstate)
        setAllnums(data.total)
    }
    if(data.data.length<10){
        setMorestate(false)
    }


    // fetch(
    //   "https://shl-info.cn/front/bbs/articleList?user_id="+localStorage.getItem('userid')+"&topic_id="+ id+"&order="+types+"&"
    // )
    // .then(data => {
    //   return data.json();
    // })
    // .then(res => {
    //   console.log(res.data)
    //   setUserPostlist(res.data)

      
    //   // this.setState({
    //   //   postlist:res.data
    //   // })
      
    // })
  }


  const changepattern = (index)=>{
    console.log(index) 
    let id = query.id
    console.log(id) 
    if(index==patternSelect){
      setTimeout(() => setPatternSelectPanel(0), 100)
      return false;
    }
    if(index==0){
      getpostlist(id,"id",1);
      setUsertypes("id")
    }else{
      getpostlist(id,"hits",1);
      setUsertypes("hits")
    }
    setPatternSelect(index); 
    setTimeout(() => setPatternSelectPanel(0), 100)
  }

  // 类型选择
  let postPatternSelectDom = () => {
    let postsPatternDom = postsPattern.map((item, index) => {
      return (
        <div className={ classnames("patternSelectItem", patternSelect === index ? 'active' : '') } key={index} onClick={ () => {  changepattern(index) } } >
          {item.name}
        </div>
      )
    })
    return (
      <div className="patternSelect">
        { postsPatternDom }
      </div>
    )
  }

  let PostItemDom = () => {
    let Postlist = userPostlist.map((item,index) =>{
      console.log(item,index)
      // <PostItem data={item} key={ index } hide={ ['userLabel'] }>
         
      // </PostItem>
      return (
        <>
        <PostItem data={item} key={ index }   isgodetail={true}  history={props.history}  hide={ ['label', 'userLabel'] }></PostItem>
        
        </>
      )
    })
    return (
      <>
        {Postlist}
      </>
    )
    // <PostItem hide={ ['label', 'userLabel'] }></PostItem>
    //     <PostItem hide={ ['label', 'userLabel'] }></PostItem>
   
  }
  const goback = ()=>{
    console.log(props.history)
    //props.history.goBack()

    props.history.push('/homedoctorinteraction?topic=1')


  }
  const gocreact = ()=>{
    props.history.push('/homeDoctorReleasePost')
  }


  const addmore = ()=>{
    console.log(userpage)

    let pagesnum=userpage
    pagesnum++;
    console.log(pagesnum)
    getpostlist(query.id,usertypes,pagesnum)
}

  return (
    <div className='talkDetail'>
      <Header active='6' isbbs={true}  topicid={query.id} ></Header>
      <div className='gocreatpost' onClick={()=>gocreact()}>
        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAANCAYAAABy6+R8AAAAXklEQVQokWP4//8/AxqW+48AuljkGZgYyAD008TCwMAgiCbGj8TmwyL/gfH/////SbRIgGznmaCJSTAwMGyBsiMYGBjuoMl/wYiDYRBPjFhCnIkBEVefGBgY/qIrAAD8G1+93fuX6QAAAABJRU5ErkJggg==" alt="" class="postsHeadButtonIcon" />
        
        发帖
      </div>
      <div className="detailContent">
        <div className="detailHead">
          
          <div className="detailHeadItem">
            <span className="detailHeadItemModule">互动专区</span>
            <img className="detailHeadItemIcon" src={require('../../static/images/select_arrow.png')}/>
            <span className="detailHeadItemText">话题</span>
          </div>
          <img className="detailHeadReturn" onClick={()=>goback()} src={require('../../static/images/icon-11.png')}/>
        </div>
        <div className="talkDetailBody">
          <div className="talkDetailTitle">{talkDetail.title}</div>
          <div className="talkDetailDate">{ moment(talkDetail.created).format('YYYY-MM-DD') }</div>
          <div className="talkDetailContent" dangerouslySetInnerHTML={{ __html: talkDetail.data }}></div>
        </div>
        <div className="detailSubord">
          <div className="detailSubordHead">
          {/* { talkDetail.article_count } */}
            <div className="detailSubordHeadTitle">{allnums} 条帖子</div>
            <div className="detailSubordHeadSelect" onClick={ () => setPatternSelectPanel(1) }>{ postsPattern[patternSelect].name }</div>
            { patternSelectPanel ? postPatternSelectDom() : '' }
          </div>
          <div className="detailSubordBody">
            { PostItemDom() }

            {morestate && userPostlist.length >= 10?<div className="detailLoad" onClick={()=>addmore()}>
                        加载更多
                        <img src={require('../../static/images/icon-9.png')} alt="" className="detailLoadIcon"/>
                    </div>:null}
            {/* <div className="detailLoad">
              加载更多
              <img src={require('../../static/images/icon-9.png')} alt="" className="detailLoadIcon"/>
            </div> */}
          </div>
        </div>

      </div>

      <Footer></Footer>
    </div>
  )
}

export default TalkDetail
