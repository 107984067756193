import React from 'react'

import './index.css'
import { Link } from 'react-router-dom'

function MaskBoxplay(props) {
    console.log(props.info.videolooktime)
   
    let result = parseInt(props.info.videolooktime)
    let h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600);
    let m = Math.floor((result / 60 % 60)) < 10 ? '0' + Math.floor((result / 60 % 60)) : Math.floor((result / 60 % 60));
    let s = Math.floor((result % 60)) < 10 ? '0' + Math.floor((result % 60)) : Math.floor((result % 60));
    
    let res = '';
    res += `${h}:`;
    res += `${m}:`;
    res += `${s}`;
    console.log(h,m,s)
    console.log(res)
    return (
        <div className="common-mask">
            <div className="common-maskbox common-maskboxplay">
                <h2 className="common-masktitle">
                    {props.info.title}
                    <span
                        className="common-maskclose cursor"
                        onClick={() => props.closeMask()}></span>
                </h2>

                <div className="common-masktextplay">
                    <p className='masktextplaytitle'>上次播放至{res}<br></br>是否继续播放</p>
                    <div className="goplay" >
                        <span className="goreg" onClick={()=>props.goingplay()}>继续播放</span>
                        <span   className="gologin" onClick={()=>props.restartplay()}>重新开始</span>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default MaskBoxplay
