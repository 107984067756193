import React, { Component } from 'react'

import $ from 'jquery'
// 组件
import Header from '../../components/header'
import Footer from '../../components/footer'
import PersonImg from '../../components/personimg'
import PersonTitle from '../../components/persontitle'
import RecordList from '../../components/recordlist'
import Maskbox from "../../components/maskbox";

import './index.css'

class Exchangedata extends Component {
  constructor(props) {
    
    // this.valueChange = this.valueChange.bind(this);
    super(props);
    this.state = {
      askactive: false,
      codelist:[],
    }
  }
  
  componentDidMount() {
    let that=this
    $.ajax({
      type: "GET",
      url:
        "https://shl-info.cn/front/Cscardcode/getExchangeData?userid=" +
        localStorage.getItem('userid'),
      dataType: "json",
      headers:{'token':localStorage.getItem('token')},
      cache: false,
      success: function (res) {
        console.log(res)
        that.setState({
          codelist:res.data
        })
      } 
    });
  }
  componentWillUnmount() {

  }
  
  render() {
    let hreflist = this.state.codelist.map((item,index) => {
      return (
        <div className='exchangedatalist_small' key={index}>
          <div className="small">{index+1}</div>
          <div className="">{item.cardcode}</div>
          <div className="">{item.seriesname}</div>
          <div className="">{item.exchangetime}</div>
        </div>
      )
    })


    return (
      <div className='personpage'>
        <Header active='4'></Header>
        <PersonImg type='no'></PersonImg>
        <PersonTitle title='兑换课程列表'></PersonTitle>
        <div className='wrap exchangedatalist'>
          <div className="exchangedatalist_title">
            <div className="small">序号</div>
            <div className="">礼券码</div>
            <div className="">兑换课程</div>
            <div className="">兑换时间</div>
          </div>
         
      {hreflist}
          
          {/* <div className='exchangedatalist_small'>
            <div className="small">序号</div>
            <div className="">礼券码</div>
            <div className="">兑换课程兑换课程兑换课程兑换课程兑换课程兑换课程兑换课程兑换课程兑换课程兑换课程兑换课程兑换课程兑换课程</div>
            <div className="">兑换时间</div>
          </div> */}


        </div>
        <div className="wrap exchangedatalist-contact-us">
          <div className="exchangedatalist-contact-us-title">我们的客户服务中心热线提供7天24小时全天侯服务<br></br>请拔打热线：<a href="tel:4008105888">400-810-5888</a></div>
        </div>
        <Footer></Footer>
      </div>
    )
  }
}

export default Exchangedata
