import React from 'react'
import { connect } from 'react-redux';
import $ from 'jquery'
import { change_userinfo } from "../store/action";
import { Route, Redirect } from 'react-router-dom'
import { encrypt, decrypt } from '../utils/crypto.ts'
// 路由守卫


function getUrlkey(url) {
  var params = {};
  var urls = url.split("?");                  //console.log('1_分割url:', urls)
  var arr = urls[1].split("&");               //console.log('2_分割urls[1]:', arr)
  for (var i = 0, l = arr.length; i < l; i++) {
    var a = arr[i].split("=");                //console.log('3_遍历 arr 并分割后赋值给a:', a[0], a[1])
    params[a[0]] = a[1];                      //console.log('4_a给params对象赋值:', params)
  }                                           //console.log('5_结果:', params)
  return params;
}
if (window.location.href.indexOf('sourcechannel=') >= 0) {
  let urls = getUrlkey(window.location.href);
  let sourcechannel = urls['sourcechannel'];
  //console.log(2222,"路由进口")
  localStorage.setItem('sourcechannel', sourcechannel)
}



const PrivateRoute = ({ component: Component, ...props }) => {
  let stateProps = props;
  return <Route {...props} render={(props) => {
    let userid = localStorage.getItem('userid')
    let token = localStorage.getItem('token')
    if (userid && token && !stateProps.userinfo.userid) {
      stateProps.change_userinfo({
        token: token,
        userid: userid,
        pic: stateProps.userinfo.pic,
        info: stateProps.userinfo.info
      })
      // 验证是否过期
      $.ajax({
        type: "GET",
        url: "https://shl-info.cn/front/user/checkuser?userid=" +
          userid,
        dataType: "json",
        headers: { 'token': token },
        success: function (res) {
          console.log(res)
          console.log(22222222222)
          if (res.status === 1) {
            localStorage.setItem("userid", userid);
            localStorage.setItem("token", res.token);
            localStorage.setItem("local", res.list.local);
            localStorage.setItem('usertype', res.list.usertype)
            localStorage.setItem('iswaitype', res.list.type)

            localStorage.setItem('isdi', res.list.isdi)
            var userlist = res.list;
            if (userlist.name) {
              userlist.name = decrypt(userlist.name);
            }
            if (userlist.email) {
              userlist.email = decrypt(userlist.email);
            }
            if (userlist.phone) {
              userlist.phone = decrypt(userlist.phone);
            }
            res.list = userlist
            stateProps.change_userinfo({
              token: res.token,
              pic: res.pic,
              userid: userid,
              info: res.list ? res.list : stateProps.userinfo.info
            });
          } else {
            props.history.push("/");
          }
        }
      });
    }
    if (userid && token) {
      return <Component />
    } else {
      // 重定向
      return <Redirect to={{
        pathname: '/',
        state: {
          // 来源
          from: props.location.pathname
        }
      }} />
    }
  }} />
}

// mapStateToProps：将state映射到组件的props中
const mapStateToProps = (state) => {
  return {
    userinfo: state.userinfo
  }
}

// mapDispatchToProps：将dispatch映射到组件的props中
const mapDispatchToProps = (dispatch) => {
  return {
    change_userinfo(data) {
      dispatch(change_userinfo(data))
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute)
