import React, { useState, useEffect } from 'react'
import { change_file_typedownlist, change_userinfo } from '../../../../store/action'
import GroupUserJurisToexamine from '../GroupUserJurisToexamine'


import Maskbox from "../../../../components/maskbox";


import './index.css'
let selectNotIcon = require('../../../../static/images/icon12.png')
let selectIcon = require('../../../../static/images/icon13.png')

const IndexComponent = (props) => {
  let [userlist, setUserlist] = useState({
      creater:[],
      admin:[],
      general:[],
      leader:[],
      isverify:''
  })
  useEffect(() => {
    fetch(
      "https://shl-info.cn/front/Groupchat/getGroupUserList?userid=" +
      localStorage.getItem('userid') +
      "&chatcode=" +
      props.groupid ,{
        headers:{
          'token':localStorage.getItem('token') 
        }
      }
    )
    .then(data => {
      return data.json();
    })
    .then(res => {
      console.log(res)
      if(res.code==2000){
        setUserlist(res.data)
      // this.setState({
      //   creater:res.data.creater,
      //   admin:res.data.admin,
      //   general:res.data.general,
      //   leader:res.data.leader,
      //   isverify:res.data.isverify
      // })
      }

    });
  },[])
  
  let [changeid, setChangeid] = useState('')

  let [changetype, setChangetype] = useState('')
  let [changeindex, setChangeindex] = useState('')




  let [ismasknew, setIsmasknew] = useState(false)
  let [masktitle, setMasktitle] = useState('')
  let [masktext, setMasktext] = useState('')

  let thischangeids = (item,types,index)=>{
    setChangetype(types)
    setChangeindex(index)
    console.log(1111)
    console.log(item.id)
    setChangeid(item.id)
  }
  let changeuserstate = (type)=>{
    console.log(type)

    console.log(type)
    if(changetype==''){
      setIsmasknew(true)
      setMasktitle('请选择')
      setMasktext('请先勾选要切换身份用户')
     
    }else if(type==1 && userlist['leader'].length>0){

      setIsmasknew(true)
      setMasktitle('选择失败')
      setMasktext('请先把组长切换为其他身份')
    }else{
      if(type==5){
        fetch(
          "https://shl-info.cn/front/groupchat/delGroupUser", {
          method: 'POST',
          body: JSON.stringify({
            "chatcode": props.groupid ,
            "userid": localStorage.getItem('userid'),
            "deluserid": userlist[changetype][changeindex]['userid'],
          }),
          headers:{
            'content-type': 'application/json',
            'token':localStorage.getItem('token')
          },
          header: {
            'content-type': 'application/json',
            'token':localStorage.getItem('token')
          },
        })
          .then(data => {
            return data.json();
          })
          .then(res => {
            if(res.code==2000){
              fetch(
                "https://shl-info.cn/front/Groupchat/getGroupUserList?userid=" +
                localStorage.getItem('userid') +
                "&chatcode=" +
                props.groupid  ,{
                  headers:{
                    'token':localStorage.getItem('token') 
                  }
                }
              )
                .then(data => {
                  return data.json();
                })
                .then(res => {
                  console.log(res)
                  if(res.code==2000){
                    setUserlist(res.data)
                    setChangetype('')
                    setChangeindex('')
                    setChangeid('')
                  }
                });
            }
          })
      }else{
        fetch(
          "https://shl-info.cn/front/groupchat/updateUserType", {
          method: 'POST',
          body: JSON.stringify({
            "chatcode": props.groupid ,
            "userid": localStorage.getItem('userid'),
            "changeuserid": userlist[changetype][changeindex]['userid'],
            "usertype": type,
          }),
          headers:{
            'content-type': 'application/json',
            'token':localStorage.getItem('token')
          },
          header: {
            'content-type': 'application/json',
            'token':localStorage.getItem('token')
          },
        })
          .then(data => {
            return data.json();
          })
          .then(res => {
            if(res.code==2000){
              fetch(
                "https://shl-info.cn/front/Groupchat/getGroupUserList?userid=" +
                localStorage.getItem('userid') +
                "&chatcode=" +
                props.groupid  ,{
                  headers:{
                    'token':localStorage.getItem('token') 
                  }
                }
              )
                .then(data => {
                  return data.json();
                })
                .then(res => {
                  console.log(res)
                  if(res.code==2000){
                    setUserlist(res.data)
                    setChangetype('')
                    setChangeindex('')
                    setChangeid('')
                  }
                });
            }
          });
      }


    }


  }
  

  let [groupUserJurisToexaOpen, setGroupUserJurisToexaOpen] = useState(false)

  let groupUserJurisItem = (item,types,index) => {
    console.log(item)
    return (
      <div className="groupUserJurisItem">
        <div className="groupUserJurisItemName">{item.username}</div>
        <div className="groupUserJurisItemOper">
          <div className="groupUserJurisItemLable">{item.type==4?"创建者":item.type==1?"组长":item.type==2?"管理员":item.type==3?"组员":''}</div>
          {/* {changeid}----{item.id} */}
          {props.usertypes==2 && types =='general'?<img src={changeid === item.id?selectIcon: selectNotIcon } alt="" className="groupUserJurisItemSelect" onClick={()=>thischangeids(item,types,index)} />:props.usertypes==1 &&( types =='general' ||  types =='admin' )?<img src={changeid === item.id?selectIcon: selectNotIcon } alt="" className="groupUserJurisItemSelect" onClick={()=>thischangeids(item,types,index)} />:props.usertypes==4 && types !='creater'?<img src={changeid === item.id?selectIcon: selectNotIcon } alt="" className="groupUserJurisItemSelect" onClick={()=>thischangeids(item,types,index)} />:null}
          {/* {types !='creater'?<img src={changeid === item.id?selectIcon: selectNotIcon } alt="" className="groupUserJurisItemSelect" onClick={()=>thischangeids(item,types,index)} />:null} */}
        </div>
      </div>
    )
  }
  let closeMask =()=>{
    setIsmasknew(false)
  }

  return (
    <div className="groupUserJuris ">
      {ismasknew?<Maskbox info={{
            title: masktitle,
            text: masktext,
          }}
          closeMask={closeMask}
          ></Maskbox>:null}
      <div className="groupUserJurisHead">
        <img src={require('../../../../static/images/icon6.png')} alt="" className="groupUserJurisReturn" onClick={() => props.closeFunc()}  />
        <div className="groupUserJurisButton" onClick={() => setGroupUserJurisToexaOpen(true)}>进群审核
          {userlist.isverify==1?<span></span>:null}
        </div>
      </div>
      
      <div className="groupUserJurisContent scrollbar">
        <div className="groupUserJurisList">
          <div className="groupUserJurisList-admin">
            {userlist.creater.map((item,index)=>{
              return  groupUserJurisItem(item,'creater',index)
            })}

            {userlist.leader.map((item,index)=>{   
              return  groupUserJurisItem(item,'leader',index)
            })}

            {userlist.admin.map((item,index)=>{   
              return  groupUserJurisItem(item,'admin',index)
            })}
          </div>

        {userlist.general.map((item,index)=>{   
          return  groupUserJurisItem(item,'general',index)
        })}
          {/* { groupUserJurisItem() }
          { groupUserJurisItem() }
          { groupUserJurisItem() }
          { groupUserJurisItem() }
          { groupUserJurisItem() }
          { groupUserJurisItem() }
          { groupUserJurisItem() }
          { groupUserJurisItem() }
          { groupUserJurisItem() }
          { groupUserJurisItem() }
          { groupUserJurisItem() }
          { groupUserJurisItem() }
          { groupUserJurisItem() }
          { groupUserJurisItem() } */}
        </div>
      </div>
        {props.usertypes==2?
          <div className="groupUserJurisOperation">
              {changetype=='leader'  ?<div className="groupUserJurisButton grey" >设为管理员</div>:<div className="groupUserJurisButton grey" >设为组长</div>}
              {changetype=='general'?<div className="groupUserJurisButton grey" >设为管理员</div>: <div className="groupUserJurisButton grey" >设为组员</div>}
            <div className="groupUserJurisButton red" onClick={()=>changeuserstate(5)}>移除</div>
          </div>
        :null}


        {props.usertypes==1?
          <div className="groupUserJurisOperation">
            {changetype=='leader'  ?<div className="groupUserJurisButton grey" >设为管理员</div>:<div className="groupUserJurisButton grey" >设为组长</div>}
            {changetype=='general'?
              <div className="groupUserJurisButton" onClick={()=>changeuserstate(2)}>设为管理员</div>
            : <div className="groupUserJurisButton" onClick={()=>changeuserstate(3)}>设为组员</div>}
            <div className="groupUserJurisButton red" onClick={()=>changeuserstate(5)}>移除</div>
          </div>
        :null}


{props.usertypes==4 ?<div className="groupUserJurisOperation">
       {/* {props.usertypes==2?} */}
        {changetype=='leader'?
          <div className="groupUserJurisButton" onClick={()=>changeuserstate(2)}>设为管理员</div>
        :<div className="groupUserJurisButton" onClick={()=>changeuserstate(1)}>设为组长</div>}

         {changetype=='general'?
          <div className="groupUserJurisButton" onClick={()=>changeuserstate(2)}>设为管理员</div>
        : <div className="groupUserJurisButton" onClick={()=>changeuserstate(3)}>设为组员</div>}
        
       
        <div className="groupUserJurisButton red" onClick={()=>changeuserstate(5)}>移除</div>
      </div>:null}
      
      {groupUserJurisToexaOpen && <GroupUserJurisToexamine groupid={props.groupid} closeFunc={() => setGroupUserJurisToexaOpen(false)}></GroupUserJurisToexamine>}
    </div>
  )
};

export default IndexComponent;
