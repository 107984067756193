import React from 'react'
import { Link } from 'react-router-dom'

import '../classbox/index.css'

function CLassLabel(props) {
    let tips = null;
    if(props._item.tips){
        tips = props._item.tips.map((item) => {
            return (
                <li key={item.id}>
                    {item.text}
                </li>
            )
        })
    }
   // props._item['isscorm'] = 1;
    return (
        <div className="class-box transform_hover">
            <Link to={props._item.link}>
                <span className="class-banner">
                    {props._item.isscorm==1?<span className="isscorm">交互式课程</span>:null}
                    <img className="tranform_img" src={props._item.src} alt=""/>
                    <b>{props._item.nums}</b>
                </span>
                <div className="class-labeltext">
                    <p>{props._item.title}</p>
                    <ul className="label-tips">
                        <li>
                            <img src={require('../../static/images/peopleIcon.png')} alt=""/>
                            {props._item.name}
                        </li>
                        <li>
                            <img src={require('../../static/images/dateIcon.png')} alt="" />
                            {props._item.time}
                        </li>
                        {/* <li>
                            <img src={require('../../static/images/playnumsicon.png')} alt="" />
                            {props._item.nums}
                        </li> */}
                        {tips}
                    </ul>
                </div>
            </Link>
        </div>
    )
}

export default CLassLabel
