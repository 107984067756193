import React, { Component } from 'react'
// 组件
import Header from '../../components/header'
import Footer from '../../components/footer'
import PersonImg from '../../components/personimg'
import PersonTitle from '../../components/persontitle'
import RecordList from '../../components/recordlist'

import './index.css'

class DownRecord extends Component {

  componentDidMount() {
    this.props.change_active_two('downrecord')
    this.props.change_person_downcord_data(1)
  }
  componentWillUnmount() {
    
  }
  render() {
    let list = null;
    if (this.props.downrecord){
      list = this.props.downrecord.map((item, index) => {
        let jsx_dom = <span><span className="less-font">下载时间：</span>{item.time}</span>;
        return (
          <RecordList
            key={index}
            title={item.type}
            right={jsx_dom}>

          </RecordList>
        )
      })
    }
    return (
      <div className="personpage">
        <Header active="4"></Header>
        <PersonImg type="no"></PersonImg>
        <PersonTitle title="下载记录"></PersonTitle>
        <div className="wrap minheightmargin">{list}</div>
        <Footer></Footer>
      </div>
    );
  }
}

export default DownRecord
