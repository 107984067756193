import React, { useState, useEffect } from 'react'
import './index.css'
import CourseItem from '../CourseItem'
import groupDetail from '../..';

const IndexComponent = (props) => {


  let [packidname, setPackidname]= useState('')
  let [packidlist, setPackidlist]= useState([])
  let [packidliststate, setPackidliststate]= useState(false)
  
  useEffect(() => {
    console.log(33333)
    fetch(
      "https://shl-info.cn/front/groupchat/getCourseListByPackId?userid=" +
      localStorage.getItem('userid') +
      "&packcode=" +
      props.packidinfo.packcode ,{
        headers:{
          'token':localStorage.getItem('token') 
        }
      }
    )
      .then(data => {
        return data.json();
      })
      .then(res => {
        console.log(res)
        if(res.code==2000){
          setPackidname(res.data.packname)
          if(res.data.courseinfo){
            setPackidlist(res.data.courseinfo)
          }

          setPackidliststate(true)
        }
      });
  }, [props.packidinfo.packcode])
  let godetail = (id) =>{

     props.history.push('/coursedetails?type='+id)
  }
  let getcourselistbypackid = () =>{
    fetch(
      "https://shl-info.cn/front/groupchat/getCourseListByPackId?userid=" +
      localStorage.getItem('userid') +
      "&packcode=" +
      props.packidinfo.packcode ,{
        headers:{
          'token':localStorage.getItem('token') 
        }
      }
    )
      .then(data => {
        return data.json();
      })
      .then(res => {
        console.log(res)
        if(res.code==2000){
          setPackidname(res.data.packname)
          if(res.data.courseinfo){
            setPackidlist(res.data.courseinfo)
          }

          setPackidliststate(true)
        }
      });
  }
  let delcourser = (e,courseid) =>{
    console.log(e)
    console.log(props)
    fetch(
      "https://shl-info.cn/front/groupchat/delCourseForPack",{
        method: 'POST',
        body: JSON.stringify({
          "chatcode": props.groupid,
          "userid": localStorage.getItem('userid'),
          "courseid": courseid.id,
          "packid": props.packidinfo.packcode,
        }),
        headers:{
          'content-type': 'application/json',
          'token':localStorage.getItem('token')
        },
        header: {
          'content-type': 'application/json',
          'token':localStorage.getItem('token')
        },
      }
    )
      .then(data => {
        return data.json();
      })
      .then(res => {
        getcourselistbypackid();
      })
      e.stopPropagation();
    // //getcourselistbypackid();
    // e.stopPropagation();
  }
  let groupChatCourseItem = (item,index) => {
    console.log(item)
    let canstate = false;
    if(props.packidinfo.isdefault==0  && (props.usertypes==1 || props.usertypes==2 ||props.usertypes==4) ){
      canstate=true
    }
    return (
      <div className="groupChatCourseItem" key={index} onClick={()=>godetail(item.id)}>
        <CourseItem canstate={canstate}  item={item} delcourser={delcourser}>
          {/* <div className="groupChatCourseTime">
            <img src={require('../../../../static/images/icon3.png')} alt="" className="groupChatCourseTimeIcon" />
            <div className="groupChatCourseTimeText">已观看06:26</div>
          </div> */}
        </CourseItem>
      </div>
    )
  }
  console.log(props)

  return (
    <div className="groupChatCourse">
      <div className="groupChatCourseHead">
        <div className="groupChatCourseTitle">{packidname}</div>
        { (props.usertypes==1 || props.usertypes==2 ||props.usertypes==4) ?props.packidinfo?props.packidinfo.isdefault!=1?  <div className="groupChatCourseAdd" onClick={() => props.addCourseFunc()}>
          <img src={require('../../../../static/images/icon11.png')} alt="" className="groupChatCourseAddIcon"  />
         <div className="groupChatCourseAddText">添加课程</div>
        </div>:null :null :null}
       
      </div>

      <div className="groupChatCourseList">
        {packidlist.map((item,index)=>{
          return groupChatCourseItem(item,index)
        })}
        {!packidliststate?<div className="addtextloading">加载中</div>:null}
        {packidlist.length<=0 && packidliststate?<div className="addtextloading">暂无内容</div>:null}
        {/* { groupChatCourseItem() }
        { groupChatCourseItem() }
        { groupChatCourseItem() } */}
      </div>
    </div>
  )
};

export default IndexComponent;
