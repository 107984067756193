import React, { Component } from 'react'
// 组件
import Header from '../../components/header'
import Footer from '../../components/footer'
import PersonImg from '../../components/personimg'
import PersonTitle from '../../components/persontitle'
import HistoryList from '../../components/historylist'
import './index.css'

class Previewcsorder extends Component {
  constructor(props) {
    super(props)
    this.state = {
      infomation: "",
    }
  }
  

  getUrlkey(url) {
    var params = {};
    var urls = url.split("?");                  //////console.log('1_分割url:', urls)
    var arr = urls[1].split("&");               //////console.log('2_分割urls[1]:', arr)
    for (var i = 0, l = arr.length; i < l; i++) {
      var a = arr[i].split("=");                //////console.log('3_遍历 arr 并分割后赋值给a:', a[0], a[1])
      params[a[0]] = a[1];                      //////console.log('4_a给params对象赋值:', params)
    }                                           //////console.log('5_结果:', params)
    return params;
  }

  componentDidMount() {
    if(window.location.href.indexOf('?')>=0){
      let thisurls = this.getUrlkey(window.location.href);
      console.log(thisurls)
      if(thisurls.orderid){
        fetch(
        "https://shl-info.cn/front/Cscourse/getOrderInfoByOrderCode?ordercode="+thisurls.orderid
        )
        .then(data => {
          return data.json();
        })
        .then(res => {
          console.log(res)
          if(res.code==2000){
            console.log(2222)
            this.setState({
              infomation:res.data
            })
          }
        })
      }
    }
   
  }
  componentWillUnmount() {
    
  }
  
  
  render() {
    let list = null;
    if (this.props.history){
      list = this.props.history.map((item) => {
        return (
          <HistoryList key={item.id} _item={item}></HistoryList>
        )
      })
    }
    return (
      <div className='previewcsorder'>
       <div className='toplogo'>
         <div className="logoimg"></div>
       </div>
       <div className="csorderbox">
         <div className="csorderlayer">
           <div className="csorder-title"><img src={require('../../static/images/csorder-title.png')}  /></div>
           <div className="csordercontent">
             <div className="csordercontent_title">感谢您在西门子医疗西影力商城上的订购</div>
             <div className="csordercontent_desc">您（买方）的订单信息如下：</div>
             <div className="csordercontent_to">
               <span className="csordercontent_toleft"><i>致：</i>{this.state.infomation?this.state.infomation.userinfo.name:null}</span>
               <span className="csordercontent_toright"><i>买方联系方式：</i>{this.state.infomation?this.state.infomation.userinfo.phone:null}  {this.state.infomation?this.state.infomation.userinfo.email:null}</span>
             </div>
             
             <div className="csordercontent_oderinfo">
               <span className="csordercontent_oderinfo_left"><i>西门子医疗官方商城订单号：</i>{this.state.infomation?this.state.infomation.fictitiousnum:null}</span>
               <span className="csordercontent_oderinfo_right"><i>交付方式：</i>线上交付 <i className="marginleft">付款方式：</i>微信支付</span>
             </div>
             <div className="csordercontent_orderlist">
               <div className="csordercontent_orderlist_li gray">
                 <span className="csordercontent_orderlist_li_no">序号</span>
                 <span className="csordercontent_orderlist_li_name">购买内容</span>
                 <span className="csordercontent_orderlist_li_num">数量</span>
                 <span className="csordercontent_orderlist_li_money">单价RMB(含税)</span>
                 <span className="csordercontent_orderlist_li_allmoney">小计RMB（含税）</span>
               </div>
               
               <div className="csordercontent_orderlist_li">
                 <span className="csordercontent_orderlist_li_no">01</span>
                 <span className="csordercontent_orderlist_li_name">{this.state.infomation?this.state.infomation.seriesinfo.name:null}</span>
                 <span className="csordercontent_orderlist_li_num">1</span>
                 <span className="csordercontent_orderlist_li_money">{this.state.infomation?this.state.infomation.paymoney:null}</span>
                 <span className="csordercontent_orderlist_li_allmoney">{this.state.infomation?this.state.infomation.paymoney:null}</span>
               </div>
             </div>

             <div className="csordercontent_ordertipsli">
               <span className="csordercontent_ordertipsli_left">优惠金额：</span>
               <span className="csordercontent_ordertipsli_right">0</span>
             </div>
             
             <div className="csordercontent_ordertipsli">
               <span className="csordercontent_ordertipsli_left">总价(净值)：	</span>
               <span className="csordercontent_ordertipsli_right"> {this.state.infomation?this.state.infomation.payprice:null}</span>
             </div>

             
             <div className="csordercontent_ordertipsli">
               <span className="csordercontent_ordertipsli_left">增值税金额(税率6.0%) ： </span>
               <span className="csordercontent_ordertipsli_right"> {this.state.infomation?this.state.infomation.paylv:null}</span>
             </div>


             
             <div className="csordercontent_ordertipsli">
               <span className="csordercontent_ordertipsli_left">总价(含税)：	</span>
               <span className="csordercontent_ordertipsli_right">  {this.state.infomation?this.state.infomation.paymoney:null}</span>
             </div>

              <div className="csordercontent_ordertipstext">买方支付相应费用后，系统自动生成本订单确认单，应视为双方买卖合同关系成立的正式法律文本，西门子医疗西影力学院商城销售条款为本订单的一部分，对买卖双方具有法律约束力。</div>
            
           </div>
           <div className="csordercontent_orderbottomtext">
            西门子医疗系统有限公司
              <span>DATE 日期  {this.state.infomation?this.state.infomation.paytime:null}</span>
            </div>
         </div>
       </div>
      </div>
    )
  }
}

export default Previewcsorder
