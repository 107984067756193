import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import classnames from 'classnames';
import axios from 'axios';
import { Link } from 'react-router-dom'

import './index.css'
function PostItem(props) {
    let userid = localStorage.getItem('userid'), token = localStorage.getItem('token');
    let data = props.data
    //console.log(data)
    let [ isimg, setIsimg ] = useState(false)
    let [ imgurl, setImgurl ] = useState("")

    useEffect(() => {
        console.log(props.data)
        init()
    }, [])

    let init = () => {
        // console.log('------------ props.data')
        // console.log(data)
        console.log(data.title)
        // console.log(data.data.indexOf('<img'))
        if(data.data.indexOf('<img') >=0){
            console.log('------------ props.data1111')
            let htmlstr = document.getElementsByTagName('html')[0].innerHTML;
            let reg = /<img.+?src=('|")?([^'"]+)('|")?(?:\s+|>)/g;
            let arr = [];
            let imgurl =  reg.exec(data.data)
            console.log(imgurl)
            // if(imgurl){
            //     setIsimg(true)
            //     setImgurl(imgurl[2])
            // }
        }
        //console.log(props.data.user.name)
    }
    
    let pageInit = () => {
    }

    let postItemLabelDom = () => {
        if (props.hide && props.hide.indexOf('label') !== -1) return
        return (
            <div className="postItemLabel">#{ data.topic?data.topic.title:null }</div>
        )
    }
    let postItemUserLabelDom = () => {
        console.log(props.data)
        if (props.hide && props.hide.indexOf('userLabel') !== -1) return
        return (
            <div className="postItemUserLabel">{ props.data?props.data.topic.keywords_text[0]['title']:null }</div>
        )
    }
    const godetail =(ids)=>{
        console.log(ids)
        if(props.isgodetail){
            props.history.push(`/postDetail?id=${ids}`)
        }
        
    }
    
    let imgList = [];
    if(data.data.indexOf('<img') >=0){
        console.log('------------ props.data1111')
        let htmlstr=data.data
        htmlstr.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/g, (match, capture) => {
              imgList.push(capture);
        });
       
        console.log(imgList)
       
       // thisimgurl = thisimgurlarray[2]
        //console.log(thisimgurl)
        // if(imgurl){
        //     setIsimg(true)
        //     setImgurl(imgurl[2])
        // }
    }
    return (
        // <Link className="postItem" to={`/postDetail?id=${data.id}`}>
        <div className='postItem' onClick={()=>godetail(data.id)}>

            <div className="postItemCont">
                { postItemLabelDom() }
                <div className="postItemTitle">{data.isbest==1?<span className="isbesticon"></span>:null}{ data.title }</div>
                <div className="postItemUser">
                    <img className="postItemUserImg" src={ data.user && data.user.headimgurl?data.user.headimgurl:require('../../static/images/icon-portrait.png') }/>
                    <div className="postItemUserName">{ data.user && data.user.name_text }</div>
                    { postItemUserLabelDom() }
                </div>
                {/* {console.log(data.data.replace(/<(?!img).*?>/g, ""))} */}
                {data?<div className="postItemContent" dangerouslySetInnerHTML={{__html:data.data.replace(/<(?!img).*?>/g, "") }}></div>:null}
                
                <div className="postItemOption">
                    <div className="postItemOptionItem">
                        <img className="postItemOptionItemIcon" src={require('../../static/images/icon-1.png')} />
                        <div className="postItemOptionItemText">{ data.hits_count }</div>
                    </div>
                    <div className="postItemOptionItem">
                        <img className="postItemOptionItemIcon" src={require('../../static/images/icon-2.png')}/>
                        <div className="postItemOptionItemText">{ data.comment_count }</div>
                    </div>

                    <div className="postItemOptionItem">
                        <img className="postItemOptionItemIcon" src={require('../../static/images/playnumsicon.png')}/>
                        <div className="postItemOptionItemText">{ data.playcount }</div>
                    </div>
                    <div className="postItemOptionItem creattime">
                        <img className="postItemOptionItemIcon" src={require('../../static/images/icon_times.png')}/>
                        <div className="postItemOptionItemText">{ data.created }</div>
                    </div>

                </div>
            </div>
            {/* {imgurl} */}
            {imgList.length>0?<div className="postItemRight">
                <img className="postItemImg" src={imgList[0]}/>
            </div> :null}
            {/* <div className="postItemRight">
                <img className="postItemImg" src={require('../../static/images/publicimg/classimg3.png')}/>
            </div> */}
            { props.children }
        </div>
        // </Link>
    )
}
// mapStateToProps：将state映射到组件的props中
const mapStateToProps = (state) => {
    return {
        userinfo: state.userinfo
    }
}

// mapDispatchToProps：将dispatch映射到组件的props中
const mapDispatchToProps = (dispatch) => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PostItem)
