import React, { Component, useState,useEffect } from 'react'
import classnames from 'classnames'
// 组件
import Header from '../../components/header'
import Footer from '../../components/footer'
import PersonimgNew from '../../components/personimgNew'
import MaskBox from '../../components/maskbox'

import './index.css'
import $ from 'jquery';


import copy from 'copy-to-clipboard';


import UserpostComment from '../userpostComment'


const UserNotice = (props) => {
  let [ postState, setPostState ] = useState(9)

  let [ postText, setPostText ] = useState('')
  let [ postImg, setPostImg ] = useState('')
  let [ postids, setPostids ] = useState(0)
  let [ postartids, setPostartids ] = useState(0)
  let [ postcommids, setPostcommids ] = useState(0)

  let [ postTexterror, setPostTexterror ] = useState(false)
  let [ isLoading, setIsloading ] = useState(false)
  let [ showlayerstate, setShowlayerstate ] = useState(false)
  

  let [ activetype, setActivetype ] = useState(3)
  
  let [pagesnums,setPagesnums] =useState(1)
  let [morestate,setMorestate] =useState(true)


  let [showcopylayer,setShowcopylayer] =useState(false)
  

  let [postlist,setPostlist] =useState([])
  let [noticelist,setNoticelist] =useState([])
  let [dellist,setDellist] =useState('')
  let [dellistindex,setDellistindex] =useState('')



  let [tipslist,setTipslist] =useState([])
  let [tipsindex,setTipsindex] =useState('')



  let [postLiAlert, setPostLiAlert] = useState({
    open: false,
    x: 0,
    y: 0
  })
  useEffect(() => {
    if(!localStorage.getItem('userid')){
      props.history.push('/homedoctorinteraction');
      return;
    }
    changepoststate(0);
    getTipslist();
  }, [])
 let getTipslist = ()=>{


  $.ajax({
    type: "GET",
    url:
      "https://shl-info.cn/front/Insidenews/getInsideNew?userid=" +
      localStorage.getItem('userid') ,
      data: {
      },
    dataType: "json",
    headers:{'token':localStorage.getItem('token')},
    cache: false
  })
  .catch(error => {
    //console.log(error);
  })
  .then(res => {
    console.log(res)
    if(res.code==2000){
      console.log(res.data)
      setTipslist(res.data)
    }
  })



  // fetch(
  //   ""+localStorage.getItem('userid'),{
  //     headers:{
  //       'token':localStorage.getItem('token') 
  //     }
  //   }
  // )
  // .then(res => {
  //   console.log(res)
  // })
 }
  // 打开input弹框
  let openPostInputAlert = (e,ids,articleid,commentid) => {
    //console.log(e,ids)
    setPostids(ids)
    setPostartids(articleid)
    setPostcommids(commentid)
    //console.log('e,ids')
    e.persist();
    setPostLiAlert({
      open: true, 
      x: e.clientX - e.nativeEvent.layerX, 
      y: e.clientY - e.nativeEvent.layerY
    })
  }

  // 关闭input弹框
  let closePostInputAlert = () => {
    setPostLiAlert({
      open: false, 
      x: 0, 
      y: 0
    })
  }
  const subcommit =()=>{
    if(postText==''){
      setPostTexterror(true)
      return false;
    }
    
    let conmmentid=postcommids;
    let article_id=postartids;
    $.ajax({
      type: "POST",
      url:
        "https://shl-info.cn/front/bbs/createArticleComment/article_id/"+article_id+"?user_id=" +
        localStorage.getItem('userid') ,
        data: {
          "parent_comment_id": conmmentid,
          "data": postText,
          "img": postImg,
        },
      dataType: "json",
      headers:{'token':localStorage.getItem('token')},
      cache: false
    })
    .catch(error => {
      //console.log(error);
    })
    .then(res => {
      closePostInputAlert();
      //console.log(res)
      setPostids('')
      setPostartids('')
      setPostcommids('')
      setPostText('')
      setPostImg('')
      setIsloading(false)


      //setPostState(0)
      changepoststate(0)
      
    });

    //console.log(2222)
  }
  const closeimg =()=>{
    setPostImg('')
    setIsloading(false)
    var imgfile = document.querySelector('#imgFile');//获取input
    imgfile.value=''
  }
  const oninput =(e)=>{
    setPostText(e.target.value)
    setPostTexterror(false)
  }

  const imgupload = (e) => {
    ////console.log(e)
    setIsloading(true)
    let that = this
    var imgfile = document.querySelector('#imgFile');//获取input
    var file = imgfile.files[0];
    // var reader = new FileReader();
    // reader.readAsDataURL(file);
    // reader.onload = function (e) {
    //   // 图片base64化
    //   var newUrl = this.result;
    //   that.setState({
    //     newUrl: newUrl
    //   })
    // };
    var formFile = new FormData();

    let userid = (localStorage.getItem('userid'));
    let token = (localStorage.getItem('token'));
    formFile.append("uploadFile", file); //加入文件对象
    $.ajax({
      type: "post",
      url: "https://shl-info.cn/front/frontc/adduserworkspc?userid=" + userid ,
      data: formFile,
      headers:{'token':localStorage.getItem('token')},
      //是否预处理,默认值为true,这里改成false
      processData: false,
      //是否设置内容,默认值为true,这里改成false
      contentType: false,
      success: function (res) {
        //alert('提交成功');
        
        var backres = JSON.parse(res);
        //console.log(backres.pic)
        setPostImg(backres.pic)

       // setIsloading(false)
        
      },
      error: function (data) {
        //alert('提交失败');
      }
    });
  }
  //console.log(isLoading,postImg)
  //console.log('isLoading,postImg')
  let postLiAlertDom = (
    <>
    <div className="postLiAlertCurtain" onClick={ closePostInputAlert }></div>
    <div className="postLiAlert" style={ {left: `${postLiAlert.x}px`, top: `${postLiAlert.y}px`} }>
      <textarea  className="postLiAlertInput" onInput={(e)=>oninput(e)} ></textarea>
      <div className={postTexterror?"postLiAlertInputTips":"postLiAlertInputTips none"}>请输入内容</div>
      <div className="postLiAlertBottom">
        <div className="postLiAlertFiles">

          <div className={isLoading?"postLiAlertFilesList":"none"}>
            <div className={isLoading && !postImg?"postLiAlertFilesItem":"postLiAlertFilesItem none"}>
              <div className="postLiAlertFilesItemContent">
                <div className="postLiAlertFilesItemTips">上传中...</div>
              </div>
              {/* <img className="postLiAlertFilesItemIcon" src={require('../../static/images/classErrorpng.png')} /> */}
            </div>
            
            <div className={isLoading && postImg?"postLiAlertFilesItem":"postLiAlertFilesItem none"}>
              <div className="postLiAlertFilesItemContent">
                <img src={postImg} alt="" className="postLiAlertFilesItemImg"/>
              </div>
              <img className="postLiAlertFilesItemIcon" onClick={closeimg} src={require('../../static/images/classErrorpng.png')} />
            </div>
          </div>
          <input type="file" id="imgFile"  name='img'  className="" accept=".jpg,.png,.jpeg" onChange={(e) => imgupload(e)}   />
          <img src={require('../../static/images/icon-4.png')} className={isLoading ?"postLiAlertFilesTips none":"postLiAlertFilesTips"} />
        </div>
        <div className="postLiAlertSub" onClick={subcommit}>发布</div>
      </div>
    </div>
    </>
  )
  let replyDom = () => {
    //console.log(postlist)
    postlist.map(()=>{
      return (
        <div className="postList">
          <div className="postLi">
            <div className="postLiPos">
              <div className="postLiButton" onClick={ openPostInputAlert }>回复</div>
              <img className="postLiClose" src={require('../../static/images/icon-3.png')} />
            </div>
            
            <div className="postLiUser">
              <img className="postLiUserImg" src={require('../../static/images/userHead.png')} alt=""/>
              <div className="postLiUserName">王佚名</div>
              <div className="postLiUserTips">回复了你发的帖子</div>
              <div className="postLiUserDate">1个月前</div>
            </div>
            <div className="postLiComment">ADVIA® Centaur XPT 全自动化学发光免疫分析仪</div>
            <div className="postLiData">
              <div className="postLiDataReply">hello world 将挑战变为教育</div>
              <div className="postLiDataBody">
                <div className="postLiDataLeft">
                  <div className="postLiTitle">ADVIA® Centaur XPT 全自动化学发光免疫分析仪</div>
                  <div className="postLiContent">检测菜单包括：性激素检测、甲状腺功能、肿瘤标志物、心脏标志物、传染性疾病、贫血、代谢和药物浓度监测、性激素检测、甲状腺功能、肿瘤标志物、心脏标志物、传染性疾病、贫血、代谢和药物浓度监测等</div>
                  <div className="postLiControl">
                    <div className="postLiControlFunc">
                      <img src={ require('../../static/images/icon-1.png') } className="postLiControlFuncIcon"/>
                      <div className="postLiControlFuncText">80</div>
                    </div>
                    <div className="postLiControlFunc">
                      <img src={ require('../../static/images/icon-2.png') } className="postLiControlFuncIcon"/>
                      <div className="postLiControlFuncText">32</div>
                    </div>
                  </div>
                </div>
      
                <div className="postLiDataRight">
                  <div className="postLiBanner">
                    <img src={require('../../static/images/publicimg/classimg3.png')} alt="" className="postLiBannerImg"/>
                  </div>
                </div>
  
              </div>
            </div>
          </div>
        </div>
      )
    })
    
  }

  let systemNoticeDom = () => {
    return (
      <div className="systemNotice">
        <div className="systemNoticeLi">
          <div className="systemNoticeLiTitle">您的帖子已被删除</div>
          <div className="systemNoticeLiContent">检测菜单包括：性激素检测、甲状腺功能、肿瘤标志物、心脏标志物、传染性疾病、贫血、代谢和药物浓度监测、性激素检测、甲状腺功能、肿瘤标志物、心脏标志物、传染性疾病、贫血、代谢和药物浓度监测等</div>
          <div className="systemNoticeLiDate">1个星期前</div>
          <img src={require('../../static/images/icon-3.png')} alt="" className="systemNoticeLiClose"/>
        </div>
        <div className="systemNoticeLi">
          <div className="systemNoticeLiTitle">您的帖子已通过审核</div>
          <div className="systemNoticeLiContent">您的帖子“ADVIA® Centaur XPT 全自动化学发光免疫分析仪”已通过审核成功发布</div>
          <div className="systemNoticeLiDate">3个星期前</div>
          <img src={require('../../static/images/icon-3.png')} alt="" className="systemNoticeLiClose"/>
        </div>
        <div className="systemNoticeLi">
          <div className="systemNoticeLiTitle">有人在帖子中提到了您 </div>
          <div className="systemNoticeLiContent">李一鸣在帖子“ADVIA® Centaur XPT 全自动化学发光免疫分析仪”中提到您</div>
          <div className="systemNoticeLiDate">12-25</div>
          <img src={require('../../static/images/icon-3.png')} alt="" className="systemNoticeLiClose"/>
        </div>
      </div>
    )
  }

  let contentDom = () => {
    //console.log(postState)
    if (postState === 0) {
      return replyDom()
    }
    if (postState === 1) {
      return replyDom()
    }
    if (postState === 2) {
      return systemNoticeDom()
    }
  }
  const closeMask = ()=>{
    console.log(333)
    setShowlayerstate(false)
  }
  const changepoststate =(ids,types) =>{
    //console.log(ids)
    if(ids===postState && !types){
      //console.log("isnotmore")
      return false;
    }
    if(ids!=postState){
      //console.log(111)
      pagesnums=1;
      setPagesnums(1)
      setMorestate(true)
    }
    if(ids===0 ){
      fetch(
        "https://shl-info.cn/front/bbs/userNotice/?type=1,2&user_id="+localStorage.getItem('userid')+"&page="+pagesnums
      )
      .then(data => {
        return data.json();
      })
      .then(res => {
        if(types==1){
          let olddata=postlist;
          olddata.push.apply(olddata, res.data);
          setPostlist(olddata)
          if(res.data.length<10){
              setMorestate(false)
          }
        }else{
          setPostlist(res.data)
        }
        //console.log(pagesnums)
        let nums=pagesnums+1
        //console.log(nums)
        setPagesnums(nums)
        //console.log(res)
        setPostState(ids)
      })
    }else if (ids==1){
      fetch(
        "https://shl-info.cn/front/bbs/userNotice/?type=3,4&user_id="+localStorage.getItem('userid')+"&page="+pagesnums
      )
      .then(data => {
        return data.json();
      })
      .then(res => {
       

        let newArr =res.data
        let newArrtmp=[];
        for(var i=0;i<newArr.length;i++){
          //console.log(newArr[i])
          if(newArr[i]['type']==4 && newArr[i]['data_text']){
            newArr[i]['data_text']['article']=newArr[i]['data_text']?newArr[i]['data_text']['comment']['article']:[];
          }
        }

        //console.log(newArr)

        if(types==1){
          let olddata=postlist;
          olddata.push.apply(olddata, newArr);
          setPostlist(olddata)
          if(res.data.length<10){
              setMorestate(false)
          }
        }else{
          //setPostlist(res.data)
          setPostlist(newArr)
        }
        //setPostlist(newArr)
        let nums=pagesnums+1
        //console.log(nums)
        setPagesnums(nums)
        //console.log(res)
        setPostState(ids)
      })
    }
    else if (ids==2){
      fetch(
        "https://shl-info.cn/front/bbs/userNotice/?type=5&user_id="+localStorage.getItem('userid')+"&page="+pagesnums
      )
      .then(data => {
        return data.json();
      })
      .then(res => {
       

        let newArr =res.data
        let newArrtmp=[];
        for(var i=0;i<newArr.length;i++){
          //console.log(newArr[i])
          if(newArr[i]['type']==4){
            newArr[i]['data_text']['article']=newArr[i]['data_text']['comment']['article'];
          }
        }

        //console.log(newArr)
        setNoticelist(newArr)
        setPostState(ids)
        let nums=pagesnums+1
        //console.log(nums)
        setPagesnums(nums)
        setPostState(ids)

      })
    }
    else{
      setPostState(ids)
    }
    
  }
  let posthtml=contentDom()


  let userhtml=null;
  //console.log(postState)
  if(postState===2){
    let sechtml=noticelist.map((item,index)=>{
      //console.log(item)
      return  <div className="systemNoticeLi">
      <div className="systemNoticeLiTitle">{item.data_text?item.data_text.state==2?"您的帖子已通过审核":"您的帖子已被删除":null}</div>
    <div className="systemNoticeLiContent">您的帖子“{item.data_text?item.data_text.title:null}”{item.data_text?item.data_text.state==2?"已通过审核成功发布":"已被拒绝发布":null}</div>
      <div className="systemNoticeLiDate">{item.created?item.created:null}</div>
      <img src={require('../../static/images/icon-3.png')} onClick={(e)=>closetalk(e,item,index)} alt="" className="systemNoticeLiClose"/>
    </div>
    })
    userhtml= 
    
      <div className="systemNotice">
        
       {sechtml?sechtml:"暂无内容"}
        {/* <div className="systemNoticeLi">
          <div className="systemNoticeLiTitle">您的帖子已通过审核</div>
          <div className="systemNoticeLiContent">您的帖子“ADVIA® Centaur XPT 全自动化学发光免疫分析仪”已通过审核成功发布</div>
          <div className="systemNoticeLiDate">3个星期前</div>
          <img src={require('../../static/images/icon-3.png')} alt="" className="systemNoticeLiClose"/>
        </div>
        <div className="systemNoticeLi">
          <div className="systemNoticeLiTitle">有人在帖子中提到了您 </div>
          <div className="systemNoticeLiContent">李一鸣在帖子“ADVIA® Centaur XPT 全自动化学发光免疫分析仪”中提到您</div>
          <div className="systemNoticeLiDate">12-25</div>
          <img src={require('../../static/images/icon-3.png')} alt="" className="systemNoticeLiClose"/>
        </div> */}
      </div>
    console.log(userhtml)
  }
  if(postState===0 ||postState===1){
    userhtml=postlist.map((item,index)=>{
      //console.log(item)
      let isimg=false;
      let imgurl='';
      let html=''
      if(item.type==3){
        html=item.data_text?item.data_text.article?item.data_text.article.data:'':'';
      }
      console.log(html)
      if(html.indexOf('<img') >=0){
        
        // let reg = /<img.+?src=('|")?([^'"]+)('|")?(?:\s+|>)/g;
        // let arr = [];
        // let imgurllist =  reg.exec(html)
        // isimg=true;
        // if(imgurllist){
        //   imgurl=imgurllist[2]
        // }

        let imgList = [];
        let newhtml=html
        newhtml.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/g, (match, capture) => {
              imgList.push(capture);
        });
        console.log(imgList)
        if(imgList.length>0){
          imgurl=imgList[0]
          isimg=true;
        }

      }
      //console.log(isimg,imgurl)
      let text='';
      if(item.type==1 ||item.type==2 ){
        text=item.data_tex?item.data_text.data:'';
      }else if(item.type==3){
        text='赞了你的帖子';
      }else if(item.type==4){
        text='赞了你的评论';
      }

      return (
        <div className="postList">
          <div className="postLi">
            <div className="postLiPos">
              <div className={item.type==3 || item.type==4?"postLiButton none":"postLiButton"} onClick={(e)=> openPostInputAlert(e,item.id,item.data_text.article_id,item.data_text.id)}   >回复</div>
              <img className="postLiClose" onClick={(e)=>closetalk(e,item,index)} src={require('../../static/images/icon-3.png')} />
            </div>
            
            <div className="postLiUser">
              <img className="postLiUserImg" src={item.re_use?item.re_user.headimgurl?item.re_user.headimgurl:require('../../static/images/icon-portrait.png'):require('../../static/images/icon-portrait.png')} alt=""/>
              <div className="postLiUserName">{item.re_use?item.re_user.name_text:null}</div>
              <div className="postLiUserTips">{item.type==2?"回复了你的评论":"回复了你发的帖子"}</div>
              <div className="postLiUserDate">{item.created}</div>
            </div>
            <div className="postLiComment">{text}</div>
            <div className="postLiData">
              <div className="postLiDataReply">{item.type==4 && item.data_text?item.user.name_text +'：'+item.data_text.comment.data:item.type ==2 && item.data_text.comment?item.re_user.name_text +'：'+item.data_text.comment.data:null}</div>
              <div className="postLiDataBody">
                <div className="postLiDataLeft active">
                  <div className="postLiTitle">{item.data_text?item.data_text.article?item.data_text.article.title:null:null}</div>
                  <div className="postLiContent" dangerouslySetInnerHTML={{__html:item?item.data_text?item.data_text.article?item.data_text.article.data.replace(/<(?!img).*?>/g, ""):'':null :null }}></div>
                  <div className="postLiControl">
                    <div className="postLiControlFunc">
                      <img src={ require('../../static/images/icon-1.png') } className="postLiControlFuncIcon"/>
                      <div className="postLiControlFuncText">{item.data_text?item.data_text.article?item.data_text.article.hits_count:null:null}</div>
                    </div>
                    <div className="postLiControlFunc">
                      <img src={ require('../../static/images/icon-2.png') } className="postLiControlFuncIcon"/>
                      <div className="postLiControlFuncText">{item.data_text?item.data_text.article?item.data_text.article.comment_count:null:null}</div>
                    </div>
                  </div>
                </div>
      
                <div className={isimg?"postLiDataRight":"postLiDataRight none"}>
                  <div className="postLiBanner">
                    <img src={imgurl} alt="" className="postLiBannerImg"/>
                  </div>
                </div>
  
              </div>
            </div>
          </div>
        </div>
      )
    })


    console.log(userhtml)
  }
 
  //console.log(userhtml)
  const changeokdel =()=>{
    //console.log(123)
    //console.log(dellist)

    fetch(
      "https://shl-info.cn/front/bbs/updateUserNotice/id/"+dellist.id+"/state/1?userid="+localStorage.getItem('userid'),{
        headers:{
          'token':localStorage.getItem('token') 
        }
      }
    )
    .then(res => {
      //console.log(res,dellistindex)
      //console.log('res,index')
      var arr='';
      if(postState==2){
        arr=noticelist
      }else{
        arr=postlist
      }
      arr.splice(dellistindex,1)
      if(postState==2){
        setNoticelist(arr) 
      }else{
        setPostlist(arr)
      }
      changenodel();
    })
  }
  const changenodel =()=>{
    setDellist("")
    setDellistindex("")
  }

  const closetalk = (e,item,index) =>{
    //console.log(22222)
    //console.log(e,item)
    setDellist(item)
    setDellistindex(index)
    
  }

  const addmore =() =>{
    //console.log(123123)
    changepoststate(postState,1);
  }
  const gochangeactive = (ids) =>{
    setActivetype(ids)
  }
  const copytext = () =>{
    let text = tipslist.length>0?tipslist[tipsindex]['content']:''
    copy(text)
    setShowcopylayer(true)
    
   
    setTimeout(function(){
      setShowcopylayer(false)
    },1000)
  }
  const showtiplayer = () =>{
    return  <div className="common-mask">
        <div className="common-maskbox common-maskboxplay">
            <h2 className="common-masktitle">
                站内信
                <span
                    className="common-maskclose cursor"
                    onClick={() => closeMask()}></span>
            </h2>

            <div className="common-masktextplay" onClick={()=>copytext()}>
                <p className='masktextplaytitle' dangerouslySetInnerHTML={{__html:tipslist.length>0?tipslist[tipsindex]['content']:''}}>{}</p>
               
            </div>
        </div>
    </div >
  }
  const addClick = (item) =>{
    console.log(item)
    $.ajax({
      type: "POST",
      url:
        "https://shl-info.cn/front/Insidenews/setInsideRead?userid=" +
        localStorage.getItem('userid') ,
        data: {
          "userid": localStorage.getItem('userid'),
          "insideid": item.id,
        },
      dataType: "json",
      headers:{'token':localStorage.getItem('token')},
      cache: false
    })
    .catch(error => {
      //console.log(error);
    })
    .then(res => {
      console.log(res)
    })
  }
  return (
    <div className='userNotice'>
      {showlayerstate?showtiplayer():null}
      {showcopylayer?<div className="copylayer"><div className="text">复制成功</div></div>:null}
      <Header active='6'></Header>
      <PersonimgNew type="no"></PersonimgNew>
      <div className={dellist==''?"none":"changedellayer"}>
        <div className="changedellayer-maskbox">
                <h2 className="changedellayer-masktitle">
                    {/* 是否删除 */}
                    是否改为已读
                </h2>
                <div className="changedellayer-btnlist">
                  <span onClick={()=>changeokdel()}>是</span>
                  <span className="nomargin" onClick={()=>changenodel()}>否</span>
                </div>
        </div>
      </div>
      <div className="userNoticeContentView">
        {/* <div className="headView"><div className="head">通知</div></div> */}
        <div className="person-title">
            <div className="wrap">
                <span className={activetype==3?'active':null} onClick={()=> gochangeactive(3)}>站内信</span>
                <span className={activetype==1?'active':null} onClick={()=> gochangeactive(1)}>通知</span>
                <span className={activetype==2?'active':null} onClick={()=> gochangeactive(2)} >我的发帖</span>
            </div>
        </div>
        {activetype==3?<>
        <div className="common_sysinfo">

          {tipslist.length>0?<>
            {tipslist.map((item,index)=>{
              return <div className="common_sysinfo_li" key={"index"+index} onClick={()=>{
                setShowlayerstate(true)
                setTipsindex(index)
                addClick(item)
              }} >
                <div className='sysnotice_li_title'>
                  <span className='titles'>消息{index+1}:</span>

                  <span dangerouslySetInnerHTML={{__html:item?item.content:item.content}}></span>
                  {/* {item.content} */}
                </div>
                <div className='sysnotice_li_date'>
                {item.createtime}
                </div>
              </div>
            })}
          </>:<div className="nocontent">暂无内容</div>}
          {/* <div className="common_sysinfo_li" onClick={()=>{
            setShowlayerstate(true)
          }} >
            <div className='sysnotice_li_title'>
              <span>消息1:</span>本次CT全生命周期应用关爱网络公开课程本次CT全生命周期应用关爱网络公开课程本次CT全生命周期应用关爱网络公开课程本次CT全生命周期应用关爱网络公开课程
            </div>
            <div className='sysnotice_li_date'>
                2023-09-02   12:22
            </div>
          </div>


          <div className="common_sysinfo_li" >
            <div className='sysnotice_li_title'>
              <span>消息1:</span>本次CT全生命周期应用关爱网络公开课程本次CT全生命周期应用关爱网络公开课程本次CT全生命周期应用关爱网络公开课程本次CT全生命周期应用关爱网络公开课程
            </div>
            <div className='sysnotice_li_date'>
                2023-09-02   12:22
            </div>
          </div>


          <div className="common_sysinfo_li" >
            <div className='sysnotice_li_title'>
              <span>消息1:</span>本次CT全生命周期应用关爱网络公开课程本次CT全生命周期应用关爱网络公开课程本次CT全生命周期应用关爱网络公开课程本次CT全生命周期应用关爱网络公开课程
            </div>
            <div className='sysnotice_li_date'>
                2023-09-02   12:22
            </div>
          </div> */}

        </div>
        </>:null}
        {activetype==1?<>
        <div className="userNoticeContent">
          <div className="tabs">
            <div className={classnames("tab", postState === 0 ? 'active' : '')} onClick={ () => changepoststate(0) } >回复我的</div>
            <div className={classnames("tab", postState === 1 ? 'active' : '')} onClick={ () => changepoststate(1) }>收到的赞</div>
            <div className={classnames("tab", postState === 2 ? 'active' : '')} onClick={ () => changepoststate(2) }>系统通知</div>
          </div>

          { userhtml }
          {(postlist.length==0 && (postState==0 || postState==1) || (noticelist.length==0 && postState==2 ))?<p class='nocontentnotice'>暂无通知</p>:null}
          { postLiAlert.open ? postLiAlertDom : '' }

          {/* <div className="postListNotTips">暂无帖子</div> */}
        </div>
        {morestate?<div className="postsLoad none" onClick={()=>addmore()}>
            加载更多
            <img src={require('../../static/images/icon-9.png')} alt="" className="postsLoadIcon"/>
        </div>:null}
        </>:null}

        {activetype==2?<UserpostComment></UserpostComment>:null}
      </div>

      <Footer></Footer>
    </div>
  )

}

export default UserNotice
