import React, { Component } from 'react'
import $ from 'jquery'
// 组件
import Header from '../../components/header'
import Footer from '../../components/footer'
import PersonImg from '../../components/personimg'
import PersonTitle from '../../components/persontitle'
import ExamResults from '../../components/examresults/index.jsx';//考试结果

import './index.css'

class Diexamachievement extends Component {
  constructor() {
    super()
    this.state = {
      title: "",
      showlayers: false,
      
    };
  }

  getUrlkey(url) {
    var params = {};
    var urls = url.split("?");                  //console.log('1_分割url:', urls)
    var arr = urls[1].split("&");               //console.log('2_分割urls[1]:', arr)
    for (var i = 0, l = arr.length; i < l; i++) {
      var a = arr[i].split("=");                //console.log('3_遍历 arr 并分割后赋值给a:', a[0], a[1])
      params[a[0]] = a[1];                      //console.log('4_a给params对象赋值:', params)
    }                                           //console.log('5_结果:', params)
    return params;
  }
  componentDidMount() {
    
    var urls = this.getUrlkey(window.location.href);
    
    this.classid = urls.type;
    this.di = urls.di;
    console.log(this.classid)
    this.setState({
      showlayers:true
    })
    console.log(this.di)
    
  }
  componentWillUnmount() {

  }
  
  
  render() {
    console.log(this.classid)
    console.log(this.di)
    let onlinelist = null;
    if(this.state.showlayers && this.classid>0 && this.di==1){
       onlinelist = (
        <ExamResults
              examlist={this.state.examlist}
              gotoresult={() => this.gotoresult()}
              changeexamstate={() => this.changeexamstate()}
              userinfo={this.props.userinfo}
              classid={this.classid}
              di={this.di}
              change={this.changeExams}
            ></ExamResults>
        )
    }
    
    return (
      <div className="personpage">
        <Header active="4"></Header>
        <PersonImg type="no"></PersonImg>
        <PersonTitle title={this.state.title}></PersonTitle>
        <div className="wrap diexam-qalist">
           
            {onlinelist}
           
        </div>
        <Footer></Footer>
      </div>
    );
  }
}

export default Diexamachievement
