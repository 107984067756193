import React, { Component } from 'react'
// 组件
import Header from '../../components/header'
import Footer from '../../components/footer'
import SelectBox from '../../components/selectbox'
import PostDraft from '../../components/postDraft';
import PostRelease from '../../components/postRelease';

import './index.css'

class HomePlaying extends Component {
  constructor() {
    super()
    this.changeSelect = this.changeSelect.bind(this)
    this.changetalk = this.changetalk.bind(this)
    this.preview = this.preview.bind(this)
    this.deltalk = this.deltalk.bind(this)
    
    this.state = {
      ids: '',
    };
  }

  static defaultProps = {
    selectlist: [
      {
        id: 1,
        type: 'release',
        title: '发帖',
        icon: require('../../static/images/playing_icon_09.png'),
        icon_act: require('../../static/images/playing_icon_09_act.png')
      },
      {
        id: 0,
        type: 'draft',
        title: '草稿箱',
        icon: require('../../static/images/playing_icon_10.png'),
        icon_act: require('../../static/images/playing_icon_10_act.png')
      }
    ]
  }
  // 切换导航
  changeSelect(id) {
    var keywords = '';
    if (id == 'release') {
      keywords = '发帖';
      this.setState({
        ids:""
      })
    } else {
      keywords = '草稿箱';
    }
    window.addreadlogs(keywords)
    this.props.change_active_two(id)
  }
  deltalk(ids){
    console.log(ids)
  }

  changetalk(ids){
    this.setState({
      ids:ids
    })
    this.props.change_active_two('release')
  }

  preview(ids){
    this.setState({
      ids:ids
    })

    fetch(
      "https://shl-info.cn/front/bbsdata/addArticleLog?userid="+localStorage.getItem('userid')+"&articleid="+ids
    )
    .then(data => {
      return data.json();
    })
    .then(res => {
      
    })
    this.props.history.push('/postDetail_preview?id='+ids)
  }
  
   getUrlkey =(url)=> {
    var params = {};
    var urls = url.split("?");                  //console.log('1_分割url:', urls)
    var arr = urls[1].split("&");               //console.log('2_分割urls[1]:', arr)
    for (var i = 0, l = arr.length; i < l; i++) {
        var a = arr[i].split("=");                //console.log('3_遍历 arr 并分割后赋值给a:', a[0], a[1])
        params[a[0]] = a[1];                      //console.log('4_a给params对象赋值:', params)
    }                                           //console.log('5_结果:', params)
    return params;
    }
  componentDidMount() {

    if(!localStorage.getItem('userid')){
      this.props.history.push('/homedoctorinteraction');
     
    }
    if (this.props.active_two !== 'release') {
      this.props.change_active_two('release')
    }
    let ids='';
    if(window.location.href.indexOf('?')>=0){
      let url=this.getUrlkey(window.location.href);
          ids=url.id;
    }
    if(ids){
      this.setState({
        ids:ids
      })
    }
    console.log(ids)

    console.log(12312312312312312312312312)


    // this.props.change_active_two("draft")
  }
  componentWillUnmount() {

  }
  render() {
    // 切换导航
    let selectlist = this.props.selectlist.map((item) => {
      return (
        <SelectBox
          key={item.id}
          _item={item}
          active={this.props.active_two}
          change={this.changeSelect}>

        </SelectBox>
      )
    })
    console.log(this.state.ids)
    // 内容更换
    let container = null;
    if (this.props.active_two === 'release') {
      //console.log(1111)
      container = (<PostRelease changeSelect={this.changeSelect} ids={this.state.ids} history={this.props.history} ></PostRelease>)
    } else if (this.props.active_two === 'draft') {
      //console.log(2222)
      container = (<PostDraft changetalk={this.changetalk} preview={this.preview}   ></PostDraft>)
    }
    return (
      <div className='playingpage homedoctorinteraction'>
        <Header active='6'></Header>
        <div className='page-navselect'>
          <div className="wrap overflow">
            <ul className="navselect fl">
              {selectlist}
            </ul>
            <div className="select-contain fr">
              {container}
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    )
  }
}

export default HomePlaying
