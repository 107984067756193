import React, { useState, useEffect } from 'react'
import './index.css'
import classnames from 'classnames';


import ReconnectingWebSocket from 'reconnecting-websocket'
import MaskBox from '../../../../components/maskbox'



let socket = '';
let socketstate = false;
const IndexComponent = (props) => {

  // let [grouplist, setList] = useState([])
  useEffect(() => {
    console.log("useEffect")
    fetch(
      "https://shl-info.cn/front/groupchat/getChatForAddCourse?userid=" +
      localStorage.getItem('userid') +
      "&operate=1&groupname=" ,{
        headers:{
          'token':localStorage.getItem('token') 
        }
      }
    )
    .then(data => {
      return data.json();
    })
    .then(res => {
      console.log(res)
      if(res.code==2000){
        setList(res.data)
        // this.setState({
        //   grouplist:res.data
        // })
      }
    });
    getsocket();
  },[])



  //聊天室组件
  let getsocket = () => {
    socket = new ReconnectingWebSocket(
      "wss://shl-info.cn/webwsthi/"
    );
    socket.onopen = () => {
      //console.log("open");
      socket.send(
        JSON.stringify({
          messagetype: "linkgroup",
          user_id: localStorage.getItem('userid'),
          type_id: '1',
          username: localStorage.getItem("names"),
          groupcode: 'coursedetail',
          avatar_url: localStorage.getItem("headimg"),
        })
      );
    };
    socket.onmessage = message => {
      //console.log('message')
      let newdata = JSON.parse(message.data);

      if (newdata.type === "success") {
        console.log("success",newdata)
        socketstate=true;
        // changesocket=null
        // clearTimeout(changesocket)
      }
      
    };
    socket.onclose = () => {
      //console.log("close");
    };
  };



  let [list, setList] = useState([
    // {
    //   title: '北京第一群',
    //   subtitle: '北京协和医院',
    //   select: false,
    //   child: [
    //     { title: '抗疫系列公开课' },
    //     { title: 'CT系列公开课' },
    //     { title: 'MR系列公开课' },
    //   ]
    // },
    // {
    //   title: '北京第二群',
    //   subtitle: '北京协和医院',
    //   select: false,
    //   child: [
    //     { title: '抗疫系列公开课' },
    //     { title: 'CT系列公开课' },
    //     { title: 'MR系列公开课' },
    //   ]
    // },
    // {
    //   title: '北京第三群',
    //   subtitle: '北京协和医院',
    //   select: false,
    //   child: [
    //     { title: '抗疫系列公开课' },
    //     { title: 'CT系列公开课' },
    //     { title: 'MR系列公开课' },
    //   ]
    // }
  ])

  let selectItemFunc = (index,item) => {
    console.log(index,item)
    // setChatcode(item.groupcode)
    list[index].select = !list[index].select
    setList([...list])
    // fetch(
    //   "https://shl-info.cn/front/groupchat/getPackListByGroupId?userid=" +
    //   localStorage.getItem('userid') +
    //   "&chatcode="+item.groupcode ,{
    //     headers:{
    //       'token':localStorage.getItem('token') 
    //     }
    //   }
    // )
    // .then(data => {
    //   return data.json();
    // })
    // .then(res => {
    //   console.log(res)
    //   if(res.code==2000){
    //     list[index].child=res.data;
    //   }else{
    //     list[index].child=[];
    //   }
    //   setList([...list])
    // });
    // setList([...list])
  }


  let [chatcode, setChatcode] = useState('')

  let [tipstitle, setTipstitle] = useState('')
  let [tipstext, setTipstext] = useState('')
  let [maskboxstate, setMaskboxstate] = useState(false)
  

  let changethiscode = (item,groupcode)=>{
    console.log(item)
    console.log(item.packcode,chatcode)

    fetch(
      "https://shl-info.cn/front/groupchat/addCourseForPack",{
        method: 'POST',
        body: JSON.stringify({
          "chatcode": groupcode,
          "userid": localStorage.getItem('userid'),
          "courseid": props.classid,
          "packid": item.packcode,
        }),
        headers:{
          'content-type': 'application/json',
          'token':localStorage.getItem('token')
        },
        header: {
          'content-type': 'application/json',
          'token':localStorage.getItem('token')
        },
      }
    )
    .then(data => {
      return data.json();
    })
    .then(res => {
      console.log(res)
      setMaskboxstate(true)
      if(res.code==2000){
        setTipstitle('添加成功');
        setTipstext('课程已经添加到课程包内');
        
      }else{
        setTipstitle('添加失败');
        setTipstext(res.msg);
      }
    })
  }
  
  let groupSelectAlertLi = () => {
    return list.map((item, index) => {
      let groupSelectAlertLiBody='';
      if(item.child){
        let groupSelectAlertLiItem = item.child.map((currentItem, currentIndex) => {
          return (
            <div className="groupSelectAlertLiItem" key={currentIndex} onClick={()=>changethiscode(currentItem,item.groupcode)}>{ currentItem.name }</div>
          )
        })
        groupSelectAlertLiBody = (
          <div className="groupSelectAlertLiBody">
            { groupSelectAlertLiItem }
          </div>
        )
      }
      
      return (
        <div className={classnames("groupSelectAlertLi", item.select && 'active')} key={ index }>
          <div className={item.select?"groupSelectAlertLiHeadtalk active":"groupSelectAlertLiHeadtalk"} onClick={() => selectItemFunc(index,item)}>
            <div className="groupSelectAlertLiHeadContent">
              <div className="groupSelectAlertLiTitle">{ item.name }</div>
              <div className="groupSelectAlertLiSubTitle">{ item.hospital }</div>
            </div>
          </div>
          { item.select && groupSelectAlertLiBody }
        </div>
      )
    })
  }


  let closeMaskuserstate = () => {
    setMaskboxstate(false)
  }



  let gosendmessagebycourse = (data,groupid) =>{
    console.log(data)
    console.log(groupid)

    let arr = {
      messagetype: "messagegroup",
      user_id: localStorage.getItem('userid'),
      type_id: "1", //类型 :自己
      username: localStorage.getItem('names'), // 姓名
      text: JSON.stringify(data),
      groupcode: groupid,
      type:4,
      avatar_url: localStorage.getItem('headimg')
    }
    socket.send(JSON.stringify(arr));
  }
  

  let sendtogroup = ()=>{
    console.log(3333)
    let senddate = {
      coursetitle: props.information.title,
      headimg: props.information.src,
      id: props.classid,
      iscs: props.iscs,
      teacher: props.information.teacher
    }
    let issend=false;
    for(var i=0;i<list.length;i++){
      if(list[i]['select']){

        gosendmessagebycourse(senddate,list[i]['groupcode'])
        issend=true
      }
    }
    if(issend){
      props.closelayer()
    }

    console.log(list)
    // console.log(props.information)
    // console.log(props.classid)
    // console.log(props.iscs)
  }
  let maskbox = null;
    if (maskboxstate) {
      maskbox = (
        <MaskBox
          info={{
            title: tipstitle,
            text: tipstext,
          }}
          closeMask={closeMaskuserstate}>
        </MaskBox>
      )
    }

    let goaddgroup =()=>{
      props.history.push('/groupManipulat?isadd=1');
    }

  return (
    <div className="groupSelect">
      {maskbox}
        <div className="groupSelectAlert">
          <div className="groupSelectAlertHead">
            <div className="groupSelectAlertTitle">选择群组</div>
            <img src={require('../../../../static/images/icon16.png')} alt="" className="groupSelectAlertClose" onClick={props.closelayer} />
          </div>
          <div className="groupSelectAlertBody scrollbar">
            { groupSelectAlertLi() }

            <div className="groupSelectSendButton">
             <div className="groupSelectSendButtontext" onClick={()=>sendtogroup()}>发送</div>
            </div>
          </div>
        </div>
    </div>
  )
};

export default IndexComponent;
