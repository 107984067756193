import React, { useState, useEffect } from 'react'

import utils from '../../utils/index'

// 组件
import Header from '../../components/header'
import Footer from '../../components/footer'
import GroupChat from './components/GroupChat'
import GroupData from './components/GroupData'
import GroupCourse from './components/GroupCourse'
import classnames from 'classnames'


import { encrypt, decrypt } from '../../utils/crypto'
import './index.css'


const UserPost = (props) => {
  let userid = localStorage.getItem('userid') 
  let token = localStorage.getItem('token')

  // group: 群组  course: 课程
    let [navSelect, setNavSelect] = useState('group')
// let [navSelect, setNavSelect] = useState('course')
  
let [cousreinfo, setCousreinfo] = useState({})
  
let [isexam, setIsexam] = useState(0)
  
  let [groupinfo, setGroupinfo] = useState({
    hospital:'',
    name:'',
    id:'',
  })
  //学员列表
  let [groupuserinfo_user, setGroupuserinfo_user] = useState([])
  let [groupuserinfo_admin, setGroupuserinfo_admin] = useState([])



  //学习进度
  let [studylist, setStudylist] = useState([])
  //分类ID
  let [groupid, setGroupid] = useState('')
  let [cancreate, setCancreate] = useState(false)
  let [usertypes, setUsertypes] = useState(0)
  

  useEffect(() => {
    console.log(22222)
    console.log('usertypes',usertypes)
  }, [usertypes])
  useEffect(() => {
    let query = utils.getUrlParams()
    if(query.groupids){
      setGroupid(query.groupids)
      fetch(
        "https://shl-info.cn/front/Groupchat/getGroupInfoById?userid=" +
        localStorage.getItem('userid') +
        "&chatcode=" +
        query.groupids ,{
          headers:{
            'token':localStorage.getItem('token') 
          }
        }
      )
      .then(data => {
        return data.json();
      })
      .then(res => {
        console.log(res)
        if(res.code==2000){
          setIsexam(res.data.issurver)
          setGroupinfo(res.data.groupinfo)
          let groupuserinfo_admin=[];
          let groupuserinfo_user=[];
          for(var i=0;i<res.data.groupuserinfo.length;i++){
            if(res.data.groupuserinfo[i]['type']!=3){
              groupuserinfo_admin.push(res.data.groupuserinfo[i])
            }else{
              groupuserinfo_user.push(res.data.groupuserinfo[i])
            }
          }
          setGroupuserinfo_user(groupuserinfo_user)
          setGroupuserinfo_admin(groupuserinfo_admin)
        }
      });

      fetch(
        "https://shl-info.cn/front/Groupchat/getGroupStudyList?userid=" +localStorage.getItem('userid') +
        "&chatcode=" +
        query.groupids ,{
          headers:{
            'token':localStorage.getItem('token') 
          }
        }
      )
        .then(data => {
          return data.json();
        })
        .then(res => {
          console.log(res)
          if(res.code==2000){
            setStudylist(res.data)
          //  this.setState({
          //   studylist:
          //  })
          }

        });

      let userid = localStorage.getItem('userid')
      let token = localStorage.getItem('token')
    
      if (userid && token) {
        // 验证是否过期
        fetch(
          "https://shl-info.cn/front/user/checkuser?userid=" +
          localStorage.getItem('userid') ,{
            headers:{
              'token':localStorage.getItem('token') 
            }
          }
        )
          .then(data => {
            return data.json();
          })
          .then(res => {
            console.log(res)
            if(res.list){
              localStorage.setItem('headimg', res.pic)
              localStorage.setItem('names', decrypt(res.name))

              if(res.list.roletype==1 || res.list.roletype==2){
                setCancreate(true)
              }
              
            }
            
          });
      }else{
        //props.history.push("/home")
        props.history.push("/loginlist?isgroup=1")
      }

      //获取用户在群组的身份
      fetch(
        "https://shl-info.cn/front/Groupchat/getUserTypeById?userid=" +
        localStorage.getItem('userid') +
        "&chatcode=" +
        query.groupids ,{
          headers:{
            'token':localStorage.getItem('token') 
          }
        }
      )
        .then(data => {
          return data.json();
        })
        .then(res => {
          console.log(res)
          if(res.code==2000){
            // this.setState({
            //   usertypes:res.data
            // })
            setUsertypes(res.data)
            if(!res.data){
              //props.history.push('/home');
              window.location.href="/website/home"
            }
            localStorage.setItem('grouptypes',res.data)

          }else{
            // props.history.push('/website/home')
            window.location.href="/website/home"
          }
  
        });
    


    }else{
      console.log(2323)
    }
  }, [])

  let changecourse = (arr) => {
   // console.log(arr)
   // setCousreinfo(JSON.parse(JSON.stringify(arr)))
  }


  let changenotice = () =>{
    let query = utils.getUrlParams()
    if(query.groupids){
      setGroupid(query.groupids)
      fetch(
        "https://shl-info.cn/front/Groupchat/getGroupInfoById?userid=" +
        localStorage.getItem('userid') +
        "&chatcode=" +
        query.groupids ,{
          headers:{
            'token':localStorage.getItem('token') 
          }
        }
      )
      .then(data => {
        return data.json();
      })
      .then(res => {
        console.log(res)
        if(res.code==2000){
          setGroupinfo(res.data.groupinfo)
        }
      });
    }
  }
  // let [onlinelist, setOnlinelist] = useState([])
  
  return (
    <div className='groupDetailPage'>
      <Header active='4'></Header>
      
      <div className="pageContent">
        <div className="pageHead">
          <div className="content">
            <div className="detail">
              <div className="title text-overflow">{groupinfo.name}</div>
              <div className="subTitle text-overflow">{groupinfo.hospital}</div>
            </div>
            <div className="nav">
              <div className={classnames("navItem", navSelect === 'group' && 'active')} onClick={() => setNavSelect('group')}>群聊</div>
              <div className={classnames("navItem", navSelect === 'course' && 'active')} onClick={() => setNavSelect('course')}>课程</div>
            </div>
          </div>
        </div>

        <div className="pageBody">
          <div className="content"> 
            <div className="pageBodyLeft">
              { navSelect === 'group' ? <GroupChat history={props.history}  groupid={groupid} groupinfo={groupinfo}    usertypes={usertypes} ></GroupChat> : <GroupCourse   changecourse={changecourse} history={props.history}  usertypes={usertypes} groupid={groupid}></GroupCourse> }
            </div>
            <div className="pageBodyRight">
              <GroupData usertypes={usertypes} isexam={isexam} cancreate={cancreate} groupid={groupid} groupinfo={groupinfo} studylist={studylist} groupuserinfo_user={groupuserinfo_user} changenotice={changenotice}  groupuserinfo_admin={groupuserinfo_admin}></GroupData>
            </div>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </div>
  )
};

const Demo = ()=>{
  return <>1111</>
}

export default UserPost;
