import React, { Component } from 'react'

import $ from 'jquery';
// 组件
import Header from '../../components/header'
import Footer from '../../components/footer'

import Banner from '../../components/banner'
import SelectBox from '../../components/selectbox'
import Shopclasslist from '../../components/shopclasslist'

import Shopservicelist from './shopservicelist'

import MaskBox from '../../components/maskbox'
// import Scorelayer from '../../components/scorelayer'

import FileType from '../../components/filetype'
import Swiper from 'swiper'




import Scorelayer from '../../components/scorelayer2023';

import './index.css'

class Shopmallindex extends Component {
  constructor() {
    super()

    

   this.changeSelect = this.changeSelect.bind(this)
    this.state = {
      swiper: null,
      active:'home',
      // active:'my',
      myindex:0,
      bannerlist:[],
      serieslist:[],
      csinfoold:[{
        id: 999,
        name: '智能模拟云1小时体验',
        bannerurl: "https://shlinfo.oss-cn-shanghai.aliyuncs.com/static/%E6%99%BA%E8%83%BD%E6%A8%A1%E6%8B%9F%E4%BA%911%E5%B0%8F%E6%97%B6%E4%BD%93%E9%AA%8C.png",
        money:275,
      },],
      csinfo:[],
      mycourselist:[],
      buycourselist:[],
      userinfo:{
        hospital: '',
        hotillevel: '',
        hotiltype: '',
        name: '',
        office: '',
        offices: '',
        phone: '',
        email:'',
        province:'',
        departments:'',
      }
      
    }
  }

  static defaultProps = {
    selectlist: [
      {
        id: 0,
        type: 'home',
        title: '首页',
        icon: require('../../static/images/shopmall/shopmall-icon1.png'),
        icon_act: require('../../static/images/shopmall/shopmall-icon1-active.png')
      },
      {
        id: 1,
        type: 'my',
        title: '我的',
        icon: require('../../static/images/shopmall/shopmall-icon2.png'),
        icon_act: require('../../static/images/shopmall/shopmall-icon2-active.png')
      }
    ]
  }
  
  
  componentDidMount() {

    let that = this
    let userid = localStorage.getItem('userid')
    let token = localStorage.getItem('token')
    let thisuserid = userid;
    if (userid && token) {
      // 验证是否过期
      $.ajax({
        type: "GET",
        url: "https://shl-info.cn/front/user/checkuser?userid=" +
          userid ,
        dataType: "json",
        headers:{'token':token},
        success: function (res) {
          console.log(res)
          if (res.status === 1) {
            localStorage.setItem('usertype', res.list.usertype)
            
            localStorage.setItem('iswaitype', res.list.type)
            if (res.list.id != '' && res.list.type === 0) {
              that.setState({
                isoutuser: false,
              })
            }
            //isoutuser
          }else{
            localStorage.setItem('refurl', window.location.href);
            this.props.history.push('./loginlist')
          }
        }
      });
    }else{
      localStorage.setItem('refurl', window.location.href);
      this.props.history.push('./loginlist')
    }
    
    this.swiperInit();
    this.getbanner();
    this.getcouselist();
    this.getmycourse();
    this.getorderlist();
    this.getuserinfo();
  }
  getuserinfo =()=>{
    let userid=localStorage.getItem('userid');
    let token=localStorage.getItem('token');

    if (userid && token) {
      // 验证是否过期
      fetch(
        "https://shl-info.cn/front/eshop/getUserInfo?userid=" +
        userid ,{
          headers:{
            'token':token
          }
        }
      )
      .then(data => {
        return data.json();
      })
      .then(res => {
        //console.log(res)
        if (res.code == 2000) {
          this.setState({
            userinfo:{
              hospital: res.data.hospital,
              hotillevel: res.data.hotillevel,
              hotiltype: res.data.hotiltype,
              name: res.data.name,
              office: res.data.office,
              offices: res.data.offices,
              phone: res.data.phone,
              email:res.data.email,
              province:res.data.province,
              departments:res.data.departments,
            },
          })
        } else {
        //  this.props.history.push("/shopmalllogin");
        }
      });
    } else {
      // this.props.history.push("/shopmalllogin");
    }
  }
  getorderlist =()=>{
    fetch(
      'https://shl-info.cn/front/eshop/eshopOrderList?userid='+localStorage.getItem('userid') , {
      method: 'GET',  
      headers:{
        'token':localStorage.getItem('token')
      },
      header: {
        'token':localStorage.getItem('token')
      },
    })
    .then(data => {
      return data.json();
    })
    .catch(error => {
      
    })
    .then(res => {
      console.log(res.data)
      if(res.data){
        this.setState({
          buycourselist:res.data
        })
      }
    })
  }
  getmycourse = ()=>{
    fetch(
      'https://shl-info.cn/front/eshop/eshopCourseList?userid='+localStorage.getItem('userid'), {
      method: 'GET',  
      headers:{
        'token':localStorage.getItem('token')
      },
      header: {
        'token':localStorage.getItem('token')
      },
    })
    .then(data => {
      return data.json();
    })
    .catch(error => {
      
    })
    .then(res => {
      console.log(res)
      this.setState({
        mycourselist:res.data
      })
    })
    
  }


  getbanner(){
    fetch(
      'https://shl-info.cn/front/eshop/getShopCourse' , {
      method: 'GET',
    })
    .then(data => {
      return data.json();
    })
    .catch(error => {
      
    })
    .then(res => {
      console.log(res)
      this.setState({
        bannerlist:res.data.bannerlist,
        serieslist:res.data.serieslist,
      },()=>{
        console.log(1231230)
      })
    })
  }
  swiperInit() {
    let that = this;
    that.setState({
      swiper: new Swiper('.swiper-container', {
        observer: true,//修改swiper自己或子元素时，自动初始化swiper 
        observeParents: false,//修改swiper的父元素时，自动初始化swiper 
        onSlideChangeEnd: function () {
          that.state.swiper.update();
        }
      })
    })
  }
  componentWillUnmount() {
  }
  changeSelect (id) {
    var keywords = '';
    if (id == 'home') {
      keywords = '首页';
    } else {
      keywords = '我的';
    }
    window.addreadlogs(keywords)
    this.setState({
      active:id,
    })
  }

  getcouselist(){

    fetch(
      "https://shl-info.cn/front/Cscourse/getCourseList?userid=" +
      localStorage.getItem('userid')+"&ishopmaillindex=1" ,{
        headers:{
          'token':localStorage.getItem('token') 
        }
      }
    )
    .then(data => {
      return data.json();
    })
    .then(res => {
      this.setState({
        csinfo:res.data[0]['list']
      })
    })

   
  }
  changemyindex = (index)=>{
    this.setState({
      myindex:index,
    })
  }
  gotodetail = (item)=>{
    console.log(item)
    this.props.history.push('/homeback?cscoursestate=1&changeid='+item.seriesid);
  }
  gohome = () =>{
    this.props.history.push('/homeback?cscoursestate=1');
  }
  godetail = (item) =>{
    console.log(item.id)
    if(item.id==999){
      this.props.history.push('/shopmalldetail?typeids='+item.id)
    }else{
      console.log(item)
      if(item.coursebuy==1){
        this.props.history.push('/homeback?cscoursestate=1&changeid='+item.id);
      }else{
        this.props.history.push('/shopmalldetail?typeids='+item.id)
      }
    }
    // this.props.history.push('/id')
  }

  render() {
    // 切换导航
    let selectlist = this.props.selectlist.map((item) => {
      return (
        <SelectBox
          key={item.id}
          _item={item}
          active={this.state.active}
          change={this.changeSelect}>

        </SelectBox>
      )
    })
   

    let pagenums = 0;
    if (this.state.swiper) {
      pagenums = this.state.swiper.activeIndex + 1;
    }
    let container = null;
    if (this.state.csinfo.length>0  ) {
      container = this.state.csinfo.map((item,index) => {
        return (
          <Shopclasslist godetail={this.godetail} key={'revcsinfo' + index} _item={item}></Shopclasslist>
        )
      })
    }

    
    let containerold = null;
    if (this.state.csinfoold.length>0  ) {
      containerold = this.state.csinfoold.map((item,index) => {
        return (
          <Shopclasslist godetail={this.godetail}  key={'revcsinfoold' + index} _item={item}></Shopclasslist>
        )
      })
    }
    let homeinfo  = ()=>{
      return <div className="select-contain fr overflow">
      <div>
        <div className="shopmallbanner">
          <div className="swiper-container">
            <div className="swiper-wrapper">
              <div className="swiper-slide" onClick={()=>this.gohome()}><img src="https://shl-info.oss-cn-shanghai.aliyuncs.com/images/banner0723.jpg?1"></img></div>
              {/* <div className="swiper-slide" onClick={()=>this.gohome()}><img src={require('../../static/images/shopmall/shopmall-banner.png')}></img></div> */}
            </div>
          </div>
          <div className="shopmallbanner_num"><span>{pagenums}</span>/1</div>
        </div>
        <div className="shopmall-container">
          {containerold}
          {container}
        </div>
      </div>
     
    </div>
    }
    let mycourselistinfo = null;
    if (this.state.csinfoold.length>0  ) {
      mycourselistinfo = this.state.mycourselist.map((item,index) => {
        return (
          <Shopservicelist gotodetail={this.gotodetail} key={'revcsinfoold' + index} _item={item}></Shopservicelist>
        )
      })
    }
    let myorderinfo = (item) =>{
      return <div className="myodreinfo">
        <div className="myorderinfo_title">课程系列名称：{item.seriestitle}</div>
          <div className="myorderinfo_time">购买时间：{item.payed_at}</div>
      </div>
    }
    let myorder = ()=>{
      let info = this.state.buycourselist.map((item,index)=>{
        return myorderinfo(item)
      })
      return info
    }
    
    let myinfolist = ()=>{
      return <div className="myinfolist">
        {this.state.userinfo['name']?<div className="myuserinfo">
          <div className="myuserinfo_title"><span className="lefttitle">昵称：</span>{this.state.userinfo['name']}</div>
        </div>:null}
          {this.state.userinfo['phone']?<div className="myuserinfo">
            <div className="myuserinfo_title"><span className="lefttitle">手机号：</span>{this.state.userinfo['phone']}</div>
          </div>:null}
          {this.state.userinfo['email']?<div className="myuserinfo">
            <div className="myuserinfo_title"><span className="lefttitle">邮箱：</span>{this.state.userinfo['email']}</div>
          </div>:null}
          {this.state.userinfo['hospital']?<div className="myuserinfo">
            <div className="myuserinfo_title"><span className="lefttitle">所属单位：</span>{this.state.userinfo['hospital']}</div>
          </div>:null}
          {this.state.userinfo['hotiltype']?<div className="myuserinfo">
            <div className="myuserinfo_title"><span className="lefttitle">单位类别：</span>{this.state.userinfo['hotiltype']}</div>
          </div>:null}
          {this.state.userinfo['offices']?<div className="myuserinfo">
            <div className="myuserinfo_title"><span className="lefttitle">科室：</span>{this.state.userinfo['offices']}</div>
          </div>:null}
          {this.state.userinfo['office']?<div className="myuserinfo">
            <div className="myuserinfo_title"><span className="lefttitle">职务：</span>{this.state.userinfo['office']}</div>
          </div>:null}
      </div>
    }


    let myinfo  = ()=>{
      return <div className="select-contain fr overflow">
      <div>
        <div className="btnlistinfo">
          <div onClick={()=>this.changemyindex(0)} className={this.state.myindex ==0?"btnlist_li active":"btnlist_li"}>我的课表</div>
          <div onClick={()=>this.changemyindex(1)}  className={this.state.myindex ==1?"btnlist_li active":"btnlist_li"}>我的订单</div>
          <div onClick={()=>this.changemyindex(2)}  className={this.state.myindex ==2?"btnlist_li active":"btnlist_li"}>个人信息</div>
        </div>
        <div className="myinfobox">
          {this.state.myindex ==0?mycourselistinfo:this.state.myindex ==1?myorder():myinfolist()}
        </div>
        {/* <div className="shopmall-container">
          {containerold}
          {container}
        </div> */}
      </div>
     
    </div>
    }


    return (
      <div className='shopmall'>

        

        <Header active='3'></Header>
       
        <div className='page-navselect'>
          <div className="wrap overflow">
            <ul className="navselect fl">
              {selectlist}
            </ul>
            {this.state.active=='home'?homeinfo():myinfo()}
          </div>
        </div>
        <Footer></Footer>
      </div>
    )
  }
}

export default Shopmallindex
