import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import SearchPage from './index.bundle'

const mapStateToProps = (state) => {
  return {
    active: state.active,
    labellist: state.common_labellist,
    common_search: state.common_search,
    userinfo: state.userinfo
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SearchPage)
);
