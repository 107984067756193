import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import Iscroll from 'iscroll/build/iscroll-probe';
import axios from 'axios';
import PostItem from '../postItem';
import $ from 'jquery'


import './index.css'
function PersonImg(props) {
    let userid = localStorage.getItem('userid'), token = localStorage.getItem('token');
    let postsPattern = [{ name: '最新帖子', id: 'id' }, { name: '最热帖子', id: 'hits' }]

    let [tabSelect, setTabSelect] = useState('')
    let [patternSelectIndex, setPatternSelectIndex] = useState(0)
    let [patternSelectPanel, setPatternSelectPanel] = useState(0)
    let [postsList, setPostsList] = useState([])

    let [postsTab, setPostsTab] = useState([])
    let [userpage, setPages] = useState(1)
    let [morestate, setMorestate] = useState(true)


    useEffect(() => {
        init()
    }, [])

    useEffect(() => {
        getPostsList()
    }, [patternSelectIndex, tabSelect])


    let init = () => {
        getPostsList(userpage)
        pageInit()
    }

    // 获取帖子列表
    let getPostsList = async (pagenums) => {
        console.log(tabSelect)
        let res = await axios({
            method: 'get',
            headers: {
                "token": token
            },
            url: 'https://shl-info.cn/front/bbs/articleList',
            params: {
                user_id: userid,
                state: 0,
                topic_id: '',
                title: '',
                page: pagenums,
                keywords_id: tabSelect,
                order: postsPattern[patternSelectIndex].id
            }
        })
        let data = res.data
        console.log(res)
        setPages(data.current_page)
        if (pagenums > 1) {
            let olddata = postsList;
            olddata.push.apply(olddata, data.data);
            setPostsList(olddata)
            if (data.data.length < 10) {
                setMorestate(false)
            }
        } else {
            setPostsList(data.data)
        }
        setPostsTab(data.keywords)
    }


    let pageInit = () => {
        setTimeout(() => {
            initIscroll()
        }, 1000)
    }
    let initIscroll = () => {
        // document.
        let dom = $('.postsScroll');
        //let dom = document.getElementsByTagName('postsScroll')
        console.log(dom)
        if (dom.length === 0) return;
        new Iscroll('.postsScroll', {
            mouseWheel: true,
            scrollbars: true,
            scrollX: true,
            scrollY: false,
            scrollbars: false,
        })
    }

    let selectPattern = (index) => {
        setPatternSelectPanel(0)
        setPatternSelectIndex(index)
        setMorestate(true)
    }


    // 帖子列表
    let postItemDom = () => {
        if (postsList.length === 0) {
            return <div className="postListNotTips">没有帖子哦~</div>
        }
        return postsList.map((item, index) => {
            return <PostItem data={item} key={item.id} isgodetail={true} history={props.history}></PostItem>
        })
    }

    // tab   getPostsList()
    let postsScrollItemDom = postsTab.map((item, index) => {
        return (
            <div className={classnames("postsScrollItem", tabSelect === item.id ? 'active' : '')} key={index} onClick={() => { setTabSelect(item.id); setMorestate(true) }} >{item.title}</div>
        )
    })

    // 类型选择
    let postPatternSelectDom = () => {
        let postsPatternDom = postsPattern.map((item, index) => {
            return (
                <div className={classnames("postPatternSelectItem", patternSelectIndex === index ? 'active' : '')} key={index} onClick={() => selectPattern(index)} >
                    {item.name}
                </div>
            )
        })
        return (
            <div className="postPatternSelect">
                { postsPatternDom}
            </div>
        )
    }
    const gocreatepost = () => {
        console.log(props)
        props.history.push('/homeDoctorReleasePost')
    }
    const addmore = () => {
        console.log(userpage)

        let pagesnum = userpage
        pagesnum++;
        getPostsList(pagesnum)
    }

    return (
        <section className="postlistView">
            <div className="postsScroll">
                <div className="postsScrollList" >
                    <div className={classnames("postsScrollItem", tabSelect == '' ? 'active' : '')} onClick={() => { setTabSelect(""); }} >全部</div>
                    {postsScrollItemDom}
                </div>
            </div>
            <div className="postsContent">
                <div className="postsHead">
                    <div className="postsHeadTitle" onClick={() => setPatternSelectPanel(1)}>
                        {postsPattern[patternSelectIndex].name}
                        <img src={require('../../static/images/icon-9.png')} alt="" className="postsHeadTitleIcon" />
                    </div>

                    {localStorage.getItem('userid') ? <div className="postsHeadButton" onClick={() => gocreatepost()}>
                        <img src={require('../../static/images/icon-10.png')} alt="" className="postsHeadButtonIcon" />
                        发帖
                    </div> : null}
                    {patternSelectPanel ? postPatternSelectDom() : ''}
                </div>
                <div className="postsBody">
                    {postItemDom()}
                    {morestate && postsList.length >= 10 ? <div className="postsLoad" onClick={() => addmore()}>
                        点击加载更多
                        <img src={require('../../static/images/icon-9.png')} alt="" className="postsLoadIcon" />
                    </div> : null}

                </div>
            </div>
        </section>
    )
}
// mapStateToProps：将state映射到组件的props中
const mapStateToProps = (state) => {
    return {
        userinfo: state.userinfo
    }
}

// mapDispatchToProps：将dispatch映射到组件的props中
const mapDispatchToProps = (dispatch) => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonImg)
