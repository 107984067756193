import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import $ from 'jquery'
// 组件
import Header from '../../components/header'
import Footer from '../../components/footer'
import PersonImg from '../../components/personimg'
import PersonTitle from '../../components/persontitle'
import HistoryList from '../../components/historylist'
import SelectBox from '../../components/selectbox'

import Maskbox from "../../components/maskbox";

import './index.css'

class Integralmall extends Component {
  constructor() {
    super()

    // this.gourls = this.gourls.bind(this)
    this.state = {
      active: '0',
      mallnums: 1,
      scorcelist: [],
      signlevel: '',
      signnums: '',
      todaysign: true,
      signallscore: '',
      signlevelscore: '',
      enddate: '',
      usertoplist: {},
      candolist: [],
      usernolayer: false,
      showdolist: [
        {
          type1: {
            show: true,
            nums: 0,
          }
        },
        {
          type2: {
            show: true,
            nums: 0,
          }
        },
        {
          type3: {
            show: true,
            nums: 0,
          }
        },
        {
          type4: {
            show: true,
            nums: 0,
          }
        },
        {
          type5: {
            show: true,
            nums: 0,
          }
        },
        {
          type6: {
            show: true,
            nums: 0,
          }
        },
        {
          type7: {
            show: true,
            nums: 0,
          }
        },
        {
          type8: {
            show: true,
            nums: 0,
          }
        },
        {
          type9: {
            show: true,
            nums: 0,
          }
        },
        {
          type10: {
            show: true,
            nums: 0,
          }
        },
        {
          type11: {
            show: true,
            nums: 0,
          }
        },
        {
          type12: {
            show: true,
            nums: 0,
          }
        },
        {
          type13: {
            show: true,
            nums: 0,
          }
        },
        {
          type14: {
            show: true,
            nums: 0,
          }
        },
        {
          type15: {
            show: true,
            nums: 0,
          }
        }
      ]

    }
  }

  static defaultProps = {
    selectlist: [
      {
        id: 0,
        type: '0',
        title: '个人积分'
      },
      {
        id: 1,
        type: '1',
        title: '积分商城'
      },
    ]
  }

  changeSelect = (id) => {
    this.setState({
      active: id
    })
  }
  change = (id) => {
    console.log(id)
    if (id == 1) {
      console.log(222)
      this.setState({
        usernolayer: true,
      })
    }
  }
  closeMask = () => {
    this.setState({
      usernolayer: false,
    })
  }

  gourls = (e) => {
    if (e == 1 && !this.state.todaysign) {
      this.props.history.push('/homeperson')
    }
    if (e == 2) {
      this.props.history.push('/learnplan')
    }
    if (e == 3) {
      this.props.history.push('/learnplan')
    }
    if (e == 4) {
      this.props.history.push('/learnplan')
    }
    console.log(e)
  }
  changemallnums = (id) => {
    console.log(id)
    this.setState({
      mallnums: id,
    })
  }

  componentDidMount() {
    this.props.change_active_two('learnplan')
    this.props.change_person_learnplan()
    var that = this;
    console.log(this.state.showdolist)


    fetch(
      "https://shl-info.cn/front/score/getsignscore?userid=" + localStorage.getItem('userid') ,{
        headers:{
          'token':localStorage.getItem('token')
        }
      }
    )
      .then(data => {
        return data.json();
      })
      .then(res => {
        console.log('当前积分条数', res)
        if (res.has_sign <= 0) {
          that.setState({
            todaysign: false
          })
        }

        that.setState({
          signnums: res.sign_days
        })

        // console.log(res)
      })


    // that.setState({
    //   signlevel: 1,
    //   signallscore: 333,
    //   signlevelscore: 100,
    // })
    fetch(
      "https://shl-info.cn/front/score/getscorelevel?userid=" + localStorage.getItem('userid') ,{
        headers:{
          'token':localStorage.getItem('token')
        }
      }
    )
      .then(data => {
        return data.json();
      })
      .then(res => {
        console.log(res)
        //res.data.current_level_score = 0;
        that.setState({
          signlevel: res.data.level,
          signallscore: res.data.total_score,
          signlevelscore: res.data.current_level_score,
          enddate: res.data.end_date
        })

      })


    //https://shl-info.cn/front/score/myscorelog
    $.ajax({
      type: "GET",
      url:
        "https://shl-info.cn/front/score/myscorelog?userid=" +
        localStorage.getItem('userid') ,
      dataType: "json",
      headers:{'token':localStorage.getItem('token')},
      cache: false
    })
      .catch(error => {
        console.log(error);
      })
      .then(res => {
        console.log(res)
        that.setState({
          scorcelist: res.data
        })

      });

    $.ajax({
      type: "GET",
      url:
        "https://shl-info.cn/front/score/mytop?userid=" +
        localStorage.getItem('userid') +
        "&usertype=" +
        localStorage.getItem('usertype'),
      dataType: "json",
      headers:{'token':localStorage.getItem('token')},
      cache: false
    })
      .catch(error => {
        console.log(error);
      })
      .then(res => {
        that.setState({
          usertoplist: res.data
        })
        console.log(res)
      });



    console.log('请求回来数组')
    $.ajax({
      type: "GET",
      url:
        "https://shl-info.cn/front/score/scoretypenum?userid=" +
        localStorage.getItem('userid') +
        "&usertype=" +
        localStorage.getItem('usertype'),
      dataType: "json",
      headers:{'token':localStorage.getItem('token')},
      cache: false
    })
      .catch(error => {
        console.log(error);
      })
      .then(res => {
        that.setState({
          candolist: res
        })
        console.log('请求回来数组', res)
        console.log(res.length)
        let newarr = that.state.showdolist
        for (var i = 0; i < that.state.showdolist.length; i++) {
          console.log(newarr[i])
          for (var j = 0; j < res.length; j++) {
            if ((i + 1) == res[j]['scoreid']) {
              newarr[i]['type' + res[j]['scoreid']]['show'] = res[j]['finish'];
              newarr[i]['type' + res[j]['scoreid']]['nums'] = res[j]['num'];
            }
          }
          //console.log(this.state.showdolist[i]['type' + (i + 1)])
        }
        that.setState({
          showdolist: newarr
        })
        console.log(newarr)
      });


  }
  componentWillUnmount() {

  }
  render() {
    // 切换导航
    let selectlist = this.props.selectlist.map((item) => {
      return (
        <SelectBox
          key={item.id}
          _item={item}
          active={this.state.active}
          change={this.change}
          imgstate="no">

        </SelectBox>
      )
    })
    let layerbox = null;
    if (this.state.usernolayer) {
      layerbox = (
        <Maskbox
          info={{
            title: "积分商城",
            text: "敬请期待！"
          }}
          closeMask={this.closeMask}
        ></Maskbox>
      )
    }
    console.log(this.state.usertoplist.year)
    return (
      <div className='personpage'>
        {layerbox}
        <Header active='4'></Header>
        <PersonImg type="no"></PersonImg>
        <PersonTitle title="积分商城"></PersonTitle>
        <div className='page-navselect'>
          <div className="wrap overflow">
            <ul className="navselect fl">
              {selectlist}
            </ul>
            <div className="select-contain fr overflow integralmall_contain">
              <div className="integralmall_box">
                <div className="integralmall_box_btnlist">
                  <div className={this.state.mallnums == 1 ? "active" : ''} onClick={(e) => this.changemallnums(1)}>积分明细</div>
                  <div className={this.state.mallnums == 2 ? "active" : ''} onClick={(e) => this.changemallnums(2)} >积分排行榜</div>
                  <div className={this.state.mallnums == 3 ? "active" : ''} onClick={(e) => this.changemallnums(3)}>观看课程赢积分</div>
                </div>
                <div className="integralmall_box_toplist">
                  <div className="integralmall_box_toplist_li">
                    <img src={require('../../static/images/mall/mall_icon1.png')} />
                      当前积分：
                      <span className='nums'>{this.state.signlevelscore}/200</span>
                    <span className='line'>
                      <i style={{ width: this.state.signlevelscore / 200 * 123 + 'px' }}></i>
                    </span>
                    <span className='integralmall_box_toplist_li_ts'>（积分有效期至：{this.state.enddate}）</span>
                  </div>
                  <div className="integralmall_box_toplist_li">
                    <img src={require('../../static/images/mall/mall_icon2.png')} />
                      当前等级：
                    <span className='integralmall_box_toplist_li_lvs'>Lv.</span>{this.state.signlevel}
                  </div>
                </div>
                <div className='integral_fromlist '>
                  <ul className={this.state.mallnums == 3 ? "integral_fromlist_looklist " : "integral_fromlist_looklist none"}>
                    {/* 赢取积分任务列表 */}
                    <li className={this.state.todaysign ? 'activeover' : ''} onClick={() => this.gourls(1)}>
                      {/* {this.state.showdolist[0]['type1']['show'] ? "111" : '222'} */}
                      <div className="integral_fromlist_li_title">每日签到</div>
                      <div className="integral_fromlist_li_today">您已签到{this.state.signnums}天</div>
                      <div className="integral_fromlist_li_date">每日签到可获得2分，连续签到3天以上可获得3分</div>
                      <div className="integral_fromlist_li_addfen">+2/+3</div>
                      <div className={this.state.todaysign ? 'integral_fromlist_li_rightbox active' : 'integral_fromlist_li_rightbox'}>去签到 &gt;</div>
                    </li>
                    <li className={this.state.showdolist[2]['type3']['show'] ? '' : ''} onClick={() => this.gourls(2)}>
                      <div className="integral_fromlist_li_title">完成必修课程</div>
                      <div className="integral_fromlist_li_today">您已完成{this.state.showdolist[2]['type3']['nums']}门课程</div>
                      <div className="integral_fromlist_li_date">分数每周递减一分，即刻完成可领</div>
                      <div className="integral_fromlist_li_addfen">+10</div>
                      <div className={this.state.showdolist[2]['type3']['show'] ? 'integral_fromlist_li_rightbox ' : 'integral_fromlist_li_rightbox'}>去完成 &gt;</div>
                    </li>


                    <li className={this.state.showdolist[9]['type10']['show'] ? '' : ''} onClick={() => this.gourls(3)}>
                      <div className="integral_fromlist_li_title">设定学习计划</div>
                      <div className="integral_fromlist_li_today">您已完成{this.state.showdolist[9]['type10']['nums']}门课程</div>
                      <div className="integral_fromlist_li_date">在学习计划中完成5门课程，即可获得积分</div>
                      <div className="integral_fromlist_li_addfen">+2</div>
                      <div className={this.state.showdolist[9]['type10']['show'] ? 'integral_fromlist_li_rightbox ' : 'integral_fromlist_li_rightbox'}>去完成 &gt;</div>
                    </li>


                {localStorage.getItem('iswaitype') == 1 || localStorage.getItem('iswaitype') == 2 || localStorage.getItem('iswaitype') == 3 || localStorage.getItem('iswaitype') == 5?null:
                 <li className={this.state.showdolist[14]['type15']['show'] ? '' : ''} onClick={() => this.gourls(4)}>
                 <div className="integral_fromlist_li_title">BP连续完成6门课程领积分</div>
                 <div className="integral_fromlist_li_today">您已完成{this.state.showdolist[14]['type15']['nums']}门课程</div>
                 <div className="integral_fromlist_li_date">完成BP全部选修课程，即可获得积分</div>
                 <div className="integral_fromlist_li_addfen">+10</div>
                 <div className={this.state.showdolist[14]['type15']['show'] ? 'integral_fromlist_li_rightbox ' : 'integral_fromlist_li_rightbox'}>去完成 &gt;</div>
               </li>
                }
                   


                    <li className='li_line'></li>

                    {/* <li className={this.state.showdolist[14]['type15']['show'] ? 'activeover' : ''} onClick={() => this.gourls(3)}>
                      <div className="integral_fromlist_li_title">BP连续完成6门课程领积分</div>
                      <div className="integral_fromlist_li_today">您已完成{this.state.showdolist[14]['type15']['nums']}门课程</div>
                      <div className="integral_fromlist_li_date">前两日+2 连续三天可+3分</div>
                      <div className="integral_fromlist_li_addfen">+10</div>
                      <div className={this.state.showdolist[14]['type15']['show'] ? 'integral_fromlist_li_rightbox active' : 'integral_fromlist_li_rightbox'}>去完成 ></div>
                    </li> */}

                    <li className="small_li">
                      <div className='integral_fromlist_li_icon'><img src={require('../../static/images/mall/23.png')} /></div>
                      <div className="integral_fromlist_li_title">完成选修培训课程</div>
                      <div className="integral_fromlist_li_date">完成考试或观看完毕即可获得积分。</div>
                      <div className="integral_fromlist_li_addfen">+2</div>
                    </li>

                    <li className="small_li">
                      <div className='integral_fromlist_li_icon'><img src={require('../../static/images/mall/23.png')} /></div>
                      <div className="integral_fromlist_li_title">观看直播/录播</div>
                      <div className="integral_fromlist_li_date">录播文档达总时长一半即可获得积分(只加一次)</div>
                      <div className="integral_fromlist_li_addfen">+2</div>
                    </li>

                    <li className="small_li">
                      <div className='integral_fromlist_li_icon'><img src={require('../../static/images/mall/25.png')} /></div>
                      <div className="integral_fromlist_li_title">观看文档</div>
                      <div className="integral_fromlist_li_date">观看文档达总页数一半即可获得积分</div>
                      <div className="integral_fromlist_li_addfen">+1</div>
                    </li>

                    <li className="small_li">
                      <div className='integral_fromlist_li_icon'><img src={require('../../static/images/mall/27.png')} /></div>
                      <div className="integral_fromlist_li_title">完成一门考试</div>
                      <div className="integral_fromlist_li_date">完成课程中的考试并通过即可获得积分</div>
                      <div className="integral_fromlist_li_addfen">+10</div>
                    </li>

                    <li className="small_li">
                      <div className='integral_fromlist_li_icon'><img src={require('../../static/images/mall/27.png')} /></div>
                      <div className="integral_fromlist_li_title">考试正确率90%以上</div>
                      <div className="integral_fromlist_li_date">完成课程中的考试并达到90分</div>
                      <div className="integral_fromlist_li_addfen">+1</div>
                    </li>

                    <li className="small_li">
                      <div className='integral_fromlist_li_icon'><img src={require('../../static/images/mall/28.png')} /></div>
                      <div className="integral_fromlist_li_title">提问</div>
                      <div className="integral_fromlist_li_date">在课程中提问，审核通过后即可获得积分</div>
                      <div className="integral_fromlist_li_addfen">+2</div>
                    </li>

                    {localStorage.getItem('iswaitype') == 1 || localStorage.getItem('iswaitype') == 2 || localStorage.getItem('iswaitype') == 3 || localStorage.getItem('iswaitype') == 5?null:
                      <li className="small_li">
                        <div className='integral_fromlist_li_icon'><img src={require('../../static/images/mall/28.png')} /></div>
                        <div className="integral_fromlist_li_title">集中考试</div>
                        <div className="integral_fromlist_li_date">参加集中考试，获得积分</div>
                        <div className="integral_fromlist_li_addfen">+30</div>
                      </li>
                    }
                  </ul>


                  <ul className={this.state.mallnums == 2 ? "" : "none"}>
                    {/* 积分排行榜 */}
                    <li className='integral_fromlist_li_one'>
                      <div className='integral_fromlist_li_icon'><img src={require('../../static/images/mall/mall_rank_icon1.png')} /></div>
                      <div className="integral_fromlist_li_title">年度排行</div>
                      <div className="integral_fromlist_li_addfen">{this.state.usertoplist.year ? this.state.usertoplist.year.top : null}</div>
                    </li>
                    <li>
                      <div className='integral_fromlist_li_icon'><img src={require('../../static/images/mall/mall_rank_icon2.png')} /></div>
                      <div className="integral_fromlist_li_title">季度排行</div>
                      <div className="integral_fromlist_li_date">共有{this.state.usertoplist.quarter ? this.state.usertoplist.quarter.person_num : null}人</div>
                      <div className="integral_fromlist_li_addfen">{this.state.usertoplist.quarter ? this.state.usertoplist.quarter.top : null}</div>
                    </li>
                    <li className=''>
                      <div className='integral_fromlist_li_icon'><img src={require('../../static/images/mall/mall_rank_icon3.png')} /></div>
                      <div className="integral_fromlist_li_title">当月排行</div>
                      <div className="integral_fromlist_li_date">共有{this.state.usertoplist.month ? this.state.usertoplist.month.person_num : null}人</div>
                      <div className="integral_fromlist_li_addfen">{this.state.usertoplist.month ? this.state.usertoplist.month.top : null}</div>
                    </li>
                  </ul>


                  <ul className={this.state.mallnums == 1 ? "" : "none"}>
                    {/* 获取积分来源列表样式 */}
                    {this.state.scorcelist.map((item, index) => {
                      let text = '';
                      let imssrc = require('../../static/images/mall/mall_list_icon1.png');
                      if (item.scoreid == 1) {
                        text = '每日签到';
                        imssrc = require('../../static/images/mall/mall_list_icon1.png');
                      }
                      if (item.scoreid == 2) {
                        text = '完成选修培训课程';
                        imssrc = require('../../static/images/mall/mall_list_icon2.png');
                      }
                      if (item.scoreid == 3) {
                        text = '完成必修课程';
                        imssrc = require('../../static/images/mall/mall_list_icon3.png');
                      }
                      if (item.scoreid == 4) {
                        text = '观看直播/录播';
                        imssrc = require('../../static/images/mall/mall_list_icon2.png');
                      }
                      if (item.scoreid == 5) {
                        text = '观看文档';
                        imssrc = require('../../static/images/mall/25.png');
                      }
                      if (item.scoreid == 6) {
                        text = '完成一门考试';
                        imssrc = require('../../static/images/mall/27.png');
                      }
                      if (item.scoreid == 7) {
                        text = '高分考试额外奖励';
                        imssrc = require('../../static/images/mall/27.png');
                      }
                      if (item.scoreid == 8) {
                        text = '参加用户反馈';
                        imssrc = require('../../static/images/mall/28.png');
                      }
                      if (item.scoreid == 9) {
                        text = '提问';
                        imssrc = require('../../static/images/mall/28.png');
                        imssrc = require('../../static/images/mall/28.png');
                      }
                      if (item.scoreid == 10) {
                        text = '';
                        imssrc = require('../../static/images/mall/213.png');
                      }
                      if (item.scoreid == 15) {
                        text = 'BP选修课程';
                        imssrc = require('../../static/images/mall/214.png');
                      }
                      return <li key={index}>
                        <div className='integral_fromlist_li_icon'><img src={imssrc} /></div>
                        <div className="integral_fromlist_li_title">{text}</div>
                        <div className="integral_fromlist_li_date">获取时间：{item.adddateime}</div>
                        <div className="integral_fromlist_li_addfen">+{item.score}</div>
                      </li>
                    })}
                    {/* <li>
                      <div className='integral_fromlist_li_icon'><img src={require('../../static/images/mall/mall_list_icon1.png')} /></div>
                      <div className="integral_fromlist_li_title">每日签到</div>
                      <div className="integral_fromlist_li_date">获取时间：2020/09/27 08：00</div>
                      <div className="integral_fromlist_li_addfen">+3</div>
                    </li>
                    <li>
                      <div className='integral_fromlist_li_icon'><img src={require('../../static/images/mall/mall_list_icon2.png')} /></div>
                      <div className="integral_fromlist_li_title">观看直播</div>
                      <div className="integral_fromlist_li_date">获取时间：2020/09/27 08：00</div>
                      <div className="integral_fromlist_li_addfen">+3</div>
                    </li>
                    <li>
                      <div className='integral_fromlist_li_icon'><img src={require('../../static/images/mall/mall_list_icon3.png')} /></div>
                      <div className="integral_fromlist_li_title">完成必修课程</div>
                      <div className="integral_fromlist_li_date">获取时间：2020/09/27 08：00</div>
                      <div className="integral_fromlist_li_addfen">+3</div>
                    </li>
                    <li>
                      <div className='integral_fromlist_li_icon'><img src={require('../../static/images/mall/mall_list_icon4.png')} /></div>
                      <div className="integral_fromlist_li_title">高分考试额外奖励</div>
                      <div className="integral_fromlist_li_date">获取时间：2020/09/27 08：00</div>
                      <div className="integral_fromlist_li_addfen">+3</div>
                    </li>
                    <li>
                      <div className='integral_fromlist_li_icon'><img src={require('../../static/images/mall/mall_list_icon5.png')} /></div>
                      <div className="integral_fromlist_li_title">完成考试</div>
                      <div className="integral_fromlist_li_date">获取时间：2020/09/27 08：00</div>
                      <div className="integral_fromlist_li_addfen">+3</div>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div >
    )
  }
}

export default Integralmall
