import React, { Component } from 'react'
import $ from 'jquery'
// 组件
import Header from '../../components/header'
import Footer from '../../components/footer'
import PersonImg from '../../components/personimg'
import PersonTitle from '../../components/persontitle'

import Onlinediexam from '../../components/onlinediexam';//考试


import Maskbox from '../../components/maskbox';



import './index.css'

class Diexamdetail extends Component {
  constructor() {
    super()
    this.state = {
      title: "",
      thisindex:-1,
      mastboxsurverystate:false,
      starttime:'',
      qalist:[],
      endtime:'',
      iscanactive:true,
      item:'',
      itemselect:'',
      isnotime:false,
      iserrortext:'',
      loading_tips:true,
      examlist:'',
      thistypeids:'',
      thisids:'',
      name: "",
      certificatetime: "",
      certificatenum: ""
      
    };
  }

  
  getUrlkey(url) {
    var params = {};
    var urls = url.split("?");                  ////console.log('1_分割url:', urls)
    var arr = urls[1].split("&");               ////console.log('2_分割urls[1]:', arr)
    for (var i = 0, l = arr.length; i < l; i++) {
      var a = arr[i].split("=");                ////console.log('3_遍历 arr 并分割后赋值给a:', a[0], a[1])
      params[a[0]] = a[1];                      ////console.log('4_a给params对象赋值:', params)
    }                                           ////console.log('5_结果:', params)
    return params;
  }
  componentDidMount() {
    this.classid = this.props.location.search.split("&")[0].slice(4);
    this.types = "";
    console.log(this.props.location.search)
    if (window.location.href.indexOf('?') != -1) {
      var urls = this.getUrlkey(window.location.href);
      //console.log(urls.id)
      this.types = urls.types
      
    }
    let nums=1;
    if(this.types==6){
      nums=1;
    }else if(this.types==7){
      nums=2;
    }else if(this.types==8){
      nums=3;
    }else if(this.types==9){
      nums=4;
    }
    console.log(this.classid)
    console.log(this.types)
    if(!this.classid){
      //window.location.href="https://shl-info.cn/"
    }
    let that=this;
    console.log(this.props.reviewClassArr)
    // $.ajax({
    //   type: "GET",
    //   url:
    //     "https://shl-info.cn/front/frontc/getxiliesbyids_foruserlist?id="+this.classid+"&typeids="+nums+"&userid=" +
    //     this.props.userinfo.userid ,
    //   dataType: "json",
    //   headers:{'token':this.props.userinfo.token},
    //   cache: false
    // }).then(res => {
    //   console.log(res)
    //   if(res.code==200){
        
    //     // qalist:res.qalist
    //     that.setState({
    //       title:res.data.name,
    //       examlist:res.list,
    //       loading_tips:false,
    //     })
    //   }
    // });

    
    $.ajax({
      type: "GET",
      url:
        "https://shl-info.cn/front/Diexam/getExamList?id="+this.classid+"&typeids="+nums+"&userid=" +
        this.props.userinfo.userid ,
      dataType: "json",
      headers:{'token':this.props.userinfo.token},
      cache: false
    }).then(res => {
      console.log(res)
      if(res.code==200){
        that.setState({
          title:res.data.name,
          examlist:res.list,
          loading_tips:false,
        })
      }
    });



    //获取问卷
    // $.ajax({
    //   type: "GET",
    //   url:
    //     "https://shl-info.cn/front/frontc/getxiliesbyids?id="+this.classid+"&userid=" +
    //     this.props.userinfo.userid +
    //     "&token=" +
    //     this.props.userinfo.token,
    //   dataType: "json",
    //   cache: false
    // }).then(res => {
    //   console.log(res)
    //   if(res.code==200){
    //     that.setState({
    //       title:res.data.name,
    //       examlist:res.list,
    //     })
    //   }
    // });
  }
  gotorul = () => {
    this.props.history.push("/broadcast?type=" + this.classid + "&viewresults=1");
  }
  componentWillUnmount() {

  }
  changeactive = (e,item)=>{
    console.log(e,item)
    let that =this
    if(e==this.state.thisindex){
      console.log(1111)
      this.setState({
        thisindex:-1
      })
    }else{
      console.log(2222)
      $.ajax({
        type: "GET",
        url:
          "https://shl-info.cn/front/Diexam/getSeriesInfo?id="+item.id+"&xilieid="+this.classid+"&userid=" +
          this.props.userinfo.userid ,
        dataType: "json",
        headers:{'token':this.props.userinfo.token},
        cache: false
      }).then(res => {
        console.log(res)
        if(res.code==200){
          console.log(33333)
          let  arras=that.state.examlist[e];
          arras['timelist_sec'] = res.data['timelist_sec'];
          arras['timelist_fir'] = res.data['timelist_fir'];
          arras['namelist'] = res.data['namelist'];
          arras['thistypesallnums'] = res.data['thistypesallnums'];
          arras['thistypesallpassnums'] = res.data['thistypesallpassnums'];
          arras['issubstate'] = res.data['issubstate'];
          
          arras['isfirsurver'] = res.data['isfirsurver'];
          arras['ispass'] = res.data['ispass'];
          arras['issuerver'] = res.data['issuerver'];
          arras['maxfen'] = res.data['maxfen'];
          arras['firispass'] = res.data['firispass'];
          arras['firissuerver'] = res.data['firissuerver'];

          
          // arras['thistypesallnums'] = 100;
          // arras['thistypesallpassnums'] = 100;


          console.log(arras)

          let newsarray=that.state.examlist;
          newsarray[e] = arras;
          that.setState({
            examlist:newsarray
          })
          console.log(that.state.examlist[e])
        }
      });
      this.setState({
        thisindex:e
      })
    }
    console.log(12312312312)
  }
  // gotoserieslist= ()=>{
  //   console.log(2222)
  // }
  gotocertificatedetails = (ids) =>{
   // certificatedata?type=20&di=1
    this.props.history.push("/certificatedata?type=" + ids +"&di=1");
  }
  goexam = (ids,starttime,endtime,item,itemselect)=>{
    
    let sttime=new Date(starttime).getTime();
    let entime=new Date(endtime).getTime();
    let nowtime=new Date().getTime();
    if(nowtime>=sttime&&nowtime<=entime ){
     

      localStorage.setItem('itemlistid',ids)
      this.setState({
        thistypeids:ids,
        starttime:starttime,
        endtime:endtime,
        item:item,
        itemselect:itemselect,
      })
    }else{
      this.setState({
        iserrortext:"不在考试时间内，请按时间准时参与考试",
        isnotime:true,
      })
    }
    console.log(sttime,entime,nowtime)
    return false;
    console.log(ids)
    console.log(item)
    
    // this.props.history.push("/diexamdetails?id=" + ids );
    // console.log(1231231230)
  }
  gotoresult=()=>{
    console.log(22222)
  }
  gotocertificate=()=>{
    console.log(33333)
  }

  changesurverymask =()=>{
    console.log(2222222)
  }
  subsurvery =()=>{
    console.log(1111111)
  }
  startsur =()=>{
    if(!this.state.iscanactive){
      this.setState({
        iserrortext:"请同意考试条款",
        isnotime:true,
      })
      return false;
    }
    console.log(this.state.item)
    console.log(this.state.itemselect)
    let that=this;
    $.ajax({
      type: "GET",
      url:
        "https://shl-info.cn/front/frontc/savesurverdifir?courseid="+this.state.itemselect.id+"&userid=" +
        this.props.userinfo.userid + "&types=" + this.state.itemselect.types ,
      dataType: "json",
      
      headers:{'token':this.props.userinfo.token},
      cache: false
    }).then(res => {
      console.log(res)
      if(res.code==200){
        that.setState({
          thisids:res.ids
        })
        console.log(11111112222222)
        let orderarrjson=localStorage.getItem("qalist"+that.state.itemselect.id);
        let qalistarr=that.state.itemselect['examlist']
        if(orderarrjson){
          qalistarr=JSON.parse( orderarrjson );
        }
        console.log(orderarrjson)
        //let orderarr=JSON.parse( jsonStr );
        that.setState({
          qalist:qalistarr,
        })
      }
    });
    console.log(3333333)
    //console.log(this.state.item['numlist'][0]['examlist'])
    // this.setState({
    //   qalist:this.state.itemselect['examlist'],
    // })
  }

  
  // 修改考试
  changeExams = arr => {
    console.log(arr)
    console.log(this.state.thistypeids)
    let arrss=JSON.stringify(arr);
    console.log(arrss)
    localStorage.setItem("qalist"+this.state.thistypeids,arrss)
    this.setState({
      qalist: arr
    });
  };


  
  closeMask = () => {
    this.setState({
      isnotime: false,
      mastboxsurverystate:false,
    });
  }

  changeiscanactive = ()=>{
    this.setState({
      iscanactive:!this.state.iscanactive,
      
    })
  }

  gotoachievement = (ids) =>{
    console.log(ids)

    this.props.history.push("/diexamachievement?type=" + ids +"&di=1");
  }


  render() {
    console.log(this.state.itemselect)
    let onlineexam=null
    if(this.state.qalist.length>0){
      onlineexam=<Onlinediexam
      examlist={this.state.qalist}
      thisids={this.state.thisids}
      gotoresult={() => this.gotoresult()}
      gotocertificate={() => this.gotocertificate()}
      change={this.changeExams}
      classid={this.state.thistypeids}
      userinfo={this.props.userinfo}
      itemselect={this.state.itemselect}
      lookstate={true}
      usercount={this.state.userqacount}
      history = {this.props.history}
      userqastate={this.state.userqastate}
      changeexamstate={this.state.changeexamstate}
    ></Onlinediexam>
    }


    let exammask = null;
    if (this.state.isnotime) {
      exammask = (
        <Maskbox
          info={{
            title: "在线考试",
            text: this.state.iserrortext
          }}
          closeMask={()=>this.closeMask()}
        ></Maskbox>
      );
    }

    
    return (
      <div className="personpage">
        <Header active="4"></Header>
        <PersonImg type="no"></PersonImg>
        <PersonTitle title={this.state.title}></PersonTitle>
        {exammask}
        {/* {layerexammaskstate} */}
        <div className={this.state.thistypeids?"wrap diexam-qalist ":"wrap diexam-qalist"}>
          
          {onlineexam}
        </div>
        <div className={this.state.thistypeids?this.state.qalist.length>0?" none ":"wrap diexam-box":"none"}>
            <div className='exam_title'>{this.state.item.exam_name}</div>
            <div className="exam_content" style={{paddingTop:"30px"}}>
              {/* 课程简介： */}
              考试简介：
              {this.state.item.exam_content}
            </div>
            
        <div className='exam_times'>考试时间：{this.state.starttime} - {this.state.endtime} </div>
        <div className='exam_start'>
          <span className='goexam' onClick={()=>this.startsur()}>开始考试</span>
        </div>
        <div className='exam_role  '>
          <span className={this.state.iscanactive?"active":" "}  onClick={()=>this.changeiscanactive()}>我愿意诚信考试，遵守考场纪律</span>
        </div>
        </div>
        <div className={this.state.loading_tips?" loading_tips ":" none "}>
          您好，欢迎进入考试系统。<br></br>
          在开始考试前请确认您已经完成该次考试对应模块的视频内容学习及随堂考练习<br></br>
          否则无法进入考试系统<br></br>
          请耐心等待，正在装载数据。
        </div>
        <div className={this.state.thistypeids?"none":"wrap diexam-box "}>
          {this.state.examlist.length >0?
            this.state.examlist.map((item,index)=>{
              console.log(item)
              console.log(item.thistypesallnums,item.thistypesallpassnums)
              console.log("item.thistypesallnums,item.thistypesallpassnums")
              let newstitle='';  
              if(item.nameslist){
                newstitle=item.nameslist.substring(0, item.nameslist.length - 1);  
              }
              console.log(newstitle)
              console.log(item['namelist'])
              console.log(item['timelist_fir'])
              return (
                <div className={this.state.thisindex == index?"serieslist cursor active":"serieslist cursor" } key={index}>
                  <h3 className='series-title'  onClick={(e) => this.changeactive(index,item)}>
                      {item.exam_name}
                      <img src={require('../../static/images/seriesarrow.png')}  className="series-select" alt="" />
                  </h3>
                  {item['timelist_fir']?
                    <div className={item['timelist_fir'].length>0?this.state.thisindex == index?"examlist-boxli ":"none":" none "} >
                    <div className={item.thistypesallnums==item.thistypesallpassnums?" none ":" tips_erroenopass "}>您好，本次考试涉及以下内容，{item['namelist']}。<br></br>您尚未完成视频学习及随堂考试练习，暂不能参加考试。</div>
                    <div className={item.thistypesallnums==item.thistypesallpassnums?"tips_erroenopass  ":" none "}>您好，本次考试涉及以下内容，{item['namelist']}。<br></br>您已经完成视频学习及随堂考试练习， 请您在规定时间内开始考试。</div>
                 

                    <div  className={item.thistypesallnums==item.thistypesallpassnums?" ":"  "}>
                      <div className='nametitles'>第一轮考试</div> 
                      <ul className='series-labellist examlist-boxlist'>
                        <li style={{fontWeight:"bold"}}>
                          <span className='firtitle'>场次</span>
                          <span className='sectitle'>开始时间</span>
                          <span className='thititle'>结束时间</span>
                          <span className='fivetitle'>考试状态</span>
                          <span className='sixtitle'>考试结果</span>
                          <span className='fourtitle titles'>操作</span>
                        </li>
                        {item['timelist_fir'].length>0?
                          item['timelist_fir'].map((itemlist,indexlist)=>{
                            console.log(itemlist)
                            console.log("itemlist22222")
                            let thisstate=0;
                            let sttime=new Date(itemlist.starttime).getTime();
                            let entime=new Date(itemlist.endtime).getTime();
                            let nowtime=new Date().getTime();
                            let stovertime = sttime + (item.max_logintime*1000*60);
                          //  / && localStorage.getItem('itemlistid') ==  itemlist.id
                            if( (nowtime>=sttime&&nowtime<=stovertime ) || (nowtime>=sttime&&nowtime<=entime && itemlist.issubstate ===0) ){
                              thisstate=1;
                            }else{
                              thisstate=2
                            }
                            console.log(thisstate)
                            console.log(itemlist.issuerver)
                            console.log(itemlist.issubstate)
                            console.log(item.firissuerver)
                            console.log(item.thistypesallnums)
                            console.log(item.thistypesallpassnums)
                            console.log(item.iscan_exam_fir)
                            return (
                              <li  key={indexlist} >
                                {/* {itemlist.starttime} */}
                                <span className='firtitle'>第{indexlist+1}场</span>
                                <span className='sectitle'>{itemlist.starttime} </span>
                                <span className='thititle'>{itemlist.endtime} </span>

                            {itemlist.issuerver !=1   && thisstate !=1?<span className='fourtitle nostart' >{nowtime>entime?"考试结束":"进入考试"}</span>:null}
                                { (itemlist.issuerver !=1 && thisstate ==1 ) || (itemlist.issubstate !=1  && thisstate ==1 ) 
                                ?(item.firissuerver==1 && item.iscan_exam_fir==1 )||item.firissuerver==0 || (item.firissuerver==1 && itemlist.issubstate ===0  && thisstate ==1 )
                                ?item.thistypesallnums==item.thistypesallpassnums
                                ?<span className='fourtitle '  onClick={()=>this.goexam(itemlist.id,itemlist.starttime,itemlist.endtime,item,itemlist)} >进入考试</span>
                                :<span className='fourtitle nostart' >{"进入考试"}</span>
                                :<span className='fourtitle nostart' >{"进入考试"}</span>
                                :null}
                                {itemlist.isfirsurver==1 && itemlist.issubstate ==1 ? <span className='fourtitle nostart' >考试结束</span>: null}
                                {itemlist.ispass==1?<span className='fourtitle ' onClick={()=>this.gotocertificatedetails(itemlist.baseids)} >查看证书</span>:null}
                                {itemlist.issuerver==1  && itemlist.issubstate ==1?<span className='fourtitle ' onClick={()=>this.gotoachievement(itemlist.id)} >查看成绩</span>:null}

                                {/* {itemlist.isfirsurver==1? <span className='fourtitle' >考试结束</span>: <span className='fourtitle' onClick={()=>this.goexam(itemlist.id,itemlist.starttime,itemlist.endtime,item,itemlist)}>进入考试 </span>} */}
                              
                                <span className='fivetitle'>{itemlist.issuerver==1?"已考试":"未考试"}</span>
                                <span className='sixtitle'>{itemlist.issuerver==1&&itemlist.issubstate ==1?itemlist.ispass==1&&itemlist.issubstate ==1?itemlist.maxfen+"分/ 通过":itemlist.maxfen+"分/ 未通过":"无成绩"}</span>
                              </li>
                            )
                          })
                        :null}
                      </ul>
                    </div>
                   </div>
                  :null}
                  
                  <div  className={item.thistypesallnums==item.thistypesallpassnums?"  ":" "}>
                    {item['timelist_sec']?
                    <div className={item['timelist_sec'].length>0?this.state.thisindex == index?"examlist-boxli ":"none":" none "} >
                    <div className='nametitles'>第二轮考试</div> 
                    <ul className='series-labellist examlist-boxlist'>
                      <li  style={{fontWeight:"bold"}}>
                        <span className='firtitle'>场次</span>
                        <span className='sectitle'>开始时间</span>
                        <span className='thititle'>结束时间</span>
                        <span className='fivetitle'>考试状态</span>
                        <span className='sixtitle'>考试结果</span>
                        <span className='fourtitle titles'>操作</span>
                      </li>

                      {item['timelist_sec'].length>0?
                          item['timelist_sec'].map((itemlist,indexlist)=>{
                          
                            let thisstate=0;
                            let sttime=new Date(itemlist.starttime).getTime();
                            let entime=new Date(itemlist.endtime).getTime();
                            let nowtime=new Date().getTime();
                            let stovertime = sttime + (item.max_logintime*1000*60);
                            console.log(sttime,stovertime,entime)
                            console.log(item.max_logintime)
                            if( (nowtime>=sttime&&nowtime<=stovertime ) || (nowtime>=sttime&&nowtime<=entime && localStorage.getItem('itemlistid') ==  itemlist.id) ){
                              thisstate=1;
                            }else{
                              thisstate=2
                            }
                            console.log(itemlist)
                            return (
                                  <li key={indexlist} >
                                    <span className='firtitle'>第{indexlist+1}场</span>
                                    <span className='sectitle'>{itemlist.starttime} </span>
                                    <span className='thititle'>{itemlist.endtime} </span>
                                    {thisstate !=1?<span className='fourtitle nostart' >{nowtime>entime?"考试结束":itemlist.issuerver==1?"考试结束":"进入考试"}</span>:null}
                                    {/* {thisstate!=1? <span className='fourtitle nostart' >进入考试</span>: null} */}
                                    {thisstate==1
                                    ?itemlist.issuerver==1  && itemlist.issubstate ==1
                                    ?<span className='fourtitle nostart' >考试结束</span>
                                    :item.iscan_exam==0 && item.firispass==1 
                                    ?<span className='fourtitle nostart' >进入考试</span>
                                    :item.thistypesallnums==item.thistypesallpassnums
                                    ?<span className='fourtitle '  onClick={()=>this.goexam(itemlist.id,itemlist.starttime,itemlist.endtime,item,itemlist)}>进入考试</span>
                                    :<span className='fourtitle nostart' >进入考试</span>
                                    : null}
                                    {/* <span className='fourtitle' onClick={()=>this.goexam(itemlist.id,itemlist.starttime,itemlist.endtime,item,itemlist)}>进入考试 </span> */}
                                    {/* <span className='fourtitle' onClick={()=>this.goexam(itemlist.id,itemlist.starttime,itemlist.endtime,item,itemlist)}>进入考试 </span> */}

                                  
                                  {itemlist.ispass==1?<span className='fourtitle ' onClick={()=>this.gotocertificatedetails(itemlist.baseids)} >查看证书</span>:null}
                                    {itemlist.issuerver==1 && itemlist.issubstate ==1?<span className='fourtitle ' onClick={()=>this.gotoachievement(itemlist.id)} >查看成绩</span>:null}


                                    <span className='fivetitle'>{itemlist.issuerver==1?"已考试":"未考试"}</span>
                                    <span className='sixtitle'>{itemlist.issuerver==1 && itemlist.issubstate ==1?itemlist.ispass==1&&itemlist.issubstate ==1?itemlist.maxfen+"分/ 通过":itemlist.maxfen+"分/ 未通过":"无成绩"}</span>
                                  </li>
                            )
                          })
                      :null}
                    
                    </ul>
                  </div>
                  :null}
                    
                  </div> 
                 
                  {/* <div  className="examlist-boxli">
                    <div className='nametitles'>第一轮考试</div> 
                    <ul className='series-labellist examlist'>
                      <li  onClick={() => this.gotoserieslist()}>
                        test123123123
                      </li>
                      <li  onClick={() => this.gotoserieslist()}>
                        test123123123
                      </li>
                      <li  onClick={() => this.gotoserieslist()}>
                        test123123123
                      </li>
                      <li  onClick={() => this.gotoserieslist()}>
                        test123123123
                      </li>
                    </ul>
                  </div> */}
                </div>)
            })
            
          :null}
          
        </div>
        <Footer></Footer>
      </div>
    );
  }
}

export default Diexamdetail
