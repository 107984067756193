import React, { Component } from 'react'
// 组件
import Header from '../../components/header'
import Footer from '../../components/footer'
import PersonImg from '../../components/personimg'
import PersonTitle from '../../components/persontitle'
import RecordList from '../../components/recordlist'

import './index.css'

class LearnRecord extends Component {

  componentDidMount() {
    this.props.change_active_two('learnrecord')
    this.props.change_person_learncord_data()
  }
  componentWillUnmount() {
    
  }
  render() {
    let list = null;
    if (this.props.learnrecord){
      list = this.props.learnrecord.loglist.map((item) => {
        let jsx_dom = <span>{item.nums}<span className="less-font">次</span></span>;
        return (
          <RecordList
            key={item.id}
            title={item.type}
            right={jsx_dom}>

          </RecordList>
        )
      })
    }
    return (
      <div className='personpage'>
        <Header active='4'></Header>
        <PersonImg type='no'></PersonImg>
        <PersonTitle title='学习统计'></PersonTitle>
        <div className='wrap'>
          {list}
          <div className="record-bottom">
            <p><span>注册时间：{this.props.learnrecord.regtime}</span>
            <span>上次登录时间：{this.props.learnrecord.lasttime}</span></p>
          </div>
        </div>
        <Footer></Footer>
      </div>
    )
  }
}

export default LearnRecord
